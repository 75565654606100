import React, { Component } from "react";
import { connect } from "react-redux";

import Header from "../../../../common/header";
import InspectionsList from "./list";
import { ReactComponent as IconSearch } from "../../../../../../resource/img/icons/icon_search.svg";
import { getManyInspections } from "../../../../../data/actions/ekostraznik/inspection_history";
import { generatePaginationQueryValues } from "../../../../../core/utils/api_utils";
import { isNullOrUndefined } from "../../../../../core/utils/misc_utils";
import ListPagination from "../../../../common/pagination";
import withPagination from "../../../../common/hoc/with_pagination";
import InspectionCreateModalForm from "./create_modal_form";
import {
    getInspectionCountThreshold,
    updateInspectionCountThreshold
} from "../../../../../data/actions/ekostraznik/inspection_count_threshold";
import Select from "../../../../common/select";
import DropdownMenu from "../../../../common/dropdown_menu";
import { ReactComponent as IconEllipsis } from "../../../../../../resource/img/icons/icon_ellipsis.svg";
import QuantitativeReportModalForm from "../reports/quantitative_report_modal_form";


class EkostraznikInspectionHistory extends Component {

    state = {
        inspectionCountThreshold: 2,
        filterOptions: [
            { value: "all", label: "Wszystko" },
            { value: "above-threshold", label: "Przekroczone kontrole" }
        ],
        selectedFilterOption: "all"
    };

    defaultPaginationQueryValues = generatePaginationQueryValues(0, 30, ["lastInspectionDate", "desc"]);

    render = () => {
        const { filterOptions, inspectionCountThreshold } = this.state;
        const { inspectionLocations, _onPrevClick, _onNextClick, _onPageNumberInputValueChange } = this.props;

        return (
            <div className="main-content--table">
                <div className="main-content--table__top">
                    <Header>
                        Kontrole nieprawidłowej segregacji
                    </Header>
                    <div className="container is-fluid">
                        <div className="row row--separated row--options flexible-height-wrap">
                            <div className="row__left is-v-centered wrapable">
                                <div className="input-field header__search with-icon--left options-item">
                                    <input type='text'
                                           placeholder='Znajdź adres lub grupę'
                                           onChange={ (event) => this._onSearchChange(event.target.value) }
                                    />
                                    <IconSearch className='input-icon'/>
                                </div>

                                <div className="options-item is-v-centered">
                                    <span className="is-spaced--right-10">Pokaż:</span>
                                    <div className="input-size-l">
                                        <Select
                                            options={ filterOptions }
                                            defaultValue={ filterOptions[0] }
                                            isClearable={ false }
                                            onChange={ option => this._onFilterSelect(option.value) }/>
                                    </div>
                                </div>
                            </div>
                            <div className="row__right">
                                <InspectionCreateModalForm _onSubmitCallback={ this.updateInspectionsList }/>
                                <span className='is-spaced--left-10'>
                                    <DropdownMenu
                                        triggerComponent={<button
                                            className="btn btn--outlined btn--icon"
                                            title='Więcej'
                                        >
                                            <IconEllipsis/>
                                        </button>}
                                    >
                                        <QuantitativeReportModalForm />
                                    </DropdownMenu>
                                </span>
                            </div>
                        </div>
                        <div className="row row--options">
                            <p className="is-bold">
                                Alarmuj, gdy będzie min.
                                <input className="input--number" type="number" value={ inspectionCountThreshold } min="1" onChange={ (event) => this._onInspectionCountThresholdChange(event) }/>
                                zgłoszeń
                            </p>
                        </div>
                    </div>
                </div>
                <InspectionsList
                    list={ !isNullOrUndefined(inspectionLocations.data) ? inspectionLocations.data.content : [] }
                />
                <div className="container is-fluid">
                    <div className="is-flex justify-end pagination--list">

                        {
                            !isNullOrUndefined(inspectionLocations.data) &&

                            <ListPagination data={ inspectionLocations.data }
                                            _onPrevClick={ _onPrevClick }
                                            _onNextClick={ _onNextClick }
                                            _onPageNumberInputValueChange={ _onPageNumberInputValueChange }/>
                        }

                    </div>
                </div>
            </div>
        );
    };

    componentDidMount = () => {
        const { getInspectionCountThreshold } = this.props;

        getInspectionCountThreshold()
            .then(() => {
                const { inspectionCountThresholdGet } = this.props;
                this.setState({
                    inspectionCountThreshold: inspectionCountThresholdGet.data.inspectionCountThreshold
                });
                this.updateInspectionsList();
            });
    };

    updateInspectionsList = () => {
        const { getManyInspections, currentPage, setMaxPage } = this.props;

        getManyInspections({ ...this.defaultPaginationQueryValues, page: currentPage, inspectionCountThreshold: this.state.inspectionCountThreshold })
            .then(() => {
                    setMaxPage(this.props.inspectionLocations.data.totalPages);
                }
            );
    };

    shouldComponentUpdate = (nextProps, nextState) => {
        const { currentPage, inspectionLocations, getManyInspections } = this.props;

        if (nextProps.currentPage !== currentPage) {
            getManyInspections({ ...this.defaultPaginationQueryValues, page: nextProps.currentPage, inspectionCountThreshold: this.state.inspectionCountThreshold });
            return true;
        }

        if (nextProps.inspectionLocations !== inspectionLocations) {
            return true;
        }

        if (nextState !== this.state) {
            return true;
        }

        if (nextProps !== this.props) {
            return true;
        }

        return false;
    };

    _onFilterSelect = (selectedFilterValue) => {
        const { getManyInspections, currentPage, setMaxPage } = this.props;

        this.setState({
            selectedFilterOption: selectedFilterValue
        });

        getManyInspections({ ...this.defaultPaginationQueryValues, page: currentPage, inspectionCountThreshold: this.state.inspectionCountThreshold, show: selectedFilterValue })
            .then(() => {
                    setMaxPage(this.props.inspectionLocations.data.totalPages);
                }
            );
    };

    _onSearchChange = (inputValue) => {
        const { getManyInspections, currentPage, setMaxPage } = this.props;
        const { selectedFilterOption } = this.state;

        getManyInspections({ ...this.defaultPaginationQueryValues, page: currentPage, inspectionCountThreshold: this.state.inspectionCountThreshold, show: selectedFilterOption, location: inputValue })
            .then(() => {
                    setMaxPage(this.props.inspectionLocations.data.totalPages);
                }
            );
    };

    _onInspectionCountThresholdChange = (event) => {
        const { getManyInspections, updateInspectionCountThreshold, currentPage } = this.props;

        if (event.target.value !== "" && event.target.value > 0) {

            this.setState({
                inspectionCountThreshold: event.target.value
            }, () => {
                const { inspectionCountThreshold } = this.state;

                updateInspectionCountThreshold({ inspectionCountThreshold: inspectionCountThreshold });
                getManyInspections({ ...this.defaultPaginationQueryValues, page: currentPage, inspectionCountThreshold: inspectionCountThreshold });
            });

        }
        else {
            this.setState({
                inspectionCountThreshold: event.target.value
            });
        }

    };
}

const mapStateToProps = (state) => {
    return {
        inspectionLocations: state.entities.ekostraznik.inspectionHistory.getMany,
        inspectionCountThresholdGet: state.entities.ekostraznik.inspectionCountThreshold.get
    };
};

export default withPagination(connect(mapStateToProps, { getManyInspections, getInspectionCountThreshold, updateInspectionCountThreshold })(EkostraznikInspectionHistory));