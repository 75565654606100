import React from "react";

import { Field, Form } from "formik";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, { formatDate } from "react-day-picker/moment";
import moment from "moment";
import "moment/locale/pl";

import Header from "../../../../../common/header";
import { ReactComponent as IconSearch } from "../../../../../../../resource/img/icons/icon_search.svg";
import { MISC_DATE_ISO_DATE_ONLY_FORMAT } from "../../../../../../core/constants";


const MessageHistorySearchOptions = ({ from, to, setFieldValue }) => {

    return (
        <div className="main-content--table__top message-history">
            <Header>
                Wysłane wiadomości
            </Header>

            <Form>
                <section className='container is-fluid'>
                    <div className="row row--options">

                        <Field
                            name="query"
                            render={ ({ field }) => (
                                <div className="input-field header__search with-icon--right options-item">
                                    <input type='text'
                                           placeholder='Szukaj wiadomość'
                                           { ...field }/>

                                    <IconSearch className='input-icon'/>
                                </div>
                            ) }/>

                        <div className="options--group">
                            <fieldset className="input-field options-item options-item--range">
                                <legend className="is-sr-only">Zakres dat:</legend>
                                <div className="is-v-centered">
                                    <div className="options-param">
                                        <span className="label-name" aria-hidden={ true }>Zakres dat:</span>
                                    </div>
                                    <label className="options-param">
                                        <div className="input-size--date">
                                            <DayPickerInput
                                                className="message-sending-1__input"
                                                placeholder={ MISC_DATE_ISO_DATE_ONLY_FORMAT }
                                                format={ MISC_DATE_ISO_DATE_ONLY_FORMAT }
                                                formatDate={ formatDate }
                                                value={ from }
                                                onDayChange={ date => setFieldValue("from", moment(date)
                                                    .format(MISC_DATE_ISO_DATE_ONLY_FORMAT)) }
                                                inputProps={ {
                                                    readOnly: true
                                                } }
                                                dayPickerProps={ {
                                                    localeUtils: MomentLocaleUtils,
                                                    locale: "pl"
                                                } }/>
                                        </div>
                                    </label>
                                    <div className="message-sending-1__dash">-</div>
                                    <label className="options-param">
                                        <div className="input-size--date">
                                            <DayPickerInput
                                                className="message-sending-1__input"
                                                placeholder={ MISC_DATE_ISO_DATE_ONLY_FORMAT }
                                                format={ MISC_DATE_ISO_DATE_ONLY_FORMAT }
                                                formatDate={ formatDate }
                                                value={ to }
                                                onDayChange={ date => setFieldValue("to", moment(date)
                                                    .format(MISC_DATE_ISO_DATE_ONLY_FORMAT))
                                                }
                                                inputProps={ {
                                                    readOnly: true
                                                } }
                                                dayPickerProps={ {
                                                    localeUtils: MomentLocaleUtils,
                                                    locale: "pl"
                                                } }/>
                                        </div>
                                    </label>
                                </div>
                            </fieldset>


                            <div className="input-field options-item">
                                <button type="submit" className="btn btn--primary btn--small">Szukaj</button>
                            </div>

                        </div>


                    </div>

                </section>
            </Form>
        </div>
    );
};

export default MessageHistorySearchOptions;
