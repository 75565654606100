import React from "react";
import { Link } from "react-router-dom";

const EmptyListPage = ({ img, imgAlt, text, link, linkText }) => {

    return (
        <div className="empty-list-page">
            <div className="empty-list-page__content">
                {
                    img &&
                    <img src={ img } alt={ imgAlt }/>
                }
                {
                    text &&
                    <p>{ text }</p>
                }
                {
                    link &&
                    <Link to={ link } className="btn btn--outlined">{ linkText }</Link>
                }
            </div>
        </div>
    );
};

export default EmptyListPage;