import React, { Component } from "react";
import PaymentDetailsItem from "./details/paymentsDetailsItem";

class QuarterlyPayments extends Component {

    state = {
        allInputsValue: ""
    };

    render = () => {
        const { formikBag } = this.props;

        return (
            <section className="payments-spacing">
                <h2 className="is-bold is-spaced--bottom-10">
                    Termin płatności
                </h2>

                <div className="payments__details payments-spacing">

                    <div className="payments-details__content">
                        <div className="payment-details__column">
                            <PaymentDetailsItem formikBag={ formikBag }
                                                name="march" month="Marzec (za I kwartał)"/>
                            <PaymentDetailsItem
                                formikBag={ formikBag }
                                name="june"
                                month="Czerwiec (za II kwartał)"/>
                            <PaymentDetailsItem
                                formikBag={ formikBag }
                                name="september"
                                month="Wrzesień (za III kwartał)"/>
                            <PaymentDetailsItem
                                formikBag={ formikBag }
                                name="december"
                                month="Grudzień (za IV kwartał)"/>
                        </div>
                    </div>
                </div>
            </section>
        );
    };

    componentDidUpdate = (prevProps) => {
        const { formikBag, receivePaymentsDueDate, _setFormValues } = this.props;
        if (receivePaymentsDueDate.data !== prevProps.receivePaymentsDueDate.data) {
            _setFormValues(formikBag, receivePaymentsDueDate);
        }
    };

    componentDidMount = () => {
        const { formikBag, receivePaymentsDueDate, _setFormValues } = this.props;
        _setFormValues(formikBag, receivePaymentsDueDate);
    };
}

export default QuarterlyPayments;