import common from "./common";
import ekostraznik from "./ekostraznik";
import services from "./services"
import wasteManagement from "./waste_management";
import blisko from "./blisko";

export default (state = {}, action) => {
    return {
        ...state,
        common: common(state.common, action),
        blisko: blisko(state.blisko, action),
        ekostraznik: ekostraznik(state.ekostraznik, action),
        services: services(state.services, action),
        wasteManagement: wasteManagement(state.wasteManagement, action)
    }
}