import webClient from "../../../core/web_client";
import { webClientFileDownload } from "../../../core/web_client";
import { API_BASE_AKUN_ADMIN_PATH } from "../../../core/constants";

const PATH = `${ API_BASE_AKUN_ADMIN_PATH }/regulations/acceptance-history`;

const PATH_SUBSCRIPTIONS = `${ API_BASE_AKUN_ADMIN_PATH }/user-subscriptions`;

export const getUserConsents = () => {
    return webClient
        .get(`${ PATH }`);
};

export const getUserConsentsExport = () => {
    return webClientFileDownload
        .get(`${ PATH }/export`);
};

export const getUserSubscriptions = () => {
    return webClient
        .get(`${ PATH_SUBSCRIPTIONS }`);
};

export const getUserSubscriptionsExport = () => {
    return webClientFileDownload
        .get(`${ PATH_SUBSCRIPTIONS }/export`);
};