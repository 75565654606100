import React from "react";
import { MISC_DATE_FORMAT, MISC_DATE_ISO_FORMAT, ROUTE_DETAILED_INVENTORY } from "../../../../../../core/constants";
import moment from "moment";
import CheckboxInput from "../../../../../common/checkbox_input";
import DeleteInventoryForm from "../form/delete_inventory_form";
import { useHistory } from "react-router-dom";

const InventoriesListItem = ({ inventory, checkedInventories, _onDeleteOrUpdateSuccess, _onInventoryCheckboxChange }) => {
    const history = useHistory();

    return (
        <tr>
            <td className="t-width-8">
                <CheckboxInput
                    _onInputChange={ () => _onInventoryCheckboxChange(inventory.lastInventoryId) }
                    checked={ checkedInventories.indexOf(inventory.lastInventoryId) >= 0 }
                />
                <p className="table-text-content is-clickable"
                   onClick={ () => history.push(ROUTE_DETAILED_INVENTORY(inventory.lastInventoryId)) }>
                    {
                        inventory?.group?.name || (inventory?.location?.town + ' ' + (inventory?.location?.street || '') + " " + (inventory?.location?.address || ''))
                    }
                </p>
            </td>
            <td className="t-width-3">
                {
                    moment(inventory.lastInventoryDate, MISC_DATE_ISO_FORMAT)
                        .format(MISC_DATE_FORMAT)
                }
            </td>
            <td className='sticky--right td-action-buttons'>
                <DeleteInventoryForm
                    inventory={ inventory }
                    _onSuccess={ _onDeleteOrUpdateSuccess }
                />
            </td>
        </tr>
    );
};

export default InventoriesListItem;