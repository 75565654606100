import React from "react";

import { useDispatch, useSelector } from "react-redux";
import FormModal from "../../../../../../common/modal/form_modal";
import { ReactComponent as IconDelete } from "../../../../../../../../resource/img/icons/icon_delete.svg";
import { deleteMessageTemplate } from "../../../../../../../data/actions/blisko/message_templates";

const DeleteMessageTemplateForm = ({ messageTemplate, _onSubmitCallback }) => {
    const dispatch = useDispatch();
    const deleteStatus = useSelector(state => state.entities?.blisko?.messageTemplates?.delete);

    return (
        <FormModal
            triggerComponent={
                <button className='unstyled-btn' title='Usuń'>
                    <IconDelete/>
                </button>
            }
            deleteOrCancelButtons={ true }
            _actionFunction={ () =>
                dispatch(deleteMessageTemplate(messageTemplate.id))
                    .then(async () => _onSubmitCallback())
            }
            isLoading={ deleteStatus?.meta?.loading }
        >
            <div className='c-modal__body modal-size-m modal--no-header'>
                <p className="confirmation-alert">
                    Czy na pewno chcesz usunąć ten szablon wiadomości?
                </p>
            </div>
        </FormModal>
    );
};

export default DeleteMessageTemplateForm;