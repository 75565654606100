import React, { Component } from "react";
import withDraftEditor from "../hoc/with_draft_editor";

class ExternalStateDraftEditor extends Component {

    render = () => {
        return (
            <>
            </>
        );
    };
}

export default withDraftEditor(ExternalStateDraftEditor, true);