import React from "react";
import TitleWithBottomDivider from "../../../../../common/title_with_bottom_divider";
import { Field } from "formik";
import CheckboxInput from "../../../../../common/checkbox_input";
import { ReactComponent as InfoIcon } from "../../../../../../../resource/img/icons/icon_info.svg";

const ReceiverGroupContent = ({ formikBag, onNameChange }) => {
    const { errors } = formikBag;

    return (
        <section className="media">
            <div className="media-left step-count-wrap is-spaced--top-30">
                <div className="step-count">
                    <span>1/3</span>
                </div>
            </div>
            <div className="media-content is-spaced--top-30">
                <div className="message-sending-1__section">
                    <div className="message-sending-1__subtitle">
                        <TitleWithBottomDivider>
                            Podstawowe informacje
                        </TitleWithBottomDivider>
                    </div>
                    <div className="is-spaced--top-20">
                        <Field>
                            {
                                () => (
                                    <>
                                        <CheckboxInput
                                            label={
                                                <span>
                                                    Serwis komercyjny
                                                </span>
                                            }
                                            checked={ formikBag.values.isCommercial }
                                            _onInputChange={ () => formikBag.setFieldValue("isCommercial", !formikBag.values.isCommercial) }
                                        />
                                        <div className="tooltip-trigger-icon">
                                            <InfoIcon/>
                                            <div className="tooltip-desc tooltip-desc--dark">
                                                TODO
                                            </div>
                                        </div>
                                    </>

                                )
                            }
                        </Field>
                    </div>
                    <div className="is-spaced--top-20">
                        <span className="required-accent">*</span><span className="is-bold">Nazwa serwisu</span>
                        <Field
                            name="name"
                            id="name"
                            className={ errors.name ? "rejected" : "" }
                            type="text"
                            onChange={ (event) => {
                                formikBag.setFieldValue("name", event.target.value);
                                onNameChange(event.target.value);
                            } }
                            placeholder="Nazwa serwisu"
                            aria-label="Nazwa"/>

                        {
                            errors.name &&
                            <strong className="hint hint--rejected">
                                {
                                    errors.name
                                }
                            </strong>
                        }
                    </div>
                    <div className="is-spaced--top-20">
                        <span className="is-bold">Opis serwisu</span>
                        <Field
                            name="description"
                            id="description"
                            type="text"
                            placeholder="Opis serwisu"
                            aria-label="Opis"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
};

export default ReceiverGroupContent;
