import React, { Component } from "react";
import { connect } from "react-redux";
import { queryParameterValue } from "../../../core/utils/string_utils";
import { addNewsletterSubscription } from "../../../data/actions/waste_management/newsletter";
import { isNullOrUndefinedOneOfMany } from "../../../core/utils/misc_utils";
import Loader from "../../common/loader";


class NewsletterSubscribeConfirmation extends Component {

    render = () => {
        const { newsletterSubscriptionAdd } = this.props;

        if (isNullOrUndefinedOneOfMany(newsletterSubscriptionAdd, newsletterSubscriptionAdd.meta) || newsletterSubscriptionAdd.meta.loading) {
            return <Loader/>;
        }

        return (
            <div className="content--message flex-center">

                {
                    newsletterSubscriptionAdd.meta.error &&
                    <p className="text--message">
                        Potwierdzenie rejestracji nie powiodło się, spróbuj ponownie
                    </p>
                }
                {
                    !newsletterSubscriptionAdd.meta.error &&
                    <p className="text--message">
                        Potwierdzono rejestrację <br/> w systemie powiadomień
                    </p>
                }

            </div>
        );
    };

    componentDidMount = () => {
        const { location, addNewsletterSubscription } = this.props;
        const token = queryParameterValue(location.search, "token");

        addNewsletterSubscription(token);
    };

}

const mapStateToProps = (state) => {
    return {
        newsletterSubscriptionAdd: state.entities.wasteManagement.newsletter.add
    };
};

export default connect(mapStateToProps, { addNewsletterSubscription })(NewsletterSubscribeConfirmation);