import { actions } from "../../../core/constants";
import { exportFile, extractErrorData, extractResponseData } from "../../../core/utils/api_utils";
import * as api from "../../_api";

export const getManyReports = (queryParams) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_REPORTS__GET_MANY__STARTED
    });

    return api
        .ekostraznik
        .reports
        .getManyReports(queryParams)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_REPORTS__GET_MANY__SUCCESS,
                meta: {},
                payload: response.data,
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_REPORTS__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getReport = (id) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_REPORTS__GET__STARTED
    });

    return api
        .ekostraznik
        .reports
        .getReport(id)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_REPORTS__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_REPORTS__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};


export const updateReport = (id, data) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_REPORTS__UPDATE__STARTED
    });

    return api
        .ekostraznik
        .reports
        .updateReport(id, data)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_REPORTS__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_REPORTS__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getReportTypes = () => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_REPORT_TYPES__GET__STARTED
    });

    return api
        .ekostraznik
        .reports
        .getReportTypes()
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_REPORT_TYPES__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_REPORT_TYPES__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const addReport = (data) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_REPORTS__ADD__STARTED
    });

    return api
        .ekostraznik
        .reports
        .addReport(data)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_REPORTS__ADD__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_REPORTS__ADD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getQuantitativeReport = (data) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_QUANTITATIVE_REPORT__GET__STARTED
    });

    return api
        .ekostraznik
        .reports
        .getQuantitativeReport(data)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_QUANTITATIVE_REPORT__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
            exportFile(response.data, 'raport_ilosciowy.pdf')
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_QUANTITATIVE_REPORT__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
}
