import { actions } from "../../../core/constants";
import * as api from "../../_api";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const getManyDraftMessages = (queryParams) => dispatch => {
    dispatch({
        type: actions.blisko.API__BLISKO__DRAFT_MESSAGES__GET_MANY__STARTED
    });

    return api
        .blisko
        .draftMessages
        .getMany(queryParams)
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__DRAFT_MESSAGES__GET_MANY__SUCCESS,
                meta: {},
                payload: response.data,
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__DRAFT_MESSAGES__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getDraftMessage = (id) => dispatch => {
    dispatch({
        type: actions.blisko.API__BLISKO__DRAFT_MESSAGES__GET__STARTED
    });

    return api
        .blisko
        .draftMessages
        .get(id)
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__DRAFT_MESSAGES__GET__SUCCESS,
                meta: {},
                payload: response.data,
                error: false
            });
            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__DRAFT_MESSAGES__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const addDraftMessage = (data) => dispatch => {
    dispatch({
        type: actions.blisko.API__BLISKO__DRAFT_MESSAGES__ADD__STARTED
    });

    return api
        .blisko
        .draftMessages
        .addDraftMessage(data)
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__DRAFT_MESSAGES__ADD__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });

            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__DRAFT_MESSAGES__ADD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });

            return error.response;
        });
};

export const updateDraftMessage = (id, data) => dispatch => {
    dispatch({
        type: actions.blisko.API__BLISKO__DRAFT_MESSAGES__UPDATE__STARTED
    });

    return api
        .blisko
        .draftMessages
        .updateDraftMessage(id, data)
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__DRAFT_MESSAGES__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });

            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__DRAFT_MESSAGES__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });

            return error.response;
        });
};

export const patchDraftMessage = (id, data) => dispatch => {
    dispatch({
        type: actions.blisko.API__BLISKO__DRAFT_MESSAGES__PATCH__STARTED
    });

    return api
        .blisko
        .draftMessages
        .patchDraftMessage(id, data)
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__DRAFT_MESSAGES__PATCH__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });

            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__DRAFT_MESSAGES__PATCH__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });

            return error.response;
        });
};

export const deleteDraftMessage = (id) => dispatch => {
    dispatch({
        type: actions.blisko.API__BLISKO__DRAFT_MESSAGES__DELETE__STARTED
    });

    return api
        .blisko
        .draftMessages
        .deleteDraftMessage(id)
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__DRAFT_MESSAGES__DELETE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__DRAFT_MESSAGES__DELETE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};