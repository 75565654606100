import webClient from "../../../core/web_client";
import { API_BASE_AKUN_GOK_PATH } from '../../../core/constants';

const PATH = `${ API_BASE_AKUN_GOK_PATH }/companies`;

export const getManyCompanies = () => {
    return webClient
        .get(`${ PATH }`);
};

export const getCompany = (id) => {
    return webClient
        .get(`${ PATH }/${ id }`);
};

export const addCompany = (data) => {
    return webClient
        .post(`${ PATH }`, data);
};

export const deleteCompany = (id) => {
    return webClient
        .delete(`${ PATH }/${ id }`);
};

export const updateCompany = (id, data) => {
    return webClient
        .put(`${ PATH }/${ id }`, data);
};

export const renameCompany = (id, data) => {
    return webClient
        .patch(`${ PATH }/${ id }`, data);
};