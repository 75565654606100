import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const getMessageAddresses = () => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__MESSAGE_ADDRESSES__GET_MANY__STARTED
    });

    return api
        .waste_management
        .messageAddresses
        .getMany()
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__MESSAGE_ADDRESSES__GET_MANY__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__MESSAGE_ADDRESSES__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });

}

export const addUnitsToMessageAddresses = (unitsId) => dispatch => {
    return executeUpdateAction(() =>
        api
            .waste_management
            .messageAddresses
            .addUnits(unitsId),
        dispatch
    );

};

export const addUnitsRangeToMessageAddresses = (unitIdFrom, unitIdTo) => dispatch => {
    return executeUpdateAction(() =>
        api
            .waste_management
            .messageAddresses
            .addUnitsRange(unitIdFrom, unitIdTo),
        dispatch
    );

};

export const addAreaToMessageAddresses = (areaId) => dispatch => {
    return executeUpdateAction(() =>
        api
            .waste_management
            .messageAddresses
            .addArea(areaId),
        dispatch
    );

};

export const removeUnitsFromMessageAddresses = (unitIds) => dispatch => {
    return executeUpdateAction(() =>
        api
            .waste_management
            .messageAddresses
            .removeUnits(unitIds),
        dispatch
    );
};

const executeUpdateAction = (action, dispatch) => {
    dispatch({
        type: actions.wasteManagement.API__MESSAGE_ADDRESSES__UPDATE__STARTED
    });

    return action()
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__MESSAGE_ADDRESSES__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__MESSAGE_ADDRESSES__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
}
