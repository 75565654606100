import { actions } from "../../../../core/constants";
import * as api from "../../../_api";
import { extractErrorData, extractResponseData } from "../../../../core/utils/api_utils";

export const getManyLocationsForAddressTemplate = (addressTemplateId, queryParams) => dispatch => {
    dispatch({
        type: actions.blisko.API__BLISKO__ADDRESS_TEMPLATE_LOCATIONS__GET__STARTED
    });

    return api
        .blisko
        .addressTemplateLocations
        .getManyLocationsForAddressTemplate(addressTemplateId, queryParams)
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__ADDRESS_TEMPLATE_LOCATIONS__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__ADDRESS_TEMPLATE_LOCATIONS__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const addOrRemoveLocations = (addressTemplateId, {
    unitIdsToAdd,
    unitIdsRangeToAdd,
    unitIdsToRemove
}) => dispatch => {
    dispatch({
        type: actions.blisko.API__BLISKO__ADDRESS_TEMPLATE_LOCATIONS__PATCH_MANY__STARTED
    });

    const promises = [];

    if (unitIdsToAdd?.length > 0) {
        promises.push(
            api
                .blisko
                .addressTemplateLocations
                .assignLocations(addressTemplateId, unitIdsToAdd)
        )
    }

    if (unitIdsRangeToAdd?.from && unitIdsRangeToAdd.to) {
        promises.push(
            api
                .blisko
                .addressTemplateLocations
                .addLocationsRange(addressTemplateId, unitIdsRangeToAdd.from, unitIdsRangeToAdd.to)
        )
    }

    if (unitIdsToRemove?.length > 0) {
        promises.push(
            api
                .blisko
                .addressTemplateLocations
                .removeLocations(addressTemplateId, unitIdsToRemove)
        )
    }

    return Promise.all(promises)
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__ADDRESS_TEMPLATE_LOCATIONS__PATCH_MANY__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__ADDRESS_TEMPLATE_LOCATIONS__PATCH_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
}