import { DEFAULT_STATE, DEFAULT_STATE__API__FAIL, DEFAULT_STATE__API__STARTED, DEFAULT_STATE__API__SUCCESS } from "../../../../../core/utils/store_utils";
import { actions } from "../../../../../core/constants";

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case actions.ekostraznik.API__INSPECTION_COMPANIES__ADD__STARTED:
            return DEFAULT_STATE__API__STARTED(state, DEFAULT_STATE);

        case actions.ekostraznik.API__INSPECTION_COMPANIES__ADD__SUCCESS:
            return DEFAULT_STATE__API__SUCCESS(state, DEFAULT_STATE, action);

        case actions.ekostraznik.API__INSPECTION_COMPANIES__ADD__FAIL:
            return DEFAULT_STATE__API__FAIL(state, DEFAULT_STATE, action);

        case actions.common.UI__AUTHENTICATION__SIGN_OUT:
            return DEFAULT_STATE;

        case actions.ekostraznik.INSPECTION_COMPANIES__RESET:
            return DEFAULT_STATE;

        default:
            return state;
    }
}