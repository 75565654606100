import { deepFreeze } from "../../utils/misc_utils";

export const SettingsEnum = deepFreeze({
    tab: {
        PASSWORD_CHANGE: "PASSWORD_CHANGE",
        CONTACT_DATA: "CONTACT_DATA",
        REGULATIONS: "REGULATIONS",
        ACCESSIBILITY_STATEMENT: "ACCESSIBILITY_STATEMENT",
    },
    status: {
        ACTIVE: "ACTIVE",
        PENDING: "PENDING",
        ARCHIVED: "ARCHIVAL"
    }
});