import React, { Component } from "react";
import { connect } from "react-redux";

import { setSidebarItemSelected } from "../../../../data/actions/common/ui/sidebar";
import { SidebarEnum } from "../../../../core/enums/common/sidebar";
import { PermissionEnum } from "../../../../core/enums/common/permissions";
import { isLoadingError, isNullOrUndefined } from "../../../../core/utils/misc_utils";
import withModuleMainPage from "../../../common/hoc/with_module_main_page";
import ModuleCategoryTile from "../../../common/module_category_tile";

class WasteManagementModule extends Component {

    render = () => {
        const { authentication, _onSubmenuItemSelected } = this.props;

        if (isLoadingError(authentication) ||
            isNullOrUndefined(authentication.data) ||
            isNullOrUndefined(authentication.data.permissions)) {
            return null;
        }

        const { permissions } = authentication.data;

        return (
            <div>
                <div className="container is-fluid">
                    <header className="header--basic">
                        <h1 className="title is-1">
                            { SidebarEnum.WASTE_MANAGEMENT.name }
                        </h1>
                        <p>Wybierz kategorię</p>
                    </header>
                    <main>
                        <div className=''>
                            <div className='columns is-multiline'>

                                {
                                    permissions[PermissionEnum.module.WASTE_MANAGEMENT.type.MUNICIPAL_WASTE.full_code] &&
                                    <ModuleCategoryTile
                                        to={SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.MUNICIPAL_WASTE.link}
                                        name={SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.MUNICIPAL_WASTE.name}
                                        description={SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.MUNICIPAL_WASTE.description}
                                        cssSubclass='management'
                                        onClick={() => _onSubmenuItemSelected(
                                            SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.id,
                                            SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.MUNICIPAL_WASTE.id
                                        )}
                                    />
                                }

                                {
                                    permissions[PermissionEnum.module.WASTE_MANAGEMENT.type.BULKY_WASTE.full_code] &&
                                    <ModuleCategoryTile
                                        to={SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.BULKY_WASTE.link}
                                        name={SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.BULKY_WASTE.name}
                                        description={SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.BULKY_WASTE.description}
                                        cssSubclass='management'
                                        onClick={() => _onSubmenuItemSelected(
                                            SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.id,
                                            SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.BULKY_WASTE.id
                                        )}
                                    />
                                }

                                {
                                    permissions[PermissionEnum.module.WASTE_MANAGEMENT.type.WASTE_COLLECTION_POINTS.full_code] &&
                                    <ModuleCategoryTile
                                        to={SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.WASTE_COLLECTION_POINTS.link}
                                        name={SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.WASTE_COLLECTION_POINTS.name}
                                        description={SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.WASTE_COLLECTION_POINTS.description}
                                        cssSubclass='management'
                                        onClick={() => _onSubmenuItemSelected(
                                            SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.id,
                                            SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.WASTE_COLLECTION_POINTS.id
                                        )}
                                    />
                                }

                                {
                                    permissions[PermissionEnum.module.WASTE_MANAGEMENT.type.MESSAGES.full_code] &&
                                    <ModuleCategoryTile
                                        to={SidebarEnum.WASTE_MANAGEMENT.MESSAGES.SEND_MESSAGE.link}
                                        name={SidebarEnum.WASTE_MANAGEMENT.MESSAGES.SEND_MESSAGE.name}
                                        description={SidebarEnum.WASTE_MANAGEMENT.MESSAGES.SEND_MESSAGE.description}
                                        cssSubclass='messages'
                                        onClick={() => _onSubmenuItemSelected(
                                            SidebarEnum.WASTE_MANAGEMENT.MESSAGES.id,
                                            SidebarEnum.WASTE_MANAGEMENT.MESSAGES.SEND_MESSAGE.id
                                        )}
                                    />

                                }

                                {
                                    permissions[PermissionEnum.module.WASTE_MANAGEMENT.type.MESSAGES.full_code] &&
                                    <ModuleCategoryTile
                                        to={SidebarEnum.WASTE_MANAGEMENT.MESSAGES.SENT_MESSAGES.link}
                                        name={SidebarEnum.WASTE_MANAGEMENT.MESSAGES.SENT_MESSAGES.name}
                                        description={SidebarEnum.WASTE_MANAGEMENT.MESSAGES.SENT_MESSAGES.description}
                                        cssSubclass='messages'
                                        onClick={() => _onSubmenuItemSelected(
                                            SidebarEnum.WASTE_MANAGEMENT.MESSAGES.id,
                                            SidebarEnum.WASTE_MANAGEMENT.MESSAGES.SENT_MESSAGES.id
                                        )}
                                    />
                                }

                                {
                                    permissions[PermissionEnum.module.WASTE_MANAGEMENT.type.REPORTS.full_code] &&
                                    <ModuleCategoryTile
                                        to={SidebarEnum.WASTE_MANAGEMENT.REPORTS.link}
                                        name={SidebarEnum.WASTE_MANAGEMENT.REPORTS.name}
                                        description={SidebarEnum.WASTE_MANAGEMENT.REPORTS.description}
                                        cssSubclass='reports'
                                        onClick={() => _onSubmenuItemSelected(SidebarEnum.WASTE_MANAGEMENT.REPORTS.id)}
                                    />
                                }

                                {
                                    permissions[PermissionEnum.module.WASTE_MANAGEMENT.type.AREAS.full_code] &&
                                    <ModuleCategoryTile
                                        to={SidebarEnum.WASTE_MANAGEMENT.AREAS.link}
                                        name={SidebarEnum.WASTE_MANAGEMENT.AREAS.name}
                                        description={SidebarEnum.WASTE_MANAGEMENT.AREAS.description}
                                        cssSubclass='areas'
                                        onClick={() => _onSubmenuItemSelected(SidebarEnum.WASTE_MANAGEMENT.AREAS.id)}
                                    />
                                }

                                {
                                    permissions[PermissionEnum.module.WASTE_MANAGEMENT.type.PAYMENTS_DUE_DATE.full_code] &&
                                    <ModuleCategoryTile
                                        to={SidebarEnum.WASTE_MANAGEMENT.PAYMENTS_DUE_DATE.link}
                                        name={SidebarEnum.WASTE_MANAGEMENT.PAYMENTS_DUE_DATE.name}
                                        description={SidebarEnum.WASTE_MANAGEMENT.PAYMENTS_DUE_DATE.description}
                                        cssSubclass='payments'
                                        onClick={() => _onSubmenuItemSelected(SidebarEnum.WASTE_MANAGEMENT.PAYMENTS_DUE_DATE.id)}
                                    />
                                }

                                {
                                    permissions[PermissionEnum.module.WASTE_MANAGEMENT.type.COMPANIES.full_code] &&
                                    <ModuleCategoryTile
                                        to={SidebarEnum.WASTE_MANAGEMENT.COMPANIES.link}
                                        name={SidebarEnum.WASTE_MANAGEMENT.COMPANIES.name}
                                        description={SidebarEnum.WASTE_MANAGEMENT.COMPANIES.description}
                                        cssSubclass='companies'
                                        onClick={() => _onSubmenuItemSelected(SidebarEnum.WASTE_MANAGEMENT.COMPANIES.id)}
                                    />
                                }

                                {
                                    permissions[PermissionEnum.module.WASTE_MANAGEMENT.type.COMMUNAL_DOCUMENTS.full_code] &&
                                    <ModuleCategoryTile
                                        to={SidebarEnum.WASTE_MANAGEMENT.COMMUNAL_DOCUMENTS.link}
                                        name={SidebarEnum.WASTE_MANAGEMENT.COMMUNAL_DOCUMENTS.name}
                                        description={SidebarEnum.WASTE_MANAGEMENT.COMMUNAL_DOCUMENTS.description}
                                        cssSubclass='communal_documents'
                                        onClick={() => _onSubmenuItemSelected(SidebarEnum.WASTE_MANAGEMENT.COMMUNAL_DOCUMENTS.id)}
                                    />
                                }

                            </div>
                        </div>
                    </main>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        authentication: state.authentication
    };
};

export default withModuleMainPage(connect(mapStateToProps, { setSidebarItemSelected })(WasteManagementModule));
