import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { isLoading, isLoadingError, isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";
import { getManyServiceCards } from "../../../../../../../data/actions/services/service_cards";
import Select from "../../../../../../common/select";

class ServicesCardAdditionalInfoRelatedServicesContent extends Component {

    render = () => {
        const { form, options, value, serviceCards, _setValue } = this.props;

        return (
            <div className="column is-half field">
                <label>
                    <span className="label-name">Powiązane karty</span>
                    <Select
                        isMulti
                        isSearchable
                        isDisabled={ isLoadingError(serviceCards) || isLoading(serviceCards) }
                        isLoading={ isLoading(serviceCards) }
                        placeholder={ "- Wybierz z listy powiązane tematycznie karty -" }
                        options={ options }
                        value={ value }
                        onChange={ (selectedOptions) => {
                            _setValue(selectedOptions);
                            form.setFieldValue("relatedServiceCardIds", (selectedOptions || []).reduce((ids, option) => [...ids, option.value.id], []));
                        } }
                    />
                </label>
            </div>
        );
    };

    componentDidMount = () => {
        const { field, getManyServiceCards, _setOptionsAndValue } = this.props;

        getManyServiceCards()
            .then(() => {
                const { serviceCards } = this.props;

                if (!isNullOrUndefined(serviceCards) && !isNullOrUndefined(serviceCards.data)) {
                    const options = _.map(serviceCards.data, serviceCard => {
                        return {
                            label: serviceCard.title,
                            value: serviceCard
                        };
                    });

                    const values = field
                        .value
                        .map(relatedServiceCard => {
                            const serviceCard = serviceCards.data[relatedServiceCard];
                            return {
                                label: serviceCard.title,
                                value: serviceCard
                            };
                        })
                        .filter(relatedServiceCard => !isNullOrUndefined(relatedServiceCard));

                    _setOptionsAndValue(options, values);
                }
            });
    };
}

const mapStateToProps = (state) => {
    return {
        serviceCards: state.entities.services.serviceCards.getMany
    };
};

export default connect(mapStateToProps, { getManyServiceCards })(ServicesCardAdditionalInfoRelatedServicesContent);