import webClient from "../../../core/web_client";
import { API_BASE_AKUN_ADMIN_PATH } from "../../../core/constants";

const PATH = `${ API_BASE_AKUN_ADMIN_PATH }/password-reset-requests`;

export const addPasswordResetRequest = (email) => {

    return webClient
        .post(`${ PATH }`, email);
};

export const checkTokenStatus = (token) => {
    return webClient
        .get(`${ PATH }/${ token }`);
};

export const updatePasswordResetRequest = (password, token) => {
    return webClient
        .put(`${ PATH }/${ token }`, password);
};