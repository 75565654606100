import webClient from "../../../core/web_client";
import { API_BASE_AKUN_GOK_PATH } from '../../../core/constants';
import { buildQueryString } from "../../../core/utils/api_utils";

const PATH = `${ API_BASE_AKUN_GOK_PATH }/payment-periods`;

export const getPaymentsDueDate = (data) => {
    return webClient
        .get(`${ PATH }${buildQueryString(data)}`);
};

export const updatePaymentsDueDate = (data) => {
    return webClient
        .post(`${ PATH }`, data);
};