import _ from "lodash";

import { isNullOrUndefined } from "./misc_utils";
import { MISC_ADDRESS_REGEX } from "../constants";


// --------------------------------------------------------------------------------------------------------------------- address sorting

export const sortAddresses = (addresses, wrapped = false) => {
    if (isNullOrUndefined(addresses) || addresses.length <= 1) {
        return addresses;
    }

    const deconstructedAddresses = deconstructAddresses(addresses, wrapped);

    const sortedAddresses = _
        .sortBy(deconstructedAddresses, ["number", "extra"]);

    return sortedAddresses
        .map(wrappedAddress => {
            return wrappedAddress.address;
        });
};

// --------------------------------------------------------------------------------------------------------------------- address deconstruction

const deconstructAddresses = (addresses, wrapped = false) => {
    if (isNullOrUndefined(addresses)) {
        return addresses;
    }

    return addresses
        .map(address => deconstructAddress(address, wrapped));
};

const deconstructAddress = (address, wrapped = false) => {
    if (isNullOrUndefined(address)) {
        return address;
    }

    try {
        const groups = wrapped
            ? MISC_ADDRESS_REGEX.exec(address.data.number).slice(1, 6)
            : MISC_ADDRESS_REGEX.exec(address.number).slice(1, 6);

        return {
            number: parseInt(groups[0]),
            extra: groups[1],
            address: address
        };
    } catch (error) {
        return {
            number: "",
            extra: wrapped ? address.data.number : address.number,
            address: address
        };
    }
};