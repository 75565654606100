import webClient from "../../../core/web_client";
import { API_BASE_AKUN_EKOSTRAZNIK_PATH } from "../../../core/constants";

const PATH_ADDRESS_GROUPS = `${ API_BASE_AKUN_EKOSTRAZNIK_PATH }/address-groups`;
const PATH = "units";

export const deleteAddresses = (groupId, unitIds) => {
    return webClient
        .patch(`${ PATH_ADDRESS_GROUPS }/${ groupId }/${ PATH }`, {
            command: "remove-assignments",
            unitIds
        })
}

export const addAddresses = (groupId, unitIds, reassignAddresses) => {
    return webClient
        .patch(`${ PATH_ADDRESS_GROUPS }/${ groupId }/${ PATH }`, {
            command: "assign-addresses",
            unitIds,
            reassignAddresses
        })
}

export const addAddressesByRange = (groupId, unitIdFrom, unitIdTo, reassignAddresses) => {
    return webClient
        .patch(`${ PATH_ADDRESS_GROUPS }/${ groupId }/${ PATH }`, {
            command: "assign-address-range",
            unitIdFrom,
            unitIdTo,
            reassignAddresses
        })
}

export const addApartmentAddresses = (groupId, unitIds, apartmentAddresses, addNonExisting) => {
    return webClient
        .patch(`${ PATH_ADDRESS_GROUPS }/${ groupId }/${ PATH }`, {
            command: "assign-apartment-addresses",
            apartmentAddresses,
            unitIds,
            addNonExisting
        })
}