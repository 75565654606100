import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import Loader from "../../../../../../../common/loader";
import { getManyAreas } from "../../../../../../../../data/actions/waste_management/areas";
import { isEmptyArray } from "../../../../../../../../core/utils/misc_utils";
import Select from "../../../../../../../common/select";

class AreaAddresses extends Component {

    state = {
        selectedArea: null,
        areaOptions: []
    };

    render = () => {
        const { _onAreaAdd } = this.props;
        const { selectedArea, areaOptions } = this.state;

        if (isEmptyArray(areaOptions)) {
            return (
                <Loader/>
            );
        }

        return (
            <div className="message-sending-1__row message-sending-1__row--flex is-spaced--top-10">
                <label className="message-sending-1__input-wrap message-sending-1__input-wrap--select">
                    <span className="location-form__label">Wybierz obszar</span>
                    <Select
                        options={ areaOptions }
                        value={ selectedArea }
                        isClearable={ false }
                        onChange={ areaOption => this._onAreaOptionSelect(areaOption) }/>
                </label>
                <button className="btn btn--primary message-sending-1__button is-spaced--bottom-20"
                        type="button"
                        disabled={ !this.state.selectedArea }
                        onClick={ () => _onAreaAdd(selectedArea.value.id) }>
                    Dodaj do listy
                </button>
            </div>
        );
    };

    componentDidMount = () => {
        const { getManyAreas } = this.props;

        getManyAreas()
            .then(() => {
                const areaOptions = this.generateAreaOptions();
                this.setState({
                    areaOptions
                });

                return areaOptions;
            })
            .then(areaOptions => {
                if (!isEmptyArray(areaOptions)) {
                    this._onAreaOptionSelect(areaOptions[0]);
                }
            });
    };

    generateAreaOptions = () => {
        const { areas } = this.props;

        if (!areas?.data) {
            return [];
        }

        return _.map(areas.data, area => {
            return {
                label: area.name,
                value: area
            };
        });
    };

    _onAreaOptionSelect = (areaOption) => {
        this.setState({
            selectedArea: areaOption
        });
    };
}

const mapStateToProps = (state) => {
    return {
        areas: state.entities.wasteManagement.areas?.getMany,
    };
};

export default connect(mapStateToProps, { getManyAreas })(AreaAddresses);