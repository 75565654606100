import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReceiverGroupsList from "./list";
import ReceiverGroupsHeader from "./list/header";
import { getOwnerConfiguration } from "../../../../../data/actions/common/owner";

const ReceiverGroups = () => {
    const receiverGroups = useSelector(state => state.entities?.blisko?.receiverGroups?.getMany);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOwnerConfiguration())
    }, [dispatch])

    return (
        <div className="main-content--table">
            <ReceiverGroupsHeader
                size={ receiverGroups?.data?.numberOfElements }
            />

            <ReceiverGroupsList
                receiverGroups={ receiverGroups }
            />
        </div>
    );
};

export default ReceiverGroups;