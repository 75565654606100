import React, { Component } from "react";
import { connect } from "react-redux";
import FormModal from "../../../../../../common/modal/form_modal";
import RegulationsDeleteFormContent from "./content";
import { ReactComponent as IconDelete } from "../../../../../../../../resource/img/icons/icon_delete.svg";
import { deleteRegulation, getRegulations } from "../../../../../../../data/actions/common/regulations";
import { toastFor500, toastForSuccess } from "../../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../../data/actions/common/ui/toast";


class RegulationDelete extends Component {

    render = () => {
        return (
            <FormModal
                triggerComponent={ <button className='unstyled-btn'><IconDelete/></button> }
                deleteOrCancelButtons={ true }
                _actionFunction={ () => {
                    return this._onSubmit();
                } }>
                <RegulationsDeleteFormContent/>
            </FormModal>
        );
    };

    _onSubmit = () => {
        const { id, deleteRegulation, getRegulations } = this.props;

        return deleteRegulation(id)
            .then(() => getRegulations())
            .then(() => {
                const { showToast, regulationsDelete } = this.props;

                toastFor500(showToast, regulationsDelete, "Nie udało się usunąć regulaminu. Spróbuj ponownie później");
                toastForSuccess(showToast, regulationsDelete, "Regulamin został usunięty");
            });
    };
}

const mapStateToProps = (state) => {
    return {
        regulationsDelete: state.entities.common.regulations.delete
    };
};

export default connect(mapStateToProps, { deleteRegulation, getRegulations, showToast })(RegulationDelete);
