import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import CompaniesItem from "./item";

import CompaniesCreateTrigger from "./create_trigger";
import CompaniesCreateAndEditModalForm from "./create_and_edit_modal_form";
import { getManyCompanies } from "../../../../../../data/actions/waste_management/companies";
import Loader from "../../../../../common/loader";

class CompaniesList extends Component {

    render = () => {
        const { companiesList } = this.props;

        if (companiesList.meta.loading) {
            return <Loader/>;
        }

        return (
            <div className='tiles-wrapper'>
                <CompaniesCreateAndEditModalForm
                    triggerComponent={ <CompaniesCreateTrigger/> }
                    ariaLabel="Dodaj firmę"
                    headerText="Dodaj firmę"
                    _onSubmitCallback={ this._loadCompanies }/>
                {
                    this.renderListItems()
                }
            </div>
        );
    };

    componentDidMount = () => {
        this._loadCompanies();
    };

    renderListItems = () => {
        const { companiesList } = this.props;
        const sortedCompaniesList = _.sortBy(companiesList.data, [company => company.name.toLowerCase(), "name"]);

        return _.map(sortedCompaniesList, company => {
            return (
                <CompaniesItem
                    key={ company.id }
                    company={ company }
                    _onSubmitCallback={ this._loadCompanies }/>
            );
        });
    };

    _loadCompanies = () => {
        return this.props.getManyCompanies();
    };
}

const mapStateToProps = (state) => {
    return {
        companiesList: state.entities.wasteManagement.companies.getMany
    };
};

export default connect(mapStateToProps, { getManyCompanies })(CompaniesList);
