import React from "react";

const HeaderUnderlined = ({ text, children }) => {

    return (
        <div className='header--underlined space-between'>
            <h2 className='title'>
                {
                    text
                }
            </h2>
            <div className="header__action-btns">
                {
                    children
                }
            </div>
        </div>
    );
};

export default HeaderUnderlined;
