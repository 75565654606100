import { actions } from "../../../core/constants";
import * as api from "../../_api";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const getManyAddressesFromAddressGroup = (id, queryParams) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_ADDRESSES_ADDRESS_GROUPS__GET_ADDRESSES___STARTED
    });

    return api
        .ekostraznik
        .addresses
        .getMany(id, queryParams)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES_ADDRESS_GROUPS__GET_ADDRESSES__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES_ADDRESS_GROUPS__GET_ADDRESSES__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};