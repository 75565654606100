import React from "react";
import { Link } from "react-router-dom";

import { ROUTE_ACCOUNTS } from "../../../../../core/constants";
import { EmployeesEnum } from "../../../../../core/enums/common/employees";
import { ReactComponent as IconBack } from "../../../../../../resource/img/icons/icon_back_blue.svg";

const AccountDetailsHeader = ({ employee }) => {
    return (
        <header className='page-header page-header--with-back page-header--no-sidebar'>
            <Link to={ ROUTE_ACCOUNTS } className="unstyled-btn">
                <IconBack/>
            </Link>
            <h1 className='title is-1'>
                {
                    employee.position === EmployeesEnum.position.MODERATOR &&
                    "Konto moderatora"
                }
                {
                    employee.position === EmployeesEnum.position.ADMINISTRATOR &&
                    "Konto administratora"
                }
            </h1>
        </header>
    );
};

export default AccountDetailsHeader;