import React from "react";
import moment from "moment";

import { isNullOrUndefined } from "../../../core/utils/misc_utils";
import { MISC_DATE_ISO_DATE_ONLY_FORMAT, MISC_DATE_ISO_TIME_ONLY_FORMAT } from "../../../core/constants";

const ShipmentDate = ({ date, time }) => {

    const formattedDate =
        !isNullOrUndefined(date)
            ? date
            : moment().format(MISC_DATE_ISO_DATE_ONLY_FORMAT);

    const formattedTime =
        !isNullOrUndefined(time)
            ? time.substring(0, 5)
            : moment().format(MISC_DATE_ISO_TIME_ONLY_FORMAT).substring(0, 5);

    return (
        <div className="notifications-2__row notifications-2__row--flex">
            <p className="notifications-2__label">Termin wysyłki:</p>
            <div className="notifications-2__box">
                <p>
                    <span>{ formattedDate }</span> <span className="text-separator">|</span> <span>{ formattedTime }</span>
                </p>
            </div>
        </div>
    );
};

export default ShipmentDate;