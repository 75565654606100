import React, { Component } from "react";
import { connect } from "react-redux";
import { isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";
import _ from "lodash";
import { getManyBins } from "../../../../../../../data/actions/ekostraznik/bins";
import Select from "../../../../../../common/select";


class InspectionBinType extends Component {

    render = () => {
        const { errors, formikBag } = this.props;
        const binOptions = this.generateBinOptions();

        return (
            <label className="is-spaced--top-20">
                <span className="label-name"><span className="required-accent">*</span>Rodzaj frakcji</span>
                <div className="is-relative full-width">
                    <div>
                        <Select
                            value={ formikBag.values.binCode ? undefined : null }
                            options={ binOptions }
                            onChange={ this._onSelectChange }
                        />
                    </div>
                    {
                        !isNullOrUndefined(formikBag.errors.binCode) &&
                        <span className="hint hint--rejected">
                            {
                                errors.binCode
                            }
                        </span>
                    }
                </div>
            </label>
        )
    };

    componentDidMount = () => {
        this.props.getManyBins();
    };

    generateBinOptions = () => {
        const { bins } = this.props;

        if (isNullOrUndefined(bins.data)) {
            return [];
        }

        return _.map(bins.data, bin => {
            return {
                label: bin.name,
                value: bin,
            };
        });
    };

    _onSelectChange = (selectedOption) => {
        const { formikBag } = this.props;

        formikBag.setFieldValue("binCode", selectedOption?.value.code);
    };
}


const mapStateToProps = (state) => {
    return {
        bins: state.entities.ekostraznik.bins.getMany,
    };
};

export default connect(mapStateToProps, { getManyBins })(InspectionBinType);

