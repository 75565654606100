import React, { Component } from "react";

import Addresses from "./addresses";
import ShipmentDate from "./shipment_date";
import { BottomCustomButtons } from "../../../../../../common/bottom_action_buttons";
import FormRequiredLegend from "../../../../../../common/legend_required";
import MessageContent from "../../../../../../common/message_content_form";

class Step1 extends Component {

    render = () => {
        const {
            useGeoLocation, useLocationFilter, sendImmediately, currentStep, values, uploadedFiles,
            defaultCoordinates, setFieldValue, errors, _useGeoLocationToggle, _useLocationFilterChange,
            _sendImmediatelyChange, _alternativeTextChange, _onFileUploadStarted, _onDeleteUploadedFileClick, _onNextClick
        } = this.props;

        if (currentStep !== 1) {
            return null;
        }

        return (
            <main className="message-sending-1">
                <div className="content--m content--not-centered">
                    <div className="is-relative">
                        <FormRequiredLegend/>
                    </div>
                    <MessageContent
                        body={ values.body }
                        useGeoLocation={ useGeoLocation }
                        useLocationFilter={ useLocationFilter }
                        uploadedFiles={ uploadedFiles }
                        latitude={ values.latitude }
                        longitude={ values.longitude }
                        defaultCoordinates={ defaultCoordinates }
                        setFieldValue={ setFieldValue }
                        errors={ errors }
                        step={"1/3"}
                        _useGeoLocationToggle={ _useGeoLocationToggle }
                        _onFileUploadStarted={ _onFileUploadStarted }
                        _onDeleteUploadedFileClick={ _onDeleteUploadedFileClick }
                        _onAlternativeTextChanged={ _alternativeTextChange }
                        _onMessageContentChanged={ this._onMessageContentChanged }/>

                    <Addresses
                        useLocationFilter={ useLocationFilter }
                        _useLocationFilterChange={ _useLocationFilterChange }/>

                    <ShipmentDate
                        date={ values.date }
                        time={ values.time }
                        sendImmediately={ sendImmediately }
                        setFieldValue={ setFieldValue }
                        _sendImmediatelyChange={ _sendImmediatelyChange }/>
                </div>

                <BottomCustomButtons
                    containerClass="content--m content--not-centered"
                    buttonSpacingClass="bottom-right"
                >
                    <button className="btn btn--primary btn--medium"
                            onClick={ _onNextClick }>
                        Dalej
                        <div className="btn__arrow"/>
                    </button>
                </BottomCustomButtons>
            </main>
        );
    };

    _onMessageContentChanged = (value) => {
        const { setFieldValue } = this.props;
        setFieldValue("body", value);
    };
}

export default Step1;