import { actions } from "../../../core/constants";
import * as api from "../../_api";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const addNest = (addressGroupId, data) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_ADDRESSES__ADDRESS_GROUPS__ADD_NEST__STARTED
    });

    return api
        .ekostraznik
        .addressGroupsNests
        .add(addressGroupId, data)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__ADDRESS_GROUPS__ADD_NEST__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__ADDRESS_GROUPS__ADD_NEST__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
            return error.response;
        });
};

export const updateNest = (addressGroupId, nestId, data) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_ADDRESSES__ADDRESS_GROUPS__UPDATE_NEST__STARTED
    });

    return api
        .ekostraznik
        .addressGroupsNests
        .update(addressGroupId, nestId, data)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__ADDRESS_GROUPS__UPDATE_NEST__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__ADDRESS_GROUPS__UPDATE_NEST__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const deleteNest = (addressGroupId, nestId) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_ADDRESSES__ADDRESS_GROUPS__DELETE_NEST__STARTED
    });

    return api
        .ekostraznik
        .addressGroupsNests
        .del(addressGroupId, nestId)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__ADDRESS_GROUPS__DELETE_NEST__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__ADDRESS_GROUPS__DELETE_NEST__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

