import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import ComponentWithDropdown from "../../../../../common/component_with_dropdown";
import ContainerExpirationForm from "./container_expiration_form";
import BulkyWasteCreateAndEditModalForm from "./create_and_edit_modal_form";

import { ReactComponent as IconEllipsis } from "../../../../../../../resource/img/icons/icon_ellipsis.svg";
import { ReactComponent as IconSearch } from "../../../../../../../resource/img/icons/icon_search.svg";
import { ReactComponent as IconCheckCircle } from "../../../../../../../resource/img/icons/icon_check_circle.svg";
import { ReactComponent as IconMapShow } from "../../../../../../../resource/img/icons/icon_map_show.svg";
import { BulkyWasteEnum } from "../../../../../../core/enums/waste_management/bulky_waste";
import { isEmptyArray, isNullOrUndefined } from "../../../../../../core/utils/misc_utils";
import { getManyBulkyWaste, publishManyBulkyWaste } from "../../../../../../data/actions/waste_management/bulky_waste";
import { MISC_SHOW_UNFINISHED_ELEMENTS } from "../../../../../../core/constants";
import { toastFor500, toastForSuccess } from "../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../data/actions/common/ui/toast";

class BulkyWasteHeader extends ComponentWithDropdown {

    render = () => {
        const { dropdownOpen = false } = this.state;
        const {
            activeTab, searchBarTerm, _onTabSelect, _onSearchBarInputChange, newBulkyWaste, bulkyWasteDataLoading
        } = this.props;

        const invisibleBulkyWasteIds =
            !isNullOrUndefined(newBulkyWaste)
                ? _.map(newBulkyWaste, newBulkyWasteItem => newBulkyWasteItem.id)
                : [];

        return (
            <section className='main-content--table__top'>
                <header className='row page-header'>
                    <h1 className='title is-1'>
                        Odpady wielkogabarytowe
                    </h1>
                </header>

                <section className='container is-fluid'>
                    <div className="row row--options">
                        <div className='full-width'>
                            <div className="tabs">
                                <ul className="tabs-list">
                                    <li onClick={ () => _onTabSelect(BulkyWasteEnum.tab.CURRENT) }
                                        className={ `tabs-item ${ activeTab === BulkyWasteEnum.tab.CURRENT ? "is-active" : "" }` }>
                                        <span className="tab-item">Aktualne</span>
                                    </li>
                                    <li onClick={ () => _onTabSelect(BulkyWasteEnum.tab.ARCHIVED) }
                                        className={ `tabs-item ${ activeTab === BulkyWasteEnum.tab.ARCHIVED ? "is-active" : "" }` }>
                                        <span className="tab-item">Archiwalne</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='container is-fluid'>
                    <div className="row row--separated row--options">
                        <div className='row__left scrollable'>
                            <div className="input-field header__search with-icon--right">
                                <input placeholder='Znajdź kontener - wpisz adres'
                                       type='text'
                                       value={ searchBarTerm }
                                       onChange={ event => _onSearchBarInputChange(event.target.value) }/>
                                <IconSearch className='input-icon'/>
                            </div>
                        </div>
                        {
                            activeTab === BulkyWasteEnum.tab.CURRENT && (
                                <div className='row__right'>
                                    <button className='btn btn--big btn--primary header__main-cta-btn'
                                            disabled={ isEmptyArray(invisibleBulkyWasteIds) || !!bulkyWasteDataLoading }
                                            onClick={ () => this._onPublishClick(invisibleBulkyWasteIds) }>
                                            <span className="btn__icon-wrapper--left">
                                                <span className='btn__icon'>
                                                    <IconCheckCircle/>
                                                </span>
                                                Opublikuj
                                            </span>
                                    </button>
                                    <BulkyWasteCreateAndEditModalForm
                                        triggerText='Dodaj kontener'
                                        triggerClass='btn btn--outlined'
                                        ariaLabel='Dodaj kontener'
                                        headerText='Dodaj kontener'
                                        saveOrStayOrCancelButtons={ true }/>


                                    <div className={ `dropdown  ${ dropdownOpen ? "is-active" : null }` }>
                                        <div className="dropdown-trigger">
                                            <button className="btn btn--outlined btn--icon" title='Więcej'
                                                    onClick={ this._onDropdownOpen }>
                                                <IconEllipsis/>
                                            </button>
                                        </div>
                                        <div className="dropdown-menu dropdown-size-m drop-to-right">
                                            <div className="dropdown-content">

                                                <ContainerExpirationForm
                                                    triggerComponent={ <span className="unstyled-btn dropdown-item">Czas postoju kontenerów</span> }
                                                    headerText={ "Czas postoju kontenerów" }/>

                                                {
                                                    MISC_SHOW_UNFINISHED_ELEMENTS &&
                                                    <span className="unstyled-btn dropdown-item">Importuj szablon</span>
                                                }

                                                {
                                                    MISC_SHOW_UNFINISHED_ELEMENTS &&
                                                    <span className="unstyled-btn dropdown-item">Pobierz szablon</span>
                                                }

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                    </div>
                </section>

                {
                    MISC_SHOW_UNFINISHED_ELEMENTS &&
                    <section className='container is-fluid'>
                        <div className="side-map-toggle row--end">
                            <button className="btn btn--narrow is-uppercase">
                            <span className="btn__icon-wrapper--right">
                                Pokaż mapę
                                <span className='btn__icon'>
                                    <IconMapShow/>
                                </span>
                            </span>
                            </button>
                        </div>
                    </section>
                }

            </section>
        );
    };

    _onPublishClick = (invisibleBulkyWasteIds) => {
        const { publishManyBulkyWaste, getManyBulkyWaste, bulkyWastePublish, showToast } = this.props;

        return publishManyBulkyWaste(invisibleBulkyWasteIds)
            .then(() => getManyBulkyWaste())
            .then(() => {
                toastFor500(showToast, bulkyWastePublish, "Publikacja nie powiodła się. Spróbuj ponownie później");
                toastForSuccess(showToast, bulkyWastePublish, "Informacje zostały opublikowane");
            });
    }
}

const mapStateToProps = (state) => {
    return {
        bulkyWastePublish: state.entities.wasteManagement.bulkyWaste.publishMany
    };
};

export default connect(mapStateToProps, { getManyBulkyWaste, publishManyBulkyWaste, showToast })(BulkyWasteHeader);
