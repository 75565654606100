import React, { Fragment } from "react";
import _ from "lodash";

import WasteCollectionPointsCreateAndEditModalForm from "../create_and_edit_modal_form";
import WasteCollectionPointDeleteModalForm from "./delete_modal_form";
import { isEmptyArray, isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";
import { WasteCollectionPointsEnum } from "../../../../../../../core/enums/waste_management/waste_collection_points";

import { ReactComponent as IconBattery } from "../../../../../../../../resource/img/icons/collection_points/icon_battery.svg";
import { ReactComponent as IconPszok } from "../../../../../../../../resource/img/icons/collection_points/icon_pszok.svg";
import { ReactComponent as IconElectric } from "../../../../../../../../resource/img/icons/collection_points/icon_electric.svg";
import { ReactComponent as IconLightbulb } from "../../../../../../../../resource/img/icons/collection_points/icon_lightbulb.svg";
import { ReactComponent as IconMedicine } from "../../../../../../../../resource/img/icons/collection_points/icon_medicine.svg";
import { ReactComponent as IconSyringe } from "../../../../../../../../resource/img/icons/collection_points/icon_syringe.svg";
import { ReactComponent as IconContainerBlack } from "../../../../../../../../resource/img/icons/collection_points/icon_container--black.svg";
import { ReactComponent as IconContainerBlue } from "../../../../../../../../resource/img/icons/collection_points/icon_container--blue.svg";
import { ReactComponent as IconContainerGreen } from "../../../../../../../../resource/img/icons/collection_points/icon_container--green.svg";
import { ReactComponent as IconContainerYellow } from "../../../../../../../../resource/img/icons/collection_points/icon_container--yellow.svg";
import { ReactComponent as IconContainerCeladon } from "../../../../../../../../resource/img/icons/collection_points/icon_container--celadon.svg";
import { ReactComponent as IconContainerWhite } from "../../../../../../../../resource/img/icons/collection_points/icon_container--white.svg";
import { ReactComponent as IconBio } from "../../../../../../../../resource/img/icons/collection_points/icon_bio.svg";
import { ReactComponent as IconOil } from "../../../../../../../../resource/img/icons/collection_points/icon_oil.svg";
import { ReactComponent as IconFoils } from "../../../../../../../../resource/img/icons/collection_points/icon_foils.svg";
import { ReactComponent as IconTyres } from "../../../../../../../../resource/img/icons/collection_points/icon_tyres.svg";
import { ReactComponent as IconRopes } from "../../../../../../../../resource/img/icons/collection_points/icon_ropes.svg";
import { ReactComponent as IconPlasticCap } from "../../../../../../../../resource/img/icons/collection_points/icon_plastic_cap.svg";
import { ReactComponent as IconScrap } from "../../../../../../../../resource/img/icons/collection_points/icon_scrap.svg";

import { ReactComponent as IconEdit } from "../../../../../../../../resource/img/icons/icon_edit.svg";
import { ReactComponent as IconDelete } from "../../../../../../../../resource/img/icons/icon_delete.svg";
import { MISC_SHOW_UNFINISHED_ELEMENTS } from "../../../../../../../core/constants";

const WasteCollectionPointsListItem = ({ wasteCollectionPoint, _onSubmitCallback, showUnpublishedBadge }) => {

    return (
        <tr>
            <td className='t-width-4'>

                {
                    MISC_SHOW_UNFINISHED_ELEMENTS &&
                    <div className='t-checkbox'>
                        <label className='acceptance-label'>
                            <input type='checkbox' className='acceptance-checkbox'/>
                        </label>
                    </div>
                }

                {
                    renderCollectionPointsLocation(wasteCollectionPoint, showUnpublishedBadge)
                }
            </td>

            <td className='t-width-3'>

                {
                    renderIcons(wasteCollectionPoint.wasteCollectionPointTypes)
                }

            </td>

            <td className='t-width-4'>
                <p className="table-text-content" title={wasteCollectionPoint?.name || ''}>
                    {
                        wasteCollectionPoint?.name || ''
                    }
                </p>
            </td>

            <td className='sticky--right td-action-buttons'>

                <WasteCollectionPointsCreateAndEditModalForm
                    triggerComponent={ <button className='unstyled-btn'><IconEdit/></button> }
                    wasteCollectionPoint={ wasteCollectionPoint }
                    _onSubmitCallback={ _onSubmitCallback }/>

                <WasteCollectionPointDeleteModalForm
                    triggerComponent={ <button className='unstyled-btn'><IconDelete/></button> }
                    wasteCollectionPoint={ wasteCollectionPoint }
                    _onSubmitCallback={ _onSubmitCallback }/>

            </td>
        </tr>
    );
};

const renderCollectionPointsLocation = (wasteCollectionPoint, showUnpublishedBadge) => {

    if (showUnpublishedBadge) {
        return (
            <div className="table-text-with-badge">
                <p className="table-text-content is-bold" title={ wasteCollectionPoint.location }>
                    {
                        wasteCollectionPoint.location
                    }
                </p>
                <span className="is-uppercase f-12">
                        Do publikacji
                    </span>
            </div>
        );
    } else {
        return (
            <p className="table-text-content" title={ wasteCollectionPoint.location }>
                {
                    !isNullOrUndefined(wasteCollectionPoint) ? wasteCollectionPoint.location : ""
                }
            </p>
        );
    }
};

const renderIcons = (types) => {
    if (isNullOrUndefined(types) || isEmptyArray(types)) {
        return null;
    }

    const mappedTypes = _.mapKeys(types, "code");

    return (
        <Fragment>

            {
                !isNullOrUndefined(mappedTypes[WasteCollectionPointsEnum.type.BATERIE.code]) &&
                <span className='collection-icon'>
                    <IconBattery/>
                </span>
            }

            {
                !isNullOrUndefined(mappedTypes[WasteCollectionPointsEnum.type.PSZOK.code]) &&
                <span className='collection-icon'>
                    <IconPszok/>
                </span>
            }

            {
                !isNullOrUndefined(mappedTypes[WasteCollectionPointsEnum.type.ELEKTROODPADY.code]) &&
                <span className='collection-icon'>
                    <IconElectric/>
                </span>
            }

            {
                !isNullOrUndefined(mappedTypes[WasteCollectionPointsEnum.type.ZAROWKI.code]) &&
                <span className='collection-icon'>
                    <IconLightbulb/>
                </span>
            }

            {
                !isNullOrUndefined(mappedTypes[WasteCollectionPointsEnum.type.APTEKA.code]) &&
                <span className='collection-icon'>
                    <IconMedicine/>
                </span>
            }

            {
                !isNullOrUndefined(mappedTypes[WasteCollectionPointsEnum.type.IGLY.code]) &&
                <span className='collection-icon'>
                    <IconSyringe/>
                </span>
            }

            {
                !isNullOrUndefined(mappedTypes[WasteCollectionPointsEnum.type.SZKLO.code]) &&
                <span className='collection-icon'>
                    <IconContainerGreen/>
                </span>
            }

            {
                !isNullOrUndefined(mappedTypes[WasteCollectionPointsEnum.type.SZKLO_KOLOROWE.code]) &&
                <span className='collection-icon'>
                    <IconContainerGreen/>
                </span>
            }

            {
                !isNullOrUndefined(mappedTypes[WasteCollectionPointsEnum.type.SZKLO_BEZBARWNE.code]) &&
                <span className='collection-icon'>
                    <IconContainerWhite/>
                </span>
            }

            {
                !isNullOrUndefined(mappedTypes[WasteCollectionPointsEnum.type.PAPIER.code]) &&
                <span className='collection-icon'>
                    <IconContainerBlue/>
                </span>
            }

            {
                !isNullOrUndefined(mappedTypes[WasteCollectionPointsEnum.type.TWORZYWA.code]) &&
                <span className='collection-icon'>
                    <IconContainerYellow/>
                </span>
            }

            {
                !isNullOrUndefined(mappedTypes[WasteCollectionPointsEnum.type.ZMIESZANE.code]) &&
                <span className='collection-icon'>
                    <IconContainerBlack/>
                </span>
            }

            {
                !isNullOrUndefined(mappedTypes[WasteCollectionPointsEnum.type.ZIELONE.code]) &&
                <span className='collection-icon'>
                    <IconContainerCeladon/>
                </span>
            }

            {
                !isNullOrUndefined(mappedTypes[WasteCollectionPointsEnum.type.BIO.code]) &&
                <span className='collection-icon'>
                    <IconBio/>
                </span>
            }

            {
                !isNullOrUndefined(mappedTypes[WasteCollectionPointsEnum.type.FOLIE.code]) &&
                <span className='collection-icon'>
                    <IconFoils/>
                </span>
            }

            {
                !isNullOrUndefined(mappedTypes[WasteCollectionPointsEnum.type.SZNURKI.code]) &&
                <span className='collection-icon'>
                    <IconRopes/>
                </span>
            }

            {
                !isNullOrUndefined(mappedTypes[WasteCollectionPointsEnum.type.OPONY.code]) &&
                <span className='collection-icon'>
                    <IconTyres/>
                </span>
            }

            {
                !isNullOrUndefined(mappedTypes[WasteCollectionPointsEnum.type.PLASTIKOWE_NAKRETKI.code]) &&
                <span className='collection-icon'>
                    <IconPlasticCap/>
                </span>
            }

            {
                !isNullOrUndefined(mappedTypes[WasteCollectionPointsEnum.type.OLEJ.code]) &&
                <span className='collection-icon'>
                    <IconOil/>
                </span>
            }

            {
                !isNullOrUndefined(mappedTypes[WasteCollectionPointsEnum.type.ZLOM.code]) &&
                <span className='collection-icon'>
                    <IconScrap/>
                </span>
            }
        </Fragment>
    );
};

export default WasteCollectionPointsListItem;
