import React from "react";

import {
    MISC_DATE_FORMAT,
    MISC_DATE_ISO_FORMAT,
    ROUTE_BLISKO_DRAFT_MESSAGE_EDIT
} from "../../../../../../../core/constants";
import moment from "moment";
import { replaceHtmlEntities } from "../../../../../../../core/utils/string_utils";
import striptags from "striptags";
import DeleteDraftForm from "../form/delete_form";
import { useHistory } from "react-router-dom";

const DraftMessagesListItem = ({ message, _onDeleteSuccess }) => {
    const history = useHistory();

    return (
        <tr>
            <td className='t-width-1 is-clickable' onClick={ () => {
                history.push(ROUTE_BLISKO_DRAFT_MESSAGE_EDIT(message.id))
            } }>
                <span className="notification__date">
                    {
                        moment(message.createdAt, MISC_DATE_ISO_FORMAT)
                            .format(MISC_DATE_FORMAT)
                    }
                </span>
            </td>
            <td className='t-width-7 is-clickable' onClick={ () => {
                history.push(ROUTE_BLISKO_DRAFT_MESSAGE_EDIT(message.id))
            } }>
                <p className="table-text-content">
                    <span className="is-bold">{ message.title }</span>
                    <span> - </span>
                    {
                        replaceHtmlEntities(striptags(message.body))
                    }
                </p>
            </td>
            <td className='t-width-3 is-clickable' onClick={ () => {
                history.push(ROUTE_BLISKO_DRAFT_MESSAGE_EDIT(message.id))
            } }>
                {
                    `${ message.employee.name } ${ message.employee.surname }`
                }
            </td>
            <td className='sticky--right td-action-buttons'>
                <DeleteDraftForm
                    message={ message }
                    _onSuccess={ _onDeleteSuccess }
                />
            </td>
        </tr>
    );
};

export default DraftMessagesListItem;