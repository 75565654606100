import webClient, { webClientFileDownload } from "../../../core/web_client";
import { API_BASE_AKUN_GOK_PATH, MISC_REST_CONTENT_TYPE_APPLICATION_JSON } from '../../../core/constants';

const PATH = `${ API_BASE_AKUN_GOK_PATH }/owner-communal-documents`;

export const getMany = () => {
    return webClient
        .get(PATH);
};

export const addCommunalDocument = (data) => {
    let body = new FormData();

    const form = {
        filename: data.filename,
        hidden: data.hidden
    };
    const formJson = JSON.stringify(form);
    const formBlob = new Blob([ formJson ], {
        type: MISC_REST_CONTENT_TYPE_APPLICATION_JSON
    });

    body.append("file", data.file);
    body.append("form", formBlob);

    return webClient
        .post(`${ PATH }`, body, {
            headers: {
                API_CONTENT_TYPE_HEADER: "multipart/form-data"
            }
        });
};

export const downloadCommunalDocument = (id) => {
    return webClientFileDownload
        .get(`${ PATH }/${ id }`);
}

export const updateCommunalDocumentFilename = (id, filename) => {
    return webClient
        .patch(`${ PATH }/${ id }`, {
            command: "update-filename",
            filename
        })
};

export const updateCommunalDocumentHidden = (id, hidden) => {
    return webClient
        .patch(`${ PATH }/${ id }`, {
            command: "update-hidden",
            hidden
        })
};

export const deleteCommunalDocument = (id) => {
    return webClient
        .delete(`${ PATH }/${ id }`);
};
