import React from "react";

const ApartmentAddressDeleteModalFormContent = () => {

    return (
        <div className='c-modal__body modal-size-m modal--no-header'>
            <p className="confirmation-alert">
                Czy napewno chcesz usunąć wybrany adres?<br/>
                Usunięcie wpłynie na dane wyświetlane w pozostałych zakładkach.
            </p>
        </div>
    );
};

export default ApartmentAddressDeleteModalFormContent;