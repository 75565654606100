import { actions } from "../../../../core/constants";

export const showToast = (type, text) => dispatch => {
    return dispatch({
        type: actions.common.UI__TOAST__SHOW,
        payload: { type, text }
    });
};


export const hideToast = () => dispatch => {
    return dispatch({
        type: actions.common.UI__TOAST__HIDE
    });
};