import React, { Component } from "react";
import { ReactComponent as IconTwoBuildings } from "./icon_two_buildings.svg";
import HeaderUnderlined from "../../../../common/header_underlined";
import { connect } from "react-redux";
import { getAllAddressGroups } from "../../../../../data/actions/ekostraznik/address_groups";
import { showToast } from "../../../../../data/actions/common/ui/toast";
import { ReactComponent as IconDelete } from "../../../../../../resource/img/icons/icon_delete.svg";
import LocationForm, { LocationFormTypeEnum } from "../../../../common/location_form";
import { deepFreeze, isNullOrUndefined } from "../../../../../core/utils/misc_utils";
import { ReactComponent as InfoIcon } from "../../../../../../resource/img/icons/icon_info.svg";
import {
    addAddressGroupToList,
    addAddressRangeToList,
    addAddressToList,
    getList,
    removeAddressFromList,
    removeAddressGroupFromList
} from "../../../../../data/actions/ekostraznik/qr_codes_preparation_list";
import EkostraznikQrCodesGenerationForm from "./qr_codes_generation__form";
import { ButtonLoader } from "../../../../common/loader";
import Select from "../../../../common/select";

const FormType = deepFreeze({
    BY_GROUP: 'BY_GROUP',
    BY_BUILDING: 'BY_BUILDING',
    BY_APARTMENT: 'BY_APARTMENT',
});

class EkostraznikQrCodesGenerationAddresses extends Component {
    state = {
        formType: FormType.BY_BUILDING,
        selectedGroupOption: null,
        selectedForList: null,
        inProgress: false
    }

    render() {

        const { groups, listItems, bins, getList } = this.props;

        const Option = props => {
            const { formType, label } = props;

            return (
                <label
                    onClick={ () => this.setState({ formType, selectedForList: null }) }
                    className={ `choice-label choice-label--radio ${ formType === this.state.formType ? "checked--radio" : "" } ` }
                >
                    <input className="choice--radio" type="radio" value={ formType } name="formType"/>
                    { label }
                </label>
            );
        };

        return (

            <section className="media">
                <div className="media-left">
                    <div className="header-icon">
                        <IconTwoBuildings/>
                    </div>
                </div>
                <div className="media-content">
                    <HeaderUnderlined
                        text='Naklejki z kodami QR dla wybranych budynków, grup adresów lub wyodrębnionych lokali'
                    />
                    <section className='is-spaced--bottom-10 is-flex'>
                        <span>Wybierz adresy, dodaj do listy i wygeneruj naklejki.</span>
                        <div className="tooltip-trigger-icon">
                            <InfoIcon/>
                            <div className="tooltip-desc tooltip-desc--dark">
                                Możesz wybrać:
                                <ul>
                                    <li>- samą miejscowość,</li>
                                    <li>- miejscowość z samą ulicą,</li>
                                    <li>- miejscowość z ulicą i numerem budynku,</li>
                                    <li>- miejscowość z ulicą i zakresem numerów budynków,</li>
                                    <li>- miejscowość z ulicą, numerem budynku i numerem lokalu,</li>
                                    <li>- zdefiniowaną grupę adresów.</li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <div className='options-wrapper--horizontal is-flex is-spaced--top-30 is-spaced--bottom-30'>
                        <Option formType={ FormType.BY_BUILDING } label='Wskazane budynki'/>
                        <Option formType={ FormType.BY_GROUP } label='Zdefiniowane grupy adresów'/>
                        <Option formType={ FormType.BY_APARTMENT } label='Wyodrębnione lokale'/>
                    </div>
                    <div className="is-spaced--top-30 is-flex align-end" style={ { height: '63px' } }>
                        <div className="is-flex flex-grow-1">
                            {
                                this.state.formType === FormType.BY_GROUP && groups.data &&
                                <label className="flex-grow-1">
                                    &nbsp;
                                    <Select
                                        isClearable={ false }
                                        value={ this.state.selectedGroupOption }
                                        options={ groups.data.map(group => ({ label: group.name, value: group.id })) }
                                        onChange={ groupOption => {
                                            this.setState({ selectedGroupOption: groupOption });
                                            this._onSelect({ selectedGroupId: groupOption.value })
                                        } }
                                    />
                                </label>
                            }

                            {
                                this.state.formType === FormType.BY_BUILDING &&
                                <LocationForm
                                    horizontal={ true }
                                    formType={ LocationFormTypeEnum.WITH_BUILDING_NUMBERS_RANGE }
                                    _onChange={ () => this.setState({ selectedForList: null }) }
                                    _onSelect={ this._onSelect }
                                />
                            }

                            {
                                this.state.formType === FormType.BY_APARTMENT &&
                                <LocationForm
                                    horizontal={ true }
                                    formType={ LocationFormTypeEnum.WITH_APARTMENT_ADDRESS }
                                    _onChange={ values => {
                                        this.setState({ selectedForList: null })
                                    } }
                                    _onSelect={ this._onSelect }
                                />
                            }
                        </div>
                        <button
                            disabled={ !this.state.selectedForList || this.state.inProgress }
                            className="btn btn--primary is-spaced--left-30"
                            onClick={ () => this._onAddToListClick() }
                        >
                            {
                                this.state.inProgress &&
                                <ButtonLoader/>
                            }
                            Dodaj&nbsp;do&nbsp;listy
                        </button>


                    </div>

                    <div className='is-spaced--top-50'>
                        <EkostraznikQrCodesGenerationForm
                            _onFinished={ () => getList() }
                            listOnly={ true }
                            bins={ bins }
                            triggerComponent={
                                <button
                                    disabled={ isNullOrUndefined(listItems.data) || listItems.data.length < 1 }
                                    className="btn btn--primary mw-100 generate-qrs-btn"
                                >
                                    Wygeneruj naklejki dla wybranych grup/adresów
                                </button>
                            }
                        />
                    </div>

                    {
                        !isNullOrUndefined(listItems.data) && listItems.data.length > 0 &&
                        <div className="is-spaced--top-20">
                            <div className="qr-codes-generation--addresses">
                                {

                                    listItems.data.map(item => (
                                        <div key={ item.unitId || item.addressGroupId }
                                             className="qr-codes-generation--addresses--row">
                                            { item.name }
                                            <IconDelete
                                                onClick={ () => this._onRemoveFromListClick(item) }
                                            />
                                        </div>
                                    ))
                                }
                            </div>

                        </div>
                    }

                </div>

            </section>
        );
    }

    componentDidMount() {
        const { getAllAddressGroups, getList } = this.props;
        getAllAddressGroups();
        getList();
    }

    _onAddToListClick() {
        const { addAddressToList, addAddressGroupToList, addAddressRangeToList, getList } = this.props;
        const { selectedForList } = this.state;

        if (!selectedForList) {
            return;
        }

        this.setState({ inProgress: true });

        let promise;

        if (selectedForList.addressId) {
            promise = addAddressToList(selectedForList.addressId);
        }

        if (selectedForList.addressGroupId) {
            promise = addAddressGroupToList(selectedForList.addressGroupId)
        }

        if (selectedForList.addressIdFrom && selectedForList.addressIdTo) {
            promise = addAddressRangeToList(selectedForList.addressIdFrom, selectedForList.addressIdTo);
        }

        promise.then(() => {
            getList().then(() => this.setState({ inProgress: false }))
        });
    }

    _onRemoveFromListClick(listItem) {
        const { removeAddressGroupFromList, removeAddressFromList, getList } = this.props;

        if (listItem.unitId) {
            removeAddressFromList(listItem.unitId)
                .then(() => getList())
        }

        if (listItem.addressGroupId) {
            removeAddressGroupFromList(listItem.addressGroupId)
                .then(() => getList())
        }
    }

    _onSelect = (values) => {
        this.setState({ selectedForList: null });

        if (values.selectedGroupId) {
            this.setState({ selectedForList: { addressGroupId: values.selectedGroupId } });
        }

        if (values.selectedFrom && values.selectedTo) {
            this.setState({
                selectedForList: {
                    addressIdFrom: values.selectedFrom.id,
                    addressIdTo: values.selectedTo.id
                }
            });
            return;
        }

        const selectedAddress = values.selectedApartmentAddress || values.selectedFrom || values.selectedAddress || values.selectedStreet || values.selectedTown;
        if (selectedAddress) {
            this.setState({ selectedForList: { addressId: selectedAddress.id } });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        groups: state.entities.ekostraznik.addressGroups.getAll,
        updateList: state.entities.ekostraznik.qrCodes.updatePreparationList,
        listItems: state.entities.ekostraznik.qrCodes.getPreparationList,
    };
};

export default connect(mapStateToProps, {
    getAllAddressGroups,
    showToast,
    addAddressToList,
    addAddressGroupToList,
    addAddressRangeToList,
    getList,
    removeAddressFromList,
    removeAddressGroupFromList
})(EkostraznikQrCodesGenerationAddresses);
