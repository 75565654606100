import React, { Component } from "react";
import { Field, Form } from "formik";
import { SelectWithIcons } from "../../../../../../../../common/select";

class CreateBinModalFormContent extends Component {

    render = () => {
        const { colorOptions, defaultColorSelectValue } = this.props;

        return (
            <div className='c-modal__body modal-size-m'>
                <Form className="form--horizontal">
                    <Field
                        name="name"
                        render={ () => (
                            <label>
                                <span className='label-name'>Kolor:</span>
                                <SelectWithIcons
                                    defaultValue={ defaultColorSelectValue }
                                    options={ colorOptions }
                                    onChange={ this._onSelectChange }
                                    isClearable={ false }
                                />
                            </label>
                        ) }/>

                    <Field
                        name="name"
                        render={ ({ field }) => (
                            <label>
                                <span className='label-name'>Nazwa zbiórki:</span>
                                <input
                                    type="text"
                                    placeholder="Wpisz nazwę, np.: Zbiórka odzieży PCK"
                                    { ...field }/>
                            </label>
                        ) }/>
                </Form>
            </div>
        );
    };

    componentDidMount = () => {
        const { formikBag, _setFormValues, defaultColorSelectValue } = this.props;
        _setFormValues(formikBag);

        // _onSelectChange is called here to set default field value of select for Formik
        this._onSelectChange(defaultColorSelectValue);
    };

    _onSelectChange = (selectedOption) => {
        const { formikBag } = this.props;

        formikBag.setFieldValue("color", selectedOption.value);
    };
}

export default CreateBinModalFormContent;