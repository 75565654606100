import React, { Component } from "react";
import { connect } from "react-redux";

import FormModal from "../../../../../common/modal/form_modal";
import { InspectionCompaniesEnum } from "../../../../../../core/enums/ekostraznik/inspection_companies";
import { invoke, isNullOrUndefined } from "../../../../../../core/utils/misc_utils";
// import { toastFor500, toastForSuccess } from "../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../data/actions/common/ui/toast";
import { updateInspectionCompany } from "../../../../../../data/actions/ekostraznik/inspection_companies";
import { toastFor500, toastForSuccess } from "../../../../../../core/utils/toast_utils";


class DeactivateInspectionCompanyModalForm extends Component {

    render = () => {
        const { triggerComponent, saveButtonText, inspectionCompany } = this.props;

        return (
            <FormModal
                triggerComponent={ triggerComponent }
                saveOrCancelButtons={ true }
                saveButtonText={ saveButtonText }
                _actionFunction={ () => {
                    return this._onSubmit();
                } }>

                <div className='c-modal__body modal-size-m modal--no-header'>
                    <p className="confirmation-alert">
                        Czy na pewno chcesz dezaktywować kontrolera { inspectionCompany.name }?
                    </p>
                </div>

            </FormModal>
        );
    };

    _onSubmit = () => {
        const { inspectionCompany, updateInspectionCompany, _onSubmitCallback, showToast, inspectionCompanyUpdate } = this.props;

        if (!isNullOrUndefined(inspectionCompany)) {
            return updateInspectionCompany(inspectionCompany.id, { command: InspectionCompaniesEnum.command.INSPECTION_COMPANY_DEACTIVATE})
                .then(() => invoke(_onSubmitCallback))
                .then( () => {
                    toastFor500(showToast, inspectionCompanyUpdate, "Kontroler nie został dezaktywowany. Spróbuj ponownie");
                    toastForSuccess(showToast, inspectionCompanyUpdate, "Kontroler został dezaktywowany");
                });
        } else {
            invoke(_onSubmitCallback);
        }
    };
}

const mapStateToProps = (state) => {
    return {
        inspectionCompanyUpdate: state.entities.ekostraznik.inspectionCompanies.update
    };
};


export default connect(mapStateToProps, { updateInspectionCompany, showToast })(DeactivateInspectionCompanyModalForm);

