import React, { Component } from "react";
import { Formik } from "formik";

import FormModal from "../../../../../../../../common/modal/form_modal";
import CreateBinModalFormContent from "./content";
import { ReactComponent as IconPlus } from "../../../../../../../../../../resource/img/icons/icon_add.svg";
import { connect } from "react-redux";
import { addBin, getManyBins } from "../../../../../../../../../data/actions/waste_management/bins";
import { isNullOrUndefined } from "../../../../../../../../../core/utils/misc_utils";
import { CustomBinsEnum } from "../../../../../../../../../core/enums/waste_management/bins";
import BinsIcon from "../icon";


const { GRAY, BEIGE, CHERRY, CELADON, PURPLE } = CustomBinsEnum.colors;
const colorOptions = [
    { value: GRAY, label: "Szary", icon: <BinsIcon color={ GRAY }/> },
    { value: BEIGE, label: "Beżowy", icon: <BinsIcon color={ BEIGE }/> },
    { value: CHERRY, label: "Wiśniowy", icon: <BinsIcon color={ CHERRY }/> },
    { value: CELADON, label: "Seledynowy", icon: <BinsIcon color={ CELADON }/> },
    { value: PURPLE, label: "Fioletowy", icon: <BinsIcon color={ PURPLE }/> },
];

const defaultColorSelectValue = colorOptions[0];

const initialFormValues = {
    name: "",
    color: defaultColorSelectValue
};

class CreateBinModalForm extends Component {

    render = () => {
        return (
            <Formik
                initialValues={ initialFormValues }
                render={ formikBag => (
                    <FormModal
                        triggerComponent={
                            <button className="unstyled-btn add-new-tab">
                                <IconPlus/>
                            </button>
                        }
                        ariaLabel="Nowy rodzaj zbiórki"
                        headerText={ "Nowy rodzaj zbiórki" }
                        saveOrCancelButtons={ true }
                        overflow={ true }
                        _actionFunction={ () => this._onSubmit(formikBag) }>

                        <CreateBinModalFormContent
                            formikBag={ formikBag }
                            _setFormValues={ this._setFormValues }
                            defaultColorSelectValue={ defaultColorSelectValue }
                            colorOptions={ colorOptions }/>

                    </FormModal>
                ) }

            />
        );
    };

    _onSubmit = (formikBag) => {
        const { addBin, getManyBins } = this.props;

        return addBin(formikBag.values)
            .then(() => getManyBins());
    };

    _setFormValues = (formikBag) => {
        if (!isNullOrUndefined(formikBag)) {
            formikBag.resetForm(initialFormValues);
        }
    };
}


export default connect(null, { addBin, getManyBins })(CreateBinModalForm);

