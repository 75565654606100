import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as IconAdd } from "../../../../../../../resource/img/icons/icon_add_white.svg";
import FormModal from "../../../../../common/modal/form_modal";
import { addAddressGroup } from "../../../../../../data/actions/ekostraznik/address_groups";
import { useHistory } from "react-router-dom";
import { ROUTE_DETAILED_ADDRESS_GROUP } from "../../../../../../core/constants";

const AddAddressGroupForm = () => {
    const dispatch = useDispatch()
    const addStatus = useSelector(state => state.entities?.ekostraznik?.addressGroups?.add)
    const [groupName, setGroupName] = useState('')

    let history = useHistory();

    return (
        <FormModal
            headerText="Dodaj nową grupę"
            triggerComponent={
                <button className="btn btn--primary btn--big">
                    <IconAdd />
                    <span className="is-spaced--left-10">Dodaj nową grupę</span>
                </button>
            }
            saveOrCancelButtons={true}
            saveButtonText="Dodaj"
            isLoading={addStatus?.meta?.loading}
            submitDisabled={groupName.length === 0}
            _actionFunction={() =>
                dispatch(addAddressGroup({name: groupName}))
                .then(async response => {
                    history.push(ROUTE_DETAILED_ADDRESS_GROUP(response.data.id))
                })
            }

        >
            <div className='c-modal__body modal-size-m'>
                <input
                    placeholder="Wpisz nazwę grupy"
                    type='text'
                    value={groupName}
                    onChange={(event) => setGroupName(event.target.value)} />
            </div>
        </FormModal>
    );
}

export default AddAddressGroupForm;
