import { API_BASE_AKUN_BLISKO_PATH } from "../../../core/constants";
import webClient from "../../../core/web_client";
import { buildQueryString } from "../../../core/utils/api_utils";

const PATH = `${ API_BASE_AKUN_BLISKO_PATH }/receiver-groups`;

export const getMany = (queryParams) => {
    return webClient
        .get(`${ PATH }${ buildQueryString(queryParams) }`);
};

export const getReceiverGroup = (id) => {
    return webClient
        .get(`${ PATH }/${ id }`)
};

export const addReceiverGroup = (data) => {
    return webClient
        .post(PATH, data);
};

export const updateReceiverGroup = (id, data) => {
    return webClient
        .put(`${ PATH }/${ id }`, data);
};

export const hideReceiverGroup = (id) => {
    return webClient
        .patch(`${ PATH }/${ id }`, {
            command: "hide"
        })
};

export const activeReceiverGroup = (id) => {
    return webClient
        .patch(`${ PATH }/${ id }`, {
            command: "activate"
        })
};

export const removeReceiverGroups = (data) => {
    return webClient
        .delete(PATH, { data });
};
