import React from "react";
import CheckboxInput from "../../../../../common/checkbox_input";
import ApartmentAddressDeleteModalForm from "./form";

const ApartmentAddressesListItem = ({ apartmentAddress, deleteApartmentAddresses, checkedApartmentAddresses, _onApartmentAddressDeleteClick, _onApartmentAddressCheckboxChange }) => {

    return (
        <tr>
            <td className='t-width-11'>
                <CheckboxInput
                    label={ apartmentAddress.name }
                    _onInputChange={ () => _onApartmentAddressCheckboxChange(apartmentAddress.id) }
                    checked={ checkedApartmentAddresses.indexOf(apartmentAddress.id) >= 0 }/>
                <p className="table-text-content">
                </p>
            </td>
            <td className='t-width-1 sticky--right td-action-buttons'>

                <ApartmentAddressDeleteModalForm
                    apartmentAddress={ apartmentAddress }
                    deleteApartmentAddresses={ deleteApartmentAddresses }
                    _onApartmentAddressDeleteClick={ _onApartmentAddressDeleteClick }/>
            </td>
        </tr>
    );
};

export default ApartmentAddressesListItem;