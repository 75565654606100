import React from "react";

import AllTypesListModal from "./all_types_list_modal";

import { MISC_SHOW_UNFINISHED_ELEMENTS } from "../../../../../../../core/constants";

import { ReactComponent as IconHelp } from "../../../../../../../../resource/img/icons/icon_help.svg";
import { ReactComponent as IconArrowSwitchUp } from "../../../../../../../../resource/img/icons/list/icon_arrow_switch_up.svg";

const WasteCollectionPointsListHeader = () => {

    return (
        <tr>
            <th className='t-width-4'>
                {
                    MISC_SHOW_UNFINISHED_ELEMENTS &&
                    <div className='t-checkbox'>
                        <label className='acceptance-label'>
                            <input type='checkbox' className='acceptance-checkbox'/>
                        </label>
                    </div>
                }

                Lokalizacja

                <div className='th-action-btn'>
                    <IconArrowSwitchUp/>
                </div>
            </th>
            <th className='t-width-3'>
                Rodzaj zbiórki
                <div className='th-action-btn'>
                    <AllTypesListModal
                        triggerComponent={ <IconHelp/> }
                        ariaLabel="Legenda wszystkich typów punktów zbiórki"
                        headerText="Legenda"
                    />
                </div>
            </th>
            <th className='t-width-4'>Nazwa punktu</th>
        </tr>
    );
};

export default WasteCollectionPointsListHeader;