import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, Form, Formik } from "formik";

import SettingsHeader from "../common/header";
import PasswordRequirements from "../../../../common/password_requirements";
import Loader from "../../../../common/loader";
import { passwordStrength } from "../../../../../core/utils/password_utils";
import { resetPasswordChange, updatePasswordChange } from "../../../../../data/actions/common/password_change";

import { isEmptyString, isNullOrUndefined } from "../../../../../core/utils/misc_utils";
import { SettingsEnum } from "../../../../../core/enums/common/settings";
import { PasswordChangeEnum } from "../../../../../core/enums/common/password_change";
import { ToastEnum } from "../../../../../core/enums/common/toast";
import hiddenIcon from "../../../../../../resource/img/icons/icon_eye_off.svg";
import visibleIcon from "../../../../../../resource/img/icons/icon_eye.svg";
import { showToast } from "../../../../../data/actions/common/ui/toast";
import { toastFor500, toastForSuccess } from "../../../../../core/utils/toast_utils";

const initialFormValues = {
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: ""
};


class SettingsPasswordChange extends Component {
    state = {
        visiblePassword: false,
        visibleOldPassword: false
    };

    render = () => {
        const { visiblePassword, visibleOldPassword } = this.state;
        const { passwordChange } = this.props;

        const oldPasswordInvalid = !isNullOrUndefined(passwordChange.meta.message) && passwordChange.meta.message.startsWith(PasswordChangeEnum.errorCode.INVALID_OLD_PASSWORD);

        if (passwordChange.meta.loading) {
            return (
                <>
                    <SettingsHeader tab={ SettingsEnum.tab.PASSWORD_CHANGE }/>
                    <Loader/>
                </>
            );
        }

        return (
            <div className="side-spacing">
                <SettingsHeader tab={ SettingsEnum.tab.PASSWORD_CHANGE }/>
                <div className="content--xs content--to-left">

                    <Formik initialValues={ initialFormValues }
                            validate={ this._validate }
                            onSubmit={ values => this._onSubmit(values) }
                            render={ () => (
                                <Form className="change-passowrd-form">
                                    <Field name="oldPassword"
                                           render={ ({ field, form }) => (
                                               <div className='field'>
                                                   <label className='label'>
                                                       Aktualne hasło
                                                       <span className="is-relative">
                                                            <input className={ `${ (form.errors.oldPassword && form.touched.oldPassword) || oldPasswordInvalid ? "rejected" : "" }` }
                                                                   type={ visibleOldPassword ? "text" : "password" } { ...field }/>

                                                            <span tabIndex="0" className='show-password'
                                                                  onClick={ event => this._visiblePasswordToggle(event, "oldPassword") }
                                                                  onKeyPress={ event => this._visiblePasswordToggle(event, "oldPassword") }>
                                                                <img src={ visibleOldPassword ? visibleIcon : hiddenIcon } alt={ visibleOldPassword ? "Ukryj hasło" : "Pokaż hasło" }/>
                                                            </span>

                                                           {
                                                               form.errors.oldPassword &&
                                                               form.touched.oldPassword &&

                                                               <span className='hint hint--rejected'>{ form.errors.oldPassword }</span>
                                                           }
                                                           {
                                                               oldPasswordInvalid &&

                                                               <span className='hint hint--rejected'>{ "Aktualne hasło jest nieprawidłowe." }</span>
                                                           }
                                                        </span>
                                                   </label>
                                               </div>
                                           ) }/>
                                    <Field name="newPassword"
                                           validate={ validatePassword }
                                           render={ ({ field, form }) => (
                                               <div>
                                                   <div className='field'>
                                                       <label className='label'>
                                                           Nowe hasło
                                                           <span className='is-relative'>
                                                               <input type={ visiblePassword ? "text" : "password" } { ...field }/>
                                                               <span tabIndex="0" className='show-password'
                                                                     onClick={ (e) => this._visiblePasswordToggle(e) }
                                                                     onKeyPress={ (e) => this._visiblePasswordToggle(e) }>
                                                                   <img src={ visiblePassword ? visibleIcon : hiddenIcon } alt={ visiblePassword ? "Ukryj hasło" : "Pokaż hasło" }/>
                                                               </span>
                                                            </span>
                                                       </label>
                                                   </div>

                                                   {
                                                       <PasswordRequirements password={ field.value }
                                                                             touched={ !isNullOrUndefined(form.touched.newPassword) ? form.touched.newPassword : false }/>
                                                   }

                                               </div>
                                           ) }/>

                                    <Field name="newPasswordRepeat"
                                           render={ ({ field, form }) => (

                                               <div className='field'>
                                                   <label className='label'>
                                                       Powtórz hasło
                                                       <span className="is-relative">
                                                           <input className={ `${ form.errors.newPasswordRepeat && form.touched.newPasswordRepeat ? "rejected" : "" }` }
                                                                  type={ visiblePassword ? "text" : "password" } { ...field }/>
                                                           <span tabIndex="0" className='show-password'
                                                                 onClick={ (e) => this._visiblePasswordToggle(e) }
                                                                 onKeyPress={ (e) => this._visiblePasswordToggle(e) }>
                                                            <img src={ visiblePassword ? visibleIcon : hiddenIcon } alt={ visiblePassword ? "Ukryj hasło" : "Pokaż hasło" }/>
                                                           </span>

                                                           {
                                                               form.errors.newPasswordRepeat &&
                                                               form.touched.newPasswordRepeat &&
                                                               <span className='hint hint--rejected'>{ form.errors.newPasswordRepeat }</span>
                                                           }

                                                        </span>
                                                   </label>
                                               </div>

                                           ) }/>

                                    <button type='submit' className='btn btn--primary btn--medium'>Zapisz</button>
                                </Form>
                            ) }/>
                </div>
            </div>

        );
    };

    componentDidMount = () => {
        this.props.resetPasswordChange();
    };

    _visiblePasswordToggle = (event, oldPassword) => {

        if (event.which === 13 || event.type === "click") {

            if (oldPassword) {
                this.setState(prevState => ({
                    visibleOldPassword: !prevState.visibleOldPassword
                }));
            } else {
                this.setState(prevState => ({
                    visiblePassword: !prevState.visiblePassword
                }));
            }

        }
    };

    _validate = (values) => {
        let errors = {};

        if (isEmptyString(values.newPasswordRepeat) ||
            (values.newPasswordRepeat !== values.newPassword)) {

            errors.newPasswordRepeat = "Wpisane hasła nie są takie same";
        }

        if (isEmptyString(values.oldPassword)) {
            errors.oldPassword = "Pole wymagane";
        }

        return errors;
    };

    _onSubmit = (values) => {
        const { updatePasswordChange, showToast } = this.props;
        const { oldPassword, newPassword } = values;

        return updatePasswordChange({ oldPassword: oldPassword, newPassword: newPassword })
            .then(() => {
                const { passwordChange }= this.props;



                toastFor500(showToast, passwordChange, "Wystąpił błąd. Nie udało się zmienić hasła. Spróbuj ponownie");
                toastForSuccess(showToast, passwordChange, "Hasło zostało zmienione");

                //custom toast
                if(!isNullOrUndefined(passwordChange.meta.message) && passwordChange.meta.message.startsWith(PasswordChangeEnum.errorCode.NEW_PASSWORD_EQUAL_TO_OLD_PASSWORD)) {
                    return showToast(ToastEnum.type.ERROR, "Nowe hasło nie może być takie jak aktualne")
                }
            });
    };
}

const validatePassword = (field) => {
    let error;
    const strength = passwordStrength(field);
    if (!strength.hasValidLength
        || !strength.hasLowercaseLetter
        || !strength.hasUppercaseLetter
        || !strength.hasNumberOrSpecialCharacter) {
        error = " ";
    }

    return error;
};


const mapStateToProps = (state) => {
    return {
        passwordChange: state.entities.common.passwordChange.update
    };
};

export default connect(mapStateToProps, { updatePasswordChange, resetPasswordChange, showToast })(SettingsPasswordChange);
