import React, { Component } from "react";
import { EditorState, RichUtils } from "draft-js";
import Editor from "draft-js-plugins-editor";
import isSoftNewlineEvent from "draft-js/lib/isSoftNewlineEvent";
import RenderControls from "../draft_editor/render_controls";
import createFocusPlugin from "draft-js-focus-plugin";
import createBlockDndPlugin from "draft-js-drag-n-drop-plugin";
import createLinkPlugin from "draft-js-anchor-plugin";
import createInlineToolbarPlugin from "draft-js-inline-toolbar-plugin";

import { isNullOrUndefined } from "../../../core/utils/misc_utils";

import "draft-js-focus-plugin/lib/plugin.css";
import "draft-js-anchor-plugin/lib/plugin.css";
import "draft-js-inline-toolbar-plugin/lib/plugin.css";

const styleMap = {
    BOLD: {
        fontWeight: "500"
    },
};

const focusPlugin = createFocusPlugin();
const blockDndPlugin = createBlockDndPlugin();

const linkPlugin = createLinkPlugin({
    placeholder: "https://..."
});
const inlineToolbarPlugin = createInlineToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;

const plugins = [
    blockDndPlugin,
    focusPlugin,
    linkPlugin,
    inlineToolbarPlugin
];

const withDraftEditor = (OriginalComponent, externalState = false) => {

    class WrappedComponent extends Component {

        constructor(props) {
            super(props);

            if (!isExternal()) {
                this.state = {
                    editorState: EditorState.createEmpty()
                };
            }

            this.onChange = (editorState) =>
                isExternal()
                    ? props._onChange(editorState)
                    : this.setState({ editorState });
            this.handleKeyCommand = (command) => this._handleKeyCommand(command);
            this.toggleType = (type) => this._toggleType(type);
            this.onTab = (event) => this._onTab(event);
        }

        render = () => {
            const editorState = this.getEditorState();

            let className = "RichEditor-editor";
            const contentState = editorState.getCurrentContent();
            if (!contentState.hasText()) {
                if (contentState.getBlockMap()
                                .first()
                                .getType() !== "unstyled") {
                    className += " RichEditor-hidePlaceholder";
                }
            }

            return (
                <div className="RichEditor-root">
                    <RenderControls
                        editorState={ editorState }
                        onChange={ this.onChange }
                        onToggle={ this.toggleType }
                        linkPlugin={ linkPlugin }/>

                    <div className={ className }
                         onClick={ this.focus }>

                        <Editor
                            blockStyleFn={ this.getBlockStyle }
                            customStyleMap={ styleMap }
                            editorState={ editorState }
                            handleKeyCommand={ this.handleKeyCommand }
                            onChange={ this.onChange }
                            onTab={ this.onTab }
                            ref={ element => {
                                this.editor = element;
                            } }
                            spellCheck={ true }
                            handleReturn={ this._handleReturn }
                            plugins={ plugins }/>

                        <InlineToolbar>
                            {
                                externalProps => (
                                    <>
                                        <linkPlugin.LinkButton {...externalProps} />
                                    </>
                                )
                            }
                        </InlineToolbar>
                    </div>
                </div>
            );
        };

        focus = () => {
            this.editor.focus();
        };

        _handleKeyCommand = (command) => {
            const editorState = this.getEditorState();
            const newState = RichUtils.handleKeyCommand(editorState, command);
            if (newState) {
                this.onChange(newState);
                return true;
            }
            return false;
        };

        _handleReturn = (event) => {
            if (isSoftNewlineEvent(event)) {
                const editorState = this.getEditorState();
                this.onChange(RichUtils.insertSoftNewline(editorState));
                return "handled";
            }

            return "not-handled";
        };

        _onTab = (event) => {
            const editorState = this.getEditorState();
            const maxDepth = 4;
            this.onChange(RichUtils.onTab(event, editorState, maxDepth));
        };

        _toggleType = (controlType) => {
            const editorState = this.getEditorState();

            if (controlType === "BOLD" || controlType === "ITALIC" || controlType === "UNDERLINE") {
                this.onChange(RichUtils.toggleInlineStyle(editorState, controlType));
            } else {
                this.onChange(RichUtils.toggleBlockType(editorState, controlType));
            }
        };

        getBlockStyle = (block) => {
            switch (block.getType()) {
                case "INFO":
                    return "RichEditor-info";
                case "WARNING":
                    return "RichEditor-warning";
                default:
                    return null;
            }
        };

        getEditorState = () => {
            return isExternal() ? this.props.editorState : this.state.editorState;
        };
    }

    const isExternal = () => {
        return !isNullOrUndefined(externalState) && externalState;
    };

    return WrappedComponent;
};

export default withDraftEditor;