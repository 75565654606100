import React, { useEffect, useState } from "react";

import FormModal from "../../../../../../../common/modal/form_modal";
import { useDispatch } from "react-redux";
import { getMessageTemplates } from "../../../../../../../../data/actions/blisko/messages";
import { isNullOrUndefined } from "../../../../../../../../core/utils/misc_utils";
import { ReactComponent as IconCopy } from "../../../../../../../../../resource/img/icons/icon_copy.svg";
import _ from "lodash";
import Select from "../../../../../../../common/select";
import { MessageEnum } from "../../../../../../../../core/enums/waste_management/messages";

const PasteTemplate = ({ setFieldValue, useGeoLocation, setState, _useGeoLocationToggle }) => {
    const dispatch = useDispatch();
    const [selectedMessageTemplate, setSelectedMessageTemplate] = useState();
    const [messageTemplateOptions, setMessageTemplateOptions] = useState();

    useEffect(() => {
        dispatch(getMessageTemplates())
            .then(response => {
                const options = _.map(response?.data, messageTemplate => {
                    return {
                        label: messageTemplate.name,
                        value: messageTemplate
                    };
                });

                setMessageTemplateOptions(options);
            });
    }, [dispatch]);

    return (
        <FormModal
            headerText="Wklej treść z szablonu"
            triggerComponent={
                <span className="btn__icon-wrapper--left is-clickable">
                    <span className='btn__icon'>
                        <IconCopy/>
                    </span>
                    Wklej treść z szablonu
                </span>
            }
            saveOrCancelButtons
            saveButtonText="Wklej treść"
            submitDisabled={ isNullOrUndefined(selectedMessageTemplate) }
            overflow
            _actionFunction={ () => {
                const messageTemplate = selectedMessageTemplate.value;

                setFieldValue("priority", Object.entries(MessageEnum.priorityLevel).filter(item => item[0] === messageTemplate.priority).map(item => item[1])[0]);
                setFieldValue("title", messageTemplate.title);
                setFieldValue("body", messageTemplate.body);

                if (!!messageTemplate.latitude && !!messageTemplate.longitude) {
                    setFieldValue("latitude", messageTemplate.latitude);
                    setFieldValue("longitude", messageTemplate.longitude);

                    if (!useGeoLocation) {
                        _useGeoLocationToggle();
                    }
                } else {
                    if (useGeoLocation) {
                        _useGeoLocationToggle();
                    }
                }

                setState(prevState => {
                    return {
                        ...prevState,
                        uploadedFiles: messageTemplate.attachments ? [...messageTemplate.attachments] : [],
                    }
                });

                return Promise.resolve();
            } }
        >
            <div className='c-modal__body modal-size-m'>
                <Select
                    options={ messageTemplateOptions }
                    isClearable={ false }
                    onChange={ messageTemplateOption => setSelectedMessageTemplate(messageTemplateOption) }/>
            </div>
        </FormModal>
    );
};

export default PasteTemplate;