import React from "react";

const FormRequiredLegend = () => {
    return (
        <div className="form-legend--required-field">
            <span>
                wymagane
            </span>
            <span className="required-accent"> *</span>
        </div>
    )
};

export default FormRequiredLegend;