import { actions } from "../../../../core/constants";

const defaultState = {
    sidebarVisible: false,
    sidebarCollapsed: false,
    activeSidebarItem: null,
    activeSubmenuItem: null,
    submenuExpanded: false
};

export default (state = defaultState, action) => {
    switch (action.type) {

        case actions.common.UI__SIDEBAR_ITEM__SELECTED:
            return {
                ...state,
                ...action.payload
            };

        case actions.common.UI__SIDEBAR__RESET:
        case actions.common.UI__AUTHENTICATION__SIGN_OUT:
            return defaultState;

        default:
            return state;
    }
}
