import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, Form, Formik } from "formik";

import PasswordRequirements from "../../../common/password_requirements";
import FormErrorMessage from "../../../common/form_error_message";
import { isNullOrUndefined } from "../../../../core/utils/misc_utils";
import { passwordStrength } from "../../../../core/utils/password_utils";
import { updatePasswordResetRequest } from "../../../../data/actions/common/password_reset_request";
import { MISC_EVENT_CLICK } from "../../../../core/constants";
import hiddenIcon from "../../../../../resource/img/icons/icon_eye_off.svg";
import visibleIcon from "../../../../../resource/img/icons/icon_eye.svg";

const initialFormValues = {
    password: "",
    passwordRepeat: ""
};

class PasswordResetForm extends Component {

    state = {
        visiblePassword: false,
        token: this.props.token
    };

    render = () => {
        const { passwordResetRequest } = this.props;

        return (
            <>
                <div className="form--signed-out">
                    <Formik
                        initialValues={ initialFormValues }
                        validate={ this._validate }
                        onSubmit={ values => this._onSubmit(values) }
                        render={ () => (
                            <Form>
                                <div className='input-wrapper'>

                                    <Field name="password"
                                           validate={ validatePassword }
                                           render={ ({ field, form }) => (

                                               <div>
                                                   <div className='field'>
                                                       <label className='label'>
                                                           Nowe hasło
                                                           <span
                                                               className='is-relative'>
                                                               <input type={ this.state.visiblePassword ? "text" : "password" } { ...field }/>

                                                               <span tabIndex="0" className='show-password'
                                                                     onClick={ event => this._visiblePasswordToggle(event) }
                                                                     onKeyPress={ event => this._visiblePasswordToggle(event) }>
                                                                   <img src={ this.state.visiblePassword ? visibleIcon : hiddenIcon }
                                                                        alt={ this.state.visiblePassword ? "Ukryj hasło" : "Pokaż hasło" }/>
                                                               </span>
                                                           </span>
                                                       </label>
                                                   </div>
                                                   {
                                                       <PasswordRequirements
                                                           password={ field.value }
                                                           touched={ !isNullOrUndefined(form.touched.password) ? form.touched.password : false }/>
                                                   }
                                               </div>
                                           ) }/>

                                    <Field
                                        name="passwordRepeat"
                                        render={ ({ field, form }) => (
                                            <div className='field'>
                                                <label className='label'>
                                                    Powtórz hasło
                                                    <span className="is-relative">
                                                        <input className={ `${ form.errors.passwordRepeat && form.touched.passwordRepeat ? "rejected" : "" }` }
                                                               type={ this.state.visiblePassword ? "text" : "password" }
                                                               { ...field }/>

                                                        <span tabIndex="0" className='show-password'
                                                              onClick={ event => this._visiblePasswordToggle(event) }
                                                              onKeyPress={ event => this._visiblePasswordToggle(event) }>
                                                            <img src={ this.state.visiblePassword ? visibleIcon : hiddenIcon }
                                                                 alt={ this.state.visiblePassword ? "Ukryj hasło" : "Pokaż hasło" }/>
                                                        </span>

                                                        {
                                                            form.errors.passwordRepeat &&
                                                            form.touched.passwordRepeat &&

                                                            <span className='hint hint--rejected'>{ form.errors.passwordRepeat }</span>
                                                        }

                                                    </span>
                                                </label>
                                            </div>
                                        ) }/>
                                </div>

                                {
                                    passwordResetRequest.error &&

                                    <FormErrorMessage message="Wystąpił błąd. Nie udało się zmienić hasła. Spróbuj ponownie."/>
                                }

                                <input type='submit' className='btn btn--primary' value='Zapisz'/>
                            </Form>
                        ) }/>
                </div>
            </>
        );
    };

    _visiblePasswordToggle = (event) => {

        if (event.which === 13 || event.type === MISC_EVENT_CLICK) {
            this.setState(prevState => ({
                visiblePassword: !prevState.visiblePassword
            }));
        }
    };

    _validate = (values) => {
        let errors = {};

        if (!values.passwordRepeat || values.passwordRepeat !== values.password) {
            errors.passwordRepeat = "Wpisane hasła nie są takie same.";
        }

        return errors;
    };

    _onSubmit(values) {
        const { password } = values;
        const { token } = this.state;
        const { updatePasswordResetRequest, _handleRedirection } = this.props;

        return updatePasswordResetRequest({ password }, token)
            .then(() => {
                _handleRedirection();
            });
    }
}

const validatePassword = (field) => {
    let error;
    const strength = passwordStrength(field);
    if (!strength.hasValidLength
        || !strength.hasLowercaseLetter
        || !strength.hasUppercaseLetter
        || !strength.hasNumberOrSpecialCharacter) {
        error = " ";
    }

    return error;
};

const mapStateToProps = (state) => {
    return {
        passwordResetRequest: state.entities.common.passwordResetRequest.tokenCheck,
        passwordResetRequestUpdate: state.entities.common.passwordResetRequest.update
    };
};

export default connect(mapStateToProps, { updatePasswordResetRequest })(PasswordResetForm);