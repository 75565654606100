import React from "react";
import moment from "moment";

import CenterPinMap from "../../../../../../common/map/center_pin_map";
import DocumentGallery from "../../../../../../common/document_gallery/gallery";
import TitleWithDivider from "../../../../../../common/title_with_divider";
import {
    MISC_DATE_DAY_MONTH_YEAR_FORMAT,
    MISC_DATE_DAY_MONTH_YEAR_HOUR_MINUTE_FORMAT,
    MISC_DATE_ISO_FORMAT
} from "../../../../../../../core/constants";
import {
    isEmptyArray,
    isEmptyString,
    isNullOrUndefined,
    isNullOrUndefinedOneOfMany
} from "../../../../../../../core/utils/misc_utils";

const ReportDetailsInfo = ({ selectedReport, bins }) => {

    if (isNullOrUndefined(selectedReport) || isNullOrUndefined(selectedReport.data)) {
        return null;
    }

    return (
        <div className="report-details__section">
            <TitleWithDivider>
                Zgłoszenie
            </TitleWithDivider>

            {
                !isNullOrUndefined(selectedReport.data.createdDate) &&

                <div className="report-details__row report-details__row--info">
                    <p className="report-details__label">Data zgłoszenia:</p>
                    <p className="report-details__text">
                        {
                            moment(selectedReport.data.createdDate, MISC_DATE_ISO_FORMAT)
                                .format(MISC_DATE_DAY_MONTH_YEAR_HOUR_MINUTE_FORMAT)
                        }
                    </p>
                </div>
            }

            {
                !isNullOrUndefined(selectedReport.data.additionalDate) &&

                <div className="report-details__row report-details__row--info">
                    <p className="report-details__label">Data zdarzenia:</p>
                    <p className="report-details__text">
                        {
                            moment(selectedReport.data.additionalDate, MISC_DATE_ISO_FORMAT)
                                .format(MISC_DATE_DAY_MONTH_YEAR_FORMAT)
                        }
                    </p>
                </div>
            }

            {
                !isEmptyArray(selectedReport.data.binIds) &&

                <div className="report-details__row report-details__row--info">
                    <p className="report-details__label">Rodzaj pojemnika:</p>
                    <p className="report-details__text">
                        {
                            // AWA-221 fix
                            selectedReport
                                .data
                                .binIds
                                .map(binId => {
                                    if (!isNullOrUndefined(bins) &&
                                        !isNullOrUndefined(bins.data) &&
                                        !isNullOrUndefined(bins.data[binId])) {
                                        return (
                                            <span key={ binId } className="is-block">
                                                { bins.data[binId].name }
                                            </span>
                                        );
                                    }
                                    return null;
                                })
                                .filter(bin => !isNullOrUndefined(bin))
                        }
                    </p>
                </div>
            }

            {
                !isEmptyString(selectedReport.data.localization) &&

                <div className="report-details__row report-details__row--info">
                    <p className="report-details__label">Lokalizacja:</p>
                    <p className="report-details__text">{ selectedReport.data.localization }</p>
                </div>
            }

            {
                !isNullOrUndefinedOneOfMany(selectedReport.data.latitude, selectedReport.data.longitude) &&

                <div className="report-details__row report-details__row--map">
                    {
                        <CenterPinMap
                            latitude={ selectedReport.data.latitude }
                            longitude={ selectedReport.data.longitude }
                            readOnly={ true }/>
                    }
                </div>
            }

            {
                !isEmptyString(selectedReport.data.description) &&

                <div className="report-details__row report-details__row--info">
                    <p className="report-details__label">Opis zdarzenia:</p>
                    <p className="report-details__text">
                        {
                            selectedReport.data.description
                        }
                    </p>
                </div>
            }

            {
                selectedReport.data.additionalInfo &&

                <div className="report-details__row report-details__row--info">
                    <p className="report-details__label">Dodatkowe informacje:</p>
                    <p className="report-details__text">
                        {
                            selectedReport.data.additionalInfo
                        }
                    </p>
                </div>
            }

            <DocumentGallery
                attachmentPaths={ selectedReport.data.attachmentPaths }
                label={ "Zdjęcia:" }/>
        </div>
    );
};

export default ReportDetailsInfo;
