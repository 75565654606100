import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const addMessage = (data) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__MESSAGES__ADD__STARTED
    });

    return api
        .waste_management
        .messages
        .addMessage(data)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__MESSAGES__ADD__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__MESSAGES__ADD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getManyMessages = (queryParams) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__MESSAGES__GET_MANY__STARTED
    });

    return api
        .waste_management
        .messages
        .getManyMessages(queryParams)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__MESSAGES__GET_MANY__SUCCESS,
                meta: {},
                payload: response.data,
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__MESSAGES__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getMessage = (id) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__MESSAGES__GET__STARTED
    });

    return api
        .waste_management
        .messages
        .getMessage(id)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__MESSAGES__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__MESSAGES__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const cancelMessage = (id) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__MESSAGES__DELETE__STARTED
    });

    return api
        .waste_management
        .messages
        .cancelMessage(id)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__MESSAGES__DELETE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__MESSAGES__DELETE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};