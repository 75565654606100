import { deepFreeze } from "../../utils/misc_utils";

export const ModulesEnum = deepFreeze({
    waste_management: {
        code: "gok"
    },
    services:{
        code: "service_cards"
    },
    ekostraznik:{
        code: "ekostraznik"
    },
    blisko:{
        code: "blisko"
    }
});