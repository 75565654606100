import React, { Component } from "react";
import _ from "lodash";

import { isEmptyArray, isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";
import Select from "../../../../../../common/select";

class MunicipalWasteHeaderAreaSelect extends Component {

    state = {
        options: []
    };

    render = () => {
        const { selectedArea, _onAreaSelect } = this.props;
        const { options } = this.state;

        if (isNullOrUndefined(selectedArea)) {
            return null;
        }

        return (
            <div className="select-wrapper select-wrapper--with-icon select-wrapper--map-marker-icon">
                <Select
                    options={ options }
                    defaultValue={ options[0] }
                    isClearable={ false }
                    onChange={ option => _onAreaSelect(option.value) }/>
            </div>
        );
    };

    componentDidUpdate = () => {
        const { areas } = this.props;
        const options = this.generateSelectOptions(areas.data);

        if (isEmptyArray(this.state.options)) {
            this.setState({
                options
            }, this.setSelectOptionIfNoneChosen);
        } else {
            this.setSelectOptionIfNoneChosen();
        }
    };

    shouldComponentUpdate = (nextProps, nextState) => {
        const { areas, selectedArea } = this.props;
        const { options } = this.state;

        if (options !== nextState.options) {
            return true;
        }

        if (areas !== nextProps.areas) {
            return true;
        }

        if (selectedArea !== nextProps.selectedArea) {
            return true;
        }

        return false;
    };

    setSelectOptionIfNoneChosen = () => {
        const { areas, selectedArea, _onAreaSelect } = this.props;
        const options = this.generateSelectOptions(areas.data);
        const defaultOption = options[0];

        if (isNullOrUndefined(selectedArea) && !isNullOrUndefined(defaultOption)) {
            _onAreaSelect(defaultOption.value);
        }
    };

    generateSelectOptions = (areaData) => {
        const sortedAreaData = _.sortBy(areaData, [area => area.name.toLowerCase(), 'name']);

        return _.map(sortedAreaData, area => {
            return {
                value: area.id,
                label: area.name
            };
        });
    };
}

export default MunicipalWasteHeaderAreaSelect;