import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import ContainerExpirationFormContent from "./content";
import FormModal from "../../../../../../common/modal/form_modal";
import { getBulkyWasteConfig, updateBulkyWasteConfig } from "../../../../../../../data/actions/waste_management/bulky_waste";
import { isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";

const initialFormValues = {
    days: 0
};

class ContainerExpirationForm extends Component {

    render = () => {
        const { triggerComponent, headerText, bulkyWasteConfig } = this.props;

        return (
            <Formik
                initialValues={ initialFormValues }
                render={ formikBag => (
                    <FormModal
                        triggerComponent={ triggerComponent }
                        headerText={ headerText }
                        saveOrCancelButtons={ true }
                        _actionFunction={ () => this._onSubmit(formikBag) }>

                        <ContainerExpirationFormContent
                            formikBag={ formikBag }
                            disabled={ bulkyWasteConfig.meta.loading }
                            _setFormValues={ this._setFormValues }/>

                    </FormModal>
                ) }
            />
        );
    };

    componentDidMount = () => {
        this.props.getBulkyWasteConfig();
    };

    _setFormValues = (formikBag) => {
        const { bulkyWasteConfig } = this.props;

        const values = !isNullOrUndefined(bulkyWasteConfig) && !isNullOrUndefined(bulkyWasteConfig.data)
            ? bulkyWasteConfig.data
            : initialFormValues;

        if (!isNullOrUndefined(formikBag)) {
            formikBag.resetForm(values);
        }
    };

    _onSubmit = (formikBag) => {
        const { getBulkyWasteConfig } = this.props;

        if (!isNullOrUndefined(formikBag)) {
            return this.props.updateBulkyWasteConfig(formikBag.values.days)
                .then(() => getBulkyWasteConfig());
        }
    };
}

const mapStateToProps = (state) => {
    return {
        bulkyWasteConfig: state.entities.wasteManagement.bulkyWaste.getConfig
    };
};

export default connect(mapStateToProps, { getBulkyWasteConfig, updateBulkyWasteConfig })(ContainerExpirationForm);

