import React, { useCallback, useEffect, useState } from "react";

import _ from "lodash";
import AddressTemplateLocationsHeader from "./header";
import { geAddressTemplate } from "../../../../../../../../data/actions/blisko/address_templates";
import { useDispatch, useSelector } from "react-redux";
import AddressTemplateLocationList from "./list";

const AddressTemplateLocations = ({ match }) => {
    const dispatch = useDispatch();
    const [searchBarTerm, setSearchBarTerm] = useState('');
    const addressTemplate = useSelector(state => state.entities?.blisko?.addressTemplates?.get)

    const addressTemplateId = match.params.addressTemplateId;

    useEffect(() => {
        dispatch(geAddressTemplate(addressTemplateId))
    }, [dispatch, addressTemplateId])

    const _onSearchBarInputChange = useCallback(
        _.debounce(input => setSearchBarTerm(input || ''), 600),
        []
    );

    return (
        <div className="main-content--table">
            <AddressTemplateLocationsHeader
                addressTemplate={ addressTemplate }
                searchBarTerm={ searchBarTerm }
                _onSearchBarInputChange={ _onSearchBarInputChange }
            />
            <AddressTemplateLocationList
                addressTemplateId={ addressTemplateId }
                searchBarTerm={ searchBarTerm }
            />
        </div>
    );
};

export default AddressTemplateLocations;