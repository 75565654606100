import React, { Component } from "react";
import Loader from "../../loader";
import CheckboxGroup from "../../checkbox_group";

class LocationEditFormContent extends Component {

    state = {
        loading: true
    };

    render = () => {
        const { location, _onSelectionChange, locationCandidateAddresses } = this.props;
        const { loading } = this.state;

        if (loading) {
            return (
                <Loader/>
            );
        }

        return (
            <div className='c-modal__body modal-size-m'>
                <div className="checkbox-list">
                    <CheckboxGroup
                        options={Object.values(locationCandidateAddresses?.data || {}).map(address => ({
                            label: address.fullName,
                            value: address.id
                        }))}
                        defaultValues={location.addresses.map(address => address.id)}
                        onChange={selectedOptions => {
                            _onSelectionChange(selectedOptions.map(option => option.value))
                        }}
                    />
                </div>
            </div>
        );
    };

    componentDidMount = () => {
        const { location, _loadLocationDataToEdit } = this.props;
        _loadLocationDataToEdit(location, () => {
            this.setState({
                loading: false
            });
        });
    };

}

export default LocationEditFormContent;
