import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getManyInspectionCompanies } from "../../../../../../data/actions/ekostraznik/inspection_companies";
import { isNullOrUndefined } from "../../../../../../core/utils/misc_utils";
import Select from "../../../../../common/select";


class InspectionCompany extends Component {

    render = () => {
        const { errors, formikBag } = this.props;
        const companyOptions = this.generateCompanyOptions();

        return (
            <label>
                <span className="label-name"><span className="required-accent">*</span>Kontroler</span>
                <div className="is-relative full-width">
                    <Select
                        value={ formikBag.values.companyId ? undefined : null }
                        options={ companyOptions }
                        onChange={ this._onSelectChange }
                    />
                    {
                        !isNullOrUndefined(formikBag.errors.companyId) &&
                        <span className="hint hint--rejected">
                            {
                                errors.companyId
                            }
                        </span>
                    }
                </div>
            </label>
        );
    };

    componentDidMount = () => {
        this.props.getManyInspectionCompanies();
    };

    generateCompanyOptions = () => {
        const { companies } = this.props;

        if (isNullOrUndefined(companies.data)) {
            return [];
        }

        return _.map(companies.data, company => {
            return {
                label: company.name,
                value: company.id.toString(),
            };
        });
    };

    _onSelectChange = (selectedOption) => {
        const { formikBag } = this.props;

        formikBag.setFieldValue("companyId", selectedOption?.value);
    };
}


const mapStateToProps = (state) => {
    return {
        companies: state.entities.ekostraznik.inspectionCompanies.getMany,
    };
};

export default connect(mapStateToProps, { getManyInspectionCompanies })(InspectionCompany);

