import React, { Component } from "react";
import { Form, Formik } from "formik";
import { connect } from "react-redux";

import FormModal from "../../../../../../common/modal/form_modal";
import CompaniesCreateAndEditModalFormContent from "./content";
import { CompaniesEnum } from "../../../../../../../core/enums/waste_management/companies";
import { invoke, isEmptyObject, isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";
import { addCompany, renameCompany } from "../../../../../../../data/actions/waste_management/companies";
import { toastFor500, toastForSuccess } from "../../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../../data/actions/common/ui/toast";
import _ from "lodash";

const initialFormValues = {
    name: ""
};

class CompaniesCreateAndEditModalForm extends Component {

    render = () => {
        const { company, _onSubmitCallback, triggerComponent, headerText, ariaLabel } = this.props;

        const initialValues =
            !isNullOrUndefined(company) && !isNullOrUndefined(company.name)
                ? { name: company.name }
                : initialFormValues;

        return (
            <Formik
                initialValues={ initialValues }
                initialFormValues={ initialFormValues }
                render={ formikBag => (
                    <Form>
                        <FormModal
                            triggerComponent={ triggerComponent }
                            saveOrCancelButtons={ true }
                            ariaLabel={ ariaLabel }
                            headerText={ headerText }
                            _actionFunction={ () => {
                                return this
                                    ._onSubmit(formikBag)
                                    .then(() => this._handleErrorMessage(formikBag))
                                    .then(() => invoke(_onSubmitCallback));
                            } }>

                            <CompaniesCreateAndEditModalFormContent
                                formikBag={ formikBag }/>
                        </FormModal>
                    </Form>
                ) }/>
        );
    };

    _handleErrorMessage = (formikBag) => {
        const { companyAdd, companyRename } = this.props;
        const errors = {};

        const nameAlreadyExistsErrorCode = CompaniesEnum.errorCode.NAME_ALREADY_EXISTS;
        const emptyNameErrorCode = CompaniesEnum.errorCode.EMPTY_NAME;

        if (companyRename.meta.message?.startsWith(nameAlreadyExistsErrorCode) ||
            companyAdd.meta.message?.startsWith(nameAlreadyExistsErrorCode)) {
            errors.name = "Firma o tej nazwie już istnieje"
        }
        if (companyRename.meta.message?.startsWith(emptyNameErrorCode) ||
            companyAdd.meta.message?.startsWith(emptyNameErrorCode)) {
            errors.name = "Dodaj nazwę firmy"
        }

        if (!isEmptyObject(errors)) {
            formikBag.setErrors(errors);
            return Promise.reject();
        }

        return Promise.resolve();
    };

    _validate = (values) => _.pickBy({
        name: !values.name ? "Dodaj nazwę firmy" : undefined
    })

    _onSubmit = (formikBag) => {
        const { company, addCompany, renameCompany, showToast } = this.props;
        const errors = this._validate(formikBag.values);

        if (!isEmptyObject(errors)) {
            formikBag.setErrors(errors);
            return Promise.reject();
        }

        if (!isNullOrUndefined(company)) {
            return renameCompany(company.id, formikBag.values).then(() => {
                const { companyRename } = this.props;

                toastFor500(showToast, companyRename, "Zmiana nazwy firmy wywożącej nie powiodła się. Spróbuj ponownie");
                toastForSuccess(showToast, companyRename, "Nazwa firmy wywożącej została zmieniona");
            });
        } else {
            return addCompany(formikBag.values).then(() => {
                const { companyAdd } = this.props;

                toastFor500(showToast, companyAdd, "Dodanie firmy wywożącej nie powiodło się. Spróbuj ponownie");
                toastForSuccess(showToast, companyAdd, "Dodano firmę wywożącą odpady");
            });
        }

    };
}

const mapStateToProps = (state) => {
    return {
        companyAdd: state.entities.wasteManagement.companies.add,
        companyRename: state.entities.wasteManagement.companies.rename
    };
};

export default connect(mapStateToProps, { addCompany, renameCompany, showToast })(CompaniesCreateAndEditModalForm);