import React from "react";

const SideListBlankPage = ({ text, img }) => {

    return (
        <div className="sll__blankpage">
            <div className="sll__blankpage__content">
                <img src={ img } alt=""/>

                <p className="sll__blankpage__text">
                    {
                        text
                    }
                </p>
            </div>
        </div>
    );
};

export default SideListBlankPage;