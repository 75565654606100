import React from "react";

import { ReactComponent as IconSearch } from "../../../../../../../resource/img/icons/icon_search.svg";
import Header from "../../../../../common/header";
import { useHistory } from "react-router-dom";
import { ROUTE_BLISKO_MESSAGE_TEMPLATE_ADD } from "../../../../../../core/constants";
import { ReactComponent as IconAdd } from "../../../../../../../resource/img/icons/icon_add_white.svg";

const MessageTemplateHeader = ({ _onSearchBarInputChange }) => {
    const history = useHistory();

    return (
        <section className='main-content--table__top'>
            <Header>Szablony wiadomości</Header>

            <section className='container is-fluid'>
                <div className="row row--separated row--options">
                    <div className='row__left'>
                        <div className="input-field header__search with-icon--right">
                            <input
                                type='text'
                                placeholder='Szukaj szablonu'
                                onChange={ event => _onSearchBarInputChange(event.target.value) }
                            />
                            <IconSearch className='input-icon'/>
                        </div>
                    </div>
                    <div className='row__right'>
                        <button className="btn btn--primary btn--big header__main-cta-btn"
                                onClick={ () => history.push(ROUTE_BLISKO_MESSAGE_TEMPLATE_ADD) }>
                            <span className="btn__icon-wrapper--left">
                                <span className='btn__icon'>
                                    <IconAdd/>
                                </span>
                                Nowy szablon wiadomości
                            </span>
                        </button>
                    </div>
                </div>
            </section>
        </section>
    );
};

export default MessageTemplateHeader;