import React, { useState } from "react";

import { deepFreeze } from "../../../../../../core/utils/misc_utils";
import moment from "moment";
import { MISC_DATE_ISO_DATE_ONLY_FORMAT } from "../../../../../../core/constants";
import DraftMessagesList from "./list";
import DraftMessageHeader from "./header";

const messageAuthors = deepFreeze({
    ALL: {
        value: "all",
        description: "Wszystkie"
    },
    MINE: {
        value: "employee",
        description: "Wysłane przeze mnie"
    }
});

const DraftMessages = () => {
    const [state, setState] = useState({
        searchBarTerm: '',
        from: moment().subtract(1, "weeks").format(MISC_DATE_ISO_DATE_ONLY_FORMAT),
        to: moment().format(MISC_DATE_ISO_DATE_ONLY_FORMAT),
        messageAuthor: messageAuthors.ALL.value
    });

    return (
        <>
            <DraftMessageHeader
                messageAuthors={ messageAuthors }
                state={ state }
                setState={ setState }
            />
            <DraftMessagesList
                state={ state }
            />
        </>
    )
};

export default DraftMessages;