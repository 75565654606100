import { deepFreeze } from "../../utils/misc_utils";

import iconCalendar from "../../../../resource/img/icons/sidebar/icon_calendar.svg";
import iconSend from "../../../../resource/img/icons/sidebar/icon_send.svg";
import iconMessage from "../../../../resource/img/icons/sidebar/icon_message.svg";
import iconPin from "../../../../resource/img/icons/sidebar/icon_pin.svg";
import iconWallet from "../../../../resource/img/icons/sidebar/icon_wallet.svg";
import iconCar from "../../../../resource/img/icons/sidebar/icon_car.svg";
import iconBuildings from "../../../../resource/img/icons/sidebar/icon_buildings.svg";
import iconInspections from "../../../../resource/img/icons/sidebar/icon_ekostraznik_inspections.svg";
import iconInventory from "../../../../resource/img/icons/sidebar/icon_inventory.svg";
import iconReport from "../../../../resource/img/icons/sidebar/icon_ekostraznik_reports.svg";
import iconEkoCompanies from "../../../../resource/img/icons/sidebar/icon_ekostraznik_comapnies.svg";
import iconDownload from "../../../../resource/img/icons/sidebar/icon_download_gray.svg";
import iconReceiverGroup from "../../../../resource/img/icons/sidebar/icon_info_big.svg";
import iconSenderDetails from "../../../../resource/img/icons/sidebar/icon_card.svg";
import iconAddressTemplate from "../../../../resource/img/icons/sidebar/icon_folder.svg";
import {
    ROUTE_BLISKO,
    ROUTE_BLISKO_ADDRESS_TEMPLATE,
    ROUTE_BLISKO_MESSAGE_TEMPLATE,
    ROUTE_BLISKO_MESSAGES,
    ROUTE_BLISKO_RECEIVER_GROUP,
    ROUTE_BLISKO_SENDER_DETAILS,
    ROUTE_EKOSTRAZNIK,
    ROUTE_EKOSTRAZNIK_ADDRESSES_APARTMENT_ADDRESS,
    ROUTE_EKOSTRAZNIK_ADDRESSES_GROUPED_ADDRESSES,
    ROUTE_EKOSTRAZNIK_ADDRESSES_QR_CODES,
    ROUTE_EKOSTRAZNIK_COMPANIES,
    ROUTE_EKOSTRAZNIK_INSPECTION_HISTORY,
    ROUTE_EKOSTRAZNIK_INVENTORIES,
    ROUTE_EKOSTRAZNIK_REPORTS,
    ROUTE_SERVICES,
    ROUTE_WASTE,
    ROUTE_WASTE_AREAS,
    ROUTE_WASTE_COMMUNAL_DOCUMENTS,
    ROUTE_WASTE_COMPANIES,
    ROUTE_WASTE_MANAGEMENT_BULKY_WASTE,
    ROUTE_WASTE_MANAGEMENT_COLLECTION_POINTS,
    ROUTE_WASTE_MANAGEMENT_MUNICIPAL_WASTE,
    ROUTE_WASTE_NOTIFICATIONS,
    ROUTE_WASTE_NOTIFICATIONS_SENT,
    ROUTE_WASTE_PAYMENTS_DUE_DATE,
    ROUTE_WASTE_REPORTS
} from "../../constants";

//id to random do użytku wewnętrznego

export const SidebarEnum = deepFreeze({
    BLISKO: {
        name: 'Blisko',
        link: ROUTE_BLISKO,
        MESSAGES: {
            id: "cbb07dd8e825971e2756f13e1558cb891f060001",
            name: "Wiadomości",
            description: "Prześlij wiadomość do mieszkańców na aplikację Blisko",
            icon: iconMessage,
            link: ROUTE_BLISKO_MESSAGES,
        },
        RECEIVER_GROUP: {
            id: "cbb07ee8e825971e1errf13e1777cb891f069471",
            name: "Serwisy publiczne",
            description: "Zarządzaj serwisami publicznymi dostępnymi na aplikacji Blisko",
            icon: iconReceiverGroup,
            link: ROUTE_BLISKO_RECEIVER_GROUP,
        },
        TEMPLATES: {
            id: "aswqe0ej72wd61j4ete985l3b4b89renjsecwl29",
            name: "Szablony",
            icon: iconAddressTemplate,

            ADDRESS_TEMPLATE: {
                id: "3zzdz2ko4gp0kq6oeulb5qfh82xahupzrwoxjn9c",
                name: "Szablon adresów",
                description: "Dodaj szablony adresów i wysyłaj do nich wiadomości",
                link: ROUTE_BLISKO_ADDRESS_TEMPLATE
            },
            MESSAGE_TEMPLATE: {
                id: "oc8v6s9u6hr8gm61io0su0urgrmjigbkz6jskdfs",
                name: "Szablony wiadomości",
                description: "Dodaj szablon wiadomości",
                link: ROUTE_BLISKO_MESSAGE_TEMPLATE
            }
        },
        SENDER_DETAILS: {
            id: "aDb07ee8eEaAVS1e1errf13psJF56b891f069471",
            name: "Wizytówka nadawcy",
            description: "Dodaj informacje o Nadawcy dla użytkowników BLISKO",
            icon: iconSenderDetails,
            link: ROUTE_BLISKO_SENDER_DETAILS,
        },
    },
    WASTE_MANAGEMENT: {
        name: "Gospodarka odpadami",
        link: ROUTE_WASTE,
        WASTE_MANAGEMENT: {
            id: "13d1a75265d6219005a12b408dfd0618139b29e6",
            name: "Zarządzaj odpadami",
            icon: iconCalendar,

            MUNICIPAL_WASTE: {
                id: "bf8e4e4b1e9eac6462b3528cad1dcaeec07eaa2d",
                name: "Harmonogramy",
                description: "Zarządzaj harmonogramami wywozu odpadów komunalnych i okazjonalnych.",
                link: ROUTE_WASTE_MANAGEMENT_MUNICIPAL_WASTE
            },
            BULKY_WASTE: {
                id: "8af979f3857f948e78ed7b55a7f0ee90564e1162",
                name: "Odpady wielkogabarytowe",
                description: "Zaznacz lokalizacje kontenerów i określ daty podstawienia.",
                link: ROUTE_WASTE_MANAGEMENT_BULKY_WASTE
            },
            WASTE_COLLECTION_POINTS: {
                id: "48a02c5ed5fd3ebe90d32c5df2da5673febb9c75",
                name: "Punkty zbiórki",
                description: "Określ lokalizacje punktów zbiórki odpadów nietypowych.",
                link: ROUTE_WASTE_MANAGEMENT_COLLECTION_POINTS
            }
        },
        MESSAGES: {
            id: "22eb5975fa337e85ca544850af4b5ba7de486722",
            name: "Wiadomości",
            icon: iconSend,

            SEND_MESSAGE: {
                id: "ea4d5ceb7521e5c92783f175018e138322151c5c",
                name: "Wyślij wiadomość",
                description: "Prześlij wiadomość do mieszkańców.",
                link: ROUTE_WASTE_NOTIFICATIONS
            },
            SENT_MESSAGES: {
                id: "1365134156f529ffa2a7d0a68adec1cf84a4e593",
                name: "Wysłane wiadomości",
                description: "Przeglądaj historię wysłanych wiadomości.",
                link: ROUTE_WASTE_NOTIFICATIONS_SENT
            }
        },
        REPORTS: {
            id: "b6d07dd8e825971e2756f13e1558cb891f061684",
            name: "Zgłoszenia mieszkańców",
            description: "Zarządzaj zgłoszeniami mieszkańców.",
            icon: iconMessage,
            link: ROUTE_WASTE_REPORTS
        },
        AREAS: {
            id: "63095473ea4001484030fd78e9ad911ebe1c7807",
            name: "Obszary",
            description: "Zdefiniuj obszary zbiórek odpadów.",
            icon: iconPin,
            link: ROUTE_WASTE_AREAS
        },
        PAYMENTS_DUE_DATE: {
            id: "24a211c356b619e3c04c3914d3723dcc331b4dab",
            name: "Terminy opłat",
            description: "Oznacz terminy uiszczania opłat za gospodarowanie odpadami.",
            icon: iconWallet,
            link: ROUTE_WASTE_PAYMENTS_DUE_DATE
        },
        COMPANIES: {
            id: "019943f3bfd964774ab4831791a208ac26449e20",
            name: "Firmy wywożące odpady",
            description: "Podaj informacje o firmach wywożących odpady.",
            icon: iconCar,
            link: ROUTE_WASTE_COMPANIES
        },
        COMMUNAL_DOCUMENTS: {
            id: "986543f3bfd964774ab4831791a208ac2644df34",
            name: "Dokumenty dla mieszkańca",
            description: "Dodaj dokumenty dla mieszkańca związane z gospodarką odpadami.",
            icon: iconDownload,
            link: ROUTE_WASTE_COMMUNAL_DOCUMENTS
        },
    },
    SERVICES: {
        name: "Karty usług",
        link: ROUTE_SERVICES
    },
    EKOSTRAZNIK: {
        name: "Ekostrażnik",
        link: ROUTE_EKOSTRAZNIK,
        INSPECTION_HISTORY: {
            id: "b921bd9b44cc3f11774586302c2c5926469d0ccb",
            name: "Kontrole nieprawidłowej segregacji",
            description: "Weryfikuj kontrole segregacji odpadów.",
            icon: iconInspections,
            link: ROUTE_EKOSTRAZNIK_INSPECTION_HISTORY
        },
        REPORTS: {
            id: "04447d06fdd9ee44a253b85a24a1e882d4aedf7e",
            name: "Zgłoszenia",
            description: "Zarządzaj problemami zgłoszonymi przez kontrolerów.",
            icon: iconReport,
            link: ROUTE_EKOSTRAZNIK_REPORTS
        },
        INVENTORIES: {
            id: "14447d06fdE9ee44aE53b85a24a1eE82d4aedf7E",
            name: "Inwentaryzacje",
            description: "Weryfikuj przeprowadzone inwentaryzacje.",
            icon: iconInventory,
            link: ROUTE_EKOSTRAZNIK_INVENTORIES
        },
        ADDRESSES: {
            id: "0eaf438eb7984b6a65f86a12b8f682f58bc9c997",
            name: "Adresy",
            icon: iconBuildings,
            GROUPED_ADDRESSES: {
                id: "8642c4d1305a3617a8a12fa7849e820cd7b9e983",
                name: "Zgrupowane adresy",
                description: "Twórz wspólnoty i spółdzielnie ze wspólnymi miejscami gromadzenia odpadów.",
                link: ROUTE_EKOSTRAZNIK_ADDRESSES_GROUPED_ADDRESSES
            },
            APARTMENT_ADDRESS: {
                id: "b7a69817ae610a5f836fb8066e7544f122aadbb3",
                name: "Wyodrębnione lokale",
                description: "Rozdzielaj budynki na lokale z oddzielnymi deklaracjami.",
                link: ROUTE_EKOSTRAZNIK_ADDRESSES_APARTMENT_ADDRESS
            },
            QR_CODES: {
                id: "035f9cf8361a43f44430c70053f5fcabb3dead38",
                name: "Naklejki z kodami QR",
                description: "Generuj naklejki z kodami QR i naklejaj je na kosze mieszkańców.",
                link: ROUTE_EKOSTRAZNIK_ADDRESSES_QR_CODES
            },
        },
        COMPANIES: {
            id: "97a80b56ef6ff9124fd8d07cf0042a1e02958d83",
            name: "Kontrolerzy",
            description: "Wprowadź dane firm prowadzących kontrole.",
            icon: iconEkoCompanies,
            link: ROUTE_EKOSTRAZNIK_COMPANIES
        },
    }
});
