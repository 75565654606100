import getMany from "./get_many";
import getAll from "./get_all";
import add from "./add";
import deleteMany from "./delete_many";
import get from "./get";
import addresses from "./addresses"
import update from "./update";
import nests from "./nests";

export default (state = {}, action) => {
    return {
        ...state,
        getMany: getMany(state.getMany, action),
        getAll: getAll(state.getAll, action),
        add: add(state.add, action),
        deleteMany: deleteMany(state.deleteMany, action),
        get: get(state.get, action),
        update: update(state.update, action),
        addresses: addresses(state.addresses, action),
        nests: nests(state.nests, action)
    };
}
