import React, { Component } from "react";
import { connect } from "react-redux";

import InputSuggestions from "../input_suggestions";
import { isEmptyArray, isEmptyObject } from "../../../core/utils/misc_utils";
import { forwardGeocoding } from "../../../data/actions/common/geocoding";

const withForwardGeocoding = (OriginalComponent) => {

    class WrappedComponent extends Component {

        state = {
            suggestionsVisible: false
        };

        render = () => {
            return (
                <OriginalComponent
                    renderInputWithSuggestions={ this.renderInputWithSuggestions }
                    { ...this.props } />
            );
        };

        renderInputWithSuggestions = () => {
            const { value, readOnly, errors } = this.props;
            const { suggestionsVisible } = this.state;

            const forwardGeocodingResults = this.generateForwardGeocodingResults();

            return (
                <div className="input-with-suggestions">
                    <input
                        placeholder='np. Sienkiewicza 10, Wrocław'
                        type="text"
                        className={ `${ errors ? "rejected" : "" }` }
                        disabled={ readOnly || false }
                        onFocus={ this._onInputFocus }
                        onBlur={ this._onInputBlur }
                        onChange={ event => this._onLocationFieldChange(event) }
                        value={ value }/>

                    {
                        suggestionsVisible &&
                        !isEmptyArray(forwardGeocodingResults) &&
                        <InputSuggestions
                            dataArray={ forwardGeocodingResults }
                            _onSuggestionClick={ this._onSuggestionClick }/>
                    }

                    {
                        errors &&
                        <span className="hint hint--rejected">{ errors }</span>
                    }

                </div>
            );
        };

        generateForwardGeocodingResults = () => {
            const { forward } = this.props;

            if (isEmptyObject(forward?.data) || forward.meta.loading) {
                return [];
            }

            return forward.data.map(forwardGeocodingResult => {
                return {
                    key: forwardGeocodingResult.place_name,
                    label: forwardGeocodingResult.place_name,
                    data: forwardGeocodingResult
                };
            });
        };

        _onLocationFieldChange = (event) => {
            event.persist();
            const { _onLocationFieldChangeCallback, forwardGeocoding } = this.props;
            const { value } = event.target;
            _onLocationFieldChangeCallback(value);
            if (value?.length >= 2) {
                forwardGeocoding(value);
            }
        };

        _onSuggestionClick = (suggestion) => {
            const { _onSuggestionClickCallback } = this.props;
            const { geometry } = suggestion;

            if (geometry?.location) {
                const { lng, lat } = geometry.location;

                return _onSuggestionClickCallback(lng, lat, suggestion.place_name);
            }
        };

        _onInputFocus = () => {
            this.setState({
                suggestionsVisible: true
            }, () => {
                this.generateForwardGeocodingResults();
            });
        };

        _onInputBlur = () => {
            this.setState({
                suggestionsVisible: false
            });
        };
    }

    const mapStateToProps = (state) => {
        return {
            forward: state.entities.common.geocoding.forward
        }
    };

    return connect(mapStateToProps, { forwardGeocoding })(WrappedComponent);
};

export default withForwardGeocoding;