import add from "./add";
import delete_ from "./delete";
import get from "./get";
import getMany from "./get_many";
import rename from "./rename";
import update from "./update";

export default (state = {}, action) => {
    return {
        ...state,
        add: add(state.add, action),
        delete_: delete_(state.delete_, action),
        get: get(state.get, action),
        getMany: getMany(state.getMany, action),
        rename: rename(state.rename, action),
        update: update(state.update, action)
    };
}