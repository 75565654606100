import getManyPaginated from "./get_many_paginated";
import get from "./get";
import add from "./add";
import update from "./update";
import deleteMany from "./delete_many";
import delete_ from "./delete";

export default (state = {}, action) => {
    return {
        ...state,
        getManyPaginated: getManyPaginated(state.getManyPaginated, action),
        get: get(state.get, action),
        add: add(state.add, action),
        update: update(state.update, action),
        deleteMany: deleteMany(state.deleteMany, action),
        delete: delete_(state.delete_, action),
    };
}