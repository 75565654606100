import React, { Component } from "react";
import ExternalStateDraftEditor from "../../../../common/draft_editor/external_state_draft_editor";
import UnderlinedHeader from "../../../../common/header_underlined";

class SourceAndLicense extends Component {

    render = () => {
        const {step, sourceAndLicenseEditorState, _setSourceAndLicenseEditorState} = this.props;

        return (
            <section className="media mw-100">
                <div className="media-left step-count-wrap">
                    <div className="step-count">
                        <span>{ step }</span>
                    </div>
                </div>
                <div className="media-content media-content--steps">
                    <UnderlinedHeader text="Informacja o licencji"/>
                    <p className="is-spaced--bottom-30">
                        Informacja o licencji na jakiej została utworzona publikacja
                    </p>
                    <div>
                        <div>
                            <ExternalStateDraftEditor
                                editorState={ sourceAndLicenseEditorState }
                                _onChange={ (editorState) => {
                                    _setSourceAndLicenseEditorState(editorState)
                                } }
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    };
}

export default SourceAndLicense;
