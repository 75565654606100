import React, { useCallback, useMemo } from "react";

import _ from "lodash";
import { ReactComponent as IconSearch } from "../../../../../../../resource/img/icons/icon_search.svg";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { MISC_DATE_ISO_DATE_ONLY_FORMAT } from "../../../../../../core/constants";
import MomentLocaleUtils, { formatDate } from "react-day-picker/moment";
import moment from "moment";
import Select from "../../../../../common/select";

const DraftMessageHeader = ({ messageAuthors, state, setState }) => {
    const authorsOptions = useMemo(() => {
        return _.map(messageAuthors, status => {
            return {
                label: status.description,
                value: status.value
            };
        });
    }, [messageAuthors]);

    const _onSearchBarInputChange = useCallback(
        _.debounce(input => setState(prevState => {
            return { ...prevState, searchBarTerm: input || '' }
        }), 600),
        []
    );

    return (
        <section className="main-content--table__top container is-fluid">
            <div className="row row--separated row--options flexible-height-wrap">
                <div className="row__left is-v-centered wrapable">
                    <div className="input-field header__search with-icon--left options-item">
                        <input type='text'
                               placeholder='Szukaj wiadomość'
                               onChange={ (event) => _onSearchBarInputChange(event.target.value) }
                        />
                        <IconSearch className='input-icon'/>
                    </div>
                    <div className="options-item is-v-centered">
                        <fieldset className="input-field options-item options-item--range">
                            <legend className="is-sr-only">Zakres dat:</legend>
                            <div className="is-v-centered">
                                <div className="options-param">
                                    <span className="label-name" aria-hidden={ true }>Zakres dat:</span>
                                </div>
                                <label className="options-param">
                                    <div className="input-size--date">
                                        <DayPickerInput
                                            className="message-sending-1__input"
                                            placeholder={ MISC_DATE_ISO_DATE_ONLY_FORMAT }
                                            format={ MISC_DATE_ISO_DATE_ONLY_FORMAT }
                                            formatDate={ formatDate }
                                            value={ state.from }
                                            onDayChange={ date => setState(prevState => {
                                                return {
                                                    ...prevState,
                                                    from: moment(date).format(MISC_DATE_ISO_DATE_ONLY_FORMAT)
                                                }
                                            }) }
                                            inputProps={ {
                                                readOnly: true
                                            } }
                                            dayPickerProps={ {
                                                localeUtils: MomentLocaleUtils,
                                                locale: "pl"
                                            } }/>
                                    </div>
                                </label>
                                <div className="message-sending-1__dash">-</div>
                                <label className="options-param">
                                    <div className="input-size--date">
                                        <DayPickerInput
                                            className="message-sending-1__input"
                                            placeholder={ MISC_DATE_ISO_DATE_ONLY_FORMAT }
                                            format={ MISC_DATE_ISO_DATE_ONLY_FORMAT }
                                            formatDate={ formatDate }
                                            value={ state.to }
                                            onDayChange={ date => setState(prevState => {
                                                return {
                                                    ...prevState,
                                                    to: moment(date).format(MISC_DATE_ISO_DATE_ONLY_FORMAT)
                                                }
                                            }) }
                                            inputProps={ {
                                                readOnly: true
                                            } }
                                            dayPickerProps={ {
                                                localeUtils: MomentLocaleUtils,
                                                locale: "pl"
                                            } }/>
                                    </div>
                                </label>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
            <div className="row row--options">
                <span className="is-spaced--right-10">Pokaż:</span>
                <div className="input-size-l">
                    <Select
                        options={ authorsOptions }
                        defaultValue={ authorsOptions[0] }
                        isClearable={ false }
                        onChange={ option => setState(prevState => {
                            return { ...prevState, messageAuthor: option.value }
                        }) }
                    />
                </div>
            </div>
        </section>
    )
};

export default DraftMessageHeader;