import React, { Component } from "react";
import moment from "moment";

import { MISC_DATE_DAY_MONTH_YEAR_HOUR_MINUTE_FORMAT, MISC_DATE_ISO_FORMAT } from "../../../core/constants";
import { ReportEnum } from "../../../core/enums/waste_management/reports";
import { EkostraznikReportsEnum } from "../../../core/enums/ekostraznik/reports";
import { isNullOrUndefined } from "../../../core/utils/misc_utils";

class SideListItem extends Component {

    render = () => {
        const { active, listItem, listType, _onListItemClick, renderIconFunction } = this.props;

        return (
            <li className={ `sll__list-item ${ active ? "sll__list-item--selected" : "" } ${ isNullOrUndefined(listItem.readDate) ? "sll__list-item--unread" : "" }` }
                role="button"
                onClick={ () => _onListItemClick(listItem.code) }>

                <div className="sll__list-item__info-wrapper">
                    <div className="sll__list-item__icon-wrapper">
                        <div className="sll__list-item__icon">
                            {
                                renderIconFunction(listItem, listType)
                            }
                        </div>
                        <div className="sll__list-item__notification">
                            {
                                this.renderStatus()
                            }
                        </div>
                    </div>

                    <div className="sll__list-item__information">

                        {
                            this.renderListItemInfo()
                        }

                        <p className="sll__list-item__date f-12">
                            {
                                moment(listItem.createdDate, MISC_DATE_ISO_FORMAT)
                                    .format(MISC_DATE_DAY_MONTH_YEAR_HOUR_MINUTE_FORMAT)
                            }
                        </p>
                    </div>
                </div>
                <div>
                    { /* place for delete report button*/ }
                </div>
            </li>
        );
    };

    //TODO refactor after changes to GO reports
    renderListItemInfo = () => {
        const { listItem, listType } = this.props;

        if (listType === ReportEnum.name) {
            return (
                <>
                    <p className="sll__list-item__title">
                        {
                            listItem.subject
                        }
                    </p>
                    <p className="sll__list-item__address f-12">
                        {
                            listItem.localization
                        }
                    </p>
                </>
            );
        }
        else if (listType === EkostraznikReportsEnum.name) {

            if (!isNullOrUndefined(listItem.location)) {
                return (
                    <>
                        <p className="sll__list-item__title">
                            {
                                EkostraznikReportsEnum.type[listItem.type].label
                            }
                        </p>
                        <p className="sll__list-item__address f-12">
                            {
                                listItem.location.town
                            }
                            {
                                !isNullOrUndefined(listItem.location.street) ? ", " + listItem.location.street : ""
                            }
                            {
                                " " + listItem.location.address
                            }
                        </p>
                    </>
                );
            }

            if (!isNullOrUndefined(listItem.group)) {
                return (
                    <>
                        <p className="sll__list-item__title">
                            {
                                EkostraznikReportsEnum.type[listItem.type].label
                            }
                        </p>
                        <p className="sll__list-item__address f-12">
                            {
                                listItem.group.name
                            }
                        </p>
                    </>
                );
            }

            return (
                <>
                    <p className="sll__list-item__title">
                        {
                            EkostraznikReportsEnum.type[listItem.type].label
                        }
                    </p>
                </>
            );
        }
    };

    renderStatus = () => {
        const { listType, listItem } = this.props;

        if (listType === ReportEnum.name) {
            switch (listItem.status.code) {
                case ReportEnum.status.NEW.code:
                    return ReportEnum.status.NEW.icon;

                case ReportEnum.status.IN_PROGRESS.code:
                    return ReportEnum.status.IN_PROGRESS.icon;

                case ReportEnum.status.DONE.code:
                    return ReportEnum.status.DONE.icon;

                case ReportEnum.status.REJECTED.code:
                    return ReportEnum.status.REJECTED.icon;

                default:
                    return "";
            }
        }
        else if (listType === EkostraznikReportsEnum.name) {
            switch (listItem.status.toLowerCase()) {
                case ReportEnum.status.NEW.code:
                    return ReportEnum.status.NEW.icon;

                case ReportEnum.status.IN_PROGRESS.code:
                    return ReportEnum.status.IN_PROGRESS.icon;

                case ReportEnum.status.DONE.code:
                    return ReportEnum.status.DONE.icon;

                case ReportEnum.status.REJECTED.code:
                    return ReportEnum.status.REJECTED.icon;

                default:
                    return "";
            }
        }
    };
}

export default SideListItem;
