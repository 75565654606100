import React, { Component } from "react";
import { InspectionStatusesEnum } from "../../../../../../core/enums/ekostraznik/inspection_statuses";
import { EKOSTRAZNIK_START_YEAR } from "../../../../../../core/constants";
import GenerateInspectionDocumentModalForm from "../generate_inspection_document";
import Select, { selectBgCustomStyles } from "../../../../../common/select";


class InspectionDetailsOptions extends Component {

    state = {
        filterOptions: [
            { value: "all", label: "Wszystko" },
            { value: InspectionStatusesEnum.statuses.PENDING.value, label: "Nierozstrzygnięta" },
            { value: InspectionStatusesEnum.statuses.PROCESSED.value, label: "Wszczęto postępowanie" },
            { value: InspectionStatusesEnum.statuses.REJECTED.value, label: "Odrzucono" }
        ],
        yearOptions: []
    };

    render = () => {
        const { filterOptions, yearOptions } = this.state;
        const { inspections, selectedFilter, _onFilterSelect, selectedYear, _onYearOptionSelect } = this.props;

        return (
            <>
                <div className="row">
                    <h2 className="title is-1">
                        Lista kontroli za okres
                    </h2>
                    <div className="input-size-s is-spaced--left-10">
                        <Select
                            options={ yearOptions }
                            value={ selectedYear }
                            styles={ selectBgCustomStyles }
                            isClearable={ false }
                            onChange={ option => _onYearOptionSelect(option) }/>
                    </div>
                </div>
                <div className="is-spaced--bottom-30">
                    <div className="row row--separated row--options flexible-height-wrap">
                        <div className="row__left is-v-centered wrapable">
                            <div className="options-item is-v-centered">
                                <span className="is-spaced--right-10">Pokaż statusy:</span>
                                <div className="input-size-m">
                                    <Select
                                        options={ filterOptions }
                                        value={ selectedFilter }
                                        isClearable={ false }
                                        onChange={ option => _onFilterSelect(option) }/>
                                </div>
                            </div>
                        </div>
                        <div className="row__right">
                            <GenerateInspectionDocumentModalForm inspections={ inspections }
                                                                 selectedYear={ selectedYear.value }/>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    componentDidMount = () => {
        this.generateYearOptions();
    };

    generateYearOptions = () => {
        const start_year = EKOSTRAZNIK_START_YEAR;
        const current_year = new Date().getFullYear();
        let yearOption = [];

        for (let i = current_year; i >= start_year; i--) {
            yearOption.push({ value: i, label: i });
        }

        this.setState({
            yearOptions: yearOption
        });
    };
}

export default InspectionDetailsOptions;