import React from "react";
import messageSentImg from "../../../../../../../../resource/img/icons/empty_list_icons/message-sent.png";
import { ROUTE_BLISKO_MESSAGES } from "../../../../../../../core/constants";
import { useHistory } from "react-router-dom";

const Step3 = ({ editDraftMode, currentStep, _onNewMessageClicked }) => {
    const history = useHistory();

    if (currentStep !== 3) {
        return null;
    }

    return (
        <div className="notifications-3__confirmation">
            <img src={ messageSentImg } alt=""/>
            <h2 className="notifications-3__confirmation-header is-bold">Zlecenie wysyłki zostało przyjęte</h2>
            {
                editDraftMode
                    ? <button className="btn btn--outlined"
                              type="button"
                              onClick={ () => history.push(ROUTE_BLISKO_MESSAGES, { selectTab: 1 }) }>
                        Powrót do listy
                    </button>
                    : <button className="btn btn--outlined"
                              type="button"
                              onClick={ _onNewMessageClicked }>
                        Wyślij nową wiadomość
                    </button>
            }

        </div>
    );

};

export default Step3;