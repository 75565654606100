import webClient from "../../../core/web_client";
import { isNullOrUndefined } from "../../../core/utils/misc_utils";
import { API_BASE_AKUN_ADMIN_PATH } from '../../../core/constants';

const PATH = `${ API_BASE_AKUN_ADMIN_PATH }/location-candidates`;

export const getTowns = () => {
    return webClient.get(`${ PATH }`);
};

export const getTownAddressesAndStreets = (townId) => {
    return webClient.get(`${ PATH }?townId=${ townId }`);
};

export const getStreetAddresses = (streetId) => {
    return webClient.get(`${ PATH }?streetId=${ streetId }`);
};

export const getStreetAddressesTo = (townId, streetId, from) => {
    return webClient.get(`${ PATH }?${ !isNullOrUndefined(streetId) ? "streetId=" + streetId : "townId=" + townId }&from=${ from }`);
};

export const getApartmentAddresses = (addressId) => {
    return webClient.get(`${ PATH }?addressId=${ addressId }`);
};
