import categories from "./categories";
import subcategories from "./subcategories";
import serviceCards from "./service_cards";
import keywords from "./keywords";
import responsibleEntities from "./responsible_entities"
import files from "./files";

export default (state = {}, action) => {
    return {
        ...state,
        categories: categories(state.categories, action),
        subcategories: subcategories(state.subcategories, action),
        serviceCards: serviceCards(state.serviceCards, action),
        keywords: keywords(state.keywords, action),
        responsibleEntities: responsibleEntities(state.responsibleEntities, action),
        files: files(state.files, action)
    };
}