import React from "react";
import { Field } from "formik";

const AreaCreateAndEditModalFormContent = ({ formikBag }) => {

    return (
        <Field
            name="name"
            render={ ({ field }) => (
                <div className='c-modal__body modal-size-m'>
                    <label>
                        <span className="is-sr-only">Nazwa obszaru</span>
                        <textarea
                            className={ `${ formikBag.errors.name ? "rejected" : "" }` }
                            placeholder="Wpisz nazwę obszaru, np.: Śródmieście"
                            cols="30" rows="10"
                            { ...field }/>

                        {
                            formikBag.errors.name &&
                            <span className="hint hint--rejected">{ formikBag.errors.name }</span>
                        }
                    </label>

                </div>
            ) }/>
    );
};


export default AreaCreateAndEditModalFormContent;