import _ from "lodash";

import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const getManyEmployees = () => dispatch => {
    dispatch({
        type: actions.common.API__EMPLOYEES__GET_MANY__STARTED
    });

    return api
        .common
        .employees
        .getManyEmployees()
        .then(response => {
            const extractedData = extractResponseData(response);

            const data = {
                slots: extractedData.slots,
                employees: _.mapKeys(extractedData.employees, "id")
            };

            dispatch({
                type: actions.common.API__EMPLOYEES__GET_MANY__SUCCESS,
                meta: {},
                payload: data,
                error: false
            });

            return data;
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__EMPLOYEES__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getEmployee = (id) => dispatch => {
    dispatch({
        type: actions.common.API__EMPLOYEES__GET__STARTED
    });

    return api
        .common
        .employees
        .getEmployee(id)
        .then(response => {
            dispatch({
                type: actions.common.API__EMPLOYEES__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__EMPLOYEES__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const addEmployee = (data) => dispatch => {
    dispatch({
        type: actions.common.API__EMPLOYEES__ADD__STARTED
    });

    return api
        .common
        .employees
        .addEmployee(data)
        .then(response => {
            dispatch({
                type: actions.common.API__EMPLOYEES__ADD__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__EMPLOYEES__ADD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: false
            });
        });
};

export const reinviteEmployee = (id) => dispatch => {
    dispatch({
        type: actions.common.API__EMPLOYEES__REINVITE__STARTED
    });

    return api
        .common
        .employees
        .reinviteEmployee(id)
        .then(response => {
            dispatch({
                type: actions.common.API__EMPLOYEES__REINVITE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__EMPLOYEES__REINVITE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: false
            });
        });
};

export const updateEmployee = (id, data) => dispatch => {
    dispatch({
        type: actions.common.API__EMPLOYEES__UPDATE__STARTED
    });

    return api
        .common
        .employees
        .updateEmployee(id, data)
        .then(response => {
            dispatch({
                type: actions.common.API__EMPLOYEES__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__EMPLOYEES__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: false
            });
        });
};

export const changeEmployeeAccountStatus = (id, accountStatus) => dispatch => {
    dispatch({
        type: actions.common.API__EMPLOYEES__PATCH_ACCOUNT_STATUS__STARTED
    });

    return api
        .common
        .employees
        .patchEmployee(id, { accountStatus })
        .then(response => {
            dispatch({
                type: actions.common.API__EMPLOYEES__PATCH_ACCOUNT_STATUS__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__EMPLOYEES__PATCH_ACCOUNT_STATUS__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const deleteEmployee = (id) => dispatch => {
    dispatch({
        type: actions.common.API__EMPLOYEES__DELETE__STARTED
    });

    return api
        .common
        .employees
        .deleteEmployee(id)
        .then(response => {
            dispatch({
                type: actions.common.API__EMPLOYEES__DELETE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__EMPLOYEES__DELETE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};