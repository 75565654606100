import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import InputSuggestions from "../input_suggestions";
import { LocationsEnum } from "../../../core/enums/waste_management/locations";
import {
    getManyLocationCandidatesApartmentAddresses,
    getManyLocationCandidatesStreetAddresses,
    getManyLocationCandidatesStreetAddressesTo,
    getManyLocationCandidatesTownAddressesAndStreets,
    resetLocationCandidates
} from "../../../data/actions/waste_management/location_candidates";
import {
    isEmptyArray,
    isEmptyObject,
    isNullOrUndefined,
    stringContainsSearchTerm
} from "../../../core/utils/misc_utils";
import { getManyLocationsForArea } from "../../../data/actions/waste_management/areas/locations";

const initialState = {
    town: "",
    street: "",
    from: "",
    to: "",
    address: "",
    apartmentAddress: "",
    selectedTown: null,
    selectedStreet: null,
    selectedFrom: null,
    selectedTo: null,
    selectedAddress: null,
    selectedApartmentAddress: null,
    selectedInput: null
};

const withLocationManagement = (OriginalComponent) => {

    class WrappedComponent extends Component {

        state = initialState;

        render = () => {
            const {
                locationCandidates,
                getManyLocationsForArea,
                getManyLocationCandidatesTownAddressesAndStreets,
                getManyLocationCandidatesStreetAddresses,
                getManyLocationCandidatesStreetAddressesTo,
                resetLocationCandidates
            } = this.props;

            return (
                <OriginalComponent
                    locationCandidates={ locationCandidates }
                    actions={ {
                        getManyLocationsForArea,
                        getManyLocationCandidatesTownAddressesAndStreets,
                        getManyLocationCandidatesStreetAddresses,
                        getManyLocationCandidatesStreetAddressesTo,
                        resetLocationCandidates
                    } }
                    values={ this.state }
                    renderSuggestions={ this.renderSuggestions }

                    inputDisabled={ this.inputDisabled }

                    _setFieldValue={ this._setFieldValue }
                    _resetForm={ this._resetForm }
                    _onInputFieldFocus={ this._onInputFieldFocus }
                    _onInputFieldBlur={ this._onInputFieldBlur }

                    _onNewApartmentAddressSelect={ this._onNewApartmentAddressSelect }

                    { ...this.props }/>
            );
        };

        // suggestions

        renderSuggestions = (type) => {
            const { selectedInput } = this.state;
            const { data, onSuggestionClickExtraParam, _onSuggestionClick } = this.suggestionsData(type);

            return (
                selectedInput === type &&
                !isEmptyArray(data) &&
                <InputSuggestions
                    dataArray={ data }
                    onSuggestionClickExtraParam={ onSuggestionClickExtraParam }
                    _onSuggestionClick={ _onSuggestionClick }/>
            );
        };

        suggestionsData = (type) => {
            const { town, street, from, to, selectedTown, address, apartmentAddress, selectedAddress } = this.state;

            switch (type) {

                case LocationsEnum.visualType.TOWN:
                    return {
                        data: this.generateTownsData(town),
                        _onSuggestionClick: this._onTownSelect
                    };

                case LocationsEnum.visualType.STREET:
                    return {
                        data: this.generateStreetData(selectedTown, street),
                        _onSuggestionClick: this._onStreetSelect
                    };

                case LocationsEnum.visualType.ADDRESS_FROM:
                    return {
                        data: this.generateAddressFrom(selectedTown, from),
                        _onSuggestionClick: this._onAddressFromSelect
                    };

                case LocationsEnum.visualType.ADDRESS_TO:
                    return {
                        data: this.generateAddressTo(selectedTown, to),
                        _onSuggestionClick: this._onAddressToSelect
                    };

                case LocationsEnum.visualType.ADDRESS:
                    return {
                        data: this.generateAddress(selectedTown, address),
                        _onSuggestionClick: this._onAddressSelect
                    };

                case LocationsEnum.visualType.APARTMENT_ADDRESS:
                    return {
                        data: this.generateApartmentAddress(selectedAddress, apartmentAddress),
                        _onSuggestionClick: this._onApartmentAddressSelect
                    };


                default:
                    return {
                        data: []
                    };
            }
        };

        // /suggestions

        inputDisabled = (type) => {

            const { towns, streets, addresses, addressesTo, apartmentAddresses } = this.props.locationCandidates;
            const { selectedTown, selectedStreet, selectedFrom, selectedAddress } = this.state;

            switch (type) {
                case LocationsEnum.visualType.TOWN:
                    return isNullOrUndefined(towns.data) || isEmptyObject(streets.data);

                case LocationsEnum.visualType.STREET:
                    return isNullOrUndefined(streets.data) || isEmptyObject(streets.data) || isNullOrUndefined(selectedTown);

                case LocationsEnum.visualType.ADDRESS_FROM:
                    return (isNullOrUndefined(addresses.data) || isEmptyObject(addresses.data)) || (isNullOrUndefined(selectedTown) && isNullOrUndefined(selectedStreet));

                case LocationsEnum.visualType.ADDRESS_TO:
                    return isNullOrUndefined(addressesTo.data) || isEmptyObject(addressesTo.data) || (isNullOrUndefined(selectedTown) && isNullOrUndefined(selectedStreet)) || isNullOrUndefined(selectedFrom);

                case LocationsEnum.visualType.ADDRESS:
                    return (isNullOrUndefined(addresses.data) || isEmptyObject(addresses.data)) || (isNullOrUndefined(selectedTown) && isNullOrUndefined(selectedStreet));

                case LocationsEnum.visualType.APARTMENT_ADDRESS:
                    return (isNullOrUndefined(apartmentAddresses.data) || isEmptyObject(apartmentAddresses.data)) || isEmptyObject(selectedAddress);

                default:
                    return false;
            }
        };

        // --
        // TODO: refactor generate* functions into something more generic...


        generateLabelForTown = (name, gmina, powiat) => {
            return (
                <>
                    { name }
                    {
                        powiat && gmina ?
                            <div style={ { color: "#848b98" } }>
                                powiat { powiat }, gmina { gmina }
                            </div>
                            : gmina ?
                            <div style={ { color: "#848b98" } }>
                                gmina { gmina }
                            </div>
                            : null
                    }
                </>
            )
        };

        generateTownsData = (town) => {
            const { towns } = this.props.locationCandidates;

            return _
                .filter(towns.data, locationCandidate => stringContainsSearchTerm(locationCandidate.name, town))
                .map(locationCandidate => {
                    return {
                        key: locationCandidate.id,
                        label: this.generateLabelForTown(locationCandidate.name, locationCandidate.gmina, locationCandidate.powiat),
                        data: locationCandidate
                    };
                });
        };

        generateAddressFrom = (selectedTown, from) => {
            const { addresses } = this.props.locationCandidates;

            if (isNullOrUndefined(selectedTown) || addresses.meta.loading) {
                return [];
            }

            return _
                .filter(addresses.data, locationCandidate => stringContainsSearchTerm(locationCandidate.number, from))
                .map(locationCandidate => {
                    return {
                        key: locationCandidate.id,
                        label: locationCandidate.number,
                        data: locationCandidate
                    };
                });
        };

        generateAddressTo = (selectedTown, to) => {
            const { addressesTo } = this.props.locationCandidates;

            if (isNullOrUndefined(selectedTown) || addressesTo.meta.loading) {
                return [];
            }

            return _
                .filter(addressesTo.data, locationCandidate => stringContainsSearchTerm(locationCandidate.number, to))
                .map(locationCandidate => {
                    return {
                        key: locationCandidate.id,
                        label: locationCandidate.number,
                        data: locationCandidate
                    };
                });
        };

        generateAddress = (selectedTown, address) => {
            return this.generateAddressFrom(selectedTown, address);
        };

        generateApartmentAddress = (selectedAddress, apartmentAddress) => {
            const { apartmentAddresses } = this.props.locationCandidates;

            if (isNullOrUndefined(selectedAddress) || apartmentAddresses.meta.loading) {
                return [];
            }

            return _
                .filter(apartmentAddresses.data, locationCandidate => stringContainsSearchTerm(locationCandidate.number, apartmentAddress))
                .map(locationCandidate => {
                    return {
                        key: locationCandidate.id,
                        label: locationCandidate.number,
                        data: locationCandidate
                    };
                });
        };

        generateStreetData = (selectedTown, street) => {
            const { streets } = this.props.locationCandidates;

            if (isNullOrUndefined(selectedTown) || streets.meta.loading) {
                return [];
            }

            return _
                .filter(streets.data, locationCandidate => stringContainsSearchTerm(locationCandidate.name, street))
                .map(locationCandidate => {
                    return {
                        key: locationCandidate.id,
                        label: locationCandidate.name,
                        data: locationCandidate
                    };
                });
        };

        // ---
        // TODO: refactor _on*Select functions into something more generic...

        _onTownSelect = (townData) => {
            const { getManyLocationCandidatesTownAddressesAndStreets, resetLocationCandidates, _onSelect } = this.props;

            this.setState({
                to: "",
                from: "",
                street: "",
                town: townData.name,
                selectedTo: null,
                selectedFrom: null,
                selectedStreet: null,
                selectedTown: townData,
                selectedApartmentAddress: null,
            }, () => {
                this._onInputFieldBlur();
                _onSelect && _onSelect(this.state);

                resetLocationCandidates(false, true, true, true);
                getManyLocationCandidatesTownAddressesAndStreets(townData.id);
            });
        };

        _onStreetSelect = (streetData) => {
            const { getManyLocationCandidatesStreetAddresses, resetLocationCandidates, _onSelect } = this.props;

            this.setState({
                to: "",
                from: "",
                street: streetData.name,
                selectedTo: null,
                selectedFrom: null,
                selectedStreet: streetData,
                selectedApartmentAddress: null,
            }, () => {
                this._onInputFieldBlur();
                _onSelect && _onSelect(this.state);

                resetLocationCandidates(false, false, true, true);
                getManyLocationCandidatesStreetAddresses(streetData.id);
            });
        };

        _onAddressFromSelect = (addressData) => {
            const { getManyLocationCandidatesStreetAddressesTo, resetLocationCandidates, _onSelect } = this.props;
            const { selectedTown, selectedStreet } = this.state;

            this.setState({
                to: "",
                address: "",
                apartmentAddress: "",
                from: addressData.number,
                selectedTo: null,
                selectedFrom: addressData,
                selectedAddress: null,
                selectedApartmentAddress: null,
            }, () => {
                this._onInputFieldBlur();
                _onSelect && _onSelect(this.state);

                const selectedTownId = !isNullOrUndefined(selectedTown) ? selectedTown.id : null;
                const selectedStreetId = !isNullOrUndefined(selectedStreet) ? selectedStreet.id : null;

                resetLocationCandidates(false, false, false, true);
                getManyLocationCandidatesStreetAddressesTo(selectedTownId, selectedStreetId, addressData.number);
            });
        };

        _onAddressToSelect = (addressData) => {
            const { _onSelect } = this.props;
            this.setState({
                to: addressData.number,
                selectedTo: addressData
            }, () => {
                this._onInputFieldBlur();
                _onSelect && _onSelect(this.state);
            });
        };

        _onAddressSelect = (addressData) => {
            const { resetLocationCandidates, getManyLocationCandidatesApartmentAddresses, _onSelect } = this.props;

            this.setState({
                to: "",
                address: addressData.number,
                apartmentAddress: "",
                from: "",
                selectedTo: null,
                selectedFrom: null,
                selectedAddress: addressData,
                selectedApartmentAddress: null,
            }, () => {
                this._onInputFieldBlur();
                _onSelect && _onSelect(this.state);

                resetLocationCandidates(false, false, false, false, true);
                getManyLocationCandidatesApartmentAddresses(addressData.id);
            });
        };

        _onApartmentAddressSelect = (apartmentData) => {
            const { _onSelect } = this.props;

            this.setState({
                apartmentAddress: apartmentData.number,
                selectedApartmentAddress: apartmentData,
            }, () => {
                this._onInputFieldBlur();
                _onSelect && _onSelect(this.state);
            });
        };

        _onNewApartmentAddressSelect = (apartmentData) => {
            const { _onSelect } = this.props;

            this.setState({
                selectedApartmentAddress: apartmentData,
            }, () => {
                this._onInputFieldBlur();
                _onSelect && _onSelect(this.state);
            });
        };


        // field behavior handlers

        _setFieldValue = (type, value) => {
            switch (type) {

                case LocationsEnum.visualType.TOWN:
                    this.setState({ town: value });
                    break;

                case LocationsEnum.visualType.STREET:
                    this.setState({ street: value });
                    break;

                case LocationsEnum.visualType.ADDRESS_FROM:
                    this.setState({ from: value });
                    break;

                case LocationsEnum.visualType.ADDRESS_TO:
                    this.setState({ to: value });
                    break;

                case LocationsEnum.visualType.ADDRESS:
                    this.setState({ address: value });
                    break;

                case LocationsEnum.visualType.APARTMENT_ADDRESS:
                    this.setState({ apartmentAddress: value });
                    break;

                default:
                // empty
            }
        };

        _resetForm = (values) => {
            this.setState({
                ...initialState,
                ...values
            });
        };

        _onInputFieldFocus = (id) => {
            this.setState({
                selectedInput: id
            });
        };

        _onInputFieldBlur = () => {
            this.setState({
                selectedInput: null
            });
        };

        // /field behavior handlers
    }

    const mapStateToProps = (state) => {
        return {
            locationCandidates: {
                towns: state.entities.wasteManagement.locationCandidates?.towns.getMany,
                streets: state.entities.wasteManagement.locationCandidates?.streets.getMany,
                addresses: state.entities.wasteManagement.locationCandidates?.addresses.getMany,
                addressesTo: state.entities.wasteManagement.locationCandidates?.addressesTo.getMany,
                apartmentAddresses: state.entities.wasteManagement.locationCandidates?.apartmentAddresses.getMany,
            }
        };
    };

    return connect(
        mapStateToProps, {
            getManyLocationsForArea,
            getManyLocationCandidatesTownAddressesAndStreets,
            getManyLocationCandidatesStreetAddresses,
            getManyLocationCandidatesStreetAddressesTo,
            getManyLocationCandidatesApartmentAddresses,
            resetLocationCandidates
        })(WrappedComponent);
};

export default withLocationManagement;
