import _ from "lodash";

import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const getManySubcategories = () => dispatch => {
    dispatch({
        type: actions.services.API__SUBCATEGORIES__GET_MANY__STARTED
    });

    return api
        .services
        .subcategories
        .getManySubcategories()
        .then(response => {
            dispatch({
                type: actions.services.API__SUBCATEGORIES__GET_MANY__SUCCESS,
                meta: {},
                payload: _.mapKeys(extractResponseData(response), "id"),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__SUBCATEGORIES__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const updateSubcategory = (subcategoryId, data) => dispatch => {
    dispatch({
        type: actions.services.API__SUBCATEGORIES__UPDATE__STARTED
    });

    return api
        .services
        .subcategories
        .updateSubcategory(subcategoryId, data)
        .then(response => {
            dispatch({
                type: actions.services.API__SUBCATEGORIES__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__SUBCATEGORIES__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                errors: true
            });
        });
};

export const deleteSubcategory = (subcategoryId) => dispatch => {
    dispatch({
        type: actions.services.API__SUBCATEGORIES__DELETE__STARTED
    });

    return api
        .services
        .subcategories
        .deleteSubcategory(subcategoryId)
        .then(response => {
            dispatch({
                type: actions.services.API__SUBCATEGORIES__DELETE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__SUBCATEGORIES__DELETE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                errors: true
            });
        });
};

export const getManySubcategoriesForCategory = (categoryId) => dispatch => {
    dispatch({
        type: actions.services.API__SUBCATEGORIES_FOR_CATEGORY__GET_MANY__STARTED
    });

    return api
        .services
        .subcategories
        .getManySubcategoriesForCategory(categoryId)
        .then(response => {
            dispatch({
                type: actions.services.API__SUBCATEGORIES_FOR_CATEGORY__GET_MANY__SUCCESS,
                meta: {},
                payload: _.mapKeys(extractResponseData(response), "id"),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__SUBCATEGORIES_FOR_CATEGORY__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                errors: true
            });
        });
};

export const addSubcategoryToCategory = (categoryId, data) => dispatch => {
    dispatch({
        type: actions.services.API__SUBCATEGORIES_FOR_CATEGORY__ADD__STARTED
    });

    return api
        .services
        .subcategories
        .addSubcategoryToCategory(categoryId, data)
        .then(response => {
            dispatch({
                type: actions.services.API__SUBCATEGORIES_FOR_CATEGORY__ADD__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__SUBCATEGORIES_FOR_CATEGORY__ADD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                errors: true
            });
        });
};