import { deepFreeze } from "../../utils/misc_utils";

export const UserRecordsEnum = deepFreeze({
    tab: {
        USER_CONSENTS: "USER_CONSENTS",
        USER_SUBSCRIPTIONS: "USER_SUBSCRIPTIONS"

    },
    status: {
        ACTIVE: "ACTIVE",
        PENDING: "PENDING",
        ARCHIVED: "ARCHIVED"
    },
    action: {
        REGISTRATION: "REGISTRATION",
        ACCEPTANCE: "ACCEPTANCE",
        UNSUBSCRIBE: "UNSUBSCRIBE"
    }
});