import React, { Component } from "react";
import { connect } from "react-redux";

import { setSidebarItemSelected } from "../../../data/actions/common/ui/sidebar";

const withModuleMainPage = (OriginalComponent) => {

    class WrappedComponent extends Component {

        render = () => {
            return (
                <OriginalComponent
                    _onSidebarItemSelected={ this._onSidebarItemSelected }
                    _onSubmenuItemSelected={ this._onSubmenuItemSelected }
                    { ...this.props }/>
            );
        };

        componentDidMount = () => {
            this._onSidebarItemSelected(null);
        };

        _onSidebarItemSelected = (index) => {
            this.props.setSidebarItemSelected({
                activeSidebarItem: index,
                activeSubmenuItem: null
            });
        };

        _onSubmenuItemSelected = (sidebarItemIndex, submenuItemIndex) => {
            this.props.setSidebarItemSelected({
                activeSidebarItem: sidebarItemIndex,
                activeSubmenuItem: submenuItemIndex,
                submenuExpanded: true
            });
        };
    }

    return connect(null, { setSidebarItemSelected })(WrappedComponent);
};

export default withModuleMainPage;