import React, { Component } from "react";
import _ from "lodash";

import { isEmptyArray, isNullOrUndefined } from "../../../../../../../../core/utils/misc_utils";
import { WasteCollectionPointsEnum } from "../../../../../../../../core/enums/waste_management/waste_collection_points";
import Select from "../../../../../../../common/select";

class CreateAndEditModalFormTypeSelect extends Component {

    render = () => {
        const { types } = this.props.formikBag.errors;
        const typeOptions = this._generateSelectOptions();
        const selected = this._generateDefaultSelected(typeOptions);

        return (
            <span className="is-relative is-block full-width">
                <Select
                    options={ typeOptions }
                    value={ selected }
                    className={ !isEmptyArray(types) ? "rejected" : "" }
                    onChange={ this._onInputChange }
                    isMulti={ true }
                />

                {
                    !isNullOrUndefined(types) &&

                    <span className="hint hint--rejected">{ types }</span>
                }
            </span>
        );
    };

    _generateSelectOptions = () => {
        return _.map(WasteCollectionPointsEnum.type, wasteCollectionPointType => {
            return {
                value: wasteCollectionPointType.code,
                label: wasteCollectionPointType.label
            };
        });
    };

    _generateDefaultSelected = (typeOptions) => {
        const { types } = this.props.formikBag.values;

        return typeOptions
            .map(option => {
                for (let i = 0; i < types.length; i++) {
                    if (option.value === types[i]) {
                        return option;
                    }
                }

                return null;
            })
            .filter(selectedOption => !isNullOrUndefined(selectedOption));
    };

    _onInputChange = (selectedOptions) => {
        const { formikBag } = this.props;

        const formikSelectedOptions = (selectedOptions || []).map(option => {
            return option.value;
        });

        formikBag.setFieldValue("types", formikSelectedOptions);
    };
}

export default CreateAndEditModalFormTypeSelect;
