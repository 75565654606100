import React from "react";

const SubcategoryEmptyList = () => {
    return (
        <div className="empty-element">
            <p>
                Pusta podkategoria. Dodaj kartę
            </p>
        </div>
    );
};


export default SubcategoryEmptyList;