import React, { Component } from "react";
import { connect } from "react-redux";
import TitleWithDivider from "../../../../../common/title_with_divider.js";
import WasteManagementRegulationsItem from "./item";
import SettingsRegulationsAddNew from "./add_new/";
import { downloadRegulation, getRegulations } from "../../../../../../data/actions/common/regulations";
import { isNullOrUndefined } from "../../../../../../core/utils/misc_utils";

class WasteManagementRegulations extends Component {

    render = () => {
        return (
            <div className="regulations">
                <TitleWithDivider>
                    Gospodarka odpadami
                </TitleWithDivider>
                <div className="options-table is-spaced--bottom-20">
                    <div className='table-wrapper'>
                        <table className='table--single-line fixed'>
                            <thead>
                            <tr>
                                <th className='t-width-4'>Miejsce</th>
                                <th className='t-width-2'>Obowiązuje od</th>
                                <th className='t-width-2'>Dodano</th>
                                <th className='t-width-2'>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.renderRegulationsList()
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                {
                    this.renderInstructions()
                }
                <div className="row row--bottom">
                    <SettingsRegulationsAddNew _onSubmitCallback={ this.props.getRegulations }/>
                </div>
            </div>
        );
    };

    componentDidMount = () => {
        this.props.getRegulations();
    };

    renderRegulationsList = () => {
        const { regulations } = this.props;


        if(isNullOrUndefined(regulations.data)){
            return
        }

        const regulationsToRender = regulations.data.regulationModelList
                                               .map((regulation, index) => {
                                                       return <WasteManagementRegulationsItem key={ index }
                                                                                              regulation={ regulation }
                                                                                              _onDownloadRegulationClick={ this._onDownloadRegulationClick }/>;
                                                   }
                                               );

        return regulationsToRender;
    };

    _onDownloadRegulationClick = (id, name) => {
        const { downloadRegulation } = this.props;

        return downloadRegulation(id, name)
    };

    renderInstructions = () => {
        const { regulations } = this.props;

        if(!regulations.data){
            return
        }

        if(!regulations.data.requiredRegulationsActive){
            return (
                <div>
                    <div className="instruction-list">
                        <h3>Aby aktywować Gospodarkę Odpadami w aplikacji Blisko:</h3>
                        <ul>
                            <li>Dodaj politykę prywatności</li>
                            <li>Dodaj politykę cookies</li>
                            <li>Dodaj regulamin świadczenia e-usługi Gospodarka Odpadami dla użytkownika</li>
                        </ul>
                    </div>
                    <div className="instruction-list">
                        <h3>Aby aktywować Gospodarkę Odpadami na swojej stronie internetowej:</h3>
                        <ul>
                            <li>Dodaj politykę prywatności</li>
                            <li>Dodaj politykę cookies</li>
                            <li>Dodaj regulamin świadczenia e-usługi Gospodarka Odpadami dla użytkownika</li>
                        </ul>
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        regulations: state.entities.common.regulations.get
    };
};


export default connect(mapStateToProps, { getRegulations, downloadRegulation })(WasteManagementRegulations);

