import React from "react";
import ComponentWithDropdown from "../../../../../../common/component_with_dropdown";

import { ReactComponent as IconCheckCircle } from "../../../../../../../../resource/img/icons/icon_check_circle.svg";
import { ReactComponent as IconEllipsis } from "../../../../../../../../resource/img/icons/icon_ellipsis.svg";
import { isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";
import { MunicipalWasteEnum } from "../../../../../../../core/enums/waste_management/municipal_waste";
import DropdownMenu from "../../../../../../common/dropdown_menu";
import CopyForm, { CopyFormTypeEnum } from "./copy_form";
import _ from 'lodash';

class MunicipalWasteMenuOptions extends ComponentWithDropdown {

    render = () => {
        const { _onPublishClicked, bins, areas, selectedArea, selectedYear, selectedBin } = this.props;
        const activeBins = _.filter(bins.data, bin => bin.status === "ACTIVE");

        return (
            <div className='row__right schedule-menu'>
                <button className='btn btn--big btn--primary header__main-cta-btn'
                        disabled={ !this.publishButtonEnabled() }
                        onClick={ _onPublishClicked }>
                    <span className="btn__icon-wrapper--left">
                        <span className='btn__icon'>
                            <IconCheckCircle/>
                        </span>
                        Opublikuj
                    </span>
                </button>

                <DropdownMenu
                    triggerComponent={
                        <button
                            className="btn btn--outlined btn--icon"
                            title='Więcej'
                        >
                            <IconEllipsis/>
                        </button>
                    }
                >
                    <CopyForm
                        formType={ CopyFormTypeEnum.BY_YEAR }
                        bins={ activeBins }
                        areas={ areas }
                        selectedArea={ selectedArea }
                        selectedYear={ selectedYear }
                        selectedBin={ selectedBin }
                    />
                    <CopyForm
                        formType={ CopyFormTypeEnum.BY_AREA }
                        bins={ activeBins }
                        areas={ areas }
                        selectedYear={ selectedYear }
                    />
                    <CopyForm
                        formType={ CopyFormTypeEnum.BY_BIN }
                        bins={ activeBins }
                        areas={ areas }
                        selectedYear={ selectedYear }
                    />
                </DropdownMenu>
            </div>
        );
    };

    publishButtonEnabled = () => {
        const { receptions } = this.props;

        if (isNullOrUndefined(receptions) ||
            isNullOrUndefined(receptions.data)) {
            return false;
        }

        // TODO: fugly
        for (let reception in receptions.data) {
            if (receptions.data.hasOwnProperty(reception)) {
                if (receptions.data[reception].status === MunicipalWasteEnum.reception.status.NEW ||
                    receptions.data[reception].status === MunicipalWasteEnum.reception.status.REMOVED) {
                    return true;
                }
            }
        }

        return false;
    };
}

export default MunicipalWasteMenuOptions;
