import React, { Component } from "react";
import { connect } from "react-redux";
import { Field } from "formik";

import ServicesCardAdditionalInfoKeywordsContent from "./content";
import { getManyKeywords } from "../../../../../../../data/actions/services/keywords";

class ServicesCardAdditionalInfoKeywords extends Component {

    state = {
        options: [],
        value: []
    };

    render = () => {
        const { options, value } = this.state;
        const { cardType } = this.props;

        return (
            <Field
                name="keywords"
                render={ ({ field, form }) => {

                    return (
                        <ServicesCardAdditionalInfoKeywordsContent
                            field={ field }
                            form={ form }
                            options={ options }
                            value={ value }
                            _setValue={ value => this.setState({ value })}
                            _setOptionsAndValue={ (options, value) => this.setState({ options, value }) }
                            cardType={ cardType }/>
                    );
                } }/>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        keywords: state.entities.services.keywords.getMany
    };
};

export default connect(mapStateToProps, { getManyKeywords })(ServicesCardAdditionalInfoKeywords);