import React, { Component } from "react";
import { Field } from "formik";

import TitleWithDivider from "../../../../../../common/title_with_divider";

class CompanyInfo extends Component {

    render = () => {
        return (
            <section className="is-spaced--bottom-30">
                <TitleWithDivider>
                    Dane firmy
                </TitleWithDivider>
                <div>
                    <div className="form--vertical">
                        <div className="columns is-multiline is-paddingless is-marginless">
                            <div className="column is-half field">
                                <Field
                                    name="address"
                                    render={ ({ field }) => (
                                        <label>
                                            <span className='label-name'>Adres</span>
                                            <input type="text"
                                                   { ...field }/>
                                        </label>
                                    ) }/>
                            </div>
                            <div className="column is-half field">
                                <Field
                                    name="phoneNumber"
                                    render={ ({ field }) => (
                                        <label>
                                            <span className='label-name'>Nr telefonu</span>
                                            <input type="text"
                                                   { ...field }/>
                                        </label>
                                    ) }/>
                            </div>
                            <div className="column is-half field">
                                <Field
                                    name="email"
                                    render={ ({ field }) => (
                                        <label>
                                            <span className='label-name'>E-mail</span>
                                            <input type="text"
                                                   { ...field }/>
                                        </label>
                                    ) }/>
                            </div>
                            <div className="column is-half field">
                                <Field
                                    name="webPageUrl"
                                    render={ ({ field }) => (
                                        <label>
                                            <span className='label-name'>Adres www</span>
                                            <input type="text"
                                                   { ...field }/>
                                        </label>
                                    ) }/>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    };

}

export default CompanyInfo;