import account from "./account";
import employees from "./employees";
import files from "./files";
import geocoding from "./geocoding";
import owners from "./owners";
import owner from "./owner";
import passwordChange from "./password_change";
import passwordResetRequest from "./password_reset_request";
import regulations from "./regulations";
import userConsents from "./user_consents";
import userSubscriptions from "./user_subscriptions";
import accessibilityStatement from "./accessibility_statement";

export default (state = {}, action) => {
    return {
        ...state,
        account: account(state.account, action),
        employees: employees(state.employees, action),
        files: files(state.files, action),
        geocoding: geocoding(state.geocoding, action),
        owners: owners(state.owners, action),
        owner: owner(state.owner, action),
        passwordChange: passwordChange(state.passwordChange, action),
        passwordResetRequest: passwordResetRequest(state.passwordResetRequest, action),
        regulations: regulations(state.regulations, action),
        userConsents: userConsents(state.userConsents, action),
        userSubscriptions: userSubscriptions(state.userSubscriptions, action),
        accessibilityStatement: accessibilityStatement(state.accessibilityStatement, action),
    };
}
