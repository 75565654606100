import {
    DEFAULT_STATE,
    DEFAULT_STATE__API__FAIL,
    DEFAULT_STATE__API__STARTED,
    DEFAULT_STATE__API__SUCCESS,
    DEFAULT_STATE_ARRAY
} from "../../../../../../core/utils/store_utils";
import { actions } from "../../../../../../core/constants";

export default (state = DEFAULT_STATE_ARRAY, action) => {
    switch (action.type) {
        case actions.ekostraznik.API__EKO_ADDRESSES__ADDRESS_GROUPS__UPDATE_NEST__STARTED:
            return DEFAULT_STATE__API__STARTED(state, DEFAULT_STATE);

        case actions.ekostraznik.API__EKO_ADDRESSES__ADDRESS_GROUPS__UPDATE_NEST__SUCCESS:
            return DEFAULT_STATE__API__SUCCESS(state, DEFAULT_STATE, action);

        case actions.ekostraznik.API__EKO_ADDRESSES__ADDRESS_GROUPS__UPDATE_NEST__FAIL:
            return DEFAULT_STATE__API__FAIL(state, DEFAULT_STATE, action);

        case actions.common.UI__AUTHENTICATION__SIGN_OUT:
            return DEFAULT_STATE;

        default:
            return state;
    }
}