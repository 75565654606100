import React, { Component } from "react";

import withMap from "../hoc/with_map";
import _ from "lodash";

class MessageSendingStep2Map extends Component {

    render = () => {
        const { mapboxContainerRef } = this.props;

        return (
            <div className="notifications-2__row notifications-2__row--flex notifications-2__row--map">
                <p className="notifications-2__label">Miejsce:</p>
                <div ref={ mapboxContainerRef } className="map-wrapper"/>
            </div>
        );
    };

    componentDidMount = () => {
        const { originalComponentInstanceRef } = this.props;
        // hook for accessing this components instance in the HOC
        originalComponentInstanceRef.current = this;
    };

    shouldComponentUpdate = (nextProps) => {
        const { reverse, longitude, latitude } = this.props;

        if (!_.isEqual(reverse, nextProps.reverse)) {
            return true;
        } else if (!_.isEqual(longitude, nextProps.longitude) ||
            !_.isEqual(latitude, nextProps.latitude)) {
            return true;
        }

        return false;
    };
}

export default withMap(MessageSendingStep2Map);
