import add from "./add";
import del from "./del";
import update from "./update";

export default (state = {}, action) => {
    return {
        ...state,
        add: add(state.add, action),
        delete: del(state.del, action),
        update: update(state.update, action),
    };
}
