import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData, exportFile } from "../../../core/utils/api_utils";


export const getUserConsents = () => dispatch => {
    dispatch({
        type: actions.common.API__USER_CONSENTS__GET__STARTED
    });

    return api
        .common
        .logs
        .getUserConsents()
        .then(response => {
            dispatch({
                type: actions.common.API__USER_CONSENTS__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__USER_CONSENTS__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getUserConsentsExport = () => dispatch => {
    dispatch({
        type: actions.common.API__USER_CONSENTS_EXPORT__GET__STARTED
    });

    return api
        .common
        .logs
        .getUserConsentsExport()
        .then(response => {
            dispatch({
                type: actions.common.API__USER_CONSENTS_EXPORT__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });

            exportFile(response.data, 'zgody_uzytkownikow.xlsx')
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__USER_CONSENTS_EXPORT__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};


export const getUserSubscriptions = () => dispatch => {
    dispatch({
        type: actions.common.API__USER_SUBSCRIPTIONS__GET__STARTED
    });

    return api
        .common
        .logs
        .getUserSubscriptions()
        .then(response => {
            dispatch({
                type: actions.common.API__USER_SUBSCRIPTIONS__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__USER_SUBSCRIPTIONS__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getUserSubscriptionsExport = () => dispatch => {
    dispatch({
        type: actions.common.API__USER_SUBSCRIPTIONS_EXPORT__GET__STARTED
    });

    return api
        .common
        .logs
        .getUserSubscriptionsExport()
        .then(response => {
            dispatch({
                type: actions.common.API__USER_SUBSCRIPTIONS_EXPORT__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });

            exportFile(response.data, 'subskrypcje_uzytkownikow.xlsx')
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__USER_SUBSCRIPTIONS_EXPORT__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};