import webClient, { webClientFileDownload } from "../../../core/web_client";
import { API_BASE_AKUN_ADMIN_PATH, MISC_REST_CONTENT_TYPE_APPLICATION_JSON } from "../../../core/constants";

const PATH = `${ API_BASE_AKUN_ADMIN_PATH }/regulations`;
const PATH_REGULATION_PLACES = `${ API_BASE_AKUN_ADMIN_PATH }/regulation-places`;


export const getRegulationPlaces = () => {
    return webClient
        .get(`${ PATH_REGULATION_PLACES }`);
};

export const getRegulations = () => {
    return webClient
        .get(`${ PATH }`);
};

export const addRegulation = (data) => {
    let body = new FormData();

    const form = {
        fromDate: data.fromDate,
        regulationPlaceCode: data.regulationPlaceCode
    };
    const formJson = JSON.stringify(form);
    const formBlob = new Blob([formJson], {
        type: MISC_REST_CONTENT_TYPE_APPLICATION_JSON
    });

    body.append("file", data.file);
    body.append("form", formBlob);


    return webClient
        .post(`${ PATH }`, body, {
            headers: {
                API_CONTENT_TYPE_HEADER: "multipart/form-data"
            }
        });
};

export const deleteRegulation = (id) => {
    return webClient
        .delete(`${ PATH }/${ id }`);
};

export const downlowadRegulation = (id) => {
    return webClientFileDownload
        .get(`${ PATH }/${ id }/pdf`);
};
