import React from "react";
import { deepFreeze } from "../../utils/misc_utils";

import { ReactComponent as IconNew } from "../../../../resource/img/icons/status/icon_to_do.svg";
import { ReactComponent as IconRejected } from "../../../../resource/img/icons/status/icon_rejected.svg";
import { ReactComponent as IconDone } from "../../../../resource/img/icons/status/icon_done.svg";

export const InspectionStatusesEnum = deepFreeze({
    statuses: {
        PENDING: {
            label: "Nierozstrzygnięta",
            value: "PENDING",
            command: "set-as-pending",
            icon: <IconNew/>
        },
        PROCESSED: {
            label: "Wszczęto postępowanie",
            value: "PROCESSED",
            command: "set-as-processed",
            icon: <IconDone/>
        },
        REJECTED: {
            label: "Odrzucono",
            value: "REJECTED",
            command: "set-as-rejected",
            icon: <IconRejected/>
        }
    }
});