import React, { Component } from "react";
import { connect } from "react-redux";

import UnderlinedHeader from "../../../../common/header_underlined";
import { FileUpload, UploadedFile } from "../../../../common/file_upload";
import { addFile, resetFileState } from "../../../../../data/actions/services/files";
import { isEmptyArray, isNullOrUndefined } from "../../../../../core/utils/misc_utils";
import { toastForCustomError } from "../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../data/actions/common/ui/toast";
import { MISC_MAX_SERVICES_ATTACHMENT_SIZE } from "../../../../../core/constants";

class ServicesCardFiles extends Component {

    state = {
        uploadingFiles: [],
        uploadedFiles: []
    };

    render = () => {
        const { step } = this.props;
        const { uploadingFiles } = this.state;

        return (
            <section className="media mw-100">
                <div className="media-left step-count-wrap">
                    <div className="step-count">
                        <span>{ step }</span>
                    </div>
                </div>
                <div className="media-content media-content--steps">
                    <UnderlinedHeader text="Pliki do pobrania"/>
                    <p className="is-spaced--bottom-30">Nazwa pliku musi zawierać informację dla osób niewidzących odnośnie jego zawartości.</p>
                    <div>

                        {
                            this.renderUploadedFiles()
                        }

                        {
                            this.renderUploadingFiles()
                        }

                        <FileUpload
                            disabled={ !isEmptyArray(uploadingFiles) }
                            _onStart={ this._onFileUploadStarted }
                            maxSingleFileSizeInMB={ 2 }
                            acceptedFileExtensions={ ['jpg', 'jpeg', 'png', 'pdf', 'docx', 'doc', 'odt'] }
                        />
                    </div>
                </div>
            </section>
        );
    };

    componentDidMount = () => {
        const { resetFileState, loadedAttachments } = this.props;
        resetFileState();

        this.setState({
            uploadedFiles: loadedAttachments
        });
    };

    componentWillUnmount = () => {
        const { resetFileState } = this.props;
        resetFileState();
    };

    componentDidUpdate = (prevProps) => {
        const { lastUploadedFile, fileIds, _setFormikFieldValue, showToast } = this.props;
        const { uploadedFiles, uploadingFiles } = this.state;

        // file upload success
        if (!isNullOrUndefined(lastUploadedFile) &&
            !isNullOrUndefined(lastUploadedFile.data) &&
            !lastUploadedFile.meta.loading &&
            !lastUploadedFile.error &&
            prevProps.lastUploadedFile !== lastUploadedFile) {

            const stillUploadingFiles = uploadingFiles
                .filter(file => file.name !== lastUploadedFile.data.name && file.size !== lastUploadedFile.data.size);

            this.setState({
                uploadingFiles: stillUploadingFiles,
                uploadedFiles: [...uploadedFiles, lastUploadedFile.data]
            });

            _setFormikFieldValue("fileIds", [...fileIds, lastUploadedFile.data.id]);
        }
        // file upload fail
        else if (!isNullOrUndefined(lastUploadedFile) &&
            !lastUploadedFile.meta.loading &&
            lastUploadedFile.error &&
            prevProps.lastUploadedFile !== lastUploadedFile) {

            const stillUploadingFiles = uploadingFiles
                .filter(file => file.name !== lastUploadedFile.meta.name && file.size !== lastUploadedFile.meta.size);

            this.setState({
                uploadingFiles: stillUploadingFiles
            });

            if (lastUploadedFile.meta.size > MISC_MAX_SERVICES_ATTACHMENT_SIZE) {
                return toastForCustomError(showToast, "Rozmiar załącznika przekracza dopuszczalny limit");
            }
        }
    };

    renderUploadingFiles = () => {
        const { uploadingFiles } = this.state;

        return uploadingFiles.map(file => {
            return (
                <UploadedFile
                    key={ file.name }
                    name={ file.name }
                    alternativeTextRequired={ false }
                    isLoading={ true }/>
            );
        });
    };

    renderUploadedFiles = () => {
        const { uploadedFiles } = this.state;

        return uploadedFiles.map(file => {
            return (
                <UploadedFile
                    key={ file.id }
                    id={ file.id }
                    name={ file.name }
                    alternativeTextRequired={ false }
                    isLoading={ false }
                    _onDeleteUploadedFileClick={ this._onDeleteUploadedFileClick }/>
            );
        });
    };

    _onFileUploadStarted = (fileArray) => {
        const { addFile } = this.props;
        const { uploadingFiles } = this.state;

        const newUploadingFiles = uploadingFiles;
        fileArray.forEach(file => newUploadingFiles.push(file));

        this.setState({
            uploadingFiles: newUploadingFiles
        }, () => {
            newUploadingFiles.forEach(file => addFile(file));
        });
    };

    _onDeleteUploadedFileClick = (id) => {
        const { fileIds, _setFormikFieldValue } = this.props;
        const { uploadedFiles } = this.state;

        if (!id || isEmptyArray(uploadedFiles)) {
            return;
        }

        let i = 0;
        for (; i < uploadedFiles.length; i++) {
            if (uploadedFiles[i].id === id) {
                break;
            }
        }

        const newUploadedFiles = uploadedFiles;
        newUploadedFiles.splice(i, 1);

        this.setState({
            uploadedFiles: newUploadedFiles
        });

        _setFormikFieldValue("fileIds", fileIds.filter(fileId => fileId !== id));
    };
}

const mapStateToProps = (state) => {
    return {
        lastUploadedFile: state.entities.services.files.add
    };
};

export default connect(mapStateToProps, { resetFileState, addFile, showToast })(ServicesCardFiles);