import { actions } from "../../../core/constants";
import * as api from "../../_api";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const addInventory = (inventory) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKOSTRAZNIK_INVENTORIES__ADD_INVENTORY__STARTED
    });

    return api
        .ekostraznik
        .inventories
        .addInventory(inventory)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKOSTRAZNIK_INVENTORIES__ADD_INVENTORY__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKOSTRAZNIK_INVENTORIES__ADD_INVENTORY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
            return error.response;
        });
};

export const getInventory = (id) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKOSTRAZNIK_INVENTORIES__GET__STARTED
    });

    return api
        .ekostraznik
        .inventories
        .get(id)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKOSTRAZNIK_INVENTORIES__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKOSTRAZNIK_INVENTORIES__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const deleteManyInventories = (data) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKOSTRAZNIK_INVENTORIES__DELETE_MANY__STARTED
    });

    return api
        .ekostraznik
        .inventories
        .deleteMany(data)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKOSTRAZNIK_INVENTORIES__DELETE_MANY__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKOSTRAZNIK_INVENTORIES__DELETE_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};