import React from "react";
import { Redirect, Route } from "react-router-dom";

import { isSignedIn } from "../utils/session_utils";
import { isNullOrUndefined } from "../utils/misc_utils";
import { ROUTE_SIGN_IN } from "../constants";

const SignedInAppRoute = ({ component: Component, layout: Layout, authentication, storageLoaded, ...rest }) => {
    if (!isNullOrUndefined(storageLoaded) && !storageLoaded) {
        return (
            <Route { ...rest } render={ props => (
                <Layout history={ props.history } { ...rest }>
                    {/* TODO: loading screen */ }
                </Layout>
            ) }/>
        );
    }

    if (isSignedIn(authentication)) {
        return (
            <Route { ...rest } render={ props => (
                <Layout history={ props.history } { ...rest }>
                    <Component { ...props } />
                </Layout>
            ) }/>
        );
    }

    return (
        <Route { ...rest } render={ props => (
            <Redirect to={ { pathname: ROUTE_SIGN_IN, state: { from: props.location } } }/>
        ) }/>
    );
};

export default SignedInAppRoute;