import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import WasteCollectionPointsListHeader from "./header";
import WasteCollectionPointsListItem from "./item";
import { getManyWasteCollectionPoints } from "../../../../../../../data/actions/waste_management/waste_collection_points";
import { isEmptyArray, isNullOrUndefined, stringContainsSearchTerm } from "../../../../../../../core/utils/misc_utils";
import { WasteCollectionPointsEnum } from "../../../../../../../core/enums/waste_management/waste_collection_points";
import Loader from "../../../../../../common/loader";

class WasteCollectionPointsList extends Component {

    render = () => {
        const { wasteCollectionPoints, searchBarTerm } = this.props;
        const filteredWasteCollectionPoints = this._filterWasteCollectionPointsBySearchTerm(wasteCollectionPoints, searchBarTerm);

        if (wasteCollectionPoints.meta.loading) {
            return <Loader/>;
        }

        return (
            <section className="main-content--table__bottom container is-fluid">
                <div className='table-wrapper'>
                    <table className='table--single-line fixed'>
                        <thead>
                        <WasteCollectionPointsListHeader/>
                        </thead>
                        {
                            this.renderUnpublishedWasteCollectionPoints(filteredWasteCollectionPoints)
                        }
                        {
                            this.renderPublishedWasteCollectionPoints(filteredWasteCollectionPoints)
                        }
                    </table>
                </div>
            </section>
        );

    };

    componentDidMount = () => {
        this._loadWasteCollectionPoints();
    };

    renderUnpublishedWasteCollectionPoints = (wasteCollectionPoints) => {
        if (isNullOrUndefined(wasteCollectionPoints)) {
            return null;
        }

        const wasteCollectionPointsToRender = wasteCollectionPoints
            .filter(wasteCollectionPoint =>
                !isNullOrUndefined(wasteCollectionPoint) &&
                !isNullOrUndefined(wasteCollectionPoint.status) &&
                wasteCollectionPoint.status.value === WasteCollectionPointsEnum.status.INVISIBLE.id)
            .map(wasteCollectionPoint =>
                <WasteCollectionPointsListItem
                    key={ wasteCollectionPoint.id }
                    showUnpublishedBadge={ true }
                    wasteCollectionPoint={ wasteCollectionPoint }
                    _onSubmitCallback={ this._loadWasteCollectionPoints }/>
            );

        if (isNullOrUndefined(wasteCollectionPointsToRender) || isEmptyArray(wasteCollectionPointsToRender)) {
            return null;
        }

        return (
            <tbody className="prepared-for-publication">
            {
                wasteCollectionPointsToRender
            }
            </tbody>
        );
    };

    renderPublishedWasteCollectionPoints = (wasteCollectionPoints) => {
        if (isEmptyArray(wasteCollectionPoints)) {
            return null;
        }

        const wasteCollectionPointsToRender = wasteCollectionPoints
            .filter(wasteCollectionPoint =>
                !isNullOrUndefined(wasteCollectionPoint) &&
                !isNullOrUndefined(wasteCollectionPoint.status) &&
                wasteCollectionPoint.status.value === WasteCollectionPointsEnum.status.VISIBLE.id)
            .map(wasteCollectionPoint =>
                <WasteCollectionPointsListItem
                    key={ wasteCollectionPoint.id }
                    showUnpublishedBadge={ false }
                    wasteCollectionPoint={ wasteCollectionPoint }
                    _onSubmitCallback={ this._loadWasteCollectionPoints }/>
            );

        if (isEmptyArray(wasteCollectionPointsToRender)) {
            return null;
        }

        return (
            <tbody>
            {
                wasteCollectionPointsToRender
            }
            </tbody>
        );
    };

    _filterWasteCollectionPointsBySearchTerm = (wasteCollectionPoints, searchTerm) => {
        if (isNullOrUndefined(wasteCollectionPoints)
            || isNullOrUndefined(wasteCollectionPoints.data)
            || wasteCollectionPoints.meta.loading) {
            return null;
        }

        return _.map(wasteCollectionPoints.data, wasteCollectionPoint => {
            if (stringContainsSearchTerm(wasteCollectionPoint.location, searchTerm)) {
                return wasteCollectionPoint;
            }
        });
    };

    _loadWasteCollectionPoints = () => {
        this.props.getManyWasteCollectionPoints();
    };
}

export default connect(null, { getManyWasteCollectionPoints })(WasteCollectionPointsList);