import { API_BASE_AKUN_BLISKO_PATH } from "../../../core/constants";
import webClient from "../../../core/web_client";

const PATH = `${API_BASE_AKUN_BLISKO_PATH}/messages/addresses`;

export const getMany = () => {
    return webClient
        .get(PATH);
};

export const addUnits = (unitIds) => {
    return webClient
        .patch(PATH, {
            command: 'add-units',
            unitIds
        });
};

export const addUnitsRange = (unitIdFrom, unitIdTo) => {
    return webClient
        .patch(PATH, {
            command: 'add-address-range',
            fromId: unitIdFrom,
            toId: unitIdTo
        });
};

export const addAddressTemplate = (addressTemplateId) => {
    return webClient
        .patch(PATH, {
            command: 'add-address-template-units',
            addressTemplateId
        });
};

export const removeUnits = (unitIds) => {
    return webClient
        .patch(PATH, {
            command: 'remove-units',
            unitIds
        });
};