import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import InspectionCompaniesItem from "./item";

import { TileCreateTrigger } from "../../../../../common/tiles";
import InspectionCompaniesCreateAndEditModalForm from "./create_and_edit_modal_form";
import { getManyInspectionCompanies } from "../../../../../../data/actions/ekostraznik/inspection_companies";
import Loader from "../../../../../common/loader";

class InspectionCompaniesList extends Component {

    render = () => {
        const { inspectionCompaniesList } = this.props;

        if (inspectionCompaniesList.meta.loading) {
            return <Loader/>;
        }

        return (
            <div className='tiles-wrapper'>
                <InspectionCompaniesCreateAndEditModalForm
                    triggerComponent={ <TileCreateTrigger title="Dodaj kontrolera"/> }
                    ariaLabel="Dodaj kontrolera"
                    headerText="Dodaj kontrolera"
                    _onSubmitCallback={ this._loadCompanies }/>
                {
                    this.renderListItems()
                }
            </div>
        );
    };

    componentDidMount = () => {
        this._loadCompanies();
    };

    renderListItems = () => {
        const { inspectionCompaniesList } = this.props;
        const sortedInspectionCompaniesList = _.sortBy(inspectionCompaniesList.data, [company => company.name.toLowerCase(), "name"]);

        return _.map(sortedInspectionCompaniesList, inspectionCompany => {
            return (
                <InspectionCompaniesItem
                    key={ inspectionCompany.id }
                    inspectionCompany={ inspectionCompany }
                    _onSubmitCallback={ this._loadCompanies }/>
            );
        });
    };

    _loadCompanies = () => {
        return this.props.getManyInspectionCompanies();
    };
}

const mapStateToProps = (state) => {
    return {
        inspectionCompaniesList: state.entities.ekostraznik.inspectionCompanies.getMany
    };
};

export default connect(mapStateToProps, { getManyInspectionCompanies })(InspectionCompaniesList);
