import React from "react";
import _ from "lodash";

import { deepFreeze, isNullOrUndefinedOneOfMany } from "../../utils/misc_utils";

//statuses
import { ReactComponent as IconInProgress } from "../../../../resource/img/icons/status/icon_in_progress.svg";
import { ReactComponent as IconNew } from "../../../../resource/img/icons/status/icon_to_do.svg";
import { ReactComponent as IconRejected } from "../../../../resource/img/icons/status/icon_rejected.svg";
import { ReactComponent as IconDone } from "../../../../resource/img/icons/status/icon_done.svg";

//types
import { ReactComponent as IconUncollected } from "../../../../resource/img/icons/reports/types/icon_uncollected.svg";
import { ReactComponent as IconDump } from "../../../../resource/img/icons/reports/types/icon_dump.svg";
import { ReactComponent as IconOther } from "../../../../resource/img/icons/reports/types/icon_other.svg";
import { ReactComponent as IconContainer } from "../../../../resource/img/icons/reports/types/icon_container.svg";
import { ReactComponent as IconTrashbag } from "../../../../resource/img/icons/reports/types/icon_trashbag.svg";
import { ReactComponent as IconBin } from "../../../../resource/img/icons/reports/types/icon_bin.svg";
import { ReactComponent as IconSmoke } from "../../../../resource/img/icons/reports/types/icon_smoke.svg";

export const ReportEnum = deepFreeze({
    name: "Reports",
    type: {
        UNCOLLECTED_WASTE: {
            code: "uncollected_waste",
            label: "Nieodebrane odpady",
            icon: <IconUncollected/>
        },
        WILD_DUMP: {
            code: "wild_dump",
            label: "Dzikie wysypisko",
            icon: <IconDump/>
        },
        OTHER: {
            code: "other",
            label: "Inne",
            icon: <IconOther/>
        },
        ORDER_CONTAINER: {
            code: "order_container",
            label: "Zamów kontener",
            icon: <IconContainer/>
        },
        ORDER_TRASHBAG: {
            code: "order_trashbag",
            label: "Zamów worek",
            icon: <IconTrashbag/>
        },
        ORDER_BIN: {
            code: "order_bin",
            label: "Zamów kubeł",
            icon: <IconBin/>
        },
        SMOKE: {
            code: "smoke",
            label: "Zgłoś kopciucha",
            icon: <IconSmoke/>
        },
        INVALID_SEGREGATION: {
            code: "invalid_segregation",
            label: "Niepoprawna segregacja"
        }
    },
    status: {
        NEW: {
            code: "new",
            icon: <IconNew/>
        },
        IN_PROGRESS: {
            code: "in_progress",
            icon: <IconInProgress/>
        },
        DONE: {
            code: "done",
            icon: <IconDone/>
        },
        REJECTED: {
            code: "rejected",
            icon: <IconRejected/>
        }
    }
});

export const getTypeOptions = () => {
    return _.map(ReportEnum.type, type => {
        return {
            label: type.label,
            data: type
        };
    });
};

export const getStatusByCode = (code) => {
    for (let key in ReportEnum.status) {
        if (ReportEnum.status.hasOwnProperty(key) &&
            ReportEnum.status[key].code === code) {
            return ReportEnum.status[key];
        }
    }

    return null;
};

export const isDisabled = (current, selected) => {
    if (isNullOrUndefinedOneOfMany(current, selected)) {
        return false;
    }

    switch (selected) {

        case ReportEnum.status.NEW:
            if (current === ReportEnum.status.NEW) {
                return true;
            }

            return false;

        case ReportEnum.status.IN_PROGRESS:
            if (current === ReportEnum.status.NEW ||
                current === ReportEnum.status.IN_PROGRESS) {
                return true;
            }
            return false;

        case ReportEnum.status.DONE:
            return true;

        case ReportEnum.status.REJECTED:
            return true;

        default:
            return false;
    }
};