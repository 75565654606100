import React, { Component } from "react";
import { connect } from "react-redux";

import GenerateInspectionDocumentModalFormContent from "./content";
import FormModal from "../../../../../common/modal/form_modal";
import { ReactComponent as IconExport } from "../../../../../../../resource/img/icons/icon_export_document.svg";
import { generateInspectionDocument } from "../../../../../../data/actions/ekostraznik/inspection_history";
import { isEmptyArray } from "../../../../../../core/utils/misc_utils";

class GenerateInspectionDocumentModalForm extends Component {

    state = {
        selectedInspections: [],
        error: ""
    };

    render = () => {
        const { selectedInspections, error } = this.state;
        const { inspections, selectedYear,inspectionDocumentGenerate } = this.props;


        return (
            <FormModal
                saveButtonText="Wygeneruj dokument kontroli"
                triggerComponent={
                    <button className="btn btn--primary">
                        <span className="btn__icon-wrapper--left">
                            <span className='btn__icon'>
                                <IconExport/>
                            </span>
                            Wygeneruj dokument kontroli
                         </span>
                    </button>
                }
                ariaLabel={ `Wygeneruj dokument kontroli za ${ selectedYear } rok` }
                headerText={ `Wygeneruj dokument kontroli za ${ selectedYear } rok` }
                saveOrCancelButtons={ true }
                isLoading={ inspectionDocumentGenerate.meta.loading }
                submitDisabled={ isEmptyArray(selectedInspections)}
                _actionFunction={ () => {
                    return this._onSubmit()
                } }>

                <GenerateInspectionDocumentModalFormContent
                    inspections={ inspections }
                    selectedYear={ selectedYear }
                    error={ error }
                    selectedInspections={ selectedInspections }
                    _resetForm={ this.resetForm }
                    _onInspectionClick={ this._onInspectionClick }
                />
            </FormModal>
        );
    };

    resetForm = () => {
        return this.setState({
            selectedInspections: [],
            error: ""
        });
    };

    _onInspectionClick = (inspectionCode) => {
        const { selectedInspections } = this.state;

        let indexOfInspection = selectedInspections.indexOf(inspectionCode);

        if (indexOfInspection === -1) {
            selectedInspections.push(inspectionCode);
        }
        else {
            selectedInspections.splice(indexOfInspection, 1);
        }

        this.setState({
            selectedInspections
        });
    };

    _onSubmit = () => {
        const { generateInspectionDocument } = this.props;
        const { selectedInspections } = this.state;

        return generateInspectionDocument({ command: "generate-inspection-document", inspectionCodes: selectedInspections })
                .then(() => this.resetForm);
    };
}

const mapStateToProps = (state) => {
    return {
        inspectionDocumentGenerate: state.entities.ekostraznik.inspectionHistory.generate
    };
};
export default connect(mapStateToProps, { generateInspectionDocument })(GenerateInspectionDocumentModalForm);

