import React, { Component } from "react";
import _ from "lodash";
import renderHTML from "react-render-html";

import Loader from "../../../../../common/loader";
import FoldableContainer from "../../../../../common/foldable_container";
import TitleWithDivider from "../../../../../common/title_with_divider";
import TinyEditor from "../../../../../common/tiny_editor";
import { isEmptyObject, isLoading, isLoadingError, isNullOrUndefined, isNullOrUndefinedOneOfMany } from "../../../../../../core/utils/misc_utils";
import { ReactComponent as IconMail } from "../../../../../../../resource/img/icons/icon_mail.svg";

const initialState = {
    textBoxActive: false,
    textBoxContent: "",
};

class ReportDetailsResponseHistory extends Component {

    state = initialState;

    render = () => {
        const { selectedReportResponses } = this.props;

        if (isLoadingError(selectedReportResponses)) {
            // TODO
            return (
                <div>
                    Błąd wczytywania historii odpowiedzi
                </div>
            );
        }

        if (isLoading(selectedReportResponses)) {
            return (
                <Loader/>
            );
        }

        return (
            <>
                <div className="report-details__section">
                    {
                        !isNullOrUndefinedOneOfMany(selectedReportResponses, selectedReportResponses.data)
                        && !isEmptyObject(selectedReportResponses.data) &&
                        <>
                            <TitleWithDivider>
                                Historia odpowiedzi
                            </TitleWithDivider>

                            {
                                _.map(selectedReportResponses.data, reportResponse => (
                                    <FoldableContainer
                                        key={ reportResponse.createdDate }
                                        icon={ <IconMail/> }
                                        children={ renderHTML(reportResponse.message) }/>)
                                )
                            }

                        </>
                    }
                </div>

                <div className="report-details__section">
                    {
                        this.renderTextBox()
                    }
                </div>
            </>
        );
    };

    renderTextBox = () => {
        const { textBoxActive, textBoxContent } = this.state;
        const { selectedReportId, _onSendResponseClick } = this.props;

        if (!isNullOrUndefined(textBoxActive) && textBoxActive) {
            return (
                <div className="report-details__text-box">
                    <TinyEditor
                        value={ textBoxContent }
                        _onMessageContentChanged={ this._onEditorContentChange }/>

                    <button className="btn btn--primary text-box-button"
                            type="submit"
                            onClick={
                                () => _onSendResponseClick(selectedReportId, textBoxContent)
                                    .then(() => this.setState(initialState))
                            }>
                        Wyślij
                    </button>
                </div>
            );
        } else {
            return (
                <div className="report-details__row">
                    <button className="btn btn--primary"
                            onClick={ this._onToggleTextBoxClick }
                            type="button">
                        Udziel odpowiedzi
                    </button>
                </div>
            );
        }
    };

    _onToggleTextBoxClick = () => {
        this.setState(prevState => ({
            textBoxActive: !prevState.textBoxActive,
        }));
    };

    _onEditorContentChange = (value) => {
        this.setState({
            textBoxContent: value,
        });
    };
}

export default ReportDetailsResponseHistory;