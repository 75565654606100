import React, { useCallback, useState } from "react";
import AreasList from "./list";
import _ from "lodash";
import AreasHeader from "./list/header";

const Areas = () => {
    const [searchBarTerm, setSearchBarTerm] = useState('');

    const _onSearchBarInputChange = useCallback(
        _.debounce(input => setSearchBarTerm(input || ''), 600),
        []
    );

    return (
        <div className="main-content--table">
            <AreasHeader
                _onSearchBarInputChange={ _onSearchBarInputChange }
            />

            <AreasList
                searchBarTerm={ searchBarTerm }
            />
        </div>
    );
};

export default Areas;
