import React from "react";
import _ from "lodash";

import Loader from "../../../../../common/loader";
import AccountFormPermission from "./permission";
import { isEmptyObject, isLoading, isLoadingError, isNullOrUndefined } from "../../../../../../core/utils/misc_utils";

const AccountFormPermissionGroup = ({ permissionItems, modules, disabled = false, _onPermissionItemClicked }) => {
    if (isLoadingError(modules) || isNullOrUndefined(modules.data) || isEmptyObject(modules.data)) {
        // TODO
        return (
            <div>Błąd wczytywania dostępnych uprawnień</div>
        );
    }

    if (isLoading(modules)) {
        return (
            <Loader/>
        );
    }

    return (
        <>
            <h3 className="form-group-title"><span className="required-accent">*</span>Uprawnienia:</h3>
            <label>
                <div>
                    {
                        _.map(modules.data, module => {
                            return (
                                <div className="define-options" key={ module.code }>
                                    <h3 className="title is-3">Moduł - { module.name }</h3>
                                    <span className="unstyled-btn is-uppercase f-12 define-options__check-all">
                                        {/* TODO: select all */ }
                                        {/* Zaznacz wszystkie */ }
                                    </span>
                                    <ul className="define-options__list columns is-multiline">
                                        {
                                            _.map(module.groups, group => {
                                                return _.map(group.items, item => {

                                                    let includes = false;
                                                    for (let i = 0; i < permissionItems.length; i++) {
                                                        if (permissionItems[i].itemCode === item.code && permissionItems[i].moduleCode === module.code) {
                                                            includes = true;
                                                            break;
                                                        }
                                                    }

                                                    return (
                                                        <AccountFormPermission
                                                            key={ item.code }
                                                            item={ item }
                                                            moduleCode={ module.code }
                                                            checked={ includes }
                                                            disabled={ disabled }
                                                            _onPermissionItemClicked={ _onPermissionItemClicked }/>
                                                    );
                                                });
                                            })
                                        }
                                    </ul>
                                </div>
                            );
                        })
                    }
                </div>

            </label>
        </>
    );
};

export default AccountFormPermissionGroup;