import React from "react";
import { useHistory } from "react-router-dom";
import { ROUTE_WASTE_AREAS_DETAILS } from "../../../../../../core/constants";
import AreaCreateAndEditModalForm from "./create_and_edit_modal_form";
import AreaDeleteModalForm from "./delete_modal_form";

const AreasListItem = ({ area, _onDeleteOrUpdateSuccess }) => {
    const history = useHistory();

    return (
        <tr className="tr--clickable">
            <td className='t-width-11' onClick={ () => history.push(ROUTE_WASTE_AREAS_DETAILS(area.id)) }>
                <p className="table-text-content">
                    {
                        area.name
                    }
                </p>
            </td>

            <td className='sticky--right td-action-buttons'>
                <AreaCreateAndEditModalForm
                    area={ area }
                    _onSubmitCallback={ () => _onDeleteOrUpdateSuccess() }
                />
                <AreaDeleteModalForm
                    area={ area }
                    _onSubmitCallback={ () => _onDeleteOrUpdateSuccess() }
                />
            </td>

        </tr>
    );
};

export default AreasListItem;

