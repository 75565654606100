import webClient from "../../../core/web_client";
import { buildQueryString } from "../../../core/utils/api_utils";
import { API_BASE_AKUN_GOK_PATH } from '../../../core/constants';

const PATH = `${ API_BASE_AKUN_GOK_PATH }/messages`;

export const getManyMessages = (queryData) => {
    return webClient
        .get(`${ PATH }${ buildQueryString(queryData) }`);
};

export const addMessage = (data) => {
    return webClient
        .post(`${ PATH }`, data);
};

export const getMessage = (id) => {
    return webClient
        .get(`${ PATH }/${ id }`);
};

export const cancelMessage = (id) => {
    return webClient
        .delete(`${ PATH }/${ id }`);
};