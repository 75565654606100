import React from "react";
import { isNullOrUndefined } from "../../../../../../core/utils/misc_utils";

const ServicesCardKindRadio = ({ label, cardKind, selectedCardKind, _onCardKindClicked }) => {
    const isChecked = !isNullOrUndefined(cardKind) && cardKind === selectedCardKind;
    return (
        <label
            onClick={ event => {
                event.stopPropagation();
                _onCardKindClicked(event, cardKind);
            } }
            className={ `choice-label choice-label--radio ${ isChecked ? "checked--radio" : "" }` }
        >
            <input className="choice--radio" type="radio"/> { label }
        </label>
    );
};

export default ServicesCardKindRadio;
