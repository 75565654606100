import React from "react";
import { Field } from "formik";

const PaymentDetailsItem = ({ name, month }) => {

    return (
        <Field
            name={ name }
            render={ ({ field }) => (
                <label className="is-uppercase">
                    <input className="input--single-day" placeholder="dzień" type="number" min="1" max="31" { ...field }/>
                    {
                        month
                    }
                </label>
            ) }/>
    );
};

export default PaymentDetailsItem;