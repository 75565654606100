import React, { Component } from "react";
import FormModal from "../../../../../../../../common/modal/form_modal";
import { ReactComponent as IconPlus } from "../../../../../../../../../../resource/img/icons/icon_plus_white.svg";
import { addAddressesByRangeToGroup, addApartmentAddressesToGroup, addAddressesToGroup } from "../../../../../../../../../data/actions/ekostraznik/address_group_addresses";
import { connect } from "react-redux";
import FormRequiredLegend from "../../../../../../../../common/legend_required";
import { getManyAddressesFromAddressGroup } from "../../../../../../../../../data/actions/ekostraznik/addresses";
import LocationForm, { LocationFormTypeEnum } from "../../../../../../../../common/location_form";
import { deepFreeze } from "../../../../../../../../../core/utils/misc_utils";
import { showToast } from "../../../../../../../../../data/actions/common/ui/toast";
import { toastFor400, toastFor500, toastForSuccess } from '../../../../../../../../../core/utils/toast_utils';
import _ from "lodash";
import CheckboxGroup from '../../../../../../../../common/checkbox_group';

const FormType = deepFreeze({
    BUILDING: 'BUILDING',
    APARTMENT: 'APARTMENT',
});

class AddAddressModalForm extends Component {

    state = {
        selectedAddresses: null,
        formType: FormType.BUILDING,
        submitDisabled: true,
        resetLocationForm: false,
        noCloseForm: false,
        apartmentAddressesToApprove: [],
        alreadyAssignedUnits: [],
        unitIdsToReassign: []
    }

    render = () => {
        const { apartmentAddressesToApprove, alreadyAssignedUnits } = this.state;

        if (apartmentAddressesToApprove.length > 0) {
            return (
                this.renderNonExistingApartmentAddressesForm()
            )
        }

        if (alreadyAssignedUnits.length === 1) {
            return (
                this.renderAlreadyAssignedAddressForm()
            )
        }

        if (alreadyAssignedUnits.length > 1) {
            return (
                this.renderAlreadyAssignedAddressesForm()
            )
        }

        return (
            this.renderAddressesForm()
        )
    };

    renderNonExistingApartmentAddressesForm = () => {
        return (
            <FormModal
                triggerComponent={ this.renderTriggerComponent() }
                headerText='Wyodrębnij adresy'
                overflow={ true }
                saveOrCancelButtons={ true }
                saveButtonText='Wyodrębnij adresy'
                cancelButtonText='Anuluj wyodrębnianie adresów'
                _actionFunction={ () => {
                    return this._onConfirmApartmentAddresses();
                } }
                _cancelFunction={ async () => this._onCancelConfirmApartmentAddresses() }
            >
                <div className='c-modal__body modal-size-m'>
                    <div>
                        <strong>Uwaga!</strong> Wybrane lokale są niewyodrębnione. Czy chcesz je wyodrębnić?
                    </div>
                    <br />
                    <div>
                        <strong>
                            Lista niewyodrębnionych lokali
                        </strong>
                    </div>
                    {
                        this.renderApartmentItems()
                    }
                </div>
            </FormModal>
        );
    }

    renderAlreadyAssignedAddressForm = () => {
        const { alreadyAssignedUnits } = this.state;
        const { groupName } = this.props;

        let alreadyAssignedUnit = alreadyAssignedUnits[0];

        return (
            <FormModal
                triggerComponent={ this.renderTriggerComponent() }
                headerText='Adres występuje w innej grupie'
                overflow={ true }
                saveOrCancelButtons={ true }
                saveButtonText='Przenieś adres do grupy'
                cancelButtonText='Anuluj przenoszenie adresu do grupy'
                submitDisabled={ false }
                _actionFunction={ () => {
                    return this._onReassignAddresses(alreadyAssignedUnits.map(address => address.unitId));
                } }
                _cancelFunction={ async () => this._onCancelReassignAddresses() }
            >
                <div className='c-modal__body modal-size-m'>
                    <div>
                        <strong>Uwaga!</strong> Poniższy adres, który chcesz dodać występuje w innej grupie.
                    </div>
                    <br />
                    <div>
                        Przenieś adres do grupy <strong>{ groupName }</strong>.
                        Adres zostanie usuniety z poprzedniej grupy, wpłynie to na dane wyświetlane w pozostałych zakładkach.
                    </div>
                    <br />

                    <div className="assigned-address">
                        <span className="acceptance-text">
                            {
                                alreadyAssignedUnit.unitName
                            }
                        </span>
                        <span className="acceptance-secondary-text">
                            {
                                alreadyAssignedUnit.addressGroupName
                            }
                        </span>
                    </div>

                </div>
            </FormModal>
        );
    }

    renderAlreadyAssignedAddressesForm = () => {
        const { unitIdsToReassign } = this.state;
        const { groupName } = this.props;

        return (
            <FormModal
                triggerComponent={ this.renderTriggerComponent() }
                headerText='Adresy występują w innych grupach'
                overflow={ true }
                saveOrCancelButtons={ true }
                saveButtonText='Przenieś adresy do grupy'
                cancelButtonText='Anuluj przenoszenie adresów do grupy'
                submitDisabled={ this.state.submitDisabled }
                _actionFunction={ () => {
                    return this._onReassignAddresses(unitIdsToReassign);
                } }
                _cancelFunction={ async () => this._onCancelReassignAddresses() }
            >
                <div className='c-modal__body modal-size-m'>
                    <div>
                        <strong>Uwaga!</strong> Poniższe adresy, które chcesz dodać występują w innych grupach.
                    </div>
                    <br />
                    <div>
                        Wybierz i przenieś adresy do grupy <strong>{ groupName }</strong>.
                        Adresy zostaną usunietę z poprzednich grup, wpłynie to na dane wyświetlane w pozostałych zakładkach.
                    </div>
                    <br />
                    {
                        this.renderAlreadyAssignedUnits()
                    }
                </div>
            </FormModal>
        );
    }

    renderAddressesForm = () => {

        const Option = props => {
            const { formType, label } = props;

            return (
                <label
                    className={ `choice-label choice-label--radio ${ formType === this.state.formType ? 'checked--radio' : '' }` }
                    onClick={ () => this.setState({ formType, selectedAddresses: null }) }
                >
                    <input className="choice--radio" type="radio" value={ formType } name="formType" />
                    { label }
                </label>
            );
        };

        return (
            <FormModal
                triggerComponent={ this.renderTriggerComponent() }
                ariaLabel='Dodaj adresy'
                headerText='Dodaj adresy'
                saveOrStayOrCancelButtons={ true }
                submitDisabled={ this.state.submitDisabled }
                _actionFunction={ () => {
                    this.setState({
                        noCloseForm: false
                    })

                    return this._onSubmitAddresses();
                } }
                _actionFunctionWithFormClear={ () => {
                    this.setState({
                        noCloseForm: true
                    })

                    return this._onSubmitAddresses();
                } }
                _cancelFunction={ async () => this._clearAddAddressesForm() }
            >

                <div className='c-modal__body modal-size-m'>
                    <div className='is-relative is-spaced--top-10'>
                        <FormRequiredLegend />
                    </div>
                    <Option formType={ FormType.BUILDING } label='Budynki' />
                    <Option formType={ FormType.APARTMENT } label='Lokale' />
                    {
                        this.state.formType === FormType.BUILDING &&
                        <LocationForm
                            horizontal={ false }
                            formType={ LocationFormTypeEnum.WITH_BUILDING_NUMBERS_RANGE }
                            _onChange={ () => {
                                this.setState({
                                    selectedAddresses: null,
                                    resetLocationForm: false
                                })
                            } }
                            _onSelect={ values => { 
                                this._onSelectLocation(values);
                                this.setState({ resetLocationForm: false }) 
                            } }
                            resetForm={ this.state.resetLocationForm }
                        />
                    }

                    {
                        this.state.formType === FormType.APARTMENT &&
                        <LocationForm
                            horizontal={ false }
                            formType={ LocationFormTypeEnum.WITH_ADD_APARTMENT_ADDRESS }
                            _onChange={ () => {
                                this.setState({
                                    selectedAddresses: null,
                                    resetLocationForm: false
                                })
                            } }
                            _onSelect={ values => {
                                this._onSelectLocation(values);
                                this.setState({ resetLocationForm: false }) 
                            } }
                            resetForm={ this.state.resetLocationForm }
                        />
                    }
                </div>
            </FormModal>
        );
    }

    renderTriggerComponent = () => {
        return (
            <button className="btn btn--primary btn--big">
                <IconPlus />
                <span className="is-spaced--left-10">Dodaj adresy</span>
            </button>
        );
    };

    renderApartmentItems = () => {
        const { apartmentAddressesToApprove } = this.state;
        const sortedApartmentList = _.sortBy(apartmentAddressesToApprove, [apartment => apartment.toLowerCase()]);

        return _.map(sortedApartmentList, apartment => {
            return (
                <div key={ apartment } className="address-item">
                    { apartment }
                </div>
            );
        });
    };

    renderAlreadyAssignedUnits = () => {
        const { alreadyAssignedUnits } = this.state;

        return (
            <div className="checkbox-list">
                <CheckboxGroup
                    options={Object.values(alreadyAssignedUnits || {}).map(address => ({
                        label: address.unitName,
                        description: 'Nazwa grupy: ' + address.addressGroupName,
                        value: address.unitId
                    })) }
                    onChange={selectedOptions => {
                        this._onSelectionChange(selectedOptions.map(option => option.value))
                    }}
                />
            </div>
        );
    }

    _onSelectionChange = (selectedUnitIds) => {
        this.setState({
            unitIdsToReassign: selectedUnitIds,
            submitDisabled: !selectedUnitIds.length
        });
    }

    _onSelectLocation = (values) => {
        this.setState({ selectedAddresses: null });
        if (values.selectedFrom && !values.selectedTo) {
            this.setState({ selectedAddresses: { addressesUnit: values.selectedFrom.id }, submitDisabled: false });
            return;
        }

        if (values.selectedFrom && values.selectedTo) {
            this.setState({ selectedAddresses: { addressIdFrom: values.selectedFrom.id, addressIdTo: values.selectedTo.id }, submitDisabled: false });
            return;
        }

        if (values.apartmentAddress && values.selectedAddress) {
            this.setState({ selectedAddresses: { apartmentAddress: values.apartmentAddress, selectedAddress: values.selectedAddress }, submitDisabled: false });
        }
    }

    _onSubmitAddresses = () => {
        const { groupId, addAddressesByRangeToGroup, addApartmentAddressesToGroup, addAddressesToGroup } = this.props;
        const { selectedAddresses } = this.state;

        let promise;

        if (!groupId || !selectedAddresses) {
            return Promise.reject();
        }

        if (selectedAddresses?.addressesUnit) {
            promise = addAddressesToGroup(groupId, [selectedAddresses.addressesUnit], false);
        }

        if (selectedAddresses?.addressIdFrom && selectedAddresses?.addressIdTo) {
            promise = addAddressesByRangeToGroup(groupId, selectedAddresses.addressIdFrom, selectedAddresses.addressIdTo, false);
        }

        if (selectedAddresses?.apartmentAddress && selectedAddresses?.selectedAddress) {
            promise = addApartmentAddressesToGroup(groupId, [selectedAddresses.selectedAddress.id], selectedAddresses.apartmentAddress, false);
        }

        if (promise) {
            return promise.then(response => this._handleResponse(response, true))
        }

        return Promise.reject();
    };

    _onConfirmApartmentAddresses = () => {
        const { groupId, addApartmentAddressesToGroup } = this.props;
        const { selectedAddresses, apartmentAddressesToApprove } = this.state;

        if (!groupId || !apartmentAddressesToApprove) {
            return Promise.reject();
        }

        return addApartmentAddressesToGroup(groupId, [selectedAddresses.selectedAddress.id], selectedAddresses.apartmentAddress, true)
            .then(response => this._handleResponse(response))
    };

    _onReassignAddresses = (unitIdsToReassign) => {
        const { groupId, addAddressesToGroup } = this.props;

        if (!groupId || !unitIdsToReassign) {
            return Promise.reject();
        }

        return addAddressesToGroup(groupId, unitIdsToReassign, true)
            .then(response => this._handleResponse(response))
    };

    _handleResponse = (response, firstStep) => {
        const { showToast, updateAddresses, groupId, searchBarTerm, getManyAddressesFromAddressGroup } = this.props;
        const { selectedAddresses, noCloseForm } = this.state;

        toastFor500(showToast, updateAddresses, 'Dodawanie adresu nieudane, spróbuj ponownie');
        toastFor400(showToast, updateAddresses, 'Dodawanie adresu nieudane, spróbuj ponownie');

        let nonExistingApartments = response?.data?.nonExistingApartments || {};
        let alreadyAssignedUnits = response?.data?.alreadyAssignedUnits || {};

        getManyAddressesFromAddressGroup(groupId, { query: searchBarTerm || '' });

        if (nonExistingApartments.length || alreadyAssignedUnits.length) {
            this.setState({
                selectedAddresses: {
                    selectedAddress: selectedAddresses.selectedAddress,
                    apartmentAddress: selectedAddresses.apartmentAddress
                },
                apartmentAddressesToApprove: nonExistingApartments,
                alreadyAssignedUnits: alreadyAssignedUnits
            });

            if (!firstStep || !noCloseForm) {
                return Promise.reject();
            }
        }
        else {
            toastForSuccess(showToast, updateAddresses, 'Poprawnie dodano adresy do grupy');
            this.setState({
                submitDisabled: true,
                selectedAddresses: null,
                resetLocationForm: true,
                apartmentAddressesToApprove: [],
                alreadyAssignedUnits: [],
                unitIdsToReassign: []
            });
        }

        if (!firstStep && noCloseForm) {
            return Promise.reject();
        }

        return response;
    }

    _onCancelConfirmApartmentAddresses = () => {
        const { alreadyAssignedUnits, noCloseForm } = this.state;

        this._clearAddAddressesForm();
        this._clearNonExistingAddressesForm();

        if (noCloseForm || alreadyAssignedUnits.length > 0) {
            return Promise.reject();
        }
    }

    _onCancelReassignAddresses = () => {
        const { noCloseForm } = this.state;

        this._clearAddAddressesForm();
        this._clearAddressesToReassignForm();

        if (noCloseForm) {
            return Promise.reject();
        }
    }

    _clearAddAddressesForm = () => {
        this.setState({
            submitDisabled: true,
            selectedAddresses: null,
            resetLocationForm: true,
        });
    }

    _clearNonExistingAddressesForm = () => {
        this.setState({
            apartmentAddressesToApprove: []
        });
    }

    _clearAddressesToReassignForm = () => {
        this.setState({
            alreadyAssignedUnits: [],
            unitIdsToReassign: []
        });
    }
}

const mapStateToProps = (state) => {
    return {
        updateAddresses: state.entities.ekostraznik.addressGroups.addresses.update
    };
};

export default connect(mapStateToProps, { addAddressesByRangeToGroup, addApartmentAddressesToGroup, addAddressesToGroup, getManyAddressesFromAddressGroup, showToast })(AddAddressModalForm);