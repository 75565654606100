import React from "react";

import { ReactComponent as IconAddNew } from "../../../resource/img/icons/icon_add_blue.svg";

export const TileCreateTrigger = ({title}) => {

    return (
        <div className='tiles-item tiles-item--add-new'>
            <div className="text-center">
                <IconAddNew/>
                <strong>
                    {
                        title
                    }
                </strong>
            </div>
        </div>
    );
};


