import { actions } from "../../../../core/constants";

export const setSidebarVariant = (data) => dispatch => {
    return dispatch({
        type: actions.common.UI__SIDEBAR_VARIANT__SELECTED,
        payload: data
    });
};

// data = sidebarVisible, sidebarCollapsed, activeSidebarItem, activeSubmenuItem, submenuExpanded
export const setSidebarItemSelected = (data) => dispatch => {
    return dispatch({
        type: actions.common.UI__SIDEBAR_ITEM__SELECTED,
        payload: data
    });
};

export const resetSidebar = () => {
    return {
        type: actions.common.UI__SIDEBAR__RESET
    };
};