import React from "react";

import FormModal from "../../../../../../../../common/modal/form_modal";
import { ReactComponent as IconDelete } from "../../../../../../../../../../resource/img/icons/icon_delete.svg";

const AddressTemplateLocationDeleteForm = ({ location, _onLocationDeleteClick }) => (
    <FormModal
        triggerComponent={
            <button className='unstyled-btn' title='Usuń'>
                <IconDelete/>
            </button>
        }
        ariaLabel='Usuń adres'
        deleteOrCancelButtons={ true }
        _actionFunction={ () => _onLocationDeleteClick(location) }>

        <div className='c-modal__body modal-size-m modal--no-header'>
            <p className="confirmation-alert">
                Czy na pewno chcesz usunąć ten adres?
            </p>
        </div>

    </FormModal>
);

export default AddressTemplateLocationDeleteForm;