import React, { Component } from "react";
import { Field } from "formik";
import { isEmptyString } from "../../../../../../../core/utils/misc_utils";

class InspectionCompaniesCreateAndEditModalFormContent extends Component {

    render = () => {
        const { errorMessage } = this.props;

        return (
            <Field
                name="name"
                render={ ({ field }) => (
                    <div className='c-modal__body modal-size-m'>
                        <label>
                            <span className="is-sr-only">Nazwa kontrolera</span>
                            <textarea
                                placeholder="Nazwa kontrolera"
                                cols="30" rows="10"
                                { ...field }/>
                            {
                                !isEmptyString(errorMessage) &&
                                <span className="hint hint--rejected">
                                    {
                                        errorMessage
                                    }
                                </span>
                            }
                        </label>

                    </div>
                ) }/>
        );
    };

    componentDidMount = () => {
        const { resetInspectionCompanies } = this.props;

        resetInspectionCompanies();
    };

    componentWillUnmount = () => {
        const { formikResetForm, _clearErrorMessage } = this.props;
        formikResetForm();
        _clearErrorMessage();

    };
}


export default InspectionCompaniesCreateAndEditModalFormContent;
