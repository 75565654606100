import webClient from "../../../core/web_client";
import { API_BASE_AKUN_KU_PATH } from "../../../core/constants";

const PATH = `${ API_BASE_AKUN_KU_PATH }/responsible-entities`;

export const getManyResponsibleEntities = () => {
    return webClient
        .get(`${ PATH }`);
};

export const addResponsibleEntity = (data) => {
    return webClient
        .post(`${ PATH }`, data);
};

export const updateResponsibleEntity = (responsibleEntityId, data) => {
    return webClient
        .put(`${ PATH }/${ responsibleEntityId }`, data);
};

export const deleteResponsibleEntity = (responsibleEntityId) => {
    return webClient
        .delete(`${ PATH }/${ responsibleEntityId }`);
};