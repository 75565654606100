import { API_BASE_AKUN_BLISKO_PATH } from "../../../core/constants";
import webClient from "../../../core/web_client";
import { buildQueryString } from "../../../core/utils/api_utils";

const PATH = `${ API_BASE_AKUN_BLISKO_PATH }/messages/drafts`;

export const getMany = (queryData) => {
    return webClient
        .get(`${ PATH }${ buildQueryString(queryData) }`);
};

export const get = (id) => {
    return webClient
        .get(`${ PATH }/${ id }`);
};


export const addDraftMessage = (data) => {
    return webClient
        .post(`${ PATH }`, data);
};

export const updateDraftMessage = (id, data) => {
    return webClient
        .put(`${ PATH }/${ id }`, data);
};

export const patchDraftMessage = (id, data) => {
    return webClient
        .patch(`${ PATH }/${ id }`, data);
};

export const deleteDraftMessage = (id) => {
    return webClient
        .delete(`${ PATH }/${ id }`);
};