import React from "react";
import { connect } from "react-redux";

import ResponsibleEntityConfirmationModalForm from "./confirmation_modal_form";
import AddOrEditResponsibleEntityModalForm from "./create_and_edit_modal_form";
import { isEmptyString, isNullOrUndefined } from "../../../../../../core/utils/misc_utils";
import { ReactComponent as IconEdit } from "../../../../../../../resource/img/icons/icon_edit.svg";
import { ReactComponent as IconDelete } from "../../../../../../../resource/img/icons/icon_delete.svg";
import { ReactComponent as IconDeleteSelect } from "../../../../../../../resource/img/icons/icon_delete_select.svg";
import { deleteResponsibleEntity, getManyResponsibleEntities } from "../../../../../../data/actions/services/responsible_entities";

const ResponsibleEntitySelectedItem = ({ responsibleEntity, getManyResponsibleEntities, deleteResponsibleEntity, _setFormikFieldValue }) => {

    if (isNullOrUndefined(responsibleEntity)) {
        return null;
    }

    return (
        <div className="is-spaced--top-30">
            <span className="label-name">Wybrany podmiot</span>
            <div className="with-gray-bg is-spaced--bottom-10">
                <div className="is-spaced--bottom-20">
                    {
                        !isEmptyString(responsibleEntity.name) &&
                        <p className="is-bold f-18 is-spaced--bottom-10">
                            {
                                responsibleEntity.name
                            }
                        </p>
                    }
                    {
                        !isEmptyString(responsibleEntity.address) &&
                        <p>
                            {
                                responsibleEntity.address
                            }
                        </p>
                    }
                    {
                        !isEmptyString(responsibleEntity.phoneNumber) &&
                        <p>
                            <span>Tel.: </span>
                            {
                                responsibleEntity.phoneNumber
                            }
                        </p>
                    }
                    {
                        !isEmptyString(responsibleEntity.emailAddress) &&
                        <p>
                            {
                                responsibleEntity.emailAddress
                            }
                        </p>
                    }
                </div>
                <div>
                    {
                        !isEmptyString(responsibleEntity.openingHours) &&
                        <>
                            <p className="is-bold is-spaced--bottom-10">Godziny otwarcia</p>
                            <p>
                                {
                                    responsibleEntity.openingHours
                                }
                            </p>
                        </>
                    }
                </div>
            </div>
            <div className="is-spaced--top-10 triple-buttons-wrapper">

                <div className="with-modal">
                    <AddOrEditResponsibleEntityModalForm
                        responsibleEntity={ responsibleEntity }
                        headerText="Edytuj podmiot"
                        triggerComponent={
                            <button className="btn btn--outlined"
                                    onClick={ e => e.preventDefault() }>
                                <span className="btn__icon-wrapper--left">
                                    <span className='btn__icon'>
                                        <IconEdit/>
                                    </span>
                                    Edytuj
                                </span>
                            </button>
                        }/>
                </div>

                <button className="btn btn--outlined"
                        onClick={ e => {
                            e.preventDefault();
                            _setFormikFieldValue("responsibleEntityId", null);
                        } }>
                    <span className="btn__icon-wrapper--left">
                        <span className='btn__icon'>
                            <IconDelete/>
                        </span>
                        Usuń z karty
                    </span>
                </button>

                <div className="with-modal far-right">
                    <ResponsibleEntityConfirmationModalForm
                        _actionFunction={ () => {
                            return deleteResponsibleEntity(responsibleEntity.id)
                                .then(() => getManyResponsibleEntities())
                                .then(() => _setFormikFieldValue("responsibleEntityId", null));
                        } }
                        triggerComponent={
                            <button className="btn btn--outlined"
                                    onClick={ e => e.preventDefault() }>
                                <span className="btn__icon-wrapper--left">
                                    <span className='btn__icon'>
                                        <IconDeleteSelect/>
                                    </span>
                                    Usuń z bazy podmiotów
                                </span>
                            </button>
                        }/>
                </div>
            </div>
        </div>
    );
};

export default connect(null, { getManyResponsibleEntities, deleteResponsibleEntity })(ResponsibleEntitySelectedItem);