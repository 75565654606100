import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import MunicipalWasteMenuBinsItem from "./item";
import MunicipalWasteMenuBinsSelect from "./mobile_select";
import CreateBinModalForm from "./create_modal_form";

import { isNullOrUndefined } from "../../../../../../../../core/utils/misc_utils";
import { deleteBin, getManyBins } from "../../../../../../../../data/actions/waste_management/bins";


class MunicipalWasteBins extends Component {

    state = {
        activeBins: []
    };

    render = () => {
        const { selectedBin, _onBinSelect } = this.props;
        const { activeBins } = this.state;

        return (
            <section className='container is-fluid'>

                { /*desktop version*/ }

                <div className="row row--options municipal-waste-bins--tabs">
                    <div className='tabs-wrapper with-icon--right'>

                        <div className="tabs">
                            <ul className="tabs-list">

                                {
                                    activeBins.map(bin => {
                                        return (
                                            <MunicipalWasteMenuBinsItem
                                                key={ bin.id }
                                                bin={ bin }
                                                selectedBin={ selectedBin }
                                                _onBinSelect={ _onBinSelect }
                                                _onDeleteCustomBinTypeClick={ this._onDeleteCustomBinTypeClick }/>
                                        );
                                    })
                                }

                            </ul>
                        </div>

                        <CreateBinModalForm/>
                    </div>
                </div>

                { /*mobile version*/ }

                <div className='tabs-wrapper tabs-wrapper--select with-icon--right municipal-waste-bins--select'>

                    <MunicipalWasteMenuBinsSelect
                        binsUnmapped={ activeBins }
                        selectedBin={ selectedBin }
                        _onBinSelect={ _onBinSelect }/>
                    <CreateBinModalForm/>
                </div>

            </section>
        );
    };

    componentDidMount = () => {
        const { bins, selectedBin, _onBinSelect } = this.props;

        const binsUnmapped = _.map(bins.data, bin => bin);
        const activeBins = binsUnmapped.filter( bin => bin.status === "ACTIVE");
        const defaultBin = activeBins[0];

        this.setState({
            activeBins
        }, () => {
            if (isNullOrUndefined(selectedBin) && !isNullOrUndefined(defaultBin)) {
                _onBinSelect(defaultBin.id);
            }
        });
    };

    shouldComponentUpdate = (nextProps, nextState) => {
        const { selectedBin } = this.props;
        const { activeBins } = this.state;

        return activeBins !== nextState.activeBins || selectedBin !== nextProps.selectedBin;
    };

    setTabAfterBinDelete = (bin) => {
        const { selectedBin, _onBinSelect } = this.props;
        const { activeBins } = this.state;
        const defaultBin = activeBins[0];

        if (bin.id === selectedBin) {
            _onBinSelect(defaultBin.id);
        }
    };

    _onDeleteCustomBinTypeClick = (bin) => {
        const { deleteBin, getManyBins } = this.props;

        if (!isNullOrUndefined(bin)) {
            return deleteBin(bin.id)
                .then(() => getManyBins())
                .then(() => this.setTabAfterBinDelete(bin));
        }
    };
}

export default connect(null, { deleteBin, getManyBins })(MunicipalWasteBins);