import React from "react";
import { ReactComponent as IconAdd } from "../../../../../../../resource/img/icons/icon_add_white.svg";
import { useHistory } from "react-router-dom";
import { ROUTE_BLISKO_RECEIVER_GROUP_ADD } from "../../../../../../core/constants";
import Header from "../../../../../common/header";
import { useSelector } from "react-redux";
import { isNumber } from 'lodash';

const ReceiverGroupsHeader = ({ size }) => {
    const history = useHistory();
    const receiverGroupLimit = useSelector(state => state.entities?.common?.owner?.getConfiguration)?.data?.receiverGroupLimit;

    return (
        <section className='main-content--table__top'>
            <Header>
                Serwisy publiczne
            </Header>

            <section className='container is-fluid'>
                <div className="row row--separated row--options">
                    <div className="media-left step-count-wrap">
                        <div className="row">
                            <div className="step-count is-bold row-bg">
                                <span>{ size }/{receiverGroupLimit}</span>
                            </div>
                            <div className="is-spaced--left-10 is-bold">
                                Liczba dostępnych <br/> seriwsów publicznych
                            </div>
                        </div>
                    </div>
                    <div className="row__right">
                        {
                            (isNumber(size) && isNumber(receiverGroupLimit)) &&
                            <button className="btn btn--primary btn--big header__main-cta-btn"
                                    disabled={ size >= receiverGroupLimit }
                                    onClick={ () => history.push(ROUTE_BLISKO_RECEIVER_GROUP_ADD) }>
                            <span className="btn__icon-wrapper--left">
                                <span className='btn__icon'>
                                    <IconAdd/>
                                </span>
                                Dodaj serwis
                            </span>
                            </button>
                        }
                    </div>
                    {/* To do button send message */ }
                </div>
            </section>
        </section>
    )
};

export default ReceiverGroupsHeader;
