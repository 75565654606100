import React from "react";
import { ReactComponent as IconDelete } from "../../../../../../../resource/img/icons/icon_delete.svg";

const ChosenEmployees = ({ employees, currentEmployee, _onEmployeeRemove }) => {

    if (!employees?.length) {
        return null;
    }

    return (
        <div className="message-sending-1__box message-sending-1__box--list is-spaced--top-30">
            <ul>
                {
                    employees.map(employee =>

                        <li key={ employee.value } className="message-sending-1__list-item">
                            <span>
                                {
                                    employee.label
                                }
                            </span>

                            {
                                currentEmployee?.id === employee.value
                                    ?
                                    <span>
                                        TWÓRCA GRUPY
                                    </span>
                                    :
                                    <span className="delete-trigger" onClick={ () => _onEmployeeRemove(employee) }
                                          title='Usuń'>
                                        <IconDelete/>
                                    </span>
                            }
                        </li>
                    )
                }
            </ul>
        </div>
    );
};

export default ChosenEmployees;
