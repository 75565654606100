import React from "react";
import DeleteAddressForm from "../../../form/delete_address_form";
import CheckboxInput from "../../../../../../../common/checkbox_input";

const AddressesListItem = ({ address, groupId, checkedAddresses, _onDeleteOrUpdateSuccess, _onAddressCheckboxChange }) => {

    return (
        <tr>
            <td className='t-width-11'>
                <CheckboxInput
                    label={ address.name }
                    _onInputChange={ () => _onAddressCheckboxChange(address.id) }
                    checked={ checkedAddresses.indexOf(address.id) >= 0 }
                />
            </td>
            <td className='sticky--right td-action-buttons'>

                {/* @todo edit form */ }

                <DeleteAddressForm
                    groupId={ groupId }
                    addressId={ address.id }
                    _onSuccess={ _onDeleteOrUpdateSuccess }
                />

            </td>
        </tr>
    );
};

export default AddressesListItem;
