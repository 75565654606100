import * as api from "../../_api";

import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const updateAccessibilityStatement = (password) => dispatch => {
    dispatch({
        type: actions.common.API__ACCESSIBILITY_STATEMENT__UPDATE__STARTED
    });

    return api
        .common
        .accessibilityStatements
        .update(password)
        .then(response => {
            dispatch({
                type: actions.common.API__ACCESSIBILITY_STATEMENT__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__ACCESSIBILITY_STATEMENT__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getAccessibilityStatement = () => dispatch => {
    dispatch({
        type: actions.common.API__ACCESSIBILITY_STATEMENT__GET__STARTED
    });

    return api
        .common
        .accessibilityStatements
        .get()
        .then(response => {
            dispatch({
                type: actions.common.API__ACCESSIBILITY_STATEMENT__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__ACCESSIBILITY_STATEMENT__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};