import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";

import withModules from "../../common/hoc/with_modules";
import ComponentWithDropdown from "../../common/component_with_dropdown";
import { ReactComponent as IconModules } from "../../../../resource/img/icons/icon_modules.svg";
import { ROUTE_OWNER_SELECTION } from "../../../core/constants";
import { isNullOrUndefined } from "../../../core/utils/misc_utils";
import { moduleCodeToData } from "../../../core/utils/permission_utils";
import { ReactComponent as IconHome } from "../../../../resource/img/icons/icon_home.svg";


class AdminHeaderDropdownModules extends ComponentWithDropdown {

    render = () => {
        const { dropdownOpen = false } = this.state;
        const { modules, account, ownerName, setSidebarVariant, showHomeIcon = false, moduleItems } = this.props;
        const activeModuleCodes = [...new Set(moduleItems?.map(moduleItem => moduleItem.moduleCode))];

        if (!isNullOrUndefined(modules?.data) &&
            _.size(modules.data) === 1 &&
            !isNullOrUndefined(account.data) &&
            !isNullOrUndefined(account.data.owners) &&
            account.data.owners.length < 2) {
            if (showHomeIcon) {
                const moduleData = moduleCodeToData(modules.data[Object.keys(modules.data)[0]].code);

                return (
                    <Link to={ moduleData.route } className="btn btn--icon" title='Powrót do modułu'>
                        <IconHome/>
                    </Link>
                );
            } else {
                return "";
            }
        }

        return (
            <div className={ `dropdown ${ dropdownOpen ? "is-active" : "" }` }>

                <div className="dropdown-trigger">
                    <button className="btn btn--icon"
                            title='Więcej'
                            onClick={ this._onDropdownOpen }>
                        <IconModules/>
                    </button>
                </div>

                <div
                    className="dropdown-size-l drop-to-left admin-navbar__dropdown-menu admin-navbar__dropdown-menu--modules">
                    <div className="dropdown-content admin-navbar__dropdown-content">

                        {
                            !isNullOrUndefined(account?.data) &&
                            !isNullOrUndefined(account.data.owners) &&
                            account.data.owners.length > 1 &&

                            <div className="admin-navbar__dropdown-item dropdown-title">
                                <span className="is-bold">
                                    {
                                        !isNullOrUndefined(ownerName)
                                            ? ownerName
                                            : ""
                                    }
                                </span>
                                <Link to={ ROUTE_OWNER_SELECTION } className="dropdown-title__link">
                                    Zmień
                                </Link>
                            </div>
                        }

                        <div className="admin-navbar__dropdown-item admin-navbar__dropdown-item--title-divider">
                            <span className="is-bold f-12">Moduły:</span>
                        </div>

                        {
                            _.map(modules?.data, module => {
                                if (activeModuleCodes.includes(module.code)) {
                                    const moduleData = moduleCodeToData(module.code);
                                    return (
                                        <Link to={ moduleData.route }
                                              key={ module.code }
                                              onClick={ () => setSidebarVariant(moduleData.sidebarVariant) }
                                              className="admin-navbar__dropdown-item admin-navbar__dropdown-item--modules-item is-v-centered">
                                            <span>
                                                {
                                                    moduleData.name
                                                }
                                            </span>
                                        </Link>
                                    );
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        );
    };
}


const mapStateToProps = (state) => {
    return {
        account: state.entities?.common?.account?.get,
        ownerName: state.authentication?.data?.ownerName,
        moduleItems: state.authentication?.data?.moduleItems
    };
};

export default withModules(
    connect(mapStateToProps, null)(AdminHeaderDropdownModules)
);
