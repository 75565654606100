import * as actions from "./actions";
import { isNullOrUndefined } from "../utils/misc_utils";

//---------------------------------------------------------------------------------------------------------------------- environment

const ENVIRONMENT_DEVELOPMENT = "development";
const ENVIRONMENT_CI = "ci";
const ENVIRONMENT_TEST1 = "test1";
const ENVIRONMENT_TEST2 = "test2";
const ENVIRONMENT_TEST3 = "test3";
const ENVIRONMENT_PRODUCTION = "production";

const getEnvironment = () => {
    if (isNullOrUndefined(process.env.REACT_APP_ENVIRONMENT)) {
        return ENVIRONMENT_DEVELOPMENT;
    }

    return process.env.REACT_APP_ENVIRONMENT.trim()
        .toLowerCase();
};

export const ENVIRONMENT = getEnvironment();

//---------------------------------------------------------------------------------------------------------------------- API
const API_BASE_URL_PRODUCTION = "https://gateway.sisms.pl";
const API_BASE_URL_TEST_1 = "https://gw1.test.sisms.pl";
const API_BASE_URL_TEST_2 = "https://gw2.test.sisms.pl";
const API_BASE_URL_TEST_3 = "https://gw3.test.sisms.pl";
const API_BASE_URL_DEVELOPMENT = API_BASE_URL_TEST_2;
const API_BASE_URL_CI = API_BASE_URL_DEVELOPMENT;

const getApiRootUrl = () => {
    switch (ENVIRONMENT) {
        case ENVIRONMENT_DEVELOPMENT:
            return API_BASE_URL_DEVELOPMENT;
        case ENVIRONMENT_CI:
            return API_BASE_URL_CI;
        case ENVIRONMENT_TEST1:
            return API_BASE_URL_TEST_1;
        case ENVIRONMENT_TEST2:
            return API_BASE_URL_TEST_2;
        case ENVIRONMENT_TEST3:
            return API_BASE_URL_TEST_3;
        case ENVIRONMENT_PRODUCTION:
        default:
            return API_BASE_URL_PRODUCTION;
    }
};

export const API_BASE_URL = getApiRootUrl();
export const API_BASE_AKUN_ADMIN_PATH = "akun/api/panel/admin";
export const API_BASE_AKUN_GOK_PATH = "akun/api/panel/gok";
export const API_BASE_AKUN_EKOSTRAZNIK_PATH = "akun/api/panel/ekostraznik";
export const API_BASE_AKUN_BLISKO_PATH = "akun/api/panel/blisko";
export const API_BASE_AKUN_KU_PATH = "akun-ku/api/web/admin/2";
export const API_BASE_GEOCODING_PATH = "geocoding/api/v1";

export const API_ACCEPT_HEADER = "accept";
export const API_AUTHORIZATION_HEADER = "authorization";
export const API_CONTENT_TYPE_HEADER = "content-type";

//---------------------------------------------------------------------------------------------------------------------- routes
export const ROUTE_SIGN_IN = "/logowanie";

export const ROUTE_INSTRUCTIONS = "/instrukcje-obslugi-panelu";

export const ROUTE_PASSWORD_RESET_REQUEST = "/zmien-haslo";
export const ROUTE_PASSWORD_RESET_REQUEST_CONFIRMATION = `${ ROUTE_PASSWORD_RESET_REQUEST }/potwierdzenie`;

export const ROUTE_PASSWORD_RESET = "/zmiana-hasla";
export const ROUTE_PASSWORD_RESET_REQUEST_RESET_CONFIRMATION = `${ ROUTE_PASSWORD_RESET }/potwierdzenie`;

export const ROUTE_ACCOUNT_ACTIVATION = "/aktywacja-konta";

export const ROUTE_NEWSLETTER = "/newsletter";
export const ROUTE_NEWSLETTER_SUBSCRIBE_CONFIRMATION = `${ ROUTE_NEWSLETTER }/aktywacja-subskrypcji`;
export const ROUTE_NEWSLETTER_UNSUBSCRIBE = `${ ROUTE_NEWSLETTER }/anulowanie-subskrypcji`;
export const ROUTE_NEWSLETTER_UNSUBSCRIBE_CONFIRMATION = `${ ROUTE_NEWSLETTER }/anulowanie-subskrypcji/potwierdzenie`;

export const ROUTE_MODULE_SELECTION = "/";
export const ROUTE_OWNER_SELECTION = "/nadawca";

export const ROUTE_ACCOUNTS = "/konta-administracyjne";
export const ROUTE_ACCOUNTS_DETAILS_FOR_ROUTING = `${ ROUTE_ACCOUNTS }/:accountId`;

export const ROUTE_SETTINGS = "/ustawienia";
export const ROUTE_SETTINGS_PASSWORD_CHANGE = `${ ROUTE_SETTINGS }/zmiana-hasla`;
export const ROUTE_SETTINGS_CONTACT_DATA = `${ ROUTE_SETTINGS }/dane-kontaktowe`;
export const ROUTE_SETTINGS_REGULATIONS = `${ ROUTE_SETTINGS }/regulaminy`;
export const ROUTE_SETTINGS_ACCESSIBILITY_STATEMENT = `${ ROUTE_SETTINGS }/deklaracja-dostepnosci`;

export const ROUTE_LOGS = "/raporty";
export const ROUTE_LOGS_USER_CONSENTS = `${ ROUTE_LOGS }/zgody-uzytkownikow`;
export const ROUTE_LOGS_USER_SUBSCRIPTIONS = `${ ROUTE_LOGS }/subskrypcje-uzytkownikow`;

export const ROUTE_SERVICES = "/karty-uslug";
export const ROUTE_SERVICES_CATEGORY_DETAILS_FOR_ROUTING = `${ ROUTE_SERVICES }/:categoryId`;

export const ROUTE_SERVICES_CATEGORY_DETAILS = (categoryId) => {
    const baseRoute = ROUTE_SERVICES;
    if (isNullOrUndefined(categoryId)) {
        return baseRoute;
    }

    return `${ baseRoute }/${ categoryId }`;
};

export const ROUTE_SERVICES_ADD_CARD = `${ ROUTE_SERVICES }/dodaj-karte`;
export const ROUTE_SERVICES_EDIT_CARD_FOR_ROUTING = `${ ROUTE_SERVICES }/edytuj-karte/:serviceCardId`;

export const ROUTE_SERVICES_EDIT_CARD = (serviceCardId) => {
    const baseRoute = `${ ROUTE_SERVICES }/edytuj-karte`;
    if (isNullOrUndefined(serviceCardId)) {
        return baseRoute;
    }

    return `${ baseRoute }/${ serviceCardId }`;
};

export const ROUTE_EKOSTRAZNIK = "/ekostraznik";
export const ROUTE_EKOSTRAZNIK_INSPECTION_HISTORY = `${ ROUTE_EKOSTRAZNIK }/historia-kontroli`;
export const ROUTE_EKOSTRAZNIK_LOCATION_DETAILED_INSPECTION_FOR_ROUTING = `${ ROUTE_EKOSTRAZNIK }/historia-kontroli/adres/:inspectionLocationId`;
export const ROUTE_EKOSTRAZNIK_GROUP_DETAILED_INSPECTION_FOR_ROUTING = `${ ROUTE_EKOSTRAZNIK }/historia-kontroli/grupa/:inspectionGroupId`;
export const ROUTE_EKOSTRAZNIK_INSPECTION_DETAILS_FOR_ROUTING = `${ ROUTE_EKOSTRAZNIK }/historia-kontroli/:parentType/:parentId/:inspectionId`;
export const ROUTE_EKOSTRAZNIK_REPORTS = `${ ROUTE_EKOSTRAZNIK }/zgloszenia`;
export const ROUTE_EKOSTRAZNIK_INVENTORIES = `${ ROUTE_EKOSTRAZNIK }/inwentaryzacje`;
export const ROUTE_EKOSTRAZNIK_INVENTORIES_DETAILS_FOR_ROUTING = `${ ROUTE_EKOSTRAZNIK_INVENTORIES }/:inventoryId`;
export const ROUTE_EKOSTRAZNIK_COMPANIES = `${ ROUTE_EKOSTRAZNIK }/kontrolerzy`;
export const ROUTE_EKOSTRAZNIK_ADDRESSES_GROUPED_ADDRESSES = `${ ROUTE_EKOSTRAZNIK }/adresy/zgrupowane-adresy`;
export const ROUTE_EKOSTRAZNIK_ADDRESSES_DETAILED_GROUPED_ADDRESSES_FOR_ROUTING = `${ ROUTE_EKOSTRAZNIK_ADDRESSES_GROUPED_ADDRESSES }/:groupId`;
export const ROUTE_EKOSTRAZNIK_ADDRESSES_APARTMENT_ADDRESS = `${ ROUTE_EKOSTRAZNIK }/adresy/rozdzielone-adresy`;
export const ROUTE_EKOSTRAZNIK_ADDRESSES_QR_CODES = `${ ROUTE_EKOSTRAZNIK }/adresy/naklejki-z-kodami-qr`;

export const ROUTE_DETAILED_INSPECTION = (detailedInspectionType, detailedInspectionId) => {
    const baseRoute = ROUTE_EKOSTRAZNIK_INSPECTION_HISTORY;
    if (isNullOrUndefined(detailedInspectionId) || isNullOrUndefined(detailedInspectionType)) {
        return baseRoute;
    }

    return `${ baseRoute }/${ detailedInspectionType }/${ detailedInspectionId }`;
};

export const ROUTE_BIN_INSPECTION = (detailedInspectionType, detailedInspectionId, binInspectionId) => {
    const baseRoute = ROUTE_EKOSTRAZNIK_INSPECTION_HISTORY;
    if (isNullOrUndefined(binInspectionId) || isNullOrUndefined(detailedInspectionId) || isNullOrUndefined(detailedInspectionType)) {
        return baseRoute;
    }

    return `${ baseRoute }/${ detailedInspectionType }/${ detailedInspectionId }/${ binInspectionId }`;
};

export const ROUTE_DETAILED_INVENTORY = (inventoryId) => {
    const baseRoute = ROUTE_EKOSTRAZNIK_INVENTORIES;
    if (!inventoryId) {
        return baseRoute;
    }

    return `${ baseRoute }/${ inventoryId }`
};

export const ROUTE_DETAILED_ADDRESS_GROUP = (groupId) => {
    const baseRoute = ROUTE_EKOSTRAZNIK_ADDRESSES_GROUPED_ADDRESSES;
    if (!groupId) {
        return baseRoute;
    }

    return `${ baseRoute }/${ groupId }`
}

export const ROUTE_WASTE = "/gospodarka-odpadami";
export const ROUTE_WASTE_MANAGEMENT_MUNICIPAL_WASTE = `${ ROUTE_WASTE }/zarzadzaj-odpadami/odpady-komunalne`;
export const ROUTE_WASTE_MANAGEMENT_BULKY_WASTE = `${ ROUTE_WASTE }/zarzadzaj-odpadami/odpady-wielkogabarytowe`;
export const ROUTE_WASTE_MANAGEMENT_COLLECTION_POINTS = `${ ROUTE_WASTE }/zarzadzaj-odpadami/punkty-zbiorki`;
export const ROUTE_WASTE_COMPANIES = `${ ROUTE_WASTE }/firmy-wywozace-odpady`;
export const ROUTE_WASTE_COMPANIES_DETAILS_FOR_ROUTING = `${ ROUTE_WASTE }/firmy-wywozace-odpady/:companyId`;
export const ROUTE_WASTE_AREAS = `${ ROUTE_WASTE }/obszary`;
export const ROUTE_WASTE_AREAS_DETAILS_FOR_ROUTING = `${ ROUTE_WASTE }/obszary/:areaId`;
export const ROUTE_WASTE_COMMUNAL_DOCUMENTS = `${ ROUTE_WASTE }/dokumenty-dla-mieszkanca`;

export const ROUTE_WASTE_AREAS_DETAILS = (areaId) => {
    const baseRoute = ROUTE_WASTE_AREAS;
    if (isNullOrUndefined(areaId)) {
        return baseRoute;
    }

    return `${ baseRoute }/${ areaId }`;
};

export const ROUTE_ACCOUNTS_DETAILS = (accountId) => {
    const baseRoute = ROUTE_ACCOUNTS;
    if (isNullOrUndefined(accountId)) {
        return baseRoute;
    }

    return `${ baseRoute }/${ accountId }`;
};

export const ROUTE_COMPANIES_DETAILS = (companyId) => {
    const baseRoute = ROUTE_WASTE_COMPANIES;
    if (isNullOrUndefined(companyId)) {
        return baseRoute;
    }

    return `${ baseRoute }/${ companyId }`;
};

export const ROUTE_WASTE_PAYMENTS_DUE_DATE = `${ ROUTE_WASTE }/terminy-oplat`;
export const ROUTE_WASTE_NOTIFICATIONS = `${ ROUTE_WASTE }/wyslij-wiadomosc`;
export const ROUTE_WASTE_NOTIFICATIONS_SENT = `${ ROUTE_WASTE }/wyslane-wiadomosci`;
export const ROUTE_WASTE_MESSAGE_PREVIEW_FOR_ROUTING = `${ ROUTE_WASTE }/wyslane-wiadomosci/:messageId`;
export const ROUTE_WASTE_REPORTS = `${ ROUTE_WASTE }/zgloszenia-mieszkancow`;

export const ROUTE_MESSAGE_PREVIEW = (messageId) => {
    const baseRoute = ROUTE_WASTE_NOTIFICATIONS_SENT;
    if (isNullOrUndefined(messageId)) {
        return baseRoute;
    }

    return `${ baseRoute }/${ messageId }`;
};


export const ROUTE_BLISKO = "/blisko";
export const ROUTE_BLISKO_MESSAGES = `${ ROUTE_BLISKO }/wiadomosci`;
export const ROUTE_BLISKO_MESSAGE_PREVIEW_FOR_ROUTING = `${ ROUTE_BLISKO_MESSAGES }/:messageId`;
export const ROUTE_BLISKO_MESSAGE_SEND = `${ ROUTE_BLISKO_MESSAGES }/wyslij-wiadomosc`;
export const ROUTE_BLISKO_DRAFT_MESSAGE_EDIT_FOR_ROUTING = `${ ROUTE_BLISKO_MESSAGES }/edytuj-wiadomosc/:draftMessageId`;
export const ROUTE_BLISKO_RECEIVER_GROUP = `${ ROUTE_BLISKO }/serwisy-publiczne`;
export const ROUTE_BLISKO_RECEIVER_GROUP_ADD = `${ ROUTE_BLISKO_RECEIVER_GROUP }/dodaj-serwis`;
export const ROUTE_BLISKO_RECEIVER_GROUP_EDIT_FOR_ROUTING = `${ ROUTE_BLISKO_RECEIVER_GROUP }/:receiverGroupId`;
export const ROUTE_BLISKO_ADDRESS_TEMPLATE = `${ ROUTE_BLISKO }/szablony-adresow`;
export const ROUTE_BLISKO_ADDRESS_TEMPLATE_LOCATIONS_FOR_ROUTING = `${ ROUTE_BLISKO_ADDRESS_TEMPLATE }/:addressTemplateId`;
export const ROUTE_BLISKO_MESSAGE_TEMPLATE = `${ ROUTE_BLISKO }/szablony-wiadomosci`;
export const ROUTE_BLISKO_MESSAGE_TEMPLATE_ADD = `${ ROUTE_BLISKO_MESSAGE_TEMPLATE }/dodaj-szablon-wiadomosci`;
export const ROUTE_BLISKO_MESSAGE_TEMPLATE_EDIT_FOR_ROUTING = `${ ROUTE_BLISKO_MESSAGE_TEMPLATE }/edytuj-szablon-wiadomosci/:messageTemplateId`;
export const ROUTE_BLISKO_SENDER_DETAILS = `${ ROUTE_BLISKO }/wizytowka-nadawcy`;

export const ROUTE_BLISKO_ADDRESS_TEMPLATE_LOCATIONS = (addressTemplateId) => {
    const baseRoute = ROUTE_BLISKO_ADDRESS_TEMPLATE;
    if (!addressTemplateId) {
        return baseRoute;
    }

    return `${ baseRoute }/${ addressTemplateId }`
};

export const ROUTE_BLISKO_RECEIVER_GROUP_EDIT = (receiverGroupId) => {
    const baseRoute = ROUTE_BLISKO_RECEIVER_GROUP;
    if (!receiverGroupId) {
        return baseRoute;
    }

    return `${ baseRoute }/${ receiverGroupId }`
};

export const ROUTE_BLISKO_MESSAGE_PREVIEW = (messageId) => {
    const baseRoute = ROUTE_BLISKO_MESSAGES;
    if (!messageId) {
        return baseRoute;
    }

    return `${ baseRoute }/${ messageId }`
}

export const ROUTE_BLISKO_DRAFT_MESSAGE_EDIT = (draftMessageId) => {
    if (!draftMessageId) {
        return ROUTE_BLISKO_MESSAGE_SEND;
    }

    return `${ ROUTE_BLISKO_MESSAGES }/edytuj-wiadomosc/${ draftMessageId }`;
};

export const ROUTE_BLISKO_MESSAGE_TEMPLATE_EDIT = (messageTemplateId) => {
    if (!messageTemplateId) {
        return ROUTE_BLISKO_MESSAGE_TEMPLATE_ADD;
    }

    return `${ ROUTE_BLISKO_MESSAGE_TEMPLATE }/edytuj-szablon-wiadomosci/${ messageTemplateId }`;
};

//---------------------------------------------------------------------------------------------------------------------- misc

export const MISC_SHOW_UNFINISHED_ELEMENTS = ENVIRONMENT === ENVIRONMENT_DEVELOPMENT || ENVIRONMENT === ENVIRONMENT_TEST2 || ENVIRONMENT === ENVIRONMENT_TEST3;
// export const MISC_SHOW_UNFINISHED_ELEMENTS = false;

// regex
export const MISC_ADDRESS_REGEX = /\s*(\d+)([a-z]*)(\s*[\W_]\s*(\d+)([a-z]*))?\s*/iu;
export const REGEX_EMAIL_SIMPLE = /^([\w!#$%&'*+\-/=?^`{|}~]+\.)*[\w!#$%&'*+\-/=?^`{|}~]+@((((([a-z0-9]{1}[a-z0-9-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(:\d{1,5})?)$/i;
// configuration related
export const MISC_STORAGE_ENGINE_NAME = "akun-web-admin";
// date related
export const MISC_DATE_ISO_DATE_ONLY_FORMAT = "YYYY-MM-DD";
export const MISC_DATE_ISO_TIME_ONLY_FORMAT = "HH:mm:ss";
export const MISC_DATE_ISO_FORMAT = MISC_DATE_ISO_DATE_ONLY_FORMAT + "T" + MISC_DATE_ISO_TIME_ONLY_FORMAT;
export const MISC_DATE_POLISH_FORMAT = "DD.MM.YYYY HH:mm:ss";
export const MISC_DATE_DAY_MONTH_FORMAT = "DD MMM";
export const MISC_DATE_DAY_MONTH_YEAR_FORMAT = "DD MMMM YYYY";
export const MISC_DATE_DAY_MONTH_YEAR_HOUR_MINUTE_FORMAT = "DD MMMM YYYY, HH:mm";
export const MISC_DATE_FORMAT = "DD.MM.YYYY";
export const MISC_TIME_FORMAT = "HH:mm:ss";

//file uploads
export const MISC_MAX_SERVICES_ATTACHMENT_SIZE = 2097152; //2MB

// key codes
export const MISC_KEYCODE_ESC = 27;
// special events
export const MISC_EVENT_CLICK = "click";
export const MISC_EVENT_KEYDOWN = "keydown";
// special classes
export const MISC_CLASS_SCROLL_LOCK = "lock-scroll";
// ekostraznik start year
export const EKOSTRAZNIK_START_YEAR = 2020;
// rest
export const MISC_REST_CONTENT_TYPE_APPLICATION_JSON = "application/json";
export const MISC_REST_CONTENT_TYPE_APPLICATION_PDF = "application/pdf";
// tinymce
export const MISC_TINYMCE_API_KEY = "j6k4tcee5x48k2ba42bgbg4aki2wcpxlhlb8sa6k07b8k9us";
// send message page
export const MISC_RECIPIENT_ALL = "all";
export const MISC_RECIPIENT_ADDRESS = "address";
// mapbox
export const MISC_MAPBOX_DEFAULT_MAP_CENTER_COORDINATES = {
    lat: 52.125736,
    lng: 19.080392
};
export const MISC_MAPBOX_API_KEY = "pk.eyJ1Ijoic2lzbXMiLCJhIjoiY2pzNGNnZzg4MDE3bjQzcWs0NXY0YmV3MCJ9.2pf_XKV1uON2_9iaS8l-oA";
export const MISC_MAPBOX_DEFAULT_STYLE = "mapbox://styles/sisms/cjubgsh4105s51fmhdtb92ikh";
export const MISC_MAPBOX_EVENT_LOAD = "load";
export const MISC_MAPBOX_EVENT_MOVE = "move";
export const MISC_MAPBOX_EVENT_MOVEEND = "moveend";

//---------------------------------------------------------------------------------------------------------------------- action types
export {
    actions
};
