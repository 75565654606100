import React from "react";

const SignedOutHeader = ({ signedOutHeaderText, signedOutSubtitle }) => {

    return (
        <div className="header--signed-out">
            <h1 className='title--signed-out'>{ signedOutHeaderText }</h1>

            {
                signedOutSubtitle &&
                <p className="subtitle--signed-out">
                    {
                        signedOutSubtitle
                    }
                </p>
            }

        </div>
    );
};

export default SignedOutHeader;
