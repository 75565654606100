import React from "react";

const TitleWithDivider = ({ children }) => {

    return (
        <div className="title-with-divider">
            <h2 className="title-with-divider__title">{ children }</h2>
            <hr className="title-with-divider__divider"/>
        </div>
    );
};

export default TitleWithDivider;