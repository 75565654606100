import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getManyInspectionCompanies } from "../../../../../../data/actions/ekostraznik/inspection_companies";
import TitleWithDivider from "../../../../../common/title_with_divider";

const SelectAddressTypeForm = ({ formikBag }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getManyInspectionCompanies())
    }, [dispatch]);

    return (
        <div className='is-spaced--top-40'>
            <TitleWithDivider>
                <span className="required-accent">*</span>
                Miejsce inwentaryzacji
            </TitleWithDivider> 
            
            <div>
                { renderOption('address', formikBag, 'Wskazany budynek') }
                { renderOption('group', formikBag, 'Grupa adresów') }
                { renderOption('apartmentAddress', formikBag, 'Wyodrębniony lokal') }
            </div>
        </div>
    );
};

const renderOption = (optionFormType, formikBag, label) => {
    return (
        <label
            className={ `choice-label choice-label--radio ${ optionFormType === formikBag.values.formType ? 'checked--radio' : '' }` }
            onClick={ () => { 
                formikBag.setFieldValue("formType", optionFormType) 
                formikBag.setFieldValue("address", []) 
                formikBag.setFieldValue("groupId", '') 
            } }
        >
            <input className="choice--radio" type="radio" value={ optionFormType } name="formType" />
            { label }
        </label>
    );
};

export default SelectAddressTypeForm;