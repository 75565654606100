import { API_BASE_AKUN_EKOSTRAZNIK_PATH } from "../../../core/constants";
import webClient from "../../../core/web_client";

const ADDRESS_GROUPS_PATH = `${ API_BASE_AKUN_EKOSTRAZNIK_PATH }/address-groups`;
const PATH = `nests`;

export const add = (addressGroupId, { longitude, latitude, name }) => {
    return webClient
        .post(
            `${ ADDRESS_GROUPS_PATH }/${ addressGroupId }/${ PATH }`,
            {
                name,
                locationPoints: [
                    {
                        longitude,
                        latitude
                    }
                ]
            }
        );
};

export const update = (addressGroupId, nestId, { longitude, latitude, name }) => {
    return webClient
        .put(
            `${ ADDRESS_GROUPS_PATH }/${ addressGroupId }/${ PATH }/${ nestId }`,
            {
                name,
                locationPoints: [
                    {
                        longitude,
                        latitude
                    }
                ]
            }
        );
};

export const del = (addressGroupId, nestId) => {
    return webClient.delete(`${ ADDRESS_GROUPS_PATH }/${ addressGroupId }/${ PATH }/${nestId}`);
}