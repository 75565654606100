import { actions } from "../../../core/constants";
import * as api from "../../_api";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const copyReceptionFromYearToYear = (data) => dispatch => {
    return executeCopyAction(() => api
            .waste_management
            .receptions
            .copyReceptionFromYearToYear(data),
        dispatch
    );
};


export const copyReceptionFromAreaToArea = (data, sourceYear) => dispatch => {
    return executeCopyAction(() => api
            .waste_management
            .receptions
            .copyReceptionFromAreaToArea(data, sourceYear),
        dispatch
    );
};

export const copyReceptionFromBinToBin = (data, sourceYear) => dispatch => {
    return executeCopyAction(() => api
            .waste_management
            .receptions
            .copyReceptionFromBinToBin(data, sourceYear),
        dispatch
    );
};


const executeCopyAction = (action, dispatch) => {
    dispatch({
        type: actions.wasteManagement.API__COPY_RECEPTION__STARTED
    });

    return action()
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__COPY_RECEPTION__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__COPY_RECEPTION__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

