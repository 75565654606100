import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";
import { isEmptyArray } from "../../../core/utils/misc_utils";

export const addFile = (file) => dispatch => {
    dispatch({
        type: actions.services.API__FILES__ADD__STARTED
    });

    return api
        .services
        .files
        .addFile(file)
        .then(response => {
            const fileIdArray = extractResponseData(response);

            if (isEmptyArray(fileIdArray)) {
                dispatch({
                    type: actions.services.API__FILES__ADD__FAIL,
                    meta: {
                        name: file.name,
                        size: file.size
                    },
                    payload: null,
                    error: true
                })
            }

            dispatch({
                type: actions.services.API__FILES__ADD__SUCCESS,
                meta: {},
                payload: {
                    id: fileIdArray[0],
                    name: file.name,
                    size: file.size
                },
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__FILES__ADD__FAIL,
                meta: {
                    ...extractErrorData(error),
                    name: file.name,
                    size: file.size
                },
                payload: null,
                error: true
            })
        });
};

export const resetFileState = () => {
    return {
        type: actions.services.API__FILES__RESET
    }
};