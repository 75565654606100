const PREFIX = "services.";

export const API__CATEGORIES__GET_MANY__STARTED = `${ PREFIX }API__CATEGORIES__GET_MANY__STARTED`;
export const API__CATEGORIES__GET_MANY__SUCCESS = `${ PREFIX }API__CATEGORIES__GET_MANY__SUCCESS`;
export const API__CATEGORIES__GET_MANY__FAIL = `${ PREFIX }API__CATEGORIES__GET_MANY__FAIL`;

export const API__CATEGORIES__GET__STARTED = `${ PREFIX }API__CATEGORIES__GET__STARTED`;
export const API__CATEGORIES__GET__SUCCESS = `${ PREFIX }API__CATEGORIES__GET__SUCCESS`;
export const API__CATEGORIES__GET__FAIL = `${ PREFIX }API__CATEGORIES__GET__FAIL`;

export const API__SUBCATEGORIES__GET_MANY__STARTED = `${ PREFIX }API__SUBCATEGORIES__GET_MANY__STARTED`;
export const API__SUBCATEGORIES__GET_MANY__SUCCESS = `${ PREFIX }API__SUBCATEGORIES__GET_MANY__SUCCESS`;
export const API__SUBCATEGORIES__GET_MANY__FAIL = `${ PREFIX }API__SUBCATEGORIES__GET_MANY__FAIL`;

export const API__SUBCATEGORIES_FOR_CATEGORY__GET_MANY__STARTED = `${ PREFIX }API__SUBCATEGORIES_FOR_CATEGORY__GET_MANY__STARTED`;
export const API__SUBCATEGORIES_FOR_CATEGORY__GET_MANY__SUCCESS = `${ PREFIX }API__SUBCATEGORIES_FOR_CATEGORY__GET_MANY__SUCCESS`;
export const API__SUBCATEGORIES_FOR_CATEGORY__GET_MANY__FAIL = `${ PREFIX }API__SUBCATEGORIES_FOR_CATEGORY__GET_MANY__FAIL`;

export const API__SUBCATEGORIES_FOR_CATEGORY__ADD__STARTED = `${ PREFIX }API__SUBCATEGORIES_FOR_CATEGORY__ADD__STARTED`;
export const API__SUBCATEGORIES_FOR_CATEGORY__ADD__SUCCESS = `${ PREFIX }API__SUBCATEGORIES_FOR_CATEGORY__ADD__SUCCESS`;
export const API__SUBCATEGORIES_FOR_CATEGORY__ADD__FAIL = `${ PREFIX }API__SUBCATEGORIES_FOR_CATEGORY__ADD__FAIL`;

export const API__SUBCATEGORIES__UPDATE__STARTED = `${ PREFIX }API__SUBCATEGORIES__UPDATE__STARTED`;
export const API__SUBCATEGORIES__UPDATE__SUCCESS = `${ PREFIX }API__SUBCATEGORIES__UPDATE__SUCCESS`;
export const API__SUBCATEGORIES__UPDATE__FAIL = `${ PREFIX }API__SUBCATEGORIES__UPDATE__FAIL`;

export const API__SUBCATEGORIES__DELETE__STARTED = `${ PREFIX }API__SUBCATEGORIES__DELETE__STARTED`;
export const API__SUBCATEGORIES__DELETE__SUCCESS = `${ PREFIX }API__SUBCATEGORIES__DELETE__SUCCESS`;
export const API__SUBCATEGORIES__DELETE__FAIL = `${ PREFIX }API__SUBCATEGORIES__DELETE__FAIL`;

export const API__SERVICE_CARDS__GET_MANY__STARTED = `${ PREFIX }API__SERVICE_CARDS__GET_MANY__STARTED`;
export const API__SERVICE_CARDS__GET_MANY__SUCCESS = `${ PREFIX }API__SERVICE_CARDS__GET_MANY__SUCCESS`;
export const API__SERVICE_CARDS__GET_MANY__FAIL = `${ PREFIX }API__SERVICE_CARDS__GET_MANY__FAIL`;

export const API__SERVICE_CARDS__GET__STARTED = `${ PREFIX }API__SERVICE_CARDS__GET__STARTED`;
export const API__SERVICE_CARDS__GET__SUCCESS = `${ PREFIX }API__SERVICE_CARDS__GET__SUCCESS`;
export const API__SERVICE_CARDS__GET__FAIL = `${ PREFIX }API__SERVICE_CARDS__GET__FAIL`;

export const API__SERVICE_CARDS__DELETE__STARTED = `${ PREFIX }API__SERVICE_CARDS__DELETE__STARTED`;
export const API__SERVICE_CARDS__DELETE__SUCCESS = `${ PREFIX }API__SERVICE_CARDS__DELETE__SUCCESS`;
export const API__SERVICE_CARDS__DELETE__FAIL = `${ PREFIX }API__SERVICE_CARDS__DELETE__FAIL`;

export const API__SERVICE_CARDS__PUBLISH__STARTED = `${ PREFIX }API__SERVICE_CARDS__PUBLISH__STARTED`;
export const API__SERVICE_CARDS__PUBLISH__SUCCESS = `${ PREFIX }API__SERVICE_CARDS__PUBLISH__SUCCESS`;
export const API__SERVICE_CARDS__PUBLISH__FAIL = `${ PREFIX }API__SERVICE_CARDS__PUBLISH__FAIL`;

export const API__SERVICE_CARDS__HIDE__STARTED = `${ PREFIX }API__SERVICE_CARDS__HIDE__STARTED`;
export const API__SERVICE_CARDS__HIDE__SUCCESS = `${ PREFIX }API__SERVICE_CARDS__HIDE__SUCCESS`;
export const API__SERVICE_CARDS__HIDE__FAIL = `${ PREFIX }API__SERVICE_CARDS__HIDE__FAIL`;

export const API__SERVICE_CARDS__ADD_SERVICE__STARTED = `${ PREFIX }API__SERVICE_CARDS__ADD_SERVICE__STARTED`;
export const API__SERVICE_CARDS__ADD_SERVICE__SUCCESS = `${ PREFIX }API__SERVICE_CARDS__ADD_SERVICE__SUCCESS`;
export const API__SERVICE_CARDS__ADD_SERVICE__FAIL = `${ PREFIX }API__SERVICE_CARDS__ADD_SERVICE__FAIL`;

export const API__SERVICE_CARDS__UPDATE_SERVICE__STARTED = `${ PREFIX }API__SERVICE_CARDS__UPDATE_SERVICE__STARTED`;
export const API__SERVICE_CARDS__UPDATE_SERVICE__SUCCESS = `${ PREFIX }API__SERVICE_CARDS__UPDATE_SERVICE__SUCCESS`;
export const API__SERVICE_CARDS__UPDATE_SERVICE__FAIL = `${ PREFIX }API__SERVICE_CARDS__UPDATE_SERVICE__FAIL`;

export const API__SERVICE_CARDS__ADD_ISSUE_HANDLE__STARTED = `${ PREFIX }API__SERVICE_CARDS__ADD_ISSUE_HANDLE__STARTED`;
export const API__SERVICE_CARDS__ADD_ISSUE_HANDLE__SUCCESS = `${ PREFIX }API__SERVICE_CARDS__ADD_ISSUE_HANDLE__SUCCESS`;
export const API__SERVICE_CARDS__ADD_ISSUE_HANDLE__FAIL = `${ PREFIX }API__SERVICE_CARDS__ADD_ISSUE_HANDLE__FAIL`;

export const API__SERVICE_CARDS__UPDATE_ISSUE_HANDLE__STARTED = `${ PREFIX }API__SERVICE_CARDS__UPDATE_ISSUE_HANDLE__STARTED`;
export const API__SERVICE_CARDS__UPDATE_ISSUE_HANDLE__SUCCESS = `${ PREFIX }API__SERVICE_CARDS__UPDATE_ISSUE_HANDLE__SUCCESS`;
export const API__SERVICE_CARDS__UPDATE_ISSUE_HANDLE__FAIL = `${ PREFIX }API__SERVICE_CARDS__UPDATE_ISSUE_HANDLE__FAIL`;

export const API__SERVICE_CARDS_FOR_CATEGORY__GET_MANY__STARTED = `${ PREFIX }API__SERVICE_CARDS_FOR_CATEGORY__GET_MANY__STARTED`;
export const API__SERVICE_CARDS_FOR_CATEGORY__GET_MANY__SUCCESS = `${ PREFIX }API__SERVICE_CARDS_FOR_CATEGORY__GET_MANY__SUCCESS`;
export const API__SERVICE_CARDS_FOR_CATEGORY__GET_MANY__FAIL = `${ PREFIX }API__SERVICE_CARDS_FOR_CATEGORY__GET_MANY__FAIL`;

export const API__KEYWORDS__GET_MANY__STARTED = `${ PREFIX }API__KEYWORDS__GET_MANY__STARTED`;
export const API__KEYWORDS__GET_MANY__SUCCESS = `${ PREFIX }API__KEYWORDS__GET_MANY__SUCCESS`;
export const API__KEYWORDS__GET_MANY__FAIL = `${ PREFIX }API__KEYWORDS__GET_MANY__FAIL`;

export const API__RESPONSIBLE_ENTITIES__GET_MANY__STARTED = `${ PREFIX }API__RESPONSIBLE_ENTITIES__GET_MANY__STARTED`;
export const API__RESPONSIBLE_ENTITIES__GET_MANY__SUCCESS = `${ PREFIX }API__RESPONSIBLE_ENTITIES__GET_MANY__SUCCESS`;
export const API__RESPONSIBLE_ENTITIES__GET_MANY__FAIL = `${ PREFIX }API__RESPONSIBLE_ENTITIES__GET_MANY__FAIL`;

export const API__RESPONSIBLE_ENTITIES__ADD__STARTED = `${ PREFIX }API__RESPONSIBLE_ENTITIES__ADD__STARTED`;
export const API__RESPONSIBLE_ENTITIES__ADD__SUCCESS = `${ PREFIX }API__RESPONSIBLE_ENTITIES__ADD__SUCCESS`;
export const API__RESPONSIBLE_ENTITIES__ADD__FAIL = `${ PREFIX }API__RESPONSIBLE_ENTITIES__ADD__FAIL`;

export const API__RESPONSIBLE_ENTITIES__UPDATE__STARTED = `${ PREFIX }API__RESPONSIBLE_ENTITIES__UPDATE__STARTED`;
export const API__RESPONSIBLE_ENTITIES__UPDATE__SUCCESS = `${ PREFIX }API__RESPONSIBLE_ENTITIES__UPDATE__SUCCESS`;
export const API__RESPONSIBLE_ENTITIES__UPDATE__FAIL = `${ PREFIX }API__RESPONSIBLE_ENTITIES__UPDATE__FAIL`;

export const API__RESPONSIBLE_ENTITIES__DELETE__STARTED = `${ PREFIX }API__RESPONSIBLE_ENTITIES__DELETE__STARTED`;
export const API__RESPONSIBLE_ENTITIES__DELETE__SUCCESS = `${ PREFIX }API__RESPONSIBLE_ENTITIES__DELETE__SUCCESS`;
export const API__RESPONSIBLE_ENTITIES__DELETE__FAIL = `${ PREFIX }API__RESPONSIBLE_ENTITIES__DELETE__FAIL`;

export const API__FILES__ADD__STARTED = `${ PREFIX }API__FILES__ADD__STARTED`;
export const API__FILES__ADD__SUCCESS = `${ PREFIX }API__FILES__ADD__SUCCESS`;
export const API__FILES__ADD__FAIL = `${ PREFIX }API__FILES__ADD__FAIL`;
export const API__FILES__RESET = `${ PREFIX }API__FILES__RESET`;