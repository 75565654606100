const PREFIX = "blisko.";

export const API__BLISKO__MESSAGES__GET__STARTED = `${ PREFIX }API__BLISKO__MESSAGES__GET__STARTED`;
export const API__BLISKO__MESSAGES__GET__SUCCESS = `${ PREFIX }API__BLISKO__MESSAGES__GET__SUCCESS`;
export const API__BLISKO__MESSAGES__GET__FAIL = `${ PREFIX }API__BLISKO__MESSAGES__GET__FAIL`;

export const API__BLISKO__MESSAGES__GET_MANY__STARTED = `${ PREFIX }API__BLISKO__MESSAGES__GET_MANY__STARTED`;
export const API__BLISKO__MESSAGES__GET_MANY__SUCCESS = `${ PREFIX }API__BLISKO__MESSAGES__GET_MANY__SUCCESS`;
export const API__BLISKO__MESSAGES__GET_MANY__FAIL = `${ PREFIX }API__BLISKO__MESSAGES__GET_MANY__FAIL`;

export const API__BLISKO__MESSAGES__GET_MESSAGE_TEMPLATES__STARTED = `${ PREFIX }API__BLISKO__MESSAGES__GET_MESSAGE_TEMPLATES__STARTED`;
export const API__BLISKO__MESSAGES__GET_MESSAGE_TEMPLATES__SUCCESS = `${ PREFIX }API__BLISKO__MESSAGES__GET_MESSAGE_TEMPLATES__SUCCESS`;
export const API__BLISKO__MESSAGES__GET_MESSAGE_TEMPLATES__FAIL = `${ PREFIX }API__BLISKO__MESSAGES__GET_MESSAGE_TEMPLATES__FAIL`;

export const API__BLISKO__MESSAGES__ADD__STARTED = `${ PREFIX }API__BLISKO__MESSAGES__ADD__STARTED`;
export const API__BLISKO__MESSAGES__ADD__SUCCESS = `${ PREFIX }API__BLISKO__MESSAGES__ADD__SUCCESS`;
export const API__BLISKO__MESSAGES__ADD__FAIL = `${ PREFIX }API__BLISKO__MESSAGES__ADD__FAIL`;

export const API__BLISKO__MESSAGES__DELETE__STARTED = `${ PREFIX }API__BLISKO__MESSAGES__DELETE__STARTED`;
export const API__BLISKO__MESSAGES__DELETE__SUCCESS = `${ PREFIX }API__BLISKO__MESSAGES__DELETE__SUCCESS`;
export const API__BLISKO__MESSAGES__DELETE__FAIL = `${ PREFIX }API__BLISKO__MESSAGES__DELETE__FAIL`;

export const API__BLISKO__MESSAGE_ADDRESSES__GET_MANY__STARTED = `${ PREFIX }API__BLISKO__MESSAGE_ADDRESSES__GET_MANY__STARTED`;
export const API__BLISKO__MESSAGE_ADDRESSES__GET_MANY__SUCCESS = `${ PREFIX }API__BLISKO__MESSAGE_ADDRESSES__GET_MANY__SUCCESS`;
export const API__BLISKO__MESSAGE_ADDRESSES__GET_MANY__FAIL = `${ PREFIX }API__BLISKO__MESSAGE_ADDRESSES__GET_MANY__FAIL`;

export const API__BLISKO__MESSAGE_ADDRESSES__UPDATE__STARTED = `${ PREFIX }API__BLISKO__MESSAGE_ADDRESSES__UPDATE__STARTED`;
export const API__BLISKO__MESSAGE_ADDRESSES__UPDATE__SUCCESS = `${ PREFIX }API__BLISKO__MESSAGE_ADDRESSES__UPDATE__SUCCESS`;
export const API__BLISKO__MESSAGE_ADDRESSES__UPDATE__FAIL = `${ PREFIX }API__BLISKO__MESSAGE_ADDRESSES__UPDATE__FAIL`;

export const API__BLISKO__ADDRESS_TEMPLATES__GET_MANY__STARTED = `${ PREFIX }API__BLISKO__ADDRESS_TEMPLATES__GET_MANY__STARTED`;
export const API__BLISKO__ADDRESS_TEMPLATES__GET_MANY__SUCCESS = `${ PREFIX }API__BLISKO__ADDRESS_TEMPLATES__GET_MANY__SUCCESS`;
export const API__BLISKO__ADDRESS_TEMPLATES__GET_MANY__FAIL = `${ PREFIX }API__BLISKO__ADDRESS_TEMPLATES__GET_MANY__FAIL`;

export const API__BLISKO__ADDRESS_TEMPLATES__GET__STARTED = `${ PREFIX }API__BLISKO__ADDRESS_TEMPLATES__GET__STARTED`;
export const API__BLISKO__ADDRESS_TEMPLATES__GET__SUCCESS = `${ PREFIX }API__BLISKO__ADDRESS_TEMPLATES__GET__SUCCESS`;
export const API__BLISKO__ADDRESS_TEMPLATES__GET__FAIL = `${ PREFIX }API__BLISKO__ADDRESS_TEMPLATES__GET__FAIL`;

export const API__BLISKO__ADDRESS_TEMPLATES__ADD__STARTED = `${ PREFIX }API__BLISKO__ADDRESS_TEMPLATES__ADD__STARTED`;
export const API__BLISKO__ADDRESS_TEMPLATES__ADD__SUCCESS = `${ PREFIX }API__BLISKO__ADDRESS_TEMPLATES__ADD__SUCCESS`;
export const API__BLISKO__ADDRESS_TEMPLATES__ADD__FAIL = `${ PREFIX }API__BLISKO__ADDRESS_TEMPLATES__ADD__FAIL`;

export const API__BLISKO__ADDRESS_TEMPLATES__UPDATE__STARTED = `${ PREFIX }API__BLISKO__ADDRESS_TEMPLATES__UPDATE__STARTED`;
export const API__BLISKO__ADDRESS_TEMPLATES__UPDATE__SUCCESS = `${ PREFIX }API__BLISKO__ADDRESS_TEMPLATES__UPDATE__SUCCESS`;
export const API__BLISKO__ADDRESS_TEMPLATES__UPDATE__FAIL = `${ PREFIX }API__BLISKO__ADDRESS_TEMPLATES__UPDATE__FAIL`;

export const API__BLISKO__ADDRESS_TEMPLATES__DELETE_MANY__STARTED = `${ PREFIX }API__BLISKO__ADDRESS_TEMPLATES__DELETE_MANY__STARTED`;
export const API__BLISKO__ADDRESS_TEMPLATES__DELETE_MANY__SUCCESS = `${ PREFIX }API__BLISKO__ADDRESS_TEMPLATES__DELETE_MANY__SUCCESS`;
export const API__BLISKO__ADDRESS_TEMPLATES__DELETE_MANY__FAIL = `${ PREFIX }API__BLISKO__ADDRESS_TEMPLATES__DELETE_MANY__FAIL`;

export const API__BLISKO__ADDRESS_TEMPLATE_LOCATIONS__GET__STARTED = `${ PREFIX }API__BLISKO__ADDRESS_TEMPLATE_LOCATIONS__GET__STARTED`;
export const API__BLISKO__ADDRESS_TEMPLATE_LOCATIONS__GET__SUCCESS = `${ PREFIX }API__BLISKO__ADDRESS_TEMPLATE_LOCATIONS__GET__SUCCESS`;
export const API__BLISKO__ADDRESS_TEMPLATE_LOCATIONS__GET__FAIL = `${ PREFIX }API__BLISKO__ADDRESS_TEMPLATE_LOCATIONS__GET__FAIL`;

export const API__BLISKO__ADDRESS_TEMPLATE_LOCATIONS__PATCH_MANY__STARTED = `${ PREFIX }API__BLISKO__ADDRESS_TEMPLATE_LOCATIONS__PATCH_MANY__STARTED`;
export const API__BLISKO__ADDRESS_TEMPLATE_LOCATIONS__PATCH_MANY__SUCCESS = `${ PREFIX }API__BLISKO__ADDRESS_TEMPLATE_LOCATIONS__PATCH_MANY__SUCCESS`;
export const API__BLISKO__ADDRESS_TEMPLATE_LOCATIONS__PATCH_MANY__FAIL = `${ PREFIX }API__BLISKO__ADDRESS_TEMPLATE_LOCATIONS__PATCH_MANY__FAIL`;

export const API__BLISKO__MESSAGE_TEMPLATES__GET_MANY_PAGINATED__STARTED = `${ PREFIX }API__BLISKO__MESSAGE_TEMPLATES__GET_MANY_PAGINATED__STARTED`;
export const API__BLISKO__MESSAGE_TEMPLATES__GET_MANY_PAGINATED__SUCCESS = `${ PREFIX }API__BLISKO__MESSAGE_TEMPLATES__GET_MANY_PAGINATED__SUCCESS`;
export const API__BLISKO__MESSAGE_TEMPLATES__GET_MANY_PAGINATED__FAIL = `${ PREFIX }API__BLISKO__MESSAGE_TEMPLATES__GET_MANY_PAGINATED__FAIL`;

export const API__BLISKO__MESSAGE_TEMPLATES__GET__STARTED = `${ PREFIX }API__BLISKO__MESSAGE_TEMPLATES__GET__STARTED`;
export const API__BLISKO__MESSAGE_TEMPLATES__GET__SUCCESS = `${ PREFIX }API__BLISKO__MESSAGE_TEMPLATES__GET__SUCCESS`;
export const API__BLISKO__MESSAGE_TEMPLATES__GET__FAIL = `${ PREFIX }API__BLISKO__MESSAGE_TEMPLATES__GET__FAIL`;

export const API__BLISKO__MESSAGE_TEMPLATES__ADD__STARTED = `${ PREFIX }API__BLISKO__MESSAGE_TEMPLATES__ADD__STARTED`;
export const API__BLISKO__MESSAGE_TEMPLATES__ADD__SUCCESS = `${ PREFIX }API__BLISKO__MESSAGE_TEMPLATES__ADD__SUCCESS`;
export const API__BLISKO__MESSAGE_TEMPLATES__ADD__FAIL = `${ PREFIX }API__BLISKO__MESSAGE_TEMPLATES__ADD__FAIL`;

export const API__BLISKO__MESSAGE_TEMPLATES__UPDATE__STARTED = `${ PREFIX }API__BLISKO__MESSAGE_TEMPLATES__UPDATE__STARTED`;
export const API__BLISKO__MESSAGE_TEMPLATES__UPDATE__SUCCESS = `${ PREFIX }API__BLISKO__MESSAGE_TEMPLATES__UPDATE__SUCCESS`;
export const API__BLISKO__MESSAGE_TEMPLATES__UPDATE__FAIL = `${ PREFIX }API__BLISKO__MESSAGE_TEMPLATES__UPDATE__FAIL`;

export const API__BLISKO__MESSAGE_TEMPLATES__DELETE_MANY__STARTED = `${ PREFIX }API__BLISKO__MESSAGE_TEMPLATES__DELETE_MANY__STARTED`;
export const API__BLISKO__MESSAGE_TEMPLATES__DELETE_MANY__SUCCESS = `${ PREFIX }API__BLISKO__MESSAGE_TEMPLATES__DELETE_MANY__SUCCESS`;
export const API__BLISKO__MESSAGE_TEMPLATES__DELETE_MANY__FAIL = `${ PREFIX }API__BLISKO__MESSAGE_TEMPLATES__DELETE_MANY__FAIL`;

export const API__BLISKO__MESSAGE_TEMPLATES__DELETE__STARTED = `${ PREFIX }API__BLISKO__MESSAGE_TEMPLATES__DELETE__STARTED`;
export const API__BLISKO__MESSAGE_TEMPLATES__DELETE__SUCCESS = `${ PREFIX }API__BLISKO__MESSAGE_TEMPLATES__DELETE__SUCCESS`;
export const API__BLISKO__MESSAGE_TEMPLATES__DELETE__FAIL = `${ PREFIX }API__BLISKO__MESSAGE_TEMPLATES__DELETE__FAIL`;

export const API__BLISKO__DRAFT_MESSAGES__ADD__STARTED = `${ PREFIX }API__BLISKO__DRAFT_MESSAGES__ADD__STARTED`;
export const API__BLISKO__DRAFT_MESSAGES__ADD__SUCCESS = `${ PREFIX }API__BLISKO__DRAFT_MESSAGES__ADD__SUCCESS`;
export const API__BLISKO__DRAFT_MESSAGES__ADD__FAIL = `${ PREFIX }API__BLISKO__DRAFT_MESSAGES__ADD__FAIL`;

export const API__BLISKO__DRAFT_MESSAGES__UPDATE__STARTED = `${ PREFIX }API__BLISKO__DRAFT_MESSAGES__UPDATE__STARTED`;
export const API__BLISKO__DRAFT_MESSAGES__UPDATE__SUCCESS = `${ PREFIX }API__BLISKO__DRAFT_MESSAGES__UPDATE__SUCCESS`;
export const API__BLISKO__DRAFT_MESSAGES__UPDATE__FAIL = `${ PREFIX }API__BLISKO__DRAFT_MESSAGES__UPDATE__FAIL`;

export const API__BLISKO__DRAFT_MESSAGES__PATCH__STARTED = `${ PREFIX }API__BLISKO__DRAFT_MESSAGES__PATCH__STARTED`;
export const API__BLISKO__DRAFT_MESSAGES__PATCH__SUCCESS = `${ PREFIX }API__BLISKO__DRAFT_MESSAGES__PATCH__SUCCESS`;
export const API__BLISKO__DRAFT_MESSAGES__PATCH__FAIL = `${ PREFIX }API__BLISKO__DRAFT_MESSAGES__PATCH__FAIL`;

export const API__BLISKO__DRAFT_MESSAGES__GET_MANY__STARTED = `${ PREFIX }API__BLISKO__DRAFT_MESSAGES__GET_MANY__STARTED`;
export const API__BLISKO__DRAFT_MESSAGES__GET_MANY__SUCCESS = `${ PREFIX }API__BLISKO__DRAFT_MESSAGES__GET_MANY__SUCCESS`;
export const API__BLISKO__DRAFT_MESSAGES__GET_MANY__FAIL = `${ PREFIX }API__BLISKO__DRAFT_MESSAGES__GET_MANY__FAIL`;

export const API__BLISKO__DRAFT_MESSAGES__GET__STARTED = `${ PREFIX }API__BLISKO__DRAFT_MESSAGES__GET__STARTED`;
export const API__BLISKO__DRAFT_MESSAGES__GET__SUCCESS = `${ PREFIX }API__BLISKO__DRAFT_MESSAGES__GET__SUCCESS`;
export const API__BLISKO__DRAFT_MESSAGES__GET__FAIL = `${ PREFIX }API__BLISKO__DRAFT_MESSAGES__GET__FAIL`;

export const API__BLISKO__DRAFT_MESSAGES__DELETE__STARTED = `${ PREFIX }API__BLISKO__DRAFT_MESSAGES__DELETE__STARTED`;
export const API__BLISKO__DRAFT_MESSAGES__DELETE__SUCCESS = `${ PREFIX }API__BLISKO__DRAFT_MESSAGES__DELETE__SUCCESS`;
export const API__BLISKO__DRAFT_MESSAGES__DELETE__FAIL = `${ PREFIX }API__BLISKO__DRAFT_MESSAGES__DELETE__FAIL`;

export const API__BLISKO__DRAFT_MESSAGE_ADDRESSES__GET_MANY__STARTED = `${ PREFIX }API__BLISKO__DRAFT_MESSAGE_ADDRESSES__GET_MANY__STARTED`;
export const API__BLISKO__DRAFT_MESSAGE_ADDRESSES__GET_MANY__SUCCESS = `${ PREFIX }API__BLISKO__DRAFT_MESSAGE_ADDRESSES__GET_MANY__SUCCESS`;
export const API__BLISKO__DRAFT_MESSAGE_ADDRESSES__GET_MANY__FAIL = `${ PREFIX }API__BLISKO__DRAFT_MESSAGE_ADDRESSES__GET_MANY__FAIL`;

export const API__BLISKO__DRAFT_MESSAGE_ADDRESSES__UPDATE__STARTED = `${ PREFIX }API__BLISKO__DRAFT_MESSAGE_ADDRESSES__UPDATE__STARTED`;
export const API__BLISKO__DRAFT_MESSAGE_ADDRESSES__UPDATE__SUCCESS = `${ PREFIX }API__BLISKO__DRAFT_MESSAGE_ADDRESSES__UPDATE__SUCCESS`;
export const API__BLISKO__DRAFT_MESSAGE_ADDRESSES__UPDATE__FAIL = `${ PREFIX }API__BLISKO__DRAFT_MESSAGE_ADDRESSES__UPDATE__FAIL`;

export const API__RECEIVER_GROUPS__GET_MANY__STARTED = `${ PREFIX }API__RECEIVER_GROUPS__GET_MANY__STARTED`;
export const API__RECEIVER_GROUPS__GET_MANY__SUCCESS = `${ PREFIX }API__RECEIVER_GROUPS__GET_MANY__SUCCESS`;
export const API__RECEIVER_GROUPS__GET_MANY__FAIL = `${ PREFIX }API__RECEIVER_GROUPS__GET_MANY__FAIL`;

export const API__RECEIVER_GROUPS__GET__STARTED = `${ PREFIX }API__RECEIVER_GROUPS__GET__STARTED`;
export const API__RECEIVER_GROUPS__GET__SUCCESS = `${ PREFIX }API__RECEIVER_GROUPS__GET__SUCCESS`;
export const API__RECEIVER_GROUPS__GET__FAIL = `${ PREFIX }API__RECEIVER_GROUPS__GET__FAIL`;

export const API__RECEIVER_GROUPS__ADD_AND_PUBLISH__STARTED = `${ PREFIX }API__RECEIVER_GROUPS__ADD_AND_PUBLISH__STARTED`;
export const API__RECEIVER_GROUPS__ADD_AND_PUBLISH__SUCCESS = `${ PREFIX }API__RECEIVER_GROUPS__ADD_AND_PUBLISH__SUCCESS`;
export const API__RECEIVER_GROUPS__ADD_AND_PUBLISH__FAIL = `${ PREFIX }API__RECEIVER_GROUPS__ADD_AND_PUBLISH__FAIL`;

export const API__RECEIVER_GROUPS__UPDATE__STARTED = `${ PREFIX }API__RECEIVER_GROUPS__UPDATE__STARTED`;
export const API__RECEIVER_GROUPS__UPDATE__SUCCESS = `${ PREFIX }API__RECEIVER_GROUPS__UPDATE__SUCCESS`;
export const API__RECEIVER_GROUPS__UPDATE__FAIL = `${ PREFIX }API__RECEIVER_GROUPS__UPDATE__FAIL`;

export const API__RECEIVER_GROUPS__HIDE__STARTED = `${ PREFIX }API__RECEIVER_GROUPS__HIDE__STARTED`;
export const API__RECEIVER_GROUPS__HIDE__SUCCESS = `${ PREFIX }API__RECEIVER_GROUPS__HIDE__SUCCESS`;
export const API__RECEIVER_GROUPS__HIDE__FAIL = `${ PREFIX }API__RECEIVER_GROUPS__HIDE__FAIL`;

export const API__RECEIVER_GROUPS__ACTIVE__STARTED = `${ PREFIX }API__RECEIVER_GROUPS__ACTIVE__STARTED`;
export const API__RECEIVER_GROUPS__ACTIVE__SUCCESS = `${ PREFIX }API__RECEIVER_GROUPS__ACTIVE__SUCCESS`;
export const API__RECEIVER_GROUPS__ACTIVE__FAIL = `${ PREFIX }API__RECEIVER_GROUPS__ACTIVE__FAIL`;

export const API__RECEIVER_GROUPS__DELETE__STARTED = `${ PREFIX }API__RECEIVER_GROUPS__DELETE__STARTED`;
export const API__RECEIVER_GROUPS__DELETE__SUCCESS = `${ PREFIX }API__RECEIVER_GROUPS__DELETE__SUCCESS`;
export const API__RECEIVER_GROUPS__DELETE__FAIL = `${ PREFIX }API__RECEIVER_GROUPS__DELETE__FAIL`;

export const API__SENDER_DETAILS__GET__STARTED = `${ PREFIX }API__SENDER_DETAILS__GET__STARTED`;
export const API__SENDER_DETAILS__GET__SUCCESS = `${ PREFIX }API__SENDER_DETAILS__GET__SUCCESS`;
export const API__SENDER_DETAILS__GET__FAIL = `${ PREFIX }API__SENDER_DETAILS__GET__FAIL`;

export const API__SENDER_DETAILS__UPDATE__STARTED = `${ PREFIX }API__SENDER_DETAILS__UPDATE__STARTED`;
export const API__SENDER_DETAILS__UPDATE__SUCCESS = `${ PREFIX }API__SENDER_DETAILS__UPDATE__SUCCESS`;
export const API__SENDER_DETAILS__UPDATE__FAIL = `${ PREFIX }API__SENDER_DETAILS__UPDATE__FAIL`;
