import { deepFreeze } from "../../utils/misc_utils";

export const MunicipalWasteEnum = deepFreeze({
    reception: {
        status: {
            EMPTY: "EMPTY",
            NEW: "NEW",
            PUBLISHED: "PUBLISHED",
            REMOVED: "REMOVED"
        }
    }
});