import { DEFAULT_STATE, DEFAULT_STATE__API__STARTED, DEFAULT_STATE__API__SUCCESS, DEFAULT_STATE__API__FAIL } from "../../../../../core/utils/store_utils";
import { actions } from "../../../../../core/constants";

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case actions.wasteManagement.API__NEWSLETTER_OWNER__GET__STARTED:
            return DEFAULT_STATE__API__STARTED(state, DEFAULT_STATE);
        case actions.wasteManagement.API__NEWSLETTER_OWNER__GET__SUCCESS:
            return DEFAULT_STATE__API__SUCCESS(state, DEFAULT_STATE, action);
        case actions.wasteManagement.API__NEWSLETTER_OWNER__GET__FAIL:
            return DEFAULT_STATE__API__FAIL(state, DEFAULT_STATE, action);
        default:
            return state;
    }
}