import React, { Component } from "react";

import { ReactComponent as IconH3 } from "../../../../../resource/img/icons/wysiwyg/header1.svg";
import { ReactComponent as IconH4 } from "../../../../../resource/img/icons/wysiwyg/header2.svg";
import { ReactComponent as IconBold } from "../../../../../resource/img/icons/wysiwyg/icon_bold.svg";
import { ReactComponent as IconItalic } from "../../../../../resource/img/icons/wysiwyg/icon_italic.svg";
import { ReactComponent as IconUnderline } from "../../../../../resource/img/icons/wysiwyg/icon_underline.svg";
import { ReactComponent as IconUl } from "../../../../../resource/img/icons/wysiwyg/icon_ul.svg";
import { ReactComponent as IconOl } from "../../../../../resource/img/icons/wysiwyg/icon_ol.svg";
import { ReactComponent as IconInfo } from "../../../../../resource/img/icons/wysiwyg/icon_info.svg";
import { ReactComponent as IconWarning } from "../../../../../resource/img/icons/wysiwyg/icon_warning.svg";
import { ReactComponent as IconImage } from "../../../../../resource/img/icons/wysiwyg/icon_image.svg";
import { ReactComponent as IconLink } from "../../../../../resource/img/icons/wysiwyg/icon_link.svg";

const withStyleButton = (OriginalComponent) => {

    class WrappedComponent extends Component {

        render = () => {
            return (
                <OriginalComponent
                    _renderIcon={ this.renderIcon }
                    { ...this.props }/>
            )
        };

        renderIcon = () => {
            const { label } = this.props;

            switch (label) {
                case "IconH3":
                    return <IconH3/>;
                case "IconH4":
                    return <IconH4/>;
                case "IconBold":
                    return <IconBold/>;
                case "IconItalic":
                    return <IconItalic/>;
                case "IconUnderline":
                    return <IconUnderline/>;
                case "IconUl":
                    return <IconUl/>;
                case "IconOl":
                    return <IconOl/>;
                case "IconInfo":
                    return <IconInfo/>;
                case "IconWarning":
                    return <IconWarning/>;
                case "IconImage":
                    return <IconImage/>;
                case "IconLink":
                    return <IconLink/>;
                default:
                    return "";
            }
        };
    }

    return WrappedComponent;
};

export default withStyleButton;