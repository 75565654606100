import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const getManyBulkyWaste = () => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__BULKY_WASTE__GET_MANY__STARTED
    });

    return api
        .waste_management
        .bulkyWaste
        .getManyBulkyWaste()
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__BULKY_WASTE__GET_MANY__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__BULKY_WASTE__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const addBulkyWaste = (data) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__BULKY_WASTE__ADD__STARTED
    });

    return api
        .waste_management
        .bulkyWaste
        .addBulkyWaste(data)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__BULKY_WASTE__ADD__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__BULKY_WASTE__ADD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const deleteManyBulkyWaste = (idArray) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__BULKY_WASTE__DELETE_MANY__STARTED
    });

    return api
        .waste_management
        .bulkyWaste
        .deleteManyBulkyWaste(idArray)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__BULKY_WASTE__DELETE_MANY__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__BULKY_WASTE__DELETE_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: false
            });
        });
};

export const updateBulkyWaste = (id, data) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__BULKY_WASTE__UPDATE__STARTED
    });

    return api
        .waste_management
        .bulkyWaste
        .updateBulkyWaste(id, data)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__BULKY_WASTE__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {

            dispatch({
                type: actions.wasteManagement.API__BULKY_WASTE__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: false
            });
        });
};

export const publishManyBulkyWaste = (idArray) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__BULKY_WASTE__PUBLISH_MANY__STARTED
    });

    return api
        .waste_management
        .bulkyWaste
        .publishBulkyWaste(idArray)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__BULKY_WASTE__PUBLISH_MANY__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__BULKY_WASTE__PUBLISH_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: false
            });
        });
};

export const getBulkyWasteConfig = () => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__BULKY_WASTE_CONFIG__GET__STARTED
    });

    return api
        .waste_management
        .bulkyWaste
        .getBulkyWasteConfig()
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__BULKY_WASTE_CONFIG__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__BULKY_WASTE_CONFIG__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: false
            });
        });
};

export const updateBulkyWasteConfig = (numberOfDays) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__BULKY_WASTE_CONFIG__UPDATE__STARTED
    });

    return api
        .waste_management
        .bulkyWaste
        .updateBulkyWasteConfig({ days: numberOfDays })
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__BULKY_WASTE_CONFIG__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__BULKY_WASTE_CONFIG__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: false
            });
        });
};