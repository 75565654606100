import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import SubcategoryListItemDeleteModalForm from "./delete_list_item_modal_form";
import ServiceCardsEnum from "../../../../../../core/enums/services/service_cards";
import { ROUTE_SERVICES_EDIT_CARD } from "../../../../../../core/constants";
import { deleteServiceCard, hideServiceCard, publishServiceCard } from "../../../../../../data/actions/services/service_cards";
import { ReactComponent as IconPublished } from "../../../../../../../resource/img/icons/status/icon_done.svg";
import { ReactComponent as IconInProgress } from "../../../../../../../resource/img/icons/status/icon_in_progress.svg";
import { ReactComponent as IconHidden } from "../../../../../../../resource/img/icons/status/icon_to_do.svg";
import { ReactComponent as IconEdit } from "../../../../../../../resource/img/icons/icon_edit.svg";
import { ReactComponent as IconDelete } from "../../../../../../../resource/img/icons/icon_delete.svg";

const SubcategoryListItem = ({ serviceCard, deleteServiceCard, hideServiceCard, publishServiceCard, _refreshData }) => {
    return (
        <tr>
            <td className='data--flexible'>
                <p className="table-text-content ">
                    {
                        serviceCard.title
                    }
                </p>
            </td>
            <td className="data--fixed subcategory-list-item-status">
                <div className="full-width space-between">
                    <div className="flex-center">

                        {
                            renderStatus(serviceCard.status)
                        }

                    </div>
                    <div>

                        {
                            renderStatusChangeButton(
                                serviceCard.status, serviceCard.id, hideServiceCard, publishServiceCard, _refreshData)
                        }

                        <Link to={ ROUTE_SERVICES_EDIT_CARD(serviceCard.id) }
                              className="action-btn">
                            <IconEdit/>
                        </Link>
                        <span className="action-btn">
                            <SubcategoryListItemDeleteModalForm
                                _actionFunction={ () =>
                                    deleteServiceCard(serviceCard.id)
                                        .then(() => _refreshData())
                                }
                                triggerComponent={
                                    <button className='unstyled-btn'>
                                        <IconDelete/>
                                    </button>
                                }/>
                        </span>
                    </div>
                </div>

            </td>

        </tr>
    );
};

const renderStatus = (status) => {

    switch (status) {
        case ServiceCardsEnum.status.PUBLISHED:
            return (
                <>
                    <span className="table-status">
                        <IconPublished/>
                    </span>
                    Opublikowana
                </>
            );

        case ServiceCardsEnum.status.HIDDEN:
            return (
                <>
                    <span className="table-status">
                        <IconHidden/>
                    </span>
                    Ukryta
                </>
            );

        case ServiceCardsEnum.status.DRAFT:
            return (
                <>
                    <span className="table-status">
                        <IconInProgress/>
                    </span>
                    Wersja robocza
                </>
            );

        default:
        // empty
    }
};

const renderStatusChangeButton = (status, serviceCardId, hideServiceCard, publishServiceCard, _refreshData) => {

    switch (status) {
        case ServiceCardsEnum.status.PUBLISHED:
            return (
                <button className="btn btn--xsmall btn--short btn--outlined"
                        onClick={ () =>
                            hideServiceCard(serviceCardId)
                                .then(() => _refreshData()) }>
                    Ukryj
                </button>
            );

        case ServiceCardsEnum.status.HIDDEN:
            return (
                <button className="btn btn--xsmall btn--short btn--primary"
                        onClick={ () =>
                            publishServiceCard(serviceCardId)
                                .then(() => _refreshData()) }>
                    Opublikuj
                </button>
            );

        case ServiceCardsEnum.status.DRAFT:
            return (
                <button className="btn btn--xsmall btn--short btn--primary"
                        disabled>
                    Opublikuj
                </button>
            );

        default:
        // empty
    }
};

export default connect(null, { deleteServiceCard, hideServiceCard, publishServiceCard })(SubcategoryListItem);