import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ROUTE_SIGN_IN } from "../../../../core/constants";


class PasswordResetConfirmation extends Component {

    render = () => {
        return (
            <>
                <h1 className="signed-out-page__confirmation_header">Hasło zostało zmienione</h1>
                <Link to={ ROUTE_SIGN_IN } className="btn btn--primary btn--full-width">Wróć do logowania</Link>
            </>
        );
    };
}

export default PasswordResetConfirmation;
