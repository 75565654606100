import add from "./add";
import tokenCheck from "./token_check";
import update from "./update";


export default (state = {}, action) => {
    return {
        ...state,
        add: add(state.add, action),
        tokenCheck: tokenCheck(state.tokenCheck, action),
        update: update(state.update, action)
    };
}