export const displayBinName = (bin) => {

    switch (bin) {
        case "BLACK" :
            return "Zmieszane odpady komunalne";
        case "GREEN" :
            return "Szkło";
        case "GREEN_WHITE" :
            return "Szkło bezbarwne";
        case "GREEN_COLOR" :
            return "Szkło kolorowe";
        case "BLUE" :
            return "Papier i tektura";
        case "YELLOW" :
            return "Tworzywa sztuczne i metale";
        case "BROWN" :
            return "Odpady biodegradowalne";
        case "PURPLE":
            return "Odpady wielkogabarytowe";
        case "DARK_BLUE":
            return "Elektroodpady";
        case "GRAY":
            return "Popiół";
        case "YELLOW_BLACK":
            return "Pozostałe odpady opakowaniowe";
        case "LIGHT_BROWN":
            return "Odpady zielone";
        default:
            return ""
    }
};

