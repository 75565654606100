import React from "react";
import _ from "lodash";

import { isNullOrUndefined } from "../../../core/utils/misc_utils";
import SideListItem from "./side_list_item";
import SideListPagination from "./side_list_pagination";

const SideList = ({ data, selectedListItem, list, renderIconFunction, _onListItemClick, listType, _onPrevClick, _onNextClick, _onPageNumberInputValueChange }) => {

    return (
        <div className={ `sll__list-wrapper sll__list-wrapper--with-pagination` }>

            <ul className="sll__list">
                {
                    !isNullOrUndefined(list) &&
                    _.map(list, listItem =>
                        <SideListItem
                            key={ listItem.code }
                            listItem={ listItem }
                            active={ listItem.code === selectedListItem }
                            _onListItemClick={ _onListItemClick }
                            listType={ listType }
                            renderIconFunction={ renderIconFunction }/>
                    )
                }
            </ul>
            <SideListPagination
                data={ data }
                _onPrevClick={ _onPrevClick }
                _onNextClick={ _onNextClick }
                _onPageNumberInputValueChange={ _onPageNumberInputValueChange }/>
        </div>
    );
};

export default SideList;