import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";

import { ROUTE_SERVICES_ADD_CARD } from "../../../../../core/constants";
import Header from "../../../../common/header";
import Subcategory from "./subcategory/index";
import SubcategoryCreateOrEditModalForm from "./subcategory/create_or_edit_modal_form";
import Loader from "../../../../common/loader";
import { ReactComponent as IconAdd } from "../../../../../../resource/img/icons/icon_add_white.svg";
import { getCategory } from "../../../../../data/actions/services/categories";
import { getManyServiceCardsForCategory } from "../../../../../data/actions/services/service_cards";
import { addSubcategoryToCategory, getManySubcategoriesForCategory, updateSubcategory } from "../../../../../data/actions/services/subcategories";
import { isEmptyObject, isLoading, isLoadingError, isNullOrUndefined } from "../../../../../core/utils/misc_utils";
import BlankPage from "../../../../common/blank_page";
import noDataAvailableIcon from "../../../../../../resource/img/icons/empty_list_icons/brak_wpisow.png";


class CategoryDetails extends Component {

    render = () => {
        const { category, subcategoriesForCategory, serviceCardsForCategory } = this.props;

        if (isLoadingError(category) ||
            isLoadingError(subcategoriesForCategory) ||
            isLoadingError(serviceCardsForCategory)) {
            // TODO
            return (
                <div>Błąd wczytywania danych</div>
            );
        }

        if (isLoading(category) ||
            isLoading(subcategoriesForCategory) ||
            isLoading(serviceCardsForCategory) ||
            isNullOrUndefined(category) ||
            isNullOrUndefined(category.data)) {

            return (
                <Loader/>
            );
        }

        return (
            <div className="">
                <Header>
                    {
                        !isNullOrUndefined(category) &&
                        !isNullOrUndefined(category.data) &&
                        category.data.name
                    }
                </Header>
                <section className="container is-fluid">
                    <div className="row row--options justify-end">
                        <Link to={ ROUTE_SERVICES_ADD_CARD } className="btn btn--primary">
                            <span className="btn__icon-wrapper--left">
                                        <span className="btn__icon">
                                            <IconAdd/>
                                        </span>
                                        Dodaj nową kartę
                            </span>
                        </Link>
                        <SubcategoryCreateOrEditModalForm
                            headerText="Dodaj podkategorię"
                            triggerComponent={
                                <button className="btn btn--outlined header__main-cta-btn action-btn">
                                    Dodaj podkategorię
                                </button>
                            }
                            categoryId={ category.data.id }
                            _onSubmit={ this._onCreateOrEditSubcategorySubmit }/>
                    </div>
                </section>
                <main className="container is-fluid">

                    {
                        !isEmptyObject(subcategoriesForCategory.data)
                            ?
                            _.map(subcategoriesForCategory.data, subcategory => {
                                return (
                                    <Subcategory
                                        key={ subcategory.id }
                                        subcategory={ subcategory }
                                        serviceCards={
                                            _.filter(serviceCardsForCategory.data, serviceCard =>
                                                serviceCard.assignedSubcategoryIds.includes(subcategory.id))
                                        }
                                        _refreshData={ this.refreshData }
                                        _onCreateOrEditSubcategorySubmit={ this._onCreateOrEditSubcategorySubmit }/>
                                );
                            })
                            :
                            <BlankPage
                            img={ noDataAvailableIcon }
                            text="Brak podkategorii"/>
                    }

                </main>
            </div>
        );
    };

    componentDidMount = () => {
        this.refreshData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { match } = this.props;
        const { categoryId } = match.params;

        if (prevProps.match.params.categoryId !== categoryId) {
            this.refreshData();
        }
    }

    refreshData = () => {
        const { getCategory, getManySubcategoriesForCategory, getManyServiceCardsForCategory, match } = this.props;
        const { categoryId } = match.params;

        return getCategory(categoryId)
            .then(() => getManySubcategoriesForCategory(categoryId))
            .then(() => getManyServiceCardsForCategory(categoryId));
    };

    _onCreateOrEditSubcategorySubmit = (categoryId, subcategoryId, data) => {
        const { addSubcategoryToCategory, updateSubcategory } = this.props;

        if (isNullOrUndefined(subcategoryId)) {
            // add
            return addSubcategoryToCategory(categoryId, data)
                .then(() => this.refreshData());
        } else {
            // update
            return updateSubcategory(subcategoryId, data)
                .then(() => this.refreshData());
        }
    };
}

const mapStateToProps = (state) => {
    return {
        category: state.entities.services.categories.get,
        subcategoriesForCategory: state.entities.services.subcategories.getManyForCategory,
        serviceCardsForCategory: state.entities.services.serviceCards.getManyForCategory
    };
};

export default connect(mapStateToProps, { getCategory, updateSubcategory, addSubcategoryToCategory, getManySubcategoriesForCategory, getManyServiceCardsForCategory })(CategoryDetails);