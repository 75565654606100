import webClient, { webClientFileDownload } from "../../../core/web_client";
import { API_BASE_AKUN_EKOSTRAZNIK_PATH } from "../../../core/constants";
import { buildQueryString } from "../../../core/utils/api_utils";

export const PATH = `${ API_BASE_AKUN_EKOSTRAZNIK_PATH }/reports`;
export const PATH_REPORT_TYPES = `${ API_BASE_AKUN_EKOSTRAZNIK_PATH }/report-types`;
export const PATH_QUANTITATIVE_REPORT = `${ PATH }/quantitative-report`;

export const getManyReports = (queryParams) => {
    return webClient
        .get(`${ PATH }${buildQueryString(queryParams)}`);
};

// side effect: sets report as read
export const getReport = (id) => {
    return webClient
        .get(`${ PATH }/${ id }`);
};

export const updateReport = (id, data) => {
    return webClient
        .patch(`${ PATH }/${ id }`, data);
};

export const getReportTypes = () => {
    return webClient
        .get(`${ PATH_REPORT_TYPES }`);
};

export const addReport = (data) => {
    return webClient
        .post(`${ PATH }`, data);
};

export const getQuantitativeReport = (data) => {
    return webClientFileDownload
        .patch(PATH_QUANTITATIVE_REPORT, data);
};
