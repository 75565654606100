import React, { useEffect } from "react";
import SettingsHeader from "../common/header";
import { SettingsEnum } from "../../../../../core/enums/common/settings";
import { Form, Formik } from "formik";
import validator from 'validator'
import { useDispatch, useSelector } from "react-redux";
import {
    getAccessibilityStatement,
    updateAccessibilityStatement
} from "../../../../../data/actions/common/accessibility_statement";
import Loader, { ButtonLoader } from "../../../../common/loader";

const SettingsAccessibilityStatement = () => {
    const dispatch = useDispatch();
    const updateStatus = useSelector(state => {
        return state.entities?.common?.accessibilityStatement?.update
    });
    const declaration = useSelector(state => {
        return state.entities?.common?.accessibilityStatement?.get
    });

    useEffect(() => {
       dispatch(getAccessibilityStatement())
    }, [dispatch])

    if (!declaration.data) {
        return <Loader />
    }

    return (
        <div className="side-spacing">
            <SettingsHeader tab={ SettingsEnum.tab.ACCESSIBILITY_STATEMENT }/>
            <div className="content--xs content--to-left">
                <div className='is-spaced--bottom-10'>
                    Podaj link do deklaracji dostępności dla Twoich usług.
                    Po zapisaniu link będzie dostępny dla użytkowników aplikacji takich jak Blisko, Segrego, Karty Usług.
                </div>
                <Formik
                    initialValues={ { url: declaration.data.url } }
                    onSubmit={ values => {
                        dispatch(updateAccessibilityStatement(values))
                            .then(() => dispatch(getAccessibilityStatement()))
                    } }
                    validate={ values => {
                        if (!validator.isURL(values.url)) {
                            return { url: 'Niepoprawny link' };
                        }
                    } }
                >
                    {
                        formik => (
                            <Form>
                                <div className='field'>
                                    <label className='label'>
                                        Link do deklaracji dostępności
                                        <input
                                            value={formik.values.url}
                                            type='text'
                                            onChange={ event => formik.setFieldValue('url', event.target.value) }
                                        />
                                        <span className='hint'>np.: https://www.twojagmina.pl/deklaracjadostepnosci</span>
                                        {
                                            formik.errors.url &&
                                            <span className='hint hint--rejected'>{ formik.errors.url }</span>
                                        }
                                    </label>
                                </div>
                                {
                                    updateStatus.meta.loading &&
                                    <button disabled type='submit' className='btn btn--primary btn--medium'>
                                        <ButtonLoader />
                                    </button>
                                }
                                {
                                    !updateStatus.meta.loading &&
                                    <button type='submit' className='btn btn--primary btn--medium'>Zapisz</button>
                                }
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </div>
    );
}

export default SettingsAccessibilityStatement;