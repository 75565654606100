import React, { useEffect } from "react";

import { isLoading } from "../../../../../../core/utils/misc_utils";
import Loader from "../../../../../common/loader";
import { Link } from "react-router-dom";
import { MISC_DATE_FORMAT, MISC_TIME_FORMAT, ROUTE_BLISKO_MESSAGES } from "../../../../../../core/constants";
import { ReactComponent as IconBack } from "../../../../../../../resource/img/icons/icon_back_blue.svg";
import MessageSummary from "../../../../../common/message_summary";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { getMessage } from "../../../../../../data/actions/blisko/messages";
import { getManyReceiverGroups } from "../../../../../../data/actions/blisko/receiver_groups";
import { MessageEnum } from "../../../../../../core/enums/waste_management/messages";

const MessagePreviewBlisko = ({ match }) => {
    const dispatch = useDispatch();
    const message = useSelector(state => state.entities?.blisko?.messages?.get);
    const messageId = match.params.messageId;

    useEffect(() => {
        dispatch(getManyReceiverGroups());
        dispatch(getMessage(messageId));
    }, [dispatch, messageId]);

    if (isLoading(message)) {
        return (
            <Loader/>
        );
    }

    return (
        <div>
            <header className='page-header page-header--with-back'>
                <Link to={ ROUTE_BLISKO_MESSAGES } className="unstyled-btn">
                    <IconBack/>
                </Link>

                {
                    message.data &&

                    <h1 className='title is-1'>
                        {
                            message.data.sendingStatus === "READY" &&
                            "Zaplanowana wysyłka"
                        }
                        {
                            message.data.sendingStatus === "SENT" &&
                            "Wiadomość wysłana"
                        }
                        {
                            message.data.sendingStatus === "CANCELED" &&
                            "Wiadomość anulowana"
                        }
                    </h1>
                }
            </header>

            <main className="notifications-2">
                <div className="content--m content--not-centered">

                    {
                        message.data &&

                        <MessageSummary
                            time={ moment(message.data.requestedRealization).format(MISC_TIME_FORMAT) }
                            date={ moment(message.data.requestedRealization).format(MISC_DATE_FORMAT) }
                            priority={ Object.entries(MessageEnum.priorityLevel).filter(item => item[0] === message.data.priority).map(item => item[1])[0] }
                            title={ message.data.title }
                            body={ message.data.body }
                            receiverGroupIds={ message.data.receiverGroups.map(receiverGroup => receiverGroup.id) }
                            latitude={ message.data.latitude }
                            longitude={ message.data.longitude }
                            locations={ message.data.recipientAddresses }
                            uploadedFiles={ message.data.attachments }
                            useLocationFilter={ true }
                            useGeoLocation={ true }
                        />
                    }
                </div>
            </main>
        </div>
    );
};

export default MessagePreviewBlisko;