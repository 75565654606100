import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as IconEdit } from "../../../../../../../resource/img/icons/icon_edit.svg";
import FormModal from "../../../../../common/modal/form_modal";
import { editAddressGroup } from "../../../../../../data/actions/ekostraznik/address_groups";
import { toastForCustomError } from "../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../data/actions/common/ui/toast";

const EditAddressGroupForm = ({ _onSuccess, group }) => {
    const dispatch = useDispatch()
    const updateStatus = useSelector(state => state.entities?.ekostraznik?.addressGroups?.update)
    const [groupName, setGroupName] = useState(group?.name)

    return (
        <FormModal
            headerText="Zmień nazwę grupy"
            triggerComponent={
                <button className='unstyled-btn' title='Edytuj'>
                    <IconEdit/>
                </button>
            }
            saveOrCancelButtons={ true }
            saveButtonText="Zmień nazwę"
            isLoading={ updateStatus?.meta?.loading }
            submitDisabled={ groupName.length === 0 }
            _actionFunction={ () =>
                dispatch(editAddressGroup(group?.id, { name: groupName }))
                    .then(async response => {
                        if (response?.status === 400) {
                            toastForCustomError((...args) => dispatch(showToast(...args)), 'Podana nazwa już istnieje');
                        } else {
                            return _onSuccess()
                        }
                    })
            }
        >
            <div className='c-modal__body modal-size-m'>
                <p className='is-spaced--bottom-10'>
                    <strong>Pamiętaj! </strong>
                    Po zmianie nazwy wygeneruj nowe naklejki z kodami QR dla tej grupy,
                    ponieważ nazwa grupy zapisana na naklejkach nie będzie zgodna.
                </p>
                <input
                    placeholder="Wpisz nazwę grupy"
                    type='text'
                    value={ groupName }
                    onChange={ (event) => setGroupName(event.target.value) }/>
            </div>
        </FormModal>
    );
}
export default EditAddressGroupForm;