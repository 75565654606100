import React, { Component } from "react";
import { connect } from "react-redux";
import { getAccount } from "../../data/actions/common/account";
import _ from "lodash";
import { chooseOwner } from "../../data/actions/common/authentication";
import { Redirect } from "react-router-dom";
import { isLoading, isLoadingError, isNullOrUndefined } from "../../core/utils/misc_utils";
import Loader from "../common/loader";
import { ROUTE_MODULE_SELECTION } from "../../core/constants";

class OwnerSelection extends Component {

    state = {
        toModuleSelection: false,
    };

    render = () => {
        const { account } = this.props;

        if (isLoadingError(account)) {
            // TODO
            return <div>Błąd wczytywania nadawców</div>;
        }

        if (isLoading(account)) {
            return <Loader/>;
        }

        if (this.state.toModuleSelection === true ||
            (!isNullOrUndefined(account.data) && _.size(account.data.owners) === 1)) {
            return <Redirect to={ ROUTE_MODULE_SELECTION }/>;
        }

        return (
            <div className="tile-selection">
                <header className="is-spaced--bottom-20">
                    <h1 className="title is-1">
                        Wybierz nadawcę
                    </h1>
                </header>

                <div className="columns is-multiline">
                    {
                        !isNullOrUndefined(account.data) &&
                        _.map(account.data.owners, owner => {
                            return (
                                <div className="column is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
                                     key={ owner.id }
                                     onClick={ () => this.onOwnerClick(owner.id, owner.name) }>
                                    <div className="module-wrapper">

                                        <div className="module">
                                            <div className="module__name">
                                                <h2 className="title is-2 is-spaced">
                                                    {
                                                        owner.name
                                                    }
                                                </h2>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    };

    componentDidMount = () => {
        const { getAccount } = this.props;
        getAccount();
    };

    onOwnerClick = (ownerId, ownerName) => {
        const { getAccount, chooseOwner } = this.props;

        return chooseOwner({ ownerId, ownerName })
            .then(() => getAccount())
            .then(() =>
                this.setState({
                    toModuleSelection: true
                })
            );
    };
}

const mapStateToProps = (state) => {
    return {
        account: state.entities.common.account.get
    };
};

export default connect(mapStateToProps, { getAccount, chooseOwner })(OwnerSelection);
