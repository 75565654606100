import React, { Component } from "react";
import moment from "moment";

import { ReactComponent as IconDownload } from "../../../../../../../resource/img/icons/icon_download.svg";
import { ReactComponent as IconStatusActive } from "../../../../../../../resource/img/icons/icon_status_active.svg";
import { ReactComponent as IconStatusPending } from "../../../../../../../resource/img/icons/icon_status_pending.svg";
import { ReactComponent as IconStatusArchived } from "../../../../../../../resource/img/icons/icon_status_archived.svg";

import { MISC_DATE_FORMAT, MISC_TIME_FORMAT, MISC_DATE_ISO_FORMAT } from "../../../../../../core/constants";
import { SettingsEnum } from "../../../../../../core/enums/common/settings";
import RegulationDelete from "./delete_modal_form";


class WasteManagementRegulationsItem extends Component {

    render = () => {
        const { regulation, _onDownloadRegulationClick } = this.props;

        return (
            <tr>
                <td className='t-width-4'>
                    {
                        regulation.place.name
                    }
                </td>
                <td className='t-width-2'>
                    {
                        moment(regulation.fromDate, MISC_DATE_ISO_FORMAT)
                            .format(MISC_DATE_FORMAT)
                    }
                </td>
                <td className='t-width-2 td--column'>
                    <p className="">
                        {
                            moment(regulation.additionDate, MISC_DATE_ISO_FORMAT)
                                .format(MISC_DATE_FORMAT)
                        }
                    </p>
                    <p className="secondary-text">
                        {
                            moment(regulation.additionDate, MISC_DATE_ISO_FORMAT)
                                .format(MISC_TIME_FORMAT)
                        }
                    </p>
                </td>
                <td className='t-width-2'>
                    {
                        this.renderRegulationStatus(regulation.status)
                    }
                </td>
                <td className='sticky--right td-action-buttons'>
                    {
                        regulation.status === SettingsEnum.status.PENDING &&
                        <RegulationDelete id={ regulation.id }/>
                    }

                    <button onClick={ () => _onDownloadRegulationClick(regulation.id, regulation.filename) }  className='unstyled-btn'><IconDownload/></button>
                </td>
            </tr>
        );
    };

    renderRegulationStatus = (regulationStatus) => {
        if(regulationStatus === SettingsEnum.status.ACTIVE){
            return <>
                <IconStatusActive className="status-icon"/>
                Aktywny
            </>
        }
        else if(regulationStatus === SettingsEnum.status.PENDING){
            return <>
                <IconStatusPending className="status-icon"/>
                Oczekujący
            </>
        }
        else if(regulationStatus === SettingsEnum.status.ARCHIVED){
            return <>
                <IconStatusArchived className="status-icon"/>
                Archiwalny
            </>
        }
    };

}



export default WasteManagementRegulationsItem;
