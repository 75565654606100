import React, { useCallback, useState } from "react";
import Dropzone from "react-dropzone";

import { ReactComponent as IconAddBlue } from "../../../resource/img/icons/icon_add_blue.svg";
import { ReactComponent as IconDelete } from "../../../resource/img/icons/icon_cancel_small.svg";
import InfoBanner from "./info_banner";
import AddOrEditAlternativeTextModalForm from './modal/forms/alternative_text_modal';

const MB_TO_B_MULTIPLIER = 1024 * 1024;

export const FileUpload = (
    {
        disabled,
        _onStart,
        acceptedFileExtensions,
        maxTotalFilesSizeInMB,
        maxSingleFileSizeInMB,
        maxFiles,
        hint,
        currentFiles,
        customErrorMessage
    }) => {

    if (!acceptedFileExtensions || acceptedFileExtensions?.length < 1) {
        throw new Error('acceptedFileExtensions not specified');
    }

    const [error, setError] = useState();
    const [isDragActive, setIsDragActive] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const onDrop = useCallback(files => {
        setError(false);

        if (files?.length === 0) {
            setError('Niepoprawny plik.');
        }

        if (!checkFilesType(files, acceptedFileExtensions)) {
            setError('Niepoprawny format pliku.');
            return;
        }

        if ((maxSingleFileSizeInMB || maxTotalFilesSizeInMB) && !checkFilesSize(files, maxSingleFileSizeInMB || maxTotalFilesSizeInMB)) {
            setError('Za duży rozmiar pliku.');
            return;
        }

        if (maxTotalFilesSizeInMB && currentFiles && !checkTotalFilesSize([...files, ...currentFiles], maxTotalFilesSizeInMB)) {
            setError('Przekroczony dozwolony łączny rozmiar plików.');
            return;
        }

        _onStart && _onStart(files);

        // eslint-disable-next-line
    }, []);

    if (maxFiles > 1 && currentFiles?.length >= maxFiles) {
        return (
            <div className='dropzone__info-banner'>
                <InfoBanner>Maksymalna liczba plików została osiągnięta.</InfoBanner>
            </div>
        );
    }

    return (
        <Dropzone
            onDrop={ onDrop }
            disabled={ disabled }
            multiple={ false }
            onDragEnter={ () => setIsDragActive(true) }
            onDragLeave={ () => setIsDragActive(false) }
        >
            { ({ getRootProps, getInputProps }) => (
                <div
                    { ...getRootProps() }
                    className='dropzone-root'
                    onMouseOver={ () => setIsHovered(true) }
                    onMouseOut={ () => setIsHovered(false) }
                >
                    <input { ...getInputProps() } />
                    <div className={ `dropzone dropzone--horizontal ${ error || customErrorMessage ? 'dropzone--error' : '' } ${ isHovered ? 'dropzone--hover' : '' }` }>
                        <IconAddBlue className="dropzone__icon"/>
                        <span className="dropzone__text">
                            <span className={ isDragActive ? 'dropzone__text--gray' : '' }>Wybierz plik</span>
                            <span className={ isDragActive || isHovered ? 'dropzone__text--gray' : '' }> lub </span>
                            <span className={ !isDragActive && isHovered ? 'dropzone__text--gray' : '' }>przeciągnij go tutaj</span>
                        </span>
                    </div>
                    <p className="notice--small hint--rejected">
                        { error }
                        { customErrorMessage }
                    </p>
                    <p className="notice--small">
                        {
                            hint &&
                            <>
                                { hint }
                                <br />
                            </>
                        }
                        {`
                            Wymagany format pliku: ${ acceptedFileExtensions.join(', ') }.
                            ${ maxFiles > 1 ? `Maksymalna liczba plików: ${ maxFiles }.` : ''}
                            ${ maxSingleFileSizeInMB ? `Rozmiar pliku nie może przekraczać ${ maxSingleFileSizeInMB }MB.` : ''}
                            ${ maxFiles > 1 && maxTotalFilesSizeInMB ? `Łączny rozmiar plików nie może przekraczać ${ maxTotalFilesSizeInMB }MB.` : ''}
                        `}
                    </p>
                </div>
            ) }
        </Dropzone>
    );
};


const checkFilesType = (files, acceptedFileExtensions) => {
    for (const file of files) {
        if (!acceptedFileExtensions.includes(getFileExtension(file))) {
            return false;
        }
    }

    return true;
};

const checkFilesSize = (files, maxSizeInMB) => {
    for (const file of files) {
        if (file.size > maxSizeInMB * MB_TO_B_MULTIPLIER) {
            return false;
        }
    }

    return true;
};

const checkTotalFilesSize = (files, maxSizeInMB) => {
    const totalSize = files.reduce((sum, file) => sum + (file.size || file.sizeInBytes), 0);

    return totalSize <= maxSizeInMB * MB_TO_B_MULTIPLIER;
};

const getFileExtension = (file) => {
    if (!file.name.match(/\./)) {
        return '';
    }

    return file.name.split('.').pop().toLowerCase();
}

export const UploadedFile = ({ id, name, error, isLoading, alternativeTextRequired, initialAlternativeText, _onDeleteUploadedFileClick, _onAlternativeTextChanged }) => {
    const [ alternativeText, setAlternativeText ] = useState(initialAlternativeText || '');

    return (
        <>
            <div className={ `uploaded-file ${ error ? 'uploaded-file--error' : ''  }` }>
                <div className={ `uploaded-file__icon ${ alternativeTextRequired ? 'uploaded-file__moved-section' : ''  }` }></div>
                <div className="uploaded-file__name">
                    <p className={ `prevent-long-text ${ isLoading ? " file-name--is-loading" : "" }` }>
                        { name }
                    </p>
                    {
                        alternativeTextRequired &&
                        <p className="uploaded-file__alternative_text">

                            {
                                alternativeText &&
                                <span>{ alternativeText }</span>
                            }

                            <span className="update-action__link">
                                <AddOrEditAlternativeTextModalForm
                                    fileId={ id }
                                    alternativeText={ alternativeText }
                                    triggerComponent={ alternativeText ? 'Zmień opis' : 'Dodaj opis alternatywny pliku' }
                                    _onUpdateSuccess={ (text) => {
                                        _onAlternativeTextChanged(text);
                                        setAlternativeText(text);
                                    } }
                                />
                            </span>
                        </p>
                    }
                </div>
                {
                    !isLoading &&

                    <div tabIndex="0"
                         title="Usuń"
                         className="uploaded-file__delete"
                         onClick={ () => {
                             if (_onDeleteUploadedFileClick) {
                                 _onDeleteUploadedFileClick(id);
                             }
                         } }
                    >
                        <IconDelete/>
                    </div>
                }

                {
                    isLoading &&
                    <progress className="progress is-small is-primary" max="100"/>
                }
            </div>

            {
                error &&
                <strong className="hint hint--rejected">
                    { error }
                </strong>
            }
        </>
    );
};

