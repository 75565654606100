import React, { useCallback, useEffect, useState } from "react";

import AddressesListItem from "./item";
import noAddressesImg from "../../../../../../../../../resource/img/icons/empty_list_icons/dodaj_adresy.png";
import { useDispatch, useSelector } from "react-redux";
import EmptyListPage from "../../../../../../../common/empty_list_page";
import FormModal from "../../../../../../../common/modal/form_modal";
import { ReactComponent as IconArrowSwitchUp } from "../../../../../../../../../resource/img/icons/list/icon_arrow_switch_up.svg";
import { ReactComponent as IconDeleteWhite } from "../../../../../../../../../resource/img/icons/icon_delete--white-bin.svg";
import ListPagination from "../../../../../../../common/pagination";
import withPagination from "../../../../../../../common/hoc/with_pagination";
import { getManyAddressesFromAddressGroup } from "../../../../../../../../data/actions/ekostraznik/addresses";
import { generatePaginationQueryValues } from "../../../../../../../../core/utils/api_utils";
import CheckboxInput from "../../../../../../../common/checkbox_input";
import { deleteAddressesFromGroup } from "../../../../../../../../data/actions/ekostraznik/address_group_addresses";

const defaultPaginationQueryValues = generatePaginationQueryValues(0, 30, ["name", "asc"]);

const AddressesList = ({ groupId, searchBarTerm, _onPrevClick, _onNextClick, _onPageNumberInputValueChange, currentPage, setMaxPage }) => {
    const dispatch = useDispatch();
    const [checkedAddresses, setCheckedAddresses] = useState([]);
    const addresses = useSelector(state => state.entities?.ekostraznik?.addressGroups?.addresses?.getMany);
    const deleteStatus = useSelector(state => state.entities?.ekostraznik?.addressGroups?.addresses?.update);

    const totalPages = addresses?.data?.totalPages;
    const isAddressesEmpty = !addresses?.data;
    const allChecked = checkedAddresses.length === addresses?.data?.content?.length;

    const _reloadTable = useCallback((searchBarTerm) => {
        dispatch(getManyAddressesFromAddressGroup(
            groupId,
            {
                ...defaultPaginationQueryValues,
                page: currentPage,
                query: searchBarTerm
            }
        )).then(() => {
                if (totalPages !== undefined) {
                    setMaxPage(totalPages);
                }

                setCheckedAddresses([]);
            }
        );
    }, [groupId, totalPages, currentPage, dispatch, setMaxPage]);

    useEffect(() => {
        _reloadTable(searchBarTerm);
        // eslint-disable-next-line
    }, [currentPage, searchBarTerm]);

    const _onAddressCheckboxChange = (id) => {
        const addressIndex = checkedAddresses.indexOf(id);

        if (addressIndex >= 0) {
            const newCheckedAddresses = [...checkedAddresses];
            newCheckedAddresses.splice(addressIndex, 1);

            setCheckedAddresses(newCheckedAddresses)
        } else {
            setCheckedAddresses(prevState => [...prevState, id])
        }
    };

    const _onAllAddressCheckboxesChange = () => {
        if (allChecked) {
            setCheckedAddresses([]);
        } else {
            const addressIds = addresses?.data?.content
                .map(address => {
                    return address.id
                });
            setCheckedAddresses(addressIds);
        }
    };

    const _onSelectedAddressesDeleteClick = () => {
        return dispatch(deleteAddressesFromGroup(
            groupId,
            checkedAddresses
        )).then(_onDeleteOrUpdateSuccess);
    };

    const _onDeleteOrUpdateSuccess = () => {
        _reloadTable(searchBarTerm);
    };

    if (addresses?.data?.content?.length === 0) {
        return (
            <EmptyListPage
                img={ noAddressesImg }
                imgAlt='Brak adresów'
                text={ !searchBarTerm ? 'Dodaj adresy, które należą do grupy' : 'Brak pasujących wyników' }
            />
        )
    }

    return (
        <>
            <section className="main-content--table__bottom container is-fluid">
                <div className='table-wrapper'>
                    <table className='table--single-line fixed'>
                        <thead>
                        <tr>
                            <th className='t-width-11'>
                                <CheckboxInput
                                    label="Lokalizacja"
                                    _onInputChange={ _onAllAddressCheckboxesChange }
                                    checked={ allChecked }/>

                                <div className='th-action-btn'>
                                    <IconArrowSwitchUp/>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>

                        {
                            !isAddressesEmpty &&
                            renderListItems(addresses, groupId, checkedAddresses, _onDeleteOrUpdateSuccess, _onAddressCheckboxChange)
                        }

                        </tbody>
                    </table>
                </div>
            </section>
            <section className="container is-fluid is-flex">
                <div className="sll__pagination-wrapper">
                    {
                        checkedAddresses.length > 0 &&
                        <FormModal
                            triggerComponent={
                                <div className="btn btn--danger btn--small">
                                        <span className="btn__icon-wrapper--left">
                                            <span className="btn__icon">
                                                <IconDeleteWhite/>
                                            </span>
                                            Usuń
                                        </span>
                                </div>
                            }
                            deleteOrCancelButtons={ true }
                            _actionFunction={ _onSelectedAddressesDeleteClick }
                            isLoading={ deleteStatus?.meta?.loading }
                        >

                            <div className='c-modal__body modal-size-m modal--no-header'>
                                <p className="confirmation-alert">
                                    Czy napewno chcesz
                                    usunąć { checkedAddresses.length === 1 ? 'wybrany adres' : "wybrane adresy" }?<br/>
                                    Usunięcie wpłynie na dane wyświetlane w pozostałych zakładkach.
                                </p>
                            </div>

                        </FormModal>
                    }
                </div>
                <div className="is-flex justify-end pagination--list">
                    {
                        !isAddressesEmpty &&
                        <ListPagination
                            data={ addresses.data }
                            _onPrevClick={ _onPrevClick }
                            _onNextClick={ _onNextClick }
                            _onPageNumberInputValueChange={ _onPageNumberInputValueChange }
                        />
                    }
                </div>
            </section>
        </>
    );
};

const renderListItems = (addresses, groupId, checkedAddresses, _onDeleteOrUpdateSuccess, _onAddressCheckboxChange) => {

    return addresses.data.content
        .map(address => {
            return (
                <AddressesListItem
                    key={ address.name }
                    address={ address }
                    groupId={ groupId }
                    checkedAddresses={ checkedAddresses }
                    _onDeleteOrUpdateSuccess={ _onDeleteOrUpdateSuccess }
                    _onAddressCheckboxChange={ _onAddressCheckboxChange }
                />
            );
        });
};

export default withPagination(AddressesList);
