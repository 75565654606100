import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getManyBins } from "../../../../../../data/actions/ekostraznik/bins";
import Select from "../../../../../common/select";
import TitleWithDivider from "../../../../../common/title_with_divider";
import { ReactComponent as IconAdd } from "../../../../../../../resource/img/icons/icon_add_white.svg";
import { ReactComponent as IconDelete } from "../../../../../../../resource/img/icons/icon_delete.svg";
import { isEmptyArray } from "../../../../../../core/utils/misc_utils";

const SelectBinForm = ({ formikBag }) => {
    const dispatch = useDispatch();
    const bins = useSelector(state => state.entities?.ekostraznik?.bins?.getMany);

    useEffect(() => {
        dispatch(getManyBins())
    }, [dispatch]);

    const binsOptions = useMemo(() => {
        if (bins.data?.length) {
           return  bins.data.map(bin => ({
               label: bin.name,
               value: bin.code,
           }));
        }
        return [];
    }, [bins]);

    const updateSelectedBinInventory = (bin, newCode, newCapacity, binCount) => {   
        const binInventories = formikBag.values.binInventories;
        binInventories[findBinInventoryArrayIndexByPosition(bin.position)] = {
            position: bin.position,
            binCode: newCode,
            binCapacity: newCapacity,
            binCount: binCount 
        }   
        formikBag.setFieldValue("binInventories", binInventories);
    }

    const onAddNextBinInventory = () => {
        const binInventories = formikBag.values.binInventories;
        const lastBinInventory = binInventories[binInventories.length - 1]
        binInventories.push({
            position: binInventories.length ? lastBinInventory.position + 1 : 0,
            binCode: '',
            binCapacity: '',
            binCount: ''
        })
        formikBag.setFieldValue("binInventories", binInventories);
    }

    const onDeleteBinInventory = (bin) => {
        const binInventories = formikBag.values.binInventories;
        binInventories.splice(findBinInventoryArrayIndexByPosition(bin.position), 1);
        formikBag.setFieldValue("binInventories", binInventories);
    }

    const findBinInventoryArrayIndexByPosition = (position) => {
        for (const [arrIndex, binInventory] of formikBag.values.binInventories.entries()) {
            if (binInventory.position === position) {
                return arrIndex;
            }
        }
        return null;
    }

    return (
        <div className='is-spaced--top-40'>
            <TitleWithDivider>
                <span className="required-accent">*</span>
                Kontrolowane frakcje
            </TitleWithDivider>
            <div>
            {
                !isEmptyArray(formikBag.values.binInventories) &&
                formikBag.values.binInventories.map(binInventory => {
                    return (
                        <div key={ binInventory.position } >
                            <div className={ ` ${ binInventory.position < 1 ? 'is-spaced--top-10' : 'is-spaced--top-40' } ` }>
                                <div>
                                    <span className='label-name is-spaced--top-20'>Kontrolowana frakcja</span>
                                    <div className='multiple-inputs-row'>
                                        <Select
                                            value={ binInventory.binCode ? undefined : null }
                                            options={ binsOptions }
                                            isClearable={ false }
                                            onChange={ option => {
                                                updateSelectedBinInventory(binInventory, option.value, binInventory.binCapacity, binInventory.binCount);
                                            } }
                                        />
                                        <button 
                                            className="btn"
                                            onClick={ () => onDeleteBinInventory(binInventory) }
                                        >
                                            <IconDelete/>
                                        </button>
                                    </div>
                                    <div className='multiple-inputs-row'>
                                    {
                                        formikBag.errors.errorsBinCode?.length > 0 &&
                                        <span className="hint hint--rejected">
                                            { formikBag.errors.errorsBinCode[binInventory.position] }
                                        </span>
                                    }
                                    </div>
                                </div>
                                <div className='multiple-inputs-row is-spaced--top-20'>
                                    <div className='input-size-xl is-spaced--right-40'>
                                        <span className='label-name'>Pojemność pojemnika</span>
                                        <input
                                            type="number"
                                            value={ binInventory.binCapacity }
                                            onChange={ event => 
                                                updateSelectedBinInventory(binInventory, binInventory.binCode, event.target.value, binInventory.binCount)
                                            }
                                        />
                                        <span className='notice--small'>
                                            Podaj pojemność w litrach, np. 360
                                        </span>
                                        {
                                            formikBag.errors.emptyBinCapacity?.length > 0 &&
                                            <span className="hint hint--rejected">
                                                { formikBag.errors.emptyBinCapacity[binInventory.position] }
                                            </span>
                                        }
                                        {
                                            formikBag.errors.wrongFormatBinCapacity?.length > 0 &&
                                            <span className="hint hint--rejected">
                                                { formikBag.errors.wrongFormatBinCapacity[binInventory.position] }
                                            </span>
                                        }
                                    </div>
                                    <div className='input-size-s is-spaced--right-65'>
                                        <span className='label-name'>Liczba</span>
                                        <input
                                            type="number" 
                                            value={ binInventory.binCount }
                                            onChange={ event => {
                                                updateSelectedBinInventory(binInventory, 
                                                    binInventory.binCode, 
                                                    binInventory.binCapacity, 
                                                    event.target.value);
                                            } }
                                        />
                                        {
                                            formikBag.errors.emptyBinCount?.length > 0 &&
                                            <span className="hint hint--rejected">
                                                { formikBag.errors.emptyBinCount[binInventory.position] }
                                            </span>
                                        }
                                        {
                                            formikBag.errors.wrongFormatBinCount?.length > 0 &&
                                            <span className="hint hint--rejected">
                                                { formikBag.errors.wrongFormatBinCount[binInventory.position] }
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
                
            }
            </div>
            <button
                type="submit"
                className="btn btn--primary btn--small is-spaced--top-20"
                onClick={ () => onAddNextBinInventory() }
            >
                <IconAdd/>
                Dodaj kolejną frakcję
            </button>
        </div>
    );
}
export default SelectBinForm;