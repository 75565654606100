import webClient from "../../../core/web_client";
import { API_BASE_AKUN_KU_PATH } from "../../../core/constants";

import { PATH as PATH_CATEGORIES } from "./categories";

const PATH = `${ API_BASE_AKUN_KU_PATH }/subcategories`;
const PATH_ALTERNATIVE = "subcategories";

export const getManySubcategories = () => {
    return webClient
        .get(`${ PATH }`);
};

export const getManySubcategoriesForCategory = (categoryId) => {
    return webClient
        .get(`${ PATH_CATEGORIES }/${ categoryId }/${ PATH_ALTERNATIVE }`);
};

export const addSubcategoryToCategory = (categoryId, data) => {
    return webClient
        .post(`${ PATH_CATEGORIES }/${ categoryId }/${ PATH_ALTERNATIVE }`, data);
};

export const updateSubcategory = (subcategoryId, data) => {
    return webClient
        .put(`${ PATH }/${ subcategoryId }`, data);
};

export const deleteSubcategory = (subcategoryId) => {
    return webClient
        .delete(`${ PATH }/${ subcategoryId }`);
};