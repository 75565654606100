import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getManyAreasPaginated } from "../../../../../../data/actions/waste_management/areas";
import withPagination from "../../../../../common/hoc/with_pagination";
import { generatePaginationQueryValues } from "../../../../../../core/utils/api_utils";
import ListPagination from "../../../../../common/pagination";
import EmptyListPage from "../../../../../common/empty_list_page";
import IconEmptyAreas from "../../../../../../../resource/img/icons/empty_list_icons/brak_obszarow.png";
import AreasListItem from "./item";

const AreasList = ({ searchBarTerm, _onPrevClick, _onNextClick, _onPageNumberInputValueChange, currentPage, setMaxPage }) => {
    const dispatch = useDispatch();
    const areas = useSelector(state => state.entities?.wasteManagement?.areas?.getManyPaginated);

    const totalPages = areas?.data?.totalPages;
    const isAreasEmpty = !areas?.data;

    const _reloadTable = useCallback((searchBarTerm) => {
        dispatch(getManyAreasPaginated({
            ...generatePaginationQueryValues(0, 30, ["createdAt", "desc"]),
            page: currentPage,
            query: searchBarTerm || ''
        }))
            .then(() => {
                if (totalPages !== undefined) {
                    setMaxPage(totalPages);
                }
            });
    }, [totalPages, currentPage, dispatch, setMaxPage]);

    useEffect(() => {
        _reloadTable(searchBarTerm);
        // eslint-disable-next-line
    }, [currentPage, searchBarTerm]);

    const _onDeleteOrUpdateSuccess = () =>{
        _reloadTable(searchBarTerm);
    };

    if (areas?.data?.content?.length === 0) {
        return (
            <EmptyListPage
                img={ IconEmptyAreas }
                imgAlt='Brak obszarów'
                text={ !searchBarTerm ? 'Brak obszarów' : 'Brak pasujących wyników' }
            />
        )
    }

    return (
        <>
            <section className="main-content--table__bottom container is-fluid">
                <div className='table-wrapper'>
                    <table className='table--single-line fixed'>
                        <thead>
                        <tr>
                            <th className='t-width-11'>
                                Nazwa obszaru
                            </th>
                        </tr>
                        </thead>
                        <tbody>

                        {
                            !isAreasEmpty &&
                            areas?.data?.content
                                .map(area => {
                                    return (
                                        <AreasListItem
                                            key={ area.id }
                                            area={ area }
                                            _onDeleteOrUpdateSuccess={_onDeleteOrUpdateSuccess}
                                        />
                                    );
                                })
                        }

                        </tbody>
                    </table>
                </div>

            </section>
            <section className="container is-fluid is-flex">
                <div className="is-flex justify-end pagination--list">
                    {
                        !isAreasEmpty &&
                        <ListPagination
                            data={ areas?.data }
                            _onPrevClick={ _onPrevClick }
                            _onNextClick={ _onNextClick }
                            _onPageNumberInputValueChange={ _onPageNumberInputValueChange }
                        />
                    }
                </div>
            </section>
        </>

    );
};

export default withPagination(AreasList);

