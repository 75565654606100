import { actions } from "../../../../core/constants";
import * as api from "../../../_api";
import { exportFile, extractErrorData, extractResponseData } from "../../../../core/utils/api_utils";

export const importFile = (areaId, file) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__AREAS__IMPORT__STARTED
    });

    return api
        .waste_management
        .areasImport
        .importFile(areaId, file)
        .then(response => {

            dispatch({
                type: actions.wasteManagement.API__AREAS__IMPORT__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });

            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__AREAS__IMPORT__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
            return error.response;
        });
};

export const downloadTemplate = () => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__AREAS__DOWNLOAD_TEMPLATE__STARTED
    });

    return api
        .waste_management
        .areasImport
        .downloadTemplate()
        .then(response => {

            dispatch({
                type: actions.wasteManagement.API__AREAS__DOWNLOAD_TEMPLATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });

            exportFile(response.data, 'szablon_do_importu_obszarów.xlsx')
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__AREAS__DOWNLOAD_TEMPLATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};
