import React, { useEffect } from "react";
import { Form } from "formik";
import FormRequiredLegend from "../../../../../../../common/legend_required";
import CommunalDocumentFile from "./file";

const AddCommunalDocumentFormContent = ({ formikBag, errors }) => {

    useEffect(() => {
        return () => {
            formikBag.resetForm()
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className='c-modal__body modal-size-m'>
            <Form className='form--vertical form--with-legend'>

                <CommunalDocumentFile
                    formikBag={ formikBag }
                    errors={ errors }/>

                <label>
                    <span className='label-name'>
                        <span className="required-accent">*</span>
                            Nazwa pliku
                    </span>
                    <input
                        className={ errors.filename ? "rejected" : "" }
                        type='text'
                        onChange={ (event) => formikBag.setFieldValue("filename", event.target.value) }/>
                    {
                        errors.filename &&
                        <p className="notice--small hint--rejected">
                            { errors.filename }
                        </p>
                    }
                    <p className="notice--small">
                        Wpisz własną nazwę pliku, rozszerzenie pliku zostanie zachowane.<br/>
                        Nazwa pliku musi zawierać informację dla osób niewidzących odnośnie jego zawartości<br/>
                        np.: Wzór deklaracji - lokale niezamieszkałe.
                    </p>
                </label>

                <FormRequiredLegend/>
            </Form>
        </div>
    )
}

export default AddCommunalDocumentFormContent;