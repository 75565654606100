import React, { Component } from "react";
import { Form } from "formik";

import FormRequiredLegend from "../../../../../../../common/legend_required";
import SelectRegulationPlace from "./select_regulation_place";
import PickRegulationDate from "./pick_regulation_date";
import UploadRegulationFile from "./upload_regulation_file";

class SettingsRegulationsAddNewContent extends Component {

    render = () => {
        const { formikBag, regulationPlaces } = this.props;

        return (
            <div className='c-modal__body modal-size-l'>
                <Form className="form--vertical form--with-legend">
                    <SelectRegulationPlace formikBag={ formikBag }
                                           regulationPlaces={ regulationPlaces }
                                           required={ true }/>
                    <UploadRegulationFile formikBag={ formikBag }
                                          required={ true }/>
                    <PickRegulationDate formikBag={ formikBag }
                                        required={ true }/>
                    <FormRequiredLegend/>
                </Form>
            </div>
        );
    };

    componentDidMount = () => {
        const { formikBag } = this.props;

        formikBag.resetForm();
    };
}

export default SettingsRegulationsAddNewContent;
