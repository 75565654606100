import webClient from "../../../../core/web_client";
import { ReportEnum } from "../../../../core/enums/waste_management/reports";
import { API_BASE_AKUN_GOK_PATH } from "../../../../core/constants";
import { buildQueryString } from "../../../../core/utils/api_utils";

export const PATH = `${ API_BASE_AKUN_GOK_PATH }/report`;

export const getManyReports = (queryParams) => {
    return webClient
        .get(`${ PATH }${buildQueryString(queryParams)}`);
};

// side effect: sets report as read
export const getReport = (id) => {
    return webClient
        .get(`${ PATH }/${ id }`);
};

export const updateReportStatus = (id, statusCode) => {
    return webClient
        .put(`${ PATH }/${ id }/status/${ statusCode }`);
};

export const sendStatusChangeConfirmation = (id) => {
    return webClient
        .post(`${ PATH }/${ id }/confirm-status-change`);
};

export const addReport = (type, { localization, longitude, latitude, subject, additionalDate, description, binIds }) => {

    switch (type.value) {

        case ReportEnum.type.UNCOLLECTED_WASTE.code:
            return addUncollectedWasteReport({ localization, description, additionalDate, binIds });

        case ReportEnum.type.WILD_DUMP.code:
            return addWildDumpReport({ description, latitude, longitude });

        case ReportEnum.type.OTHER.code:
            return addOtherReport({ kindCode: "other", localization, subject, description, latitude, longitude });

        case ReportEnum.type.ORDER_CONTAINER.code:
            return addOrderContainerReport({ localization, description });

        case ReportEnum.type.ORDER_TRASHBAG.code:
            return addOrderTrashbagReport({ localization, description, binIds });

        case ReportEnum.type.ORDER_BIN.code:
            return addOrderBinReport({ localization, description, binIds });

        case ReportEnum.type.SMOKE.code:
            return addSmokeReport({ localization, description, additionalDate });

        default:
            // empty
            return Promise.reject(null);
    }
};

export const addUncollectedWasteReport = (data) => {
    return webClient
        .post(`${ PATH }/uncollected-waste`, data);
};

export const addWildDumpReport = (data) => {
    return webClient
        .post(`${ PATH }/wild-dump`, data);
};

export const addOtherReport = (data) => {
    return webClient
        .post(`${ PATH }/other`, data);
};

export const addOrderContainerReport = (data) => {
    return webClient
        .post(`${ PATH }/order-container`, data);
};

export const addOrderTrashbagReport = (data) => {
    return webClient
        .post(`${ PATH }/order-trashbag`, data);
};

export const addOrderBinReport = (data) => {
    return webClient
        .post(`${ PATH }/order-bin`, data);
};

export const addSmokeReport = (data) => {
    return webClient
        .post(`${ PATH }/smoke`, data);
};