import add from "./add";
import get from "./get";
import delete_ from "./delete";
import getMany from "./get_many";

export default (state = {}, action) => {
    return {
        ...state,
        add: add(state.add, action),
        get: get(state.get, action),
        delete_: delete_(state.delete, action),
        getMany: getMany(state.getMany, action)
    };
}