import * as api from "../../_api";

import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const addFile = (file) => dispatch => {
    dispatch({
        type: actions.common.API__FILES__ADD__STARTED,
        meta: {
            filename: file.name
        },
    });

    return api
        .common
        .files
        .addFile(file)
        .then(response => {
            dispatch({
                type: actions.common.API__FILES__ADD__SUCCESS,
                meta: {
                    filename: file.name
                },
                payload: extractResponseData(response),
                error: false
            });

            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__FILES__ADD__FAIL,
                meta: {
                    ...extractErrorData(error),
                    filename: file.name
                },
                payload: null,
                error: true
            });

            return error.response;
        });
};

export const resetFileState = () => dispatch => Promise.resolve().then(() => {
    return dispatch({
            type: actions.common.API__FILES__ADD__RESET
        });
});

export const updateFileAlternativeText = (id, alternativeText) => dispatch => {
    dispatch({
        type: actions.common.API__FILES__UPDATE__STARTED
    });

    return api
        .common
        .files
        .updateFileAlternativeText(id, { alternativeText })
        .then(response => {
            dispatch({
                type: actions.common.API__FILES__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__FILES__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
            return error.response;
        });
};