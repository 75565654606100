import React from "react";

import ReportDetailsStatus from "./status";
import ReportDetailsInfo from "./info";
import Loader from "../../../../../common/loader";
import { isLoading, isLoadingError, isNullOrUndefined } from "../../../../../../core/utils/misc_utils";
import { ReactComponent as IconBack } from "../../../../../../../resource/img/icons/icon_back_blue.svg";
import { EkostraznikReportsEnum } from "../../../../../../core/enums/ekostraznik/reports";

const ReportDetails = ({ employees, statuses, bins, selectedReportId, selectedReport, selectedReportResponses, _onEmployeeChange, _onStatusChange, _onSendResponseClick, _onSendStatusChangeConfirmation, _onClearSelectedReport }) => {

    if (isLoadingError(selectedReport)) {
        return (
            // TODO
            <div>
                Błąd wczytywania zgłoszenia
            </div>
        );
    }

    if (isLoading(selectedReport) || isNullOrUndefined(selectedReport.data)) {
        return (
            <div className="loader-wrapper">
                <Loader/>
            </div>
        );
    }

    return (
        <>
            <header className="report-details__header">
                <div className="unstyled-btn report-details__go-back-link" onClick={ () => _onClearSelectedReport() }>
                    <IconBack/>
                </div>
                <div className="list-item__icon-wrapper reports-icon report-details__icon">
                    <div className="reports-icon list-item__icon">
                        {
                            renderEkoReportTypeIcon(selectedReport)
                        }
                    </div>
                </div>
                <h2 className="report-details__title title is-1">{ EkostraznikReportsEnum.type[selectedReport.data.type].label }</h2>
            </header>
            <div className="report-details__content">

                <ReportDetailsStatus
                    employees={ employees }
                    statuses={ statuses }
                    selectedEmployee={ selectedReport.data.employee }
                    selectedStatus={ selectedReport.data.status }
                    selectedReportId={ selectedReportId }
                    selectedReport={ selectedReport }
                    _onEmployeeChange={ _onEmployeeChange }
                    _onStatusChange={ _onStatusChange }
                    _onSendStatusChangeConfirmation={ _onSendStatusChangeConfirmation }/>

                <ReportDetailsInfo
                    bins={ bins }
                    selectedReport={ selectedReport }/>
            </div>
        </>
    );
};

const renderEkoReportTypeIcon = (selectedReport) => {

    switch (selectedReport.data.type) {
        case EkostraznikReportsEnum.type.MISSING_WASTE_BIN.code:
            return EkostraznikReportsEnum.type.MISSING_WASTE_BIN.icon;

        case EkostraznikReportsEnum.type.DAMAGED_WASTE_BIN.code:
            return EkostraznikReportsEnum.type.DAMAGED_WASTE_BIN.icon;

        case EkostraznikReportsEnum.type.NO_ACCESS_TO_BIN.code:
            return EkostraznikReportsEnum.type.NO_ACCESS_TO_BIN.icon;

        case EkostraznikReportsEnum.type.WASTE_BIN_CAPACITY_EXCEEDED.code:
            return EkostraznikReportsEnum.type.WASTE_BIN_CAPACITY_EXCEEDED.icon;

        case EkostraznikReportsEnum.type.OTHER.code:
            return EkostraznikReportsEnum.type.OTHER.icon;
        default:
            return "";
    }
};

export default ReportDetails;
