import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getAllAddressGroups
} from "../../../../../../data/actions/ekostraznik/address_groups";
import Select from "../../../../../common/select";

const SelectGroupForm = ({ formikBag, errors }) => {
    const dispatch = useDispatch();
    const groups = useSelector(state => state.entities?.ekostraznik?.addressGroups?.getAll);

    useEffect(() => {
        dispatch(getAllAddressGroups())
    }, [dispatch])

    const groupOptions = useMemo(() => {
        if (groups.data?.length) {
           return  groups.data.map(group => ({
               label: group.name,
               value: group.id,
           }));
        }
        return [];
    }, [groups]);
    
    return (
        <div>
            <Select
                value={ formikBag.values.groupId ? undefined : null }
                aria-label='Wybierz z grupy'
                options={ groupOptions }
                isClearable={ true }
                onChange={ option => formikBag.setFieldValue("groupId", option.value) } 
            />
            {
                errors?.address &&
                <span className="hint hint--rejected">
                {
                    errors.address
                }
                </span>
            }
        </div>
    );
}
export default SelectGroupForm;