import React, { Component } from "react";

import FormModal from "../../../../../common/modal/form_modal";
import moment from 'moment';
import { MISC_DATE_DAY_MONTH_YEAR_HOUR_MINUTE_FORMAT, MISC_DATE_ISO_FORMAT } from '../../../../../../core/constants';
import _ from 'lodash';

class AllAddressesInGroupModal extends Component {

    render = () => {
        const { triggerComponent, groupName, reportDate, addresses } = this.props;
        const sortedAddresses = _.sortBy(addresses)

        return (
            <FormModal
                triggerComponent={ triggerComponent }
                closeButton={ true }
                headerText={ "Adresy należące do grupy " + groupName }>

                <div className='subtitle-modal'>

                    <p className="is-bold">Stan adresów na: </p>
                    <p>&nbsp;</p>
                    <p>
                        {
                            moment(reportDate, MISC_DATE_ISO_FORMAT)
                                .format(MISC_DATE_DAY_MONTH_YEAR_HOUR_MINUTE_FORMAT)
                        }
                    </p>

                </div>

                <div className='c-modal__body modal-size-m'>
                    {
                        sortedAddresses.map(address => {
                            return (<div className="address-item" key={ address }> { address } </div>);
                        })
                    }
                </div>

            </FormModal>
        );
    }
};

export default AllAddressesInGroupModal;
