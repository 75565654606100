import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Formik } from "formik";

import withMultiStepForm from "../../../../../common/hoc/with_multistep_form";
import Header from "../../../../../common/header";
import Step1 from "./step-1";
import Step2 from "./step-2";
import Step3 from "./step-3";
import { getManyAreas } from "../../../../../../data/actions/waste_management/areas";
import { addFile, resetFileState } from "../../../../../../data/actions/common/files";
import { addMessage } from "../../../../../../data/actions/waste_management/messages";
import { isEmptyArray, isEmptyObject } from "../../../../../../core/utils/misc_utils";
import { MessageEnum } from "../../../../../../core/enums/waste_management/messages";
import { toastFor400, toastFor500 } from '../../../../../../core/utils/toast_utils';
import { showToast } from '../../../../../../data/actions/common/ui/toast';
import { getOwnerConfiguration } from "../../../../../../data/actions/common/owner";

const initialFormValues = {
    title: "",
    body: "",
    latitude: "",
    longitude: "",
    date: null,
    time: null,
    types: []
};

const initialState = () => {
    return {
        useGeoLocation: false,
        useLocationFilter: false,
        sendImmediately: true,
        uploadedFiles: [],
        sendToAll: true
    };
};

class MessageSending extends Component {

    state = initialState();

    render = () => {
        const initialValues = this._fixDefaultFormValues(initialFormValues);
        const { currentStep } = this.props;

        return (
            <>
                {
                    currentStep !== 3 &&

                    <Header>
                        {
                            currentStep === 1 &&
                            "Wyślij wiadomość"
                        }
                        {
                            currentStep === 2 &&
                            "Sprawdź poprawność i zatwierdź wysyłkę"
                        }
                    </Header>
                }

                <Formik
                    initialValues={ initialValues }
                    onSubmit={ this._onSubmit }
                    render={ formikBag => (
                        <Form className={ `${ currentStep !== 3 ? "has-bottom-action-buttons" : "" }` }>

                            {
                                this.renderAppropriateStep(formikBag)
                            }

                        </Form>
                    ) }/>
            </>
        );
    };

    renderAppropriateStep = (formikBag) => {
        const { defaultCoordinates, currentStep, _onPrevStepClick } = this.props;
        const { useGeoLocation, useLocationFilter, sendImmediately, uploadedFiles } = this.state;

        const { values, setFieldValue, errors } = formikBag;

        switch (currentStep) {
            case 1:
                return (
                    <Step1
                        currentStep={ currentStep }
                        useGeoLocation={ useGeoLocation }
                        useLocationFilter={ useLocationFilter }
                        sendImmediately={ sendImmediately }
                        values={ values }
                        uploadedFiles={ uploadedFiles }
                        defaultCoordinates={ defaultCoordinates }
                        setFieldValue={ setFieldValue }
                        errors={ errors }
                        _useGeoLocationToggle={ this._useGeoLocationToggle }
                        _useLocationFilterChange={ this._useLocationFilterChange }
                        _sendImmediatelyChange={ this._sendImmediatelyChange }
                        _alternativeTextChange={ () => {
                            formikBag.setErrors({})
                        } }
                        _onFileUploadStarted={ this._onFileUploadStarted }
                        _onDeleteUploadedFileClick={ this._onDeleteUploadedFileClick }
                        _onNextClick={ (event) => {
                            this._onNextClick(event, formikBag)
                        } }
                    />
                );

            case 2:
                return (
                    <Step2
                        values={ values }
                        useGeoLocation={ useGeoLocation }
                        useLocationFilter={ useLocationFilter }
                        uploadedFiles={ uploadedFiles }
                        currentStep={ currentStep }
                        onPrevClick={ _onPrevStepClick }
                        _onCancel={ () => this._onCancel(formikBag.resetForm) }/>
                );

            case 3:
                return (
                    <Step3
                        currentStep={ currentStep }
                        _onNewMessageClicked={ () => this._onNewMessageClicked(formikBag.resetForm) }/>
                );

            default:
                return null;
        }

    };

    componentDidMount = () => {
        const { getManyAreas, resetFileState, getOwnerConfiguration } = this.props;
        resetFileState();
        getManyAreas();
        getOwnerConfiguration();
    };

    _fixDefaultFormValues = () => {
        const { defaultCoordinates } = this.props;

        return {
            ...initialFormValues,
            latitude: defaultCoordinates.latitude,
            longitude: defaultCoordinates.longitude
        };
    };

    _onFileUploadStarted = (fileArray) => {
        const { addFile } = this.props;
        const { uploadedFiles } = this.state;

        fileArray.forEach(file =>
            addFile(file)
                .then(uploadedFile => {
                    if (!uploadedFile.error && uploadedFile.data) {

                        const uploadedFileData = uploadedFile.data;

                        if (uploadedFileData && uploadedFiles.indexOf(uploadedFileData) === -1) {

                            const newUploadedFiles = uploadedFiles;
                            newUploadedFiles.push(uploadedFileData);
                            this.setState({
                                uploadedFiles: newUploadedFiles
                            });
                        }
                    }
                })
        );
    };

    _onDeleteUploadedFileClick = (id) => {
        const { uploadedFiles } = this.state;

        if (!id || isEmptyArray(uploadedFiles)) {
            return;
        }

        let i = 0;
        for (; i < uploadedFiles.length; i++) {
            if (uploadedFiles[i].id === id) {
                break;
            }
        }

        const newUploadedFiles = uploadedFiles;
        newUploadedFiles.splice(i, 1);

        this.setState({
            uploadedFiles: newUploadedFiles
        });
    };

    _useGeoLocationToggle = () => {
        this.setState(prevState => ({
            useGeoLocation: !prevState.useGeoLocation
        }));
    };

    _useLocationFilterChange = (value) => {
        this.setState({
            useLocationFilter: value,
            sendToAll: !value
        });
    };

    _sendImmediatelyChange = (value) => {
        this.setState({
            sendImmediately: value
        });
    };

    _onNewMessageClicked = (resetForm) => {
        const { _onFirstStepClick, resetFileState } = this.props;

        return resetFileState()
            .then(() => this.setState(initialState()))
            .then(() => resetForm(this._fixDefaultFormValues(initialFormValues)))
            .then(() => _onFirstStepClick());
    };

    _onCancel = (formikResetForm) => {
        const { _onFirstStepClick } = this.props;
        formikResetForm(initialFormValues);
        _onFirstStepClick();
        this.setState(initialState);
    };

    _onNextClick = (event, formikBag) => {
        const { _onNextStepClick } = this.props;
        const errors = this._validate(formikBag);

        if (!isEmptyObject(errors)) {
            formikBag.setErrors(errors);
            event.preventDefault();
            return;
        }

        return _onNextStepClick();
    }

    _validate = (formikBag) => {
        const { uploadedFiles } = this.state;
        const { values } = formikBag;

        const errors = {};
        const isTitleValid = !!values.title;
        const isBodyValid =
            values.body &&
            !(values.body.search('<p><br data-mce-bogus="1"></p>') >= 0 ||
                values.body.search('<p><br></p>') >= 0);

        if (!isTitleValid) {
            errors.title = "Pole wymagane";
        }

        if (!isBodyValid) {
            errors.body = "Pole wymagane";
        }

        uploadedFiles.forEach(file => {
            if (file.alternativeTextRequired && !file.alternativeText) {
                if (!errors.uploadedFiles) {
                    errors.uploadedFiles = new Map();
                }
                errors.uploadedFiles.set(file.id, "Opis alternatywny wymagany");
            }
        })

        return errors;
    }

    _onSubmit = (formValues) => {
        const { addMessage, _onNextStepClick } = this.props;
        const { useGeoLocation, sendImmediately, uploadedFiles, sendToAll } = this.state;

        const data = {
            title: formValues.title,
            body: formValues.body,
            fileIds: uploadedFiles.map(file => file.id),
            latitude: useGeoLocation ? formValues.latitude : null,
            longitude: useGeoLocation ? formValues.longitude : null,
            requestedRealization: !sendImmediately ? formValues.date + "T" + formValues.time : null,
            types: [MessageEnum.type.CLOUD, MessageEnum.type.EMAIL],
            sendToAll: sendToAll
        };

        return addMessage(data)
            .then(() => {
                const { showToast, messageAdd } = this.props;

                toastFor400(showToast, messageAdd, "Nie udało się wysłać wiadomości. Spróbuj ponownie");
                toastFor500(showToast, messageAdd, "Nie udało się wysłać wiadomości. Spróbuj ponownie");

                if (!messageAdd.meta.loading && !messageAdd.meta.error) {
                    _onNextStepClick()
                }
            });
    };
}

const mapStateToProps = (state) => {
    return {
        uploadedFile: state.entities.common.files.add,
        defaultCoordinates: state.entities.common.owners.getCoordinates,
        messageAdd: state.entities.wasteManagement.messages.add
    };
};

export default withMultiStepForm(
    connect(mapStateToProps, {
        addFile,
        getManyAreas,
        addMessage,
        getOwnerConfiguration,
        resetFileState,
        showToast
    })(MessageSending),
    3
);
