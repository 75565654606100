import messages from "./messages";
import messageAddresses from "./message_addresses";
import addressTemplates from "./address_templates";
import messageTemplates from "./message_template"
import draftMessages from "./draft_messages";
import draftMessageAddresses from "./draft_message_addresses";
import receiverGroups from './receiver_groups';
import senderDetails from './sender_details';

export default (state = {}, action) => {
    return {
        ...state,
        messages: messages(state.messages, action),
        messageAddresses: messageAddresses(state.messageAddresses, action),
        addressTemplates: addressTemplates(state.addressTemplates, action),
        messageTemplates: messageTemplates(state.messageTemplates, action),
        draftMessages: draftMessages(state.draftMessages, action),
        draftMessageAddresses: draftMessageAddresses(state.draftMessageAddresses, action),
        receiverGroups: receiverGroups(state.receiverGroups, action),
        senderDetails: senderDetails(state.senderDetails, action),
    }
}