import React from "react";

import ReportDetailsStatus from "./status";
import ReportDetailsInfo from "./info";
import ReportDetailsResponseHistory from "./history";
import Loader from "../../../../../common/loader";
import { isLoading, isLoadingError, isNullOrUndefined } from "../../../../../../core/utils/misc_utils";
import { ReportEnum } from "../../../../../../core/enums/waste_management/reports";
import { ReactComponent as IconBack } from "../../../../../../../resource/img/icons/icon_back_blue.svg";

const ReportDetails = ({ employees, statuses, bins, selectedReportId, selectedReport, selectedReportResponses, _onEmployeeChange, _onStatusChange, _onSendResponseClick, _onSendStatusChangeConfirmation, _onClearSelectedReport }) => {

    if (isLoadingError(selectedReport)) {
        return (
            // TODO
            <div>
                Błąd wczytywania zgłoszenia
            </div>
        );
    }

    if (isLoading(selectedReport) || isNullOrUndefined(selectedReport.data)) {
        return (
            <div className="loader-wrapper">
                <Loader/>
            </div>
        );
    }

    return (
        <>
            <header className="report-details__header">
                <div className="unstyled-btn report-details__go-back-link" onClick={ () => _onClearSelectedReport() }>
                    <IconBack/>
                </div>
                <div className="list-item__icon-wrapper reports-icon report-details__icon">
                    <div className="reports-icon list-item__icon">
                        {
                            renderReportTypeIcon(selectedReport)
                        }
                    </div>
                </div>
                <h2 className="report-details__title title is-1">{ selectedReport.data.subject }</h2>
            </header>
            <div className="report-details__content">

                <ReportDetailsStatus
                    employees={ employees }
                    statuses={ statuses }
                    selectedEmployee={ selectedReport.data.employee }
                    selectedStatus={ selectedReport.data.status }
                    selectedReportId={ selectedReportId }
                    selectedReport={ selectedReport }
                    statusChangeConfirmed={ selectedReport.data.statusChangeConfirmed }
                    _onEmployeeChange={ _onEmployeeChange }
                    _onStatusChange={ _onStatusChange }
                    _onSendStatusChangeConfirmation={ _onSendStatusChangeConfirmation }/>

                <ReportDetailsInfo
                    bins={ bins }
                    selectedReport={ selectedReport }/>

                <ReportDetailsResponseHistory
                    selectedReportId={ selectedReportId }
                    selectedReportResponses={ selectedReportResponses }
                    _onSendResponseClick={ _onSendResponseClick }/>

            </div>
        </>
    );
};

const renderReportTypeIcon = (selectedReport) => {
    switch (selectedReport.data.kind.code) {
        case ReportEnum.type.UNCOLLECTED_WASTE.code.toLowerCase():
            return ReportEnum.type.UNCOLLECTED_WASTE.icon;

        case ReportEnum.type.WILD_DUMP.code.toLowerCase():
            return ReportEnum.type.WILD_DUMP.icon;

        case ReportEnum.type.OTHER.code.toLowerCase():
            return ReportEnum.type.OTHER.icon;

        case ReportEnum.type.ORDER_CONTAINER.code.toLowerCase():
            return ReportEnum.type.ORDER_CONTAINER.icon;

        case ReportEnum.type.ORDER_TRASHBAG.code.toLowerCase():
            return ReportEnum.type.ORDER_TRASHBAG.icon;

        case ReportEnum.type.ORDER_BIN.code.toLowerCase():
            return ReportEnum.type.ORDER_BIN.icon;

        case ReportEnum.type.SMOKE.code.toLowerCase():
            return ReportEnum.type.SMOKE.icon;

        default:
            return "";
    }
};

export default ReportDetails;
