import React, { Component } from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import _ from "lodash";

import AccountFormBasicInfo from "./basic_info";
import AccountFormPermissionGroup from "./permission_group";
import FormRequiredLegend from "../../../../../common/legend_required";
import { addEmployee, getManyEmployees } from "../../../../../../data/actions/common/employees";
import {
    isEmptyObject,
    isEmptyString,
    isNullOrUndefined,
    isNullOrUndefinedOneOfMany
} from "../../../../../../core/utils/misc_utils";
import { REGEX_EMAIL_SIMPLE } from "../../../../../../core/constants";
import { toastFor500, toastForSuccess } from "../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../data/actions/common/ui/toast";
import InfoBanner from "../../../../../common/info_banner";

const withAccountFields = (OriginalComponent) => {

    class WrappedComponent extends Component {

        render = () => {
            return (
                <OriginalComponent
                    renderAccountFields={ this.renderAccountFields }
                    _onSubmitAdd={ this._onSubmitAdd }
                    { ...this.props }/>
            );
        };

        renderAccountFields = (disabled = false, withInfoBanner = false, editMode = false) => {
            const { modules } = this.props;

            return (
                <>
                    <AccountFormBasicInfo
                        editMode={ editMode }
                        disabled={ disabled }/>

                    <Field
                        name="moduleItems"
                        render={ ({ field, form }) => (
                            <AccountFormPermissionGroup
                                permissionItems={ field.value }
                                modules={ modules }
                                disabled={ disabled }
                                _onPermissionItemClicked={ moduleItem => this._onPermissionItemClicked(moduleItem, field.value, form.setFieldValue) }/>
                        ) }/>

                    {
                        !isNullOrUndefined(withInfoBanner) &&
                        withInfoBanner &&

                        <InfoBanner>
                            <span className="is-bold">Ważne!</span> Przekaż moderatorowi kod aktywacyjny dostępny na liście kont
                        </InfoBanner>
                    }

                    <FormRequiredLegend/>
                </>
            );
        };

        _onPermissionItemClicked = (clickedPermissionItem, permissionItems, setFieldValue) => {
            if (isNullOrUndefinedOneOfMany(clickedPermissionItem, permissionItems, setFieldValue)) {
                return;
            }

            const newPermissionItems = _.cloneDeep(permissionItems);

            let existingItemIndex = -1;
            for (let i = 0; i < permissionItems.length; i++) {
                if (permissionItems[i].itemCode === clickedPermissionItem.itemCode &&
                    permissionItems[i].moduleCode === clickedPermissionItem.moduleCode) {
                    existingItemIndex = i;
                    break;
                }
            }

            if (existingItemIndex === -1) {
                newPermissionItems.push(clickedPermissionItem);
            } else {
                newPermissionItems.splice(existingItemIndex, 1);
            }

            setFieldValue("moduleItems", newPermissionItems);
        };

        _validate = (values) => {
            // TODO error texts
            const errors = {};

            if (isEmptyString(values.name)) {
                errors.name = "Pole wymagane";
            }

            if (isEmptyString(values.surname)) {
                errors.surname = "Pole wymagane";
            }

            if (isEmptyString(values.email)) {
                errors.email = "Pole wymagane";
            }

            if (!REGEX_EMAIL_SIMPLE.test(values.email)) {
                errors.email = "Niepoprawny format adresu email";
            }

            return errors;
        };

        _onSubmitAdd = (formikBag) => {
            const { addEmployee, getManyEmployees, showToast, add } = this.props;

            const errors = this._validate(formikBag.values);
            if (!isEmptyObject(errors)) {
                formikBag.setErrors(errors);

                return Promise.reject(null);
            }

            return addEmployee(formikBag.values)
                .then(() => {
                    toastFor500(showToast, add, "Zaproszenie nie zostało wysłane. Spróbuj ponownie");
                    toastForSuccess(showToast, add, "Zaproszenie zostało wysłane");

                    getManyEmployees();
                });
        };
    }

    const mapStateToProps = (state) => {
        return {
            modules: state.entities.common.owners.getModules,
            add: state.entities.common.employees.add
        };
    };

    return connect(mapStateToProps, { addEmployee, getManyEmployees, showToast })(WrappedComponent);
};

export default withAccountFields;