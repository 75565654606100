import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const getAccount = () => dispatch => {
    dispatch({
        type: actions.common.API__ACCOUNT__GET__STARTED
    });

    return api
        .common
        .account
        .getAccount()
        .then(response => {
            dispatch({
                type: actions.common.API__ACCOUNT__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__ACCOUNT__GET__FAIL,
                meta: extractErrorData(error),
                payload: {},
                error: true
            });
        });
};

export const getAccountActivationStatus = (token) => dispatch => {
    dispatch({
        type: actions.common.API__ACCOUNT__GET_ACTIVATION_STATUS__STARTED
    });

    return api
        .common
        .account
        .getAccountActivationStatus(token)
        .then(response => {
            dispatch({
                type: actions.common.API__ACCOUNT__GET_ACTIVATION_STATUS__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__ACCOUNT__GET_ACTIVATION_STATUS__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const activateAccount = (token, data) => dispatch => {
    dispatch({
        type: actions.common.API__ACCOUNT__ACTIVATE__STARTED
    });

    return api
        .common
        .account
        .activateAccount(token, data)
        .then(response => {
            dispatch({
                type: actions.common.API__ACCOUNT__ACTIVATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__ACCOUNT__ACTIVATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};