import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import Application from "./main/js/core/router";
import { store } from "./main/js/core/store";

import "./main/scss/style.scss";

ReactDOM.render(
    <Provider store={ store }>
        <Application/>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
