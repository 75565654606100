import React from "react";
import HeaderUnderlined from "../../../common/header_underlined";
import Iframe from 'react-iframe';

const Instructions = () => {

    return (
        <div className="container is-fluid">
            <section className="content--s">
                <header className="header--basic">
                    <h1 className="title is-1"> Instrukcje obsługi panelu</h1>
                </header>

                <div className="is-spaced--bottom-10">
                    <HeaderUnderlined text="Zarządzanie obszarami"/>
                </div>
                <div class="iframe-container">
                    <Iframe class="iframe" src="https://player.vimeo.com/video/647609257?h=4b854fefb7" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></Iframe>
                </div>
                
                <div className="is-spaced--bottom-10">
                    <HeaderUnderlined text="Harmonogramy odpadów"/>
                </div>
                <div class="iframe-container">
                    <Iframe class="iframe" src="https://player.vimeo.com/video/647609446?h=ac4557917b" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></Iframe>
                </div>
                
                <div className="is-spaced--bottom-10">
                    <HeaderUnderlined text="Odpady wielkogabarytowe i punkty zbiórek"/>
                </div>
                <div class="iframe-container">
                    <Iframe class="iframe" src="https://player.vimeo.com/video/647609333?h=ce8482de4b" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></Iframe>
                </div>
                
                <div className="is-spaced--bottom-10">
                    <HeaderUnderlined text="Wiadomości"/>
                </div>
                <div class="iframe-container">
                    <Iframe class="iframe" src="https://player.vimeo.com/video/647609496?h=343e86d826" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></Iframe>
                </div>
                
                <div className="is-spaced--bottom-10">
                    <HeaderUnderlined text="Zgłoszenia"/>
                </div>
                <div class="iframe-container">
                    <Iframe class="iframe" src="https://player.vimeo.com/video/647609544?h=357992e119" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></Iframe>
                </div>
                
                <div className="is-spaced--bottom-10">
                    <HeaderUnderlined text="Opłaty i firmy wywozowe"/>
                </div>
                <div class="iframe-container">
                    <Iframe class="iframe" src="https://player.vimeo.com/video/647609588?h=623b34b9fa" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></Iframe>
                </div>
                
                <div className="is-spaced--bottom-10">
                    <HeaderUnderlined text="Dokumenty dla mieszkańców"/>
                </div>
                <div class="iframe-container">
                    <Iframe src="https://player.vimeo.com/video/647609402?h=80b8e60fae" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></Iframe>
                </div>
            </section>
        </div>
    );
};

export default Instructions;
