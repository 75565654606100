import React from "react";
import InspectionDetailsMonthTableItem from "./table-item";

const InspectionDetailsMonth = ({ selectedYear, month, inspections, parentType, parentId }) => {

    return (
        <div className="is-spaced--bottom-40">
            <div className="table-wrapper">
                <table className="table--single-line">
                    <thead>
                    <tr>
                        <th className='t-width-8 is-capitalized'>
                            {
                                month + " " + selectedYear
                            }
                        </th>
                        <th className='t-width-4'>
                            Status kontroli
                        </th>
                    </tr>
                    </thead>
                    <tbody>

                    {
                        inspections.map(inspection => {
                            return <InspectionDetailsMonthTableItem inspection={ inspection } parentType = { parentType } parentId={ parentId } key={ inspection.createdDate }/>
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default InspectionDetailsMonth;