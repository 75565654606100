import React from "react";

import SignedOutHeader from "../../header";
import GoBackLink from "../../go_back_link";

const PasswordResetRequestConfirmation = ({ location }) => {

    return (
        <>
            <SignedOutHeader signedOutHeaderText="Zmiana hasła"/>
            <div className="form--signed-out">
                <p>
                    Na adres <span className="is-bold">{ location.state.emailTarget }</span> została wysłana wiadomość z linkiem. <br/>
                    Jeżeli wiadomość nie została dostarczona, sprawdź poprawność podanego adresu.
                </p>
                <br/>
                <p>
                    PAMIĘTAJ link jest ważny 3 godziny.
                </p>
            </div>
            <GoBackLink/>
        </>
    );
};

export default PasswordResetRequestConfirmation;
