import { API_BASE_AKUN_BLISKO_PATH } from "../../../core/constants";
import webClient from "../../../core/web_client";
import { buildQueryString } from "../../../core/utils/api_utils";

const PATH = `${ API_BASE_AKUN_BLISKO_PATH }/message-templates`;

export const getManyPaginated = (queryParams) => {
    return webClient
        .get(`${ PATH }${ buildQueryString(queryParams) }`);
};

export const get = (id) => {
    return webClient
        .get(`${ PATH }/${ id }`);
};

export const addMessageTemplate = (data) => {
    return webClient
        .post(PATH, data);
};

export const updateMessageTemplate = (id, data) => {
    return webClient
        .put(`${ PATH }/${ id }`, data);
};

export const deleteMany = (data) => {
    return webClient
        .delete(PATH, { data });
};

export const deleteMessageTemplate = (templateId) => {
    return webClient
        .delete(`${ PATH }/${ templateId }`);
};