import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { ROUTE_SIGN_IN } from "../../core/constants";
import { ReactComponent as IconArrowLeft } from "../../../resource/img/icons/icon_arrow_left.svg";


const GoBackLink = () => {

    return (
        <Fragment>
            <Link to={ ROUTE_SIGN_IN } className="go-back--signed-out">
                <IconArrowLeft/> Wróć do logowania
            </Link>
        </Fragment>
    );
};

export default GoBackLink;
