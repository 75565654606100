import React from "react";
import { connect } from "react-redux";

import HeaderUnderlined from "../../../../../common/header_underlined";
import SubcategoryCreateOrEditModalForm from "./create_or_edit_modal_form";
import SubcategoryDeleteModalForm from "./delete_subcategory_modal_form";
import SubcategoryListItem from "./list_item.js";
import SubcategoryEmptyList from "./empty_list.js";
import { ReactComponent as IconEdit } from "../../../../../../../resource/img/icons/icon_edit.svg";
import { ReactComponent as IconDelete } from "../../../../../../../resource/img/icons/icon_delete.svg";
import { isEmptyArray, isNullOrUndefined } from "../../../../../../core/utils/misc_utils";
import { deleteSubcategory } from "../../../../../../data/actions/services/subcategories";

const Subcategory = ({ subcategory, serviceCards, deleteSubcategory, _refreshData, _onCreateOrEditSubcategorySubmit }) => {

    return (
        <div className="subcategory">
            <HeaderUnderlined text={ subcategory.name }>
                <span className="action-btn">
                    <SubcategoryCreateOrEditModalForm
                        headerText="Edytuj podkategorię"
                        triggerComponent={
                            <button className="unstyled-btn"><IconEdit/></button>
                        }
                        subcategory={ subcategory }
                        _onSubmit={ _onCreateOrEditSubcategorySubmit }/>
                </span>
                <span className="action-btn">
                    <SubcategoryDeleteModalForm
                        _actionFunction={
                            () => deleteSubcategory(subcategory.id)
                                .then(() => _refreshData()) }
                        triggerComponent={
                            <button className="unstyled-btn">
                                <IconDelete/>
                            </button>
                        }/>
                </span>
            </HeaderUnderlined>

            {
                !isNullOrUndefined(serviceCards) && !isEmptyArray(serviceCards)
                    ?
                    <div className="table-wrapper table-wrapper--flexible">
                        <table className="table--single-line fixed table--flexible">
                            <thead>
                            <tr>
                                <th className="data--flexible">
                                    Tytuł karty
                                </th>
                                <th className="data--fixed subcategory-list-item-status">
                                    Status
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                serviceCards.map(serviceCard =>
                                    <SubcategoryListItem
                                        key={ serviceCard.id }
                                        serviceCard={ serviceCard }
                                        _refreshData={ _refreshData }/>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                    :
                    <SubcategoryEmptyList/>
            }

        </div>
    );
};

export default connect(null, { deleteSubcategory })(Subcategory);