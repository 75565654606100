import webClient from "../../../core/web_client";
import { buildQueryString } from "../../../core/utils/api_utils";
import { API_BASE_AKUN_BLISKO_PATH } from "../../../core/constants";

const PATH = `${ API_BASE_AKUN_BLISKO_PATH }/messages`;

export const get = (id) => {
    return webClient
        .get(`${ PATH }/${ id }`);
};

export const getMany = (queryData) => {
    return webClient
        .get(`${ PATH }${ buildQueryString(queryData) }`);
};

export const getMessageTemplates = () => {
    return webClient
        .get(`${ PATH }/message-template`);
};

export const addMessage = (data) => {
    return webClient
        .post(`${ PATH }`, data);
};

export const cancelMessage = (id) => {
    return webClient
        .delete(`${ PATH }/${ id }`);
};
