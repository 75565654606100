import webClient from "../../../core/web_client";
import { API_BASE_AKUN_EKOSTRAZNIK_PATH } from "../../../core/constants";

const PATH = `${ API_BASE_AKUN_EKOSTRAZNIK_PATH }/inspection-count-threshold`;

export const getInspectionCountThreshold = () => {
    return webClient
        .get(`${ PATH }`);
};

export const updateInspectionCountThreshold = (data) => {
    return webClient
        .put(`${ PATH }`, data);
};