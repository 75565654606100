import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Form, Formik } from "formik";
import _ from "lodash";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

import Header from "../../../../common/header";
import ServicesCardBasicInfo from "./basic_info";
import ServicesCardContent from "./card_content";
import ServicesCardLegalFoundation from "./legal_foundation";
import ServicesCardAdditionalInfo from "./additional_info";
import ServicesCardResponsibleEntity from "./responsible_entity";
import ServicesCardFiles from "./files";
import FormRequiredLegend from "../../../../common/legend_required";
import Loader from "../../../../common/loader";
import ServiceCardsEnum from "../../../../../core/enums/services/service_cards";
import { stateFromHTMLOptions, stateToHTMLOptions } from "../../../../../core/utils/draft_utils";
import { BottomCustomButtons } from "../../../../common/bottom_action_buttons";
import {
    addIssueHandleServiceCard,
    addServiceServiceCard,
    getServiceCard,
    updateIssueHandleServiceCard,
    updateServiceServiceCard
} from "../../../../../data/actions/services/service_cards";
import {
    deepFreeze,
    isEmptyArray,
    isEmptyObject,
    isEmptyString,
    isHtmlStringWithoutText,
    isLoading,
    isLoadingError,
    isNullOrUndefined
} from "../../../../../core/utils/misc_utils";
import { ROUTE_SERVICES, ROUTE_SERVICES_CATEGORY_DETAILS } from "../../../../../core/constants";
import { showToast } from "../../../../../data/actions/common/ui/toast";
import { toastForCustomError } from "../../../../../core/utils/toast_utils";
import SourceAndLicense from "./source_and_license";

const initialFormValues = deepFreeze({
    title: "",
    description: "",
    externalUri: {
        uri: "",
        alternativeText: ""
    },
    keywords: [],
    assignedSubcategoryIds: [],
    relatedServiceCardIds: [],
    fileIds: [],
    legalFoundations: {
        0: { value: "", no: 0 }
    },
    responsibleEntityId: null,
    type: ServiceCardsEnum.type.ISSUE_HANDLE,
    kind: ServiceCardsEnum.kind.EXTENDED
});

const defaultSections = deepFreeze({
    0: { header: "Co przygotować", body: EditorState.createEmpty(), no: 0 },
    1: { header: "Co zrobić", body: EditorState.createEmpty(), no: 1 },
    2: { header: "Ile będziesz czekać", body: EditorState.createEmpty(), no: 2 },
    3: { header: "Ile zapłacisz", body: EditorState.createEmpty(), no: 3 },
    4: { header: "Kto może odebrać", body: EditorState.createEmpty(), no: 4 }
});

const defaultState = deepFreeze({
    cardType: ServiceCardsEnum.type.ISSUE_HANDLE,
    cardKind: ServiceCardsEnum.kind.EXTENDED,
    sections: defaultSections,
    content:  EditorState.createEmpty(),
    redirectTo: null,
    submitError: false,
    isNewCard: true,
    sourceAndLicenseEditorState: EditorState.createEmpty()
});

class ServicesCard extends Component {

    state = _.cloneDeep(defaultState);

    render = () => {
        const { cardType, cardKind, sections, redirectTo, submitError, isNewCard, sourceAndLicenseEditorState, content } = this.state;
        const { serviceCard, match } = this.props;
        const { serviceCardId } = match.params;

        if (!isNullOrUndefined(redirectTo)) {
            return (
                <Redirect to={ redirectTo }/>
            );
        }

        if (!isNullOrUndefined(serviceCardId) && isLoadingError(serviceCard)) {
            // TODO
            return (
                <div>Błąd wczytywania danych</div>
            );
        }

        if (!isNullOrUndefined(serviceCardId) && isLoading(serviceCard)) {
            return (
                <Loader/>
            );
        }

        const initialValues =
            !isNullOrUndefined(serviceCardId) && !isNullOrUndefined(serviceCard) && !isEmptyObject(serviceCard.data)
                ? this.prepareServiceCardData()
                : _.cloneDeep(initialFormValues);

        const stepsCount = cardType === ServiceCardsEnum.type.ISSUE_HANDLE ? "7" : "6";
        let stepCounter = 0;

        return (
            <Formik
                initialValues={ initialValues }
                render={ formikBag => {
                    return (
                        <div>
                            <Header>
                                Dodaj kartę
                            </Header>
                            <main>

                                <Form className=" service-card has-bottom-action-buttons">
                                    <div className="content--m content--not-centered is-relative">
                                        <FormRequiredLegend/>

                                        <ServicesCardBasicInfo
                                            step={ `${ stepCounter = 1 }/${ stepsCount }` }
                                            cardType={ cardType }
                                            errors={ formikBag.errors }
                                            _onCardTypeClicked={ (event, nextCardType) => {
                                                event.stopPropagation();
                                                formikBag.setFieldValue('type', nextCardType);
                                                this._onCardTypeClicked(nextCardType);
                                            } }/>

                                        <ServicesCardContent
                                            step={ `${ ++stepCounter }/${ stepsCount }` }
                                            cardKind={ cardKind }
                                            errors={ formikBag.errors }
                                            sections={ sections }
                                            content={ content }
                                            _setContentValue={nextValue => {
                                                this.setState({
                                                    content: nextValue
                                                });
                                            }}
                                            _setSectionValue={ nextValue => {
                                                const tempSections = sections;
                                                tempSections[nextValue.no] = nextValue;

                                                this.setState({
                                                    sections: tempSections
                                                });
                                            } }
                                            _setSectionsValue={ nextSections =>
                                                this.setState({
                                                    sections: nextSections
                                                }) }

                                            _onCardKindClicked={ (event, nextCardKind) => {
                                                event.stopPropagation();
                                                formikBag.setFieldValue('kind', nextCardKind);
                                                this._onCardKindClicked(nextCardKind);
                                            } }
                                        />

                                        {
                                            cardType === ServiceCardsEnum.type.ISSUE_HANDLE &&

                                            <ServicesCardLegalFoundation
                                                step={ `${ ++stepCounter }/${ stepsCount }` }
                                                cardType={ cardType }
                                                legalFoundations={ formikBag.values.legalFoundations }
                                                _setLegalFoundationsValue={ legalFoundations =>
                                                    formikBag.setFieldValue("legalFoundations", legalFoundations)
                                                }/>
                                        }

                                        <SourceAndLicense
                                            step={ `${ ++stepCounter }/${ stepsCount }` }
                                            sourceAndLicenseEditorState={sourceAndLicenseEditorState}
                                            _setSourceAndLicenseEditorState={(__sourceAndLicenseEditorState) => this.setState({
                                                sourceAndLicenseEditorState: __sourceAndLicenseEditorState
                                            })}
                                        />

                                        <ServicesCardFiles
                                            step={ `${ ++stepCounter }/${ stepsCount }` }
                                            fileIds={ formikBag.values.fileIds }
                                            loadedAttachments={
                                                !isNullOrUndefined(serviceCard) && !isNullOrUndefined(serviceCard.data) && serviceCardId === serviceCard.data.id
                                                    ? serviceCard.data.attachments
                                                    : []
                                            }
                                            _setFormikFieldValue={ formikBag.setFieldValue }/>

                                        <ServicesCardAdditionalInfo
                                            step={ `${ ++stepCounter }/${ stepsCount }` }
                                            cardType={ cardType }
                                            errors={ formikBag.errors }/>

                                        <ServicesCardResponsibleEntity
                                            step={ `${ ++stepCounter }/${ stepsCount }` }
                                            responsibleEntityId={ formikBag.values.responsibleEntityId }
                                            _setFormikFieldValue={ formikBag.setFieldValue }/>
                                    </div>

                                    <BottomCustomButtons containerClass="content--m content--not-centered"
                                                         buttonSpacingClass="space-between">

                                        <button type="button"
                                                className="btn btn--outlined btn--medium"
                                                onClick={ this._onCloseClicked }>
                                            Zamknij
                                        </button>

                                        <div className="notifications-2__btn-footer-group">

                                            {
                                                isNewCard &&
                                                <button className="btn"
                                                        type="button"
                                                        disabled={ submitError }
                                                        onClick={ () => {
                                                            return this._onSaveAsDraftClicked(formikBag);
                                                        } }>
                                                    Zapisz wersję roboczą
                                                </button>
                                            }
                                            {
                                                !isNewCard &&
                                                <button className="btn"
                                                        type="button"
                                                        disabled={ submitError }
                                                        onClick={ () => {
                                                            return this._onSaveAndHideClicked(formikBag);
                                                        } }>
                                                    Zapisz i ukryj
                                                </button>
                                            }

                                            <button className="btn btn--primary btn--medium"
                                                    type="button"
                                                    disabled={ submitError }
                                                    onClick={ () => {
                                                        return this._onSaveAsPublishedClicked(formikBag);
                                                    } }>
                                                Opublikuj
                                            </button>
                                        </div>

                                    </BottomCustomButtons>
                                </Form>
                            </main>
                        </div>
                    );
                } }/>
        );
    };

    componentDidMount = () => {
        const { getServiceCard, match } = this.props;
        const { serviceCardId } = match.params;

        if (isEmptyString(serviceCardId)) {
            return;
        }

        getServiceCard(serviceCardId)
            .then(() => {
                const { serviceCard } = this.props;

                this.setState({
                    sourceAndLicenseEditorState: EditorState.createWithContent(stateFromHTML(serviceCard.data.source, stateFromHTMLOptions))
                });

                if (serviceCard.data.status !== ServiceCardsEnum.status.DRAFT) {
                    this.setState({
                        isNewCard: false
                    });
                }

                if (serviceCard.data.kind === ServiceCardsEnum.kind.SIMPLE) {
                    const editorState = EditorState.createWithContent(
                        stateFromHTML(serviceCard.data.content || '', stateFromHTMLOptions)
                    );

                    this.setState({
                        cardKind: serviceCard.data.kind,
                        cardType: serviceCard.data.type,
                        // sections: {
                        //     0: { header: "", body: body, no: 0 }
                        // },
                        content: editorState
                    });

                } else if (serviceCard.data.kind === ServiceCardsEnum.kind.EXTENDED) {
                    this.setState({
                        cardKind: serviceCard.data.kind,
                        cardType: serviceCard.data.type,
                        sections:
                            !isNullOrUndefined(serviceCard.data.sections)
                                ? _.mapKeys(serviceCard.data.sections.map(section => {
                                    const state = stateFromHTML(section.body, stateFromHTMLOptions);
                                    return { ...section, body: EditorState.createWithContent(state) };
                                }), "no")
                                : {},
                    });
                }
            });
    };

    prepareServiceCardData = () => {
        const { serviceCard } = this.props;

        return {
            ...serviceCard.data,
            legalFoundations:
                !isNullOrUndefined(serviceCard.data.legalFoundations)
                    ? serviceCard.data.legalFoundations.reduce((accumulator, current) => {
                        const nextNo = Object.keys(accumulator).length;
                        accumulator[nextNo] = { value: current, no: nextNo };
                        return accumulator;
                    }, {})
                    : {},
            fileIds: !isNullOrUndefined(serviceCard.data.attachments)
                ? serviceCard.data.attachments.map(attachment => attachment.id)
                : []
        };
    };

    _handleSubmitErrorReset = () => {
        this.setState({
            submitError: false
        });
    };

    _onCardTypeClicked = (nextCardType) => {
        const { cardType } = this.state;

        if (cardType !== nextCardType) {

            this.setState({
                cardType: nextCardType
            });
        }
    };

    _onCardKindClicked = (nextCardKind) => {
        const { cardKind } = this.state;

        if (cardKind !== nextCardKind) {

            this.setState({
                cardKind: nextCardKind
            });
        }
    };

    _onCloseClicked = () => {
        const { category } = this.props;

        if (isNullOrUndefined(category) || isNullOrUndefined(category.data) || isNullOrUndefined(category.data.id)) {
            this.setState({
                redirectTo: ROUTE_SERVICES
            });
        } else {
            this.setState({
                redirectTo: ROUTE_SERVICES_CATEGORY_DETAILS(category.data.id)
            });
        }

        return Promise.resolve();
    };

    _validate = (data, status) => {
        let errors = {};

        if (status === ServiceCardsEnum.status.DRAFT) {
            if (isEmptyString(data.title) || isEmptyArray(data.assignedSubcategoryIds)) {
                errors.basicInfo = " Pola \"Tytuł karty\" oraz \"Gdzie chcesz umieścić kartę?\" muszą być uzupełnione.";
            }
        }
        else if (status === ServiceCardsEnum.status.PUBLISHED) {
            if (isEmptyString(data.title) || isEmptyString(data.description) || isEmptyArray(data.assignedSubcategoryIds)) {
                errors.basicInfo = "Wszystkie pola sekcji “Informacje podstawowe” muszą być uzupełnione.";
            }

            if (this.state.cardKind === ServiceCardsEnum.kind.SIMPLE && isHtmlStringWithoutText(data.content)){
                errors.cardContent = "Treść musi być wypełniona";
            }

            if (this.state.cardKind === ServiceCardsEnum.kind.EXTENDED &&
                data.sections.reduce((isEmpty, section) => {
                    return isEmpty &&
                        (isEmptyString(section.header.trim()) || isHtmlStringWithoutText(section.body))
                }, true)
            ){
                errors.cardContent = "Karta musi zawierać co najmniej jeden nagłówek z uzupełnioną treścią.";
            }

            if (this.state.cardType === ServiceCardsEnum.type.SERVICE) {
                if (isEmptyString(data.externalUri.uri)) {
                    errors.externalUri = "Link musi być uzupełniony";
                }
            }
        }

        return errors;
    };

    _onSaveAndHideClicked = (formikBag) => {
        const status = ServiceCardsEnum.status.HIDDEN;
        const data = this.prepareSubmitData(formikBag.values, status);

        return this._onSubmit(data, formikBag, status);
    };

    _onSaveAsDraftClicked = (formikBag) => {
        const status = ServiceCardsEnum.status.DRAFT;
        const data = this.prepareSubmitData(formikBag.values, status);

        return this._onSubmit(data, formikBag, status);
    };

    _onSaveAsPublishedClicked = (formikBag) => {
        const status = ServiceCardsEnum.status.PUBLISHED;
        const data = this.prepareSubmitData(formikBag.values, status);
        return this._onSubmit(data, formikBag, status);
    };

    _onSubmit = (data, formikBag, status) => {
        const { cardType } = this.state;
        const { addIssueHandleServiceCard, addServiceServiceCard, updateIssueHandleServiceCard, updateServiceServiceCard, match, showToast } = this.props;
        const { serviceCardId } = match.params;

        const errors = this._validate(data, status);

        if (!isEmptyObject(errors)) {
            formikBag.setErrors(errors);
            toastForCustomError(showToast, "Błędnie wypełniony formularz");

            return Promise.reject(null);
        }

        if (isEmptyString(serviceCardId)) {
            // adding
            if (cardType === ServiceCardsEnum.type.SERVICE) {
                return addServiceServiceCard(data)
                    .then(() => this._handleErrorResponse(this.props.serviceServiceCardAdd))
                    .then(() => this._redirectAfterSubmit(data));
            } else if (cardType === ServiceCardsEnum.type.ISSUE_HANDLE) {
                return addIssueHandleServiceCard(data)
                    .then(() => this._handleErrorResponse(this.props.issueHandleServiceCardAdd))
                    .then(() => this._redirectAfterSubmit(data));
            }
        }
        // updating
        else {
            if (cardType === ServiceCardsEnum.type.SERVICE) {
                return updateServiceServiceCard(serviceCardId, data)
                    .then(() => this._handleErrorResponse(this.props.serviceServiceCardUpdate))
                    .then(() => this._redirectAfterSubmit(data));
            } else if (cardType === ServiceCardsEnum.type.ISSUE_HANDLE) {
                return updateIssueHandleServiceCard(serviceCardId, data)
                    .then(() => this._handleErrorResponse(this.props.issueHandleServiceCardUpdate))
                    .then(() => this._redirectAfterSubmit(data));
            }
        }
    };

    _handleErrorResponse = (entityData) => {
        if (isLoadingError(entityData)) {
            this.setState({
                submitError: true
            });
            return Promise.reject(null);
        }
        return Promise.resolve();
    };

    _redirectAfterSubmit = (data) => {
        const { subcategories } = this.props;
        return new Promise(resolve => {
            if (!isLoadingError(subcategories) || !isNullOrUndefined(subcategories.data)) {
                const randomSubcategory =
                    !isEmptyArray(data.assignedSubcategoryIds)
                        ? subcategories.data[data.assignedSubcategoryIds[0]]
                        : null;

                const randomCategoryId =
                    !isNullOrUndefined(randomSubcategory)
                        ? randomSubcategory.category_id
                        : null;

                if (!isEmptyString(randomCategoryId)) {
                    this.setState({
                        redirectTo: ROUTE_SERVICES_CATEGORY_DETAILS(randomCategoryId),
                        submitError: false
                    });

                    return resolve();
                }
            }

            this.setState({
                redirectTo: ROUTE_SERVICES,
                submitError: false
            });

            return resolve();
        });
    };

    prepareSubmitData = (values, status) => {
        const { cardType, cardKind, sections, sourceAndLicenseEditorState, content } = this.state;

        const transformedSections = _
            .map(_.cloneDeep(sections), section => {
                section.body = stateToHTML(section.body.getCurrentContent(), stateToHTMLOptions);
                return section;
            });

        const transformedContent = content ? stateToHTML(content.getCurrentContent(), stateToHTMLOptions) : null;

        return {
            ...values,
            content:
                cardKind === ServiceCardsEnum.kind.SIMPLE
                    ? transformedContent
                    : undefined,
            sections:
                cardKind === ServiceCardsEnum.kind.EXTENDED
                    ? transformedSections
                    : undefined,
            legalFoundations:
                cardType === ServiceCardsEnum.type.ISSUE_HANDLE
                    ? _.map(values.legalFoundations, legalFoundation => legalFoundation.value)
                       .filter(legalFoundationValue => !isEmptyString(legalFoundationValue.trim()))
                    : undefined,
            attachments: undefined,
            id: undefined,
            status: status,
            source: this._normalizeEditorStateIfEmpty(stateToHTML(sourceAndLicenseEditorState.getCurrentContent(), stateToHTMLOptions))
        };
    };

    _normalizeEditorStateIfEmpty = (editorStateContent) => {
        const div = document.createElement('div');
        div.innerHTML = editorStateContent;
        return div.innerText.trim() ? editorStateContent : '';
    }
}

const mapStateToProps = (state) => {
    return {
        serviceCard: state.entities.services.serviceCards.get,
        subcategories: state.entities.services.subcategories.getMany,
        category: state.entities.services.categories.get,
        serviceServiceCardAdd: state.entities.services.serviceCards.addService,
        serviceServiceCardUpdate: state.entities.services.serviceCards.updateService,
        issueHandleServiceCardAdd: state.entities.services.serviceCards.addIssueHandle,
        issueHandleServiceCardUpdate: state.entities.services.serviceCards.updateIssueHandle
    };
};

export default connect(mapStateToProps, { addIssueHandleServiceCard, addServiceServiceCard, getServiceCard, updateIssueHandleServiceCard, updateServiceServiceCard, showToast })(ServicesCard);
