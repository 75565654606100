import getMany from "./get_many";
import get from "./get";
import add from "./add";
import update from "./update";
import hide from "./hide";
import delete_ from "./delete";

export default (state = {}, action) => {
    return {
        ...state,
        getMany: getMany(state.getMany, action),
        get: get(state.get, action),
        add: add(state.add, action),
        update: update(state.update, action),
        hide: hide(state.hide, action),
        delete_: delete_(state.delete_, action)
    };
}
