import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";
import { mapModuleKeys } from "../../../core/utils/permission_utils";

export const getOwnerConfiguration = () => dispatch => {
    dispatch({
        type: actions.common.API__OWNER_CONFIGURATION__GET__STARTED
    });

    return api
        .common
        .owner
        .getConfiguration()
        .then(response => {
            dispatch({
                type: actions.common.API__OWNER_CONFIGURATION__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });

            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__OWNER_CONFIGURATION__GET__FAIL,
                meta: extractErrorData(error),
                payload: {},
                error: true
            });
        });
};

export const getOwnerCoordinates = () => dispatch => {
    dispatch({
        type: actions.common.API__OWNER_COORDINATES__GET__STARTED
    });

    return api
        .common
        .owner
        .getOwnerCoordinates()
        .then(response => {
            dispatch({
                type: actions.common.API__OWNER_COORDINATES__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__OWNER_COORDINATES__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getOwnerModules = () => dispatch => {
    dispatch({
        type: actions.common.API__MODULES__GET__STARTED
    });

    return api
        .common
        .owner
        .getOwnerModules()
        .then(response => {
            dispatch({
                type: actions.common.API__MODULES__GET__SUCCESS,
                meta: {},
                payload: mapModuleKeys(extractResponseData(response)),
                error: false
            });

            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__MODULES__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};