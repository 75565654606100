import React, { Component } from "react";
import { Field } from "formik";

import ServicesCardAdditionalInfoRelatedServicesContent from "./content";

class ServicesCardAdditionalInfoRelatedServices extends Component {

    state = {
        options: [],
        value: []
    };

    render = () => {
        const { serviceCards } = this.props;
        const { options, value } = this.state;

        return (
            <Field
                name="relatedServiceCardIds"
                render={ ({ field, form }) => (
                    <ServicesCardAdditionalInfoRelatedServicesContent
                        field={ field }
                        form={ form }
                        options={ options }
                        value={ value }
                        serviceCard={ serviceCards }
                        _setValue={ value => this.setState({ value })}
                        _setOptionsAndValue={ (options, value) => this.setState({ options, value })} />
                ) }/>
        );
    };
}

export default ServicesCardAdditionalInfoRelatedServices;