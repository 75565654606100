import webClient from "../../../core/web_client";
import { API_BASE_AKUN_KU_PATH } from "../../../core/constants";

export const PATH = `${ API_BASE_AKUN_KU_PATH }/categories`;

export const getManyCategories = () => {
    return webClient
        .get(`${ PATH }`);
};

export const getCategory = (categoryId) => {
    return webClient
        .get(`${ PATH }/${ categoryId }`)
};