import React from "react";
import { isNullOrUndefined } from "../../../../../../core/utils/misc_utils";

const ServicesCardBasicInfoCardTypeRadio = ({ label, cardType, selectedCardType, _onCardTypeClicked }) => {
    const isChecked = !isNullOrUndefined(cardType) && cardType === selectedCardType;
    return (
        <label
            onClick={ event => {
                event.stopPropagation();
                _onCardTypeClicked(event, cardType);
            } }
            className={ `choice-label choice-label--radio ${ isChecked ? "checked--radio" : "" }` }
        >
            <input className="choice--radio" type="radio"/> { label }
        </label>
    );
};

export default ServicesCardBasicInfoCardTypeRadio;
