import React from "react";

import { isNullOrUndefined } from "../../../../../../../../core/utils/misc_utils";
import CustomBinDeleteModalForm from "./delete_form";
import BinsIcon from "./icon";

const MunicipalWasteMenuBinsItem = ({ bin, selectedBin, _onBinSelect, _onDeleteCustomBinTypeClick }) => {
    if (isNullOrUndefined(bin)) {
        return null;
    }

    return (
        <li tabIndex="0" className={ `tabs-item ${ bin.id === selectedBin ? "is-active" : "" } ${ bin.type === null ? "tab-item--removable" : "" } ` }
            onClick={ () => _onBinSelect(bin.id) }>
            <span className="tab-item tab-item--with-icon" role="button">
                <BinsIcon color={ bin.color }/>

                {
                    bin.name
                }

                {
                    bin.type === null &&
                    <CustomBinDeleteModalForm
                        bin={ bin }
                        _onDeleteCustomBinTypeClick={ _onDeleteCustomBinTypeClick }/>
                }
             </span>
        </li>
    );
};

export default MunicipalWasteMenuBinsItem;