import { Link } from "react-router-dom";
import React from "react";

const ModuleCategoryTile = ({ to, onClick, name, description, cssSubclass }) => {
    return (
      <div className='column is-half-tablet is-one-third-desktop is-one-quarter-fullhd'>
          <Link to={ to }
                onClick={ onClick }
                className={`module__category module__category-bg module__category--${cssSubclass}`}>
              <div className="module__category-desc">
                  <h2 className="title is-2 is-spaced">
                      { name }
                  </h2>
                  <p className="subtitle is-3">
                      { description }
                  </p>
              </div>
              <span className="icon-bg"/>
          </Link>
      </div>
    );
}

export default ModuleCategoryTile;
