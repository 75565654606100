import React, { Component } from "react";
import { createPortal } from "react-dom";
import FocusTrap from "focus-trap-react";
import { isNullOrUndefined } from "../../../core/utils/misc_utils";
import { ReactComponent as IconCancel } from "../../../../resource/img/icons/icon_cancel.svg";

import { MISC_CLASS_SCROLL_LOCK, MISC_KEYCODE_ESC } from "../../../core/constants";

class Modal extends Component {

    state = {
        isOpen: undefined
    };

    render = () => {
        const { children, triggerText, triggerClass, triggerComponent, triggerDisabled, triggerWrapperClass, ariaLabel,
            role, noTrigger, defaultOpen, headerText, withBgHeader, _cancelFunction, _onClickAway } = this.props;
        const isOpen = this.state.isOpen ?? defaultOpen ?? false;

        return (
            <>
                {
                    !noTrigger &&
                    <ModalTrigger
                        _onOpen={ this._onOpen }
                        buttonRef={ n => this.openButtonNode = n }
                        triggerText={ triggerText }
                        triggerClass={ triggerClass }
                        triggerWrapperClass={ triggerWrapperClass }
                        triggerComponent={ triggerComponent }
                        triggerDisabled={ !isNullOrUndefined(triggerDisabled) ? triggerDisabled : false }/>
                }

                {
                    isOpen &&
                    <ModalContent
                        _onClose={ this._onClose }
                        _onKeyDown={ this._onKeyDown }
                        _onClickAway={ _onClickAway }
                        content={ children }
                        modalRef={ n => this.modalNode = n }
                        buttonRef={ n => this.closeButtonNode = n }
                        role={ role }
                        ariaLabel={ ariaLabel }
                        headerText={ headerText }
                        withBgHeader={ withBgHeader }
                        _cancelFunction={ _cancelFunction }
                    />
                }

            </>
        );
    };

    _onOpen = () => {
        this.setState({ isOpen: true }, () => {
            if (!isNullOrUndefined(this.closeButtonNode)) {
                this.closeButtonNode.focus();
            }
        });

        this._toggleScrollLock(true);
    };

    _onClose = () => {
        this._toggleScrollLock(false);
        this.setState({ isOpen: false });
        if (this.openButtonNode) {
            this.openButtonNode.focus();
        }
    };

    _onKeyDown = (event) => {
        return event.keyCode === MISC_KEYCODE_ESC && this._onClose();
    };

    _toggleScrollLock = (forcedValue) => {
        const htmlElement = document.querySelector("html");

        if (isNullOrUndefined(forcedValue)) {
            document.querySelector("html")
                    .classList
                    .toggle(MISC_CLASS_SCROLL_LOCK);
        } else {
            if (forcedValue) {
                htmlElement
                    .classList
                    .add(MISC_CLASS_SCROLL_LOCK);

            } else {
                htmlElement
                    .classList
                    .remove(MISC_CLASS_SCROLL_LOCK);
            }
        }
    };
}

export const ModalTrigger = ({ triggerComponent, triggerText, triggerClass, triggerWrapperClass, triggerDisabled, buttonRef, _onOpen }) => {

    if (!isNullOrUndefined(triggerComponent)) {
        return (
            <span
                onClick={ () => {
                    if (!triggerDisabled) {
                        _onOpen();
                    }
                } }
                ref={ buttonRef }
                className={ triggerWrapperClass }>

                {
                    triggerComponent
                }

            </span>
        );
    }

    return (
        <button
            onClick={ () => {
                if (!triggerDisabled) {
                    _onOpen();
                }
            } }
            className={ triggerClass }
            ref={ buttonRef }
            disabled={ triggerDisabled }>
            {
                triggerText
            }
        </button>
    );
};

const ModalContent = ({ _onClose, _onClickAway, _onKeyDown, content, modalRef, buttonRef, ariaLabel, headerText, withBgHeader, _cancelFunction, role = "dialog" }) => {

    return createPortal(
        <FocusTrap>
            <aside
                onKeyDown={ _onKeyDown }
                onClick={ _onClickAway }
                className='c-modal-cover'
                aria-modal='true'
                aria-label={ ariaLabel }
                role={ role }
                tabIndex='-1'>

                <section className='c-modal' ref={ modalRef }>

                    <div className="c-modal__close_wrapper">
                        <button className='unstyled-btn c-modal__close' aria-label='Zamknij' onClick={ () => _onClose(_cancelFunction) } ref={ buttonRef }>
                            <span className="is-sr-only">Zamknij</span>
                            <IconCancel/>
                        </button>
                    </div>
                    {
                        headerText &&
                        <header className={ `c-modal__header ${ withBgHeader ? "c-modal__header--with-bg" : "" }` }>
                            <h2 className='c-modal__title'>
                                { headerText }
                            </h2>
                        </header>

                    }

                    {
                        content
                    }

                </section>

            </aside>
        </FocusTrap>,
        document.body
    );
};


export default Modal;
