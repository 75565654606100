import React from "react";
import { FileUpload, UploadedFile } from "../../../../../../../common/file_upload";
import { isEmptyObject } from "../../../../../../../../core/utils/misc_utils";

const CommunalDocumentFile = ({ errors, formikBag }) => {
    const file = formikBag.values.file;

    const _onFileUploadStarted = (fileArray) => {
        formikBag.setFieldValue("file", fileArray[0]);
    };

    const _onDeleteUploadedFileClick = () => {
        formikBag.setFieldValue("file", {});
    };

    return (
        <label onClick={ e => e.preventDefault() }>
            <span className='label-name'>
                <span className="required-accent">*</span>
                    Dodaj dokument
            </span>

            {
                !isEmptyObject(file) &&
                <>
                    <UploadedFile
                        key={ file.name }
                        id={ file.name }
                        name={ file.name }
                        alternativeTextRequired={ false }
                        isLoading={ false }
                        _onDeleteUploadedFileClick={ _onDeleteUploadedFileClick }/>

                    <p className="notice--small hint--rejected">
                        { errors.file }
                    </p>
                </>
            }

            {
                isEmptyObject(file) &&
                <FileUpload
                    _onStart={ _onFileUploadStarted }
                    acceptedFileExtensions={ [ 'pdf', 'docx', 'doc', 'odt' ] }
                    maxSingleFileSizeInMB={ 3 }
                    customErrorMessage={ errors.file }
                />
            }
        </label>
    )
}

export default CommunalDocumentFile;