import React from "react";

import FormModal from "../../../../../common/modal/form_modal";

const ResponsibleEntityConfirmationModalForm = ({ _actionFunction, triggerComponent }) => {

    return (
        <FormModal
            triggerComponent={ triggerComponent }
            deleteOrCancelButtons={ true }
            _actionFunction={ () => {
                return _actionFunction();
            } }>

            <div className='c-modal__body modal-size-m modal--no-header'>
                <p className="confirmation-alert">
                    Czy na pewno chcesz usunąć podmiot? Dane zostaną usunięte ze wszystkich istniejących kart, do których został dodany.
                </p>
            </div>

        </FormModal>
    );
};

export default ResponsibleEntityConfirmationModalForm;


