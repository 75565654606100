import webClient from "../../../core/web_client";
import { API_BASE_AKUN_ADMIN_PATH } from "../../../core/constants";

const PATH = `${ API_BASE_AKUN_ADMIN_PATH }/account`;
const PATH_PLURAL = `${ API_BASE_AKUN_ADMIN_PATH }/accounts`;

export const getAccount = () => {
    return webClient
        .get(`${ PATH }`);
};

export const getAccountActivationStatus = (token) => {
    return webClient
        .get(`${ PATH_PLURAL }/${ token }`);
};

export const activateAccount = (token, data) => {
    return webClient
        .post(`${ PATH_PLURAL }/${ token }/activate`, data);
};