import { downloadTemplate } from "../../../../../../../data/actions/waste_management/areas/import";
import React from "react";
import { useDispatch } from "react-redux";

const DownloadTemplate = () => {
    const dispatch = useDispatch();

    return (
        <span className="unstyled-btn dropdown-item" onClick={ () => dispatch(downloadTemplate()) }>
            Pobierz szablon pliku Excel
        </span>
    )
};

export default DownloadTemplate;