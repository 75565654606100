import add from "./add";
import delete_ from "./delete";
import getOwner from "./getOwner";

export default function newsletterReducer(state = {}, action) {
    return {
        ...state,
        add: add(state.add, action),
        delete_: delete_(state.delete_, action),
        getOwner: getOwner(state.getOwner, action)
    };
}