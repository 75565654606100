import React, { Component } from "react";

import withLocationManagement from "../../../../../../../../common/hoc/with_location_management";
import AddressAddModalForm from "../../../../../../../../common/modal/forms/address_modal";
import FormModal from "../../../../../../../../common/modal/form_modal";
import { ReactComponent as IconPlus } from "../../../../../../../../../../resource/img/icons/icon_plus_white.svg";
import { connect } from "react-redux";
import {
    addOrRemoveLocations,
    getManyLocationsForArea
} from "../../../../../../../../../data/actions/waste_management/areas/locations";
import { generatePaginationQueryValues } from "../../../../../../../../../core/utils/api_utils";

const defaultPaginationQueryValues = generatePaginationQueryValues(0, 30, ["name", "asc"]);

class AreaLocationAddModalForm extends Component {

    render = () => {
        const {
            locationCandidates, actions, values, renderSuggestions, inputDisabled,
            _setFieldValue, _resetForm, _onInputFieldFocus, _onInputFieldBlur
        } = this.props;

        return (
            <FormModal
                triggerComponent={ this.renderTriggerComponent() }
                ariaLabel='Dodaj adres'
                headerText='Dodaj adres'
                overflow={ true }
                saveOrStayOrCancelButtons={ true }
                _actionFunction={ () => {
                    return this._onSubmit()
                               .then(() => _resetForm());
                } }
                _actionFunctionWithFormClear={ () => {
                    return this._onSubmit()
                               .then(() => _resetForm({
                                   town: values.town,
                                   selectedTown: values.selectedTown
                               }));
                } }
                _cancelFunction={ async () => _resetForm() }
            >

                <AddressAddModalForm
                    locationCandidates={ locationCandidates }
                    actions={ actions }
                    values={ values }
                    renderSuggestions={ renderSuggestions }
                    inputDisabled={ inputDisabled }
                    _setFieldValue={ _setFieldValue }
                    _onInputFieldFocus={ _onInputFieldFocus }
                    _onInputFieldBlur={ _onInputFieldBlur }/>

            </FormModal>
        );
    };

    renderTriggerComponent = () => {
        return (
            <a href="#root" className='btn btn--big btn--primary header__main-cta-btn'>
                <span className="btn__icon-wrapper--left">
                    <span className='btn__icon'>
                        <IconPlus/>
                    </span>
                    Dodaj adres
                </span>
            </a>
        );
    };

    _onSubmit = () => {
        const { area, searchBarTerm, values, addOrRemoveLocations, getManyLocationsForArea } = this.props;
        if (!area?.data || !values) {
            return Promise.reject();
        }

        if (values.selectedFrom && values.selectedTo) {
            return addOrRemoveLocations(area.data.id, {
                unitIdsRangeToAdd: {
                    from: values.selectedFrom.id,
                    to: values.selectedTo.id
                }
            }).then(() => getManyLocationsForArea(
                area.data.id,
                {
                    ...defaultPaginationQueryValues,
                    query: searchBarTerm || ''
                }
            ));
        }

        const unitId = (values.selectedFrom || values.selectedStreet || values.selectedTown)?.id;
        if (unitId) {
            return addOrRemoveLocations(area.data.id, { unitIdsToAdd: [unitId] })
                .then(() => getManyLocationsForArea(
                    area.data.id,
                    {
                        ...defaultPaginationQueryValues,
                        query: searchBarTerm || ''
                    }
                ));
        }

        return Promise.reject();
    };
}

export default connect(undefined, {addOrRemoveLocations, getManyLocationsForArea})(withLocationManagement(AreaLocationAddModalForm));

