import { deepFreeze } from "../../utils/misc_utils";

export const WasteCollectionPointsEnum = deepFreeze({
    status: {
        UNKNOWN: {
            id: 0
        },
        INVISIBLE: {
            id: 1,
            description: "niewidoczny"
        },
        VISIBLE: {
            id: 2,
            description: "widoczny"
        }

    },
    type: {
        PSZOK: {
            "code": "pszok",
            "id": 1,
            "name": "Punkt Selektywnego Zbierania Odpadów Komunalnych",
            "shortName": "PSZOK",
            "label": "Punkt Selektywnej Zbiórki Odpadów Komunalnych (PSZOK)"
        },
        APTEKA: {
            "code": "apteka",
            "id": 2,
            "name": "Punkt zbiórki przeterminowanych leków",
            "shortName": "lekarstwa",
            "label": "Lekarstwa"
        },
        ELEKTROODPADY: {
            "code": "elektroodpady",
            "id": 3,
            "name": "Punkt zbiórki elektroodpadów lub sklep RTV i AGD",
            "shortName": "elektroodpady",
            "label": "Elektroodpady"
        },
        BATERIE: {
            "code": "baterie",
            "id": 4,
            "name": "Pojemnik na baterie i małe akumulatory",
            "shortName": "baterie i małę akumulatory",
            "label": "Baterie i Małe Akumulatory"
        },
        IGLY: {
            "code": "igly",
            "id": 5,
            "name": "Igły i strzykawki",
            "shortName": "igły i strzykawki",
            "label": "Igły i strzykawki"
        },
        ZAROWKI: {
            "code": "zarowki",
            "id": 6,
            "name": "Pojemnik na żarówki",
            "shortName": "żarówki",
            "label": "Żarówki"
        },
        TWORZYWA: {
            "code": "kontener_na_plastik",
            "id": 7,
            "name": "Kontener na plastki",
            "shortName": "kontener na plastik",
            "label": "Tworzywa i metale"
        },
        PAPIER: {
            "code": "kontener_na_papier",
            "id": 8,
            "name": "Kontener na papier",
            "shortName": "kontener na papier",
            "label": "Papier i tektura"
        },

        SZKLO: {
            "code": "kontener_na_szklo",
            "id": 9,
            "name": "Kontener na szkło",
            "shortName": "kontener na szkło",
            "label": "Szkło"
        },

        SZKLO_BEZBARWNE: {
            "code": "kontener_na_szklo_bezbarwne",
            "id": 23,
            "name": "Kontener na szkło bezbarwne",
            "shortName": "szkło bezbarwne",
            "label": "Szkło bezbarwne"
        },

        SZKLO_KOLOROWE: {
            "code": "kontener_na_szklo_kolorowe",
            "id": 22,
            "name": "Kontener na szkło kolorowe",
            "shortName": "szkło kolorowe",
            "label": "Szkło kolorowe"
        },

        ZMIESZANE: {
            "code": "kontener_na_odpady_zmieszane",
            "name": "Kontener na odpady zmieszane",
            "shortName": "kontener na odpady zmieszane",
            "label": "Zmieszane odpady komunalne"
        },

        ZIELONE: {
            "code": "kontener_na_odpady_zielone",
            "name": "Kontener na odpady zielone",
            "shortName": "kontener na odpady zielone",
            "label": "Odpady zielone"
        },

        BIO: {
            "code": "kontener_na_odpady_bio",
            "name": "Kontener na odpady bio",
            "shortName": "kontener na odpady bio",
            "label": "Odpady biodegradowalne"
        },

        FOLIE: {
            "code": "kontener_na_folie_rolnicze",
            "name": "Kontener na folie rolnicze",
            "shortName": "folie rolnicze",
            "label": "Folie rolnicze"
        },

        SZNURKI: {
            "code": "kontener_na_sznurki_rolnicze",
            "name": "Kontener na Sznurki rolnicze",
            "shortName": "sznurki rolnicze",
            "label": "Sznurki rolnicze"
        },

        OPONY: {
            "code": "kontener_na_opony_rolnicze",
            "name": "Kontener na opony rolnicze",
            "shortName": "opony rolnicze",
            "label": "Opony rolnicze"
        },

        PLASTIKOWE_NAKRETKI: {
            "code": "plastikowe_nakretki",
            "name": "Plastikowe nakrętki",
            "shortName": "plastikowe nakrętki",
            "label": "Plastikowe nakrętki"
        },
        OLEJ: {
            "code": "pojemnik_na_zuzyte_oleje_jadalne",
            "name": "Pojemniki na zużyte oleje jadalne",
            "shortName": "zużyte oleje jadalne",
            "label": "Pojemniki na zużyte oleje jadalne"
        },
        ZLOM: {
            "code": "zlom",
            "name": "Złom",
            "shortName": "złom",
            "label": "Złom"
        },
    }
});
