import React, { useCallback, useState } from "react";

import _ from "lodash";
import MessageTemplateHeader from "./header";
import MessageTemplateList from "./list";

const MessageTemplate = () => {
    const [searchBarTerm, setSearchBarTerm] = useState('');

    const _onSearchBarInputChange = useCallback(
        _.debounce(input => setSearchBarTerm(input || ''), 600),
        []
    );

    return (
        <div className="main-content--table">
            <MessageTemplateHeader
                _onSearchBarInputChange={ _onSearchBarInputChange }/>
            <MessageTemplateList
                searchBarTerm={ searchBarTerm }/>
        </div>
    )
};

export default MessageTemplate;