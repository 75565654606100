import React, { Component } from "react";
import { LocationsEnum } from "../../../../core/enums/waste_management/locations";

class AddressAddModalForm extends Component {

    render = () => {
        const {
            values, renderSuggestions, inputDisabled, _setFieldValue, _onInputFieldFocus, _onInputFieldBlur
        } = this.props;

        return (
            <div className='c-modal__body modal-size-m'>
                <div className='form--vertical'>

                    <label className="is-spaced-bottom--20">
                        <span className='label-name'>Miejscowość</span>
                        <div className="input-with-suggestions">
                            <input type="text"
                                   onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.TOWN) }
                                   onBlur={ _onInputFieldBlur }
                                   value={ values.town }
                                   onChange={ event => _setFieldValue(LocationsEnum.visualType.TOWN, event.target.value) }/>

                            {
                                renderSuggestions(LocationsEnum.visualType.TOWN)
                            }

                        </div>
                    </label>

                    <div className="multiple-inputs-row">
                        <label className="main-width-input main-width-input--left">
                            <span className='label-name'>Ulica</span>
                            <div className="input-with-suggestions">
                                <input placeholder='Wpisz nazwę'
                                       type="text"
                                       onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.STREET) }
                                       onBlur={ _onInputFieldBlur }
                                       value={ values.street }
                                       disabled={ inputDisabled(LocationsEnum.visualType.STREET) }
                                       onChange={ event => _setFieldValue(LocationsEnum.visualType.STREET, event.target.value) }/>

                                {
                                    renderSuggestions(LocationsEnum.visualType.STREET)
                                }

                            </div>
                        </label>

                        <fieldset>
                            <legend className="label-name">Nr budynku</legend>
                            <div className="range-input">
                                <label>
                                    <span className='is-sr-only'>Od</span>
                                    <div className="input-with-suggestions">
                                        <input placeholder="Od" type="text"
                                               onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.ADDRESS_FROM) }
                                               onBlur={ _onInputFieldBlur }
                                               value={ values.from }
                                               disabled={ inputDisabled(LocationsEnum.visualType.ADDRESS_FROM) }
                                               onChange={ event => _setFieldValue(LocationsEnum.visualType.ADDRESS_FROM, event.target.value) }/>

                                        {
                                            renderSuggestions(LocationsEnum.visualType.ADDRESS_FROM)
                                        }

                                    </div>
                                </label>
                                <label>
                                    <div className="location-form__dash">-</div>
                                </label>
                                <label>
                                    <span className='is-sr-only'>Do</span>
                                    <div className="input-with-suggestions">
                                        <input placeholder="Do" type="text"
                                               onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.ADDRESS_TO) }
                                               onBlur={ _onInputFieldBlur }
                                               value={ values.to }
                                               disabled={ inputDisabled(LocationsEnum.visualType.ADDRESS_TO) }
                                               onChange={ event => _setFieldValue(LocationsEnum.visualType.ADDRESS_TO, event.target.value) }/>

                                        {
                                            renderSuggestions(LocationsEnum.visualType.ADDRESS_TO)
                                        }

                                    </div>
                                </label>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        );
    };
}

export default AddressAddModalForm;