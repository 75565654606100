import React, { useCallback, useEffect, useState } from "react";

import withPagination from "../../../../../../common/hoc/with_pagination";
import { useDispatch, useSelector } from "react-redux";
import { generatePaginationQueryValues } from "../../../../../../../core/utils/api_utils";
import {
    deleteManyAddressTemplates,
    getManyAddressTemplates
} from "../../../../../../../data/actions/blisko/address_templates";
import EmptyListPage from "../../../../../../common/empty_list_page";
import IconEmptyAddressTemplates from "../../../../../../../../resource/img/icons/empty_list_icons/brak_wpisow.png";
import ListPagination from "../../../../../../common/pagination";
import AddressTemplateItem from "./item";
import CheckboxInput from "../../../../../../common/checkbox_input";
import FormModal from "../../../../../../common/modal/form_modal";
import {
    ReactComponent as IconDeleteWhite
} from "../../../../../../../../resource/img/icons/icon_delete--white-bin.svg";

const defaultPaginationQueryValues = generatePaginationQueryValues(0, 30, ["name", "asc"]);

const AddressTemplateList = ({
                                 state,
                                 _onPrevClick,
                                 _onNextClick,
                                 _onPageNumberInputValueChange,
                                 currentPage,
                                 setMaxPage
                             }) => {
    const dispatch = useDispatch();
    const addressTemplates = useSelector(state => state.entities?.blisko?.addressTemplates?.getMany);
    const { searchBarTerm, addressTemplateAuthor } = state;
    const [checkedAddressTemplates, setCheckedAddressTemplates] = useState([]);
    const deleteStatus = useSelector(state => state.entities?.blisko?.addressTemplates?.deleteMany);

    const totalPages = addressTemplates?.data?.totalPages;
    const isAddressTemplatesEmpty = !addressTemplates?.data;
    const allChecked = checkedAddressTemplates.length === addressTemplates?.data?.content?.length;

    const _reloadTable = useCallback((searchBarTerm, addressTemplateAuthor) => {
        dispatch(getManyAddressTemplates({
            ...defaultPaginationQueryValues,
            page: currentPage,
            query: searchBarTerm || '',
            show: addressTemplateAuthor
        })).then(() => {
                if (totalPages !== undefined) {
                    setMaxPage(totalPages);
                }

                setCheckedAddressTemplates([])
            }
        );
    }, [totalPages, currentPage, dispatch, setMaxPage]);

    useEffect(() => {
        _reloadTable(searchBarTerm, addressTemplateAuthor);
        // eslint-disable-next-line
    }, [currentPage, searchBarTerm, addressTemplateAuthor]);

    const _onAddressTemplateCheckboxChange = (id) => {
        const addressTemplateIndex = checkedAddressTemplates.indexOf(id);

        if (addressTemplateIndex >= 0) {
            const newCheckedAddressTemplates = [...checkedAddressTemplates];
            newCheckedAddressTemplates.splice(addressTemplateIndex, 1);

            setCheckedAddressTemplates(newCheckedAddressTemplates)
        } else {
            setCheckedAddressTemplates(prevState => [...prevState, id])
        }
    };

    const _onAllAddressTemplateCheckboxesChange = () => {
        if (allChecked) {
            setCheckedAddressTemplates([]);
        } else {
            const addressTemplateIds = addressTemplates?.data?.content
                .map(addressTemplate => {
                    return addressTemplate.id
                });
            setCheckedAddressTemplates(addressTemplateIds);
        }
    };

    const _onSelectedAddressTemplatesDeleteClick = () => {
        return dispatch(deleteManyAddressTemplates({
            ids: checkedAddressTemplates
        })).then(_onDeleteOrUpdateSuccess);
    };

    const _onDeleteOrUpdateSuccess = () => {
        _reloadTable(searchBarTerm, addressTemplateAuthor);
    };

    return (
        addressTemplates?.data?.content?.length === 0 ?
            (
                <EmptyListPage
                    img={ IconEmptyAddressTemplates }
                    imgAlt='Brak szablonów adresów'
                    text={ !searchBarTerm && addressTemplateAuthor !== 'employee' ? 'Brak szablonów adresów' : 'Brak pasujących wyników' }
                />
            )
            :
            (
                <>
                    <section className="main-content--table__bottom container is-fluid">
                        <div className='table-wrapper'>
                            <table className='table--single-line fixed'>
                                <thead>
                                <tr>
                                    <th className='t-width-9'>
                                        <CheckboxInput
                                            label={<p className="is-bold">Nazwa szablonu</p>}
                                            _onInputChange={ _onAllAddressTemplateCheckboxesChange }
                                            checked={ allChecked }/>
                                    </th>
                                    <th className='t-width-2'>Utworzono przez</th>
                                </tr>
                                </thead>
                                <tbody>

                                {
                                    !isAddressTemplatesEmpty &&
                                    addressTemplates.data.content
                                        .map(addressTemplate => {
                                            return (
                                                <AddressTemplateItem
                                                    key={ addressTemplate.id }
                                                    addressTemplate={ addressTemplate }
                                                    checkedAddressTemplates={ checkedAddressTemplates }
                                                    _onAddressTemplateCheckboxChange={ _onAddressTemplateCheckboxChange }
                                                    _onDeleteOrUpdateSuccess={ _onDeleteOrUpdateSuccess }
                                                />
                                            );
                                        })
                                }

                                </tbody>
                            </table>
                        </div>

                    </section>
                    <section className="container is-fluid is-flex">
                        <div className="sll__pagination-wrapper">
                            {
                                checkedAddressTemplates.length > 0 &&
                                <FormModal
                                    triggerComponent={
                                        <div className="btn btn--danger btn--small">
                                        <span className="btn__icon-wrapper--left">
                                            <span className="btn__icon">
                                                <IconDeleteWhite/>
                                            </span>
                                            Usuń
                                        </span>
                                        </div>
                                    }
                                    deleteOrCancelButtons={ true }
                                    _actionFunction={ _onSelectedAddressTemplatesDeleteClick }
                                    isLoading={ deleteStatus?.meta?.loading }
                                >

                                    <div className='c-modal__body modal-size-m modal--no-header'>
                                        <p className="confirmation-alert">
                                            Czy na pewno chcesz usunąć
                                            { checkedAddressTemplates?.length === 1
                                                ? " wybrany szablon adresów"
                                                : " wybrane szablony adresów" } ?
                                        </p>
                                    </div>

                                </FormModal>
                            }
                        </div>
                        <div className="is-flex justify-end pagination--list">
                            {
                                !isAddressTemplatesEmpty &&
                                <ListPagination
                                    data={ addressTemplates.data }
                                    _onPrevClick={ _onPrevClick }
                                    _onNextClick={ _onNextClick }
                                    _onPageNumberInputValueChange={ _onPageNumberInputValueChange }
                                />
                            }
                        </div>
                    </section>
                </>
            )
    )
};

export default withPagination(AddressTemplateList);