import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as IconAdd } from "../../../../../../../resource/img/icons/icon_add_white.svg";
import FormModal from "../../../../../common/modal/form_modal";
import SelectInspectionCompaniesForm from "./selectInspectionCompany";
import SelectAddressTypeForm from "./selectAddresses";
import SelectGroupForm from "./selectGroup";
import SelectBinForm from "./selectBin"
import SelectAdditionalInformationForm from "./selectAdditionalInformation";
import LocationForm, { LocationFormTypeEnum } from "../../../../../common/location_form";
import { Formik } from "formik";
import FormRequiredLegend from "../../../../../common/legend_required";
import { addInventory } from "../../../../../../data/actions/ekostraznik/inventories";
import { showToast } from "../../../../../../data/actions/common/ui/toast";
import { ToastEnum } from "../../../../../../core/enums/common/toast"
import { filesToBase64 } from "../../../../../../core/utils/misc_utils";
import { getAllInventories } from "../../../../../../data/actions/ekostraznik/inventory_history";
import { generatePaginationQueryValues } from "../../../../../../core/utils/api_utils";

const initialValues = {
    inspectionCompanyId: '',
    formType: 'address',
    address: [],
    groupId: '',
    description: '',
    binInventories: [{
        position: 0,
        binCode: '',
        binCapacity: '',
        binCount: ''
    }]
};

const InventoryForm = () => {
    const dispatch = useDispatch();
    const [images, setImages] = useState([]);
    const [resetForm, setResetForm] = useState(false);
    const addStatus = useSelector(state => state.entities?.ekostraznik?.inventories?.add);

    const onSubmit = (formik) => {
        const errors = validate(formik.values);

        if (errors.errorsBinCode.length ||
            errors.emptyBinCount.length ||
            errors.wrongFormatBinCount.length ||
            errors.emptyBinCapacity.length ||
            errors.wrongFormatBinCapacity.length) {
            formik.setErrors(errors);
            return Promise.reject();
        }

        const validatedBinInventories = validateDuplicateBinInventory(formik.values);

        return filesToBase64(images)
            .then(base64Images => {
                dispatch(addInventory({
                    inspectionCompanyId: formik.values.inspectionCompanyId,
                    unitId: formik.values.address.selectedFrom?.id || formik.values.address.selectedApartmentAddress?.id,
                    groupId: formik.values.groupId,
                    description: formik.values.description,
                    images: base64Images,
                    binInventories: validatedBinInventories.map(binInventory => {
                        return {
                            binCode: binInventory.binCode,
                            binCapacity: binInventory.binCapacity,
                            binCount: binInventory.binCount
                        };
                    })
                }))
                    .then(response => {
                        if (response.status >= 400 && response.status < 600) {
                            dispatch(showToast(ToastEnum.type.ERROR, 'Błąd przy dodawaniu inwentaryzacji. Spróbuj ponownie'));
                        }
                        if (response.status === 200) {
                            let messageText = 'Inwentaryzacja została dodana poprawnie. ';
                            if (formik.values.binInventories.length !== validatedBinInventories.length) {
                                messageText = messageText + 'Liczba pojemników z powtarzającą się pojemnością w tych samych frakcjach została zsumowana.';
                            }
                            dispatch(showToast(ToastEnum.type.INFO, messageText));
                            dispatch(getAllInventories(generatePaginationQueryValues(0, 30, ["name", "asc"])));
                        }
                    })
                    .then(() => {
                        clearForm(formik)
                    })
            });
    };

    const clearForm = (formik) => {
        formik.resetForm();
        formik.setFieldValue('binInventories', [{
            position: 0,
            binCode: '',
            binCapacity: '',
            binCount: ''
        }]);
        setImages([]);
        setResetForm(true);
    };

    const validate = (values) => {
        const errors = {
            errorsBinCode: [],
            emptyBinCount: [],
            wrongFormatBinCount: [],
            emptyBinCapacity: [],
            wrongFormatBinCapacity: []
        };

        values.binInventories.forEach(binInventory => {
            if (!binInventory.binCode.length) {
                errors.errorsBinCode[binInventory.position] = 'Pole wymagane';
            }
            if (!binInventory.binCount) {
                errors.emptyBinCount[binInventory.position] = 'Pole wymagane';
            }
            if (binInventory.binCount < 1 && binInventory.binCount) {
                errors.wrongFormatBinCount[binInventory.position] = 'Niepoprawna wartość';
            }
            if (!binInventory.binCapacity) {
                errors.emptyBinCapacity[binInventory.position] = 'Pole wymagane';
            }
            if (binInventory.binCapacity < 1 && binInventory.binCapacity) {
                errors.wrongFormatBinCapacity[binInventory.position] = 'Niepoprawna wartość';
            }
        });

        return errors;
    };

    const validateDuplicateBinInventory = (values) => {
        const result = [];
        values.binInventories.forEach(binInventory => {
            const binInventoryAddedToResult = result
                .filter(binInventoryResult =>
                    binInventoryResult.binCode === binInventory.binCode &&
                    binInventoryResult.binCapacity === binInventory.binCapacity
                );

            if (!binInventoryAddedToResult.length) {
                const binInventoriesOneCodeAndCapacity = values.binInventories
                    .filter(binInventoryToCheckDuplicate =>
                        binInventoryToCheckDuplicate.binCode === binInventory.binCode &&
                        binInventoryToCheckDuplicate.binCapacity === binInventory.binCapacity);

                const sumCountBinInventoriesOneCodeAndCapacity = binInventoriesOneCodeAndCapacity
                    .map(binInventoryOneCodeAndCapacity => Number(binInventoryOneCodeAndCapacity.binCount))
                    .reduce((totalBinCount, currentBinCount) => {
                        return totalBinCount + currentBinCount;
                    }, 0);

                if (binInventoriesOneCodeAndCapacity.length) {
                    result.push({
                        binCode: binInventoriesOneCodeAndCapacity[0].binCode,
                        binCapacity: binInventoriesOneCodeAndCapacity[0].binCapacity,
                        binCount: sumCountBinInventoriesOneCodeAndCapacity
                    });
                }
            }
        });
        return result;
    };

    const onFileUploadStarted = (fileArray) => {
        setImages(prevState => {
            const newImages = [...prevState];
            fileArray.forEach(file => newImages.push(file));

            return newImages;
        });
    };

    const onDeleteUploadedFileClick = (name) => {
        setImages(prevState => {
            const newImages = [...prevState];

            for (let i = 0; i < newImages.length; i++) {
                if (newImages[i].name === name) {
                    newImages.splice(i, 1);
                    return newImages;
                }
            }
        });
    };

    return (
        <Formik
            initialValues={ initialValues }
        >
            {
                formik => (
                    <FormModal
                        headerText="Dodaj inwentaryzację"
                        triggerComponent={
                            <button className='btn btn--primary btn--big'>
                                <IconAdd/>
                                <span className='is-spaced--left-10'>Dodaj inwentaryzację</span>
                            </button>
                        }
                        saveOrStayOrCancelButtons={ true }
                        saveButtonText="Zapisz inwentaryzację"
                        isLoading={ addStatus?.meta?.loading }
                        submitDisabled={
                            !formik.values.inspectionCompanyId ||
                            !formik.values.binInventories?.length ||
                            !(formik.values.address?.from ||
                                formik.values.groupId ||
                                formik.values.address?.selectedApartmentAddress)
                        }
                        _cancelFunction={ async () => clearForm(formik) }
                        _actionFunction={ () => onSubmit(formik) }
                        _actionFunctionWithFormClear={ () => onSubmit(formik) }
                    >
                        <div className='c-modal__body modal-size-l'>
                            <div className='is-relative is-spaced--bottom-30'>
                                <FormRequiredLegend/>
                            </div>
                            <SelectInspectionCompaniesForm
                                formikBag={ formik }
                            />
                            <SelectAddressTypeForm
                                formikBag={ formik }
                            />
                            {
                                formik.values.formType === "address" &&
                                <div>
                                    <LocationForm
                                        horizontal={ false }
                                        formType={ LocationFormTypeEnum.WITH_BUILDING_NUMBER }
                                        _onChange={ () => {
                                            formik.setFieldValue("address", null);
                                            setResetForm(false);
                                        } }
                                        _onSelect={ values => {
                                            formik.setFieldValue("address", values);
                                            setResetForm(false);
                                        } }
                                        resetForm={ resetForm }
                                    />
                                </div>
                            }
                            {
                                formik.values.formType === "group" &&
                                <div>
                                    <SelectGroupForm
                                        formikBag={ formik }
                                    />
                                </div>
                            }
                            {
                                formik.values.formType === "apartmentAddress" &&
                                <div>
                                    <LocationForm
                                        horizontal={ false }
                                        formType={ LocationFormTypeEnum.WITH_APARTMENT_ADDRESS }
                                        _onChange={ () => {
                                            formik.setFieldValue("address", null);
                                        } }
                                        _onSelect={ values => {
                                            formik.setFieldValue("address", values);
                                        } }
                                    />
                                </div>
                            }
                            <SelectBinForm
                                formikBag={ formik }
                            />
                            <SelectAdditionalInformationForm
                                formikBag={ formik }
                                images={ images }
                                onFileUploadStarted={ onFileUploadStarted }
                                onDeleteUploadedFileClick={ onDeleteUploadedFileClick }
                            />
                        </div>
                    </FormModal>
                )
            }
        </Formik>
    );
};

export default InventoryForm;