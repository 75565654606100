import React, { useCallback } from "react";
import FormModal from "../../../../../common/modal/form_modal";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { MISC_DATE_ISO_DATE_ONLY_FORMAT } from "../../../../../../core/constants";
import MomentLocaleUtils, { formatDate } from "react-day-picker/moment";
import moment from "moment";
import CheckboxInput from "../../../../../common/checkbox_input";
import { getQuantitativeReport } from "../../../../../../data/actions/ekostraznik/reports";
import _ from "lodash";
import { isEmptyObject } from "../../../../../../core/utils/misc_utils";
import FormRequiredLegend from "../../../../../common/legend_required";

const QuantitativeReportModalForm = () => {
    const dispatch = useDispatch();
    const reportStatus = useSelector(state => {
        return state.entities?.ekostraznik?.reports?.getQuantitativeReport
    });

    const initialValues = {
        dateFrom: '',
        dateTo: '',
        includeInvalidSegregation: false,
        includeReport: false
    };

    const validate = useCallback(values => (_.pickBy({
        dateFrom: !values.dateFrom ? 'Pole wymagane' : undefined,
        dateTo: !values.dateTo ? 'Pole wymagane' : undefined,
        options: (!values.includeInvalidSegregation && !values.includeReport) ? 'Wybierz przynajmniej jedną opcję' : undefined
    })), [])

    return (
        <Formik
            initialValues={initialValues}
        >
            {
                formik => (
                    <FormModal
                        isLoading={ reportStatus?.meta?.loading }
                        triggerComponent={ <span className="unstyled-btn dropdown-item">Wygeneruj raport ilościowy kontroli i zgłoszeń</span> }
                        overflow={ true }
                        saveOrCancelButtons={ true }
                        headerText="Wygeneruj raport ilościowy kontroli i zgłoszeń"
                        saveButtonText="Wygeneruj raport"
                        _actionFunction={ async () => {
                                const errors = validate(formik.values);
                                if (!isEmptyObject(errors)) {
                                    formik.setErrors(errors);
                                    return Promise.reject(null)
                                }

                                return dispatch(getQuantitativeReport(formik.values))
                                    .then(() => formik.resetForm())
                            }
                        }
                    >
                        <div className='c-modal__body__base modal-size-m'>
                            <div className='is-relative'>
                                <FormRequiredLegend />
                            </div>
                            <Form>
                                <fieldset className="input-field options-item options-item--range">
                                    <legend className="is-sr-only">Wybierz zakres dat, których ma dotyczyć raport</legend>
                                    <div>
                                        <div>
                                            <span className="required-accent">*</span>
                                            <span className="label-name" aria-hidden={ true }>Wybierz zakres dat, których ma dotyczyć raport</span>
                                        </div>
                                        <div className='is-flex'>
                                            <label className='options-param' style={ { alignItems: 'baseline' } }>
                                                <div className="input-size--date">
                                                    <Field>
                                                        {
                                                            field => (
                                                                <DayPickerInput
                                                                    className="message-sending-1__input"
                                                                    placeholder='Od'
                                                                    format={ MISC_DATE_ISO_DATE_ONLY_FORMAT }
                                                                    formatDate={ formatDate }
                                                                    value={ field.value }
                                                                    onDayChange={ date => formik.setFieldValue('dateFrom', moment(date)
                                                                        .format(MISC_DATE_ISO_DATE_ONLY_FORMAT)) }
                                                                    inputProps={ {
                                                                        readOnly: true
                                                                    } }
                                                                    dayPickerProps={ {
                                                                        localeUtils: MomentLocaleUtils,
                                                                        locale: "pl"
                                                                    } }
                                                                />
                                                            )

                                                        }
                                                    </Field>
                                                    {
                                                        formik.errors.dateFrom &&
                                                        <span className="hint hint--rejected">
                                                            { formik.errors.dateFrom }
                                                        </span>
                                                    }
                                                </div>
                                            </label>
                                            <div className="message-sending-1__dash">-</div>
                                            <label className="options-param" style={ { alignItems: 'baseline' } }>
                                                <div className="input-size--date">
                                                    <Field>
                                                        {
                                                            field => (
                                                                <DayPickerInput
                                                                    className="message-sending-1__input"
                                                                    placeholder='Do'
                                                                    format={ MISC_DATE_ISO_DATE_ONLY_FORMAT }
                                                                    formatDate={ formatDate }
                                                                    value={ field.value }
                                                                    onDayChange={ date => formik.setFieldValue('dateTo', moment(date)
                                                                        .format(MISC_DATE_ISO_DATE_ONLY_FORMAT))
                                                                    }
                                                                    inputProps={ {
                                                                        readOnly: true
                                                                    } }
                                                                    dayPickerProps={ {
                                                                        localeUtils: MomentLocaleUtils,
                                                                        locale: "pl"
                                                                    } }
                                                                />
                                                            )

                                                        }
                                                    </Field>
                                                    {
                                                        formik.errors.dateTo &&
                                                        <span className="hint hint--rejected">
                                                            { formik.errors.dateTo }
                                                        </span>
                                                    }
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </fieldset>

                                <div className='flex-column is-spaced--top-20'>
                                    <div>
                                        <span className="required-accent">*</span>
                                        <span className='label-name'>Wybierz czego ma dotyczyć raport</span>
                                        {
                                            formik.errors.options &&
                                            <strong className="hint hint--rejected">{ formik.errors.options }</strong>
                                        }
                                    </div>
                                    <div className='is-spaced--top-10'>
                                        <Field>
                                            {
                                                () => (
                                                    <CheckboxInput
                                                        label='Kontrole segregacji'
                                                        checked={ formik.values.includeInvalidSegregation }
                                                        _onInputChange={ () => formik.setFieldValue('includeInvalidSegregation', !formik.values.includeInvalidSegregation) }
                                                    />
                                                )
                                            }
                                        </Field>
                                    </div>
                                    <div className='is-spaced--top-10'>
                                        <Field>
                                            {
                                                () => (
                                                    <CheckboxInput
                                                        label='Zgłoszenia nieprawidłowości'
                                                        checked={ formik.values.includeReport }
                                                        _onInputChange={ () => formik.setFieldValue('includeReport', !formik.values.includeReport) }
                                                    />
                                                )
                                            }
                                        </Field>
                                    </div>
                                </div>
                            </Form>
                        </div>

                    </FormModal>
                )
            }
        </Formik>
    );
}
export default QuantitativeReportModalForm;
