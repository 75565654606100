import React from "react";

import SidebarItem from "../sidebar_item";
import { PermissionEnum } from "../../../../../core/enums/common/permissions";
import { SidebarEnum } from "../../../../../core/enums/common/sidebar";
import SidebarItemWithSubmenu from "../sidebar_item_with_submenu";
import SidebarSubmenuItem from "../sidebar_submenu_item";
import RestrictedView from "../../../../common/restricted_view";

const EkostraznikSidebarVariant = ({ activeSidebarItem, submenuExpanded, activeSubmenuItem, _onSidebarItemSelected, _onSidebarItemWithSubmenuSelected, _onSubmenuItemSelected }) => {

        return (
            <>
                <RestrictedView
                    neededPermission={PermissionEnum.module.EKOSTRAZNIK.type.INSPECTIONS.full_code}>
                    <SidebarItem
                        sidebarItemId={ SidebarEnum.EKOSTRAZNIK.INSPECTION_HISTORY.id }
                        sidebarItemName={ SidebarEnum.EKOSTRAZNIK.INSPECTION_HISTORY.name }
                        sidebarItemIcon={ SidebarEnum.EKOSTRAZNIK.INSPECTION_HISTORY.icon }
                        sidebarItemLink={ SidebarEnum.EKOSTRAZNIK.INSPECTION_HISTORY.link }
                        activeSidebarItem={ activeSidebarItem }
                        _onSidebarItemSelected={ _onSidebarItemSelected }/>
                </RestrictedView>

                <RestrictedView
                    neededPermission={PermissionEnum.module.EKOSTRAZNIK.type.REPORTS.full_code}>
                    <SidebarItem
                        sidebarItemId={ SidebarEnum.EKOSTRAZNIK.REPORTS.id }
                        sidebarItemName={ SidebarEnum.EKOSTRAZNIK.REPORTS.name }
                        sidebarItemIcon={ SidebarEnum.EKOSTRAZNIK.REPORTS.icon }
                        sidebarItemLink={ SidebarEnum.EKOSTRAZNIK.REPORTS.link }
                        activeSidebarItem={ activeSidebarItem }
                        _onSidebarItemSelected={ _onSidebarItemSelected }/>
                </RestrictedView>

                <RestrictedView
                    neededPermission={PermissionEnum.module.EKOSTRAZNIK.type.INVENTORIES.full_code}>
                    <SidebarItem
                        sidebarItemId={ SidebarEnum.EKOSTRAZNIK.INVENTORIES.id }
                        sidebarItemName={ SidebarEnum.EKOSTRAZNIK.INVENTORIES.name }
                        sidebarItemIcon={ SidebarEnum.EKOSTRAZNIK.INVENTORIES.icon }
                        sidebarItemLink={ SidebarEnum.EKOSTRAZNIK.INVENTORIES.link }
                        activeSidebarItem={ activeSidebarItem }
                        _onSidebarItemSelected={ _onSidebarItemSelected }/>
                </RestrictedView>

                <RestrictedView
                    neededPermission={[
                        PermissionEnum.module.EKOSTRAZNIK.type.ADDRESSES__GROUPED_ADDRESSES.full_code,
                        PermissionEnum.module.EKOSTRAZNIK.type.ADDRESSES__QR_CODES.full_code,
                        PermissionEnum.module.EKOSTRAZNIK.type.ADDRESSES__APARTMENT_ADDRESS.full_code,
                    ]}
                >

                    <SidebarItemWithSubmenu
                        sidebarItemId={ SidebarEnum.EKOSTRAZNIK.ADDRESSES.id }
                        sidebarItemName={ SidebarEnum.EKOSTRAZNIK.ADDRESSES.name }
                        sidebarItemIcon={ SidebarEnum.EKOSTRAZNIK.ADDRESSES.icon }
                        submenuExpanded={ submenuExpanded }
                        activeSidebarItem={ activeSidebarItem }
                        _onSidebarItemWithSubmenuSelected={ _onSidebarItemWithSubmenuSelected }>

                        <RestrictedView
                            neededPermission={PermissionEnum.module.EKOSTRAZNIK.type.ADDRESSES__GROUPED_ADDRESSES.full_code}>
                            <SidebarSubmenuItem
                                sidebarSubmenuItemId={ SidebarEnum.EKOSTRAZNIK.ADDRESSES.GROUPED_ADDRESSES.id }
                                sidebarItemName={ SidebarEnum.EKOSTRAZNIK.ADDRESSES.GROUPED_ADDRESSES.name }
                                sidebarSubmenuLink={ SidebarEnum.EKOSTRAZNIK.ADDRESSES.GROUPED_ADDRESSES.link }
                                activeSubmenuItem={ activeSubmenuItem }
                                _onSubmenuItemSelected={ _onSubmenuItemSelected }/>
                        </RestrictedView>

                        <RestrictedView
                            neededPermission={PermissionEnum.module.EKOSTRAZNIK.type.ADDRESSES__APARTMENT_ADDRESS.full_code}>
                            <SidebarSubmenuItem
                                sidebarSubmenuItemId={ SidebarEnum.EKOSTRAZNIK.ADDRESSES.APARTMENT_ADDRESS.id }
                                sidebarItemName={ SidebarEnum.EKOSTRAZNIK.ADDRESSES.APARTMENT_ADDRESS.name }
                                sidebarSubmenuLink={ SidebarEnum.EKOSTRAZNIK.ADDRESSES.APARTMENT_ADDRESS.link }
                                activeSubmenuItem={ activeSubmenuItem }
                                _onSubmenuItemSelected={ _onSubmenuItemSelected }/>
                        </RestrictedView>

                        <RestrictedView
                            neededPermission={PermissionEnum.module.EKOSTRAZNIK.type.ADDRESSES__QR_CODES.full_code}>
                            <SidebarSubmenuItem
                                sidebarSubmenuItemId={ SidebarEnum.EKOSTRAZNIK.ADDRESSES.QR_CODES.id }
                                sidebarItemName={ SidebarEnum.EKOSTRAZNIK.ADDRESSES.QR_CODES.name }
                                sidebarSubmenuLink={ SidebarEnum.EKOSTRAZNIK.ADDRESSES.QR_CODES.link }
                                activeSubmenuItem={ activeSubmenuItem }
                                _onSubmenuItemSelected={ _onSubmenuItemSelected }/>
                        </RestrictedView>

                    </SidebarItemWithSubmenu>

                </RestrictedView>

                <RestrictedView
                    neededPermission={PermissionEnum.module.EKOSTRAZNIK.type.COMPANIES.full_code}>
                    <SidebarItem
                        sidebarItemId={ SidebarEnum.EKOSTRAZNIK.COMPANIES.id }
                        sidebarItemName={ SidebarEnum.EKOSTRAZNIK.COMPANIES.name }
                        sidebarItemIcon={ SidebarEnum.EKOSTRAZNIK.COMPANIES.icon }
                        sidebarItemLink={ SidebarEnum.EKOSTRAZNIK.COMPANIES.link }
                        activeSidebarItem={ activeSidebarItem }
                        _onSidebarItemSelected={ _onSidebarItemSelected }/>
                </RestrictedView>
            </>

        )

};


export default EkostraznikSidebarVariant;
