import React, { useCallback, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { generatePaginationQueryValues } from "../../../../../../../core/utils/api_utils";
import EmptyListPage from "../../../../../../common/empty_list_page";
import IconEmptyMessages from "../../../../../../../../resource/img/icons/empty_list_icons/brak_wpisow.png";
import ListPagination from "../../../../../../common/pagination";
import withPagination from "../../../../../../common/hoc/with_pagination";
import { getManyDraftMessages } from "../../../../../../../data/actions/blisko/draft_messages";
import DraftMessagesListItem from "./item";

const DraftMessagesList = ({ state, _onPrevClick, _onNextClick, _onPageNumberInputValueChange, currentPage, setMaxPage }) => {
    const dispatch = useDispatch();
    const draftMessages = useSelector(state => state.entities?.blisko?.draftMessages?.getMany);
    const { searchBarTerm, from, to, messageAuthor } = state;

    const totalPages = draftMessages?.data?.totalPages;
    const isMessagesEmpty = !draftMessages?.data;

    const _reloadTable = useCallback((searchBarTerm, from, to, messageAuthor) => {
        dispatch(getManyDraftMessages({
            ...generatePaginationQueryValues(0, 30, ["createdAt", "desc"]),
            page: currentPage,
            query: searchBarTerm || '',
            from,
            to,
            show: messageAuthor
        }))
            .then(() => {
                    if (totalPages !== undefined) {
                        setMaxPage(totalPages);
                    }
                }
            );
    }, [totalPages, currentPage, dispatch, setMaxPage]);

    useEffect(() => {
        _reloadTable(searchBarTerm, from, to, messageAuthor);
        // eslint-disable-next-line
    }, [currentPage, searchBarTerm, from, to, messageAuthor]);

    return (
        draftMessages?.data?.content?.length === 0 ?
            (
                <EmptyListPage
                    img={ IconEmptyMessages }
                    imgAlt='Brak wiadomości'
                    text={ !searchBarTerm ? 'Brak wiadomości roboczych' : 'Brak pasujących wyników' }
                />
            )
            :
            (
                <>
                    <section className="main-content--table__bottom container is-fluid">
                        <div className='table-wrapper'>
                            <table className='table--single-line fixed'>
                                <thead>
                                <tr>
                                    <th className='t-width-1'>Utworzono</th>
                                    <th className='t-width-7'>Treść wiadomości</th>
                                    <th className='t-width-3'>Utworzono przez</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    !isMessagesEmpty &&
                                    draftMessages.data.content
                                        .map(message => {
                                            return (
                                                <DraftMessagesListItem
                                                    key={ message.id }
                                                    message={ message }
                                                    _onDeleteSuccess={ () => _reloadTable(searchBarTerm, from, to, messageAuthor) }
                                                />
                                            );
                                        })
                                }
                                </tbody>
                            </table>
                        </div>

                    </section>
                    <section className="container is-fluid is-flex">
                        <div className="is-flex justify-end pagination--list">
                            {
                                !isMessagesEmpty &&
                                <ListPagination
                                    data={ draftMessages.data }
                                    _onPrevClick={ _onPrevClick }
                                    _onNextClick={ _onNextClick }
                                    _onPageNumberInputValueChange={ _onPageNumberInputValueChange }
                                />
                            }
                        </div>
                    </section>
                </>
            )
    )
};

export default withPagination(DraftMessagesList);