import React from 'react';
import FormModal from "../form_modal";
import { useDispatch } from 'react-redux';
import { updateFileAlternativeText } from '../../../../data/actions/common/files';
import { isEmptyObject } from '../../../../core/utils/misc_utils';
import _ from 'lodash';
import { is4xx, is5xx } from '../../../../core/utils/api_utils';
import { toastForCustomError } from '../../../../core/utils/toast_utils';
import { showToast } from '../../../../data/actions/common/ui/toast';
import { Formik } from 'formik';

const AddOrEditAlternativeTextModalForm = ({ fileId, alternativeText, triggerComponent, _onUpdateSuccess }) => {
    const dispatch = useDispatch();
    const initialFormValues = {
        text: alternativeText || ''
    };

    const _onSubmit = (formikBag) => {
        const errors = validate(formikBag.values);
        if (!isEmptyObject(errors)) {
            formikBag.setErrors(errors);
            return Promise.reject()
        }

        return dispatch(updateFileAlternativeText(fileId, formikBag.values.text))
            .then(async response => {
                if (is4xx(response?.status) || is5xx(response?.status)) {
                    toastForCustomError((...args) => dispatch(showToast(...args)), "Aktualizacja opisu alternatywnego nie powiodła się. Spróbuj ponownie");
                } else {
                    return _onUpdateSuccess(formikBag.values.text);
                }
            })
    }

    const validate = (values) => _.pickBy({
        text: !values.text?.trim() ? 'Pole wymagane' : undefined
    })

    return (
        <Formik
            initialValues={ initialFormValues }
            render={ formikBag =>

                <FormModal
                    headerText={ alternativeText ? 'Zmień opis alternatywny' : 'Dodaj opis alternatywny pliku' }
                    saveOrCancelButtons={true}
                    saveButtonText="Zapisz"
                    triggerComponent={ triggerComponent }
                    _actionFunction={ () =>{
                        return _onSubmit(formikBag);
                    } }>
                    <div className='c-modal__body modal-size-m'>
                        <p>
                            Opis alternatywny pliku musi zawierać informację dla osób niewidzących odnośnie jego zawartości.
                        </p>
                        <br />

                        <label>
                            <textarea
                                placeholder="Wpisz tekst alternatywny"
                                className={ formikBag.errors.text ? "rejected" : "" }
                                value={ formikBag.values.text }
                                onChange={ (event) => formikBag.setFieldValue("text", event.target.value) }/>
                            {
                                formikBag.errors.text &&
                                <p className="notice--small hint--rejected">
                                    { formikBag.errors.text }
                                </p>
                            }
                        </label>
                    </div>
                </FormModal>
            }/>
    )
}

export default AddOrEditAlternativeTextModalForm;