import React, { Component } from "react";
import { Field } from "formik";

class ServicesCardBasicInfoDescription extends Component {

    render = () => {
        const { maxChars } = this.props;

        return (
            <Field name="description"
                   render={ ({ field, form }) => {
                       return (
                           <label>
                               <span className='label-name'>Opis</span>
                               <textarea
                                   value={ field.value }
                                   placeholder="Opisz krótko, czego dotyczy karta"
                                   onChange={ event => this._onFieldChange(event.target.value, form.setFieldValue) }/>
                               <div className="input-desc--bottom">
                                   <span>Liczba znaków</span>
                                   <span>{ field.value.length }/{ maxChars }</span>
                               </div>
                           </label>
                       );
                   } }/>
        );
    };

    _onFieldChange = (value, _setFieldValue) => {
        const { maxChars } = this.props;
        _setFieldValue("description", value.substring(0, maxChars));
    };
}

export default ServicesCardBasicInfoDescription;