import React from "react";
import { useDispatch } from "react-redux";
import { activeReceiverGroup } from "../../../../../../data/actions/blisko/receiver_groups";
import { ReactComponent as IconNew } from "../../../../../../../resource/img/icons/status/icon_to_do.svg";
import { ReactComponent as IconDone } from "../../../../../../../resource/img/icons/status/icon_done.svg";
import { ReactComponent as IconDraft } from "../../../../../../../resource/img/icons/status/icon_in_progress.svg";
import ReceiverGroupDeleteModalForm from './delete_modal_form';
import ReceiverGroupHideModalForm from './hide_modal_form';
import { ReactComponent as IconEdit } from '../../../../../../../resource/img/icons/icon_edit.svg';
import { ROUTE_BLISKO_RECEIVER_GROUP_EDIT } from '../../../../../../core/constants';
import { useHistory } from 'react-router-dom';

const ReceiverGroupListItem = ({ receiverGroup, _onDeleteOrUpdateSuccess }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const showStatusIcon = () => {
        switch (receiverGroup.status) {
            case "active":
                return (
                    <>
                        <IconDone/>
                        <p className="is-spaced--left-2"> Opublikowany </p>
                    </>
                );
            case  "hidden":
                return (
                    <>
                        <IconNew/>
                        <p className="is-spaced--left-2"> Ukryty </p>
                    </>
                );
            case "draft":
            default:
                return (
                    <>
                        <IconDraft/>
                        <p className="is-spaced--left-2"> Roboczy </p>
                    </>
                );
        }
    };

    return (
        <tr>
            <td className='t-width-3'>
                <p className="table-text-content">
                    {
                        receiverGroup.name
                    }
                </p>
            </td>
            <td className='t-width-5'>
                <p className="table-text-content">
                    {
                        receiverGroup.subscribersNumber
                    }
                </p>
            </td>
            <td className='t-width-2'>
                <div className="table-text-content">
                    <div className="row">
                        {
                            showStatusIcon()
                        }
                    </div>
                </div>
            </td>
            <td className='t-width-2 flex-center'>
                {
                    <div className="input-field options-item">
                        {
                            receiverGroup.status !== "active" &&
                            <button
                                className={ `btn  btn--short btn--small btn--primary` }
                                onClick={ () =>
                                    dispatch(activeReceiverGroup(receiverGroup?.id))
                                        .then(async () => _onDeleteOrUpdateSuccess()) }>
                                { 'Opublikuj' }
                            </button>
                        }
                        {
                            receiverGroup.status === "active" &&
                            <ReceiverGroupHideModalForm
                                receiverGroup={ receiverGroup }
                                _onSubmitCallback={ () => _onDeleteOrUpdateSuccess() }
                            />

                        }
                    </div>
                }
            </td>
            <td className='sticky--right td-action-buttons'>

                <button className='unstyled-btn' title='Edytuj'
                        onClick={ () => history.push(ROUTE_BLISKO_RECEIVER_GROUP_EDIT(receiverGroup.id)) }>
                    <IconEdit/>
                </button>

                <ReceiverGroupDeleteModalForm
                    receiverGroup={ receiverGroup }
                    _onSubmitCallback={ () => _onDeleteOrUpdateSuccess() }
                />

            </td>
        </tr>
    );
};

export default ReceiverGroupListItem;
