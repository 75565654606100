import { actions } from "../../../core/constants";
import * as api from "../../_api";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const getManyMessageAddresses = () => dispatch => {
    dispatch({
        type: actions.blisko.API__BLISKO__MESSAGE_ADDRESSES__GET_MANY__STARTED
    });

    return api
        .blisko
        .messageAddresses
        .getMany()
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__MESSAGE_ADDRESSES__GET_MANY__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__MESSAGE_ADDRESSES__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });

};

export const addUnitsToMessageAddresses = (unitsId) => dispatch => {
    return executeUpdateAction(() =>
            api
                .blisko
                .messageAddresses
                .addUnits(unitsId),
        dispatch
    );

};

export const addUnitsRangeToMessageAddresses = (unitIdFrom, unitIdTo) => dispatch => {
    return executeUpdateAction(() =>
            api
                .blisko
                .messageAddresses
                .addUnitsRange(unitIdFrom, unitIdTo),
        dispatch
    );

};

export const addAddressTemplateToMessageAddresses = (addressTemplateId) => dispatch => {
    return executeUpdateAction(() =>
            api
                .blisko
                .messageAddresses
                .addAddressTemplate(addressTemplateId),
        dispatch
    );

};

export const removeUnitsFromMessageAddresses = (unitIds) => dispatch => {
    return executeUpdateAction(() =>
            api
                .blisko
                .messageAddresses
                .removeUnits(unitIds),
        dispatch
    );
};

const executeUpdateAction = (action, dispatch) => {
    dispatch({
        type: actions.blisko.API__BLISKO__MESSAGE_ADDRESSES__UPDATE__STARTED
    });

    return action()
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__MESSAGE_ADDRESSES__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__MESSAGE_ADDRESSES__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};