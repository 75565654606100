import React from "react";

import { ReactComponent as IconSearch } from "../../../../../../resource/img/icons/icon_search.svg";
import AddAddressGroupForm from "./form/add_form";

const AddressGroupsHeader = ({ _onSearchBarInputChange }) => {

    return (
        <section className='main-content--table__top'>
            <header className='page-header page-header--with-back'>
                <h1 className='title is-1'>
                    Zgrupowane adresy
                </h1>
            </header>

            <section className='container is-fluid'>
                <div className="row row--separated row--options">
                    <div className='row__left'>
                        <div className="input-field header__search with-icon--right">
                            <input type='text'
                                   placeholder='Znajdź grupę lub adres należący do grupy'
                                   onChange={ event => _onSearchBarInputChange(event.target.value) }
                            />
                            <IconSearch className='input-icon'/>
                        </div>
                    </div>
                    <div className='row__right'>
                        <AddAddressGroupForm />
                    </div>
                </div>
            </section>
        </section>
    );
};

export default AddressGroupsHeader;
