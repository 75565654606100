import webClient from "../../../../core/web_client";
import { API_BASE_AKUN_GOK_PATH } from "../../../../core/constants";
import { buildQueryString } from "../../../../core/utils/api_utils";

export const PATH = `${ API_BASE_AKUN_GOK_PATH }/areas`;

export const getManyAreas = () => {
    return webClient
        .get(`${ PATH }`);
};

export const getManyAreasPaginated = (queryParams) => {
    return webClient
        .get(`${ PATH }/list${ buildQueryString(queryParams) }`);
};

export const getArea = (id) => {
    return webClient
        .get(`${ PATH }/${ id }`);
};

export const addArea = (data) => {
    return webClient
        .post(`${ PATH }`, data);
};

export const updateArea = (id, data) => {
    return webClient
        .put(`${ PATH }/${ id }`, data);
};

export const deleteArea = (id) => {
    return webClient
        .delete(`${ PATH }/${ id }`);
};