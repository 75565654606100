import * as api from "../../../_api";
import { actions } from "../../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../../core/utils/api_utils";

export const getManyLocationsForArea = (areaId, queryParams) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__AREA_LOCATIONS__GET_MANY__STARTED
    });

    return api
        .waste_management
        .areaLocations
        .getManyLocationsForArea(areaId, queryParams)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__AREA_LOCATIONS__GET_MANY__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__AREA_LOCATIONS__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};


export const addOrRemoveLocations = (areaId, {unitIdsToAdd, unitIdsRangeToAdd, unitIdsToRemove}) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__AREA_LOCATIONS__PATCH_MANY__STARTED
    });

    const promises = [];

    if (unitIdsToAdd?.length > 0) {
        promises.push(
            api
                .waste_management
                .areaLocations
                .addLocations(areaId, unitIdsToAdd)
        )
    }

    if (unitIdsRangeToAdd?.from && unitIdsRangeToAdd.to) {
        promises.push(
            api
                .waste_management
                .areaLocations
                .addLocationsRange(areaId, unitIdsRangeToAdd.from, unitIdsRangeToAdd.to)
        )
    }

    if (unitIdsToRemove?.length > 0) {
        promises.push(
            api
                .waste_management
                .areaLocations
                .removeLocations(areaId, unitIdsToRemove)
        )
    }

    return Promise.all(promises)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__AREA_LOCATIONS__PATCH_MANY__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__AREA_LOCATIONS__PATCH_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
}