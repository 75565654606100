import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROUTE_EKOSTRAZNIK_ADDRESSES_GROUPED_ADDRESSES } from "../../../../../../../core/constants";
import Header from "../../../../../../common/header";
import Nests from "./nests";
import { getAddressGroup } from "../../../../../../../data/actions/ekostraznik/address_groups";
import Addresses from "./addresses";

const AddressGroupDetails = (props) => {
    const dispatch = useDispatch();
    const addressGroup = useSelector(state => state.entities?.ekostraznik?.addressGroups?.get)
    const [activeTab, setActiveTab] = useState(0);
    const groupId = props.match.params.groupId;

    useEffect(() => {
        dispatch(getAddressGroup(groupId))
    }, [dispatch, groupId])

    return (
        <div className="main-content--table">
            <Header goBackLink={ ROUTE_EKOSTRAZNIK_ADDRESSES_GROUPED_ADDRESSES }>
                {
                    addressGroup.data?.name
                }
            </Header>

            <section className="main-content--table__top container is-fluid">
                <div className="row row--options">
                    <div className='full-width'>
                        <div className="tabs">
                            <ul className="tabs-list">
                                <li onClick={ () => setActiveTab(0) }
                                    className={ `tabs-item ${ activeTab === 0 ? 'is-active' : '' }` }>
                                    <span className="tab-item">Adresy należące do grupy</span>
                                </li>
                                <li onClick={ () => setActiveTab(1) }
                                    className={ `tabs-item ${ activeTab === 1 ? 'is-active' : '' }` }>
                                    <span className="tab-item">Lokalizacje wiat śmietnikowych </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {
                activeTab === 0 &&
                <Addresses groupId={ groupId } groupName={ addressGroup?.data?.name }/>
            }

            {
                activeTab === 1 &&
                <Nests groupId={ groupId } nests={ addressGroup?.data?.nests }/>
            }

        </div>
    )
}

export default AddressGroupDetails;