import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOwnerConfiguration } from "../../../../../data/actions/common/owner";
import Loader from "../../../../common/loader";
import { isNullOrUndefined } from "../../../../../core/utils/misc_utils";
import { PaymentsDueDateEnum } from "../../../../../core/enums/waste_management/payments_due_date";
import _ from "lodash";
import { getManyAreas } from "../../../../../data/actions/waste_management/areas";
import {
    getPaymentsDueDate,
    updatePaymentsDueDate
} from "../../../../../data/actions/waste_management/payments_due_date";
import { toastFor500, toastForSuccess } from "../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../data/actions/common/ui/toast";
import Header from "../../../../common/header";
import Select from "../../../../common/select";
import { Form, Formik } from "formik";
import { ReactComponent as IconCheckCircle } from "../../../../../../resource/img/icons/icon_check_circle.svg";
import DropdownMenu from "../../../../common/dropdown_menu";
import { ReactComponent as IconEllipsis } from "../../../../../../resource/img/icons/icon_ellipsis.svg";
import MonthlyPayments from "./payment_frequency/monthly";
import QuarterlyPayments from "./payment_frequency/quarterly";
import FormErrorMessage from "../../../../common/form_error_message";

const initialFormValues = {
    january: "",
    february: "",
    march: "",
    april: "",
    may: "",
    june: "",
    july: "",
    august: "",
    september: "",
    october: "",
    november: "",
    december: ""
};

const PaymentsDueDate = () => {
    const dispatch = useDispatch();
    const ownerConfiguration = useSelector(state => state.entities?.common?.owner?.getConfiguration);
    const receivePaymentsDueDate = useSelector(state => state.entities?.wasteManagement?.paymentsDueDate?.get);
    const postPaymentsDueDate = useSelector(state => state.entities?.wasteManagement?.paymentsDueDate?.update);
    const [selectedPaymentFrequency, setSelectedPaymentFrequency] = useState("");
    const [submitInvoked, setSubmitInvoked] = useState(false);
    const [areaOptions, setAreaOptions] = useState();
    const [selectedArea, setSelectedArea] = useState(null);
    const monthlyPayment = PaymentsDueDateEnum.type.MONTHLY;
    const quarterlyPayment = PaymentsDueDateEnum.type.QUARTERLY;
    const paymentAreaEnabled = ownerConfiguration?.data?.paymentAreaEnabled;

    const selectedPaymentFrequencyType = receivePaymentsDueDate?.data?.type;

    const generateSelectOptions = (response) => {
        if (!response) {
            return [];
        }

        const sortedAreaData = _.sortBy(response, [area => area.name.toLowerCase(), 'name']);

        return _.map(sortedAreaData, area => {
            return {
                label: area.name,
                value: area.id
            };
        });
    };

    useEffect(() => {
        dispatch(getOwnerConfiguration())
            .then(response => {
                if (response.data.paymentAreaEnabled) {
                    dispatch(getManyAreas())
                        .then(response => {
                            const options = generateSelectOptions(response);
                            setAreaOptions(options);

                            return options;
                        })
                        .then(options => {
                            setSelectedArea(options[0]);
                            dispatch(getPaymentsDueDate({ areaId: options[0].value }));
                        });
                } else {
                    dispatch(getPaymentsDueDate());
                }
            });
    }, [dispatch]);

    useEffect(() => {
        _setSelectedPaymentFrequency(receivePaymentsDueDate);
        // eslint-disable-next-line
    }, [selectedPaymentFrequencyType]);

    const fixDefaultFormValues = (formValues) => {
        for (let key in formValues) {
            if (formValues.hasOwnProperty(key) && isNullOrUndefined(formValues[key])) {
                formValues[key] = initialFormValues[key];
            }
        }

        return formValues;
    };

    const initialValues =
        !isNullOrUndefined(receivePaymentsDueDate) && !isNullOrUndefined(receivePaymentsDueDate.data)
            ? fixDefaultFormValues(receivePaymentsDueDate.data) :
            initialFormValues;

    const _onMonthlyPaymentsClick = () => {
        setSelectedPaymentFrequency(PaymentsDueDateEnum.type.MONTHLY);
    };

    const _onQuarterlyPaymentsClick = () => {
        setSelectedPaymentFrequency(PaymentsDueDateEnum.type.QUARTERLY);
    };

    const publishButtonEnabled = (formikBag) => {
        if (selectedPaymentFrequency === PaymentsDueDateEnum.type.QUARTERLY) {
            return !!(formikBag.dirty);
        } else {
            return formikBag.dirty;
        }
    };

    const _setSelectedPaymentFrequency = (receivePaymentsDueDate) => {
        if (!isNullOrUndefined(receivePaymentsDueDate) && !isNullOrUndefined(receivePaymentsDueDate.data)) {
            setSelectedPaymentFrequency(receivePaymentsDueDate.data.type);
        } else {
            setSelectedPaymentFrequency(PaymentsDueDateEnum.type.MONTHLY);
        }
    };

    const _setFormValues = (formikBag, receivePaymentsDueDate) => {
        if (!isNullOrUndefined(formikBag) && !isNullOrUndefined(receivePaymentsDueDate) && !isNullOrUndefined(receivePaymentsDueDate.data)) {
            formikBag.resetForm(receivePaymentsDueDate.data);
        } else if (!isNullOrUndefined(formikBag)) {
            formikBag.resetForm(fixDefaultFormValues(initialFormValues));
        }
    };

    const fixFormValuesBeforeSubmit = (formValues) => {
        if (isNullOrUndefined(formValues) || selectedPaymentFrequency === PaymentsDueDateEnum.type.MONTHLY) {
            return formValues;
        }

        for (let key in formValues) {
            const isNotQuarterlyMonth = (
                key === "january" ||
                key === "february" ||
                key === "april" ||
                key === "may" ||
                key === "july" ||
                key === "august" ||
                key === "october" ||
                key === "november"
            );

            if (formValues.hasOwnProperty(key) && isNotQuarterlyMonth) {
                formValues[key] = null;
            }
        }

        return formValues;
    };

    const _onSubmit = (values) => {
        setSubmitInvoked(false);

        const dispatchUpdate = paymentAreaEnabled
            ? dispatch(updatePaymentsDueDate({
                ...fixFormValuesBeforeSubmit(values),
                type: selectedPaymentFrequency,
                areaId: selectedArea.value
            }))
            : dispatch(updatePaymentsDueDate({ ...fixFormValuesBeforeSubmit(values), type: selectedPaymentFrequency }));

        return dispatchUpdate
            .then(() => {
                paymentAreaEnabled
                    ? dispatch(getPaymentsDueDate({ areaId: selectedArea?.value }))
                    : dispatch(getPaymentsDueDate())
            })
            .then(() => setSubmitInvoked(true))
            .then(() => {
                toastFor500((...args) => dispatch(showToast(...args)), postPaymentsDueDate, "Publikacja nie powiodła się. Spróbuj ponownie później");
                toastForSuccess((...args) => dispatch(showToast(...args)), postPaymentsDueDate, "Informacje zostały opublikowane");
            });
    };

    if (isNullOrUndefined(paymentAreaEnabled) || (paymentAreaEnabled && isNullOrUndefined(selectedArea))) {
        return (
            <Loader/>
        );
    }

    return (
        <>
            <Header>
                Terminy opłat za wywóz odpadów
            </Header>
            {
                paymentAreaEnabled &&
                <div style={ { paddingTop: "0px" } }
                     className="page-header select-wrapper select-wrapper--with-icon select-wrapper--map-marker-icon">
                    <Select
                        options={ areaOptions }
                        value={ selectedArea }
                        isClearable={ false }
                        onChange={ option => {
                            setSelectedArea(option);
                            dispatch(getPaymentsDueDate({ areaId: option.value }));
                        } }/>
                </div>
            }
            <section className="content--m content--not-centered">
                <div className="payments">

                    <Formik initialValues={ initialValues }
                            onSubmit={ values => _onSubmit(values) }
                            render={ (formikBag) => (
                                <Form>
                                    <div className="space-between">
                                        <p className="payments-spacing">
                                            { paymentAreaEnabled ? "Dla wybranego obszaru zaznacz terminy opłat" : "Zaznacz terminy opłat" } ,
                                            które pojawią się na harmonogramie. <br/>
                                            { paymentAreaEnabled ? "Opublikuj terminy, system automatycznie powiadomi mieszkańca o zbliżających się opłatach." : "System automatycznie powiadomi mieszkańca o zbliżających się opłatach." }
                                        </p>
                                        <div className="is-flex">
                                            <button type='submit'
                                                    className='btn btn--big btn--primary header__main-cta-btn payments-spacing is-spaced--right-10'
                                                    disabled={ !publishButtonEnabled(formikBag) }>
                                                <span className="btn__icon-wrapper--left">
                                                    <span className='btn__icon'>
                                                        <IconCheckCircle/>
                                                    </span>
                                                    Opublikuj
                                                </span>
                                            </button>
                                            <DropdownMenu
                                                triggerComponent={
                                                    <div
                                                        className="btn btn--outlined btn--icon"
                                                        title='Więcej'
                                                    >
                                                        <IconEllipsis/>
                                                    </div>
                                                }>
                                            </DropdownMenu>
                                        </div>
                                    </div>

                                    <section className="payments-spacing">
                                        <h2 className="is-bold is-spaced--bottom-10">
                                            Częstotliwość opłat
                                        </h2>
                                        <div className="options-wrapper--horizontal">
                                            <label onClick={ () => _onMonthlyPaymentsClick(formikBag) }
                                                   className={ `choice-label choice-label--radio ${ selectedPaymentFrequency === monthlyPayment ? "checked--radio" : "" } ` }>
                                                <input className="choice--radio" type="radio"
                                                       value={ monthlyPayment } name="frequency"/>Miesięczna
                                            </label>
                                            <label onClick={ _onQuarterlyPaymentsClick }
                                                   className={ `choice-label choice-label--radio ${ selectedPaymentFrequency === quarterlyPayment ? "checked--radio" : "" } ` }>
                                                <input className="choice--radio" type="radio"
                                                       value={ quarterlyPayment } name="frequency"/>Kwartalna
                                            </label>

                                        </div>
                                    </section>

                                    {
                                        selectedPaymentFrequency === monthlyPayment
                                            ? <MonthlyPayments
                                                formikBag={ formikBag }
                                                receivePaymentsDueDate={ receivePaymentsDueDate }
                                                _setFormValues={ _setFormValues }/>
                                            : <QuarterlyPayments
                                                formikBag={ formikBag }
                                                receivePaymentsDueDate={ receivePaymentsDueDate }
                                                _setFormValues={ _setFormValues }/>
                                    }

                                </Form>
                            ) }/>

                    {
                        postPaymentsDueDate.meta.error && submitInvoked &&
                        <FormErrorMessage
                            message={ "Błędnie wypełniony formularz. Informacje nie zostały opublikowane." }/>
                    }
                </div>
            </section>
        </>
    );
};


export default PaymentsDueDate;
