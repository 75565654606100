import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { setSidebarVariant } from "../../../data/actions/common/ui/sidebar";

const withModules = (OriginalComponent) => {

    class WrappedComponent extends Component {

        render = () => {
            const { modules, setSidebarVariant } = this.props;

            return <OriginalComponent modules={ modules }
                                      setSidebarVariant={ setSidebarVariant }
                                      { ...this.props }/>;
        };


    }

    return WrappedComponent;
};


const mapStateToProps = (state) => {
    return {
        modules: state.entities?.common?.owners?.getModules
    };
};

const composedWithModules = compose(
    connect(mapStateToProps, { setSidebarVariant }), withModules);

export default composedWithModules;
