import React, { Component } from "react";
import _ from "lodash";

import { ReactComponent as IconAdd } from "../../../../../../../resource/img/icons/icon_add_white.svg";
import iconParagraph from "../../../../../../../resource/img/icons/icon_paragraph.svg";

import UnderlinedHeader from "../../../../../common/header_underlined";
import TextareaRemovable from "../../../../../common/input_removable";

class ServicesCardLegalFoundation extends Component {

    render = () => {
        const { step, legalFoundations } = this.props;

        return (
            <section className="media mw-100">
                <div className="media-left step-count-wrap">
                    <div className="step-count">
                        <span>{ step }</span>
                    </div>
                </div>
                <div className="media-content media-content--steps">
                    <UnderlinedHeader text="Podstawa prawna"/>
                    <p className="is-spaced--bottom-30">Uzupełnij informacje prawne dotyczące treści karty. Jedno pole odpowiada jednemu paragrafowi.</p>
                    <div>

                        {
                            _.map(legalFoundations, legalFoundation => (
                                <TextareaRemovable
                                    key={ legalFoundation.no }
                                    icon={ iconParagraph }
                                    placeholder="Uzupełnij treść paragrafu"
                                    value={ legalFoundation.value }
                                    _onEdit={ value => this._onEdit(legalFoundation.no, value) }
                                    _onDeleteClick={ event => {
                                        event.preventDefault();
                                        this._onDeleteClick(legalFoundation.no);
                                    } }/>
                            ))
                        }

                        <div>
                            <button className="btn btn--primary"
                                    onClick={ event => {
                                        event.preventDefault();
                                        this._onAddClick();
                                    } }>
                                <span className="btn__icon-wrapper--left">
                                    <span className="btn__icon">
                                        <IconAdd/>
                                    </span>
                                    Dodaj nowy paragraf
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        );
    };

    _onEdit = (legalFoundationNo, value) => {
        const { legalFoundations, _setLegalFoundationsValue } = this.props;
        const nextLegalFoundations = _.cloneDeep(legalFoundations);
        nextLegalFoundations[legalFoundationNo] = { value, no: legalFoundationNo };

        return _setLegalFoundationsValue(nextLegalFoundations);
    };

    _onAddClick = () => {
        const { legalFoundations, _setLegalFoundationsValue } = this.props;
        const nextLegalFoundations = _.cloneDeep(legalFoundations);
        const nextNo = Object.keys(legalFoundations).length;
        nextLegalFoundations[nextNo] = { value: "", no: nextNo };

        return _setLegalFoundationsValue(nextLegalFoundations);
    };

    _onDeleteClick = (legalFoundationNo) => {
        const { legalFoundations, _setLegalFoundationsValue } = this.props;

        const nextLegalFoundations = _
            .filter(legalFoundations, legalFoundation => legalFoundation.no !== legalFoundationNo)
            .map(legalFoundation => {
                if (legalFoundation.no > legalFoundationNo) {
                    legalFoundation.no = legalFoundation.no - 1;
                }

                return legalFoundation;
            });

        _setLegalFoundationsValue(_.mapKeys(nextLegalFoundations, "no"));
    };
}


export default ServicesCardLegalFoundation;
