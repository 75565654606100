import React, { Component } from "react";
import { Form, Formik } from "formik";
import { connect } from "react-redux";

import FormModal from "../../../../common/modal/form_modal";
import CreateAccountModalFormContent from "./content/index";
import withAccountFields from "../common/account_form/with_account_fields_hoc";
import { ReactComponent as IconAddUser } from "../../../../../../resource/img/icons/icon_add_user_white.svg";
import { addEmployee, getManyEmployees } from "../../../../../data/actions/common/employees";

const initialFormValues = {
    email: "",
    name: "",
    surname: "",
    phoneNumber: "",
    description: "",
    moduleItems: []
};

class CreateAccountModalForm extends Component {

    state = {
        slotsFull: false
    };

    render = () => {
        const { renderAccountFields, _onSubmitAdd } = this.props;

        const { slotsFull } = this.state;

        return (
            <Formik
                initialValues={ initialFormValues }
                render={ formikBag => (
                    <Form>
                        <FormModal
                            ariaLabel={ "Utwórz konto moderatora" }
                            headerText={ "Utwórz konto moderatora" }
                            saveButtonText={ "Zapisz i wyślij zaproszenie" }
                            saveOrCancelButtons={ true }
                            triggerDisabled={ slotsFull }
                            triggerComponent={ this.triggerComponent(slotsFull) }
                            _actionFunction={ () => {
                                return _onSubmitAdd(formikBag);
                            } }>

                            <CreateAccountModalFormContent
                                renderAccountFields={ renderAccountFields }
                                _resetForm={ formikBag.resetForm }/>

                        </FormModal>
                    </Form>
                ) }/>
        );
    };

    componentDidMount = () => {
        const { slots } = this.props;

        if (slots.used >= slots.available) {
            this.setState({
                slotsFull: true
            });
        }
    };

    triggerComponent = (disabled) => {
        return (
            <button className="btn btn--primary header__main-cta-btn"
                    type="button"
                    disabled={ disabled }>
                <span className="btn__icon-wrapper--left">
                    <span className='btn__icon'>
                        <IconAddUser/>
                    </span>
                    Utwórz konto moderatora
                </span>
            </button>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        employeeAdd: state.entities.common.employees.add
    };
};

export default withAccountFields(
    connect(mapStateToProps, { addEmployee, getManyEmployees })(CreateAccountModalForm)
);