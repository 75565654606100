import React, { Component } from "react";
import { connect } from "react-redux";
import { isNullOrUndefined } from "../../core/utils/misc_utils";

class RestrictedView extends Component {
    render () {
        const {children} = this.props;
        let {permissions, neededPermission} = this.props;

        if (isNullOrUndefined(permissions)) {
            permissions = {};
        }

        if (isNullOrUndefined(neededPermission)) {
            neededPermission = [];
        }

        if (!Array.isArray(neededPermission)) {
            neededPermission = [neededPermission];
        }

        const accessGranted = neededPermission.reduce(
            (accessGrantedTmp, neededPermissionTmp) => accessGrantedTmp || !isNullOrUndefined(permissions[neededPermissionTmp]),
            false
        );

        return (
            <>
                {
                    accessGranted && children
                }
            </>
        );
    }
}

export default connect((state) => ({
    permissions: state.authentication.data.permissions,
}))(RestrictedView)
