import React, { Component } from "react";
import InspectionsListItem from "./item";
import { ReactComponent as IconArrowSwitchUp } from "../../../../../../../resource/img/icons/list/icon_arrow_switch_up.svg";
import _ from "lodash";


class InspectionsList extends Component {

    render = () => {
        const { list } = this.props;

        return (
            <section className='main-content--table__bottom container is-fluid'>
                <div className="table-wrapper table-wrapper--flexible">
                    <table className='table--single-line fixed table--flexible'>
                        <thead>
                        <tr>
                            <th className='data--flexible'>
                                Lokalizacja
                                <div className='th-action-btn'>
                                    <IconArrowSwitchUp/>
                                </div>
                            </th>
                            <th className='t-width-3 data--fixed justify-end'>
                                Ostatnia  kontrola
                                <div className='th-action-btn'>
                                    <IconArrowSwitchUp/>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            list &&
                            _.map(list, listItem =>
                                <InspectionsListItem
                                    listItem={ listItem }
                                    key={ listItem.lastInspectionDate }
                                    />
                            )
                        }
                        </tbody>
                    </table>
                </div>
            </section>
        );
    };
}

export default InspectionsList;