import ComponentWithDropdown from "../../../../../common/component_with_dropdown";
import React from "react";
import { ReactComponent as IconEllipsis } from "../../../../../../../resource/img/icons/icon_ellipsis.svg";
import AreaLocationsImportModal from "./import/import_modal";
import DownloadTemplate from "./import/download_template";

class AreaDetailsDropdownMenu extends ComponentWithDropdown {
    render = () => {
        const { dropdownOpen = false } = this.state;

        return (
            <div className={ `dropdown  ${ dropdownOpen ? "is-active" : '' }` }>
                <div className="dropdown-trigger">
                    <button
                        className="btn btn--outlined btn--icon"
                        title='Więcej'
                        onClick={ this._onDropdownOpen }
                    >
                        <IconEllipsis/>
                    </button>
                </div>


                <div className="dropdown-menu dropdown-size-m drop-to-right">
                    <div className="dropdown-content">
                        <AreaLocationsImportModal { ...this.props }/>
                        <DownloadTemplate/>
                    </div>
                </div>
            </div>
        );
    }
}

export default AreaDetailsDropdownMenu;
