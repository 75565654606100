import selected from "./selected";
import variant from "./variant";

import { combineReducers } from "redux";

const sidebar = combineReducers({
    selected,
    variant
});

export default sidebar;