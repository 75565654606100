import webClient from "../../../core/web_client";
import { API_BASE_AKUN_EKOSTRAZNIK_PATH } from "../../../core/constants";
import { buildQueryString } from "../../../core/utils/api_utils";

const ADDRESS_GROUPS_PATH = `${ API_BASE_AKUN_EKOSTRAZNIK_PATH }/address-groups`;
const PATH = 'addresses';

export const getMany = (id, queryParams) => {
    return webClient
        .get(`${ ADDRESS_GROUPS_PATH }/${id}/${ PATH }${buildQueryString(queryParams)}`);
};