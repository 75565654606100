import React, { Component } from "react";

import mapboxgl from "../../../core/mapbox";
import {
    MISC_MAPBOX_DEFAULT_STYLE,
    MISC_MAPBOX_EVENT_LOAD,
    MISC_MAPBOX_EVENT_MOVE,
    MISC_MAPBOX_EVENT_MOVEEND
} from "../../../core/constants";

const withMap = (OriginalComponent) => {

    class WrappedComponent extends Component {
        mapboxContainerRef = React.createRef();
        mapboxMapRef = React.createRef();
        mapboxMarkerRef = React.createRef();
        originalComponentInstanceRef = React.createRef();

        state = {
            mapboxMap: null
        }

        render = () => {
            return (
                <OriginalComponent
                    mapboxContainerRef={ this.mapboxContainerRef }
                    mapboxMapRef={ this.mapboxMapRef }
                    mapboxMarkerRef={ this.mapboxMarkerRef }
                    originalComponentInstanceRef={ this.originalComponentInstanceRef }
                    mapboxMap={ this.state.mapboxMap }
                    { ...this.props }/>
            );
        };

        componentDidMount = () => {
            if (!this.mapboxContainerRef.current) {
                return;
            }

            this.setMap();
        };

        componentDidUpdate = (prevProps) => {
            const { latitude, longitude } = this.props;

            if (prevProps.latitude !== latitude && prevProps.longitude !== longitude) {
                this.removeListeners();
                this.setMap();
            }
        };

        removeListeners = () => {
            const { mapboxMap } = this.state;
            const originalComponentInstance = this.originalComponentInstanceRef.current;

            mapboxMap
                .off(MISC_MAPBOX_EVENT_LOAD, () => {
                    mapboxMap.setLayoutProperty("country-label", "text-field", ["get", "name_pl"]);
                });
            mapboxMap
                .off(MISC_MAPBOX_EVENT_MOVE, originalComponentInstance._onMapMove);
            mapboxMap
                .off(MISC_MAPBOX_EVENT_MOVEEND, originalComponentInstance._onMapMoveEnd);
        };

        setMap = () => {
            const { latitude, longitude, readOnly, withoutInitialMarker, withFullScreenControl } = this.props;

            const mapboxMap = new mapboxgl.Map({
                container: this.mapboxContainerRef.current,
                style: MISC_MAPBOX_DEFAULT_STYLE,
                center: [longitude, latitude],
                zoom: 13
            });

            this.setState({ mapboxMap });

            mapboxMap.addControl(new mapboxgl.NavigationControl());
            withFullScreenControl && mapboxMap.addControl(new mapboxgl.FullscreenControl());

            this.mapboxMapRef.current = mapboxMap;
            const originalComponentInstance = this.originalComponentInstanceRef.current;

            mapboxMap
                .on(MISC_MAPBOX_EVENT_LOAD, () => {
                    mapboxMap.setLayoutProperty("country-label", "text-field", ["get", "name_pl"]);
                });

            if (originalComponentInstance?._onMapMove && !readOnly) {
                mapboxMap
                    .on(MISC_MAPBOX_EVENT_MOVE, originalComponentInstance._onMapMove);
            }

            if (originalComponentInstance?._onMapMoveEnd && !readOnly) {
                mapboxMap
                    .on(MISC_MAPBOX_EVENT_MOVEEND, originalComponentInstance._onMapMoveEnd);
            }

            if (!withoutInitialMarker) {
                this.mapboxMarkerRef.current = new mapboxgl.Marker()
                    .setLngLat([longitude, latitude])
                    .addTo(mapboxMap);
            }
        };
    }

    return WrappedComponent;
};

export default withMap;