import React from "react";

import MunicipalWasteMenuBins from "./bins";
import MunicipalWasteMenuYears from "./years";
import MunicipalWasteMenuOptions from "./options";
import { isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";

const MunicipalWasteMenu = ({ selectedArea, bins, areas, selectedBin, _onBinSelect, years, selectedYear, _onYearSelect, receptions, _onPublishClicked }) => {

    return (
        <>
            <section className='container is-fluid'>
                <div className="row row--separated row--options row--municipal-waste">

                    {
                        !isNullOrUndefined(years.data) &&
                        !isNullOrUndefined(selectedArea) &&
                        <MunicipalWasteMenuYears
                            selectedArea={ selectedArea }
                            years={ years }
                            selectedYear={ selectedYear }
                            _onYearSelect={ _onYearSelect }/>
                    }

                    <MunicipalWasteMenuOptions
                        receptions={ receptions }
                        bins={ bins }
                        areas={ areas }
                        selectedArea={ selectedArea }
                        selectedYear={ selectedYear }
                        selectedBin={ selectedBin }
                        _onPublishClicked={ _onPublishClicked }/>

                </div>
            </section>

            {
                !isNullOrUndefined(bins) &&
                !bins.meta.loading &&
                <MunicipalWasteMenuBins
                    bins={ bins }
                    _onBinSelect={ _onBinSelect }
                    selectedBin={ selectedBin }/>
            }

        </>
    );
};

export default MunicipalWasteMenu;