import React, { useCallback, useState } from "react";
import AddressGroupsHeader from "./header";
import AddressGroupsList from "./list";
import _ from "lodash";

const EkostraznikAddressGroups = () => {
    const [searchBarTerm, setSearchBarTerm] = useState('');

    const _onSearchBarInputChange = useCallback(
        _.debounce(input => setSearchBarTerm(input || ''), 600),
        []
    );

    return (
        <div className="main-content--table">
            <AddressGroupsHeader
                _onSearchBarInputChange={ _onSearchBarInputChange }
            />
            <AddressGroupsList
                searchBarTerm={ searchBarTerm }
            />
        </div>
    );

};

export default EkostraznikAddressGroups;


