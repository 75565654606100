import { API_BASE_AKUN_EKOSTRAZNIK_PATH } from "../../../core/constants";
import webClient from "../../../core/web_client";

const PATH = `${ API_BASE_AKUN_EKOSTRAZNIK_PATH }/inventories`;

export const addInventory = (data) => {
    return webClient
        .post(PATH, data);
}

export const get = (id) => {
    return webClient
        .get(`${ PATH }/${ id }`);
};

export const deleteMany = (data) => {
    return webClient
        .delete(PATH, { data });
};