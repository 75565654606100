import React, { Component } from "react";
import { connect } from "react-redux";

import FormModal from "../../../../../../common/modal/form_modal";
import BulkyWasteDeleteFormContent from "./content";
import { deleteManyBulkyWaste, getManyBulkyWaste } from "../../../../../../../data/actions/waste_management/bulky_waste";

class BulkyWasteDeleteForm extends Component {

    render = () => {
        const { triggerComponent } = this.props;

        return (
            <FormModal
                triggerComponent={ triggerComponent }
                deleteOrCancelButtons={ true }
                _actionFunction={ () => {
                    return this._onSubmit();
                } }>

                <BulkyWasteDeleteFormContent/>

            </FormModal>
        );
    };

    _onSubmit = () => {
        const { bulkyWasteItem, getManyBulkyWaste, deleteManyBulkyWaste } = this.props;

        return deleteManyBulkyWaste([bulkyWasteItem.id])
            .then(() => getManyBulkyWaste());
    };
}

export default connect(null, { getManyBulkyWaste, deleteManyBulkyWaste })(BulkyWasteDeleteForm);

