import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { ROUTE_PASSWORD_RESET_REQUEST, ROUTE_PASSWORD_RESET_REQUEST_RESET_CONFIRMATION } from "../../../../core/constants";
import { isNullOrUndefined } from "../../../../core/utils/misc_utils";
import { queryParameterValue } from "../../../../core/utils/string_utils";
import { checkPasswordResetRequestTokenStatus } from "../../../../data/actions/common/password_reset_request";
import { PasswordResetRequestEnum } from "../../../../core/enums/common/password_reset_request";
import Loader from "../../../common/loader";
import SignedOutHeader from "../../header";
import PasswordResetForm from "./form";

class PasswordReset extends Component {

    state = {
        visiblePassword: false,
        token: "",
        redirectToConfirmation: false
    };

    render = () => {
        const { passwordResetRequest, location } = this.props;

        if (isNullOrUndefined(passwordResetRequest.data) || passwordResetRequest.meta.loading) {
            return <Loader/>;
        } else if (passwordResetRequest.data === PasswordResetRequestEnum.data.EXPIRED || passwordResetRequest.data === PasswordResetRequestEnum.data.USED) {
            return <Redirect to={ { pathname: ROUTE_PASSWORD_RESET_REQUEST, state: { tokenStatus: passwordResetRequest.data } } }/>;
        } else if (this.state.redirectToConfirmation) {
            return <Redirect to={ { pathname: ROUTE_PASSWORD_RESET_REQUEST_RESET_CONFIRMATION } }/>;
        }

        return (
            <>
                <SignedOutHeader signedOutHeaderText="Utwórz nowe hasło"/>
                <div className="form--signed-out">
                    <PasswordResetForm
                        location={ location }
                        token={ this.state.token }
                        _handleRedirection={ this._handleRedirection }/>
                </div>
            </>
        );
    };

    componentDidMount = () => {
        const { location, checkPasswordResetRequestTokenStatus } = this.props;
        const token = queryParameterValue(location.search, "token");

        this.setState({
            token: token
        });

        checkPasswordResetRequestTokenStatus(token);
    };

    _handleRedirection = () => {
        this.setState({
            redirectToConfirmation: true
        });
    };
}

const mapStateToProps = (state) => {
    return {
        passwordResetRequest: state.entities.common.passwordResetRequest.tokenCheck,

    };
};

export default connect(mapStateToProps, { checkPasswordResetRequestTokenStatus })(PasswordReset);