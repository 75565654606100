import React from "react";

const BlankPage = ({ text, img }) => {

    return (
        <div className="blank-page">
            <div className="blank-page__content">
                <img src={ img } alt=""/>

                <p className="blank-page__text">
                    {
                        text
                    }
                </p>
            </div>
        </div>
    );
};

export default BlankPage;