import get from "./get";
import getExport from "./get_export";


export default (state = {}, action) => {
    return {
        ...state,
        get: get(state.get, action),
        getExport: getExport(state.getExport, action)
    };
}