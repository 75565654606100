import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Formik } from "formik";

import SignedOutHeader from "../header";
import AccountActivationForm from "./form";
import Loader from "../../common/loader";
import { queryParameterValue } from "../../../core/utils/string_utils";
import { activateAccount, getAccountActivationStatus } from "../../../data/actions/common/account";
import {
    isEmptyObject,
    isEmptyString,
    isLoading,
    isLoadingError,
    isNullOrUndefined
} from "../../../core/utils/misc_utils";
import { EmployeesEnum } from "../../../core/enums/common/employees";
import { passwordStrength } from "../../../core/utils/password_utils";
import { ROUTE_SIGN_IN } from "../../../core/constants";

const initialFormValues = {
    activationCode: "",
    password: "",
    passwordRepeat: ""
};

class AccountActivation extends Component {

    state = {
        token: "",
        redirectToSignInScreen: false
    };

    render = () => {
        const { accountActivationStatus } = this.props;
        const { redirectToSignInScreen } = this.state;

        if (redirectToSignInScreen) {
            return (
                <Redirect to={ { pathname: ROUTE_SIGN_IN } }/>
            );
        }

        if (isLoadingError(accountActivationStatus)) {
            // TODO
            return (
                <div>Błąd wczytywania stanu aktywacji konta</div>
            );
        }

        if (isLoading(accountActivationStatus)) {
            return (
                <Loader/>
            );
        }

        if (isNullOrUndefined(accountActivationStatus.data)) {
            return null;
        }

        return (
            <>
                <SignedOutHeader signedOutHeaderText="Aktywacja konta"/>
                <Formik
                    onSubmit={ () => {/** it is required by Formik */} }
                    initialValues={ initialFormValues }
                    render={ formikBag => (
                        <AccountActivationForm
                            accountActivationStatusData={ accountActivationStatus.data }
                            _validatePassword={ this._validatePassword }
                            _onSubmit={ () => this._onSubmit(formikBag)}/>
                    ) }/>
            </>
        );
    };

    componentDidMount = () => {
        const { location, getAccountActivationStatus } = this.props;
        const token = queryParameterValue(location.search, "token");

        getAccountActivationStatus(token)
            .then(() => this.setState({ token }));
    };

    _validatePassword = (field) => {
        let error;
        const strength = passwordStrength(field);
        if (!strength.hasValidLength
            || !strength.hasLowercaseLetter
            || !strength.hasUppercaseLetter
            || !strength.hasNumberOrSpecialCharacter) {
            error = " ";
        }

        return error;
    };

    _validate = (values) => {
        const { accountActivationStatus } = this.props;

        let errors = {};

        if (!isNullOrUndefined(accountActivationStatus) &&
            !isNullOrUndefined(accountActivationStatus.data) &&
            accountActivationStatus.data.position === EmployeesEnum.position.MODERATOR &&
            isEmptyString(values.activationCode.trim())) {
            errors.activationCode = "Pole wymagane.";
        }

        if (!values.passwordRepeat || values.passwordRepeat !== values.password) {
            errors.passwordRepeat = "Wpisane hasła nie są takie same.";
        }

        return errors;
    };

    _onSubmit = (formikBag) => {
        const { activateAccount } = this.props;
        const { activationCode, password } = formikBag.values;
        const { token } = this.state;

        const errors = this._validate(formikBag.values);

        if (!isEmptyObject(errors)) {
            formikBag.setErrors(errors);
            return Promise.reject(null);
        }

        return activateAccount(token, { activationCode, password })
            .then(() => {
                const { activate } = this.props;

                if (isLoadingError(activate)) {
                    // TODO: message depending on code from backend?
                    formikBag.setErrors({ activationCode: "Błędny kod aktywacyjny." });
                    return Promise.reject(null);
                }

                return this.setState({
                    redirectToSignInScreen: true
                });
            });
    };
}

const mapStateToProps = (state) => {
    return {
        activate: state.entities.common.account.activate,
        accountActivationStatus: state.entities.common.account.getActivationStatus
    };
};

export default connect(mapStateToProps, { activateAccount, getAccountActivationStatus })(AccountActivation);
