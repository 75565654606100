import React, { Component } from "react";
import _ from "lodash";

import Loader from "../../../../../common/loader";
import { EkostraznikReportsEnum } from "../../../../../../core/enums/ekostraznik/reports";
import { getWithId, isLoading, isLoadingError, isNullOrUndefined } from "../../../../../../core/utils/misc_utils";
import { getStatusByCode, isDisabled } from "../../../../../../core/enums/waste_management/reports";
import Select, { SelectWithIcons } from "../../../../../common/select";


class ReportDetailsStatus extends Component {

    state = {
        selectedStatusOption: null,
        statusOptions: [],
        selectedEmployeeOption: null,
        employeeOptions: []
    };

    render = () => {
        const { employees, selectedReportId, _onStatusChange, _onEmployeeChange } = this.props;
        const { statusOptions, employeeOptions, selectedStatusOption, selectedEmployeeOption } = this.state;

        if (isLoadingError(employees)) {
            return (
                <div>
                    Błąd wczytywania pracowników
                </div>
            );
        }

        if (isLoading(employees)) {
            return (
                <Loader/>
            );
        }

        return (
            <div className="report-details__section">
                <div className="report-details__row report-details__row--select">
                    <p className="report-details__label report-details__label--select">
                        Moderator:
                    </p>
                    <div>
                        <div className="report-details__field-input">
                            <Select
                                value={ selectedEmployeeOption }
                                options={ employeeOptions }
                                isClearable={ false }
                                onChange={ employeeOption => _onEmployeeChange(selectedReportId, { command: EkostraznikReportsEnum.commands.changeEmployee, employeeId: employeeOption.id }) }/>
                        </div>
                    </div>
                </div>

                <div className="report-details__row report-details__row--select">
                    <p className="report-details__label report-details__label--select">
                        Status:
                    </p>
                    <div>
                        <div className="report-details__field-input">
                            <SelectWithIcons
                                value={ selectedStatusOption }
                                options={ statusOptions }
                                isClearable={ false }
                                onChange={ statusOption => _onStatusChange(selectedReportId, { command: EkostraznikReportsEnum.commands.changeStatus, status: statusOption.id }) }/>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    componentDidMount = () => {
        const { selectedStatus, selectedEmployee } = this.props;

        const employeeOptions = this.generateEmployeeOptions();
        const selectedEmployeeOption = getWithId(employeeOptions, selectedEmployee.id);

        const statusOptions = this.generateStatusOptions();
        const selectedStatusOption = getWithId(statusOptions, selectedStatus.toLowerCase());

        this.setState({
            selectedStatusOption,
            statusOptions,
            selectedEmployeeOption,
            employeeOptions
        });
    };

    generateStatusOptions = () => {
        const { statuses, selectedStatus } = this.props;

        if (isNullOrUndefined(statuses) || isNullOrUndefined(statuses.data)) {
            return [];
        }

        return _.map(statuses.data, status => {
            const statusEnum = getStatusByCode(status.code);
            const disabled = isDisabled(statusEnum, getStatusByCode(selectedStatus.code));

            return {
                id: status.code,
                label: status.name,
                value: status,
                isDisabled: disabled,
                icon: !isNullOrUndefined(statusEnum) ? statusEnum.icon : null
            };
        });
    };

    generateEmployeeOptions = () => {
        const { employees, selectedReport } = this.props;

        if (isNullOrUndefined(employees) || isNullOrUndefined(employees.data)) {
            return [];
        }

        let normalizedEmployees = _.map(employees.data, employee => {
            return {
                id: employee.id,
                label: employee.name,
                value: employee
            };
        });

        const selectedReportEmployee = selectedReport.data.employee;
        if (selectedReportEmployee) { //assigned employee should be shown even if isn't active
            if (!_.find(normalizedEmployees, e => e.id === selectedReportEmployee.id)) {
                normalizedEmployees.push({
                    id: selectedReportEmployee.id,
                    label: selectedReportEmployee.name,
                    value: selectedReportEmployee,
                });
            }
        }

        return normalizedEmployees;
    };
}

export default ReportDetailsStatus;
