import { ToastEnum } from "../enums/common/toast";
import { is4xx, is5xx } from "./api_utils";


export const toastFor500 = (showToast, targetName, text) => {
    if(!targetName.meta.loading && is5xx(targetName.meta.status)){
        showToast(ToastEnum.type.ERROR, text)
    }
};

export const toastFor400 = (showToast, targetName, text) => {
    if(!targetName.meta.loading && is4xx(targetName.meta.status)){
        showToast(ToastEnum.type.ERROR, text)
    }
};


export const toastForSuccess = (showToast, targetName, text) => {
    if(!targetName.meta.loading && !targetName.error){
        return showToast(ToastEnum.type.INFO, text)
    }
};

export const toastForCustomError = (showToast, text) => {
    return showToast(ToastEnum.type.ERROR, text);
};

export const toastForCustomSuccess = (showToast, text) => {
    return showToast(ToastEnum.type.INFO, text);
};
