import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import moment from "moment";
import "moment/locale/pl";

import FormModal from "../../../../../common/modal/form_modal";
import CreateAndEditReportModalFormContent from "./content";
import { ReactComponent as IconAdd } from "../../../../../../../resource/img/icons/icon_add_white.svg";
import { MISC_DATE_ISO_DATE_ONLY_FORMAT, MISC_DATE_ISO_FORMAT } from "../../../../../../core/constants";
import { ReportEnum } from "../../../../../../core/enums/waste_management/reports";
import { reverseGeocoding } from "../../../../../../data/actions/common/geocoding";
import { addReport, getManyReports } from "../../../../../../data/actions/waste_management/reports";
import { isEmptyArray, isEmptyObject, isEmptyString, isNullOrUndefined } from "../../../../../../core/utils/misc_utils";
import { toastFor500, toastForSuccess } from "../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../data/actions/common/ui/toast";
import _ from "lodash";

const initialFormValues = {
    localization: "",
    longitude: "",
    latitude: "",
    subject: "",
    additionalDate: "",
    description: "",
    binIds: []
};

class CreateAndEditReportModalForm extends Component {

    state = {
        selectedType: "",
        typeOptions: []
    };

    render = () => {
        const { bins, geocoding, reverseGeocoding } = this.props;
        const { selectedType, typeOptions } = this.state;

        return (
            <Formik
                initialValues={ this._fixDefaultFormValues() }
                render={ formikBag =>

                    <FormModal
                        triggerComponent={
                            <button className="btn btn--big btn--primary">
                                <span className="btn__icon-wrapper--left">
                                    <span className='btn__icon'>
                                        <IconAdd/>
                                    </span>
                                    Dodaj zgłoszenie
                                </span>
                            </button>
                        }
                        headerText="Dodaj zgłoszenie"
                        overflow={ true }
                        saveOrCancelButtons={ true }
                        _actionFunction={ () => {
                            return this._onSubmit(formikBag);
                        } }>

                        <CreateAndEditReportModalFormContent
                            bins={ bins }
                            geocoding={ geocoding }
                            reverseGeocoding={ reverseGeocoding }
                            selectedType={ selectedType }
                            typeOptions={ typeOptions }
                            formikBag={ formikBag }
                            _handleSelectedTypeChange={ this._handleSelectedTypeChange }/>

                    </FormModal>
                }/>
        );
    };

    componentDidMount = () => {
        this.setState({
            selectedType: this.generateTypeOptions()[0],
            typeOptions: this.generateTypeOptions()
        })
    };

    generateTypeOptions = () => {
        const { reportTypesGet } = this.props;

        return _.map(reportTypesGet.data, type => {
            return {
                label: type.name,
                value: type.code
            };
        });
    };

    _fixDefaultFormValues = () => {
        const { defaultCoordinates } = this.props;

        return {
            ...initialFormValues,
            additionalDate: moment()
                .format(MISC_DATE_ISO_DATE_ONLY_FORMAT),
            latitude: defaultCoordinates.latitude,
            longitude: defaultCoordinates.longitude
        };
    };

    _handleSelectedTypeChange = (type, formikBag) => {
        this.setState({
            selectedType: type
        }, () => {
            formikBag.setErrors({});
        });
    };

    _validate = (values) => {
        // TODO: error messages
        const { selectedType } = this.state;
        const { localization, longitude, latitude, subject, additionalDate, description, binIds } = values;

        const errors = {};

        switch (selectedType?.value) {

            case ReportEnum.type.UNCOLLECTED_WASTE.code:
                if (isEmptyString(localization)) {
                    errors.localization = "";
                }

                if (isEmptyString(additionalDate)) {
                    errors.additionalDate = "";
                }

                if (isEmptyArray(binIds)) {
                    errors.binIds = "";
                }

                break;

            case ReportEnum.type.WILD_DUMP.code:
                if (isNullOrUndefined(longitude) || isNullOrUndefined(latitude)) {
                    errors.longitude = "";
                    errors.latitude = "";
                }

                break;

            case ReportEnum.type.OTHER.code:
                if (isNullOrUndefined(longitude) || isNullOrUndefined(latitude)) {
                    errors.longitude = "";
                    errors.latitude = "";
                }

                if (isEmptyString(localization)) {
                    errors.localization = "";
                }

                if (isEmptyString(description)) {
                    errors.description = "";
                }

                if (isEmptyString(subject)) {
                    errors.subject = "";
                }

                break;

            case ReportEnum.type.ORDER_CONTAINER.code:
                if (isEmptyString(localization)) {
                    errors.localization = "";
                }

                break;

            case ReportEnum.type.ORDER_TRASHBAG.code:
                if (isEmptyString(localization)) {
                    errors.localization = "";
                }

                if (isEmptyArray(binIds)) {
                    errors.binIds = "";
                }

                break;

            case ReportEnum.type.ORDER_BIN.code:
                if (isEmptyString(localization)) {
                    errors.localization = "";
                }

                if (isEmptyArray(binIds)) {
                    errors.binIds = "";
                }

                break;

            case ReportEnum.type.SMOKE.code:
                if (isEmptyArray(localization)) {
                    errors.localization = "";
                }

                if (isNullOrUndefined(additionalDate)) {
                    errors.additionalDate = "";
                }

                break;

            default:
            // empty
        }

        return errors;
    };

    _onSubmit = (formikBag) => {
        const { selectedType } = this.state;
        const { addReport, getManyReports, showToast, defaultPaginationQueryValues } = this.props;

        const errors = this._validate(formikBag.values);
        if (!isEmptyObject(errors)) {
            formikBag.setErrors(errors);
            return Promise.reject(null);
        }

        return addReport(selectedType, {
            ...formikBag.values,
            additionalDate: moment(formikBag.values.additionalDate, MISC_DATE_ISO_FORMAT)
        })
            .then(() => getManyReports(defaultPaginationQueryValues))
            .then( () => {
                const { reportsAdd } = this.props;

                toastFor500(showToast, reportsAdd, "Dodanie zgłoszenia nie powiodło się. Spróbuj ponownie");
                toastForSuccess(showToast, reportsAdd, "Nowe zgłoszenie zostało dodane");
            });
    };
}

const mapStateToProps = (state) => {
    return {
        defaultCoordinates: state.entities.common.owners.getCoordinates,
        geocoding: state.entities.common.geocoding,
        reportsAdd: state.entities.wasteManagement.reports.add,
        reportTypesGet: state.entities.wasteManagement.reports.types.get
    };
};

export default connect(mapStateToProps, { addReport, getManyReports, reverseGeocoding, showToast })(CreateAndEditReportModalForm);
