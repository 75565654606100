import React, { Component } from "react";
import { connect } from "react-redux";

import SideListLayout from "../../../../common/side_list_layout/side_list_layout";
import ReportDetails from "./details";
import CreateEkostraznikReportModalForm from "./create_modal_form";
import {
    getManyReports,
    getReport,
    getReportTypes,
    updateReport
} from "../../../../../data/actions/ekostraznik/reports";
import { getManyReportEmployees } from "../../../../../data/actions/waste_management/reports/emplyees";
import { showToast } from "../../../../../data/actions/common/ui/toast";
import { getManyBins } from "../../../../../data/actions/waste_management/bins";

import selectReportImg from "../../../../../../resource/img/icons/empty_list_icons/wybierz_zgloszenie.png";
import noReportsImg from "../../../../../../resource/img/icons/empty_list_icons/brak_zgloszen.png";
import { EkostraznikReportsEnum } from "../../../../../core/enums/ekostraznik/reports";
import withPagination from "../../../../common/hoc/with_pagination";
import Loader from "../../../../common/loader";
import { isLoadingError, isNullOrUndefined } from "../../../../../core/utils/misc_utils";
import { generatePaginationQueryValues } from "../../../../../core/utils/api_utils";
import { ReactComponent as IconEllipsis } from "../../../../../../resource/img/icons/icon_ellipsis.svg";
import DropdownMenu from "../../../../common/dropdown_menu";
import QuantitativeReportModalForm from "./quantitative_report_modal_form";

class EkostraznikReports extends Component {

    state = {
        selectedReportId: null
    };

    defaultPaginationQueryValues = generatePaginationQueryValues(0, 30, ["createdDate", "desc"]);

    render = () => {
        const { reports, _onPrevClick, _onNextClick, _onPageNumberInputValueChange } = this.props;
        const { selectedReportId } = this.state;

        //TODO error info

        if (isLoadingError(reports)) {
            return (
                // TODO
                <div>
                    Wystąpił błąd
                </div>
            );
        }

        if (isNullOrUndefined(reports.data)) {
            return <Loader/>;
        }

        return (
            <article className="reports">
                <header className="row page-header">
                    <h1 className="title is-1">
                        Zgłoszenia
                    </h1>
                </header>
                <div className="container is-fluid">
                    <div className="row row--separated row--options">
                        <div className="row__left" />
                        <div className="row__right">
                            <CreateEkostraznikReportModalForm
                                _onSubmitCallback={ this._onSubmitCallback }
                                defaultPaginationQueryValues={ this.defaultPaginationQueryValues }
                            />

                            <span className='is-spaced--left-10'>

                                <DropdownMenu
                                    triggerComponent={<button
                                        className="btn btn--outlined btn--icon"
                                        title='Więcej'
                                    >
                                        <IconEllipsis/>
                                    </button>}
                                >
                                    <QuantitativeReportModalForm />
                                </DropdownMenu>
                            </span>
                        </div>
                    </div>
                </div>
                <SideListLayout
                    data={ reports.data }
                    list={ reports.data.content }
                    selectedListItem={ selectedReportId }
                    _onListItemClick={ this._onReportClick }
                    listType={ EkostraznikReportsEnum.name }
                    renderIconFunction={ this.renderEkoReportTypeIcon }

                    _onPrevClick={ _onPrevClick }
                    _onNextClick={ _onNextClick }
                    _onPageNumberInputValueChange={ _onPageNumberInputValueChange }

                    noListText="Brak zgłoszeń"
                    noListImg={ noReportsImg }
                    selectItemText="Wybierz zgłoszenie"
                    selectItemImg={ selectReportImg }>
                    {
                        this.renderDetails()
                    }
                </SideListLayout>
            </article>
        );
    };

    componentDidMount = () => {
        const { getManyReports, getManyBins, currentPage, setMaxPage, getReportTypes } = this.props;
        getManyBins();
        getReportTypes();
        getManyReports({ ...this.defaultPaginationQueryValues, page: currentPage })
            .then(() => {
                    if (!isNullOrUndefined(this.props.reports.data)) {
                        setMaxPage(this.props.reports.data.totalPages);

                    }
                }
            );
    };

    shouldComponentUpdate = (nextProps, nextState) => {
        const { currentPage, reports, getManyReports } = this.props;

        if (nextProps.currentPage !== currentPage) {
            getManyReports({ ...this.defaultPaginationQueryValues, page: nextProps.currentPage });
            return true;
        }

        if (nextProps.reports !== reports) {
            return true;
        }

        if (nextState !== this.state) {
            return true;
        }

        if (nextProps !== this.props) {
            return true;
        }

        return false;
    };

    renderDetails = () => {
        const { employees, bins, statuses, selectedReport } = this.props;
        const { selectedReportId } = this.state;

        return (
            <ReportDetails
                employees={ employees }
                statuses={ statuses }
                bins={ bins }
                selectedReportId={ selectedReportId }
                selectedReport={ selectedReport }
                _onEmployeeChange={ this._onEmployeeChange }
                _onStatusChange={ this._onStatusChange }
                _onClearSelectedReport={ this._onClearSelectedReport }/>
        );
    };

    _onSubmitCallback = () => {
        const { currentPage, getManyReports } = this.props;

        getManyReports({ ...this.defaultPaginationQueryValues, page: currentPage })
    };

    _onReportClick = (id) => {
        const { getReport, getManyReportEmployees, getManyReports, currentPage } = this.props;

        getManyReportEmployees(id);
        getReport(id)
            .then(() => getManyReports({ ...this.defaultPaginationQueryValues, page: currentPage }))
            .then(() => {
                this.setState({
                    selectedReportId: id
                })
            });
    };

    _onEmployeeChange = (reportId, data) => {
        const { getReport, updateReport, getManyReports, currentPage } = this.props;

        return updateReport(reportId, data)
            .then(() => getReport(reportId))
            .then(() => getManyReports({ ...this.defaultPaginationQueryValues, page: currentPage }));
    };

    _onStatusChange = (reportId, data) => {
        const { getReport, updateReport, getManyReports, currentPage } = this.props;

        return updateReport(reportId, data)
            .then(() => getReport(reportId))
            .then(() => getManyReports({ ...this.defaultPaginationQueryValues, page: currentPage }));
    };

    _onClearSelectedReport = () => {
        return this.setState({
            selectedReportId: null,
        });
    };

    renderEkoReportTypeIcon = (listItem, listType) => {

        if (listType === EkostraznikReportsEnum.name) {
            switch (listItem.type) {
                case EkostraznikReportsEnum.type.MISSING_WASTE_BIN.code:
                    return EkostraznikReportsEnum.type.MISSING_WASTE_BIN.icon;

                case EkostraznikReportsEnum.type.DAMAGED_WASTE_BIN.code:
                    return EkostraznikReportsEnum.type.DAMAGED_WASTE_BIN.icon;

                case EkostraznikReportsEnum.type.NO_ACCESS_TO_BIN.code:
                    return EkostraznikReportsEnum.type.NO_ACCESS_TO_BIN.icon;

                case EkostraznikReportsEnum.type.WASTE_BIN_CAPACITY_EXCEEDED.code:
                    return EkostraznikReportsEnum.type.WASTE_BIN_CAPACITY_EXCEEDED.icon;

                case EkostraznikReportsEnum.type.OTHER.code:
                    return EkostraznikReportsEnum.type.OTHER.icon;
                default:
                    return "";
            }
        }
    };
}

const mapStateToProps = (state) => {
    return {
        reports: state.entities.ekostraznik.reports.getMany,
        reportTypes: state.entities.ekostraznik.reports.getTypes,
        bins: state.entities.wasteManagement.bins.getMany,
        selectedReport: state.entities.ekostraznik.reports.get,
        employees: state.entities.wasteManagement.reports.employees.getMany,
        statuses: state.entities.wasteManagement.reports.statuses.getMany
    };
};

export default withPagination(connect(mapStateToProps, { getManyReports, getReportTypes, getReport, updateReport, getManyBins, getManyReportEmployees, showToast })(EkostraznikReports));
