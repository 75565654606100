import React from "react";
import MunicipalWasteHeaderAreaSelect from "./area_select";

const MunicipalWasteHeader = ({ areas, selectedArea, _onAreaSelect }) => {

    return (
        <section className=''>
            <header className='page-header'>
                <div className='header--with-select'>
                    <h1 className='title is-1'>
                        Harmonogram odbioru odpadów komunalnych
                    </h1>
                </div>

                <MunicipalWasteHeaderAreaSelect
                    areas={ areas }
                    selectedArea={ selectedArea }
                    _onAreaSelect={ _onAreaSelect }/>

            </header>
        </section>
    );
};

export default MunicipalWasteHeader;
