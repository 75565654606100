import React, { Component } from "react";

import BulkyWasteCreateAndEditModalForm from "../create_and_edit_modal_form";
import BulkyWasteDeleteForm from "../delete_modal_form";

import { ReactComponent as IconEdit } from "../../../../../../../../resource/img/icons/icon_edit.svg";
import { ReactComponent as IconDelete } from "../../../../../../../../resource/img/icons/icon_delete.svg";
import { arrayValuesAsString, isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";
import { BulkyWasteEnum } from "../../../../../../../core/enums/waste_management/bulky_waste";
import { MISC_SHOW_UNFINISHED_ELEMENTS } from "../../../../../../../core/constants";

class BulkyWasteCurrentListItem extends Component {

    render = () => {
        const { formikBag, bulkyWasteItem } = this.props;

        return (
            <tr>
                <td className='t-width-3'>

                    {
                        MISC_SHOW_UNFINISHED_ELEMENTS &&
                        <div className='t-checkbox'>
                            <label className='acceptance-label'>
                                <input type='checkbox' className='acceptance-checkbox'/>
                            </label>
                        </div>
                    }

                    {
                        this.renderBulkyWasteLocation(bulkyWasteItem)
                    }

                </td>
                <td className='t-width-4'>

                    {
                        this.renderBulkyWasteDates(bulkyWasteItem)
                    }

                </td>
                <td className='t-width-4'>
                    <p className="table-text-content"
                       title={ bulkyWasteItem.description }>
                        {
                            bulkyWasteItem.description
                        }
                    </p>
                </td>
                <td className='sticky--right td-action-buttons'>

                    <BulkyWasteCreateAndEditModalForm
                        bulkyWasteItem={ bulkyWasteItem }
                        formikBag={ formikBag }
                        triggerComponent={ <button className='unstyled-btn'><IconEdit/></button> }
                        headerText={ "Edycja" }
                        saveOrCancelButtons={ true }/>

                    <BulkyWasteDeleteForm
                        bulkyWasteItem={ bulkyWasteItem }
                        formikBag={ formikBag }
                        triggerComponent={ <button className='unstyled-btn'><IconDelete/></button> }/>
                </td>
            </tr>
        );
    };

    renderBulkyWasteLocation = (bulkyWasteItem) => {
        if (bulkyWasteItem.status === BulkyWasteEnum.status.NEW) {
            return (
                <div className="table-text-with-badge">
                    <p className="table-text-content is-bold" title={ bulkyWasteItem.location }>
                        {
                            bulkyWasteItem.location
                        }
                    </p>
                    <span className="is-uppercase f-12">
                        Do publikacji
                    </span>
                </div>
            );
        } else {
            return (
                <p className="table-text-content" title={ bulkyWasteItem.location }>
                    {
                        bulkyWasteItem.location
                    }
                </p>
            );
        }
    };

    renderBulkyWasteDates = (bulkyWasteItem) => {
        if (isNullOrUndefined(bulkyWasteItem.dates)) {
            return null;
        }

        const bulkyWasteDateItem = bulkyWasteItem.dates.map(date => (
            <span key={ date } className="single-date">{ date }</span>
        ));

        return (
            <p className="table-text-content" title={ arrayValuesAsString(bulkyWasteItem.dates) }>
                {
                    bulkyWasteDateItem
                }
            </p>
        );
    };
}

export default BulkyWasteCurrentListItem;