import React from "react";

import { isNullOrUndefined } from "../../../../../../../../core/utils/misc_utils";
import Select from "../../../../../../../common/select";


const MunicipalWasteMenuBinsSelect = ({ binsUnmapped, selectedBin, _onBinSelect }) => {

    if (isNullOrUndefined(binsUnmapped)) {
        return null;
    }

    const binsList = binsUnmapped.map(bin => {
        return {
            value: bin.id,
            label: bin.name,
        };
    });

    return (
        <Select
            options={ binsList }
            value={ findSelectedValue(binsList, selectedBin) }
            isClearable={ false }
            onChange={ option => _onBinSelect(option.value) }
        />
    );
};

const findSelectedValue = (binsList, selectedBin) => {
    for (let i = 0; i < binsList.length; i++) {
        if (binsList[i].value === selectedBin) {
            return binsList[i];
        }
    }

    return null;
};

export default MunicipalWasteMenuBinsSelect;