const PREFIX = "waste_management.";

export const API__AREAS__GET_MANY__STARTED = `${ PREFIX }API__AREAS__GET_MANY__STARTED`;
export const API__AREAS__GET_MANY__SUCCESS = `${ PREFIX }API__AREAS__GET_MANY__SUCCESS`;
export const API__AREAS__GET_MANY__FAIL = `${ PREFIX }API__AREAS__GET_MANY__FAIL`;

export const API__AREAS__GET_MANY_PAGINATED__STARTED = `${ PREFIX }API__AREAS__GET_MANY_PAGINATED__STARTED`;
export const API__AREAS__GET_MANY_PAGINATED__SUCCESS = `${ PREFIX }API__AREAS__GET_MANY_PAGINATED__SUCCESS`;
export const API__AREAS__GET_MANY_PAGINATED__FAIL = `${ PREFIX }API__AREAS__GET_MANY_PAGINATED__FAIL`;

export const API__AREAS__GET__STARTED = `${ PREFIX }API__AREAS__GET__STARTED`;
export const API__AREAS__GET__SUCCESS = `${ PREFIX }API__AREAS__GET__SUCCESS`;
export const API__AREAS__GET__FAIL = `${ PREFIX }API__AREAS__GET__FAIL`;

export const API__AREAS__ADD__STARTED = `${ PREFIX }API__AREAS__ADD__STARTED`;
export const API__AREAS__ADD__SUCCESS = `${ PREFIX }API__AREAS__ADD__SUCCESS`;
export const API__AREAS__ADD__FAIL = `${ PREFIX }API__AREAS__ADD__FAIL`;

export const API__AREAS__IMPORT__STARTED = `${ PREFIX }API__AREAS__IMPORT__STARTED`;
export const API__AREAS__IMPORT__SUCCESS = `${ PREFIX }API__AREAS__IMPORT__SUCCESS`;
export const API__AREAS__IMPORT__FAIL = `${ PREFIX }API__AREAS__IMPORT__FAIL`;

export const API__AREAS__DOWNLOAD_TEMPLATE__STARTED = `${ PREFIX }API__AREAS__DOWNLOAD_TEMPLATE__STARTED`;
export const API__AREAS__DOWNLOAD_TEMPLATE__SUCCESS = `${ PREFIX }API__AREAS__DOWNLOAD_TEMPLATE__SUCCESS`;
export const API__AREAS__DOWNLOAD_TEMPLATE__FAIL = `${ PREFIX }API__AREAS__DOWNLOAD_TEMPLATE__FAIL`;

export const API__AREAS__UPDATE__STARTED = `${ PREFIX }API__AREAS__UPDATE__STARTED`;
export const API__AREAS__UPDATE__SUCCESS = `${ PREFIX }API__AREAS__UPDATE__SUCCESS`;
export const API__AREAS__UPDATE__FAIL = `${ PREFIX }API__AREAS__UPDATE__FAIL`;

export const API__AREAS__DELETE__STARTED = `${ PREFIX }API__AREAS__DELETE__STARTED`;
export const API__AREAS__DELETE__SUCCESS = `${ PREFIX }API__AREAS__DELETE__SUCCESS`;
export const API__AREAS__DELETE__FAIL = `${ PREFIX }API__AREAS__DELETE__FAIL`;

export const API__AREA_LOCATIONS__GET_MANY__STARTED = `${ PREFIX }API__AREA_LOCATIONS__GET_MANY__STARTED`;
export const API__AREA_LOCATIONS__GET_MANY__SUCCESS = `${ PREFIX }API__AREA_LOCATIONS__GET_MANY__SUCCESS`;
export const API__AREA_LOCATIONS__GET_MANY__FAIL = `${ PREFIX }API__AREA_LOCATIONS__GET_MANY__FAIL`;

export const API__AREA_LOCATIONS__PATCH_MANY__STARTED = `${ PREFIX }API__AREA_LOCATIONS__PATCH_MANY__STARTED`;
export const API__AREA_LOCATIONS__PATCH_MANY__SUCCESS = `${ PREFIX }API__AREA_LOCATIONS__PATCH_MANY__SUCCESS`;
export const API__AREA_LOCATIONS__PATCH_MANY__FAIL = `${ PREFIX }API__AREA_LOCATIONS__PATCH_MANY__FAIL`;

export const API__AREA_MUNICIPAL_WASTE_RECEPTIONS__GET_MANY__STARTED = `${ PREFIX }API__AREA_MUNICIPAL_WASTE_RECEPTIONS__GET_MANY__STARTED`;
export const API__AREA_MUNICIPAL_WASTE_RECEPTIONS__GET_MANY__SUCCESS = `${ PREFIX }API__AREA_MUNICIPAL_WASTE_RECEPTIONS__GET_MANY__SUCCESS`;
export const API__AREA_MUNICIPAL_WASTE_RECEPTIONS__GET_MANY__FAIL = `${ PREFIX }API__AREA_MUNICIPAL_WASTE_RECEPTIONS__GET_MANY__FAIL`;

export const API__AREA_MUNICIPAL_WASTE_RECEPTIONS__ADD__STARTED = `${ PREFIX }API__AREA_MUNICIPAL_WASTE_RECEPTIONS__ADD__STARTED`;
export const API__AREA_MUNICIPAL_WASTE_RECEPTIONS__ADD__SUCCESS = `${ PREFIX }API__AREA_MUNICIPAL_WASTE_RECEPTIONS__ADD__SUCCESS`;
export const API__AREA_MUNICIPAL_WASTE_RECEPTIONS__ADD__FAIL = `${ PREFIX }API__AREA_MUNICIPAL_WASTE_RECEPTIONS__ADD__FAIL`;

export const API__AREA_MUNICIPAL_WASTE_RECEPTIONS__UPDATE_STATUS__STARTED = `${ PREFIX }API__AREA_MUNICIPAL_WASTE_RECEPTIONS__UPDATE_STATUS__STARTED`;
export const API__AREA_MUNICIPAL_WASTE_RECEPTIONS__UPDATE_STATUS__SUCCESS = `${ PREFIX }API__AREA_MUNICIPAL_WASTE_RECEPTIONS__UPDATE_STATUS__SUCCESS`;
export const API__AREA_MUNICIPAL_WASTE_RECEPTIONS__UPDATE_STATUS__FAIL = `${ PREFIX }API__AREA_MUNICIPAL_WASTE_RECEPTIONS__UPDATE_STATUS__FAIL`;

export const API__AREA_MUNICIPAL_WASTE_RECEPTIONS__UNPUBLISH__STARTED = `${ PREFIX }API__AREA_MUNICIPAL_WASTE_RECEPTIONS__UNPUBLISH__STARTED`;
export const API__AREA_MUNICIPAL_WASTE_RECEPTIONS__UNPUBLISH__SUCCESS = `${ PREFIX }API__AREA_MUNICIPAL_WASTE_RECEPTIONS__UNPUBLISH__SUCCESS`;
export const API__AREA_MUNICIPAL_WASTE_RECEPTIONS__UNPUBLISH__FAIL = `${ PREFIX }API__AREA_MUNICIPAL_WASTE_RECEPTIONS__UNPUBLISH__FAIL`;

export const API__AREA_MUNICIPAL_WASTE_RECEPTIONS__PUBLISH_CHANGES__STARTED = `${ PREFIX }API__AREA_MUNICIPAL_WASTE_RECEPTIONS__PUBLISH_CHANGES__STARTED`;
export const API__AREA_MUNICIPAL_WASTE_RECEPTIONS__PUBLISH_CHANGES__SUCCESS = `${ PREFIX }API__AREA_MUNICIPAL_WASTE_RECEPTIONS__PUBLISH_CHANGES__SUCCESS`;
export const API__AREA_MUNICIPAL_WASTE_RECEPTIONS__PUBLISH_CHANGES__FAIL = `${ PREFIX }API__AREA_MUNICIPAL_WASTE_RECEPTIONS__PUBLISH_CHANGES__FAIL`;

export const API__AREA_MUNICIPAL_WASTE_YEARS__GET_MANY__STARTED = `${ PREFIX }API__AREA_MUNICIPAL_WASTE_YEARS__GET_MANY__STARTED`;
export const API__AREA_MUNICIPAL_WASTE_YEARS__GET_MANY__SUCCESS = `${ PREFIX }API__AREA_MUNICIPAL_WASTE_YEARS__GET_MANY__SUCCESS`;
export const API__AREA_MUNICIPAL_WASTE_YEARS__GET_MANY__FAIL = `${ PREFIX }API__AREA_MUNICIPAL_WASTE_YEARS__GET_MANY__FAIL`;

export const API__AREA_MUNICIPAL_WASTE_YEARS__ADD__STARTED = `${ PREFIX }API__AREA_MUNICIPAL_WASTE_YEARS__ADD__STARTED`;
export const API__AREA_MUNICIPAL_WASTE_YEARS__ADD__SUCCESS = `${ PREFIX }API__AREA_MUNICIPAL_WASTE_YEARS__ADD__SUCCESS`;
export const API__AREA_MUNICIPAL_WASTE_YEARS__ADD__FAIL = `${ PREFIX }API__AREA_MUNICIPAL_WASTE_YEARS__ADD__FAIL`;

export const API__BINS__GET_MANY__STARTED = `${ PREFIX }API__BINS__GET_MANY__STARTED`;
export const API__BINS__GET_MANY__SUCCESS = `${ PREFIX }API__BINS__GET_MANY__SUCCESS`;
export const API__BINS__GET_MANY__FAIL = `${ PREFIX }API__BINS__GET_MANY__FAIL`;

export const API__BINS__ADD__STARTED = `${ PREFIX }API__BINS__ADD__STARTED`;
export const API__BINS__ADD__SUCCESS = `${ PREFIX }API__BINS__ADD__SUCCESS`;
export const API__BINS__ADD__FAIL = `${ PREFIX }API__BINS__ADD__FAIL`;

export const API__BINS__DELETE__STARTED = `${ PREFIX }API__BINS__DELETE__STARTED`;
export const API__BINS__DELETE__SUCCESS = `${ PREFIX }API__BINS__DELETE__SUCCESS`;
export const API__BINS__DELETE__FAIL = `${ PREFIX }API__BINS__DELETE__FAIL`;

export const API__BULKY_WASTE__GET_MANY__STARTED = `${ PREFIX }API__BULKY_WASTE__GET_MANY__STARTED`;
export const API__BULKY_WASTE__GET_MANY__SUCCESS = `${ PREFIX }API__BULKY_WASTE__GET_MANY__SUCCESS`;
export const API__BULKY_WASTE__GET_MANY__FAIL = `${ PREFIX }API__BULKY_WASTE__GET_MANY__FAIL`;

export const API__BULKY_WASTE__ADD__STARTED = `${ PREFIX }API__BULKY_WASTE__ADD__STARTED`;
export const API__BULKY_WASTE__ADD__SUCCESS = `${ PREFIX }API__BULKY_WASTE__ADD__SUCCESS`;
export const API__BULKY_WASTE__ADD__FAIL = `${ PREFIX }API__BULKY_WASTE__ADD__FAIL`;

export const API__BULKY_WASTE__DELETE_MANY__STARTED = `${ PREFIX }API__BULKY_WASTE__DELETE_MANY__STARTED`;
export const API__BULKY_WASTE__DELETE_MANY__SUCCESS = `${ PREFIX }API__BULKY_WASTE__DELETE_MANY__SUCCESS`;
export const API__BULKY_WASTE__DELETE_MANY__FAIL = `${ PREFIX }API__BULKY_WASTE__DELETE_MANY__FAIL`;

export const API__BULKY_WASTE__UPDATE__STARTED = `${ PREFIX }API__BULKY_WASTE__UPDATE__STARTED`;
export const API__BULKY_WASTE__UPDATE__SUCCESS = `${ PREFIX }API__BULKY_WASTE__UPDATE__SUCCESS`;
export const API__BULKY_WASTE__UPDATE__FAIL = `${ PREFIX }API__BULKY_WASTE__UPDATE__FAIL`;

export const API__BULKY_WASTE__PUBLISH_MANY__STARTED = `${ PREFIX }API__BULKY_WASTE__PUBLISH_MANY__STARTED`;
export const API__BULKY_WASTE__PUBLISH_MANY__SUCCESS = `${ PREFIX }API__BULKY_WASTE__PUBLISH_MANY__SUCCESS`;
export const API__BULKY_WASTE__PUBLISH_MANY__FAIL = `${ PREFIX }API__BULKY_WASTE__PUBLISH_MANY__FAIL`;

export const API__BULKY_WASTE_CONFIG__GET__STARTED = `${ PREFIX }API__BULKY_WASTE_CONFIG__GET__STARTED`;
export const API__BULKY_WASTE_CONFIG__GET__SUCCESS = `${ PREFIX }API__BULKY_WASTE_CONFIG__GET__SUCCESS`;
export const API__BULKY_WASTE_CONFIG__GET__FAIL = `${ PREFIX }API__BULKY_WASTE_CONFIG__GET__FAIL`;

export const API__COMPANIES__GET_MANY__STARTED = `${ PREFIX }API__COMPANIES__GET_MANY__STARTED`;
export const API__COMPANIES__GET_MANY__SUCCESS = `${ PREFIX }API__COMPANIES__GET_MANY__SUCCESS`;
export const API__COMPANIES__GET_MANY__FAIL = `${ PREFIX }API__COMPANIES__GET_MANY__FAIL`;

export const API__COMPANIES__GET__STARTED = `${ PREFIX }API__COMPANIES__GET__STARTED`;
export const API__COMPANIES__GET__SUCCESS = `${ PREFIX }API__COMPANIES__GET__SUCCESS`;
export const API__COMPANIES__GET__FAIL = `${ PREFIX }API__COMPANIES__GET__FAIL`;

export const API__COMPANIES__ADD__STARTED = `${ PREFIX }API__COMPANIES__ADD__STARTED`;
export const API__COMPANIES__ADD__SUCCESS = `${ PREFIX }API__COMPANIES__ADD__SUCCESS`;
export const API__COMPANIES__ADD__FAIL = `${ PREFIX }API__COMPANIES__ADD__FAIL`;

export const API__COMPANIES__DELETE__STARTED = `${ PREFIX }API__COMPANIES__DELETE__STARTED`;
export const API__COMPANIES__DELETE__SUCCESS = `${ PREFIX }API__COMPANIES__DELETE__SUCCESS`;
export const API__COMPANIES__DELETE__FAIL = `${ PREFIX }API__COMPANIES__DELETE__FAIL`;

export const API__COMPANIES__RENAME__STARTED = `${ PREFIX }API__COMPANIES__RENAME__STARTED`;
export const API__COMPANIES__RENAME__SUCCESS = `${ PREFIX }API__COMPANIES__RENAME__SUCCESS`;
export const API__COMPANIES__RENAME__FAIL = `${ PREFIX }API__COMPANIES__RENAME__FAIL`;

export const API__COMPANIES__UPDATE__STARTED = `${ PREFIX }API__COMPANIES__UPDATE__STARTED`;
export const API__COMPANIES__UPDATE__SUCCESS = `${ PREFIX }API__COMPANIES__UPDATE__SUCCESS`;
export const API__COMPANIES__UPDATE__FAIL = `${ PREFIX }API__COMPANIES__UPDATE__FAIL`;

export const API__BULKY_WASTE_CONFIG__UPDATE__STARTED = `${ PREFIX }API__BULKY_WASTE_CONFIG__UPDATE__STARTED`;
export const API__BULKY_WASTE_CONFIG__UPDATE__SUCCESS = `${ PREFIX }API__BULKY_WASTE_CONFIG__UPDATE__SUCCESS`;
export const API__BULKY_WASTE_CONFIG__UPDATE__FAIL = `${ PREFIX }API__BULKY_WASTE_CONFIG__UPDATE__FAIL`;

export const API__LOCATION_CANDIDATES_TOWNS__GET_MANY__STARTED = `${ PREFIX }API__LOCATION_CANDIDATES_TOWNS__GET_MANY__STARTED`;
export const API__LOCATION_CANDIDATES_TOWNS__GET_MANY__SUCCESS = `${ PREFIX }API__LOCATION_CANDIDATES_TOWNS__GET_MANY__SUCCESS`;
export const API__LOCATION_CANDIDATES_TOWNS__GET_MANY__FAIL = `${ PREFIX }API__LOCATION_CANDIDATES_TOWNS__GET_MANY__FAIL`;

export const API__LOCATION_CANDIDATES_TOWN_ADDRESSES_AND_STREETS__GET_MANY__STARTED = `${ PREFIX }API__LOCATION_CANDIDATES_TOWN_ADDRESSES_AND_STREETS__GET_MANY__STARTED`;
export const API__LOCATION_CANDIDATES_TOWN_ADDRESSES_AND_STREETS__GET_MANY__SUCCESS = `${ PREFIX }API__LOCATION_CANDIDATES_TOWN_ADDRESSES_AND_STREETS__GET_MANY__SUCCESS`;
export const API__LOCATION_CANDIDATES_TOWN_ADDRESSES_AND_STREETS__GET_MANY__FAIL = `${ PREFIX }API__LOCATION_CANDIDATES_TOWN_ADDRESSES_AND_STREETS__GET_MANY__FAIL`;

export const API__LOCATION_CANDIDATES_STREET_ADDRESSES__GET_MANY__STARTED = `${ PREFIX }API__LOCATION_CANDIDATES_STREET_ADDRESSES__GET_MANY__STARTED`;
export const API__LOCATION_CANDIDATES_STREET_ADDRESSES__GET_MANY__SUCCESS = `${ PREFIX }API__LOCATION_CANDIDATES_STREET_ADDRESSES__GET_MANY__SUCCESS`;
export const API__LOCATION_CANDIDATES_STREET_ADDRESSES__GET_MANY__FAIL = `${ PREFIX }API__LOCATION_CANDIDATES_STREET_ADDRESSES__GET_MANY__FAIL`;

export const API__LOCATION_CANDIDATES_STREET_ADDRESSES_TO__GET_MANY__STARTED = `${ PREFIX }API__LOCATION_CANDIDATES_STREET_ADDRESSES_TO__GET_MANY__STARTED`;
export const API__LOCATION_CANDIDATES_STREET_ADDRESSES_TO__GET_MANY__SUCCESS = `${ PREFIX }API__LOCATION_CANDIDATES_STREET_ADDRESSES_TO__GET_MANY__SUCCESS`;
export const API__LOCATION_CANDIDATES_STREET_ADDRESSES_TO__GET_MANY__FAIL = `${ PREFIX }API__LOCATION_CANDIDATES_STREET_ADDRESSES_TO__GET_MANY__FAIL`;

export const API__LOCATION_CANDIDATES_APARTMENT_ADDRESSS__GET_MANY__STARTED = `${ PREFIX }API__LOCATION_CANDIDATES_APARTMENT_ADDRESSS__GET_MANY__STARTED`;
export const API__LOCATION_CANDIDATES_APARTMENT_ADDRESSS__GET_MANY__SUCCESS = `${ PREFIX }API__LOCATION_CANDIDATES_APARTMENT_ADDRESSS__GET_MANY__SUCCESS`;
export const API__LOCATION_CANDIDATES_APARTMENT_ADDRESSS__GET_MANY__FAIL = `${ PREFIX }API__LOCATION_CANDIDATES_APARTMENT_ADDRESSS__GET_MANY__FAIL`;


export const API_LOCATION_CANDIDATES_RESET = `${ PREFIX }API_LOCATION_CANDIDATES_RESET`;

export const API__NEWSLETTER_SUBSCRIPTION__ADD__STARTED = `${ PREFIX }API__NEWSLETTER_SUBSCRIPTION__ADD__STARTED`;
export const API__NEWSLETTER_SUBSCRIPTION__ADD__SUCCESS = `${ PREFIX }API__NEWSLETTER_SUBSCRIPTION__ADD__SUCCESS`;
export const API__NEWSLETTER_SUBSCRIPTION__ADD__FAIL = `${ PREFIX }API__NEWSLETTER_SUBSCRIPTION__ADD__FAIL`;

export const API__NEWSLETTER_SUBSCRIPTION__TOKEN_CHECK__STARTED = `${ PREFIX }API__NEWSLETTER_SUBSCRIPTION__TOKEN_CHECK__STARTED`;
export const API__NEWSLETTER_SUBSCRIPTION__TOKEN_CHECK__SUCCESS = `${ PREFIX }API__NEWSLETTER_SUBSCRIPTION__TOKEN_CHECK__SUCCESS`;
export const API__NEWSLETTER_SUBSCRIPTION__TOKEN_CHECK__FAIL = `${ PREFIX }API__NEWSLETTER_SUBSCRIPTION__TOKEN_CHECK__FAIL`;

export const API__NEWSLETTER_SUBSCRIPTION__DELETE__STARTED = `${ PREFIX }API__NEWSLETTER_SUBSCRIPTION__DELETE__STARTED`;
export const API__NEWSLETTER_SUBSCRIPTION__DELETE__SUCCESS = `${ PREFIX }API__NEWSLETTER_SUBSCRIPTION__DELETE__SUCCESS`;
export const API__NEWSLETTER_SUBSCRIPTION__DELETE__FAIL = `${ PREFIX }API__NEWSLETTER_SUBSCRIPTION__DELETE__FAIL`;

export const API__NEWSLETTER_OWNER__GET__STARTED = `${ PREFIX }API__NEWSLETTER_OWNER__GET__STARTED`;
export const API__NEWSLETTER_OWNER__GET__SUCCESS = `${ PREFIX }API__NEWSLETTER_OWNER__GET__SUCCESS`;
export const API__NEWSLETTER_OWNER__GET__FAIL = `${ PREFIX }API__NEWSLETTER_OWNER__GET__FAIL`;

export const API__PAYMENTS_DUE_DATE__GET__STARTED = `${ PREFIX }API__PAYMENTS_DUE_DATE__GET__STARTED`;
export const API__PAYMENTS_DUE_DATE__GET__SUCCESS = `${ PREFIX }API__PAYMENTS_DUE_DATE__GET__SUCCESS`;
export const API__PAYMENTS_DUE_DATE__GET__FAIL = `${ PREFIX }API__PAYMENTS_DUE_DATE__GET__FAIL`;

export const API__PAYMENTS_DUE_DATE__UPDATE__STARTED = `${ PREFIX }API__PAYMENTS_DUE_DATE__UPDATE__STARTED`;
export const API__PAYMENTS_DUE_DATE__UPDATE__SUCCESS = `${ PREFIX }API__PAYMENTS_DUE_DATE__UPDATE__SUCCESS`;
export const API__PAYMENTS_DUE_DATE__UPDATE__FAIL = `${ PREFIX }API__PAYMENTS_DUE_DATE__UPDATE__FAIL`;

export const API__REPORTS__ADD__STARTED = `${ PREFIX }API__REPORTS__ADD__STARTED`;
export const API__REPORTS__ADD__SUCCESS = `${ PREFIX }API__REPORTS__ADD__SUCCESS`;
export const API__REPORTS__ADD__FAIL = `${ PREFIX }API__REPORTS__ADD__FAIL`;

export const API__REPORTS__GET__STARTED = `${ PREFIX }API__REPORTS__GET__STARTED`;
export const API__REPORTS__GET__SUCCESS = `${ PREFIX }API__REPORTS__GET__SUCCESS`;
export const API__REPORTS__GET__FAIL = `${ PREFIX }API__REPORTS__GET__FAIL`;

export const API__REPORT_TYPES__GET__STARTED = `${ PREFIX }API__REPORT_TYPES__GET__STARTED`;
export const API__REPORTS_TYPES__GET__SUCCESS = `${ PREFIX }API__REPORTS_TYPES__GET__SUCCESS`;
export const API__REPORTS_TYPES__GET__FAIL = `${ PREFIX }API__REPORTS_TYPES__GET__FAIL`;

export const API__REPORTS__GET_MANY__STARTED = `${ PREFIX }API__REPORTS__GET_MANY__STARTED`;
export const API__REPORTS__GET_MANY__SUCCESS = `${ PREFIX }API__REPORTS__GET_MANY__SUCCESS`;
export const API__REPORTS__GET_MANY__FAIL = `${ PREFIX }API__REPORTS__GET_MANY__FAIL`;

export const API__REPORTS__UPDATE_STATUS__STARTED = `${ PREFIX }API__REPORTS__UPDATE_STATUS__STARTED`;
export const API__REPORTS__UPDATE_STATUS__SUCCESS = `${ PREFIX }API__REPORTS__UPDATE_STATUS__SUCCESS`;
export const API__REPORTS__UPDATE_STATUS__FAIL = `${ PREFIX }API__REPORTS__UPDATE_STATUS__FAIL`;

export const API__REPORTS__SEND_STATUS_CHANGE_CONFIRMATION__STARTED = `${ PREFIX }API__REPORTS__SEND_STATUS_CHANGE_CONFIRMATION__STARTED`;
export const API__REPORTS__SEND_STATUS_CHANGE_CONFIRMATION__SUCCESS = `${ PREFIX }API__REPORTS__SEND_STATUS_CHANGE_CONFIRMATION__SUCCESS`;
export const API__REPORTS__SEND_STATUS_CHANGE_CONFIRMATION__FAIL = `${ PREFIX }API__REPORTS__SEND_STATUS_CHANGE_CONFIRMATION__FAIL`;

export const API__REPORT_EMPLOYEES__GET_MANY__STARTED = `${ PREFIX }API__REPORT_EMPLOYEES__GET_MANY__STARTED`;
export const API__REPORT_EMPLOYEES__GET_MANY__SUCCESS = `${ PREFIX }API__REPORT_EMPLOYEES__GET_MANY__SUCCESS`;
export const API__REPORT_EMPLOYEES__GET_MANY__FAIL = `${ PREFIX }API__REPORT_EMPLOYEES__GET_MANY__FAIL`;

export const API__REPORT_EMPLOYEES__UPDATE_EMPLOYEE__STARTED = `${ PREFIX }API__REPORT_EMPLOYEES__UPDATE_EMPLOYEE__STARTED`;
export const API__REPORT_EMPLOYEES__UPDATE_EMPLOYEE__SUCCESS = `${ PREFIX }API__REPORT_EMPLOYEES__UPDATE_EMPLOYEE__SUCCESS`;
export const API__REPORT_EMPLOYEES__UPDATE_EMPLOYEE__FAIL = `${ PREFIX }API__REPORT_EMPLOYEES__UPDATE_EMPLOYEE__FAIL`;

export const API__REPORT_MESSAGES__GET_MANY__STARTED = `${ PREFIX }API__REPORT_MESSAGES__GET_MANY__STARTED`;
export const API__REPORT_MESSAGES__GET_MANY__SUCCESS = `${ PREFIX }API__REPORT_MESSAGES__GET_MANY__SUCCESS`;
export const API__REPORT_MESSAGES__GET_MANY__FAIL = `${ PREFIX }API__REPORT_MESSAGES__GET_MANY__FAIL`;

export const API__REPORT_MESSAGES__ADD__STARTED = `${ PREFIX }API__REPORT_MESSAGES__ADD__STARTED`;
export const API__REPORT_MESSAGES__ADD__SUCCESS = `${ PREFIX }API__REPORT_MESSAGES__ADD__SUCCESS`;
export const API__REPORT_MESSAGES__ADD__FAIL = `${ PREFIX }API__REPORT_MESSAGES__ADD__FAIL`;

export const API__REPORT_STATUSES__GET_MANY__STARTED = `${ PREFIX }API__REPORT_STATUSES__GET_MANY__STARTED`;
export const API__REPORT_STATUSES__GET_MANY__SUCCESS = `${ PREFIX }API__REPORT_STATUSES__GET_MANY__SUCCESS`;
export const API__REPORT_STATUSES__GET_MANY__FAIL = `${ PREFIX }API__REPORT_STATUSES__GET_MANY__FAIL`;

export const API__MESSAGES__ADD__STARTED = `${ PREFIX }API__MESSAGES__ADD__STARTED`;
export const API__MESSAGES__ADD__SUCCESS = `${ PREFIX }API__MESSAGES__ADD__SUCCESS`;
export const API__MESSAGES__ADD__FAIL = `${ PREFIX }API__MESSAGES__ADD__FAIL`;

export const API__MESSAGES__GET_MANY__STARTED = `${ PREFIX }API__MESSAGES__GET_MANY__STARTED`;
export const API__MESSAGES__GET_MANY__SUCCESS = `${ PREFIX }API__MESSAGES__GET_MANY__SUCCESS`;
export const API__MESSAGES__GET_MANY__FAIL = `${ PREFIX }API__MESSAGES__GET_MANY__FAIL`;

export const API__MESSAGES__GET__STARTED = `${ PREFIX }API__MESSAGES__GET__STARTED`;
export const API__MESSAGES__GET__SUCCESS = `${ PREFIX }API__MESSAGES__GET__SUCCESS`;
export const API__MESSAGES__GET__FAIL = `${ PREFIX }API__MESSAGES__GET__FAIL`;

export const API__MESSAGES__DELETE__STARTED = `${ PREFIX }API__MESSAGES__DELETE__STARTED`;
export const API__MESSAGES__DELETE__SUCCESS = `${ PREFIX }API__MESSAGES__DELETE__SUCCESS`;
export const API__MESSAGES__DELETE__FAIL = `${ PREFIX }API__MESSAGES__DELETE__FAIL`;

export const API__MESSAGE_ADDRESSES__GET_MANY__STARTED = `${ PREFIX }API__MESSAGE_ADDRESSES__GET_MANY__STARTED`;
export const API__MESSAGE_ADDRESSES__GET_MANY__SUCCESS = `${ PREFIX }API__MESSAGE_ADDRESSES__GET_MANY__SUCCESS`;
export const API__MESSAGE_ADDRESSES__GET_MANY__FAIL = `${ PREFIX }API__MESSAGE_ADDRESSES__GET_MANY__FAIL`;

export const API__MESSAGE_ADDRESSES__UPDATE__STARTED = `${ PREFIX }API__MESSAGE_ADDRESSES__UPDATE__STARTED`;
export const API__MESSAGE_ADDRESSES__UPDATE__SUCCESS = `${ PREFIX }API__MESSAGE_ADDRESSES__UPDATE__SUCCESS`;
export const API__MESSAGE_ADDRESSES__UPDATE__FAIL = `${ PREFIX }API__MESSAGE_ADDRESSES__UPDATE__FAIL`;

export const API__WASTE_COLLECTION_POINTS__GET_MANY__STARTED = `${ PREFIX }API__WASTE_COLLECTION_POINTS__GET_MANY__STARTED`;
export const API__WASTE_COLLECTION_POINTS__GET_MANY__SUCCESS = `${ PREFIX }API__WASTE_COLLECTION_POINTS__GET_MANY__SUCCESS`;
export const API__WASTE_COLLECTION_POINTS__GET_MANY__FAIL = `${ PREFIX }API__WASTE_COLLECTION_POINTS__GET_MANY__FAIL`;

export const API__WASTE_COLLECTION_POINTS__ADD__STARTED = `${ PREFIX }API__WASTE_COLLECTION_POINTS__ADD__STARTED`;
export const API__WASTE_COLLECTION_POINTS__ADD__SUCCESS = `${ PREFIX }API__WASTE_COLLECTION_POINTS__ADD__SUCCESS`;
export const API__WASTE_COLLECTION_POINTS__ADD__FAIL = `${ PREFIX }API__WASTE_COLLECTION_POINTS__ADD__FAIL`;

export const API__WASTE_COLLECTION_POINTS__UPDATE__STARTED = `${ PREFIX }API__WASTE_COLLECTION_POINTS__UPDATE__STARTED`;
export const API__WASTE_COLLECTION_POINTS__UPDATE__SUCCESS = `${ PREFIX }API__WASTE_COLLECTION_POINTS__UPDATE__SUCCESS`;
export const API__WASTE_COLLECTION_POINTS__UPDATE__FAIL = `${ PREFIX }API__WASTE_COLLECTION_POINTS__UPDATE__FAIL`;

export const API__WASTE_COLLECTION_POINTS__PATCH_STATUS__STARTED = `${ PREFIX }API__WASTE_COLLECTION_POINTS__PATCH_STATUS__STARTED`;
export const API__WASTE_COLLECTION_POINTS__PATCH_STATUS__SUCCESS = `${ PREFIX }API__WASTE_COLLECTION_POINTS__PATCH_STATUS__SUCCESS`;
export const API__WASTE_COLLECTION_POINTS__PATCH_STATUS__FAIL = `${ PREFIX }API__WASTE_COLLECTION_POINTS__PATCH_STATUS__FAIL`;

export const API__WASTE_COLLECTION_POINTS__PATCH_MANY_STATUSES__STARTED = `${ PREFIX }API__WASTE_COLLECTION_POINTS__PATCH_MANY_STATUSES__STARTED`;
export const API__WASTE_COLLECTION_POINTS__PATCH_MANY_STATUSES__SUCCESS = `${ PREFIX }API__WASTE_COLLECTION_POINTS__PATCH_MANY_STATUSES__SUCCESS`;
export const API__WASTE_COLLECTION_POINTS__PATCH_MANY_STATUSES__FAIL = `${ PREFIX }API__WASTE_COLLECTION_POINTS__PATCH_MANY_STATUSES__FAIL`;

export const API__WASTE_COLLECTION_POINTS__DELETE__STARTED = `${ PREFIX }API__WASTE_COLLECTION_POINTS__DELETE__STARTED`;
export const API__WASTE_COLLECTION_POINTS__DELETE__SUCCESS = `${ PREFIX }API__WASTE_COLLECTION_POINTS__DELETE__SUCCESS`;
export const API__WASTE_COLLECTION_POINTS__DELETE__FAIL = `${ PREFIX }API__WASTE_COLLECTION_POINTS__DELETE__FAIL`;

export const API__COPY_RECEPTION__STARTED = `${ PREFIX }API__COPY_RECEPTION__STARTED`;
export const API__COPY_RECEPTION__SUCCESS = `${ PREFIX }API__COPY_RECEPTION__SUCCESS`;
export const API__COPY_RECEPTION__FAIL = `${ PREFIX }API__COPY_RECEPTION__FAIL`;

export const API__COMMUNAL_DOCUMENTS__GET_MANY__STARTED = `${ PREFIX }API__COMMUNAL_DOCUMENTS__GET_MANY__STARTED`;
export const API__COMMUNAL_DOCUMENTS__GET_MANY__SUCCESS = `${ PREFIX }API__COMMUNAL_DOCUMENTS__GET_MANY__SUCCESS`;
export const API__COMMUNAL_DOCUMENTS__GET_MANY__FAIL = `${ PREFIX }API__COMMUNAL_DOCUMENTS__GET_MANY__FAIL`;

export const API__COMMUNAL_DOCUMENTS__ADD__STARTED = `${ PREFIX }API__COMMUNAL_DOCUMENTS__ADD__STARTED`;
export const API__COMMUNAL_DOCUMENTS__ADD__SUCCESS = `${ PREFIX }API__COMMUNAL_DOCUMENTS__ADD__SUCCESS`;
export const API__COMMUNAL_DOCUMENTS__ADD__FAIL = `${ PREFIX }API__COMMUNAL_DOCUMENTS__ADD__FAIL`;

export const API__COMMUNAL_DOCUMENTS__DOWNLOAD__STARTED = `${ PREFIX }API__COMMUNAL_DOCUMENTS__DOWNLOAD__STARTED`;
export const API__COMMUNAL_DOCUMENTS__DOWNLOAD__SUCCESS = `${ PREFIX }API__COMMUNAL_DOCUMENTS__DOWNLOAD__SUCCESS`;
export const API__COMMUNAL_DOCUMENTS__DOWNLOAD__FAIL = `${ PREFIX }API__COMMUNAL_DOCUMENTS__DOWNLOAD___FAIL`;

export const API__COMMUNAL_DOCUMENTS__UPDATE__STARTED = `${ PREFIX }API__COMMUNAL_DOCUMENTS__UPDATE__STARTED`;
export const API__COMMUNAL_DOCUMENTS__UPDATE__SUCCESS = `${ PREFIX }API__COMMUNAL_DOCUMENTS__UPDATE__SUCCESS`;
export const API__COMMUNAL_DOCUMENTS__UPDATE__FAIL = `${ PREFIX }API__COMMUNAL_DOCUMENTS__UPDATE__FAIL`;

export const API__COMMUNAL_DOCUMENTS__DELETE__STARTED = `${ PREFIX }API__COMMUNAL_DOCUMENTS__DELETE__STARTED`;
export const API__COMMUNAL_DOCUMENTS__DELETE__SUCCESS = `${ PREFIX }API__COMMUNAL_DOCUMENTS__DELETE__SUCCESS`;
export const API__COMMUNAL_DOCUMENTS__DELETE__FAIL = `${ PREFIX }API__COMMUNAL_DOCUMENTS__DELETE__FAIL`;
