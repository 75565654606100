import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/pl";

import MessageHistoryList from "./list";
import MessageHistorySearchOptions from "./search_options";
import { cancelMessage, getManyMessages } from "../../../../../../data/actions/waste_management/messages";
import { MISC_DATE_ISO_DATE_ONLY_FORMAT } from "../../../../../../core/constants";
import { isNullOrUndefined } from '../../../../../../core/utils/misc_utils';
import ListPagination from '../../../../../common/pagination';
import { generatePaginationQueryValues } from '../../../../../../core/utils/api_utils';
import withPagination from '../../../../../common/hoc/with_pagination';
import { Formik } from "formik";

const initialFormValues = {
    query: "",
    from: moment().subtract(1, "weeks").format(MISC_DATE_ISO_DATE_ONLY_FORMAT),
    to: moment().format(MISC_DATE_ISO_DATE_ONLY_FORMAT)
};

class SentNotifications extends Component {

    state = {
        query: "",
        from: moment().subtract(1, "weeks").format(MISC_DATE_ISO_DATE_ONLY_FORMAT),
        to: moment().format(MISC_DATE_ISO_DATE_ONLY_FORMAT)
    };

    defaultPaginationQueryValues = generatePaginationQueryValues(0, 30, ["requestedRealization", "desc"]);

    render = () => {
        const { messages, _onPrevClick, _onNextClick, _onPageNumberInputValueChange, cancelMessage } = this.props;

        return (
            <div className="main-content--table">

                <Formik initialValues={ initialFormValues }
                        onSubmit={ values => this._onSubmit(values) }
                        render={ formikBag => (

                            <>
                                <MessageHistorySearchOptions
                                    from={ formikBag.values.from }
                                    to={ formikBag.values.to }
                                    setFieldValue={ formikBag.setFieldValue } />

                                <MessageHistoryList
                                    messages={ messages }
                                    cancelMessage={
                                        messagesId => cancelMessage(messagesId)
                                            .then(() => this.updateHistoryList())
                                    }/>

                            </>

                        ) }/>

                <div className="container is-fluid">
                    <div className="is-flex justify-end pagination--list">

                        {
                            !isNullOrUndefined(messages.data) &&

                            <ListPagination data={ messages.data }
                                            _onPrevClick={ _onPrevClick }
                                            _onNextClick={ _onNextClick }
                                            _onPageNumberInputValueChange={ _onPageNumberInputValueChange }/>
                        }

                    </div>
                </div>
            </div>
        );
    };

    componentDidMount = () => {
        this.updateHistoryList();
    };

    _onSubmit = (values) => {
        this.setState({
            from: values.from,
            to: values.to,
            query: values.query
        })
        this.updateHistoryList();
    }

    updateHistoryList = () => {
        const { getManyMessages, currentPage, setMaxPage } = this.props;

        getManyMessages({ ...this.defaultPaginationQueryValues, page: currentPage, from: this.state.from, to: this.state.to, query: this.state.query })
            .then(() => {
                    if (!isNullOrUndefined(this.props.messages.data)) {
                        setMaxPage(this.props.messages.data.totalPages);
                    }
                }
            );
    };

    shouldComponentUpdate = (nextProps, nextState) => {
        const { currentPage, messages, getManyMessages } = this.props;

        if (nextProps.currentPage !== currentPage) {
            getManyMessages({ ...this.defaultPaginationQueryValues, page: nextProps.currentPage, from: this.state.from, to: this.state.to, query: this.state.query });
            return true;
        }

        if (nextProps.messages !== messages) {
            return true;
        }

        if (nextState !== this.state) {
            return true;
        }

        if (nextProps !== this.props) {
            return true;
        }

        return false;
    };
}

const mapStateToProps = (state) => {
    return {
        messages: state.entities.wasteManagement.messages.getMany
    };
};

export default withPagination(connect(mapStateToProps, { getManyMessages, cancelMessage })(SentNotifications));
