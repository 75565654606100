import add from "./add";
import deleteMany from "./delete_many";
import getMany from "./get_many";
import publishMany from "./publish_many";
import update from "./update";
import getConfig from "./get_config";
import updateConfig from "./update_config";

export default (state = {}, action) => {
    return {
        ...state,
        add: add(state.add, action),
        deleteMany: deleteMany(state.deleteMany, action),
        getMany: getMany(state.getMany, action),
        publishMany: publishMany(state.publishMany, action),
        update: update(state.update, action),
        getConfig: getConfig(state.getConfig, action),
        updateConfig: updateConfig(state.updateConfig, action)
    };
}