import React from "react";
import { ReactComponent as IconEye } from "../../../../resource/img/icons/reports/icon_eye.svg";
import Icon from "../icon";

const ReportImage = ({ src }) => {
    return (
        <div className="report-details__field-img-container">
            <div className="report-details__field-img-icon">
                <Icon squared small>
                    <IconEye/>
                </Icon>
            </div>
            <div className="report-details__field-img-overlay"/>
            <img className="report-details__field-img" src={ src } width="176" height="140" alt=""/>
        </div>
    );
};

export default ReportImage;
