import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Switch } from "react-router-dom";

import history from "./history";
import { initializeTinyMCE } from "./tinymce";
// routes
import AppRoute from "./routes/app_route";
import SignedInAppRoute from "./routes/signed_in_app_route";
// layouts
import AdminLayout from "../view/layout/signed_in/layout";
import AdminLayoutWithBackground from "../view/layout/signed_in/layout_with_background";
import LayoutWithSidebar from "../view/layout/signed_in/layout_with_sidebar";
import SignInLayout from "../view/layout/signed_out/sign_in/index";
import MessageLayout from "../view/layout/signed_out/message/index";
// -- SIGNED IN
import ModuleSelection from "../view/signed_in/module_selection";
import OwnerSelection from "../view/signed_in/owner_selection";
// instructions
import Instructions from "../view/signed_in/options/instructions";
// accounts
import AccountsList from "../view/signed_in/options/accounts/";
import AccountDetails from "../view/signed_in/options/accounts/details";
// settings
import SettingsPasswordChange from "../view/signed_in/options/settings/password_change/";
import SettingsContactData from "../view/signed_in/options/settings/contact_data/";
import SettingsRegulations from "../view/signed_in/options/settings/regulations/";
//user logs
import UserConsents from "../view/signed_in/options/logs/user_consents/";
import UserSubscriptions from "../view/signed_in/options/logs/user_subscriptions/";
// -- MODULE - SERVICES
import ServicesModule from "../view/signed_in/modules/services";
import CategoryDetails from "../view/signed_in/modules/services/category";
import ServicesCard from "../view/signed_in/modules/services/card";
// -- MODULE - EKOSTRAZNIK
import EkostraznikModule from "../view/signed_in/modules/ekostraznik";
import EkostraznikInspectionsHistory from "../view/signed_in/modules/ekostraznik/inspection_history";
import LocationInspectionDetails
    from "../view/signed_in/modules/ekostraznik/inspection_history/location_inspection_details";
import GroupInspectionDetails from "../view/signed_in/modules/ekostraznik/inspection_history/group_inspection_details";
import BinInspectionDetails from "../view/signed_in/modules/ekostraznik/inspection_history/bin_inspection_details";
import EkostraznikReports from "../view/signed_in/modules/ekostraznik/reports";
import InspectionCompanies from "../view/signed_in/modules/ekostraznik/inspection_companies";
import InventoryHistory from "../view/signed_in/modules/ekostraznik/inventories";
// -- MODULE - WASTE MANAGEMENT
import Waste from "../view/signed_in/modules/waste_management";
// management
import MunicipalWaste from "../view/signed_in/modules/waste_management/management/municipal_waste/";
import BulkyWaste from "../view/signed_in/modules/waste_management/management/bulky_waste/";
import CollectionPoints from "../view/signed_in/modules/waste_management/management/waste_collection_points/";
// messages
import MessageSending from "../view/signed_in/modules/waste_management/messages/sending/index";
import MessageHistory from "../view/signed_in/modules/waste_management/messages/history/index";
import MessagePreview from "../view/signed_in/modules/waste_management/messages/history/message_preview";
import MessagePreviewBlisko from "../view/signed_in/modules/blisko/messages/sent/message_preview";
// reports
import Reports from "../view/signed_in/modules/waste_management/reports";
// areas
import Areas from "../view/signed_in/modules/waste_management/areas";
import AreaDetails from "../view/signed_in/modules/waste_management/areas/details";
// payments due date
import PaymentsDueDate from "../view/signed_in/modules/waste_management/payments_due_date";
//  companies
import Companies from "../view/signed_in/modules/waste_management/companies";
import CompanyDetails from "../view/signed_in/modules/waste_management/companies/details";
// -- SIGNED OUT
// sign_in
import SignIn from "../view/signed_out/sign_in";
// password reset
import PasswordResetRequest from "../view/signed_out/password_reset/reset_request/index";
import PasswordResetRequestConfirmation from "../view/signed_out/password_reset/reset_request/confirmation";
import PasswordResetRequestResetConfirmation from "../view/signed_out/password_reset/set_password/confirmation";
import PasswordReset from "../view/signed_out/password_reset/set_password/";
// account activation
import AccountActivation from "../view/signed_out/account_activation/";
// newsletter
import NewsletterSubscribeConfirmation from "../view/signed_out/newsletter/subscribe_confirmation";
import NewsletterUnsubscribe from "../view/signed_out/newsletter/unsubscribe";
import NewsletterUnsubscribeConfirmation from "../view/signed_out/newsletter/unsubscribe_confirmation";
// -- CORE - constants
import {
    ROUTE_ACCOUNT_ACTIVATION,
    ROUTE_ACCOUNTS,
    ROUTE_ACCOUNTS_DETAILS_FOR_ROUTING,
    ROUTE_BLISKO,
    ROUTE_BLISKO_ADDRESS_TEMPLATE,
    ROUTE_BLISKO_ADDRESS_TEMPLATE_LOCATIONS_FOR_ROUTING,
    ROUTE_BLISKO_DRAFT_MESSAGE_EDIT_FOR_ROUTING,
    ROUTE_BLISKO_MESSAGE_PREVIEW_FOR_ROUTING,
    ROUTE_BLISKO_MESSAGE_SEND,
    ROUTE_BLISKO_MESSAGE_TEMPLATE,
    ROUTE_BLISKO_MESSAGE_TEMPLATE_ADD,
    ROUTE_BLISKO_MESSAGE_TEMPLATE_EDIT_FOR_ROUTING,
    ROUTE_BLISKO_MESSAGES,
    ROUTE_BLISKO_RECEIVER_GROUP,
    ROUTE_BLISKO_RECEIVER_GROUP_ADD,
    ROUTE_BLISKO_RECEIVER_GROUP_EDIT_FOR_ROUTING,
    ROUTE_BLISKO_SENDER_DETAILS,
    ROUTE_EKOSTRAZNIK,
    ROUTE_EKOSTRAZNIK_ADDRESSES_APARTMENT_ADDRESS,
    ROUTE_EKOSTRAZNIK_ADDRESSES_DETAILED_GROUPED_ADDRESSES_FOR_ROUTING,
    ROUTE_EKOSTRAZNIK_ADDRESSES_GROUPED_ADDRESSES,
    ROUTE_EKOSTRAZNIK_ADDRESSES_QR_CODES,
    ROUTE_EKOSTRAZNIK_COMPANIES,
    ROUTE_EKOSTRAZNIK_GROUP_DETAILED_INSPECTION_FOR_ROUTING,
    ROUTE_EKOSTRAZNIK_INSPECTION_DETAILS_FOR_ROUTING,
    ROUTE_EKOSTRAZNIK_INSPECTION_HISTORY,
    ROUTE_EKOSTRAZNIK_INVENTORIES,
    ROUTE_EKOSTRAZNIK_INVENTORIES_DETAILS_FOR_ROUTING,
    ROUTE_EKOSTRAZNIK_LOCATION_DETAILED_INSPECTION_FOR_ROUTING,
    ROUTE_EKOSTRAZNIK_REPORTS,
    ROUTE_INSTRUCTIONS,
    ROUTE_LOGS_USER_CONSENTS,
    ROUTE_LOGS_USER_SUBSCRIPTIONS,
    ROUTE_MODULE_SELECTION,
    ROUTE_NEWSLETTER_SUBSCRIBE_CONFIRMATION,
    ROUTE_NEWSLETTER_UNSUBSCRIBE,
    ROUTE_NEWSLETTER_UNSUBSCRIBE_CONFIRMATION,
    ROUTE_OWNER_SELECTION,
    ROUTE_PASSWORD_RESET,
    ROUTE_PASSWORD_RESET_REQUEST,
    ROUTE_PASSWORD_RESET_REQUEST_CONFIRMATION,
    ROUTE_PASSWORD_RESET_REQUEST_RESET_CONFIRMATION,
    ROUTE_SERVICES,
    ROUTE_SERVICES_ADD_CARD,
    ROUTE_SERVICES_CATEGORY_DETAILS_FOR_ROUTING,
    ROUTE_SERVICES_EDIT_CARD_FOR_ROUTING,
    ROUTE_SETTINGS_ACCESSIBILITY_STATEMENT,
    ROUTE_SETTINGS_CONTACT_DATA,
    ROUTE_SETTINGS_PASSWORD_CHANGE,
    ROUTE_SETTINGS_REGULATIONS,
    ROUTE_SIGN_IN,
    ROUTE_WASTE,
    ROUTE_WASTE_AREAS,
    ROUTE_WASTE_AREAS_DETAILS_FOR_ROUTING,
    ROUTE_WASTE_COMMUNAL_DOCUMENTS,
    ROUTE_WASTE_COMPANIES,
    ROUTE_WASTE_COMPANIES_DETAILS_FOR_ROUTING,
    ROUTE_WASTE_MANAGEMENT_BULKY_WASTE,
    ROUTE_WASTE_MANAGEMENT_COLLECTION_POINTS,
    ROUTE_WASTE_MANAGEMENT_MUNICIPAL_WASTE,
    ROUTE_WASTE_MESSAGE_PREVIEW_FOR_ROUTING,
    ROUTE_WASTE_NOTIFICATIONS,
    ROUTE_WASTE_NOTIFICATIONS_SENT,
    ROUTE_WASTE_PAYMENTS_DUE_DATE,
    ROUTE_WASTE_REPORTS
} from './constants';
import EkostraznikQrCodes from "../view/signed_in/modules/ekostraznik/qr_codes";
import EkostraznikAddressGroups from "../view/signed_in/modules/ekostraznik/adress_groups";
import SettingsAccessibilityStatement from "../view/signed_in/options/settings/accessibility_statement";
import AddressGroupDetails from "../view/signed_in/modules/ekostraznik/adress_groups/list/address_group_details";
import CommunalDocuments from "../view/signed_in/modules/waste_management/communal_documents";
import ApartmentAddresses from "../view/signed_in/modules/ekostraznik/apartment_addresses"
import BliskoModule from "../view/signed_in/modules/blisko";
import Messages from "../view/signed_in/modules/blisko/messages";
import MessageSendingBlisko from "../view/signed_in/modules/blisko/messages/sending/index";
import ReceiverGroups from "../view/signed_in/modules/blisko/receiver_group/index";
import InventoryDetails from "../view/signed_in/modules/ekostraznik/inventories/list/details";
import ReceiverGroup from "../view/signed_in/modules/blisko/receiver_group/form";
import AddressTemplate from "../view/signed_in/modules/blisko/templates/address_template";
import AddressTemplateLocations
    from "../view/signed_in/modules/blisko/templates/address_template/list/address_template_locations";
import MessageTemplate from "../view/signed_in/modules/blisko/templates/message_tempate";
import AddAndEditMessageTemplate from "../view/signed_in/modules/blisko/templates/message_tempate/form/add_and_edit";
import SenderDetails from "../view/signed_in/modules/blisko/sender_details";

class Routing extends Component {

    render = () => {
        const { authentication, storageLoaded } = this.props;

        return (
            <Router history={ history }>
                <div>
                    <Switch>
                        <AppRoute exact path={ ROUTE_SIGN_IN }
                                  layout={ SignInLayout } component={ SignIn }
                                  authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <AppRoute exact path={ ROUTE_ACCOUNT_ACTIVATION }
                                  layout={ SignInLayout } component={ AccountActivation }
                                  authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <AppRoute exact path={ ROUTE_PASSWORD_RESET_REQUEST }
                                  layout={ SignInLayout } component={ PasswordResetRequest }
                                  authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <AppRoute exact path={ ROUTE_PASSWORD_RESET_REQUEST_CONFIRMATION }
                                  layout={ SignInLayout } component={ PasswordResetRequestConfirmation }
                                  authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <AppRoute exact path={ ROUTE_PASSWORD_RESET_REQUEST_RESET_CONFIRMATION }
                                  layout={ SignInLayout } component={ PasswordResetRequestResetConfirmation }
                                  authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <AppRoute exact path={ ROUTE_PASSWORD_RESET }
                                  layout={ SignInLayout } component={ PasswordReset }
                                  authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <AppRoute exact path={ ROUTE_NEWSLETTER_SUBSCRIBE_CONFIRMATION }
                                  layout={ MessageLayout } component={ NewsletterSubscribeConfirmation }
                                  authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <AppRoute exact path={ ROUTE_NEWSLETTER_UNSUBSCRIBE }
                                  layout={ MessageLayout } component={ NewsletterUnsubscribe }
                                  authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <AppRoute exact path={ ROUTE_NEWSLETTER_UNSUBSCRIBE_CONFIRMATION }
                                  layout={ MessageLayout } component={ NewsletterUnsubscribeConfirmation }
                                  authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_MODULE_SELECTION }
                                          layout={ AdminLayoutWithBackground } component={ ModuleSelection }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_OWNER_SELECTION }
                                          layout={ AdminLayoutWithBackground } component={ OwnerSelection }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_INSTRUCTIONS }
                                          layout={ AdminLayout } component={ Instructions }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_ACCOUNTS }
                                          layout={ AdminLayout } component={ AccountsList }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_ACCOUNTS_DETAILS_FOR_ROUTING }
                                          layout={ AdminLayout } component={ AccountDetails }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_SETTINGS_PASSWORD_CHANGE }
                                          layout={ AdminLayout } component={ SettingsPasswordChange }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_SETTINGS_CONTACT_DATA }
                                          layout={ AdminLayout } component={ SettingsContactData }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_SETTINGS_REGULATIONS }
                                          layout={ AdminLayout } component={ SettingsRegulations }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_SETTINGS_ACCESSIBILITY_STATEMENT }
                                          layout={ AdminLayout } component={ SettingsAccessibilityStatement }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_LOGS_USER_CONSENTS }
                                          layout={ AdminLayout } component={ UserConsents }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_LOGS_USER_SUBSCRIPTIONS }
                                          layout={ AdminLayout } component={ UserSubscriptions }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_SERVICES }
                                          layout={ LayoutWithSidebar } component={ ServicesModule }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_SERVICES_ADD_CARD }
                                          layout={ LayoutWithSidebar } component={ ServicesCard }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_SERVICES_EDIT_CARD_FOR_ROUTING }
                                          layout={ LayoutWithSidebar } component={ ServicesCard }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_SERVICES_CATEGORY_DETAILS_FOR_ROUTING }
                                          layout={ LayoutWithSidebar } component={ CategoryDetails }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_WASTE }
                                          layout={ LayoutWithSidebar } component={ Waste }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_WASTE_MANAGEMENT_MUNICIPAL_WASTE }
                                          layout={ LayoutWithSidebar } component={ MunicipalWaste }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_WASTE_MANAGEMENT_BULKY_WASTE }
                                          layout={ LayoutWithSidebar } component={ BulkyWaste }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_WASTE_MANAGEMENT_COLLECTION_POINTS }
                                          layout={ LayoutWithSidebar } component={ CollectionPoints }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_WASTE_NOTIFICATIONS }
                                          layout={ LayoutWithSidebar } component={ MessageSending }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_WASTE_NOTIFICATIONS_SENT }
                                          layout={ LayoutWithSidebar } component={ MessageHistory }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_WASTE_MESSAGE_PREVIEW_FOR_ROUTING }
                                          layout={ LayoutWithSidebar } component={ MessagePreview }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_WASTE_REPORTS }
                                          layout={ LayoutWithSidebar } component={ Reports }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_WASTE_AREAS }
                                          layout={ LayoutWithSidebar } component={ Areas }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_WASTE_AREAS_DETAILS_FOR_ROUTING }
                                          layout={ LayoutWithSidebar } component={ AreaDetails }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_WASTE_PAYMENTS_DUE_DATE }
                                          layout={ LayoutWithSidebar } component={ PaymentsDueDate }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_WASTE_COMPANIES }
                                          layout={ LayoutWithSidebar } component={ Companies }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_WASTE_COMPANIES_DETAILS_FOR_ROUTING }
                                          layout={ LayoutWithSidebar } component={ CompanyDetails }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_WASTE_COMMUNAL_DOCUMENTS }
                                          layout={ LayoutWithSidebar } component={ CommunalDocuments }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_EKOSTRAZNIK }
                                          layout={ LayoutWithSidebar } component={ EkostraznikModule }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_EKOSTRAZNIK_INSPECTION_HISTORY }
                                          layout={ LayoutWithSidebar } component={ EkostraznikInspectionsHistory }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_EKOSTRAZNIK_LOCATION_DETAILED_INSPECTION_FOR_ROUTING }
                                          layout={ LayoutWithSidebar } component={ LocationInspectionDetails }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_EKOSTRAZNIK_GROUP_DETAILED_INSPECTION_FOR_ROUTING }
                                          layout={ LayoutWithSidebar } component={ GroupInspectionDetails }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_EKOSTRAZNIK_INSPECTION_DETAILS_FOR_ROUTING }
                                          layout={ LayoutWithSidebar } component={ BinInspectionDetails }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_EKOSTRAZNIK_REPORTS }
                                          layout={ LayoutWithSidebar } component={ EkostraznikReports }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_EKOSTRAZNIK_INVENTORIES }
                                          layout={ LayoutWithSidebar } component={ InventoryHistory }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_EKOSTRAZNIK_INVENTORIES_DETAILS_FOR_ROUTING }
                                          layout={ LayoutWithSidebar } component={ InventoryDetails }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_EKOSTRAZNIK_COMPANIES }
                                          layout={ LayoutWithSidebar } component={ InspectionCompanies }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_EKOSTRAZNIK_ADDRESSES_GROUPED_ADDRESSES }
                                          layout={ LayoutWithSidebar } component={ EkostraznikAddressGroups }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact
                                          path={ ROUTE_EKOSTRAZNIK_ADDRESSES_DETAILED_GROUPED_ADDRESSES_FOR_ROUTING }
                                          layout={ LayoutWithSidebar } component={ AddressGroupDetails }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_EKOSTRAZNIK_ADDRESSES_APARTMENT_ADDRESS }
                                          layout={ LayoutWithSidebar } component={ ApartmentAddresses }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_EKOSTRAZNIK_ADDRESSES_QR_CODES }
                                          layout={ LayoutWithSidebar } component={ EkostraznikQrCodes }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_BLISKO }
                                          layout={ LayoutWithSidebar } component={ BliskoModule }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_BLISKO_MESSAGES }
                                          layout={ LayoutWithSidebar } component={ Messages }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_BLISKO_MESSAGE_SEND }
                                          layout={ LayoutWithSidebar } component={ MessageSendingBlisko }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_BLISKO_MESSAGE_PREVIEW_FOR_ROUTING }
                                          layout={ LayoutWithSidebar } component={ MessagePreviewBlisko }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_BLISKO_DRAFT_MESSAGE_EDIT_FOR_ROUTING }
                                          layout={ LayoutWithSidebar } component={ MessageSendingBlisko }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_BLISKO_ADDRESS_TEMPLATE }
                                          layout={ LayoutWithSidebar } component={ AddressTemplate }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_BLISKO_MESSAGE_TEMPLATE }
                                          layout={ LayoutWithSidebar } component={ MessageTemplate }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_BLISKO_ADDRESS_TEMPLATE_LOCATIONS_FOR_ROUTING }
                                          layout={ LayoutWithSidebar } component={ AddressTemplateLocations }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_BLISKO_RECEIVER_GROUP }
                                          layout={ LayoutWithSidebar } component={ ReceiverGroups }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_BLISKO_RECEIVER_GROUP_ADD }
                                          layout={ LayoutWithSidebar } component={ ReceiverGroup }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_BLISKO_RECEIVER_GROUP_EDIT_FOR_ROUTING }
                                          layout={ LayoutWithSidebar } component={ ReceiverGroup }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_BLISKO_MESSAGE_TEMPLATE_ADD }
                                          layout={ LayoutWithSidebar } component={ AddAndEditMessageTemplate }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_BLISKO_MESSAGE_TEMPLATE_EDIT_FOR_ROUTING }
                                          layout={ LayoutWithSidebar } component={ AddAndEditMessageTemplate }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                        <SignedInAppRoute exact path={ ROUTE_BLISKO_SENDER_DETAILS }
                                          layout={ LayoutWithSidebar } component={ SenderDetails }
                                          authentication={ authentication } storageLoaded={ storageLoaded }/>

                    </Switch>
                </div>
            </Router>
        );
    };

    componentDidMount = () => {
        initializeTinyMCE();
    };
}

const mapStateToProps = (state) => {
    return {
        authentication: state.authentication,
        storageLoaded: state.misc.storage.loaded
    };
};

export default connect(mapStateToProps)(Routing);
