import React from "react";

const BulkyWasteDeleteFormContent = () => {

    return (
        <div className='c-modal__body modal-size-m modal--no-header'>
            <p className="confirmation-alert">
                Czy na pewno chcesz usunąć tą lokalizację?
            </p>
        </div>
    );
};

export default BulkyWasteDeleteFormContent;