import React, { useEffect } from "react";

import Header from "../../../../common/header";
import { Field, Form, Formik } from "formik";
import { BottomCustomButtons } from "../../../../common/bottom_action_buttons";
import { useDispatch, useSelector } from "react-redux";
import { getSenderDetails, updateSenderDetails } from "../../../../../data/actions/blisko/sender_details";
import { toastForCustomError, toastForCustomSuccess } from "../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../data/actions/common/ui/toast";
import { is4xx, is5xx } from "../../../../../core/utils/api_utils";

const initialFormValues = {
    description: "",
    address: "",
    phoneNumber: "",
    email: "",
    website: "",
    openHours: "",
};

const SenderDetails = () => {
    const dispatch = useDispatch();
    const senderDetails = useSelector(state => state.entities?.blisko?.senderDetails?.get);

    useEffect(() => {
        dispatch(getSenderDetails());
    }, [dispatch]);

    const _onSubmit = (formValues) => {
        dispatch(updateSenderDetails(formValues))
            .then(response => {
                if (is4xx(response?.status) || is5xx(response?.status)) {
                    toastForCustomError((...args) => dispatch(showToast(...args)), "Aktualizacja wizytówki nie powiodła się. Spróbuj ponownie");
                } else {
                    toastForCustomSuccess((...args) => dispatch(showToast(...args)), "Wizytówka została zaktualizowana");
                    dispatch(getSenderDetails());
                }
            });
    }

    const initializeFormValues = () => senderDetails?.data || initialFormValues;

    return (
        <div className="main-content--table">
            <Header>
                Wizytówka nadawcy
            </Header>

            <Formik initialValues={ initializeFormValues() }
                    onSubmit={ _onSubmit }
                    enableReinitialize={ true }
                    render={ () => (
                        <Form className="has-bottom-action-buttons">
                            <section className="content--s content--not-centered">
                                <section>
                                    <p className="is-spaced--bottom-30">
                                        Możesz uzupełnić dane, które wyświetlą się w dodatkowych informacjach o
                                        nadawcy w aplikacji Blisko.
                                    </p>
                                </section>
                                <section className="is-spaced--bottom-30">
                                    <Field
                                        name="description"
                                        id="description"
                                        render={ ({ field }) => (
                                            <label>
                                                <span className='label-name'>Dodatkowy opis:</span>
                                                <textarea
                                                    { ...field }/>
                                            </label>
                                        ) }/>
                                    <Field
                                        name="address"
                                        id="address"
                                        render={ ({ field }) => (
                                            <div className="is-spaced--top-20">
                                                <label>
                                                    <span className='label-name'>Adres</span>
                                                    <input
                                                        { ...field }/>
                                                </label>
                                            </div>
                                        ) }/>
                                    <Field
                                        name="phoneNumber"
                                        id="phoneNumber"
                                        render={ ({ field }) => (
                                            <div className="is-spaced--top-20">
                                                <label>
                                                    <span className='label-name'>Nr telefonu</span>
                                                    <input
                                                        { ...field }/>
                                                </label>
                                            </div>
                                        ) }/>
                                    <Field
                                        name="email"
                                        id="email"
                                        render={ ({ field }) => (
                                            <div className="is-spaced--top-20">
                                                <label>
                                                    <span className='label-name'>E-mail</span>
                                                    <input
                                                        { ...field }/>
                                                </label>
                                            </div>
                                        ) }/>
                                    <Field
                                        name="website"
                                        id="website"
                                        render={ ({ field }) => (
                                            <div className="is-spaced--top-20">
                                                <label>
                                                    <span className='label-name'>Adres www</span>
                                                    <input
                                                        { ...field }/>
                                                </label>
                                            </div>
                                        ) }/>
                                    <Field
                                        name="openHours"
                                        id="openHours"
                                        render={ ({ field }) => (
                                            <div className="is-spaced--top-30">
                                                <label>
                                                    <span className='label-name'>Godziny otwarcia</span>
                                                    <textarea
                                                        { ...field }
                                                        placeholder={ "Np.:\nPoniedziałek - środa: 8:00 - 17:00\nCzwartek - piątek: 8:30 - 17:30" }/>
                                                </label>
                                            </div>
                                        ) }/>
                                </section>
                            </section>

                            <BottomCustomButtons
                                containerClass="content--m content--not-centered"
                                buttonSpacingClass="space-between"
                            >
                                <button className="btn btn--primary btn--medium" type="submit">
                                    Zapisz zmiany
                                </button>
                            </BottomCustomButtons>
                        </Form>
                    ) }/>
        </div>
    )
};

export default SenderDetails;