import React, { Component } from "react";
import { connect } from "react-redux";
import LogsHeader from "../common/header";
import { UserRecordsEnum } from "../../../../../core/enums/common/logs";
import UserConsentsItem from "./item";
import { getUserConsents, getUserConsentsExport } from "../../../../../data/actions/common/logs";
import Loader from "../../../../common/loader";
import { isNullOrUndefined } from "../../../../../core/utils/misc_utils";


class UserConsents extends Component {


    render = () => {
        const { userConsents } = this.props;


        if (userConsents.meta.loading) {
            return (
                <>
                    <LogsHeader tab={ UserRecordsEnum.tab.USER_CONSENTS }/>
                    <Loader/>
                </>
            );
        }

        return (
            <div className="side-spacing">
                <LogsHeader tab={ UserRecordsEnum.tab.USER_CONSENTS }/>

                <div className="row row--options justify-end">
                    <button onClick={ this.userConsentsExportDownloadTrigger } className="btn btn--primary btn--medium">
                        Pobierz raport
                    </button>
                </div>

                <div className="options-table">
                    <div className='table-wrapper'>
                        <table className='table--single-line fixed'>
                            <thead>
                            <tr>
                                <th className='t-width-3'>Użytkownik</th>
                                <th className='t-width-2'>Nadawca</th>
                                <th className='t-width-2'>Akcja</th>
                                <th className='t-width-2'>Data akcji</th>
                                <th className='t-width-3'>Rodzaj zgody</th>
                            </tr>
                            </thead>
                            <tbody>

                            {
                                this.renderUserConsentsList()
                            }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        );

    };

    componentDidMount = () => {
        this.props.getUserConsents();
    };

    userConsentsExportDownloadTrigger = () => {
        this.props.getUserConsentsExport();
    };

    renderUserConsentsList = () => {
        const { userConsents } = this.props;

        if (isNullOrUndefined(userConsents.data)) {
            return;
        }

        const userConsentsToRender = userConsents.data
                                                 .map((userConsent) => {
                                                         return <UserConsentsItem key={ `${userConsent.dateOfAction}${userConsent.user}${userConsent.regulationType}` }
                                                                                  userConsent={ userConsent }
                                                         />;
                                                     }
                                                 );

        return userConsentsToRender;
    };
}

const mapStateToProps = (state) => {
    return {
        userConsents: state.entities.common.userConsents.get,
        userConsentsExport: state.entities.common.userConsents.getExport
    };
};


export default connect(mapStateToProps, { getUserConsents, getUserConsentsExport })(UserConsents);
