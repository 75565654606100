import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import "moment/locale/pl";

import FormModal from "../../../../../common/modal/form_modal";
import CreateAndEditReportModalFormContent from "./content";
import { ReactComponent as IconAdd } from "../../../../../../../resource/img/icons/icon_add_white.svg";
import { EkostraznikReportsEnum } from "../../../../../../core/enums/ekostraznik/reports";
import { addReport, getManyReports } from "../../../../../../data/actions/ekostraznik/reports";
import {
    filesToBase64,
    isEmptyArray,
    isEmptyObject
} from "../../../../../../core/utils/misc_utils";
import { toastFor500, toastForSuccess } from "../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../data/actions/common/ui/toast";
import _ from "lodash";
import withLocationManagement from "../../../../../common/hoc/with_location_management";

const initialFormValues = {
    binCode: "",
    description: "",
    images: [],
    groupId: '',
    address: [],
};

class CreateEkostraznikReportModalForm extends Component {

    state = {
        selectedType: "",
        typeOptions: []
    };

    render = () => {
        const {
            reportsAdd,
            // withLocationManagementProps
            values, renderSuggestions, inputDisabled, _setFieldValue, _onInputFieldFocus, _onInputFieldBlur, _resetForm
        } = this.props;
        const { selectedType, typeOptions } = this.state;

        return (
            <Formik
                initialValues={ initialFormValues }
                render={ formikBag =>

                    <FormModal
                        triggerComponent={
                            <button className="btn btn--big btn--primary">
                                <span className="btn__icon-wrapper--left">
                                    <span className='btn__icon'>
                                        <IconAdd/>
                                    </span>
                                    Dodaj zgłoszenie
                                </span>
                            </button>
                        }
                        isLoading={reportsAdd?.meta?.loading}
                        headerText="Dodaj zgłoszenie"
                        saveOrCancelButtons={ true }
                        _actionFunction={ () => {
                            return this._onSubmit(formikBag);
                        } }>

                        <CreateAndEditReportModalFormContent
                            withLocationManagementProps={ { values, renderSuggestions, inputDisabled, _setFieldValue, _onInputFieldFocus, _onInputFieldBlur, _resetForm } }
                            selectedType={ selectedType }
                            typeOptions={ typeOptions }
                            formikBag={ formikBag }
                            errors={ formikBag.errors }
                            _setFormValues={ this._setFormValues }
                            _handleSelectedTypeChange={ this._handleSelectedTypeChange }/>

                    </FormModal>
                }/>
        );
    };

    componentDidMount = () => {
        this.setState({
            selectedType: this.generateTypeOptions()[0],
            typeOptions: this.generateTypeOptions()
        });
    };
    _setFormValues = (formikBag, item) => {
        if (formikBag && item) {
            formikBag.resetForm(item);
        } else if (formikBag) {
            formikBag.resetForm({
                ...initialFormValues,
                images: []
            });
        }
    };


    generateTypeOptions = () => {
        const { reportTypes } = this.props;

        return _.map(reportTypes?.data || [], type => {
            return {
                label: type.name,
                value: type.type
            };
        });
    };

    _handleSelectedTypeChange = (type, formikBag) => {
        this.setState({
            selectedType: type
        }, () => {
            formikBag.setErrors({});
        });
    };

    _validate = (formValues) => {
        const { selectedType } = this.state;
        const errors = {};

        if (!formValues.address.selectedFrom && !formValues.groupId && !formValues.address.selectedApartmentAddress) { 
            errors.address = "Pole wymagane";
        }

        if (!formValues.companyId) {
            errors.companyId = "Pole wymagane";
        }

        if (selectedType.value !== EkostraznikReportsEnum.type.MISSING_WASTE_BIN.code.toLowerCase() &&
            selectedType.value !== EkostraznikReportsEnum.type.OTHER.code.toLowerCase()) {

            if (isEmptyArray(formValues.images)) {
                errors.images = "Dodaj minimum 1 zdjęcie";
            }
        }

        if (selectedType.value !== EkostraznikReportsEnum.type.OTHER.code.toLowerCase()) {

            if (!formValues.binCode) {
                errors.binCode = "Pole wymagane";
            }
        }

        if (selectedType.value === EkostraznikReportsEnum.type.OTHER.code.toLowerCase()) {

            if (!formValues.description) {
                errors.description = "Pole wymagane";
            }
        }

        return errors;
    };

    _onSubmit = (formikBag) => {
        const { addReport, showToast } = this.props;
        const { selectedType } = this.state;

        const errors = this._validate(formikBag.values);

        if (!isEmptyObject(errors)) {
            formikBag.setErrors(errors);
            return Promise.reject(null);
        }

        return filesToBase64(formikBag.values.images)
            .then(base64Images => 
                addReport({
                    binCode: formikBag.values.binCode,
                    type: selectedType.value,
                    location: formikBag.values.address.selectedFrom?.id || formikBag.values.address.selectedApartmentAddress?.id,
                    images: base64Images,
                    companyId: Number(formikBag.values.companyId),
                    group: formikBag.values.groupId,
                    description: formikBag.values.description
                }))
            .then(() => {
                const { reportsAdd } = this.props;
                toastFor500(showToast, reportsAdd, "Dodanie zgłoszenia nie powiodło się. Spróbuj ponownie");
                toastForSuccess(showToast, reportsAdd, "Nowe zgłoszenie zostało dodane");
            });
    };
}

const mapStateToProps = (state) => {
    return {
        reportsAdd: state.entities.ekostraznik.reports.add,
        reportTypes: state.entities.ekostraznik.reports.getTypes
    };
};

export default withLocationManagement(connect(mapStateToProps, { addReport, getManyReports, showToast })(CreateEkostraznikReportModalForm));
