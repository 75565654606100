import React, { Component } from "react";
import { connect } from "react-redux";

import FormModal from "../../../../../../common/modal/form_modal";
import { deleteWasteCollectionPoint } from "../../../../../../../data/actions/waste_management/waste_collection_points";
import { invoke, isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";

class WasteCollectionPointDeleteModalForm extends Component {

    render = () => {
        const { triggerComponent } = this.props;

        return (
            <FormModal
                triggerComponent={ triggerComponent }
                deleteOrCancelButtons={ true }
                _actionFunction={ () => {
                    return this._onSubmit();
                } }>

                <div className='c-modal__body modal-size-m modal--no-header'>
                    <p className="confirmation-alert">
                        Czy na pewno chcesz usunąć ten punkt zbiórki?
                    </p>
                </div>

            </FormModal>
        );
    };

    _onSubmit = () => {
        const { wasteCollectionPoint, deleteWasteCollectionPoint, _onSubmitCallback } = this.props;

        if (!isNullOrUndefined(wasteCollectionPoint)) {
            return deleteWasteCollectionPoint(wasteCollectionPoint.id)
                .then(() => invoke(_onSubmitCallback));
        }
    }
}

export default connect(null, { deleteWasteCollectionPoint })(WasteCollectionPointDeleteModalForm);