import React from "react";

export const BottomLeftSave = ({ containerClass, additionalElementClass }) => {

    return (
        <div className={ `bottom-action-buttons ${additionalElementClass ? additionalElementClass : ""}` }>
            <div className={ containerClass ? containerClass : "container is-fluid" }>
                <div className="bottom-action-buttons__content bottom-left-save">
                    <button type="submit" className="btn btn--primary btn--medium">
                        Zapisz zmiany
                    </button>
                </div>
            </div>
        </div>
    );
};

export const BottomCustomButtons = ({ additionalElementClass, containerClass, buttonSpacingClass, children }) => {

    return (
        <>
            <div className={ `bottom-action-buttons ${additionalElementClass ? additionalElementClass : ""}` }>
                <div className={ containerClass ? containerClass : "container is-fluid" }>
                    <div className={`bottom-action-buttons__content ${ buttonSpacingClass ? buttonSpacingClass : "" } `}>
                        {
                            children
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

