import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import ComponentWithDropdown from "../../common/component_with_dropdown";
import TitleWithDivider from "../../common/title_with_divider";
import {
    ROUTE_ACCOUNTS,
    ROUTE_INSTRUCTIONS,
    ROUTE_LOGS_USER_CONSENTS,
    ROUTE_LOGS_USER_SUBSCRIPTIONS,
    ROUTE_SETTINGS_ACCESSIBILITY_STATEMENT,
    ROUTE_SETTINGS_PASSWORD_CHANGE,
    ROUTE_SETTINGS_REGULATIONS
} from "../../../core/constants";
import { resetSidebar } from "../../../data/actions/common/ui/sidebar";

import { ReactComponent as IconCancel } from "../../../../resource/img/icons/icon_cancel_x.svg";
import { ReactComponent as IconArrowDown } from "../../../../resource/img/icons/icon_chevron_down_dark.svg";
import { ReactComponent as IconUser } from "../../../../resource/img/icons/icon_user.svg";
import { signOutRemotely } from "../../../data/actions/common/authentication";
import RoleEnum from "../../../core/enums/common/role";

class AdminHeaderDropdownUser extends ComponentWithDropdown {

    render = () => {
        const { dropdownOpen = false } = this.state;
        const { account, ownerName, resetSidebar, signOutRemotely } = this.props;
        const { name, surname, email, role } = account?.data || {};

        return (
            <button
                className="admin-navbar__navbar-item admin-navbar__navbar-item--user"
                onClick={ this._onDropdownOpen }>
                <span className='icon-wrapper icon-wrapper--with-bg '>
                    <IconUser/>
                </span>

                <div className="admin-navbar__desktop-name">
                    <p className="admin-navbar__desktop-name-field prevent-long-text">
                        { name } { surname }
                    </p>
                    <p className="admin-navbar__desktop-email-field prevent-long-text">
                        {
                            ownerName
                        }
                    </p>
                </div>

                <div className={ `dropdown  ${ dropdownOpen ? "is-active" : null }` }>
                    <div className="dropdown-trigger">
                        <div className="admin-navbar__user-icon" title='Więcej'>
                            {
                                dropdownOpen
                                    ? <IconCancel/>
                                    : <IconArrowDown/>
                            }
                        </div>
                    </div>
                    <div className="dropdown-size-l drop-to-right admin-navbar__dropdown-menu">
                        <div className="dropdown-content admin-navbar__dropdown-content">
                            <div className="admin-navbar__dropdown-item admin-navbar__dropdown-item--mobile-user">
                                <p className="admin-navbar__dropdown-item-mobile-user-name prevent-long-text">{ name } { surname }</p>
                                <p className="admin-navbar__dropdown-item-mobile-user-email prevent-long-text">{ email }</p>
                            </div>


                            <>
                                <div className="admin-navbar__dropdown-item admin-navbar__dropdown-item--title-divider">
                                    <TitleWithDivider>
                                        Ustawienia
                                    </TitleWithDivider>
                                </div>

                                <Link to={ ROUTE_SETTINGS_PASSWORD_CHANGE } className="admin-navbar__dropdown-item">
                                    Zmiana hasła
                                </Link>
                                
                                <Link to={ ROUTE_SETTINGS_REGULATIONS } className="admin-navbar__dropdown-item">
                                    Regulaminy
                                </Link>

                                <Link to={ ROUTE_SETTINGS_ACCESSIBILITY_STATEMENT } className="admin-navbar__dropdown-item">
                                    Deklaracja dostępności
                                </Link>

                                <div className="admin-navbar__dropdown-item admin-navbar__dropdown-item--title-divider">
                                    <TitleWithDivider>
                                        Raporty
                                    </TitleWithDivider>
                                </div>

                                <Link to={ ROUTE_LOGS_USER_CONSENTS } className="admin-navbar__dropdown-item">
                                    Zgody użytkowników
                                </Link>

                                <Link to={ ROUTE_LOGS_USER_SUBSCRIPTIONS } className="admin-navbar__dropdown-item">
                                    Subskrypcje użytkowników
                                </Link>
                            </>


                            {
                                role === RoleEnum.ADMINISTRATOR &&
                                <>
                                    <hr className="admin-navbar__dropdown-divider"/>
                                    <Link to={ ROUTE_ACCOUNTS }
                                          onClick={ resetSidebar }
                                          className="admin-navbar__dropdown-item">
                                        Konta administracyjne
                                    </Link>
                                </>
                            }

                            <hr className="admin-navbar__dropdown-divider"/>
                            <Link to={ ROUTE_INSTRUCTIONS }
                                  onClick={ resetSidebar }
                                  className="admin-navbar__dropdown-item">
                                Instrukcje obsługi panelu
                            </Link>
                            <hr className="admin-navbar__dropdown-divider"/>
                            <span onClick={ signOutRemotely }
                                  className="admin-navbar__dropdown-item">
                                Wyloguj
                            </span>
                        </div>
                    </div>
                </div>
            </button>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        account: state.entities?.common?.account?.get,
        ownerName: state.authentication?.data?.ownerName
    };
};

export default connect(mapStateToProps, { resetSidebar, signOutRemotely })(AdminHeaderDropdownUser);