import webClient, { webClientFileDownload } from "../../../core/web_client";
import { API_BASE_AKUN_EKOSTRAZNIK_PATH } from "../../../core/constants";
import { buildQueryString } from "../../../core/utils/api_utils";

const PATH = `${ API_BASE_AKUN_EKOSTRAZNIK_PATH }/qr-codes`;

export const generate = (binIds, ignoreGenerated, listOnly) => {
    const command = listOnly ?  'generate-list' : 'generate-all';
    const data = {
        command,
        binIds,
        missingOnly: ignoreGenerated
    };

    return webClient
        .patch(`${ PATH }`, data);
};

export const getMany = (queryParams) => {
    return webClient
        .get(`${ PATH }${buildQueryString(queryParams)}`);
}

export const getPackage = (qrCodeDocumentId) => {
    return webClientFileDownload
        .get(`${ PATH }/${qrCodeDocumentId}/zip`);
}
