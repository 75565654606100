import addToCategory from "./add_to_category";
import getMany from "./get_many";
import getManyForCategory from "./get_many_for_category";
import update from "./update";

export default (state = {}, action) => {
    return {
        ...state,
        addToCategory: addToCategory(state.addToCategory, action),
        getMany: getMany(state.getMany, action),
        getManyForCategory: getManyForCategory(state.getManyForCategory, action),
        update: update(state.update, action)
    };
}