import _ from "lodash";
import FormModal from "../../../../../../../common/modal/form_modal";
import { isEmptyObject } from "../../../../../../../../core/utils/misc_utils";
import { copyReceptionFromAreaToArea, } from "../../../../../../../../data/actions/waste_management/receptions";
import FormRequiredLegend from "../../../../../../../common/legend_required";
import Select from "../../../../../../../common/select";
import CheckboxGroup from "../../../../../../../common/checkbox_group";
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import InfoBanner from "../../../../../../../common/info_banner";

const ByAreaCopyForm = ({ bins, areas, selectedYear }) => {
    const dispatch = useDispatch();
    const copyStatus = useSelector(state => {
        return state.entities?.wasteManagement?.receptions?.copy
    });

    const initialValues = {
        areaIdsToUpdate: [],
        binIdsToUpdate: [],
        sourceAreaId: null
    };

    const validate = useCallback(values => (_.pickBy({
        sourceAreaId: !values.sourceAreaId ? 'Pole wymagane' : undefined,
        areaIdsToUpdate: values.areaIdsToUpdate.length < 1 ? 'Pole wymagane' : undefined,
        binIdsToUpdate: values.binIdsToUpdate.length < 1 ? 'Wybierz przynajmniej jedną opcję' : undefined,
    })), [])

    if (!bins || !areas.data) {
        return '';
    }

    const areaOptions = Object.values(areas.data || {}).map(area => ({ label: area.name, value: area.id }));

    return (
        <Formik
            initialValues={initialValues}
        >
            {
                formik => (
                    <FormModal
                        isLoading={ copyStatus?.meta?.loading }
                        triggerComponent={ <span className="unstyled-btn dropdown-item">Kopiuj harmonogram między obszarami</span> }
                        saveOrCancelButtons={ true }
                        headerText="Kopiuj harmonogram między obszarami"
                        saveButtonText="Kopiuj"
                        _actionFunction={ async () => {
                            const errors = validate(formik.values);
                            if (!isEmptyObject(errors)) {
                                formik.setErrors(errors);
                                return Promise.reject(null);
                            }
                            dispatch(copyReceptionFromAreaToArea(formik.values, selectedYear))
                        } }
                    >
                        <div className='c-modal__body__base modal-size-m'>
                            <div className='is-relative is-spaced--bottom-20'>
                                <FormRequiredLegend/>
                            </div>
                            <Form>
                                <div className='flex-column is-spaced--top-10'>
                                    <div className='field-group-header is-spaced--bottom-10'>
                                        Kopiuj harmonogram z:
                                    </div>
                                    <div>
                                        <span className="required-accent">*</span>
                                        <span className='label-name'>Obszar, którego dotyczy kopiowanie</span>
                                        <Select
                                            className={formik.errors.sourceAreaId ? 'rejected' : ''}
                                            isSearchable
                                            options={ areaOptions }
                                            onChange={ option => {
                                                formik.setFieldValue('sourceAreaId', option?.value)
                                            } }
                                        />
                                        {
                                            formik.errors.sourceAreaId &&
                                            <strong className="hint hint--rejected">{ formik.errors.sourceAreaId }</strong>
                                        }
                                    </div>
                                    <div className='is-spaced--top-30'>
                                        <div className='is-spaced--bottom-10'>
                                            <span className="required-accent">*</span>
                                            <span className='label-name'>Wybierz frakcje, które chcesz skopiować</span>
                                        </div>

                                        <CheckboxGroup
                                            options={ Object.values(bins).map(bin => ({
                                                label: bin.name,
                                                value: bin.id
                                            })) }
                                            onChange={ options => {
                                                formik.setFieldValue('binIdsToUpdate', (options || []).map(option => option.value))
                                            }}
                                        />
                                        {
                                            formik.errors.binIdsToUpdate &&
                                            <strong className="hint hint--rejected">{ formik.errors.binIdsToUpdate }</strong>
                                        }
                                    </div>
                                    <div className='field-group-header is-spaced--top-30'>
                                        Wklej na:
                                    </div>
                                    <div className='is-spaced--top-20'>
                                        <span className="required-accent">*</span>
                                        <span className='label-name'>Wybierz obszary</span>
                                        <Select
                                            className={formik.errors.areaIdsToUpdate ? 'rejected' : '' }
                                            isMulti
                                            isSearchable
                                            options={ areaOptions }
                                            onChange={ options => {
                                                formik.setFieldValue('areaIdsToUpdate', (options || []).map(option => option.value))
                                            } }
                                        />
                                        {
                                            formik.errors.areaIdsToUpdate &&
                                            <strong className="hint hint--rejected">{ formik.errors.areaIdsToUpdate }</strong>
                                        }
                                    </div>
                                </div>
                            </Form>
                            <InfoBanner>
                                Przed publikacją harmonogramu zwróć uwagę na dni wolne od pracy.
                            </InfoBanner>
                        </div>

                    </FormModal>
                )
            }
        </Formik>
    );
}
export default ByAreaCopyForm;
