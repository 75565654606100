import { actions } from "../../../core/constants";
import * as api from "../../_api";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const getManyMessageTemplatesPaginated = (queryParams) => dispatch => {
    dispatch({
        type: actions.blisko.API__BLISKO__MESSAGE_TEMPLATES__GET_MANY_PAGINATED__STARTED
    });

    return api
        .blisko
        .messageTemplates
        .getManyPaginated(queryParams)
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__MESSAGE_TEMPLATES__GET_MANY_PAGINATED__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__MESSAGE_TEMPLATES__GET_MANY_PAGINATED__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });

};

export const getMessageTemplate = (id) => dispatch => {
    dispatch({
        type: actions.blisko.API__BLISKO__MESSAGE_TEMPLATES__GET__STARTED
    });

    return api
        .blisko
        .messageTemplates
        .get(id)
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__MESSAGE_TEMPLATES__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__MESSAGE_TEMPLATES__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const addMessageTemplate = (data) => dispatch => {
    dispatch({
        type: actions.blisko.API__BLISKO__MESSAGE_TEMPLATES__ADD__STARTED
    });

    return api
        .blisko
        .messageTemplates
        .addMessageTemplate(data)
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__MESSAGE_TEMPLATES__ADD__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__MESSAGE_TEMPLATES__ADD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });

            return error.response;
        });
};

export const updateMessageTemplate = (id, data) => dispatch => {
    dispatch({
        type: actions.blisko.API__BLISKO__MESSAGE_TEMPLATES__UPDATE__STARTED
    });

    return api
        .blisko
        .messageTemplates
        .updateMessageTemplate(id, data)
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__MESSAGE_TEMPLATES__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__MESSAGE_TEMPLATES__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });

            return error.response;
        });
};

export const deleteManyMessageTemplates = (data) => dispatch => {
    dispatch({
        type: actions.blisko.API__BLISKO__MESSAGE_TEMPLATES__DELETE_MANY__STARTED
    });

    return api
        .blisko
        .messageTemplates
        .deleteMany(data)
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__MESSAGE_TEMPLATES__DELETE_MANY__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__MESSAGE_TEMPLATES__DELETE_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const deleteMessageTemplate = (templateId) => dispatch => {
    dispatch({
        type: actions.blisko.API__BLISKO__MESSAGE_TEMPLATES__DELETE__STARTED
    });

    return api
        .blisko
        .messageTemplates
        .deleteMessageTemplate(templateId)
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__MESSAGE_TEMPLATES__DELETE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__MESSAGE_TEMPLATES__DELETE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};