import React, { Component } from "react";
import { connect } from "react-redux";
import withModuleMainPage from "../../../common/hoc/with_module_main_page";
import { SidebarEnum } from "../../../../core/enums/common/sidebar";
import { isLoadingError } from "../../../../core/utils/misc_utils";
import { PermissionEnum } from "../../../../core/enums/common/permissions";
import { setSidebarItemSelected } from "../../../../data/actions/common/ui/sidebar";
import ModuleCategoryTile from "../../../common/module_category_tile";

class BliskoModule extends Component {

    render = () => {
        const { authentication, _onSubmenuItemSelected } = this.props;

        if (isLoadingError(authentication) || !authentication?.data?.permissions) {
            return null;
        }

        const { permissions } = authentication.data;

        return (
            <div>
                <div className="container is-fluid">
                    <header className="header--basic">
                        <h1 className="title is-1">
                            { SidebarEnum.BLISKO.name }

                        </h1>
                        <p>Wybierz kategorię</p>
                    </header>
                    <main>
                        <div className=''>
                            <div className='columns is-multiline'>
                                {
                                    permissions[PermissionEnum.module.BLISKO.type.MESSAGES.full_code] &&
                                    <ModuleCategoryTile
                                        to={ SidebarEnum.BLISKO.MESSAGES.link }
                                        name={ SidebarEnum.BLISKO.MESSAGES.name }
                                        description={ SidebarEnum.BLISKO.MESSAGES.description }
                                        onClick={ () => _onSubmenuItemSelected(SidebarEnum.BLISKO.MESSAGES.id) }
                                        cssSubclass='messages_blisko'
                                    />
                                }
                                {
                                    permissions[PermissionEnum.module.BLISKO.type.RECEIVER_GROUP.full_code] &&
                                    <ModuleCategoryTile
                                        to={ SidebarEnum.BLISKO.RECEIVER_GROUP.link }
                                        name={ SidebarEnum.BLISKO.RECEIVER_GROUP.name }
                                        description={ SidebarEnum.BLISKO.RECEIVER_GROUP.description }
                                        onClick={ () => _onSubmenuItemSelected(SidebarEnum.BLISKO.RECEIVER_GROUP.id) }
                                        cssSubclass='receiver-group'
                                    />
                                }
                                {
                                    permissions[PermissionEnum.module.BLISKO.type.ADDRESS_TEMPLATE.full_code] &&
                                    <ModuleCategoryTile
                                        to={ SidebarEnum.BLISKO.TEMPLATES.ADDRESS_TEMPLATE.link }
                                        name={ SidebarEnum.BLISKO.TEMPLATES.ADDRESS_TEMPLATE.name }
                                        description={ SidebarEnum.BLISKO.TEMPLATES.ADDRESS_TEMPLATE.description }
                                        onClick={ () => _onSubmenuItemSelected(SidebarEnum.BLISKO.TEMPLATES.ADDRESS_TEMPLATE.id) }
                                        cssSubclass='address-template'
                                    />
                                }
                                {
                                    permissions[PermissionEnum.module.BLISKO.type.MESSAGE_TEMPLATE.full_code] &&
                                    <ModuleCategoryTile
                                        to={ SidebarEnum.BLISKO.TEMPLATES.MESSAGE_TEMPLATE.link }
                                        name={ SidebarEnum.BLISKO.TEMPLATES.MESSAGE_TEMPLATE.name }
                                        description={ SidebarEnum.BLISKO.TEMPLATES.MESSAGE_TEMPLATE.description }
                                        onClick={ () => _onSubmenuItemSelected(SidebarEnum.BLISKO.TEMPLATES.MESSAGE_TEMPLATE.id) }
                                        cssSubclass='address-template'
                                    />
                                }
                                {
                                    permissions[PermissionEnum.module.BLISKO.type.SENDER_DETAILS.full_code] &&
                                    <ModuleCategoryTile
                                        to={ SidebarEnum.BLISKO.SENDER_DETAILS.link }
                                        name={ SidebarEnum.BLISKO.SENDER_DETAILS.name }
                                        description={ SidebarEnum.BLISKO.SENDER_DETAILS.description }
                                        onClick={ () => _onSubmenuItemSelected(SidebarEnum.BLISKO.SENDER_DETAILS.id) }
                                        cssSubclass='sender-details'
                                    />
                                }
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        authentication: state.authentication
    };
};

export default withModuleMainPage(connect(mapStateToProps, { setSidebarItemSelected })(BliskoModule));
