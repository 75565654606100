import webClient from "../../../core/web_client";
import { API_BASE_AKUN_EKOSTRAZNIK_PATH } from "../../../core/constants";
import { buildQueryString } from "../../../core/utils/api_utils";

const PATH = `${ API_BASE_AKUN_EKOSTRAZNIK_PATH }/address-groups`;

export const getMany = (queryParams) => {
    return webClient
        .get(`${ PATH }${buildQueryString(queryParams)}`);
};

export const add = (data) => {
    return webClient
        .post(PATH, data);
};

export const update = (id, data) => {
    return webClient
        .put(`${ PATH }/${id}`, data);
};

export const deleteMany = (data) => {
    return webClient
        .delete(PATH, { data });
};

export const get = (id) => {
    return webClient
        .get(`${ PATH }/${id}`)
        .then(response => ({
            ...response,
            data: {
                ...response.data,
                nests: response.data.nests.map(nest => ({
                    ...nest,
                    longitude: nest.locationPoints[0].longitude,
                    latitude: nest.locationPoints[0].latitude,
                }))
            }
        }));
};