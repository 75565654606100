import React from "react";

import BulkyWasteCreateAndEditModalFormContentDateSelectedItem from "./item";
import { isEmptyArray, isNullOrUndefined } from "../../../../../../../../../../core/utils/misc_utils";

const BulkyWasteCreateAndEditModalFormContentDateSelected = ({ dates, readOnly, _onSelectedDayClick }) => {

    if (isNullOrUndefined(dates) || isEmptyArray(dates)) {
        return null;
    }

    return (
        <div className="selected-dates">
            <span className="selected-dates__title">Wybrano: </span>
            <div>

                {
                    dates.map(date => {
                        return (
                            <BulkyWasteCreateAndEditModalFormContentDateSelectedItem
                                key={ date }
                                date={ date }
                                readOnly={ readOnly }
                                _onSelectedDayClick={ _onSelectedDayClick }/>
                        );
                    })
                }

            </div>
        </div>
    );
};

export default BulkyWasteCreateAndEditModalFormContentDateSelected;