import add from "./add";
import _delete from "./delete";
import getMany from "./get_many";

export default (state = {}, action) => {
    return {
        ...state,
        add: add(state.add, action),
        _delete: _delete(state._delete, action),
        getMany: getMany(state.getMany, action)
    };
}