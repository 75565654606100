import React, { Component } from "react";
import { connect } from "react-redux";

import AccountDetailsHeader from "./header";
import AccountDetailsContent from "./content";
import { isNullOrUndefinedOneOfMany } from "../../../../../core/utils/misc_utils";

class AccountDetails extends Component {

    render = () => {
        const { employees, match } = this.props;
        const accountId = match.params.accountId;

        if (isNullOrUndefinedOneOfMany(employees, employees.data, accountId)) {
            return null;
        }

        return (
            <div className="side-spacing content--s">
                <AccountDetailsHeader employee={ employees.data.employees[accountId] }/>
                <AccountDetailsContent employee={ employees.data.employees[accountId] }/>
            </div>
        );
    };
}


const mapStateToProps = (state) => {
    return {
        employees: state.entities.common.employees.getMany
    };
};

export default connect(mapStateToProps)(AccountDetails);