import { deepFreeze } from "../../utils/misc_utils";

export const MessageEnum = deepFreeze({
    type: {
        CLOUD: "CLOUD",
        EMAIL: "EMAIL"
    },
    sendingStatus: {
        UNKNOWN: "UNKNOWN",
        READY: "READY",
        PROCESSING: "PROCESSING",
        SENT: "SENT",
        ERROR: "ERROR",
        CANCELED: "CANCELED"
    },
    source: {
        OPERATOR: "OPERATOR_ANNOUNCEMENT",
        AUTOMATIC: "AUTOMATIC_ANNOUNCEMENT"
    },
    priorityLevel: {
        INFO: {
            value: "INFO",
            description: "Informacja"
        },
        WARNING: {
            value: "WARNING",
            description: "Ostrzeżenie"
        },
        ALARM: {
            value: "ALARM",
            description: "Alarm"
        }
    }
});