import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, Form } from "formik";

import BulkyWasteCreateAndEditModalFormLocation from "./location";
import BulkyWasteCreateAndEditModalFormDate from "./date";
import ReverseGeocodingMap from "../../../../../../../common/map/reverse_geocoding_map";
import { resetGeocoding, reverseGeocoding } from "../../../../../../../../data/actions/common/geocoding";
import { isNullOrUndefined } from "../../../../../../../../core/utils/misc_utils";
import FormRequiredLegend from "../../../../../../../common/legend_required";

class BulkyWasteCreateAndEditModalFormContent extends Component {

    render = () => {
        const { formikBag, readOnly, geocoding } = this.props;

        return (
            <div className='c-modal__body modal-size-m'>
                <Form className='form--vertical form--with-legend'>

                    <BulkyWasteCreateAndEditModalFormLocation
                        required={ true }
                        value={ formikBag.values.location }
                        errors={ formikBag.errors.location }
                        readOnly={ readOnly }
                        _onLocationFieldChangeCallback={ this._onForwardGeocodingLocationFieldChangeCallback }
                        _onSuggestionClickCallback={ this._onForwardGeocodingSuggestionClickCallback }/>

                    <ReverseGeocodingMap
                        reverse={ geocoding.reverse }
                        longitude={ formikBag.values.longitude }
                        latitude={ formikBag.values.latitude }
                        readOnly={ readOnly }
                        _reverseGeocodingCallback={ this._reverseGeocodingCallback }
                        _handleReverseGeocodingCallback={ this._handleReverseGeocodingCallback }/>

                    <BulkyWasteCreateAndEditModalFormDate
                        required={ true }
                        formikBag={ formikBag }
                        readOnly={ readOnly }/>

                    <Field
                        name="description"
                        render={ ({ field }) => (
                            <label>
                                <span className='label-name'>Dodatkowy opis:</span>
                                <textarea
                                    disabled={ !isNullOrUndefined(readOnly) ? readOnly : false }
                                    { ...field }/>
                            </label>
                        ) }
                    />
                    <FormRequiredLegend/>
                </Form>
            </div>
        );
    };

    componentDidMount = () => {
        const { formikBag, bulkyWasteItem, _setFormValues } = this.props;
        _setFormValues(formikBag, bulkyWasteItem);
    };

    componentWillUnmount = () => {
        const { resetGeocoding } = this.props;
        resetGeocoding();
    };

    _onForwardGeocodingLocationFieldChangeCallback = (value) => {
        const { formikBag } = this.props;

        formikBag.setFieldValue("location", value);
    };

    _onForwardGeocodingSuggestionClickCallback = (lng, lat, placeName) => {
        const { formikBag } = this.props;

        formikBag.setFieldValue("longitude", lng);
        formikBag.setFieldValue("latitude", lat);
        formikBag.setFieldValue("location", placeName);
    };

    _reverseGeocodingCallback = (longitude, latitude) => {
        const { formikBag, reverseGeocoding } = this.props;

        return reverseGeocoding(longitude, latitude)
            .then(() => {
                formikBag.setFieldValue("longitude", longitude);
                formikBag.setFieldValue("latitude", latitude);
            });
    };

    _handleReverseGeocodingCallback = (name) => {
        return this.props.formikBag.setFieldValue("location", name);
    };
}

const mapStateToProps = (state) => {
    return {
        geocoding: state.entities.common.geocoding
    };
};

export default connect(mapStateToProps, { reverseGeocoding, resetGeocoding })(BulkyWasteCreateAndEditModalFormContent);