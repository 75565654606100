import { API_BASE_AKUN_BLISKO_PATH } from "../../../core/constants";
import webClient from "../../../core/web_client";

const DRAFT_MESSAGE_ADDRESSES_PATH = `${ API_BASE_AKUN_BLISKO_PATH }/messages/drafts`;
const PATH = 'addresses';

export const getMany = (id) => {
    return webClient
        .get(`${ DRAFT_MESSAGE_ADDRESSES_PATH }/${ id }/${ PATH }`);
};

export const addUnits = (id, unitIds) => {
    return webClient
        .patch(`${ DRAFT_MESSAGE_ADDRESSES_PATH }/${ id }/${ PATH }`, {
            command: 'add-units',
            unitIds
        });
};

export const addUnitsRange = (id, unitIdFrom, unitIdTo) => {
    return webClient
        .patch(`${ DRAFT_MESSAGE_ADDRESSES_PATH }/${ id }/${ PATH }`, {
            command: 'add-address-range',
            fromId: unitIdFrom,
            toId: unitIdTo
        });
};

export const addAddressTemplate = (id, addressTemplateId) => {
    return webClient
        .patch(`${ DRAFT_MESSAGE_ADDRESSES_PATH }/${ id }/${ PATH }`, {
            command: 'add-address-template-units',
            addressTemplateId
        });
};

export const removeUnits = (id, unitIds) => {
    return webClient
        .patch(`${ DRAFT_MESSAGE_ADDRESSES_PATH }/${ id }/${ PATH }`, {
            command: 'remove-units',
            unitIds
        });
};