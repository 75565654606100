import React, { Component } from "react";
import Icon from "../common/icon";
import { isNullOrUndefined } from "../../core/utils/misc_utils";

class FoldableContainer extends Component {

    state = {
        isHidden: true,
    };

    render = () => {
        const { icon, children } = this.props;
        const { isHidden } = this.state;

        return (
            <div className={ `user-reply  ${ !isHidden ? "user-reply--show" : "" }` }>
                {
                    !isNullOrUndefined(icon) &&

                    <div className="user-reply__icon">
                        <Icon>
                            {
                                icon
                            }
                        </Icon>
                    </div>
                }
                <div className={ `user-reply__message ${ !isHidden ? "user-reply__message--show" : "" }` }>
                    {
                        children
                    }
                </div>

                <button className="user-reply__button"
                        onClick={ this._onToggleClick }
                        type="button">
                    <div className={ `user-reply__arrow ${ !isHidden ? "user-reply__arrow--hide" : "" }` }/>
                </button>

            </div>
        );
    };

    _onToggleClick = () => {
        this.setState(prevState => ({
            isHidden: !prevState.isHidden,
        }));
    };
}

export default FoldableContainer;