import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommunalDocumentsListItem from "./item";
import { getManyCommunalDocuments } from "../../../../../../data/actions/waste_management/communal_documents";
import Loader from "../../../../../common/loader";
import EmptyListPage from "../../../../../common/empty_list_page";
import noDocumentsIcon from "../../../../../../../resource/img/icons/empty_list_icons/brak_wpisow.png";

const CommunalDocumentsList = () => {
    const communalDocuments = useSelector(state => state.entities.wasteManagement.communalDocuments.getMany);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getManyCommunalDocuments())
    }, [ dispatch ])

    if (!communalDocuments?.data || communalDocuments.meta?.loading) {
        return <Loader/>;
    }

    if (communalDocuments.data.length === 0) {
        return <EmptyListPage
            img={ noDocumentsIcon }
            text='Brak plików dla mieszkańców'
            imgAlt='Brak plików dla mieszkańców'
        />
    }

    return (
        <section className="main-content--table__bottom container is-fluid">
            <div className='table-wrapper'>
                <table className='table--single-line fixed'>
                    <thead>
                    <tr>
                        <th className='t-width-7'>
                            Nazwa pliku
                        </th>
                        <th className='t-width-3'>
                            Wielkość pliku
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        communalDocuments?.data.map(communalDocument =>
                            <CommunalDocumentsListItem
                                key={ communalDocument.id }
                                communalDocument={ communalDocument }
                                _onDeleteOrUpdateSuccess={ () => dispatch(getManyCommunalDocuments()) }
                            />
                        )
                    }
                    </tbody>
                </table>
            </div>
        </section>
    );
};

export default CommunalDocumentsList;