import webClient from "../../../core/web_client";
import { API_BASE_AKUN_ADMIN_PATH } from "../../../core/constants";

const PATH = `${ API_BASE_AKUN_ADMIN_PATH }/files`;

export const addFile = (file) => {
    let body = new FormData();
    body.append("mimeType", file.type);
    body.append("fileName", file.name);
    body.append("size", file.size);
    body.append("file", file);

    return webClient
        .post(`${ PATH }`, body, {
            headers: {
                API_CONTENT_TYPE_HEADER: "multipart/form-data"
            }
        });
};

export const updateFileAlternativeText = (id, data)  => {
    return webClient
        .patch(`${ PATH }/${ id }`, data);
};