import FormModal from "../../../../../../common/modal/form_modal";
import { FileUpload, UploadedFile } from "../../../../../../common/file_upload";
import React, { useState } from "react";
import AreaNotImportedLocationsModal from "./not_imported_locations_modal";
import { useDispatch, useSelector } from "react-redux";
import { importFile } from "../../../../../../../data/actions/waste_management/areas/import";
import { toastForCustomError, toastForCustomSuccess } from "../../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../../data/actions/common/ui/toast";
import { getManyLocationsForArea } from "../../../../../../../data/actions/waste_management/areas/locations";

const AreaLocationsImportModal = (props) => {
    const { areaId } = props;
    const [file, setFile] = useState();
    const [notImportedLocations, setNotImportedLocations] = useState();
    const dispatch = useDispatch();
    const importStatus  = useSelector(state => state.entities?.wasteManagement?.areas?.import);

    return (
        <>
            <FormModal
                isLoading={ importStatus?.meta?.loading }
                submitDisabled={ !file }
                triggerComponent={ <span className="unstyled-btn dropdown-item">Importuj adresy z pliku Excel</span> }
                overflow={ true }
                saveOrCancelButtons={ true }
                headerText="Importuj adresy z pliku Excel"
                saveButtonText="Importuj"
                _actionFunction={ async () =>
                    dispatch(importFile(areaId, file))
                        .then(response => {
                            setFile(undefined);
                            setNotImportedLocations(response.data);

                            if (response.status >= 400 && response.status < 500) {
                                toastForCustomError((...args) => dispatch(showToast(...args)), 'Przepraszamy, wybrany plik nie jest przygotowany zgodnie z szablonem. Pobierz szablon i dostosuj plik.');
                            }
                            if (response.status === 200 && !response.data?.length) {
                                toastForCustomSuccess((...args) => dispatch(showToast(...args)), 'Plik został poprawnie zaimportowany');
                            }
                            if (response.status === 200) {
                                dispatch(getManyLocationsForArea(areaId))
                            }
                        })
                }
            >
                <div className='c-modal__body modal-size-m'>
                    {
                        !file &&
                        <>
                            <FileUpload
                                _onStart={ filesArray => {
                                    if (filesArray?.length > 0) {
                                        setFile(filesArray[0])
                                    }
                                } }
                                acceptedFileExtensions={['xls', 'xlsx']}
                                maxSingleFileSizeInMB={ 15 }
                            />
                        </>
                    }
                    {
                        file &&
                        <UploadedFile
                            _onDeleteUploadedFileClick={ () => setFile(undefined) }
                            name={ file.name }
                            alternativeTextRequired={ false }
                        />
                    }
                </div>

            </FormModal>
            {
                notImportedLocations?.length > 0 &&
                <AreaNotImportedLocationsModal notImportedLocations={notImportedLocations} onClose={async () => setNotImportedLocations(undefined)}/>
            }
        </>
    );
}
export default AreaLocationsImportModal
