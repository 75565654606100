import _ from "lodash";

import {
    DEFAULT_STATE,
    DEFAULT_STATE__API__FAIL,
    DEFAULT_STATE__API__STARTED,
    DEFAULT_STATE__API__SUCCESS
} from "../../../../../../core/utils/store_utils";
import { actions } from "../../../../../../core/constants";
import { LocationsEnum } from "../../../../../../core/enums/waste_management/locations";
import { isNullOrUndefined } from "../../../../../../core/utils/misc_utils";
import { sortAddresses } from "../../../../../../core/utils/location_utils";

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case actions.wasteManagement.API__LOCATION_CANDIDATES_TOWN_ADDRESSES_AND_STREETS__GET_MANY__STARTED:
        case actions.wasteManagement.API__LOCATION_CANDIDATES_STREET_ADDRESSES__GET_MANY__STARTED:
        case actions.wasteManagement.API__LOCATION_CANDIDATES_APARTMENT_ADDRESSS__GET_MANY__STARTED:
            return DEFAULT_STATE__API__STARTED(state, DEFAULT_STATE);

        case actions.wasteManagement.API__LOCATION_CANDIDATES_TOWN_ADDRESSES_AND_STREETS__GET_MANY__SUCCESS:
        case actions.wasteManagement.API__LOCATION_CANDIDATES_STREET_ADDRESSES__GET_MANY__SUCCESS:
        case actions.wasteManagement.API__LOCATION_CANDIDATES_APARTMENT_ADDRESSS__GET_MANY__SUCCESS:
            const filteredPayload = _
                .filter(action.payload, locationCandidate =>
                    locationCandidate.kind === LocationsEnum.type.APARTMENT_ADDRESS);

            const sortedFilteredPayload = sortAddresses(filteredPayload);
            return DEFAULT_STATE__API__SUCCESS(state, DEFAULT_STATE, { ...action, payload: _.mapKeys(sortedFilteredPayload, "id") });

        case actions.wasteManagement.API__LOCATION_CANDIDATES_TOWN_ADDRESSES_AND_STREETS__GET_MANY__FAIL:
        case actions.wasteManagement.API__LOCATION_CANDIDATES_STREET_ADDRESSES__GET_MANY__FAIL:
        case actions.wasteManagement.API__LOCATION_CANDIDATES_APARTMENT_ADDRESSS__GET_MANY__FAIL:
            return DEFAULT_STATE__API__FAIL(state, DEFAULT_STATE, action);

        case actions.wasteManagement.API_LOCATION_CANDIDATES_RESET:
            if (!isNullOrUndefined(action.payload.apartmentAddress) && action.payload.apartmentAddress) {
                return DEFAULT_STATE;
            } else {
                return state;
            }

        case actions.common.UI__AUTHENTICATION__SIGN_OUT:
            return DEFAULT_STATE;

        default:
            return state;
    }
}
