import React from "react";
import { Link } from "react-router-dom";
import {
    ROUTE_SETTINGS_ACCESSIBILITY_STATEMENT,
    ROUTE_SETTINGS_PASSWORD_CHANGE,
    ROUTE_SETTINGS_REGULATIONS
} from "../../../../../core/constants";
import { SettingsEnum } from "../../../../../core/enums/common/settings";


const SettingsHeader = ({ tab }) => {

    return (
        <div>
            <header className="header--basic">
                <h1 className="title is-1">
                    Ustawienia
                </h1>
            </header>

            <section className="options-tabs">
                <div className="row row--options">
                    <div className='full-width'>
                        <div className="tabs">
                            <ul className="tabs-list">
                                <li className={ `tabs-item ${ tab === SettingsEnum.tab.PASSWORD_CHANGE ? "is-active" : "" }` }>
                                    <Link className="tab-item" to={ ROUTE_SETTINGS_PASSWORD_CHANGE }>Zmiana hasła</Link>
                                </li>
                                <li className={ `tabs-item ${ tab === SettingsEnum.tab.REGULATIONS ? "is-active" : "" }` }>
                                    <Link className="tab-item" to={ ROUTE_SETTINGS_REGULATIONS }>Regulaminy</Link>
                                </li>
                                <li className={ `tabs-item ${ tab === SettingsEnum.tab.ACCESSIBILITY_STATEMENT ? "is-active" : "" }` }>
                                    <Link className="tab-item" to={ ROUTE_SETTINGS_ACCESSIBILITY_STATEMENT }>Deklaracja
                                        dostępności</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default SettingsHeader;
