import axios from "axios";
import {
    API_AUTHORIZATION_HEADER,
    API_BASE_URL,
    API_CONTENT_TYPE_HEADER,
    MISC_REST_CONTENT_TYPE_APPLICATION_JSON
} from "./constants";
import { store } from "./store";
import { isNullOrUndefined, stringsEqualTrimIgnoreCase } from "./utils/misc_utils";
import { signOutLocally } from "../data/actions/common/authentication";

const configureWebClient = (webClientToConfigure) => {
    webClientToConfigure
        .interceptors
        .request
        .use(config => {

            const token =
                !isNullOrUndefined(store) &&
                !isNullOrUndefined(store.getState()) &&
                !isNullOrUndefined(store.getState().authentication) &&
                !isNullOrUndefined(store.getState().authentication.data)
                    ? store.getState().authentication.data.token
                    : null;

            let headers = {
                ...config.headers
            };

            if ((stringsEqualTrimIgnoreCase(config.method, "post") ||
                stringsEqualTrimIgnoreCase(config.method, "put") ||
                stringsEqualTrimIgnoreCase(config.method, "patch")) &&
                isNullOrUndefined(headers[API_CONTENT_TYPE_HEADER])) {

                headers[API_CONTENT_TYPE_HEADER] = MISC_REST_CONTENT_TYPE_APPLICATION_JSON;
            }

            if (!isNullOrUndefined(token)) {
                headers[API_AUTHORIZATION_HEADER] = `Bearer ${ token }`;
            }

            return {
                ...config,
                headers
            };
        });

    webClientToConfigure
        .interceptors
        .response
        .use(undefined, error => {
            if (error?.response?.status === 403) {
                store.dispatch(signOutLocally())
            }

            return Promise.reject(error);
        })

    return webClientToConfigure;
};

const webClient = configureWebClient(axios.create({
    baseURL: API_BASE_URL,
    timeout: 20000
}));

export const webClientFileDownload = configureWebClient(axios.create({
    baseURL: API_BASE_URL,
    timeout: 60000,
    responseType: "blob" // important
}));


export default webClient;