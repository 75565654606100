import React from "react";
import { Form, Formik } from "formik";

import FormModal from "../../../../../../common/modal/form_modal";
import SubcategoryCreateOrEditModalFormContent from "./content";
import { isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";

const initialFormValues = {
    name: ""
};

const SubcategoryCreateOrEditModalForm = ({ triggerComponent, headerText, categoryId, subcategory, _onSubmit }) => {

    const initialValues =
        !isNullOrUndefined(subcategory)
            ? { name: subcategory.name }
            : initialFormValues;

    return (

        <Formik
            initialValues={ initialValues }
            render={ formikBag => (

                <FormModal
                    headerText={ headerText }
                    triggerComponent={ triggerComponent }
                    saveOrCancelButtons={ true }
                    _actionFunction={ () => {
                        return _onSubmit(
                            categoryId,
                            !isNullOrUndefined(subcategory) ? subcategory.id : null,
                            formikBag.values)
                            .then(() => formikBag.resetForm());
                    } }>

                    <Form>
                        <SubcategoryCreateOrEditModalFormContent/>
                    </Form>

                </FormModal>
            ) }
        />
    );
};

export default SubcategoryCreateOrEditModalForm;