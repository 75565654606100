import { API_BASE_AKUN_BLISKO_PATH } from "../../../../core/constants";
import webClient from "../../../../core/web_client";
import { buildQueryString } from "../../../../core/utils/api_utils";

const PATH_ADDRESS_TEMPLATE = `${ API_BASE_AKUN_BLISKO_PATH }/addresses-templates`;
const PATH = "addresses";

export const getManyLocationsForAddressTemplate = (addressTemplateId, queryParams) => {
    return webClient
        .get(`${ PATH_ADDRESS_TEMPLATE }/${ addressTemplateId }/${ PATH }${ buildQueryString(queryParams) }`);
};

export const assignLocations = (addressTemplateId, unitIds) => {
    return webClient
        .patch(
            `${ PATH_ADDRESS_TEMPLATE }/${ addressTemplateId }/${ PATH }`,
            {
                command: 'assign-units',
                unitIds
            }
        )
}

export const addLocationsRange = (addressTemplateId, fromId, toId) => {
    return webClient
        .patch(
            `${ PATH_ADDRESS_TEMPLATE }/${ addressTemplateId }/${ PATH }`,
            {
                command: 'add-address-range',
                fromId,
                toId
            }
        )
}

export const removeLocations = (addressTemplateId, unitIds) => {
    return webClient
        .patch(
            `${ PATH_ADDRESS_TEMPLATE }/${ addressTemplateId }/${ PATH }`,
            {
                command: 'remove-units',
                unitIds
            }
        )
}