import _ from "lodash";

import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const getManyResponsibleEntities = () => dispatch => {
    dispatch({
        type: actions.services.API__RESPONSIBLE_ENTITIES__GET_MANY__STARTED
    });

    return api
        .services
        .responsibleEntities
        .getManyResponsibleEntities()
        .then(response => {
            dispatch({
                type: actions.services.API__RESPONSIBLE_ENTITIES__GET_MANY__SUCCESS,
                meta: {},
                payload: _.mapKeys(extractResponseData(response), "id"),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__RESPONSIBLE_ENTITIES__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const addResponsibleEntity = (data) => dispatch => {
    dispatch({
        type: actions.services.API__RESPONSIBLE_ENTITIES__ADD__STARTED
    });

    return api
        .services
        .responsibleEntities
        .addResponsibleEntity(data)
        .then(response => {
            dispatch({
                type: actions.services.API__RESPONSIBLE_ENTITIES__ADD__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__RESPONSIBLE_ENTITIES__ADD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const updateResponsibleEntity = (responsibleEntityId, data) => dispatch => {
    dispatch({
        type: actions.services.API__RESPONSIBLE_ENTITIES__UPDATE__STARTED
    });

    return api
        .services
        .responsibleEntities
        .updateResponsibleEntity(responsibleEntityId, data)
        .then(response => {
            dispatch({
                type: actions.services.API__RESPONSIBLE_ENTITIES__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__RESPONSIBLE_ENTITIES__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const deleteResponsibleEntity = (responsibleEntityId) => dispatch => {
    dispatch({
        type: actions.services.API__RESPONSIBLE_ENTITIES__DELETE__STARTED
    });

    return api
        .services
        .responsibleEntities
        .deleteResponsibleEntity(responsibleEntityId)
        .then(response => {
            dispatch({
                type: actions.services.API__RESPONSIBLE_ENTITIES__DELETE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__RESPONSIBLE_ENTITIES__DELETE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};