import React from "react";
import FormModal from "../../../../../../common/modal/form_modal";
import { ReactComponent as IconDelete } from "../../../../../../../../resource/img/icons/icon_delete.svg";

const DeleteMessageForm = ({ _onCancelMessage }) => {

    return (
        <FormModal
            triggerComponent={
                <button className='unstyled-btn' title='Usuń'>
                    <IconDelete/>
                </button>
            }
            deleteOrCancelButtons={ true }
            _actionFunction={ () => {
                return _onCancelMessage();
            } }>

            <div className='c-modal__body modal-size-m modal--no-header'>
                <p className="confirmation-alert">
                    Czy na pewno chcesz usunąć zaplanowaną wiadomość?
                </p>
            </div>

        </FormModal>
    );
};

export default DeleteMessageForm;