import webClient, { webClientFileDownload } from "../../../../core/web_client";
import { PATH as PATH_AREAS } from "./index";

export const PATH = `import`;

export const importFile = (areaId, file) => {
    let body = new FormData();
    body.append("mimeType", file.type);
    body.append("fileName", file.name);
    body.append("size", file.size);
    body.append("file", file);

    return webClient
        .post(`${ PATH_AREAS }/${ areaId }/${ PATH }`, body, {
            headers: {
                API_CONTENT_TYPE_HEADER: "multipart/form-data"
            }
        });
};

export const downloadTemplate = () => {
    return webClientFileDownload
        .get(`${ PATH_AREAS }/${ PATH }/template`);
};
