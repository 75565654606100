import React from "react";
import CommunalDocumentsList from "./list";
import AddCommunalDocumentForm from "./form/add_form";

const CommunalDocuments = () => {

    return (
        <div className="main-content--table">
            <section className='main-content--table__top'>
                <header className='page-header page-header--with-back'>
                    <h1 className='title is-1'>
                        Dokumenty dla mieszkańca
                    </h1>
                </header>

                <section className='container is-fluid'>
                    <div className="row row--separated row--options">
                        <div className='row__left'>
                            Dodaj dokumenty dla mieszkańca związane z gospodarką odpadami. Po publikacji pliki
                            będą<br/>dostępne do pobrania we wtyczce, zakładka „Dokumenty do pobrania”.
                        </div>
                        <div className='row__right'>
                            <AddCommunalDocumentForm />
                        </div>
                    </div>
                </section>
            </section>

            <CommunalDocumentsList/>
        </div>
    );
};

export default CommunalDocuments;