import get from "./get";
import update from "./update";

export default (state = {}, action) => {
    return {
        ...state,
        get: get(state.get, action),
        update: update(state.update, action),
    };
}
