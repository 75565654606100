import { actions } from "../../../core/constants";
import * as api from "../../_api";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const getAllApartmentAddresses = (queryParams) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_ADDRESSES__GET_APARTMENT_ADDRESSES__STARTED
    });
    
    return api
        .ekostraznik
        .apartmentAddresses
        .getMany(queryParams)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__GET_APARTMENT_ADDRESSES__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__GET_APARTMENT_ADDRESSES__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};


export const addApartmentAddresses = (parentAddressId, number) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_ADDRESSES__ADD_APARTMENT_ADDRESSES__STARTED
    });
    
    return api
        .ekostraznik
        .apartmentAddresses
        .addApartmentAddresses(parentAddressId, number)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__ADD_APARTMENT_ADDRESSES__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
            return response
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__ADD_APARTMENT_ADDRESSES__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
            return error.response
        });
};

export const deleteManyApartmentAddresses = (data) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_ADDRESSES__DELETE_MANY_APARTMENT_ADDRESSES__STARTED
    });

    return api
        .ekostraznik
        .apartmentAddresses
        .deleteManyApartmentAddresses(data)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__DELETE_MANY_APARTMENT_ADDRESSES__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__DELETE_MANY_APARTMENT_ADDRESSES__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};