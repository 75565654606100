import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";

import DefineAreas from "./define_area";
import CompanyInfo from "./company_info";
import { updateCompany, getCompany } from "../../../../../../../data/actions/waste_management/companies";
import { BottomLeftSave } from "../../../../../../common/bottom_action_buttons";
import { containsWithId, isNullOrUndefined, isNullOrUndefinedOneOfMany } from "../../../../../../../core/utils/misc_utils";
import { getManyBins } from "../../../../../../../data/actions/waste_management/bins";
import _ from "lodash";

const initialFormValues = {
    address: "",
    email: "",
    phoneNumber: "",
    webPageUrl: ""
};

class CompanyDetailsContent extends Component {

    state = {
        assignedAreas: [],
        allAvailableBins: [],
        message: "",
        error: true
    };

    render = () => {
        const { company } = this.props;
        const { allAvailableBins, message, error } = this.state;

        const initialValues =
            !isNullOrUndefined(company)
                ? this._fixCompanyValues(company)
                : this.initialFormValues;

        return (
            <Formik
                initialValues={ initialValues }
                onSubmit={ this._onSubmit }
                initialFormValues={ initialFormValues }
                render={ formikBag => (
                    <Form className="has-bottom-action-buttons">
                        <div className="content--s content--not-centered">
                            <CompanyInfo company={ company }
                                         formikBag={ formikBag }/>
                            <DefineAreas company={ company }
                                         allAvailableBins={ allAvailableBins }
                                         formikBag={ formikBag }
                                         _manageAssignedAreas={ this._manageAssignedAreas }
                                         _addOrRemoveCheckboxClass={ this._addOrRemoveCheckboxClass }/>
                        </div>
                        <BottomLeftSave message={ message } error={ error }/>
                    </Form>
                ) }/>
        );
    };

    componentDidMount = () => {
        const { getManyBins, company } = this.props;

        getManyBins()
            .then(() => {
                const { binsList } = this.props;

                const basicBins = _.reject(binsList.data, ["type", null]);
                const basicBinsIds = basicBins.map(bin => {
                    return bin.id;
                });

                this.setState({
                    assignedAreas: company.areas,
                    allAvailableBins: basicBinsIds
                });
            });
    };

    _manageAssignedAreas = (areaId, binId) => {

        if (isNullOrUndefinedOneOfMany(areaId)) {
            return;
        }

        this.setState(prevState => {
            const { allAvailableBins } = this.state;
            const isAreaAssigned = containsWithId(prevState.assignedAreas, areaId);

            if (isAreaAssigned) {

                const assignedAreas = prevState.assignedAreas.map(assignedArea => {

                    if (assignedArea.id === areaId) {

                        //if function was invoked by _onCheckAllClicked
                        if (isNullOrUndefined(binId)) {

                            if (assignedArea.bins.length === allAvailableBins.length) {
                                return {
                                    ...assignedArea,
                                    bins: []
                                };
                            }
                            else {
                                return {
                                    ...assignedArea,
                                    bins: allAvailableBins
                                };
                            }
                        }
                        //if function was invoked by _onBinClick
                        else {
                            const newBins = [...assignedArea.bins];
                            const binIndex = newBins.indexOf(binId);

                            if (binIndex > -1) {

                                newBins.splice(binIndex, 1);

                                if (newBins.length === 0) {
                                    return null;
                                }
                            }
                            else {
                                newBins.push(binId);
                            }

                            return {
                                ...assignedArea,
                                bins: newBins
                            };
                        }

                    }
                    else {
                        return assignedArea;
                    }
                });

                const nonNullAssignedAreas = assignedAreas
                    .filter(assignedArea => !isNullOrUndefined(assignedArea));

                return {
                    assignedAreas: nonNullAssignedAreas
                };

            }
            else {
                let adjustedBinsValue = [];

                //if function was invoked by _onCheckAllClicked
                if (isNullOrUndefined(binId)) {
                    adjustedBinsValue.push(...allAvailableBins);
                }
                //if function was invoked by _onBinClick
                else {
                    adjustedBinsValue.push(binId);
                }

                const assignedAreas = prevState.assignedAreas.concat({ id: areaId, bins: adjustedBinsValue });

                return {
                    assignedAreas
                };
            }

        });
    };

    _addOrRemoveCheckboxClass = (areaId, binId) => {
        const { assignedAreas } = this.state;

        for (let i = 0; i < assignedAreas.length; i++) {

            if (assignedAreas[i].id === areaId) {
                return assignedAreas[i].bins.indexOf(binId) > -1;
            }
        }
    };

    _fixCompanyValues = () => {
        const { company } = this.props;

        return {
            address:
                !isNullOrUndefined(company.address)
                    ? company.address
                    : initialFormValues.address,
            email:
                !isNullOrUndefined(company.email)
                    ? company.email
                    : initialFormValues.email,
            phoneNumber:
                !isNullOrUndefined(company.phoneNumber)
                    ? company.phoneNumber
                    : initialFormValues.phoneNumber,
            webPageUrl:
                !isNullOrUndefined(company.webPageUrl)
                    ? company.webPageUrl
                    : initialFormValues.webPageUrl
        };
    };

    _onSubmit = (values) => {
        const { company, updateCompany } = this.props;

        const valuesWithAreas = {
            ...values,
            areas: this.state.assignedAreas
        };

        return updateCompany(company.id, valuesWithAreas)
            .then(() => getCompany(company.id))
            .then(() => {
                const { companyUpdate } = this.props;
                
                this.setState({
                    message: isNullOrUndefined(companyUpdate.data) ? "Wystąpił błąd" : "Zmiany zostały zapisane",
                    error: isNullOrUndefined(companyUpdate.data)
                })
            });
    };
}

const mapStateToProps = (state) => {
    return {
        binsList: state.entities.wasteManagement.bins.getMany,
        companyUpdate: state.entities.wasteManagement.companies.update
    };
};

export default connect(mapStateToProps, { updateCompany, getCompany, getManyBins })(CompanyDetailsContent);