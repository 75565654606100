import add from "./add";
import getMany from "./get_many";
import get from "./get"
import delete_ from "./delete";
import update from "./update"
import patch from "./patch"

export default (state = {}, action) => {
    return {
        ...state,
        getMany: getMany(state.getMany, action),
        get: get(state.get, action),
        add: add(state.add, action),
        update: update(state.update, action),
        patch: patch(state.patch, action),
        delete: delete_(state.delete_, action)
    };
}