import React from "react";
import { Field } from "formik";
import { isNullOrUndefined } from "../../../../../../core/utils/misc_utils";

const AccountFormBasicInfo = ({ editMode, disabled }) => {

    return (
        <>
            <h3 className="form-group-title">
                Dane moderatora
            </h3>
            <Field
                name="name"
                render={ ({ field, form }) => (
                    <label>
                        <span className="label-name"><span className="required-accent">*</span>Imię</span>
                        <span className="is-relative full-width">
                             <input
                                 disabled={ disabled }
                                 className={ `${ !isNullOrUndefined(form.errors.name) ? "rejected" : "" }` }
                                 { ...field }/>

                            {
                                !isNullOrUndefined(form.errors.name) &&

                                <span className="hint hint--rejected">{ form.errors.name }</span>
                            }
                        </span>
                    </label>
                ) }/>

            <Field
                name="surname"
                render={ ({ field, form }) => (
                    <label>
                        <span className="label-name"><span className="required-accent">*</span>Nazwisko</span>
                        <span className="is-relative full-width">
                            <input
                                disabled={ disabled }
                                className={ `${ !isNullOrUndefined(form.errors.surname) ? "rejected" : "" }` }
                                { ...field }/>

                            {
                                !isNullOrUndefined(form.errors.surname) &&

                                <span className="hint hint--rejected">{ form.errors.surname }</span>
                            }
                        </span>
                    </label>
                ) }/>

            <Field
                name="email"
                render={ ({ field, form }) => (
                    <label>
                        <span className="label-name"><span className="required-accent">*</span>E-mail</span>
                        <span className="is-relative full-width">
                            <input
                                disabled={ editMode }
                                className={ `${ !isNullOrUndefined(form.errors.email) ? "rejected" : "" }` }
                                { ...field }/>

                            {
                                !isNullOrUndefined(form.errors.email) &&

                                <span className="hint hint--rejected">{ form.errors.email }</span>
                            }
                        </span>
                    </label>
                ) }/>

            <Field
                name="phoneNumber"
                render={ ({ field }) => (
                    <label>
                        <span className="label-name">Nr tel.</span>
                        <input
                            disabled={ disabled }
                            { ...field }/>
                    </label>
                ) }/>

            <Field
                name="description"
                render={ ({ field }) => (
                    <label>
                        <span className="label-name">Opis dodatkowy</span>
                        <textarea
                            disabled={ disabled }
                            { ...field }/>
                    </label>
                ) }/>

        </>
    );
};

export default AccountFormBasicInfo;