import React from "react";
import { Field } from "formik";

import UnderlinedHeader from "../../../../../common/header_underlined";
import ServicesCardAdditionalInfoKeywords from "./keywords";
import ServicesCardAdditionalInfoRelatedServices from "./related_services";
import ServiceCardsEnum from "../../../../../../core/enums/services/service_cards";
import FormErrorMessage from "../../../../../common/form_error_message";

const ServicesCardAdditionalInfo = ({ step, cardType, errors }) => {

    return (
        <section className="media mw-100">
            <div className="media-left step-count-wrap">
                <div className="step-count">
                    <span>{ step }</span>
                </div>
            </div>
            <div className="media-content media-content--steps">
                <UnderlinedHeader text="Informacje dodatkowe"/>
                <p className="is-spaced--bottom-30">Uzupełnij informacje pomocne w znalezieniu usługi lub załatwieniu sprawy.</p>

                {
                    errors.externalUri &&
                    <FormErrorMessage message={ errors.externalUri }/>
                }

                <div className="">
                    <div className="form--vertical">
                        <div className="columns is-multiline is-paddingless is-marginless">

                            <ServicesCardAdditionalInfoKeywords cardType={ cardType }/>

                            <ServicesCardAdditionalInfoRelatedServices/>

                            <Field name={ "externalUri.uri" }
                                   render={ ({ field }) => (
                                       <div className="column is-half field">
                                           <label>
                                               <span className='label-name'>
                                                   {
                                                       cardType === ServiceCardsEnum.type.ISSUE_HANDLE
                                                           ?
                                                           "Link do usługi zewnętrznej"
                                                           :
                                                           ["Link do usługi zewnętrznej", <span className="required-accent">*</span>]
                                                   }

                                            </span>
                                            <input type="text"
                                                   placeholder="Wklej adres URL"
                                                   { ...field }/>
                                        </label>
                                    </div>
                                ) }/>


                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServicesCardAdditionalInfo;
