import React, { Component } from "react";
import { connect } from "react-redux";
import { getManyBins } from "../../../../../../../data/actions/waste_management/bins";
import Select from "../../../../../../common/select";

class EkostraznikReportType extends Component {

    render = () => {
        const { formikBag, typeOptions, selectedType, _handleSelectedTypeChange } = this.props;

        return (
            <label>
                <span className="label-name"><span className="required-accent">*</span>Rodzaj zgłoszenia</span>
                <div className="is-relative full-width">
                    <div className="select-wrapper">
                        <Select
                            options={ typeOptions }
                            defaultValue={ selectedType }
                            isClearable= { false }
                            onChange={ typeOption => _handleSelectedTypeChange(typeOption, formikBag) }
                        />
                    </div>
                </div>
            </label>
        )
    };

    _onSelectChange = (selectedOption) => {
        const { formikBag } = this.props;
        formikBag.setFieldValue("type", selectedOption.value.type);
    };
}


const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps, { getManyBins })(EkostraznikReportType);

