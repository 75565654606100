import webClient from "../../../../core/web_client";

import { PATH as PATH_REPORTS } from "./index";

const PATH = "message";

export const getManyReportMessages = (reportId) => {
    return webClient
        .get(`${ PATH_REPORTS }/${ reportId }/${ PATH }`);
};

export const addReportMessage = (reportId, data) => {
    return webClient
        .post(`${ PATH_REPORTS }/${ reportId }/${ PATH }`, data);
};

