import {
    DEFAULT_STATE,
    DEFAULT_STATE__API__FAIL,
    DEFAULT_STATE__API__STARTED,
    DEFAULT_STATE__API__SUCCESS
} from "../../core/utils/store_utils";
import { actions } from "../../core/constants";

export default (state = { ...DEFAULT_STATE, data: { token: null, ownerId: null, ownerName: null } }, action) => {
    switch (action.type) {
        case actions.common.API__AUTHENTICATION__SIGN_IN__STARTED:
        case actions.common.API__AUTHENTICATION__CHOOSE_OWNER__STARTED:
            return DEFAULT_STATE__API__STARTED(state, DEFAULT_STATE);

        case actions.common.API__AUTHENTICATION__SIGN_IN__SUCCESS:
        case actions.common.API__AUTHENTICATION__CHOOSE_OWNER__SUCCESS:
            return DEFAULT_STATE__API__SUCCESS(state, DEFAULT_STATE, action);

        case actions.common.API__AUTHENTICATION__SIGN_IN__FAIL:
        case actions.common.API__AUTHENTICATION__CHOOSE_OWNER__FAIL:
            return DEFAULT_STATE__API__FAIL(state, DEFAULT_STATE, action);

        case actions.common.UI__AUTHENTICATION__SIGN_OUT:
            return {
                ...DEFAULT_STATE,
                data: {
                    autoSignOut: action?.payload?.autoSignOut
                }
            };

        default:
            return state;
    }
}