import React from "react";
import { ReactComponent as IconSearch } from "../../../../../../../../../resource/img/icons/icon_search.svg";
import AddAddressModalForm from "./form";

const AddressesListHeader = ({ groupId, groupName, _onSearchBarInputChange, searchBarTerm }) => {

    return (
        <section className='main-content--table__top container is-fluid'>
            <div className="row row--separated row--options">
                <div className='row__left'>
                    <div className="input-field header__search with-icon--right">
                        <input 
                            type='text'
                            placeholder='Znajdź adres'
                            onChange={ event => _onSearchBarInputChange(event.target.value) }
                        />
                        <IconSearch className='input-icon'/>
                    </div>
                </div>
                <div className='row__right'>
                    <AddAddressModalForm groupId={ groupId } groupName={ groupName } searchBarTerm={ searchBarTerm }/>
                    {/* TODO import addresses */ }
                </div>
            </div>
        </section>
    );
};

export default AddressesListHeader;