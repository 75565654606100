import React from "react";
import { Link } from "react-router-dom";
import StarRatings from "react-star-ratings";

import ComponentWithDropdown from "../../../../../common/component_with_dropdown";
import CompaniesDeleteModalForm from "./delete_modal_form";
import CompaniesCreateAndEditModalForm from "./create_and_edit_modal_form";
import { ROUTE_COMPANIES_DETAILS } from "../../../../../../core/constants";
import { ReactComponent as IconEllipsisVertical } from "../../../../../../../resource/img/icons/icon_ellipsis_vertical_gray.svg";

class CompaniesItem extends ComponentWithDropdown {

    render = () => {
        const { dropdownOpen = false } = this.state;
        const { company, _onSubmitCallback } = this.props;

        return (
            <div className="tiles-item tiles-item--clickable tiles-item--with-info">
                <Link to={ ROUTE_COMPANIES_DETAILS(company.id) } className='tiles-item__content'>
                    <div className="tiles-item__name tiles-item__name--3-lines">
                        {
                            company.name
                        }
                    </div>
                    <div>
                        <p>Ocena jakości usług: </p>
                        <div className="is-v-centered">
                            <span className="tile-item__rating-number">
                                {
                                    company.averageRating ? Math.round(company.averageRating * 100) / 100  : 0
                                }
                            </span>
                            <StarRatings
                                rating={ company.averageRating ? company.averageRating : 0 }
                                starDimension="15px"
                                starSpacing="2px"
                                starRatedColor="#ffcf30"
                                starEmptyColor="#7098bb"
                            />
                            <span className="tile-item__rating-votes">
                                (liczba opinii: { company.opinionCount ? company.opinionCount : 0 })
                            </span>
                        </div>
                    </div>

                </Link>
                <div className={ `dropdown  ${ dropdownOpen ? "is-active" : "" }` }>
                    <div className="dropdown-trigger">
                        <button className="unstyled-btn icon--menu" title='Więcej' onClick={ this._onDropdownOpen }>
                            <IconEllipsisVertical/>
                        </button>
                    </div>
                    <div className="dropdown-menu drop-to-right">
                        <div className="dropdown-content">

                            <CompaniesCreateAndEditModalForm
                                triggerComponent={ <span className="unstyled-btn dropdown-item">Zmień nazwę</span> }
                                company={ company }
                                _onSubmitCallback={ _onSubmitCallback }
                                headerText="Zmień nazwę firmy"/>

                            <CompaniesDeleteModalForm
                                triggerComponent={ <span className="unstyled-btn dropdown-item">Usuń</span> }
                                company={ company }
                                _onSubmitCallback={ _onSubmitCallback }/>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default CompaniesItem;

