import React, { Component } from "react";
import GenerateInspectionDocumentInspectionItem from "./inspection_item";


class GenerateInspectionDocumentMonthItem extends Component {

    render = () => {
        const { selectedInspections, selectedYear, month, inspections, _onInspectionClick } = this.props;

        return (
            <div className='generate-document__group'>
                <h2 className="header is-capitalized">
                    {
                        month + " " + selectedYear
                    }
                </h2>

                {
                    inspections.map(inspection => {
                        return <GenerateInspectionDocumentInspectionItem inspection={ inspection }
                                                                         selectedInspections={ selectedInspections}
                                                                         _onInspectionClick={ _onInspectionClick }
                                                                         key={ inspection.code }/>;
                    })
                }
            </div>

        );

    };

}


export default GenerateInspectionDocumentMonthItem;