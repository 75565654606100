import React, { Component } from "react";
import { Field, Form } from "formik";

import PasswordRequirements from "../../common/password_requirements";
import hiddenIcon from "../../../../resource/img/icons/icon_eye_off.svg";
import visibleIcon from "../../../../resource/img/icons/icon_eye.svg";
import { ReactComponent as InfoIcon } from "../../../../resource/img/icons/icon_info.svg";
import { MISC_EVENT_CLICK } from "../../../core/constants";
import { EmployeesEnum } from "../../../core/enums/common/employees";

class AccountActivationForm extends Component {

    state = {
        passwordVisible: false
    };

    render = () => {
        const { passwordVisible } = this.state;
        const { accountActivationStatusData, _validatePassword, _onSubmit } = this.props;

        let loginLabelText = 'Wpisz kod aktywacyjny uzyskany od administratora';
        let loginHintText = 'Kod aktywacyjny przekazuje główny administrator systemu. Jeśli jeszcze nie otrzymałeś kodu skontaktuj się z nim.'

        if(accountActivationStatusData.position === EmployeesEnum.position.ADMINISTRATOR) {
            loginLabelText = 'Wpisz kod aktywacyjny uzyskany od dostawcy systemu';
            loginHintText = <>Kod aktywacyjny przekazuje dostawca systemu. Jeśli jeszcze nie posiadasz kodu zadzwoń: 71&nbsp;75&nbsp;047&nbsp;03</>;
        }

        return (
            <>
                <div className="form--signed-out">
                    <Form>
                        <div className="input-wrapper">

                            <Field
                                name="activationCode"
                                render={ ({ field, form }) => (
                                    <div className="field field-separated--bottom">
                                        <label className="label">
                                            <div className="is-flex align-start">
                                                { loginLabelText }
                                                <div className="tooltip-trigger-icon">
                                                    <InfoIcon/>
                                                    <div className="tooltip-desc tooltip-desc--dark">
                                                        { loginHintText }
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="is-relative">
                                                <input
                                                    type="text"
                                                    className={ `${ form.errors.activationCode && form.touched.activationCode ? "rejected" : "" }` }
                                                    { ...field }/>
                                            </span>

                                            {
                                                form.errors.activationCode &&
                                                form.touched.activationCode &&

                                                <span className='hint hint--rejected'>{ form.errors.activationCode }</span>
                                            }

                                        </label>
                                    </div>
                                ) }/>

                            <Field
                                name="password"
                                validate={ _validatePassword }
                                render={ ({ field, form }) => (
                                    <>
                                        <div className="field">
                                            <label className="label">
                                                Utwórz hasło
                                                <span className="is-relative">
                                                    <input
                                                        type={ passwordVisible ? "text" : "password" }
                                                        className={ `${ (form.errors.passwordRepeat && form.touched.passwordRepeat) || (form.errors.password && form.touched.password) ? "rejected" : "" }` }
                                                        { ...field }/>

                                                    <span tabIndex="0" className="show-password"
                                                          onClick={ event => this._passwordVisibilityToggle(event) }
                                                          onKeyPress={ event => this._passwordVisibilityToggle(event) }>

                                                        <img src={ passwordVisible ? visibleIcon : hiddenIcon }
                                                             alt={ passwordVisible ? "Ukryj hasło" : "Pokaż hasło" }/>
                                                    </span>
                                                </span>
                                            </label>
                                        </div>
                                        <PasswordRequirements password={ form.values.password } touched={ form.touched.password }/>
                                    </>
                                ) }/>

                            <Field
                                name="passwordRepeat"
                                render={ ({ field, form }) => (
                                    <div className="field">
                                        <label className="label">
                                            Powtórz hasło
                                            <span className="is-relative">
                                                <input
                                                    type={ passwordVisible ? "text" : "password" }
                                                    className={ `${ form.errors.passwordRepeat && form.touched.passwordRepeat ? "rejected" : "" }` }
                                                    { ...field }/>

                                                <span tabIndex="0" className="show-password"
                                                      onClick={ event => this._passwordVisibilityToggle(event) }
                                                      onKeyPress={ event => this._passwordVisibilityToggle(event) }>
                                                    <img src={ passwordVisible ? visibleIcon : hiddenIcon }
                                                         alt={ passwordVisible ? "Ukryj hasło" : "Pokaż hasło" }/>
                                                </span>
                                            </span>

                                            {
                                                form.errors.passwordRepeat &&
                                                form.touched.passwordRepeat &&

                                                <span className='hint hint--rejected'>{ form.errors.passwordRepeat }</span>
                                            }

                                        </label>
                                    </div>
                                ) }/>

                        </div>

                        <div className="login-reminder text-center">
                            <strong>Pamiętaj! Twój login to: </strong>
                            {
                                accountActivationStatusData.email
                            }
                        </div>

                        <input type="submit"
                               className="btn btn--primary"
                               value="Aktywuj konto"
                               onClick={ _onSubmit }/>

                    </Form>
                </div>
            </>
        );
    };

    _passwordVisibilityToggle = (event) => {

        if (event.which === 13 || event.type === MISC_EVENT_CLICK) {

            this.setState(prevState => ({
                passwordVisible: !prevState.passwordVisible
            }));
        }
    };
}

export default AccountActivationForm;
