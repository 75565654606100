import React, { useEffect } from "react";

import TitleWithBottomDivider from "../../../../../common/title_with_bottom_divider";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { getManyReceiverGroups } from "../../../../../../data/actions/blisko/receiver_groups";
import { useDispatch } from "react-redux";
import ReceiverGroupItem from "./receiver_group_item";

const ReceiverGroupOrder = ({ isEditMode, formikBag }) => {
    const dispatch = useDispatch();
    const receiverGroups = formikBag?.values?.receiverGroups;
    const newReceiverGroupName = formikBag?.values?.name;

    useEffect(() => {
        dispatch(getManyReceiverGroups({ all: true }))
            .then((response) => {
                if (receiverGroups.length === 0) {
                    let newReceiverGroups =
                        response
                            ? [...response.map(receiverGroup => {
                                return {
                                    id: receiverGroup.id,
                                    hashId: receiverGroup.hashId,
                                    name: receiverGroup.name,
                                    current: false
                                }
                            })]
                            : [];

                    if (!isEditMode) {
                        newReceiverGroups.push({
                            id: "NEW",
                            hashId: "NEW",
                            name: newReceiverGroupName ? newReceiverGroupName : "Nowy serwis",
                            current: true
                        });
                    }

                    formikBag.setFieldValue("receiverGroups", newReceiverGroups);
                }
            });
        // eslint-disable-next-line
    }, [dispatch])

    useEffect(() => {
        const newReceiverGroups = Array.from(receiverGroups).map(receiverGroup => receiverGroup.current
            ? {
                ...receiverGroup,
                name: newReceiverGroupName.trim() ? newReceiverGroupName : "Nowy serwis"
            }
            : receiverGroup
        );

        formikBag.setFieldValue("receiverGroups", newReceiverGroups);
        // eslint-disable-next-line
    }, [newReceiverGroupName])

    const onDragEnd = result => {
        const { destination, source } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return;
        }

        const newReceiverGroups = Array.from(receiverGroups);

        const deletedItem = newReceiverGroups.splice(source.index, 1)[0];
        newReceiverGroups.splice(destination.index, 0, deletedItem);
        formikBag.setFieldValue("receiverGroups", newReceiverGroups);
    };

    return (
        <section className="media">
            <div className="media-left step-count-wrap is-spaced--top-30">
                <div className="step-count">
                    <span>3/3</span>
                </div>
            </div>
            <div className="media-content is-spaced--top-30">
                <div className="message-sending-1__section">
                    <div className="message-sending-1__subtitle">
                        <TitleWithBottomDivider>
                            Kolejność wyświetlania serwisów w aplikacji Blisko
                        </TitleWithBottomDivider>
                        <span>Złap i zmień kolejność wyświetlania serwisów w aplikacji Blisko.</span>
                    </div>
                    <DragDropContext
                        onDragEnd={ onDragEnd }>
                        <Droppable droppableId="receiver_group_order_dnd_column_id">
                            { (provided) => (
                                <div
                                    className="drag-and-drop__column is-spaced--top-40 is-spaced--bottom-50"
                                    ref={ provided.innerRef }
                                    { ...provided.droppableProps }>
                                    {
                                        receiverGroups?.map((receiverGroup, index) => <ReceiverGroupItem
                                            key={ receiverGroup.id }
                                            name={ receiverGroup.name }
                                            id={ receiverGroup.hashId }
                                            current={ receiverGroup.current }
                                            index={ index }/>)
                                    }
                                    { provided.placeholder }
                                </div>
                            ) }
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        </section>
    )
};

export default ReceiverGroupOrder;
