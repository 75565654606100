import getMany from "./get_many";
import deleteMany from "./delete_many";

export default (state = {}, action) => {
    return {
        ...state,
        getMany: getMany(state.getMany, action),
        deleteMany: deleteMany(state.deleteMany, action)
    };
}
