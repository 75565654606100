import React from "react";
import _ from "lodash";

import MessageHistoryListItem from "./item";
import Loader from "../../../../../../common/loader";
import { ReactComponent as IconArrowSwitchUp } from "../../../../../../../../resource/img/icons/list/icon_arrow_switch_up.svg";
import { isEmptyArray, isNullOrUndefinedOneOfMany } from "../../../../../../../core/utils/misc_utils";
import { MessageEnum } from "../../../../../../../core/enums/waste_management/messages";
import FormErrorMessage from "../../../../../../common/form_error_message";

const MessageHistoryList = ({ messages, cancelMessage }) => {

    if (isNullOrUndefinedOneOfMany(messages, messages.data, messages.meta, messages.meta.loading) ||
        messages.meta.loading) {
        return (
            <Loader/>
        );
    }

    const pendingMessages = _
        .filter(messages.data.content, messages => messages.sendingStatus === MessageEnum.sendingStatus.READY);

    const otherMessages = _
        .reject(messages.data.content, messages => messages.sendingStatus === MessageEnum.sendingStatus.READY);

    return (
        <section className="main-content--table__bottom container is-fluid">
            <div className='table-wrapper'>
                <table className='table--single-line fixed'>
                    <thead>
                    <tr>
                        <th className='t-width-2'>
                            Data wysyłki
                            <div className='th-action-btn'>
                                <IconArrowSwitchUp/>
                            </div>
                        </th>
                        <th className='t-width-3'>Utworzono przez</th>
                        <th className='t-width-6'>Treść wiadomości</th>
                    </tr>
                    </thead>
                    {
                        isEmptyArray(pendingMessages) && isEmptyArray(otherMessages) &&
                        <tbody className="is-spaced--top-20 tbody--message">
                            <tr>
                                <td>
                                    <FormErrorMessage message="Brak wyników wyszukiwania"/>
                                </td>
                            </tr>
                        </tbody>
                    }
                    <tbody className="notifications--pending">

                    {
                        renderMessageItems(pendingMessages, cancelMessage)
                    }

                    </tbody>
                    <tbody className="notifications--other">

                    {
                        renderMessageItems(otherMessages)
                    }

                    </tbody>
                </table>
            </div>
        </section>
    );
};

const renderMessageItems = (pendingMessages, cancelMessage) => {

    if (isEmptyArray(pendingMessages)) {
        return null;
    }

    return pendingMessages
        .map(messages => (
            <MessageHistoryListItem
                key={ messages.id }
                messages={ messages }
                cancelMessage={ cancelMessage }/>
        ));
};

export default MessageHistoryList;
