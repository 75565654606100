import { actions } from "../../../core/constants";
import * as api from "../../_api";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const getList = () => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_ADDRESSES__QRS__GET_LIST__STARTED
    });

    return api
        .ekostraznik
        .qrCodesLocations
        .getAll()
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__QRS__GET_LIST__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__QRS__GET_LIST__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
}

export const addAddressToList = (unitId) => dispatch => {
    return executeUpdateAction(() => api
        .ekostraznik
        .qrCodesLocations
        .addUnit(unitId),
        dispatch
    );
}

export const addAddressRangeToList = (unitIdFrom, unitIdTo) => dispatch => {
    return executeUpdateAction(() => api
            .ekostraznik
            .qrCodesLocations
            .addUnitsRange(unitIdFrom, unitIdTo),
        dispatch
    );
}

export const addAddressGroupToList = (groupId) => dispatch => {
    return executeUpdateAction(() => api
            .ekostraznik
            .qrCodesLocations
            .addAddressGroup(groupId),
        dispatch
    );
}

export const removeAddressGroupFromList = (groupId) => dispatch => {
    return executeUpdateAction(() => api
            .ekostraznik
            .qrCodesLocations
            .removeAddressGroup(groupId),
        dispatch
    );
}

export const removeAddressFromList = (groupId) => dispatch => {
    return executeUpdateAction(() => api
            .ekostraznik
            .qrCodesLocations
            .removeUnit(groupId),
        dispatch
    );
}

const executeUpdateAction = (action, dispatch) => {
    dispatch({
        type: actions.ekostraznik.API__EKO_ADDRESSES__QRS__UPDATE_LIST__STARTED
    });

    return action()
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__QRS__UPDATE_LIST__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__QRS__UPDATE_LIST__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
}

