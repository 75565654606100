import _ from "lodash";

import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const getManyServiceCards = () => dispatch => {
    dispatch({
        type: actions.services.API__SERVICE_CARDS__GET_MANY__STARTED
    });

    return api
        .services
        .serviceCards
        .getManyServiceCards()
        .then(response => {
            dispatch({
                type: actions.services.API__SERVICE_CARDS__GET_MANY__SUCCESS,
                meta: {},
                payload: _.mapKeys(extractResponseData(response), "id"),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__SERVICE_CARDS__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getServiceCard = (serviceCardId) => dispatch => {
    dispatch({
        type: actions.services.API__SERVICE_CARDS__GET__STARTED
    });

    return api
        .services
        .serviceCards
        .getServiceCard(serviceCardId)
        .then(response => {
            dispatch({
                type: actions.services.API__SERVICE_CARDS__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__SERVICE_CARDS__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getManyServiceCardsForCategory = (categoryId) => dispatch => {
    dispatch({
        type: actions.services.API__SERVICE_CARDS_FOR_CATEGORY__GET_MANY__STARTED
    });

    return api
        .services
        .serviceCards
        .getManyServiceCardsForCategory(categoryId)
        .then(response => {
            dispatch({
                type: actions.services.API__SERVICE_CARDS_FOR_CATEGORY__GET_MANY__SUCCESS,
                meta: {},
                payload: _.mapKeys(extractResponseData(response), "id"),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__SERVICE_CARDS_FOR_CATEGORY__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const deleteServiceCard = (serviceCardId) => dispatch => {
    dispatch({
        type: actions.services.API__SERVICE_CARDS__DELETE__STARTED
    });

    return api
        .services
        .serviceCards
        .deleteServiceCard(serviceCardId)
        .then(response => {
            dispatch({
                type: actions.services.API__SERVICE_CARDS__DELETE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__SERVICE_CARDS__DELETE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const publishServiceCard = (serviceCardId) => dispatch => {
    dispatch({
        type: actions.services.API__SERVICE_CARDS__PUBLISH__STARTED
    });

    return api
        .services
        .serviceCards
        .publishServiceCard(serviceCardId)
        .then(response => {
            dispatch({
                type: actions.services.API__SERVICE_CARDS__PUBLISH__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__SERVICE_CARDS__PUBLISH__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const hideServiceCard = (serviceCardId) => dispatch => {
    dispatch({
        type: actions.services.API__SERVICE_CARDS__HIDE__STARTED
    });

    return api
        .services
        .serviceCards
        .hideServiceCard(serviceCardId)
        .then(response => {
            dispatch({
                type: actions.services.API__SERVICE_CARDS__HIDE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__SERVICE_CARDS__HIDE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const addServiceServiceCard = (data) => dispatch => {
    dispatch({
        type: actions.services.API__SERVICE_CARDS__ADD_SERVICE__STARTED
    });

    return api
        .services
        .serviceCards
        .addServiceServiceCard(data)
        .then(response => {
            dispatch({
                type: actions.services.API__SERVICE_CARDS__ADD_SERVICE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__SERVICE_CARDS__ADD_SERVICE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const updateServiceServiceCard = (serviceCardId, data) => dispatch => {
    dispatch({
        type: actions.services.API__SERVICE_CARDS__UPDATE_SERVICE__STARTED
    });

    return api
        .services
        .serviceCards
        .updateServiceServiceCard(serviceCardId, data)
        .then(response => {
            dispatch({
                type: actions.services.API__SERVICE_CARDS__UPDATE_SERVICE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__SERVICE_CARDS__UPDATE_SERVICE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const addIssueHandleServiceCard = (data) => dispatch => {
    dispatch({
        type: actions.services.API__SERVICE_CARDS__ADD_ISSUE_HANDLE__STARTED
    });

    return api
        .services
        .serviceCards
        .addIssueHandleServiceCard(data)
        .then(response => {
            dispatch({
                type: actions.services.API__SERVICE_CARDS__ADD_ISSUE_HANDLE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__SERVICE_CARDS__ADD_ISSUE_HANDLE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const updateIssueHandleServiceCard = (serviceCardId, data) => dispatch => {
    dispatch({
        type: actions.services.API__SERVICE_CARDS__UPDATE_ISSUE_HANDLE__STARTED
    });

    return api
        .services
        .serviceCards
        .updateIssueHandleServiceCard(serviceCardId, data)
        .then(response => {
            dispatch({
                type: actions.services.API__SERVICE_CARDS__UPDATE_ISSUE_HANDLE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__SERVICE_CARDS__UPDATE_ISSUE_HANDLE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};