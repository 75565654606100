import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData } from "../../../core/utils/api_utils";

export const getInspection = (id) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__INSPECTION__GET__STARTED
    });

    return api
        .ekostraznik
        .inspections
        .getInspection(id)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTION__GET__SUCCESS,
                meta: {},
                payload: response.data,
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTION__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const updateInspectionStatus = (id, data) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__INSPECTION__UPDATE__STARTED
    });

    return api
        .ekostraznik
        .inspections
        .updateInspectionStatus(id, data)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTION__UPDATE__SUCCESS,
                meta: {},
                payload: response.data,
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTION__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const addInspection = (data) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__INSPECTION__ADD__STARTED
    });

    return api
        .ekostraznik
        .inspections
        .addInspection(data)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTION__ADD__SUCCESS,
                meta: {},
                payload: response.data,
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTION__ADD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};
