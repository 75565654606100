import React from "react";

import AdminHeader from "./header";

const AdminLayoutWithBackground = ({ history, children }) => {

    const childrenWithKey = React.Children.map(children, child => {
        return React.cloneElement(child, {
            key: child,
            history: history
        });
    });

    return (
        <div className='container--no-sidebar container--with-bg'>
            <div className="main-area">
                <AdminHeader/>
                <div className='container is-widescreen main-content'>
                    {
                        childrenWithKey
                    }
                </div>
            </div>
        </div>
    );
};

export default AdminLayoutWithBackground;

