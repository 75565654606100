import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Formik } from "formik";

import AccountStatusPending from "./../content/status_pending";
import AccountStatusSuspended from "./../content/status_suspended";
import withAccountFields from "../../common/account_form/with_account_fields_hoc";
import { BottomLeftSave } from "../../../../../common/bottom_action_buttons";
import { EmployeesEnum } from "../../../../../../core/enums/common/employees";
import { isNullOrUndefined } from "../../../../../../core/utils/misc_utils";
import { changeEmployeeAccountStatus, getEmployee, reinviteEmployee, updateEmployee } from "../../../../../../data/actions/common/employees";
import { toastFor500, toastForSuccess } from "../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../data/actions/common/ui/toast";

const initialFormValues = {
    name: "",
    surname: "",
    email: "",
    phoneNumber: "",
    description: "",
    moduleItems: []
};

class AccountDetailsContent extends Component {

    render = () => {
        const { employee, renderAccountFields } = this.props;

        const initialValues =
            !isNullOrUndefined(employee)
                ? this._fixEmployeesValues(employee)
                : this.initialFormValues;

        const isFormDisabled =
            employee.accountStatus === EmployeesEnum.status.INACTIVE ||
            employee.accountStatus === EmployeesEnum.status.BLOCKED ||
            employee.position === EmployeesEnum.position.ADMINISTRATOR;

        return (
            <main>
                {
                    employee.accountStatus === EmployeesEnum.status.INACTIVE &&

                    <AccountStatusPending employee={ employee }
                                          _onReinviteAction={ this._onReinviteAction }/>
                }
                {
                    employee.accountStatus === EmployeesEnum.status.BLOCKED &&

                    <AccountStatusSuspended employee={ employee }
                                            _onRestoreAction={ this._onRestoreAction }/>
                }

                <Formik
                    initialValues={ initialValues }
                    onSubmit={ this._onSubmit }
                    render={ () => (

                        <Form className="form--vertical form--with-legend has-bottom-action-buttons">

                            {
                                renderAccountFields(isFormDisabled, false, true)
                            }

                            {
                                !isFormDisabled &&
                                <BottomLeftSave containerClass="content--s" additionalElementClass="bottom-action-buttons--full-width"/>
                            }
                        </Form>
                    ) }/>
            </main>
        );
    };


    _onReinviteAction = (id) => {
        const { reinviteEmployee, reinvite, showToast } = this.props;

        return reinviteEmployee(id)
            .then(() => {

            toastFor500(showToast, reinvite, "Wysłanie zaproszenia nie powiodło się. Spróbuj ponownie");
            toastForSuccess(showToast, reinvite, "Zaproszenie zostało wysłane");
        });
    };

    _onRestoreAction = (id) => {
        const { changeEmployeeAccountStatus,change, showToast } = this.props;

        return changeEmployeeAccountStatus(id, EmployeesEnum.status.ACTIVE)
            .then(() => {

                toastFor500(showToast, change, "Zawieszony moderator nie został przywrócony. Spróbuj ponownie");
                toastForSuccess(showToast, change, "Konto moderatora zostało przywrócone");
            });
    };

    _fixEmployeesValues = () => {
        const { employee } = this.props;

        return {
            name:
                !isNullOrUndefined(employee.name)
                    ? employee.name
                    : initialFormValues.name,
            surname:
                !isNullOrUndefined(employee.surname)
                    ? employee.surname
                    : initialFormValues.surname,
            email:
                !isNullOrUndefined(employee.email)
                    ? employee.email
                    : initialFormValues.email,
            phoneNumber:
                !isNullOrUndefined(employee.phoneNumber)
                    ? employee.phoneNumber
                    : initialFormValues.phoneNumber,
            description:
                !isNullOrUndefined(employee.description)
                    ? employee.description
                    : initialFormValues.description,
            moduleItems:
                !isNullOrUndefined(employee.moduleItems)
                    ? employee.moduleItems
                    : initialFormValues.moduleItems,

        };
    };

    _onSubmit = (values) => {
        const { employee, updateEmployee, update, showToast } = this.props;

        return updateEmployee(employee.id, values)
            .then(() => {
                const { employeeUpdate } = this.props;

                if (!isNullOrUndefined(employeeUpdate.error) && employeeUpdate.error) {

                    toastFor500(showToast, update, "Wystąpił błąd. Spróbuj ponownie");

                    return Promise.reject(null);
                }

                toastForSuccess(showToast, update, "Zmiany zostały zapisane");

                return Promise.resolve();
            })
            .then(() => getEmployee(employee.id));
    };
}

const mapStateToProps = (state) => {
    return {
        reinvite: state.entities.common.employees.reinvite,
        change: state.entities.common.employees.change,
        update: state.entities.common.employees.update,
        employeeUpdate: state.entities.common.employees.update
    };
};

export default withAccountFields(
    connect(mapStateToProps, { updateEmployee, getEmployee, reinviteEmployee, changeEmployeeAccountStatus, showToast })(AccountDetailsContent)
);