import { deepFreeze } from "../../utils/misc_utils";

export const PermissionEnum = deepFreeze({
    module: {
        OPTIONS: {
            code: "options",
            type: {}
        },
        BLISKO: {
            code: "blisko",
            type: {
                MESSAGES: {
                    code: "blisko",
                    full_code: "blisko_blisko"
                },
                RECEIVER_GROUP: {
                    code: "receiver_group",
                    full_code: "blisko_receiver_group"
                },
                ADDRESS_TEMPLATE: {
                    code: "address_template",
                    full_code: "blisko_address_template"
                },
                MESSAGE_TEMPLATE: {
                    code: "message_template",
                    full_code: "blisko_message_template"
                },
                SENDER_DETAILS: {
                    code: "sender_details",
                    full_code: "blisko_sender_details"
                },
            }
        },
        EKOSTRAZNIK: {
          code: "ekostraznik",
          type: {
              EKOSTRAZNIK: {
                  code: "ekostraznik",
                  full_code: "ekostraznik_ekostraznik"
              },
              INSPECTION_HISTORY: {
                  code: "inspection_history",
                  full_code: "ekostraznik_inspection_history"
              },
              INSPECTIONS: {
                  code: "inspections",
                  full_code: "ekostraznik_inspections"
              },
              REPORTS: {
                  code: "reports",
                  full_code: "ekostraznik_reports"
              },
              COMPANIES: {
                  code: "inspection_companies",
                  full_code: "ekostraznik_inspection_companies"
              },
              ADDRESSES__GROUPED_ADDRESSES: {
                  code: "address_group",
                  full_code: "ekostraznik_address_group"
              },
              ADDRESSES__APARTMENT_ADDRESS: {
                  code: "apartment_address",
                  full_code: "ekostraznik_apartment_address"
              },
              ADDRESSES__QR_CODES: {
                  code: "qr_codes",
                  full_code: "ekostraznik_qr_codes"
              },
              INVENTORIES: {
                  code: "bin_inventory",
                  full_code: "ekostraznik_bin_inventory"
              }
          }
        },
        SERVICES: {
            code: "service_cards",
            type: {
                SERVICE_CARDS: {
                    code: "service_cards",
                    full_code: "service_cards_service_cards"
                }
            }
        },
        WASTE_MANAGEMENT: {
            code: "gok",
            type: {
                MUNICIPAL_WASTE: {
                    code: "management_area_and_schedule",
                    full_code: "gok_management_area_and_schedule"
                },
                BULKY_WASTE: {
                    code: "management_large_reception",
                    full_code: "gok_management_large_reception"
                },
                WASTE_COLLECTION_POINTS: {
                    code: "management_waste_collection_point",
                    full_code: "gok_management_waste_collection_point"
                },
                MESSAGES: {
                    code: "messages",
                    full_code: "gok_messages"
                },
                REPORTS: {
                    code: "reports",
                    full_code: "gok_reports"
                },
                AREAS: {
                    code: "area",
                    full_code: "gok_area"
                },
                PAYMENTS_DUE_DATE: {
                    code: "payment_period",
                    full_code: "gok_payment_period"
                },
                COMPANIES: {
                    code: "company",
                    full_code: "gok_company"
                },
                COMMUNAL_DOCUMENTS: {
                    code: "owner_communal_documents",
                    full_code: "gok_owner_communal_documents"
                }
            }
        }
    }
});
