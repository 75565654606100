import webClient from "../../../../core/web_client";
import { API_BASE_AKUN_GOK_PATH } from '../../../../core/constants';
import { buildQueryString } from "../../../../core/utils/api_utils";


const PATH_AREAS = `${ API_BASE_AKUN_GOK_PATH }/areas`;
const PATH = "locations";

export const getManyLocationsForArea = (areaId, queryParams) => {
    return webClient
        .get(`${ PATH_AREAS }/${ areaId }/${ PATH }${buildQueryString(queryParams)}`);
};

export const addLocations = (areaId, unitIds) => {
    return webClient
        .patch(
            `${ PATH_AREAS }/${ areaId }/${ PATH }`,
            {
                command: 'add-units',
                unitIds
            }
        )
}

export const addLocationsRange = (areaId, fromId, toId) => {
    return webClient
        .patch(
            `${ PATH_AREAS }/${ areaId }/${ PATH }`,
            {
                command: 'add-address-range',
                fromId,
                toId
            }
        )
}

export const removeLocations = (areaId, unitIds) => {
    return webClient
        .patch(
            `${ PATH_AREAS }/${ areaId }/${ PATH }`,
            {
                command: 'remove-units',
                unitIds
            }
        )
}