import React from "react";
import { isNullOrUndefined } from "../../../../../../../../core/utils/misc_utils";
import Select from "../../../../../../../common/select";

const SelectRegulationPlace = ({ formikBag, regulationPlaces, required }) => {
    const { regulationPlaceCode } = formikBag.errors;

    return (
        <label>
            <span className="label-name">Gdzie umieścić regulamin
                {
                    required &&
                    <span className="required-accent">*</span>
                }
            </span>
            <div>
                <Select
                    options={ regulationPlaces }
                    className={ !isNullOrUndefined(regulationPlaceCode) ? "rejected" : "" }
                    onChange={ option => formikBag.setFieldValue("regulationPlaceCode", option?.value) }
                />
                {
                    !isNullOrUndefined(regulationPlaceCode) &&

                    <span className="hint hint--rejected">{ regulationPlaceCode }</span>
                }
            </div>
        </label>
    );
};

export default SelectRegulationPlace;
