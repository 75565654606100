import React from "react";
import logoSM from "../../../../../resource/img/logo_sm.png";

const MessageLayout = (props) => {

    const childrenWithKey = React.Children.map(props.children, child => {
        return React.cloneElement(child, {
            key: child
        });
    });

    return (
        <div className="layout--message">
            <div className="container--message">
                <div className="text-center">
                    <img src={ logoSM } alt="logo Strefa Mieszkańca" className="logo"/>
                </div>
                <div className="content-wrapper">
                    {
                        childrenWithKey
                    }
                </div>
            </div>
        </div>
    );
};

export default MessageLayout;