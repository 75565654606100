import receptions from "./receptions";
import years from "./years"
import { isNullOrUndefined } from "../../../../../../core/utils/misc_utils";

export default (state = {}, action) => {
    return {
        ...state,
        receptions: receptions(!isNullOrUndefined(state) ? state.receptions : undefined, action),
        years: years(!isNullOrUndefined(state) ? state.years : undefined, action)
    }
}