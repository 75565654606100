import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";

import withModuleMainPage from "../../../common/hoc/with_module_main_page";
import Loader from "../../../common/loader";
import { ROUTE_SERVICES_CATEGORY_DETAILS } from "../../../../core/constants";
import { isLoading, isLoadingError } from "../../../../core/utils/misc_utils";

class ServicesModule extends Component {

    render = () => {
        const { categories, _onSidebarItemSelected } = this.props;
        if (isLoadingError(categories)) {
            return null;
        }

        if (isLoading(categories)) {
            return (
                <Loader/>
            );
        }

        return (
            <div>
                <div className="container is-fluid">
                    <header className="header--basic">
                        <h1 className="title is-1">
                            Karty usług
                        </h1>
                        <p>Wybierz kategorię, do której chcesz dodać kartę usług.</p>
                    </header>
                    <main>
                        <div className=''>
                            <div className='columns is-multiline'>

                                {
                                    _.map(categories.data, category => {
                                        return (
                                            <div key={ category.id }
                                                 className='column is-half-tablet is-one-third-desktop is-one-quarter-fullhd'>
                                                <Link to={ ROUTE_SERVICES_CATEGORY_DETAILS(category.id) }
                                                      onClick={ () => _onSidebarItemSelected(category.id) }
                                                      className='module__category module__category-bg module__category--management'>
                                                    <div className="module__category-desc">
                                                        <h2 className="title is-2 is-spaced">
                                                            {
                                                                category.name
                                                            }
                                                        </h2>
                                                        <p className="subtitle is-3">
                                                            {
                                                                category.description
                                                            }
                                                        </p>
                                                    </div>
                                                    <span className="icon-bg" style={ {backgroundImage: `url(${ category.iconUri })`} }/>
                                                </Link>
                                            </div>
                                        );
                                    })
                                }

                            </div>
                        </div>
                    </main>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        categories: state.entities.services.categories.getMany
    };
};

export default withModuleMainPage(connect(mapStateToProps)(ServicesModule));