import React, { Component } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, { formatDate } from "react-day-picker/moment";
import TimePicker from "rc-time-picker";
import moment from "moment";
import "moment/locale/pl";

import TitleWithDivider from "../../../../../../common/title_with_divider";
import Radio from "../../../../../../common/radio";
import { MISC_DATE_ISO_DATE_ONLY_FORMAT, MISC_DATE_ISO_TIME_ONLY_FORMAT } from "../../../../../../../core/constants";
import { isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";

class ShipmentDate extends Component {

    render = () => {
        const { sendImmediately, date, time, setFieldValue, _sendImmediatelyChange } = this.props;

        return (
            <section className="media is-spaced--bottom-50">
                <div className="media-left step-count-wrap">
                    <div className="step-count">
                        <span>3/3</span>
                    </div>
                </div>
                <div className="media-content">
                    <div className="message-sending-1__section">
                        <div className="message-sending-1__subtitle message-sending-1__subtitle--bottom-15">
                            <TitleWithDivider>
                                Termin wysyłki
                            </TitleWithDivider>
                        </div>
                        <div className="message-sending-1__subsection">

                            <label className="message-sending-1__toggle">
                                <Radio
                                    name="sendTerm"
                                    value={ true }
                                    groupValue={ sendImmediately }
                                    ariaLabel="Wyślij wiadomość od razu po akceptacji"
                                    onInputChange={ _sendImmediatelyChange }/>
                                <span>Wyślij wiadomość od razu po akceptacji</span>
                            </label>

                            <label className="message-sending-1__toggle">
                                <Radio
                                    name="sendTerm"
                                    value={ false }
                                    groupValue={ sendImmediately }
                                    ariaLabel="Zaplanuj termin wysyłki"
                                    onInputChange={ _sendImmediatelyChange }/>
                                <span>Zaplanuj termin wysyłki</span>
                            </label>

                        </div>

                        {
                            !isNullOrUndefined(sendImmediately) &&
                            !sendImmediately &&
                            <div className="message-sending-1__row message-sending-1__row--flex" role="group">

                                <label className="message-sending-1__input-wrap message-sending-1__input-wrap--with-icon message-sending-1__input-wrap--date message-sending-1__input-wrap--date-time">
                                    <span className="is-sr-only">Data</span>

                                    <DayPickerInput
                                        className="message-sending-1__input"
                                        format={ MISC_DATE_ISO_DATE_ONLY_FORMAT }
                                        formatDate={ formatDate }
                                        value={ !isNullOrUndefined(date) ? date : moment().format(MISC_DATE_ISO_DATE_ONLY_FORMAT) }
                                        onDayChange={ date => setFieldValue("date", moment(date).format(MISC_DATE_ISO_DATE_ONLY_FORMAT)) }
                                        inputProps={ {
                                            readOnly: true
                                        } }
                                        dayPickerProps={ {
                                            localeUtils: MomentLocaleUtils,
                                            locale: "pl"
                                        } }
                                    />

                                </label>

                                <label className="message-sending-1__input-wrap message-sending-1__input-wrap--with-icon message-sending-1__input-wrap--date-time">
                                    <span className="is-sr-only">Godzina</span>
                                    <TimePicker
                                        className="message-sending-1__input"
                                        allowEmpty={ false }
                                        aria-label="Godzina"
                                        showSecond={ false }
                                        value={ !isNullOrUndefined(time) ? moment(time, MISC_DATE_ISO_TIME_ONLY_FORMAT) : moment().seconds(0) }
                                        onChange={ time => setFieldValue("time", time.format(MISC_DATE_ISO_TIME_ONLY_FORMAT)) }
                                    />
                                </label>
                            </div>
                        }
                    </div>
                </div>
            </section>
        );
    };

    componentDidUpdate = (prevProps) => {
        const { sendImmediately, setFieldValue } = this.props;
        if (prevProps.sendImmediately === true && sendImmediately === false) {
            setFieldValue("date", moment().format(MISC_DATE_ISO_DATE_ONLY_FORMAT));
            setFieldValue("time", moment().seconds(0).format(MISC_DATE_ISO_TIME_ONLY_FORMAT));
        }
    };
}

export default ShipmentDate;