import React, { Component } from "react";
import PaymentDetailsItem from "./details/paymentsDetailsItem";

class MonthlyPayments extends Component {

    render = () => {
        return (
            <section className="payments-spacing">
                <h2 className="is-bold is-spaced--bottom-10">
                    Termin płatności
                </h2>

                <div className="payments__details payments-spacing">

                    <div className="payments-details__content">
                        <div className="payment-details__column">
                            <PaymentDetailsItem name="january" month="Styczeń"/>
                            <PaymentDetailsItem name="february" month="Luty"/>
                            <PaymentDetailsItem name="march" month="Marzec"/>
                            <PaymentDetailsItem name="april" month="Kwiecień"/>
                            <PaymentDetailsItem name="may" month="Maj"/>
                            <PaymentDetailsItem name="june" month="Czerwiec"/>
                        </div>
                        <div className="payment-details__column">
                            <PaymentDetailsItem name="july" month="Lipiec"/>
                            <PaymentDetailsItem name="august" month="Sierpień"/>
                            <PaymentDetailsItem name="september" month="Wrzesień"/>
                            <PaymentDetailsItem name="october" month="Październik"/>
                            <PaymentDetailsItem name="november" month="Listopad"/>
                            <PaymentDetailsItem name="december" month="Grudzień"/>
                        </div>
                    </div>
                </div>
            </section>
        );
    };

    componentDidUpdate = (prevProps) => {
        const { formikBag, receivePaymentsDueDate, _setFormValues } = this.props;
        if (receivePaymentsDueDate.data !== prevProps.receivePaymentsDueDate.data) {
            _setFormValues(formikBag, receivePaymentsDueDate);
        }
    };

    componentDidMount = () => {
        const { formikBag, receivePaymentsDueDate, _setFormValues } = this.props;
        _setFormValues(formikBag, receivePaymentsDueDate);
    };
}


export default MonthlyPayments;