import React, { useCallback, useEffect, useState } from "react";
import { MISC_EVENT_CLICK } from "../../core/constants";

const DropdownMenu = (props) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { triggerComponent } = props;

    const onTriggerClick = useCallback(() => {
        setDropdownOpen(true);
    }, []);

    const onDropdownClose = useCallback(() => {
        setDropdownOpen(false)
    }, []);

    useEffect(() => {
        if (dropdownOpen) {
            document.addEventListener(MISC_EVENT_CLICK, onDropdownClose);
        } else {
            document.removeEventListener(MISC_EVENT_CLICK, onDropdownClose);
        }
    }, [dropdownOpen, onDropdownClose])

    return (
        <div className={ `dropdown  ${ dropdownOpen ? "is-active" : '' }` }>
            <div className="dropdown-trigger" onClick={ onTriggerClick }>
                { triggerComponent }
            </div>

            <div className="dropdown-menu drop-to-right">
                <div className="dropdown-content">
                    { props.children }
                </div>
            </div>
        </div>
    );
}

export default DropdownMenu;
