import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import SidebarItem from "../sidebar_item";
import { ROUTE_SERVICES_CATEGORY_DETAILS } from "../../../../../core/constants";
import { isLoading, isLoadingError } from "../../../../../core/utils/misc_utils";
import { getManyCategories } from "../../../../../data/actions/services/categories";

class ServicesSidebarVariant extends Component {

    render = () => {
        const { categories, activeSidebarItem, _onSidebarItemSelected } = this.props;

        if (isLoadingError(categories) || isLoading(categories)) {
            return null;
        }

        return _.map(categories.data, category => {
            return (
                <SidebarItem
                    key={ category.id }
                    sidebarItemId={ category.id }
                    sidebarItemName={ category.name }
                    sidebarItemIcon={ category.iconUri }
                    sidebarItemLink={ ROUTE_SERVICES_CATEGORY_DETAILS(category.id) }
                    activeSidebarItem={ activeSidebarItem }
                    _onSidebarItemSelected={ _onSidebarItemSelected }/>
            );
        });
    };

    componentDidMount = () => {
        const { getManyCategories } = this.props;
        getManyCategories();
    };
}

const mapStateToProps = (state) => {
    return {
        categories: state.entities.services.categories.getMany
    };
};

export default connect(mapStateToProps, { getManyCategories })(ServicesSidebarVariant);