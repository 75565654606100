import add from "./add";
import getMany from "./get_many";
import { isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";

export default (state = {}, action) => {
    return {
        ...state,
        add: add(!isNullOrUndefined(state) ? state.add : undefined, action),
        getMany: getMany(!isNullOrUndefined(state) ? state.getMany : undefined, action)
    };
}