import React from "react";

import { BottomCustomButtons } from "../../../../../../common/bottom_action_buttons";
import InfoBanner from "../../../../../../common/info_banner";
import { useSelector } from 'react-redux';
import MessageSummary from "../../../../../../common/message_summary";

const Step2 = ({ values, useGeoLocation, useLocationFilter, uploadedFiles, currentStep, onPrevClick, _onCancel }) => {

    const messageAddresses = useSelector(state => state.entities?.wasteManagement?.messageAddresses?.getMany);

    if (currentStep !== 2) {
        return null;
    }

    return (
        <main className="notifications-2">
            <div className="content--m content--not-centered">

                <InfoBanner>
                    Wiadomość zostanie wysłana do użytkowników SegreGO na kanały e-mail i aplikacji mobilnej
                </InfoBanner>

                <MessageSummary time={ values.time }
                                date={ values.date }
                                title={ values.title }
                                body={ values.body }
                                latitude={ values.latitude }
                                longitude={ values.longitude }
                                locations={ messageAddresses?.data }
                                useGeoLocation={ useGeoLocation }
                                useLocationFilter={ useLocationFilter }
                                uploadedFiles={ uploadedFiles }/>
            </div>

            <BottomCustomButtons containerClass="content--m content--not-centered" buttonSpacingClass="space-between">
                <button onClick={ _onCancel } type="button" className="btn btn--outlined btn--medium">
                    Anuluj wysyłkę
                </button>
                <div className="notifications-2__btn-footer-group">
                    <button onClick={ onPrevClick } className="btn">Popraw wiadomość</button>
                    <button className="btn btn--primary btn--medium" type="submit">Wyślij</button>
                </div>
            </BottomCustomButtons>
        </main>
    );
};

export default Step2;