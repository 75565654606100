import React, { Component } from "react";
import { connect } from "react-redux";

import CompanyDetailsHeader from "./header";
import CompanyDetailsContent from "./content";
import { isNullOrUndefinedOneOfMany } from "../../../../../../core/utils/misc_utils";

class CompanyDetails extends Component {

    render = () => {
        const { companies, match } = this.props;
        const companyId = match.params.companyId;

        if (isNullOrUndefinedOneOfMany(companies, companies.data, companyId)) {
            return null;
        }

        return (
            <>
                <CompanyDetailsHeader company={ companies.data[companyId] }/>
                <CompanyDetailsContent company={ companies.data[companyId] }/>
            </>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        companies: state.entities.wasteManagement.companies.getMany
    };
};

export default connect(mapStateToProps)(CompanyDetails);