import React from "react";
import { passwordStrength } from "../../core/utils/password_utils";
import { isNullOrUndefined } from "../../core/utils/misc_utils";

const PasswordRequirements = ({ password, touched }) => {
    const strength = passwordStrength(password);

    return (
        <ul className='pass-requirements'>
            <li className={ passwordRequirementsClassName(strength.hasValidLength, touched) }>
                Minimalna wymagana liczba znaków: 8
            </li>

            <li className={ passwordRequirementsClassName(strength.hasUppercaseLetter, touched) }>
                Musi zawierać dużą literę
            </li>

            <li className={ passwordRequirementsClassName(strength.hasLowercaseLetter, touched) }>
                Musi zawierać małą literę
            </li>

            <li className={ passwordRequirementsClassName(strength.hasNumberOrSpecialCharacter, touched) }>
                Musi zawierać liczbę lub znak specjalny
            </li>
        </ul>
    );
};

const passwordRequirementsClassName = (field, passwordTouched) => {
    if (isNullOrUndefined(passwordTouched) || !passwordTouched) {
        return "neutral";
    }

    return field ? "accepted" : "rejected";
};

export default PasswordRequirements;