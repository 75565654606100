import React from "react";

import { useSelector } from "react-redux";
import TitleWithBottomDivider from "./title_with_bottom_divider";
import PasteTemplate from "../signed_in/modules/blisko/messages/sending/step-1/form/paste_template";
import Select from "./select";
import { Field } from "formik";
import TinyEditor from "./tiny_editor";
import { FileUpload, UploadedFile } from "./file_upload";
import Checkbox from "./checkbox";
import CenterPinMap from "./map/center_pin_map";
import TitleWithDivider from "./title_with_divider";
import { MessageEnum } from "../../core/enums/waste_management/messages";

const priorityOptions = [
    { label: MessageEnum.priorityLevel.INFO.description, value: MessageEnum.priorityLevel.INFO },
    { label: MessageEnum.priorityLevel.ALARM.description, value: MessageEnum.priorityLevel.ALARM },
    { label: MessageEnum.priorityLevel.WARNING.description, value: MessageEnum.priorityLevel.WARNING }
];

const MessageContent = ({
                            body,
                            useGeoLocation,
                            uploadedFiles,
                            priorityLevel = null,
                            latitude,
                            longitude,
                            defaultCoordinates,
                            withPasteTemplate = false,
                            setFieldValue,
                            errors,
                            step,
                            blisko = false,
                            setState = null,
                            _useGeoLocationToggle,
                            _onFileUploadStarted,
                            _onDeleteUploadedFileClick,
                            _onAlternativeTextChanged,
                            _onMessageContentChanged
                        }) => {
    const ownerConfiguration = useSelector(state => state.entities?.common?.owner?.getConfiguration);
    const uploadingFile = useSelector(state => state.entities?.common?.files?.add);

    const renderTitle = () => {
        if (blisko) {
            if (withPasteTemplate) {
                return (
                    <TitleWithBottomDivider
                        alternativeText={ <PasteTemplate
                            setFieldValue={ setFieldValue }
                            useGeoLocation={ useGeoLocation }
                            setState={ setState }
                            _useGeoLocationToggle={ _useGeoLocationToggle }
                        /> }
                        children={ "Treść wiadomości" }
                    />
                )
            } else {
                return (
                    <TitleWithBottomDivider children={ "Treść wiadomości" }/>
                )
            }
        } else {
            return (
                <TitleWithDivider>
                    Treść wiadomości<span className="required-accent">*</span>
                </TitleWithDivider>
            )
        }
    }

    return (
        <section className="media">
            <div className="media-left step-count-wrap is-spaced--top-30">
                <div className="step-count">
                    <span>{ step }</span>
                </div>
            </div>
            <div className="media-content is-spaced--top-30">
                <div className="message-sending-1__section">
                    <div className="message-sending-1__subtitle">
                        { renderTitle() }
                    </div>

                    {
                        blisko &&
                        <div className="is-spaced--top-20">
                            <span className="is-bold">Typ wiadomości</span>

                            <div className="input-size-l">
                                <Select
                                    options={ priorityOptions }
                                    value={ priorityOptions.find(priority => priority.value === priorityLevel) || priorityOptions[0] }
                                    isClearable={ false }
                                    onChange={ priorityOption => setFieldValue("priority", priorityOption.value) }/>
                            </div>
                        </div>
                    }

                    <div className="is-spaced--top-20">
                        {
                            blisko &&
                            <>
                                <span className="required-accent">*</span>
                                <span className="is-bold">Tytuł wiadomości</span>
                            </>
                        }
                        <Field
                            name="title"
                            render={ ({ field }) => (
                                <label className="message-sending-1__input-wrap">
                                    <input
                                        className={ errors.title ? "rejected" : "" }
                                        type="text"
                                        placeholder="Tytuł wiadomości"
                                        aria-label="Tytuł"
                                        { ...field }
                                    />
                                    {
                                        errors.title &&
                                        <strong className="hint hint--rejected">
                                            { errors.title }
                                        </strong>
                                    }
                                </label>
                            ) }/>
                    </div>

                    <div className="is-spaced--top-20">
                        {
                            blisko &&
                            <>
                                <span className="required-accent">*</span>
                                <span className="is-bold">Treść wiadomości</span>
                            </>
                        }
                        <TinyEditor
                            value={ body }
                            _onMessageContentChanged={ _onMessageContentChanged }
                            changeStyle={ errors.body ? "text-box__error" : "" }
                        />
                        {
                            errors.body &&
                            <strong className="hint hint--rejected">
                                { errors.body }
                            </strong>
                        }
                    </div>

                    {
                        uploadedFiles.map(file => {
                            return (
                                <UploadedFile
                                    key={ file.id }
                                    id={ file.id }
                                    name={ file.filename }
                                    error={ errors.uploadedFiles?.get(file.id) }
                                    isLoading={ false }
                                    _onDeleteUploadedFileClick={ _onDeleteUploadedFileClick }
                                    alternativeTextRequired={ file.alternativeTextRequired }
                                    initialAlternativeText={ file.alternativeText }
                                    _onAlternativeTextChanged={ text => {
                                        _onAlternativeTextChanged();
                                        file.alternativeText = text
                                    } }
                                />
                            );
                        })
                    }

                    {
                        uploadingFile.meta.loading &&
                        <UploadedFile
                            key={ uploadingFile.meta.filename }
                            name={ uploadingFile.meta.filename }
                            alternativeTextRequired={ false }
                            isLoading={ true }
                        />
                    }

                    <div className="message-sending-1__dropzone">
                        <FileUpload
                            disabled={ uploadingFile.meta.loading }
                            _onStart={ _onFileUploadStarted }
                            acceptedFileExtensions={ ['png', 'pdf', 'jpg', 'jpeg', 'doc', 'docx', 'odt'] }
                            maxFiles={ 3 }
                            currentFiles={ uploadedFiles }
                            maxTotalFilesSizeInMB={ 15 }
                        />
                    </div>

                    {
                        ownerConfiguration?.data?.collectionPointEnabled &&
                        <>
                            <label className="message-sending-1__toggle">

                                <Checkbox
                                    name="useGeoLocation"
                                    ariaLabel="Zaznacz na mapie"
                                    checked={ useGeoLocation }
                                    _onInputChange={ _useGeoLocationToggle }/>

                                <span>Zaznacz miejsce zdarzenia na mapie</span>
                            </label>

                            {
                                useGeoLocation &&
                                <CenterPinMap
                                    latitude={ latitude || defaultCoordinates.latitude }
                                    longitude={ longitude || defaultCoordinates.longitude }
                                    _onMapMoveEndCallback={ (latitude, longitude) => {
                                        setFieldValue("latitude", latitude);
                                        setFieldValue("longitude", longitude);
                                    } }
                                />
                            }
                        </>
                    }

                </div>
            </div>
        </section>
    );
}

export default MessageContent;