import webClient from "../../../core/web_client";
import { buildQueryString } from "../../../core/utils/api_utils";
import { API_BASE_AKUN_GOK_PATH } from '../../../core/constants';

const PATH = `${ API_BASE_AKUN_GOK_PATH }/waste-collection-points`;

export const getManyWasteCollectionPoints = (queryData) => {
    return webClient
        .get(`${ PATH }${ buildQueryString(queryData) }`);
};

export const addWasteCollectionPoint = (data) => {
    return webClient
        .post(`${ PATH }`, data);
};

export const updateWasteCollectionPoint = (id, data) => {
    const dataWithId = { ...data, id };

    return webClient
        .put(`${ PATH }`, dataWithId);
};

export const patchWasteCollectionPoint = (id, data) => {
    return webClient
        .patch(`${ PATH }/${ id }`, data);
};

export const patchManyWasteCollectionPoints = (data) => {
    return webClient
        .patch(`${ PATH }/status`, data);
};

export const deleteWasteCollectionPoint = (id) => {
    return webClient
        .delete(`${ PATH }/${ id }`);
};