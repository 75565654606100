import React from "react";
import TitleWithBottomDivider from "../../../../../../../common/title_with_bottom_divider";
import { Field } from "formik";

const MessageTemplateName = ({ formikBag }) => (
    <section className="media">
        <div className="media-left step-count-wrap is-spaced--top-30">
            <div className="step-count">
                <span>1/2</span>
            </div>
        </div>
        <div className="media-content is-spaced--top-30">
            <div className="message-sending-1__section">
                <div className="message-sending-1__subtitle">
                    <TitleWithBottomDivider>
                        <span className="required-accent">*</span>Nazwa szablonu
                    </TitleWithBottomDivider>
                </div>

                <Field
                    name="name"
                    render={ ({ field }) => (
                        <label
                            className="message-sending-1__input-wrap is-spaced--top-30">
                            <input
                                className={ formikBag.errors?.name ? "rejected" : "" }
                                type="text"
                                aria-label="Nazwa"
                                { ...field }
                            />
                            {
                                formikBag.errors?.name &&
                                <strong className="hint hint--rejected">
                                    { formikBag.errors?.name }
                                </strong>
                            }
                        </label>
                    ) }/>
            </div>
        </div>
    </section>
);

export default MessageTemplateName;