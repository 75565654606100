import React from "react";

import FormModal from "../../../../../common/modal/form_modal";

const SubcategoryListItemDeleteModalForm = ({ triggerComponent, _actionFunction }) => {

    return (
        <FormModal
            triggerComponent={ triggerComponent }
            deleteOrCancelButtons={ true }
            _actionFunction={ () => {
                return _actionFunction();
            } }>

            <div className='c-modal__body modal-size-m modal--no-header'>
                <p className="is-spaced--bottom-10 is-bold confirmation-alert">
                    Czy na pewno chcesz usunąć tą kartę?
                </p>
                <p>
                    Karta zostanie usunięta z całego systemu ze wszystkich kategorii usług.
                </p>
            </div>

        </FormModal>
    );
};

export default SubcategoryListItemDeleteModalForm;