import tinymce from "tinymce/tinymce";
// themes
import "tinymce/themes/silver";
// skins
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
// plugins
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/charmap";

export const initializeTinyMCE = () => {
    return tinymce.init({
        selector: "#tiny",
        plugins: ["link", "lists", "charmap"]
    });
};
