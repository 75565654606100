import React, { Component } from "react"
import { connect } from "react-redux";

import { isLoading, isLoadingError, isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";
import { getManyKeywords } from "../../../../../../../data/actions/services/keywords";
import ServiceCardsEnum from "../../../../../../../core/enums/services/service_cards";
import Select from "../../../../../../common/select";

class ServicesCardAdditionalInfoKeywordsContent extends Component {

    render = () => {
        const { field, form, keywords, options, value, _setValue, cardType } = this.props;

        return (
            <div className="column is-half field">
                <label>
                    <span className='label-name'>Słowa kluczowe</span>
                    <div className="input-with-suggestions">
                        <Select
                            isCreatable
                            isSearchable
                            isMulti
                            isDisabled={ isLoadingError(keywords) || isLoading(keywords) }
                            isLoading={ isLoading(keywords) }
                            placeholder='- Dodaj własne lub wybierz z listy słowa kluczowe -'
                            options={ options }
                            value={ value }
                            onChange={ (selectedOptions) => {
                                _setValue(selectedOptions);
                                form.setFieldValue("keywords", (selectedOptions || []).reduce((ids, option) => [...ids, option.value], []));
                            } }
                            onCreateOption={ newValue => {
                                _setValue([...value, { label: newValue, value: newValue }]);
                                form.setFieldValue("keywords", [...field.value, newValue]);
                            } }
                        />
                    </div>
                    <span className="hint has-text-grey">
                        {
                            cardType === ServiceCardsEnum.type.ISSUE_HANDLE
                                ?
                                "np. dokument tożsamości"
                                :
                                "np. autobus"
                        }
                    </span>
                </label>
            </div>
        )
    };

    componentDidMount = () => {
        const { field, getManyKeywords, _setOptionsAndValue } = this.props;

        getManyKeywords()
            .then(() => {
                const { keywords } = this.props;

                if (!isNullOrUndefined(keywords) && !isNullOrUndefined(keywords.data)) {
                    const options = keywords.data.map(keyword => {
                        return {
                            label: keyword,
                            value: keyword
                        };
                    });

                    const values = field.value.map(keyword => {
                        return {
                            label: keyword,
                            value: keyword
                        };
                    });

                    _setOptionsAndValue(options, values);
                }
            });
    };
}

const mapStateToProps = (state) => {
    return {
        keywords: state.entities.services.keywords.getMany
    };
};

export default connect(mapStateToProps, { getManyKeywords })(ServicesCardAdditionalInfoKeywordsContent);