import React from "react";

import ImgUeSigns from "../../../../../resource/img/eu_signs.png";

const SignInLayoutFooter = () => {

    return (
        <>
            <div className="signed-out-page__footer">
                <div className="signed-out-page__footer--links">
                    <div className='footer-item footer-copy'>
                        <p> &copy; Copyright  { new Date().getFullYear() } AMM Systems Sp. z o.o.  </p>
                    </div>
                    <ul className="footer-item footer-regulations">
                        <li>Polityka Prywatności</li>
                        <li>Regulaminy</li>
                    </ul>
                </div>
                <div>
                    <img src={ ImgUeSigns } alt="loga: Fundusze UE, Dolny Śląsk, Unia Europejska"/>
                </div>
            </div>

        </>
    );
};

export default SignInLayoutFooter;
