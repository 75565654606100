import get from "./get";
import getMany from "./get_many";
import getMessageTemplates from "./get_message_templates";
import add from "./add"
import delete_ from "./delete";

export default (state = {}, action) => {
    return {
        ...state,
        get: get(state.get, action),
        getMany: getMany(state.getMany, action),
        getMessageTemplates: getMessageTemplates(state.getMessageTemplates, action),
        add: add(state.add, action),
        delete_: delete_(state.delete_, action),
    };
}
