import _ from "lodash";

import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData } from "../../../core/utils/api_utils";
import {
    extractRelevantForwardGeocodingData,
    extractRelevantReverseGeocodingData
} from "../../../core/utils/geocoding_utils";

const innerForwardGeocoding = (dispatch, searchText) => {
    dispatch({
        type: actions.common.API__GEOCODING__FORWARD__STARTED
    });

    return api
        .common
        .geocoding
        .forwardGeocoding(searchText)
        .then(response => {
            dispatch({
                type: actions.common.API__GEOCODING__FORWARD__SUCCESS,
                meta: {
                    searchText
                },
                payload: extractRelevantForwardGeocodingData(response.data),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__GEOCODING__FORWARD__FAIL,
                meta: extractErrorData(error),
                payload: {},
                error: true
            });
        });
};

const debouncedInnerForwardGeocoding = _.debounce(innerForwardGeocoding, 250, { leading: true });

export const forwardGeocoding = (searchText) => dispatch => {
    return debouncedInnerForwardGeocoding(dispatch, searchText);
};

const innerReverseGeocoding = (dispatch, longitude, latitude) => {
    dispatch({
        type: actions.common.API__GEOCODING__REVERSE__STARTED
    });

    return api
        .common
        .geocoding
        .reverseGeocoding(longitude, latitude)
        .then(response => {
            dispatch({
                type: actions.common.API__GEOCODING__REVERSE__SUCCESS,
                meta: {
                    longitude,
                    latitude
                },
                payload: extractRelevantReverseGeocodingData(response.data),
                error: false
            });
            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__GEOCODING__REVERSE__FAIL,
                meta: extractErrorData(error),
                payload: {},
                error: true
            });
            return error.response;
        });
};

const debouncedInnerReverseGeocoding = _.debounce(innerReverseGeocoding, 100, { leading: true });

export const reverseGeocoding = (longitude, latitude) => dispatch => {
    return debouncedInnerReverseGeocoding(dispatch, longitude, latitude);
};

export const resetGeocoding = () => {
    return {
        type: actions.common.API__GEOCODING__RESET
    };
};