import React, { useCallback, useState } from "react";
import InventoriesHeader from "./header";
import InventoriesList from "./list";
import _ from "lodash";

const InventoryHistory = () => {
    const [searchBarTerm, setSearchBarTerm] = useState('');

    const _onSearchBarInputChange = useCallback(
        _.debounce(input => setSearchBarTerm(input || ''), 600),
        []
    );

    return (
        <div className="main-content--table">
            <InventoriesHeader
                _onSearchBarInputChange={ _onSearchBarInputChange }
            />
            <InventoriesList
                searchBarTerm={ searchBarTerm }/>
        </div>
    );
};

export default InventoryHistory;