import React, { Component } from "react";
import { isNullOrUndefined } from "../../core/utils/misc_utils";
import { ReactComponent as IconTrigger } from "../../../resource/img/icons/icon_chevron_down_dark.svg";

class Accordion extends Component {

    state = {
        expanded: false
    };

    render = () => {
        const { children, title, icon, actionButtons, wysiwygContent = false } = this.props;
        const { expanded } = this.state;

        return (
            <div className={ `accordion__section ${ expanded ? "active" : "" }` }>
                <div className="accordion__header">
                    <div className="is-v-centered full-width accordion__title-wrapper">
                        {
                            !isNullOrUndefined(icon) &&
                            <div className="accordion__icon">
                                {
                                    icon
                                }
                            </div>
                        }
                        <h3 className="accordion__title prevent-long-text">
                            {
                                title
                            }
                        </h3>
                    </div>
                    <div className="is-v-centered">
                        {
                            actionButtons
                        }
                        <div className="accordion__trigger_icon" onClick={ this._onTriggerClick }>
                            <IconTrigger/>
                        </div>
                    </div>
                </div>
                <div className={ `accordion__content ${ wysiwygContent ? "accordion__content--wysiwyg" : "" }` }>
                    <div className="accordion__content-spacing">
                        {
                            children
                        }
                    </div>
                </div>
            </div>
        );
    };

    _onTriggerClick = () => {
        this.setState(prevState => ({
            expanded: !prevState.expanded,
        }));
    };
}


export default Accordion;