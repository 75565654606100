import React from "react";

const AccountFormPermission = ({ item, moduleCode, checked, disabled, _onPermissionItemClicked }) => {

    return (
        <li className="column is-paddingless is-half">
            <label className={ `acceptance-label${ checked ? " label--checked" : "" }${ disabled ? " label--disabled" : ""}` }
                   onClick={ event => {
                       event.preventDefault();
                       if (disabled) {
                           return;
                       }

                       _onPermissionItemClicked({ itemCode: item.code, moduleCode: moduleCode });
                   } }>
                <input type="checkbox" className="acceptance-checkbox" disabled={ disabled }/>
                <span className="acceptance-text">

                    {
                        item.name
                    }

                    </span>
            </label>
        </li>
    );
};

export default AccountFormPermission;