import getMany from "./get_many";
import add from "./add";
import download from "./download";
import update from "./update";
import del from "./del";

export default (state = {}, action) => {
    return {
        ...state,
        getMany: getMany(state.getMany, action),
        add: add(state.add, action),
        download: download(state.download, action),
        update: update(state.update, action),
        del: del(state.del, action)
    };
}