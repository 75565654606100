import React from "react";
import _ from "lodash";

import { deepFreeze, isNullOrUndefinedOneOfMany } from "../../utils/misc_utils";

//statuses
import { ReactComponent as IconInProgress } from "../../../../resource/img/icons/status/icon_in_progress.svg";
import { ReactComponent as IconNew } from "../../../../resource/img/icons/status/icon_to_do.svg";
import { ReactComponent as IconRejected } from "../../../../resource/img/icons/status/icon_rejected.svg";
import { ReactComponent as IconDone } from "../../../../resource/img/icons/status/icon_done.svg";

//types
import { ReactComponent as IconMissingBin } from "../../../../resource/img/icons/ekostraznik_reports/types/missing_waste_bin.svg";
import { ReactComponent as IconDamagedBin } from "../../../../resource/img/icons/ekostraznik_reports/types/damaged_waste_bin.svg";
import { ReactComponent as IconNoAccess } from "../../../../resource/img/icons/ekostraznik_reports/types/no_access_to_bin.svg";
import { ReactComponent as IconBinCapacityExceded } from "../../../../resource/img/icons/ekostraznik_reports/types/waste_bin_capacity_exceeded.svg";
import { ReactComponent as IconOther } from "../../../../resource/img/icons/ekostraznik_reports/types/other.svg";




export const EkostraznikReportsEnum = deepFreeze({
    name: "EkostraznikReports",
    type: {
        MISSING_WASTE_BIN: {
            code: "MISSING_WASTE_BIN",
            label: "Brak pojemnika",
            icon: <IconMissingBin/>
        },
        DAMAGED_WASTE_BIN: {
            code: "DAMAGED_WASTE_BIN",
            label: "Uszkodzony pojemnik",
            icon: <IconDamagedBin/>
        },
        NO_ACCESS_TO_BIN: {
            code: "NO_ACCESS_TO_BIN",
            label: "Brak dojazdu do pojemnika",
            icon: <IconNoAccess/>
        },
        WASTE_BIN_CAPACITY_EXCEEDED: {
            code: "WASTE_BIN_CAPACITY_EXCEEDED",
            label: "Przeładowany pojemnik",
            icon: <IconBinCapacityExceded/>
        },
        OTHER: {
            code: "OTHER",
            label: "Inne zgłoszenie",
            icon: <IconOther/>
        }
    },
    commands: {
        changeStatus: "change-status",
        changeEmployee: "change-employee"
    },
    status: {
        NEW: {
            code: "new",
            icon: <IconNew/>
        },
        IN_PROGRESS: {
            code: "in_progress",
            icon: <IconInProgress/>
        },
        DONE: {
            code: "done",
            icon: <IconDone/>
        },
        REJECTED: {
            code: "rejected",
            icon: <IconRejected/>
        }
    }
});

export const getTypeOptions = () => {
    return _.map(EkostraznikReportsEnum.type, type => {
        return {
            label: type.label,
            data: type
        };
    });
};

export const getStatusByCode = (code) => {
    for (let key in EkostraznikReportsEnum.status) {
        if (EkostraznikReportsEnum.status.hasOwnProperty(key) &&
            EkostraznikReportsEnum.status[key].code === code) {
            return EkostraznikReportsEnum.status[key];
        }
    }

    return null;
};

export const isDisabled = (current, selected) => {
    if (isNullOrUndefinedOneOfMany(current, selected)) {
        return false;
    }

    switch (selected) {

        case EkostraznikReportsEnum.status.NEW:
            if (current === EkostraznikReportsEnum.status.NEW) {
                return true;
            }

            return false;

        case EkostraznikReportsEnum.status.IN_PROGRESS:
            if (current === EkostraznikReportsEnum.status.NEW ||
                current === EkostraznikReportsEnum.status.IN_PROGRESS) {
                return true;
            }
            return false;

        case EkostraznikReportsEnum.status.DONE:
            return true;

        case EkostraznikReportsEnum.status.REJECTED:
            return true;

        default:
            return false;
    }
};