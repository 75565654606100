import add from "./add";
import change from "./change";
import delete_ from "./delete";
import get from "./get";
import getMany from "./get_many";
import reinvite from "./reinvite";
import update from "./update";

export default (state = {}, action) => {
    return {
        ...state,
        add: add(state.add, action),
        change: change(state.change, action),
        delete_: delete_(state.delete_, action),
        get: get(state.get, action),
        getMany: getMany(state.getMany, action),
        reinvite: reinvite(state.reinvite, action),
        update: update(state.update, action)
    };
}