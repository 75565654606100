import { useDispatch, useSelector } from "react-redux";
import FormModal from "../../../../../common/modal/form_modal";
import { ReactComponent as IconDelete } from "../../../../../../../resource/img/icons/icon_delete.svg";
import React from "react";
import { deleteManyInventories } from "../../../../../../data/actions/ekostraznik/inventories";

const DeleteInventoryForm = ({ inventory, _onSuccess }) => {
    const dispatch = useDispatch();
    const deleteStatus = useSelector(state => state.entities?.ekostraznik?.inventories?.deleteMany);
    const inventoryName = inventory?.group?.name || (inventory?.location?.town + ' ' + (inventory?.location?.street || '') + " " + (inventory?.location?.address || ''));

    return (
        <FormModal
            triggerComponent={
                <button className='unstyled-btn' title='Usuń'>
                    <IconDelete/>
                </button>
            }
            deleteOrCancelButtons={ true }
            _actionFunction={ () =>
                dispatch(deleteManyInventories({
                    ids: [inventory.lastInventoryId]
                })).then(async () => _onSuccess())
            }
            isLoading={ deleteStatus?.meta?.loading }
        >

            <div className='c-modal__body modal-size-m modal--no-header'>
                <p className="confirmation-alert">
                    <strong>Czy na pewno chcesz usunąć inwentaryzację { inventoryName }?</strong>
                </p>
            </div>

        </FormModal>
    );
};

export default DeleteInventoryForm;