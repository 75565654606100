import { actions } from "../../../../core/constants";
import * as api from "../../../_api";
import { extractErrorData, extractResponseData } from "../../../../core/utils/api_utils";

export const getManyAddressTemplates = (queryParams) => dispatch => {
    dispatch({
        type: actions.blisko.API__BLISKO__ADDRESS_TEMPLATES__GET_MANY__STARTED
    });

    return api
        .blisko
        .addressTemplates
        .getMany(queryParams)
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__ADDRESS_TEMPLATES__GET_MANY__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__ADDRESS_TEMPLATES__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });

};

export const geAddressTemplate = (id) => dispatch => {
    dispatch({
        type: actions.blisko.API__BLISKO__ADDRESS_TEMPLATES__GET__STARTED
    });

    return api
        .blisko
        .addressTemplates
        .get(id)
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__ADDRESS_TEMPLATES__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__ADDRESS_TEMPLATES__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });

};

export const addAddressTemplate = (data) => dispatch => {
    dispatch({
        type: actions.blisko.API__BLISKO__ADDRESS_TEMPLATES__ADD__STARTED
    });

    return api
        .blisko
        .addressTemplates
        .addAddressTemplate(data)
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__ADDRESS_TEMPLATES__ADD__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });

            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__ADDRESS_TEMPLATES__ADD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });

            return error.response;
        });

};

export const updateAddressTemplate = (id, data) => dispatch => {
    dispatch({
        type: actions.blisko.API__BLISKO__ADDRESS_TEMPLATES__UPDATE__STARTED
    });

    return api
        .blisko
        .addressTemplates
        .updateAddressTemplate(id, data)
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__ADDRESS_TEMPLATES__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });

            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__ADDRESS_TEMPLATES__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });

            return error.response;
        });
};

export const deleteManyAddressTemplates = (data) => dispatch => {
    dispatch({
        type: actions.blisko.API__BLISKO__ADDRESS_TEMPLATES__DELETE_MANY__STARTED
    });

    return api
        .blisko
        .addressTemplates
        .deleteMany(data)
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__ADDRESS_TEMPLATES__DELETE_MANY__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__ADDRESS_TEMPLATES__DELETE_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};
