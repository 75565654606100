import _ from "lodash";

import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const addInspectionCompany = (data) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__INSPECTION_COMPANIES__ADD__STARTED
    });

    return api
        .ekostraznik
        .inspectionCompanies
        .addInspectionCompany(data)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTION_COMPANIES__ADD__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTION_COMPANIES__ADD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getManyInspectionCompanies = () => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__INSPECTION_COMPANIES__GET_MANY__STARTED
    });

    return api
        .ekostraznik
        .inspectionCompanies
        .getManyInspectionCompanies()
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTION_COMPANIES__GET_MANY__SUCCESS,
                meta: {},
                payload: _.mapKeys(extractResponseData(response), "id"),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTION_COMPANIES__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const renameInspectionCompany = (id, data) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__INSPECTION_COMPANIES__RENAME__STARTED
    });

    return api
        .ekostraznik
        .inspectionCompanies
        .renameInspectionCompany(id, data)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTION_COMPANIES__RENAME__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTION_COMPANIES__RENAME__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: false
            });
        });
};

export const updateInspectionCompany = (id, data) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__INSPECTION_COMPANIES__UPDATE__STARTED
    });

    return api
        .ekostraznik
        .inspectionCompanies
        .updateInspectionCompany(id, data)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTION_COMPANIES__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTION_COMPANIES__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: false
            });
        });
};

export const generateNewInspectionCompanyCode = (id, data) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__INSPECTION_COMPANIES__GENERATE_NEW_CODE__STARTED
    });

    return api
        .ekostraznik
        .inspectionCompanies
        .updateInspectionCompany(id, data)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTION_COMPANIES__GENERATE_NEW_CODE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTION_COMPANIES__GENERATE_NEW_CODE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: false
            });
        });
};

export const resetInspectionCompanies = () => {
    return {
        type: actions.ekostraznik.INSPECTION_COMPANIES__RESET,
        payload: {}
    };
};
