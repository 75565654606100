import React from "react";
import { useDispatch, useSelector } from "react-redux";

import FormModal from "../../../../../common/modal/form_modal";
import { deleteReceiverGroup } from "../../../../../../data/actions/blisko/receiver_groups";
import { invoke } from "../../../../../../core/utils/misc_utils";
import { toastForCustomError, toastForCustomSuccess } from "../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../data/actions/common/ui/toast";
import { ReactComponent as IconDelete } from "../../../../../../../resource/img/icons/icon_delete.svg";
import { is4xx, is5xx } from "../../../../../../core/utils/api_utils";

const ReceiverGroupDeleteModalForm = ({ receiverGroup, _onSubmitCallback }) => {
    const dispatch = useDispatch();
    const receiverGroupDelete = useSelector(state => state.entities?.blisko?.receiverGroups?.delete_);

    const _onSubmit = () => {
        return dispatch(deleteReceiverGroup(receiverGroup.id))
            .then(response => {
                if (is4xx(response?.status) || is5xx(response?.status)) {
                    toastForCustomError((...args) => dispatch(showToast(...args)), "Serwis nie został usunięty. Spróbuj ponownie");
                } else {
                    toastForCustomSuccess((...args) => dispatch(showToast(...args)), "Serwis został usunięty");
                    return invoke(_onSubmitCallback);
                }
            });
    };

    return (
        <FormModal
            triggerComponent={
                <div className='unstyled-btn' title='Usuń'>
                    <IconDelete/>
                </div>
            }
            deleteOrCancelButtons={ true }
            isLoading={ receiverGroupDelete?.meta.loading }
            _actionFunction={ () => _onSubmit() }>

            <div className='c-modal__body modal-size-m modal--no-header'>
                <p className="confirmation-alert">
                    Czy na pewno chcesz usunąć serwis? Pamiętaj, że użytkownicy dostaną wiadomość o jego usunięciu. Czy chcesz kontynuować?
                </p>
            </div>

        </FormModal>
    );
};

export default ReceiverGroupDeleteModalForm;

