import React, { Component } from "react";
import { connect } from "react-redux";

import FormModal from "../../../../../common/modal/form_modal";
import { invoke, isNullOrUndefined } from "../../../../../../core/utils/misc_utils";
import { deleteCompany } from "../../../../../../data/actions/waste_management/companies";
import { toastFor500, toastForSuccess } from "../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../data/actions/common/ui/toast";

class CompaniesDeleteModalForm extends Component {

    render = () => {
        const { company, triggerComponent } = this.props;
        return (
            <FormModal
                triggerComponent={ triggerComponent }
                deleteOrCancelButtons={ true }
                _actionFunction={ () => {
                    return this._onSubmit();
                } }>

                <div className='c-modal__body modal-size-m modal--no-header'>
                    <p className="confirmation-alert">
                        Czy na pewno chcesz usunąć firmę <span className="is-bold">{ company.name }</span> ?
                    </p>
                </div>

            </FormModal>
        );
    };

    _onSubmit = () => {
        const { company, deleteCompany, _onSubmitCallback, showToast, companyDelete_ } = this.props;

        if (!isNullOrUndefined(company)) {
            return deleteCompany(company.id)
                .then(() => invoke(_onSubmitCallback))
                .then( () => {
                    toastFor500(showToast, companyDelete_, "Firma wywożąca nie została usunięta. Spróbuj ponownie");
                    toastForSuccess(showToast, companyDelete_, "Usunięto firmę wywożącą odpady");
                });
        } else {
            invoke(_onSubmitCallback);
        }
    };

}

const mapStateToProps = (state) => {
    return {
        companyDelete_: state.entities.wasteManagement.companies.delete_
    };
};

export default connect(mapStateToProps, { deleteCompany, showToast })(CompaniesDeleteModalForm);


