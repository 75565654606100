import _ from "lodash";
import FormModal from "../../../../../../../common/modal/form_modal";
import { isEmptyObject } from "../../../../../../../../core/utils/misc_utils";
import { copyReceptionFromYearToYear } from "../../../../../../../../data/actions/waste_management/receptions";
import FormRequiredLegend from "../../../../../../../common/legend_required";
import Select from "../../../../../../../common/select";
import CheckboxGroup from "../../../../../../../common/checkbox_group";
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import InfoBanner from "../../../../../../../common/info_banner";
import { getManyMunicipalWasteReceptionsForArea } from "../../../../../../../../data/actions/waste_management/areas/municipal_waste/receptions";
import { toastForSuccess } from "../../../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../../../data/actions/common/ui/toast";

const ByYearCopyForm = ({ bins, areas, selectedArea, selectedYear, selectedBin }) => {
    const dispatch = useDispatch();
    const years = useSelector(state => state.entities.wasteManagement?.areas?.municipalWaste?.years?.getMany);
    const copyStatus = useSelector(state => {
        return state.entities?.wasteManagement?.receptions?.copy
    });
    const initialValues = {
        areaIdsToUpdate: [],
        binIdsToUpdate: [],
        yearFrom: null,
        yearTo: null
    };

    const validate = useCallback(values => (_.pickBy({
        areaIdsToUpdate: values.areaIdsToUpdate.length < 1 ? 'Pole wymagane' : undefined,
        binIdsToUpdate: values.binIdsToUpdate.length < 1 ? 'Wybierz przynajmniej jedną opcję' : undefined,
        yearFrom: values.yearFrom === null || values.yearFrom === undefined ? 'Pole wymagane' : undefined,
        yearTo: values.yearTo === null || values.yearTo === undefined ? 'Pole wymagane' : undefined
    })), []);

    if (!bins || !areas.data || !years.data) {
        return '';
    }

    const areaOptions = Object.values(areas.data || {}).map(area => ({ label: area.name, value: area.id }));
    const yearOptions = Object.values(years.data[selectedArea] || {}).map(year => ({ label: year, value: year }));

    return (
        <Formik
            initialValues={ initialValues }
        >
            {
                formik => (
                    <FormModal
                        isLoading={ copyStatus?.meta?.loading }
                        triggerComponent={ <span
                            className="unstyled-btn dropdown-item">Kopiuj harmonogram między latami</span> }
                        saveOrCancelButtons={ true }
                        headerText="Kopiuj harmonogram między latami"
                        saveButtonText="Kopiuj"
                        _actionFunction={ async () => {
                            const errors = validate(formik.values);
                            if (!isEmptyObject(errors)) {
                                formik.setErrors(errors);
                                return Promise.reject(null);
                            }
                            dispatch(copyReceptionFromYearToYear(formik.values))
                                .then(() => dispatch(getManyMunicipalWasteReceptionsForArea(selectedArea, selectedYear, selectedBin)))
                                .then(() => toastForSuccess((...args) => dispatch(showToast(...args)), copyStatus, "Harmonogram został skopiowany"));
                        } }
                    >
                        <div className='c-modal__body__base modal-size-m'>
                            <div className='is-relative is-spaced--bottom-20'>
                                <FormRequiredLegend/>
                            </div>
                            <Form>
                                <div className='flex-column is-spaced--top-10'>
                                    <div className='field-group-header is-spaced--bottom-20'>
                                        Kopiuj harmonogram z:
                                    </div>
                                    <div className="is-spaced--bottom-30">
                                        <label>
                                            <span className="label-name"><span className="required-accent">*</span>Wybierz rok</span>
                                            <div className="is-relative full-width">
                                                <div>
                                                    <Select
                                                        className={ formik.errors.yearFrom ? 'rejected' : '' }
                                                        value={ formik.values.yearFrom ? undefined : null }
                                                        options={ yearOptions }
                                                        onChange={ selectedOption => formik.setFieldValue("yearFrom", selectedOption?.value || null) }
                                                    />
                                                </div>
                                                {
                                                    formik.errors.yearFrom &&
                                                    <strong className="hint hint--rejected">
                                                        {
                                                            formik.errors.yearFrom
                                                        }
                                                    </strong>
                                                }
                                            </div>
                                        </label>
                                    </div>
                                    <div>
                                        <span className="required-accent">*</span>
                                        <span className='label-name'>Wybierz obszary</span>
                                        <Select
                                            className={ formik.errors.areaIdsToUpdate ? 'rejected' : '' }
                                            isMulti
                                            options={ areaOptions }
                                            onChange={ options => {
                                                formik.setFieldValue('areaIdsToUpdate', (options || []).map(option => option.value))
                                            } }
                                        />
                                        {
                                            formik.errors.areaIdsToUpdate &&
                                            <strong
                                                className="hint hint--rejected">{ formik.errors.areaIdsToUpdate }</strong>
                                        }
                                    </div>
                                    <div className='is-spaced--top-30'>
                                        <div className='is-spaced--bottom-10'>
                                            <span className="required-accent">*</span>
                                            <span className='label-name'>Wybierz frakcje, które chcesz skopiować</span>
                                        </div>

                                        <CheckboxGroup
                                            options={ Object.values(bins).map(bin => ({
                                                label: bin.name,
                                                value: bin.id
                                            })) }
                                            onChange={ options => {
                                                formik.setFieldValue('binIdsToUpdate', (options || []).map(option => option.value))
                                            } }
                                        />
                                        {
                                            formik.errors.binIdsToUpdate &&
                                            <strong
                                                className="hint hint--rejected">{ formik.errors.binIdsToUpdate }</strong>
                                        }
                                    </div>
                                    <div className='field-group-header is-spaced--top-30 is-spaced--bottom-20'>
                                        Wklej na:
                                    </div>
                                    <div className="is-spaced--bottom-30">
                                        <label>
                                            <span className="label-name"><span className="required-accent">*</span>Wybierz rok</span>
                                            <div className="is-relative full-width">
                                                <div>
                                                    <Select
                                                        className={ formik.errors.yearTo ? 'rejected' : '' }
                                                        value={ formik.values.yearTo ? undefined : null }
                                                        options={ yearOptions }
                                                        onChange={ selectedOption => formik.setFieldValue("yearTo", selectedOption?.value || null) }
                                                    />
                                                </div>
                                                {
                                                    formik.errors.yearTo &&
                                                    <strong className="hint hint--rejected">
                                                        {
                                                            formik.errors.yearTo
                                                        }
                                                    </strong>
                                                }
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </Form>
                            <InfoBanner>
                                Przed publikacją harmonogramu zwróć uwagę na dni wolne od pracy.
                            </InfoBanner>
                        </div>

                    </FormModal>
                )
            }
        </Formik>
    );
}
export default ByYearCopyForm;
