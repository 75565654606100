import React, { Component } from "react";

import SideList from "./side_list";
import SideListDetails from "./side_list_details";
import SideListBlankPage from "./side_list_blank_page";
import { isEmptyArray, isNullOrUndefined } from "../../../core/utils/misc_utils";

class SideListLayout extends Component {

    render = () => {
        const { data, selectedListItem, list, _onListItemClick, listType, renderIconFunction, _onPrevClick, _onNextClick, _onPageNumberInputValueChange } = this.props;

        return (
            <section className="sll__content">
                <SideList
                    data={ data }
                    selectedListItem={ selectedListItem }
                    list={ list }
                    _onListItemClick={ _onListItemClick }
                    listType={ listType }
                    renderIconFunction={ renderIconFunction }
                    _onPrevClick={ _onPrevClick }
                    _onNextClick={ _onNextClick }
                    _onPageNumberInputValueChange={ _onPageNumberInputValueChange }/>
                {
                    this.renderDetails()
                }
            </section>
        );
    };

    renderDetails = () => {
        const { list, selectedListItem, children, noListText, noListImg, selectItemText, selectItemImg } = this.props;

        if (!isNullOrUndefined(selectedListItem)) {
            return (
                <SideListDetails>
                    {
                        children
                    }
                </SideListDetails>
            );
        } else if (isEmptyArray(list)) {
            return (
                <SideListBlankPage text={ noListText } img={ noListImg }/>
            );
        } else {
            return (
                <SideListBlankPage text={ selectItemText } img={ selectItemImg }/>
            );
        }
    };

}

export default SideListLayout;