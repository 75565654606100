import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import FormModal from "../form_modal";
import LocationEditFormContent from "./location_edit_modal_content";
import { deepFreeze, invoke, } from "../../../../core/utils/misc_utils";
import {
    getManyLocationCandidatesStreetAddresses,
    getManyLocationCandidatesTownAddressesAndStreets,
    resetLocationCandidates
} from "../../../../data/actions/waste_management/location_candidates";
import { LocationsEnum } from "../../../../core/enums/waste_management/locations";
import { ReactComponent as IconEdit } from "../../../../../resource/img/icons/icon_edit.svg";

const initialState = deepFreeze({
        additiveLocations: [],
        subtractiveLocations: [],
        unitIdsToRemove: [],
        unitIdsToAdd: [],
    }
);

class LocationEditModalForm extends Component {

    state = initialState;

    render = () => {
        const { location, locationCandidateAddresses, _onLocationEditClick } = this.props;
        const { unitIdsToRemove, unitIdsToAdd } = this.state;

        if (!location?.addresses) {
            return null;
        }

        return (
            <FormModal
                triggerComponent={
                    <button className='unstyled-btn' title='Edytuj'>
                        <IconEdit/>
                    </button>
                }
                ariaLabel='Edycja adresów'
                headerText='Edycja adresów'
                saveOrCancelButtons={ true }
                _actionFunction={ () => _onLocationEditClick(unitIdsToAdd, unitIdsToRemove) }
                _cancelFunction={ () => {
                    return this._resetState();
                } }>

                <LocationEditFormContent
                    location={ location }
                    locationCandidateAddresses={ locationCandidateAddresses }
                    _loadLocationDataToEdit={ this._loadLocationDataToEdit }
                    _onSelectionChange={ this._onSelectionChange }
                />

            </FormModal>
        );
    };

    _onSelectionChange = (selectedUnitIds) => {
        const { location } = this.props;
        const currentIds = location?.addresses.map(address => address.id) || [];
        this.setState({
            unitIdsToRemove: _.difference(currentIds, selectedUnitIds),
            unitIdsToAdd: _.difference(selectedUnitIds, currentIds)
        });
    }

    _loadLocationDataToEdit = (location, _callback) => {
        if (!location) {
            return;
        }
        const { getManyLocationCandidatesTownAddressesAndStreets, getManyLocationCandidatesStreetAddresses, resetLocationCandidates } = this.props;

        resetLocationCandidates(false, true, true, true);

        if (location.parent.kind === LocationsEnum.type.TOWN) {
            getManyLocationCandidatesTownAddressesAndStreets(location.parent.id).then(() => invoke(_callback));
        } else {
            getManyLocationCandidatesStreetAddresses(location.parent.id).then(() => invoke(_callback));
        }
    };

    _resetState = () => {
        return new Promise(resolve => {
            this.setState(initialState, () => resolve());
        });
    };
}

const mapStateToProps = (state) => {
    return {
        locationCandidateAddresses: state.entities.wasteManagement.locationCandidates.addresses.getMany
    };
};

export default connect(mapStateToProps, {
    getManyLocationCandidatesTownAddressesAndStreets,
    getManyLocationCandidatesStreetAddresses,
    resetLocationCandidates
})(LocationEditModalForm);

