import React, { Component } from "react";

import AdminHeader from "./header";
import Toast from "../../common/toast";

class AdminLayout extends Component {

    render = () => {
        const { history, children } = this.props;

        const childrenWithKey = React.Children.map(children, child => {
            return React.cloneElement(child, {
                key: child,
                history: history
            });
        });

        return (
            <div className='container--no-sidebar'>
                <div className="main-area">
                    <AdminHeader showHomeIcon={ true }/>
                    <div className='container main-content'>
                        {
                            childrenWithKey
                        }

                        <Toast/>
                    </div>
                </div>
            </div>
        );
    };
}

export default AdminLayout;
