import { actions } from "../../../core/constants";
import * as api from "../../_api";
import { exportFile, extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const generateQrCodes = (binIds, ignoreGenerated, listOnly) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_ADDRESSES__GENERATE_QRS__STARTED
    });

    return api
        .ekostraznik
        .qrCodes
        .generate(binIds, ignoreGenerated, listOnly)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__GENERATE_QRS__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__GENERATE_QRS__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getManyQrCodeDocuments = (queryParams) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_ADDRESSES__GET_QRS__STARTED
    });

    return api
        .ekostraznik
        .qrCodes
        .getMany(queryParams)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__GET_QRS__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__GET_QRS__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
}

export const getPackage = (qrCodeDocumentId) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_ADDRESSES__GET_QRS_PACKAGE__STARTED
    });

    return api
        .ekostraznik
        .qrCodes
        .getPackage(qrCodeDocumentId)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__GET_QRS_PACKAGE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });

            exportFile(response.data, 'naklejki.zip');
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__GET_QRS_PACKAGE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
}
