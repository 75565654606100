import webClient from "../../../core/web_client";
import { API_BASE_AKUN_EKOSTRAZNIK_PATH } from "../../../core/constants";

const PATH = `${ API_BASE_AKUN_EKOSTRAZNIK_PATH }/inspections`;

export const getInspection = (id) => {
    return webClient
        .get(`${ PATH }/${id}`);
};

export const updateInspectionStatus = (id, data) => {
    return webClient
        .patch(`${ PATH }/${id}`, data);
};

export const addInspection = (data) => {
    return webClient
        .post(`${ PATH }`, data);
};
