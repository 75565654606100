import React from "react";

import ShipmentDate from "./shimpment_date";
import MessageContent from "./message_content";
import { isEmptyArray } from "../../../core/utils/misc_utils";
import Attachments from "./attachments";
import MessageSendingStep2Map from "./map";
import ReceiverGroups from "./receiver_groups";
import RecipientAddresses from "./recipient_addresses";

const MessageSummary = ({
                            date,
                            time,
                            title,
                            body,
                            priority,
                            uploadedFiles,
                            useGeoLocation,
                            latitude,
                            longitude,
                            receiverGroupIds,
                            useLocationFilter,
                            locations
                        }) => (
    <>
        <ShipmentDate
            date={ date }
            time={ time }/>

        <MessageContent
            title={ title }
            body={ body }
            priority={ priority }/>

        {
            !isEmptyArray(uploadedFiles) &&
            <Attachments
                attachments={ uploadedFiles }/>
        }

        {
            useGeoLocation &&
            !!latitude &&
            !!longitude &&

            <MessageSendingStep2Map
                latitude={ latitude }
                longitude={ longitude }/>
        }

        {
            !isEmptyArray(receiverGroupIds) &&
            <ReceiverGroups
                receiverGroupIds={ receiverGroupIds }/>
        }

        {
            useLocationFilter &&
            !isEmptyArray(locations) &&

            <RecipientAddresses
                locations={ locations }/>
        }

    </>
);

export default MessageSummary;