import { isNullOrUndefined } from "./misc_utils";

export const stateToHTMLOptions = {
    blockRenderers: {
        INFO: block => `<div class="RichEditor-info">${ block.getText() }</div>`,
        WARNING: block => `<div class="RichEditor-warning">${ block.getText() }</div>`
    },
    entityStyleFn: entity => {
        const entityType = entity.get("type").toLowerCase();
        if (entityType === "image") {
            const data = entity.getData();
            return {
                element: "img",
                attributes: {
                    src: data.src,
                    alignment: data.alignment
                }
            };
        }
    }
};

export const stateFromHTMLOptions = {
    customBlockFn: (element => {

        if (!isNullOrUndefined(element) && !isNullOrUndefined(element.className)) {
            if (element.className === "RichEditor-info") {
                return {
                    type: "INFO"
                };
            }

            if (element.className === "RichEditor-warning") {
                return {
                    type: "WARNING"
                };
            }
        }
    })
};
