import React, { Component } from "react";
import { isNullOrUndefined } from "../../core/utils/misc_utils";

class ListPagination extends Component {

    state = {
        pageNumberInputValue: 0
    };

    render = () => {
        const { data, _onPrevClick, _onNextClick } = this.props;

        if (isNullOrUndefined(data)) {
            return;
        }

        return (
            <div className="sll__pagination-wrapper">
                <div className="sll__pagination-counter">
                    {
                        this.defineDisplayedListGroup(data)
                    }
                </div>
                <div>
                    <div className="sll__pagination">
                        {
                            !data.first &&
                            <span className="sll__pagination--prev" onClick={ _onPrevClick }/>
                        }
                        <div className="sll__pagination__info">
                            <input className="sll_pagination__page_number_input"
                                   type="number"
                                   value={ this.state.pageNumberInputValue }
                                   onChange={ this._onInputChange }
                                   onBlur={ this._onSubmitChange }
                                   aria-label="Wpisz stronę do której chcesz przejść"
                                   onKeyUp={ this._onKeySubmit }/>
                            <span>
                            z
                        </span>
                            <span>
                             {
                                 data.totalPages
                             }
                        </span>
                        </div>
                        {
                            !data.last &&
                            <span className="sll__pagination--next" onClick={ _onNextClick }/>
                        }
                    </div>
                </div>
            </div>
        );
    };

    componentDidMount = () => {
        this.setState({
            pageNumberInputValue: this.props.data.pageable.pageNumber + 1
        });
    };

    _onInputChange = (e) => {
        let value = e.target.value;

        this.setState({ pageNumberInputValue: value });
    };

    _onKeySubmit = (e) => {
        if (e.key === "Enter") {
            this._onSubmitChange(e);
        }
    };

    _onSubmitChange = (e) => {
        let value = e.target.value;

        if (value > this.props.data.totalPages) {
            this.props._onPageNumberInputValueChange(this.props.data.totalPages - 1);
            this.setState({ pageNumberInputValue: this.props.data.totalPages });
        }
        else if (value < 1) {
            this.props._onPageNumberInputValueChange(0);
            this.setState({ pageNumberInputValue: 1 });
        }
        else {
            this.props._onPageNumberInputValueChange(value - 1);
        }
    };

    shouldComponentUpdate = (nextProps, nextState) => {
        const { data } = this.props;

        if (nextProps.data !== data) {
            this.setState({
                pageNumberInputValue: nextProps.data.pageable.pageNumber + 1
            });
            return true;
        }

        if (nextState !== this.state) {
            return true;
        }

        return false;
    };

    defineDisplayedListGroup = (data) => {
        const pageNumber = data.pageable.pageNumber;
        const pageSize = data.pageable.pageSize;

        return (
            <>
                <span>
                    {
                        pageNumber * pageSize + 1
                    }
                    -
                    {
                        data.last ? data.totalElements :
                            pageNumber * pageSize + pageSize
                    }
                </span>
                <span>z</span>
                <span>
                    {
                        data.totalElements
                    }
                 </span>
            </>
        );
    };
}

export default ListPagination;
