import React, { Component } from "react";
import { connect } from "react-redux";

import { InspectionCompaniesEnum } from "../../../../../../core/enums/ekostraznik/inspection_companies";
import { ReactComponent as IconLockUnlocked } from "../../../../../../../resource/img/icons/icon_lock_unlocked.svg";
import { invoke, isNullOrUndefined } from "../../../../../../core/utils/misc_utils";
import { toastFor500, toastForSuccess } from "../../../../../../core/utils/toast_utils";
import { updateInspectionCompany } from "../../../../../../data/actions/ekostraznik/inspection_companies";
import { showToast } from "../../../../../../data/actions/common/ui/toast";

class ActivateCompanyButton extends Component {

    render = () => {

        return (
            <div>
                <p className="f-12">Kontroler dezaktywowany. Dostęp do aplikacji Ekostrażnik jest zablokowany. </p>
                <p className="tiles-item__activate-btn" onClick={ this._onActivateCompany }>
                    Aktywuj kontrolera
                    <IconLockUnlocked/>
                </p>
            </div>
        );
    };

    _onActivateCompany = () => {
        const { inspectionCompany, updateInspectionCompany, _onSubmitCallback, showToast, inspectionCompanyUpdate } = this.props;

        if (!isNullOrUndefined(inspectionCompany)) {
            return updateInspectionCompany(inspectionCompany.id, { command: InspectionCompaniesEnum.command.INSPECTION_COMPANY_ACTIVATE})
                .then(() => invoke(_onSubmitCallback))
                .then( () => {
                    toastFor500(showToast, inspectionCompanyUpdate, "Kontroler nie został aktywowany. Spróbuj ponownie");
                    toastForSuccess(showToast, inspectionCompanyUpdate, "Kontroler został aktywowany");
                });
        } else {
            invoke(_onSubmitCallback);
        }
    }
}

const mapStateToProps = (state) => {
    return {
        inspectionCompanyUpdate: state.entities.ekostraznik.inspectionCompanies.update
    };
};


export default connect(mapStateToProps, { updateInspectionCompany, showToast })(ActivateCompanyButton);

