import React, { Component } from "react";
import { ReactComponent as IconThreeBuildings } from "./icon_three_buildings.svg";
import HeaderUnderlined from "../../../../common/header_underlined";
import { connect } from "react-redux";
import CheckboxInput from "../../../../common/checkbox_input";
import { getManyBins } from "../../../../../data/actions/ekostraznik/bins";
import { showToast } from "../../../../../data/actions/common/ui/toast";
import EkostraznikQrCodesGenerationAddresses from "./qr_codes_generation__addresses";
import EkostraznikQrCodesGenerationForm from "./qr_codes_generation__form";

class EkostraznikQrCodesGeneration extends Component {
    state = {
        ignoreGenerated: false,
        selectedBins: {},
        validationError: false,
    };

    render() {
        const { bins } = this.props;

        return (
            <div className="container is-fluid">
                <div>
                    <EkostraznikQrCodesGenerationAddresses bins={ bins }/>
                    <section className="media mw-100">
                        <div className="media-left">
                            <div className="header-icon">
                                <IconThreeBuildings/>
                            </div>
                        </div>
                        <div className="media-content">
                            <HeaderUnderlined
                                text='Naklejki z kodami QR dla wszystkich adresów'
                            />
                            <div className='is-spaced--top-30'>
                                <EkostraznikQrCodesGenerationForm
                                    ignoreGenerated={ this.state.ignoreGenerated }
                                    bins={ bins }
                                    triggerComponent={
                                        <button
                                            className="btn btn--primary mw-100 generate-qrs-btn">
                                            Wygeneruj naklejki dla wszystkich adresów
                                        </button>
                                    }
                                />

                                <div className='is-spaced--top-20'>
                                    <CheckboxInput
                                        label='Pomiń adresy z wygenerowanymi wcześniej naklejkami'
                                        checked={ this.state.ignoreGenerated }
                                        _onInputChange={ () => this.setState({
                                            ignoreGenerated: !this.state.ignoreGenerated
                                        }) }
                                    />
                                </div>

                            </div>


                        </div>


                    </section>


                </div>
            </div>
        );
    }

    componentDidMount() {
        const { getManyBins } = this.props;
        getManyBins();
    }
}

const mapStateToProps = (state) => {
    return {
        bins: state.entities.ekostraznik.bins.getMany,
    };
};

export default connect(mapStateToProps, { getManyBins, showToast })(EkostraznikQrCodesGeneration);
