import _ from "lodash";

import * as api from "../../../_api";
import { actions } from "../../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../../core/utils/api_utils";

export const getManyReportMessages = (reportId) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__REPORT_MESSAGES__GET_MANY__STARTED
    });

    return api
        .waste_management
        .reportMessages
        .getManyReportMessages(reportId)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__REPORT_MESSAGES__GET_MANY__SUCCESS,
                meta: {},
                // TODO: different key (temporarily)
                // payload: _.mapKeys(extractResponseData(response), "id"),
                payload: _.mapKeys(extractResponseData(response), "createdDate"),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__REPORT_MESSAGES__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const addReportMessage = (reportId, data) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__REPORT_MESSAGES__ADD__STARTED
    });

    return api
        .waste_management
        .reportMessages
        .addReportMessage(reportId, data)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__REPORT_MESSAGES__ADD__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__REPORT_MESSAGES__ADD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};