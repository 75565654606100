import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { MISC_TINYMCE_API_KEY } from "../../core/constants";

const TinyEditor = ({ value, _onMessageContentChanged, changeStyle }) => {

    // TODO: height
    return (
        <div className={ changeStyle || 'text-box' }>
            <Editor
                apiKey={ MISC_TINYMCE_API_KEY }
                init={ {
                    toolbar: [
                        "bold italic underline bullist numlist charmap link removeformat",
                    ],
                    statusbar: false,
                    menubar: false,
                    plugins: "link lists charmap",
                    height: 140,
                    content_style: "p, ul, ol {color: #183970}",
                    contextmenu: ''
                } }
                initialValue={ value }
                content={ value }
                value={ value }
                onEditorChange={ value => _onMessageContentChanged(value) }
            />
        </div>
    );
};

export default TinyEditor;
