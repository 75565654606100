import { deepFreeze } from "../../utils/misc_utils";

export const AddressComponentsEnum = deepFreeze({
    types: {
        PREMISE: "premise",
        STREET_ADDRESS: "street_address",
        STREET_NUMBER: "street_number",
        ROUTE: "route",
        LOCALITY: "locality"
    }
});