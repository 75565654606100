import React, { Component } from "react";
import { connect } from "react-redux";
import TitleWithDivider from "../../../../../../common/title_with_divider";
import DefineAreaItem from "./define_area_item";
import { getManyAreas } from "../../../../../../../data/actions/waste_management/areas";
import Loader from "../../../../../../common/loader";
import _ from "lodash";
import { getManyBins } from "../../../../../../../data/actions/waste_management/bins";


class DefineAreas extends Component {

    state = {
        basicBins: []
    };

    render = () => {
        return (
            <section className="company__defineAreas">
                <TitleWithDivider>
                    Zakres odbioru odpadów na obszarze
                </TitleWithDivider>
                {
                    this.renderListItems()
                }
            </section>
        );
    };

    componentDidMount = () => {
        const { getManyAreas, getManyBins } = this.props;

        getManyAreas();
        getManyBins()
            .then(() => {
                const { binsList } = this.props;
                const selectedBasicBins = _.reject(binsList.data, ["type", null]);

                this.setState({
                    basicBins: selectedBasicBins
                });
            });
    };

    renderListItems = () => {
        const { areaList, binsList, _manageAssignedAreas, _addOrRemoveCheckboxClass } = this.props;
        const { basicBins } = this.state;
        const sortedAreaList = _.sortBy(areaList.data, [area => area.name.toLowerCase(), "name"]);

        if (areaList.meta.loading || binsList.meta.loading) {
            return <Loader/>;
        }

        return _.map(sortedAreaList, area => {
            return (
                <DefineAreaItem
                    key={ area.id }
                    area={ area }
                    basicBins={ basicBins }
                    _manageAssignedAreas={ _manageAssignedAreas }
                    _addOrRemoveCheckboxClass={ _addOrRemoveCheckboxClass }/>
            );
        });
    };

}

const mapStateToProps = (state) => {
    return {
        areaList: state.entities.wasteManagement.areas.getMany,
        binsList: state.entities.wasteManagement.bins.getMany
    };
};

export default connect(mapStateToProps, { getManyAreas, getManyBins })(DefineAreas);