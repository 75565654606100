import React from "react";
import { isNullOrUndefined } from '../../../core/utils/misc_utils';
import renderHTML from "react-render-html";

const MessageContent = ({ title, body, priority }) => {

    return (
        <>
            {
                priority &&
                <div className="notifications-2__row notifications-2__row--flex">
                    <p className="notifications-2__label">Typ wiadomości:</p>
                    <div className="notifications-2__box">
                        <p>
                            {
                                priority.description
                            }
                        </p>
                    </div>
                </div>
            }
            <div className="notifications-2__row notifications-2__row--flex">
                <p className="notifications-2__label">Tytuł:</p>
                <div className="notifications-2__box">
                    <p>
                        {
                            title
                        }
                    </p>
                </div>
            </div>

            <div className="notifications-2__row notifications-2__row--flex">
                <p className="notifications-2__label">Treść wiadomości:</p>
                <div className="notifications-2__box">
                    {
                        !isNullOrUndefined(body) && !!body
                            ? renderHTML(body)
                            : "-"
                    }
                </div>
            </div>
        </>
    );
};

export default MessageContent;
