import React from "react";
import FormModal from "../../../../../../common/modal/form_modal";
import { ReactComponent as IconAdd } from "../../../../../../../../resource/img/icons/icon_add_white.svg";
import AddCommunalDocumentFormContent from "./content";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { isEmptyObject } from "../../../../../../../core/utils/misc_utils";
import _ from "lodash";
import {
    addCommunalDocument,
    getManyCommunalDocuments
} from "../../../../../../../data/actions/waste_management/communal_documents";
import { showToast } from "../../../../../../../data/actions/common/ui/toast";
import { toastForCustomError, toastForCustomSuccess } from "../../../../../../../core/utils/toast_utils";
import { is4xx, is5xx } from "../../../../../../../core/utils/api_utils";
import { CommunalDocumentEnum } from "../../../../../../../core/enums/waste_management/communal_document";

const initialFormValues = {
    filename: "",
    file: {}
};

const AddCommunalDocumentForm = () => {
    const dispatch = useDispatch();
    const addStatus = useSelector(state => state.entities.wasteManagement.communalDocuments.add);

    const _onSubmit = (formikBag, hidden) => {
        const errors = validate(formikBag.values);
        if (!isEmptyObject(errors)) {
            formikBag.setErrors(errors);
            return Promise.reject()
        }

        return dispatch(addCommunalDocument({
            filename: formikBag.values.filename,
            file: formikBag.values.file,
            hidden
        }))
            .then(async response => {
                if (is4xx(response?.status)) {
                    return _handleErrorMessage(formikBag, response);
                }
                if (is5xx(response?.status)) {
                    toastForCustomError((...args) => dispatch(showToast(...args)), "Dodanie pliku nie powiodło się. Spróbuj ponownie");
                } else {
                    toastForCustomSuccess((...args) => dispatch(showToast(...args)), hidden ? "Dokument został dodany w wersji roboczej" : "Dokument został opublikowany");
                    dispatch(getManyCommunalDocuments());
                }
            })
    }

    const validate = (values) => _.pickBy({
        filename: !values.filename ? 'Pole wymagane' : undefined,
        file: isEmptyObject(values.file) ? 'Pole wymagane' : undefined
    })

    const _handleErrorMessage = (formikBag, response) => {
        const errors = {};

        if (response.data.message.startsWith(CommunalDocumentEnum.errorCode.INVALID_FILE_EXTENSION)) {
            errors.file = "Niepoprawny format pliku.";
        }
        if (response.data.message.startsWith(CommunalDocumentEnum.errorCode.FILE_TO_BIG)) {
            errors.file = "Za duży rozmiar pliku.";
        }
        if (response.data.message.startsWith(CommunalDocumentEnum.errorCode.FILENAME_ALREADY_EXISTS)) {
            errors.filename = "Podana nazwa pliku już istnieje.";
        }

        formikBag.setErrors(errors)
        return Promise.reject()
    }

    return (
        <Formik
            initialValues={ initialFormValues }
            render={ formikBag =>

                <FormModal
                    triggerComponent={
                        <button className="btn btn--big btn--primary">
                                <span className="btn__icon-wrapper--left">
                                    <span className='btn__icon'>
                                        <IconAdd/>
                                    </span>
                                    Dodaj nowy plik
                                </span>
                        </button>
                    }
                    isLoading={ addStatus?.meta?.loading }
                    headerText="Dodaj nowy plik"
                    saveOrAdditionalSaveOrCancelButtons={ true }
                    _actionFunction={ () => {
                        return _onSubmit(formikBag, true);
                    } }
                    _additionalActionFunction={ () => {
                        return _onSubmit(formikBag, false);
                    } }>

                    <AddCommunalDocumentFormContent
                        formikBag={ formikBag }
                        errors={ formikBag.errors }/>

                </FormModal>
            }/>
    )
}

export default AddCommunalDocumentForm;
