import React, { Component } from "react";

import MunicipalWasteMenuYearsItem from "./item";
import { isNullOrUndefined } from "../../../../../../../../core/utils/misc_utils";

class MunicipalWasteMenuYears extends Component {

    render = () => {
        const { selectedArea, years, selectedYear, _onYearSelect } = this.props;
        return (
            <div className='row__left scrollable schedule-year-wrapper'>

                {
                    !isNullOrUndefined(years.data) &&
                    !isNullOrUndefined(years.data[selectedArea]) &&
                    !years.meta.loading &&
                    years.data[selectedArea].map(year => {
                        return (
                            <MunicipalWasteMenuYearsItem
                                key={ year }
                                year={ year }
                                selectedYear={ selectedYear }
                                _onYearSelect={ _onYearSelect }/>
                        );
                    })
                }

            </div>
        );
    };

    componentDidMount = () => {
        this.setYear()
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setYear()
    };

    shouldComponentUpdate = (nextProps) => {
        const { selectedArea, years, selectedYear } = this.props;

        if (selectedArea !== nextProps.selectedArea ||
            years !== nextProps.years ||
            selectedYear !== nextProps.selectedYear) {
            return true;
        }

        return false;
    };

    setYear = () => {
        const { selectedArea, years, selectedYear, _onYearSelect } = this.props;

        if (isNullOrUndefined(selectedYear) &&
            !isNullOrUndefined(years.data) &&
            !isNullOrUndefined(years.data[selectedArea])) {

            const currentYear = new Date().getFullYear();

            for (let i = 0; i < years.data[selectedArea].length; i++) {
                if (years.data[selectedArea][i] === currentYear) {
                    _onYearSelect(years.data[selectedArea][i]);
                    return;
                }
            }

            _onYearSelect(years.data[selectedArea][0]);
        }
    }
}

export default MunicipalWasteMenuYears;