import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";
import { isEmptyArray, isNullOrUndefined } from "../../../core/utils/misc_utils";

export const signIn = (data) => dispatch => {
    dispatch({
        type: actions.common.API__AUTHENTICATION__SIGN_IN__STARTED
    });

    return api
        .common
        .authentication
        .signIn(data)
        .then(response => {
            const responseData = extractResponseData(response);

            const permissions =
                !isNullOrUndefined(responseData) && !isEmptyArray(responseData.moduleItems)
                    ? responseData.moduleItems.reduce((accumulator, current) => {
                        const index = `${ current.moduleCode }_${ current.itemCode }`
                        accumulator[index] = current;
                        return accumulator;
                    }, {})
                    : {};

            dispatch({
                type: actions.common.API__AUTHENTICATION__SIGN_IN__SUCCESS,
                meta: {},
                payload: {
                    ...responseData,
                    permissions,
                    items: undefined
                },
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__AUTHENTICATION__SIGN_IN__FAIL,
                meta: extractErrorData(error),
                payload: {},
                error: true
            });
        });
};

export const chooseOwner = (data) => dispatch => {
    dispatch({
        type: actions.common.API__AUTHENTICATION__CHOOSE_OWNER__STARTED
    });
    return api
        .common
        .authentication
        .chooseOwner(data)
        .then(response => {
            const responseData = extractResponseData(response);
            const permissions =
                !isNullOrUndefined(responseData) && !isEmptyArray(responseData.moduleItems)
                    ? responseData.moduleItems.reduce((accumulator, current) => {
                        const index = `${ current.moduleCode }_${ current.itemCode }`
                        accumulator[index] = current;
                        return accumulator;
                    }, {})
                    : {};

            dispatch({
                type: actions.common.API__AUTHENTICATION__CHOOSE_OWNER__SUCCESS,
                meta: {},
                payload: {
                    ...responseData,
                    permissions,
                    ownerId: data.ownerId,
                    ownerName: data.ownerName,
                    items: undefined
                },
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__AUTHENTICATION__CHOOSE_OWNER__FAIL,
                meta: extractErrorData(error),
                payload: {},
                error: true
            });
        });
};

export const signOutRemotely = () => dispatch => {
    api
        .common
        .authentication
        .signOut()
        .then(() => dispatch(signOutLocally(false)))
        .catch(() => dispatch(signOutLocally(false)));
};

export const signOutLocally = (isAutoSignOut = true) => dispatch => {
    dispatch(
        {
            type: actions.common.UI__AUTHENTICATION__SIGN_OUT,
            payload: {
                autoSignOut: isAutoSignOut
            }
        }
    );
};