import React, { useCallback, useEffect, useState } from "react";

import AddressGroupsListItem from "./item";
import ListPagination from "../../../../../common/pagination";
import withPagination from "../../../../../common/hoc/with_pagination";
import { useDispatch, useSelector } from "react-redux";
import { generatePaginationQueryValues } from "../../../../../../core/utils/api_utils";
import {
    deleteManyAddressGroups,
    getManyAddressGroups
} from "../../../../../../data/actions/ekostraznik/address_groups";
import FormModal from "../../../../../common/modal/form_modal";
import EmptyListPage from "../../../../../common/empty_list_page";
import { ReactComponent as IconDeleteWhite } from "../../../../../../../resource/img/icons/icon_delete--white-bin.svg";
import IconEmptyGroups from "../../../../../../../resource/img/icons/empty_list_icons/grupy_adresow.png";
import CheckboxInput from "../../../../../common/checkbox_input";

const defaultPaginationQueryValues = generatePaginationQueryValues(0, 30, ["name", "asc"]);

const AddressGroupsList = ({ searchBarTerm, _onPrevClick, _onNextClick, _onPageNumberInputValueChange, currentPage, setMaxPage }) => {
    const dispatch = useDispatch();
    const [checkedAddressGroups, setCheckedAddressGroups] = useState([]);
    const groups = useSelector(state => state.entities?.ekostraznik?.addressGroups?.getMany);
    const deleteStatus = useSelector(state => state.entities?.ekostraznik?.addressGroups?.deleteMany);

    const totalPages = groups?.data?.totalPages;
    const isAddressGroupsEmpty = !groups?.data;
    const allChecked = checkedAddressGroups.length === groups?.data?.content?.length;

    const _reloadTable = useCallback((searchBarTerm) => {
        dispatch(getManyAddressGroups({
            ...defaultPaginationQueryValues,
            page: currentPage,
            query: searchBarTerm
        })).then(() => {
                if (totalPages !== undefined) {
                    setMaxPage(totalPages);
                }

                setCheckedAddressGroups([]);
            }
        );
    }, [totalPages, currentPage, dispatch, setMaxPage]);

    useEffect(() => {
        _reloadTable(searchBarTerm);
        // eslint-disable-next-line
    }, [currentPage, searchBarTerm]);

    const _onAddressGroupCheckboxChange = (id) => {
        const addressGroupIndex = checkedAddressGroups.indexOf(id);

        if (addressGroupIndex >= 0) {
            const newCheckedAddressGroups = [...checkedAddressGroups];
            newCheckedAddressGroups.splice(addressGroupIndex, 1);

            setCheckedAddressGroups(newCheckedAddressGroups)
        } else {
            setCheckedAddressGroups(prevState => [...prevState, id])
        }
    };

    const _onAllAddressGroupCheckboxesChange = () => {
        if (allChecked) {
            setCheckedAddressGroups([]);
        } else {
            const addressGroupIds = groups?.data?.content
                .map(addressGroup => {
                    return addressGroup.id
                });
            setCheckedAddressGroups(addressGroupIds);
        }
    };

    const _onSelectedAddressGroupsDeleteClick = () => {
        return dispatch(deleteManyAddressGroups({
            ids: checkedAddressGroups
        })).then(_onDeleteOrUpdateSuccess);
    };

    const _onDeleteOrUpdateSuccess = () => {
        _reloadTable(searchBarTerm);
    };

    if (groups?.data?.content?.length === 0) {
        return (
            <EmptyListPage
                img={ IconEmptyGroups }
                imgAlt='Brak grup'
                text={ !searchBarTerm ? 'Dodaj nową grupę adresów' : 'Brak pasujących wyników' }
            />
        )
    }

    return (
        <>
            <section className="main-content--table__bottom container is-fluid">
                <div className='table-wrapper'>
                    <table className='table--single-line fixed'>
                        <thead>
                        <tr>
                            <th className='t-width-11'>
                                <CheckboxInput
                                    label="Nazwa grupy"
                                    _onInputChange={ _onAllAddressGroupCheckboxesChange }
                                    checked={ allChecked }/>
                            </th>
                        </tr>
                        </thead>
                        <tbody>

                        {
                            !isAddressGroupsEmpty &&
                            renderListItems(groups, checkedAddressGroups, _onDeleteOrUpdateSuccess, _onAddressGroupCheckboxChange)
                        }

                        </tbody>
                    </table>
                </div>

            </section>
            <section className="container is-fluid is-flex">
                <div className="sll__pagination-wrapper">
                    {
                        checkedAddressGroups.length > 0 &&
                        <FormModal
                            triggerComponent={
                                <div className="btn btn--danger btn--small">
                                        <span className="btn__icon-wrapper--left">
                                            <span className="btn__icon">
                                                <IconDeleteWhite/>
                                            </span>
                                            Usuń
                                        </span>
                                </div>
                            }
                            deleteOrCancelButtons={ true }
                            _actionFunction={ _onSelectedAddressGroupsDeleteClick }
                            isLoading={ deleteStatus?.meta?.loading }
                        >

                            <div className='c-modal__body modal-size-m modal--no-header'>
                                <p className="confirmation-alert">
                                    <strong>Czy na pewno chcesz
                                        usunąć { checkedAddressGroups?.length === 1 ? 'wybraną grupę' : "wybrane grupy" }?</strong>
                                    <br/><br/>
                                    <strong>Ważne!</strong> Adresy należące do grupy nie będą już połączone.
                                    Nie będzie można przywrócić grupy, ale historia grupy zostanie zachowana.
                                    Usunięcie wpłynie na dane wyświetlane w pozostałych zakładkach.
                                </p>
                            </div>

                        </FormModal>
                    }
                </div>
                <div className="is-flex justify-end pagination--list">
                    {
                        !isAddressGroupsEmpty &&
                        <ListPagination
                            data={ groups.data }
                            _onPrevClick={ _onPrevClick }
                            _onNextClick={ _onNextClick }
                            _onPageNumberInputValueChange={ _onPageNumberInputValueChange }
                        />
                    }
                </div>
            </section>
        </>

    );
};

const renderListItems = (groups, checkedAddressGroups, _onDeleteOrUpdateSuccess, _onAddressGroupCheckboxChange) => {

    return groups.data.content
        .map(group => {
            return (
                <AddressGroupsListItem
                    key={ group.id }
                    group={ group }
                    checkedAddressGroups={ checkedAddressGroups }
                    _onDeleteOrUpdateSuccess={ _onDeleteOrUpdateSuccess }
                    _onAddressGroupCheckboxChange={ _onAddressGroupCheckboxChange }/>
            );
        });
};

export default withPagination(AddressGroupsList);
