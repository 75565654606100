import React from 'react';

const RecipientAddresses = ({ locations }) => {
    return (
        <div className="notifications-2__row notifications-2__row--flex">
            <p className="notifications-2__label">Adresy odbiorców:</p>
            <div className="notifications-2__box notifications-2__box--list">
                <ul className="notifications-2__list">
                    {
                        locations?.map(location =>
                            <li key={ location.parent.id } className="notifications-2__list-item">
                                { location.name }
                            </li>
                        )
                    }
                </ul>
            </div>
        </div>
    )
};

export default RecipientAddresses;
