import React from "react";
import { Link } from "react-router-dom";
import { ROUTE_LOGS_USER_CONSENTS, ROUTE_LOGS_USER_SUBSCRIPTIONS } from "../../../../../core/constants";
import { UserRecordsEnum } from "../../../../../core/enums/common/logs";


const LogsHeader = ({ tab }) => {

    return (
        <div>
            <header className="header--basic">
                <h1 className="title is-1">
                    Raporty
                </h1>
            </header>

            <section className="">
                <div className="row row--options">
                    <div className='full-width'>
                        <div className="tabs">
                            <ul className="tabs-list">
                                <li className={ `tabs-item ${ tab === UserRecordsEnum.tab.USER_CONSENTS ? "is-active" : "" }` }>
                                    <Link className="tab-item" to={ ROUTE_LOGS_USER_CONSENTS }>Zgody użytkowników</Link>
                                </li>
                                <li className={ `tabs-item ${ tab === UserRecordsEnum.tab.USER_SUBSCRIPTIONS ? "is-active" : "" }` }>
                                    <Link className="tab-item" to={ ROUTE_LOGS_USER_SUBSCRIPTIONS }>Subskrypcje użytkowników</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default LogsHeader;
