import React from "react";

import ComponentWithDropdown from "../../../../../common/component_with_dropdown";
import InspectionCompaniesCreateAndEditModalForm from "./create_and_edit_modal_form";
import GenerateNewCode from "./generate_new_code_modal";
import DeactivateInspectionCompanyModalForm from "./deactivate_modal_form";
import ActivateCompanyButton from "./activate_company_button";
import { InspectionCompaniesEnum } from "../../../../../../core/enums/ekostraznik/inspection_companies";
import { ReactComponent as IconEllipsisVertical } from "../../../../../../../resource/img/icons/icon_ellipsis_vertical_gray.svg";

class InspectionCompaniesItem extends ComponentWithDropdown {

    render = () => {
        const { dropdownOpen = false } = this.state;
        const { inspectionCompany, _onSubmitCallback } = this.props;

        return (
            <div className={ `tiles-item tiles-item--with-info ${ inspectionCompany.status === InspectionCompaniesEnum.statuses.INACTIVE ? "tiles-item--deactivated" : "" }` }>
                <div className="tiles-item__content">
                    <div className="tiles-item__name tiles-item__name--2-lines">
                        {
                            inspectionCompany.name
                        }
                    </div>

                    {
                        inspectionCompany.status === InspectionCompaniesEnum.statuses.ACTIVE &&

                        <div>
                            <p className="f-12">Kod dostępu do aplikacji Ekostrażnik: </p>
                            <div className="code-box code-box--small">
                                {
                                    inspectionCompany.code
                                }
                            </div>
                        </div>
                    }

                    {
                        inspectionCompany.status === InspectionCompaniesEnum.statuses.INACTIVE &&

                        <ActivateCompanyButton inspectionCompany={ inspectionCompany }
                                               _onSubmitCallback={ _onSubmitCallback }/>
                    }

                </div>

                {
                    inspectionCompany.status === InspectionCompaniesEnum.statuses.ACTIVE &&

                    <div className={ `dropdown  ${ dropdownOpen ? "is-active" : "" }` }>
                        <div className="dropdown-trigger">
                            <button className="unstyled-btn icon--menu" title='Więcej' onClick={ this._onDropdownOpen }>
                                <IconEllipsisVertical/>
                            </button>
                        </div>
                        <div className="dropdown-menu drop-to-right">
                            <div className="dropdown-content">

                                <InspectionCompaniesCreateAndEditModalForm
                                    triggerComponent={ <span className="unstyled-btn dropdown-item">Zmień nazwę</span> }
                                    inspectionCompany={ inspectionCompany }
                                    _onSubmitCallback={ _onSubmitCallback }
                                    headerText="Zmień nazwę kontrolera"/>
                                <DeactivateInspectionCompanyModalForm
                                    triggerComponent={ <span className="unstyled-btn dropdown-item">Dezaktywuj kontrolera</span> }
                                    inspectionCompany={ inspectionCompany }
                                    saveButtonText={ "Dezaktywuj" }
                                    _onSubmitCallback={ _onSubmitCallback }/>
                                <GenerateNewCode
                                    triggerComponent={ <span className="unstyled-btn dropdown-item">Nadaj nowy kod</span> }
                                    inspectionCompany={ inspectionCompany }
                                    _onSubmitCallback={ _onSubmitCallback }/>

                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    };

}

export default InspectionCompaniesItem;


