import { actions } from "../../../core/constants";
import * as api from "../../_api";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const deleteAddressesFromGroup = (groupId, unitIds) => dispatch => {
    return executeUpdateAction(() => api
            .ekostraznik
            .addressGroupUnits
            .deleteAddresses(groupId, unitIds),
        dispatch
    )
}

export const addAddressesToGroup = (groupId, unitIds, reassignAddresses) => dispatch => {
    return executeUpdateAction(() => api
            .ekostraznik
            .addressGroupUnits
            .addAddresses(groupId, unitIds, reassignAddresses),
        dispatch
    )
}

export const addAddressesByRangeToGroup = (groupId, unitIdFrom, unitIdTo, reassignAddresses) => dispatch => {
    return executeUpdateAction(() => api
            .ekostraznik
            .addressGroupUnits
            .addAddressesByRange(groupId, unitIdFrom, unitIdTo, reassignAddresses),
        dispatch
    )
}

export const addApartmentAddressesToGroup = (groupId, unitIds, apartmentAddresses, addNonExisting) => dispatch => {
    return executeUpdateAction(() => api
            .ekostraznik
            .addressGroupUnits
            .addApartmentAddresses(groupId, unitIds, apartmentAddresses, addNonExisting),
        dispatch
    )
}

const executeUpdateAction = (action, dispatch) => {
    dispatch({
        type: actions.ekostraznik.API__EKO_ADDRESSES__ADDRESS_GROUPS__UPDATE_ADDRESSES__STARTED
    })

    return action()
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__ADDRESS_GROUPS__UPDATE_ADDRESSES__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__ADDRESS_GROUPS__UPDATE_ADDRESSES__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
            return error.response;
        });
}