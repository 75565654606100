import webClient from "../../../core/web_client";
import { API_BASE_AKUN_EKOSTRAZNIK_PATH } from "../../../core/constants";

const PATH = `${ API_BASE_AKUN_EKOSTRAZNIK_PATH }/qr-codes/locations`;

export const getAll = () => {
    return webClient.get(PATH);
}

export const addUnit = (unitId) => {
    return webClient
        .patch(PATH, {
            command: 'add-unit',
            unitId
        });
};

export const addUnitsRange = (unitIdFrom, unitIdTo) => {
    return webClient
        .patch(PATH, {
            command: 'add-address-range',
            fromId: unitIdFrom,
            toId: unitIdTo,
        });
};

export const addAddressGroup = (addressGroupId) => {
    return webClient
        .patch(PATH, {
            command: 'add-address-group',
            addressGroupId
        });
};

export const removeAddressGroup = (addressGroupId) => {
    return webClient
        .patch(PATH, {
            command: 'remove-address-group',
            addressGroupId
        });
};

export const removeUnit = (unitId) => {
    return webClient
        .patch(PATH, {
            command: 'remove-unit',
            unitId
        });
};
