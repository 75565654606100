import React from "react";

import { ReactComponent as IconSearch } from "../../../../../../../resource/img/icons/icon_search.svg";
import AreaCreateAndEditModalForm from "./create_and_edit_modal_form"
import { getManyAreasPaginated } from "../../../../../../data/actions/waste_management/areas";
import { generatePaginationQueryValues } from "../../../../../../core/utils/api_utils";
import { useDispatch } from "react-redux";

const AreasHeader = ({ _onSearchBarInputChange }) => {
    const dispatch = useDispatch();

    return (
        <section className='main-content--table__top'>
            <header className='page-header page-header--with-back'>
                <h1 className='title is-1'>
                    Obszary
                </h1>
            </header>

            <section className='container is-fluid'>
                <div className="row row--separated row--options">
                    <div className='row__left'>
                        <div className="input-field header__search with-icon--right">
                            <input
                                type='text'
                                placeholder='Szukaj obszaru lub adresu'
                                onChange={ event => _onSearchBarInputChange(event.target.value) }
                            />
                            <IconSearch className='input-icon'/>
                        </div>
                    </div>
                    <div className='row__right'>
                        <AreaCreateAndEditModalForm
                            _onSubmitCallback={ () => dispatch(getManyAreasPaginated({
                                ...generatePaginationQueryValues(0, 30, ["createdAt", "desc"])
                            })) }/>
                    </div>
                </div>
            </section>
        </section>
    )
};

export default AreasHeader;