const PREFIX = "ekostraznik.";

export const INSPECTION_COMPANIES__RESET = `INSPECTION_COMPANIES__RESET`;

export const API__INSPECTION_COMPANIES__ADD__STARTED = `${ PREFIX }API__INSPECTION_COMPANIES__ADD__STARTED`;
export const API__INSPECTION_COMPANIES__ADD__SUCCESS = `${ PREFIX }API__INSPECTION_COMPANIES__ADD__SUCCESS`;
export const API__INSPECTION_COMPANIES__ADD__FAIL = `${ PREFIX }API__INSPECTION_COMPANIES__ADD__FAIL`;

export const API__INSPECTION_COMPANIES__GET_MANY__STARTED = `${ PREFIX }API__INSPECTION_COMPANIES__GET_MANY__STARTED`;
export const API__INSPECTION_COMPANIES__GET_MANY__SUCCESS = `${ PREFIX }API__INSPECTION_COMPANIES__GET_MANY__SUCCESS`;
export const API__INSPECTION_COMPANIES__GET_MANY__FAIL = `${ PREFIX }API__INSPECTION_COMPANIES__GET_MANY__FAIL`;

export const API__INSPECTION_COMPANIES__RENAME__STARTED = `${ PREFIX }API__INSPECTION_COMPANIES__RENAME__STARTED`;
export const API__INSPECTION_COMPANIES__RENAME__SUCCESS = `${ PREFIX }API__INSPECTION_COMPANIES__RENAME__SUCCESS`;
export const API__INSPECTION_COMPANIES__RENAME__FAIL = `${ PREFIX }API__INSPECTION_COMPANIES__RENAME__FAIL`;

export const API__INSPECTION_COMPANIES__UPDATE__STARTED = `${ PREFIX }API__INSPECTION_COMPANIES__UPDATE__STARTED`;
export const API__INSPECTION_COMPANIES__UPDATE__SUCCESS = `${ PREFIX }API__INSPECTION_COMPANIES__UPDATE__SUCCESS`;
export const API__INSPECTION_COMPANIES__UPDATE__FAIL = `${ PREFIX }API__INSPECTION_COMPANIES__UPDATE__FAIL`;

export const API__INSPECTION_COMPANIES__GENERATE_NEW_CODE__STARTED = `${ PREFIX }API__INSPECTION_COMPANIES__GENERATE_NEW_CODE__STARTED`;
export const API__INSPECTION_COMPANIES__GENERATE_NEW_CODE__SUCCESS = `${ PREFIX }API__INSPECTION_COMPANIES__GENERATE_NEW_CODE__SUCCESS`;
export const API__INSPECTION_COMPANIES__GENERATE_NEW_CODE__FAIL = `${ PREFIX }API__INSPECTION_COMPANIES__GENERATE_NEW_CODE__FAIL`;

export const API__INSPECTIONS__GET_MANY__STARTED = `${ PREFIX }API__INSPECTIONS__GET_MANY__STARTED`;
export const API__INSPECTIONS__GET_MANY__SUCCESS = `${ PREFIX }API__INSPECTIONS__GET_MANY__SUCCESS`;
export const API__INSPECTIONS__GET_MANY__FAIL = `${ PREFIX }API__INSPECTIONS__GET_MANY__FAIL`;

export const API__DETAILED_GROUP_INSPECTION__GET__STARTED = `${ PREFIX }API__DETAILED_GROUP_INSPECTION__GET__STARTED`;
export const API__DETAILED_GROUP_INSPECTION__GET__SUCCESS = `${ PREFIX }API__DETAILED_GROUP_INSPECTION__GET__SUCCESS`;
export const API__DETAILED_GROUP_INSPECTION__GET__FAIL = `${ PREFIX }API__DETAILED_GROUP_INSPECTION__GET__FAIL`;

export const API__DETAILED_LOCATION_INSPECTION__GET__STARTED = `${ PREFIX }API__DETAILED_LOCATION_INSPECTION__GET__STARTED`;
export const API__DETAILED_LOCATION_INSPECTION__GET__SUCCESS = `${ PREFIX }API__DETAILED_LOCATION_INSPECTION__GET__SUCCESS`;
export const API__DETAILED_LOCATION_INSPECTION__GET__FAIL = `${ PREFIX }API__DETAILED_LOCATION_INSPECTION__GET__FAIL`;

export const API__INSPECTION__GET__STARTED = `${ PREFIX }API__INSPECTION__GET__STARTED`;
export const API__INSPECTION__GET__SUCCESS = `${ PREFIX }API__INSPECTION__GET__SUCCESS`;
export const API__INSPECTION__GET__FAIL = `${ PREFIX }API__INSPECTION__GET__FAIL`;

export const API__INSPECTION__UPDATE__STARTED = `${ PREFIX }API__INSPECTION__UPDATE__STARTED`;
export const API__INSPECTION__UPDATE__SUCCESS = `${ PREFIX }API__INSPECTION__UPDATE__SUCCESS`;
export const API__INSPECTION__UPDATE__FAIL = `${ PREFIX }API__INSPECTION__UPDATE__FAIL`;

export const API__INSPECTION__ADD__STARTED = `${ PREFIX }API__INSPECTION__ADD__STARTED`;
export const API__INSPECTION__ADD__SUCCESS = `${ PREFIX }API__INSPECTION__ADD__SUCCESS`;
export const API__INSPECTION__ADD__FAIL = `${ PREFIX }API__INSPECTION__ADD__FAIL`;

export const API__EKO_REPORTS__GET_MANY__STARTED = `${ PREFIX }API__EKO_REPORTS__GET_MANY__STARTED`;
export const API__EKO_REPORTS__GET_MANY__SUCCESS = `${ PREFIX }API__EKO_REPORTS__GET_MANY__SUCCESS`;
export const API__EKO_REPORTS__GET_MANY__FAIL = `${ PREFIX }API__EKO_REPORTS__GET_MANY__FAIL`;

export const API__EKO_REPORTS__GET__STARTED = `${ PREFIX }API__EKO_REPORTS__GET__STARTED`;
export const API__EKO_REPORTS__GET__SUCCESS = `${ PREFIX }API__EKO_REPORTS__GET__SUCCESS`;
export const API__EKO_REPORTS__GET__FAIL = `${ PREFIX }API__EKO_REPORTS__GET__FAIL`;

export const API__EKO_REPORTS__UPDATE__STARTED = `${ PREFIX }API__EKO_REPORTS__UPDATE__STARTED`;
export const API__EKO_REPORTS__UPDATE__SUCCESS = `${ PREFIX }API__EKO_REPORTS__UPDATE__SUCCESS`;
export const API__EKO_REPORTS__UPDATE__FAIL = `${ PREFIX }API__EKO_REPORTS__UPDATE__FAIL`;

export const API__EKO_REPORT_TYPES__GET__STARTED = `${ PREFIX }API__EKO_REPORT_TYPES__GET__STARTED`;
export const API__EKO_REPORT_TYPES__GET__SUCCESS = `${ PREFIX }API__EKO_REPORT_TYPES__GET__SUCCESS`;
export const API__EKO_REPORT_TYPES__GET__FAIL = `${ PREFIX }API__EKO_REPORT_TYPES__GET__FAIL`;

export const API__EKO_REPORTS__ADD__STARTED = `${ PREFIX }API__EKO_REPORTS__ADD__STARTED`;
export const API__EKO_REPORTS__ADD__SUCCESS = `${ PREFIX }API__EKO_REPORTS__ADD__SUCCESS`;
export const API__EKO_REPORTS__ADD__FAIL = `${ PREFIX }API__EKO_REPORTS__ADD__FAIL`;

export const API__EKO_QUANTITATIVE_REPORT__GET__STARTED = `${ PREFIX }API__EKO_QUANTITATIVE_REPORT__GET__STARTED`;
export const API__EKO_QUANTITATIVE_REPORT__GET__SUCCESS = `${ PREFIX }API__EKO_QUANTITATIVE_REPORT__GET__SUCCESS`;
export const API__EKO_QUANTITATIVE_REPORT__GET__FAIL = `${ PREFIX }API__EKO_QUANTITATIVE_REPORT__GET__FAIL`;

export const API__EKO_ADDRESSES__GENERATE_QRS__STARTED = `${ PREFIX }API__EKO_ADDRESSES__GENERATE_QRS__STARTED`;
export const API__EKO_ADDRESSES__GENERATE_QRS__SUCCESS = `${ PREFIX }API__EKO_ADDRESSES__GENERATE_QRS__SUCCESS`;
export const API__EKO_ADDRESSES__GENERATE_QRS__FAIL = `${ PREFIX }API__EKO_ADDRESSES__GENERATE_QRS__FAIL`;

export const API__EKO_ADDRESSES__GET_QRS__STARTED = `${ PREFIX }API__EKO_ADDRESSES__GET_QRS__STARTED`;
export const API__EKO_ADDRESSES__GET_QRS__SUCCESS = `${ PREFIX }API__EKO_ADDRESSES__GET_QRS__SUCCESS`;
export const API__EKO_ADDRESSES__GET_QRS__FAIL = `${ PREFIX }API__EKO_ADDRESSES__GET_QRS__FAIL`;

export const API__EKO_ADDRESSES__GET_QRS_PACKAGE__STARTED = `${ PREFIX }API__EKO_ADDRESSES__GET_QRS_PACKAGE__STARTED`;
export const API__EKO_ADDRESSES__GET_QRS_PACKAGE__SUCCESS = `${ PREFIX }API__EKO_ADDRESSES__GET_QRS_PACKAGE__SUCCESS`;
export const API__EKO_ADDRESSES__GET_QRS_PACKAGE__FAIL = `${ PREFIX }API__EKO_ADDRESSES__GET_QRS_PACKAGE__FAIL`;

export const API__EKO_ADDRESSES__GET_GROUPS__STARTED = `${ PREFIX }API__EKO_ADDRESSES__GET_GROUPS__STARTED`;
export const API__EKO_ADDRESSES__GET_GROUPS__SUCCESS = `${ PREFIX }API__EKO_ADDRESSES__GET_GROUPS__SUCCESS`;
export const API__EKO_ADDRESSES__GET_GROUPS__FAIL = `${ PREFIX }API__EKO_ADDRESSES__GET_GROUPS__FAIL`;

export const API__EKO_ADDRESSES_ADDRESS_GROUPS__GET_ADDRESSES___STARTED = `${ PREFIX }API__EKO_ADDRESSES_ADDRESS_GROUPS__GET_ADDRESSES___STARTED`;
export const API__EKO_ADDRESSES_ADDRESS_GROUPS__GET_ADDRESSES__SUCCESS = `${ PREFIX }API__EKO_ADDRESSES_ADDRESS_GROUPS__GET_ADDRESSES__SUCCESS`;
export const API__EKO_ADDRESSES_ADDRESS_GROUPS__GET_ADDRESSES__FAIL = `${ PREFIX }API__EKO_ADDRESSES_ADDRESS_GROUPS__GET_ADDRESSES__FAIL`;

export const API__EKO_ADDRESSES__GET_GROUPS_ALL__STARTED = `${ PREFIX }API__EKO_ADDRESSES__GET_GROUPS_ALL__STARTED`;
export const API__EKO_ADDRESSES__GET_GROUPS_ALL__SUCCESS = `${ PREFIX }API__EKO_ADDRESSES__GET_GROUPS_ALL__SUCCESS`;
export const API__EKO_ADDRESSES__GET_GROUPS_ALL__FAIL = `${ PREFIX }API__EKO_ADDRESSES__GET_GROUPS_ALL__FAIL`;

export const API__EKO_ADDRESSES__GET_GROUP__STARTED = `${ PREFIX }API__EKO_ADDRESSES__GET_GROUP__STARTED`;
export const API__EKO_ADDRESSES__GET_GROUP__SUCCESS = `${ PREFIX }API__EKO_ADDRESSES__GET_GROUP__SUCCESS`;
export const API__EKO_ADDRESSES__GET_GROUP__FAIL = `${ PREFIX }API__EKO_ADDRESSES__GET_GROUPS__FAIL`;

export const API__EKO_ADDRESSES__ADD_GROUP__STARTED = `${ PREFIX }API__EKO_ADDRESSES__ADD_GROUP__STARTED`;
export const API__EKO_ADDRESSES__ADD_GROUP__SUCCESS = `${ PREFIX }API__EKO_ADDRESSES__ADD_GROUP__SUCCESS`;
export const API__EKO_ADDRESSES__ADD_GROUP__FAIL = `${ PREFIX }API__EKO_ADDRESSES__ADD_GROUP__FAIL`;

export const API__EKO_ADDRESSES__UPDATE_GROUP__STARTED = `${ PREFIX }API__EKO_ADDRESSES__UPDATE_GROUP__STARTED`;
export const API__EKO_ADDRESSES__UPDATE_GROUP__SUCCESS = `${ PREFIX }API__EKO_ADDRESSES__UPDATE_GROUP__SUCCESS`;
export const API__EKO_ADDRESSES__UPDATE_GROUP__FAIL = `${ PREFIX }API__EKO_ADDRESSES__UPDATE_GROUP__FAIL`;

export const API__EKO_ADDRESSES__DELETE_GROUPS__STARTED = `${ PREFIX }API__EKO_ADDRESSES__DELETE_GROUPS__STARTED`;
export const API__EKO_ADDRESSES__DELETE_GROUPS__SUCCESS = `${ PREFIX }API__EKO_ADDRESSES__DELETE_GROUPS__SUCCESS`;
export const API__EKO_ADDRESSES__DELETE_GROUPS__FAIL = `${ PREFIX }API__EKO_ADDRESSES__DELETE_GROUPS__FAIL`;

export const API__EKO_ADDRESSES__ADDRESS_GROUPS__UPDATE_ADDRESSES__STARTED = `${ PREFIX }API__EKO_ADDRESSES__ADDRESS_GROUPS__UPDATE_ADDRESSES__STARTED`;
export const API__EKO_ADDRESSES__ADDRESS_GROUPS__UPDATE_ADDRESSES__SUCCESS = `${ PREFIX }API__EKO_ADDRESSES__ADDRESS_GROUPS__UPDATE_ADDRESSES__SUCCESS`;
export const API__EKO_ADDRESSES__ADDRESS_GROUPS__UPDATE_ADDRESSES__FAIL = `${ PREFIX }API__EKO_ADDRESSES__ADDRESS_GROUPS__UPDATE_ADDRESSES__FAIL`;

export const API__EKO_ADDRESSES__ADDRESS_GROUPS__ADD_NEST__STARTED = `${ PREFIX }API__EKO_ADDRESSES__ADDRESS_GROUPS__ADD_NEST__STARTED`;
export const API__EKO_ADDRESSES__ADDRESS_GROUPS__ADD_NEST__SUCCESS = `${ PREFIX }API__EKO_ADDRESSES__ADDRESS_GROUPS__ADD_NEST__SUCCESS`;
export const API__EKO_ADDRESSES__ADDRESS_GROUPS__ADD_NEST__FAIL = `${ PREFIX }API__EKO_ADDRESSES__ADDRESS_GROUPS__ADD_NEST__FAIL`;

export const API__EKO_ADDRESSES__ADDRESS_GROUPS__UPDATE_NEST__STARTED = `${ PREFIX }API__EKO_ADDRESSES__ADDRESS_GROUPS__UPDATE_NEST__STARTED`;
export const API__EKO_ADDRESSES__ADDRESS_GROUPS__UPDATE_NEST__SUCCESS = `${ PREFIX }API__EKO_ADDRESSES__ADDRESS_GROUPS__UPDATE_NEST__SUCCESS`;
export const API__EKO_ADDRESSES__ADDRESS_GROUPS__UPDATE_NEST__FAIL = `${ PREFIX }API__EKO_ADDRESSES__ADDRESS_GROUPS__UPDATE_NEST__FAIL`;

export const API__EKO_ADDRESSES__GET_APARTMENT_ADDRESSES__STARTED = `${ PREFIX }API__EKO_ADDRESSES__GET_APARTMENT_ADDRESSES__STARTED`;
export const API__EKO_ADDRESSES__GET_APARTMENT_ADDRESSES__SUCCESS = `${ PREFIX }API__EKO_ADDRESSES__GET_APARTMENT_ADDRESSES__SUCCESS`;
export const API__EKO_ADDRESSES__GET_APARTMENT_ADDRESSES__FAIL = `${ PREFIX }API__EKO_ADDRESSES__GET_APARTMENT_ADDRESSES__FAIL`;

export const API__EKO_ADDRESSES__DELETE_MANY_APARTMENT_ADDRESSES__STARTED = `${ PREFIX }API__EKO_ADDRESSES__DELETE_MANY_APARTMENT_ADDRESSES__STARTED`;
export const API__EKO_ADDRESSES__DELETE_MANY_APARTMENT_ADDRESSES__SUCCESS = `${ PREFIX }API__EKO_ADDRESSES__DELETE_MANY_APARTMENT_ADDRESSES__SUCCESS`;
export const API__EKO_ADDRESSES__DELETE_MANY_APARTMENT_ADDRESSES__FAIL = `${ PREFIX }API__EKO_ADDRESSES__DELETE_MANY_APARTMENT_ADDRESSES__FAIL`;

export const API__EKO_ADDRESSES__ADD_APARTMENT_ADDRESSES__STARTED = `${ PREFIX }API__EKO_ADDRESSES__ADD_APARTMENT_ADDRESSES__STARTED`;
export const API__EKO_ADDRESSES__ADD_APARTMENT_ADDRESSES__SUCCESS = `${ PREFIX }API__EKO_ADDRESSES__ADD_APARTMENT_ADDRESSES__SUCCESS`;
export const API__EKO_ADDRESSES__ADD_APARTMENT_ADDRESSES__FAIL = `${ PREFIX }API__EKO_ADDRESSES__ADD_APARTMENT_ADDRESSES__FAIL`;

export const API__EKO_ADDRESSES__ADDRESS_GROUPS__DELETE_NEST__STARTED = `${ PREFIX }API__EKO_ADDRESSES__ADDRESS_GROUPS__DELETE_NEST__STARTED`;
export const API__EKO_ADDRESSES__ADDRESS_GROUPS__DELETE_NEST__SUCCESS = `${ PREFIX }API__EKO_ADDRESSES__ADDRESS_GROUPS__DELETE_NEST__SUCCESS`;
export const API__EKO_ADDRESSES__ADDRESS_GROUPS__DELETE_NEST__FAIL = `${ PREFIX }API__EKO_ADDRESSES__ADDRESS_GROUPS__DELETE_NEST__FAIL`;

export const API__EKO_ADDRESSES__QRS__UPDATE_LIST__STARTED = `${ PREFIX }API__EKO_ADDRESSES__QRS__UPDATE_LIST__STARTED`;
export const API__EKO_ADDRESSES__QRS__UPDATE_LIST__SUCCESS = `${ PREFIX }API__EKO_ADDRESSES__QRS__UPDATE_LIST__SUCCESS`;
export const API__EKO_ADDRESSES__QRS__UPDATE_LIST__FAIL = `${ PREFIX }API__EKO_ADDRESSES__QRS__UPDATE_LIST__FAIL`;

export const API__EKO_ADDRESSES__QRS__GET_LIST__STARTED = `${ PREFIX }API__EKO_ADDRESSES__QRS__GET_LIST__STARTED`;
export const API__EKO_ADDRESSES__QRS__GET_LIST__SUCCESS = `${ PREFIX }API__EKO_ADDRESSES__QRS__GET_LIST__SUCCESS`;
export const API__EKO_ADDRESSES__QRS__GET_LIST__FAIL = `${ PREFIX }API__EKO_ADDRESSES__QRS__GET_LIST__FAIL`;

export const API__EKOSTRAZNIK_BINS__GET_MANY__STARTED = `${ PREFIX }API__EKOSTRAZNIK_BINS__GET_MANY__STARTED`;
export const API__EKOSTRAZNIK_BINS__GET_MANY__SUCCESS = `${ PREFIX }API__EKOSTRAZNIK_BINS__GET_MANY__SUCCESS`;
export const API__EKOSTRAZNIK_BINS__GET_MANY__FAIL = `${ PREFIX }API__EKOSTRAZNIK_BINS__GET_MANY__FAIL`;

export const API__INSPECTIONS_DOCUMENT__GENERATE__STARTED = `${ PREFIX }API__INSPECTIONS_DOCUMENT__GENERATE__STARTED`;
export const API__INSPECTIONS_DOCUMENT__GENERATE__SUCCESS = `${ PREFIX }API__INSPECTIONS_DOCUMENT__GENERATE__SUCCESS`;
export const API__INSPECTIONS_DOCUMENT__GENERATE__FAIL = `${ PREFIX }API__INSPECTIONS_DOCUMENT__GENERATE__FAIL`;

export const API__INSPECTION_COUNT_THRESHOLD__GET__STARTED = `${ PREFIX }API__INSPECTION_COUNT_THRESHOLD__GET__STARTED`;
export const API__INSPECTION_COUNT_THRESHOLD__GET__SUCCESS = `${ PREFIX }API__INSPECTION_COUNT_THRESHOLD__GET__SUCCESS`;
export const API__INSPECTION_COUNT_THRESHOLD__GET__FAIL = `${ PREFIX }API__INSPECTION_COUNT_THRESHOLD__GET__FAIL`;

export const API__INSPECTION_COUNT_THRESHOLD__UPDATE__STARTED = `${ PREFIX }API__INSPECTION_COUNT_THRESHOLD__UPDATE__STARTED`;
export const API__INSPECTION_COUNT_THRESHOLD__UPDATE__SUCCESS = `${ PREFIX }API__INSPECTION_COUNT_THRESHOLD__UPDATE__SUCCESS`;
export const API__INSPECTION_COUNT_THRESHOLD__UPDATE__FAIL = `${ PREFIX }API__INSPECTION_COUNT_THRESHOLD__UPDATE__FAIL`;

export const API__EKOSTRAZNIK_INVENTORIES__GET_MANY__STARTED = `${ PREFIX }API__EKOSTRAZNIK_INVENTORIES__GET_MANY__STARTED`;
export const API__EKOSTRAZNIK_INVENTORIES__GET_MANY__SUCCESS = `${ PREFIX }API__EKOSTRAZNIK_INVENTORIES__GET_MANY__SUCCESS`;
export const API__EKOSTRAZNIK_INVENTORIES__GET_MANY__FAIL = `${ PREFIX }API__EKOSTRAZNIK_INVENTORIES__GET_MANY__FAIL`;

export const API__EKOSTRAZNIK_INVENTORIES__ADD_INVENTORY__STARTED = `${ PREFIX }API__EKOSTRAZNIK_INVENTORIES__ADD_INVENTORY__STARTED`;
export const API__EKOSTRAZNIK_INVENTORIES__ADD_INVENTORY__SUCCESS = `${ PREFIX }API__EKOSTRAZNIK_INVENTORIES__ADD_INVENTORY__SUCCESS`;
export const API__EKOSTRAZNIK_INVENTORIES__ADD_INVENTORY__FAIL = `${ PREFIX }API__EKOSTRAZNIK_INVENTORIES__ADD_INVENTORY__FAIL`;

export const API__EKOSTRAZNIK_INVENTORIES__GET__STARTED = `${ PREFIX }API__EKOSTRAZNIK_INVENTORIES__GET__STARTED`;
export const API__EKOSTRAZNIK_INVENTORIES__GET__SUCCESS = `${ PREFIX }API__EKOSTRAZNIK_INVENTORIES__GET__SUCCESS`;
export const API__EKOSTRAZNIK_INVENTORIES__GET__FAIL = `${ PREFIX }API__EKOSTRAZNIK_INVENTORIES__GET__FAIL`;

export const API__EKOSTRAZNIK_INVENTORIES__DELETE_MANY__STARTED = `${ PREFIX }API__EKOSTRAZNIK_INVENTORIES__DELETE_MANY__STARTED`;
export const API__EKOSTRAZNIK_INVENTORIES__DELETE_MANY__SUCCESS = `${ PREFIX }API__EKOSTRAZNIK_INVENTORIES__DELETE_MANY__SUCCESS`;
export const API__EKOSTRAZNIK_INVENTORIES__DELETE_MANY__FAIL = `${ PREFIX }API__EKOSTRAZNIK_INVENTORIES__DELETE_MANY__FAIL`;

