import React from "react";

const SidebarItemWithSubmenu = ({ children, _onSidebarItemWithSubmenuSelected, sidebarItemName, sidebarItemIcon, sidebarItemId, activeSidebarItem, submenuExpanded }) => {
    return (
        <li className={ `sidebar__item  sidebar__item--has-children ${ activeSidebarItem === sidebarItemId ? "sidebar__item--active" : "" }` }
            onClick={ () => _onSidebarItemWithSubmenuSelected(sidebarItemId) }>

            <div className={ `is-v-centered sidebar__link sidebar__link--has-children ${ submenuExpanded ? "sidebar__link--has-children-expanded" : "" }` }>
                <img src={ sidebarItemIcon } className='sidebar__link-icon' alt=''/>
                <span className='sidebar__item-name'> { sidebarItemName } </span>
            </div>

            {
                activeSidebarItem === sidebarItemId &&
                submenuExpanded &&
                <ul className='sidebar__submenu'>
                    {
                        children
                    }
                </ul>
            }

        </li>
    );
};

export default SidebarItemWithSubmenu;

