import React from "react";

import FormModal from "../../../../../../common/modal/form_modal";
import { ReactComponent as IconAdd } from "../../../../../../../../resource/img/icons/icon_add_white.svg";
import { ReactComponent as IconEdit } from "../../../../../../../../resource/img/icons/icon_edit.svg";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { invoke, isEmptyObject, isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";
import _ from "lodash";
import { addAddressTemplate, updateAddressTemplate } from "../../../../../../../data/actions/blisko/address_templates";
import { is4xx, is5xx } from "../../../../../../../core/utils/api_utils";
import { toastForCustomError, toastForCustomSuccess } from "../../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../../data/actions/common/ui/toast";
import { AddressTemplatesEnum } from "../../../../../../../core/enums/blisko/address_templates";

const initialFormValues = {
    name: ""
};

const AddAndEditAddressTemplateForm = ({ _onSubmitCallback, addressTemplate }) => {
    const dispatch = useDispatch();
    const addressTemplateAdd = useSelector(state => state.entities?.blisko?.addressTemplates?.add);
    const addressTemplateUpdate = useSelector(state => state.entities?.blisko?.addressTemplates?.update);
    const initialValues = !isNullOrUndefined(addressTemplate) && !isNullOrUndefined(addressTemplate.name)
        ? { name: addressTemplate.name }
        : initialFormValues;

    const _handleErrorMessage = (formikBag, response, message) => {
        const errors = {};
        const nameAlreadyExistsCode = AddressTemplatesEnum.errorCode.NAME_ALREADY_EXISTS;

        if (response?.data?.message.startsWith(nameAlreadyExistsCode)) {
            errors.name = "Szablon adresów o tej nazwie już istnieje";
        }

        if (!isEmptyObject(errors)) {
            formikBag.setErrors(errors);
        } else {
            toastForCustomError((...args) => dispatch(showToast(...args)), message);
        }

        return Promise.reject(null);
    };

    const _validate = (values) => _.pickBy({
        name: !values.name ? "Pole wymagane" : undefined
    });

    const _onSubmit = (formikBag) => {
        const errors = _validate(formikBag.values);

        if (!isEmptyObject(errors)) {
            formikBag.setErrors(errors);
            return Promise.reject(null);
        }

        if (!isNullOrUndefined(addressTemplate)) {
            return dispatch(updateAddressTemplate(addressTemplate.id, { name: formikBag.values.name }))
                .then(async response => {
                    if (is4xx(response?.status) || is5xx(response?.status)) {
                        return _handleErrorMessage(formikBag, response, "Zmiana nazwy szablonu adresów nie powiodła się. Spróbuj ponownie")
                    } else {
                        toastForCustomSuccess((...args) => dispatch(showToast(...args)), "Nazwa szablonu adresów została zmieniona");
                        return invoke(_onSubmitCallback);
                    }
                })
        } else {
            return dispatch(addAddressTemplate({ name: formikBag.values.name }))
                .then(async response => {
                    if (is4xx(response?.status) || is5xx(response?.status)) {
                        return _handleErrorMessage(formikBag, response, "Dodanie szablonu adresów nie powiodło się. Spróbuj ponownie")
                    } else {
                        toastForCustomSuccess((...args) => dispatch(showToast(...args)), "Szablon adresów został pomyślnie dodany");
                        formikBag.resetForm();
                        return invoke(_onSubmitCallback);
                    }
                })
        }
    }

    return (
        <Formik
            initialValues={ initialValues }
            render={ formikBag => (
                <FormModal
                    triggerComponent={
                        addressTemplate
                            ? <button className='unstyled-btn' title='Edytuj'>
                                <IconEdit/>
                            </button>
                            : <button className='btn btn--primary btn--big'>
                                <IconAdd/>
                                <span className='is-spaced--left-10'>Dodaj szablon adresów</span>
                            </button>
                    }
                    headerText={
                        addressTemplate
                            ? "Zmień nazwę szablonu adresów"
                            : "Utwórz nazwę dla nowego szablonu adresów"
                    }
                    saveOrCancelButtons={ true }
                    saveButtonText={
                        addressTemplate
                            ? "Zmień"
                            : "Dodaj"
                    }
                    isLoading={
                        addressTemplate
                            ? addressTemplateUpdate.meta.loading
                            : addressTemplateAdd.meta.loading
                    }
                    submitDisabled={formikBag.values?.name?.length === 0}
                    _actionFunction={ () => _onSubmit(formikBag) }>

                    <Form>
                        <Field
                            name="name"
                            render={ ({ field }) => (
                                <div className='c-modal__body modal-size-m'>
                                    <label>
                                        <textarea
                                            className={ `${ formikBag.errors.name ? "rejected" : "" }` }
                                            cols="30" rows="10"
                                            { ...field }/>
                                        {
                                            formikBag.errors.name &&
                                            <span className="hint hint--rejected">{ formikBag.errors.name }</span>
                                        }
                                    </label>
                                </div>
                            ) }/>
                    </Form>
                </FormModal>
            ) }
        />
    )
};

export default AddAndEditAddressTemplateForm;