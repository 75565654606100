import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getManyInspectionCompanies } from "../../../../../../data/actions/ekostraznik/inspection_companies";
import Select from "../../../../../common/select";
import _ from "lodash";
import TitleWithDivider from "../../../../../common/title_with_divider";

const SelectInspectionCompaniesForm = ({ formikBag }) => {
    const dispatch = useDispatch();
    const inspectionCompanies = useSelector(state => state.entities?.ekostraznik?.inspectionCompanies?.getMany);

    useEffect(() => {
        dispatch(getManyInspectionCompanies())
    }, [dispatch])

    const generateCompanyOptions = (inspectionCompanies) => {

        if (!inspectionCompanies.data) {
            return [];
        }

        return _.map(inspectionCompanies.data, company => {
            return {
                label: company.name,
                value: company.id,
            };
        });
    };

    return (
        <label>
            <TitleWithDivider>
                <span className="required-accent">*</span>
                Kontroler
            </TitleWithDivider>
            <Select
                value={ formikBag.values.inspectionCompanyId ? undefined : null }
                aria-label= 'Kontroler'
                options={ generateCompanyOptions(inspectionCompanies) }
                isClearable={ false }
                onChange={ option => formikBag.setFieldValue("inspectionCompanyId", option.value) }
            />
        </label>
    );
}
export default SelectInspectionCompaniesForm;