import React, { Component } from "react";
import { connect } from "react-redux";
import { generateQrCodes, getManyQrCodeDocuments, getPackage } from "../../../../../data/actions/ekostraznik/qr_codes";
import withPagination from "../../../../common/hoc/with_pagination";
import { generatePaginationQueryValues } from "../../../../../core/utils/api_utils";
import { isEmptyArray, isNullOrUndefined } from "../../../../../core/utils/misc_utils";
import ListPagination from "../../../../common/pagination";
import moment from "moment";
import { MISC_DATE_FORMAT, MISC_DATE_ISO_FORMAT } from "../../../../../core/constants";
import EkostraznikQrCodesEnum from "../../../../../core/enums/ekostraznik/qr_codes";
import IndeterminateProgressBar from "../../../../common/indeterminate_progress_bar";
import { ReactComponent as IconInfo } from "../../../../../../resource/img/icons/icon_info.svg";
import { ReactComponent as IconNoQrs } from "./no-qrs.svg";
import Loader from "../../../../common/loader";
import FormModal from "../../../../common/modal/form_modal";
import BinsIcon from "../../waste_management/management/municipal_waste/menu/bins/icon";


class EkostraznikQrCodesDownload extends Component {
    defaultPaginationQueryValues = generatePaginationQueryValues(0, 10, ["executionDate", "desc"]);

    state = {
        qrCodeDocumentIdForDetails: undefined
    }

    render() {
        const {qrCodeDocuments} = this.props;
        const content = qrCodeDocuments && qrCodeDocuments.data && qrCodeDocuments.data.content;

        return (
            <div>
                <div className='is-spaced--bottom-40'>
                    <section className="container is-fluid">
                        Naklejki z kodami QR można pobrać przez 10 dni od daty wygenerowania.<br />
                        Czas generowania naklejek zależy od liczby wybranych adresów i może potrwać parę minut. <br />
                        Proces generowania naklejek będzie odbywał się nawet po wylogowaniu z panelu.
                    </section>
                </div>
                {
                    qrCodeDocuments.meta.loading &&  isEmptyArray(content) &&
                    <Loader />
                }
                {
                    !qrCodeDocuments.meta.loading && isEmptyArray(content) &&
                    this.renderEmpty()
                }
                {
                    !isEmptyArray(content) &&
                    this.renderTable()
                }
            </div>
        );
    }

    renderEmpty() {
        return (
            <div className='qr-code-documents-empty'>
                <div className='container is-fluid flex-center flex-column'>
                    <IconNoQrs />
                    <div>
                        Nie masz żadnych wygenerowanych<br />
                        naklejek do pobrania
                    </div>
                </div>
            </div>

        );
    }

    renderTable() {
        const {_onPrevClick, _onNextClick, _onPageNumberInputValueChange, qrCodeDocuments} = this.props;

        return (
            <div className="main-content--table qr-code-documents-list">

                <section className="main-content--table__bottom container is-fluid">
                    <div className='table-wrapper'>
                        <table className='table--single-line fixed'>
                            <thead>
                            <tr>
                                <th colSpan={3}>
                                    Naklejki z kodem QR
                                </th>
                            </tr>
                            </thead>
                            <tbody className="is-spaced--top-20">
                            {
                                qrCodeDocuments.data.content.map(qrCodeDocument => (
                                    <tr key={qrCodeDocument.id}>
                                        <td className='t-width-6 info-column'>

                                            <div className={'main-info--' + qrCodeDocument.status.toLowerCase()}>
                                                <span className='notification-dot'/>
                                                {
                                                    moment(qrCodeDocument.executionDate, MISC_DATE_ISO_FORMAT)
                                                        .format(MISC_DATE_FORMAT)
                                                }
                                                <span> - </span>
                                                Liczba naklejek: {qrCodeDocument.numberOfCodes}
                                            </div>
                                            <div className='status-info'>
                                                {
                                                    qrCodeDocument.status === EkostraznikQrCodesEnum.status.REMOVED &&
                                                    'Możliwość pobrania wygasła'
                                                }
                                                {
                                                    qrCodeDocument.status === EkostraznikQrCodesEnum.status.READY &&
                                                    'Do pobrania: ' + moment(qrCodeDocument.availabilityDate, MISC_DATE_ISO_FORMAT)
                                                        .format(MISC_DATE_FORMAT)
                                                }
                                            </div>
                                        </td>
                                        <td className='t-width-5 action-column'>
                                            {
                                                [EkostraznikQrCodesEnum.status.NEW, EkostraznikQrCodesEnum.status.PROCESSING].includes(qrCodeDocument.status) &&
                                                <div className='progress-bar'>
                                                    <IndeterminateProgressBar width='118px'/>
                                                </div>
                                            }

                                            {
                                                qrCodeDocument.status === EkostraznikQrCodesEnum.status.READY &&
                                                <button
                                                    className='btn btn--small btn--short btn--primary'
                                                    onClick={() => this.props.getPackage(qrCodeDocument.id)}
                                                >
                                                    Pobierz
                                                </button>

                                            }
                                        </td>
                                        <td className='t-width-1 details-column'>
                                            <FormModal
                                                triggerComponent={<IconInfo />}
                                                headerText={"Szczegóły wygenerowanych naklejek"}
                                                closeButton={true}
                                                _onOpenCallback={() => this.setState({qrDocumentIdForDetails: qrCodeDocument.id})}
                                            >
                                                <div className='c-modal__body modal-size-l'>
                                                    <div>
                                                        <div className="is-spaced--bottom-10">
                                                            <strong>Wybrane frakcje</strong>
                                                        </div>
                                                        {
                                                            qrCodeDocument.bins.map(bin => (
                                                                <div key={bin.id} className='is-spaced--bottom-10'>
                                                                    <span className="is-spaced--right-10">
                                                                        <BinsIcon color={ bin.color }/>
                                                                    </span>
                                                                    {bin.name}
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="is-spaced--top-20">
                                                        <div className="is-spaced--bottom-10">
                                                            <strong>Wybrane grupy lub adresy</strong>
                                                        </div>
                                                        {
                                                            qrCodeDocument.specifiedAddressPoints && qrCodeDocument.groupsAndAddresses.map(group => (
                                                                <div
                                                                    key={group}
                                                                    className='qr-code-documents-list__group-item'>
                                                                    {group}
                                                                </div>
                                                            ))
                                                        }
                                                        {
                                                            !qrCodeDocument.specifiedAddressPoints &&
                                                            <div
                                                                className='qr-code-documents-list__group-item'>
                                                                Wszystkie adresy i grupy
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </FormModal>

                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </section>

                <div className="container is-fluid">
                    <div className="is-flex justify-end pagination--list">

                        {

                            <ListPagination data={ qrCodeDocuments.data }
                                            _onPrevClick={ _onPrevClick }
                                            _onNextClick={ _onNextClick }
                                            _onPageNumberInputValueChange={ _onPageNumberInputValueChange }/>
                        }

                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        const { getManyQrCodeDocuments, currentPage, setMaxPage, qrCodeDocuments } = this.props;

        getManyQrCodeDocuments({ ...this.defaultPaginationQueryValues, page: currentPage})
            .then(() => {
                    if (!isNullOrUndefined(qrCodeDocuments.data)) {
                        setMaxPage(qrCodeDocuments.data.totalPages);
                    }
                }
            );
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        const { currentPage, getManyQrCodeDocuments } = this.props;

        if (nextProps.currentPage !== currentPage) {
            getManyQrCodeDocuments({ ...this.defaultPaginationQueryValues, page: nextProps.currentPage });
            return true;
        }

        if (nextState !== this.state) {
            return true;
        }

        if (nextProps !== this.props) {
            return true;
        }

        return false;
    };
}

const mapStateToProps = (state) => {
    return {
        qrCodesGenerate: state.entities.ekostraznik.qrCodes.generate,
        qrCodeDocuments: state.entities.ekostraznik.qrCodes.getMany,
    };
};

export default withPagination(connect(mapStateToProps, { generateQrCodes, getManyQrCodeDocuments, getPackage })(EkostraznikQrCodesDownload));
