import React from "react";

import SignedOutHeader from "../header";
import SignInForm from "./form";
import { useSelector } from 'react-redux';

const SignIn = () => {
    const isAutoSignOut = useSelector(state => state.authentication?.data?.autoSignOut)

    return (
        <>
            {
                isAutoSignOut &&
                <SignedOutHeader signedOutHeaderText="Zostałeś wylogowany przez brak aktywności. Zaloguj się ponownie."/>
            }

            {
                !isAutoSignOut &&
                <SignedOutHeader signedOutHeaderText="Logowanie"/>
            }
            <SignInForm/>
        </>
    );
}

export default SignIn;
