import webClient from "../../../core/web_client";
import { API_BASE_AKUN_ADMIN_PATH } from "../../../core/constants";

const PATH = `${ API_BASE_AKUN_ADMIN_PATH }/login`;
const PATH_LOGOUT = `${ API_BASE_AKUN_ADMIN_PATH }/logout`;
const PATH_RELOGIN = `${ API_BASE_AKUN_ADMIN_PATH }/relogin`;

export const signIn = (body) => {
    return webClient
        .post(PATH, body);
};

export const signOut = () => {
    return webClient
        .post(PATH_LOGOUT, '');
};

export const chooseOwner = (body) => {
    return webClient
        .post(PATH_RELOGIN, body);
};