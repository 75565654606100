import { isNullOrUndefined } from "./misc_utils";

export const passwordStrength = (password) => {
    if (isNullOrUndefined(password)) {
        return {
            hasValidLength: false,
            hasUppercaseLetter: false,
            hasLowercaseLetter: false,
            hasNumberOrSpecialCharacter: false
        };
    }

    return {
        hasValidLength: !!password.match(/^.{8,}$/),
        hasUppercaseLetter: !!password.match(/[A-Z]/),
        hasLowercaseLetter: !!password.match(/[a-z]/),
        hasNumberOrSpecialCharacter: !!(password.match(/\d/) || password.match(/[^A-Za-z0-9]/))
    };
};