const PREFIX = "common";

export const UI__SIDEBAR_VARIANT__SELECTED = `${ PREFIX }UI__SIDEBAR_VARIANT__SELECTED`;

export const UI__SIDEBAR_ITEM__SELECTED = `${ PREFIX }UI__SIDEBAR_ITEM__SELECTED`;
export const UI__SIDEBAR__RESET = `${ PREFIX }UI__SIDEBAR__RESET`;

export const UI__TOAST__SHOW = `${ PREFIX }UI__TOAST__SHOW`;
export const UI__TOAST__HIDE = `${ PREFIX }UI__TOAST__HIDE`;

export const UI__AUTHENTICATION__SIGN_OUT = `${ PREFIX }UI__AUTHENTICATION__SIGN_OUT`;

export const API__AUTHENTICATION__SIGN_IN__STARTED = `${ PREFIX }API__AUTHENTICATION__SIGN_IN__STARTED`;
export const API__AUTHENTICATION__SIGN_IN__SUCCESS = `${ PREFIX }API__AUTHENTICATION__SIGN_IN__SUCCESS`;
export const API__AUTHENTICATION__SIGN_IN__FAIL = `${ PREFIX }API__AUTHENTICATION__SIGN_IN__FAIL`;

export const API__AUTHENTICATION__CHOOSE_OWNER__STARTED = `${ PREFIX }API__AUTHENTICATION__CHOOSE_OWNER__STARTED`;
export const API__AUTHENTICATION__CHOOSE_OWNER__SUCCESS = `${ PREFIX }API__AUTHENTICATION__CHOOSE_OWNER__SUCCESS`;
export const API__AUTHENTICATION__CHOOSE_OWNER__FAIL = `${ PREFIX }API__AUTHENTICATION__CHOOSE_OWNER__FAIL`;

export const API__ACCOUNT__ACTIVATE__STARTED = `${ PREFIX }API__ACCOUNT__ACTIVATE__STARTED`;
export const API__ACCOUNT__ACTIVATE__SUCCESS = `${ PREFIX }API__ACCOUNT__ACTIVATE__SUCCESS`;
export const API__ACCOUNT__ACTIVATE__FAIL = `${ PREFIX }API__ACCOUNT__ACTIVATE__FAIL`;

export const API__ACCOUNT__GET__STARTED = `${ PREFIX }API__ACCOUNT__GET__STARTED`;
export const API__ACCOUNT__GET__SUCCESS = `${ PREFIX }API__ACCOUNT__GET__SUCCESS`;
export const API__ACCOUNT__GET__FAIL = `${ PREFIX }API__ACCOUNT__GET__FAIL`;

export const API__ACCOUNT__GET_ACTIVATION_STATUS__STARTED = `${ PREFIX }API__ACCOUNT__GET_ACTIVATION_STATUS__STARTED`;
export const API__ACCOUNT__GET_ACTIVATION_STATUS__SUCCESS = `${ PREFIX }API__ACCOUNT__GET_ACTIVATION_STATUS__SUCCESS`;
export const API__ACCOUNT__GET_ACTIVATION_STATUS__FAIL = `${ PREFIX }API__ACCOUNT__GET_ACTIVATION_STATUS__FAIL`;

export const API__EMPLOYEES__GET_MANY__STARTED = `${ PREFIX }API__EMPLOYEES__GET_MANY__STARTED`;
export const API__EMPLOYEES__GET_MANY__SUCCESS = `${ PREFIX }API__EMPLOYEES__GET_MANY__SUCCESS`;
export const API__EMPLOYEES__GET_MANY__FAIL = `${ PREFIX }API__EMPLOYEES__GET_MANY__FAIL`;

export const API__EMPLOYEES__GET__STARTED = `${ PREFIX }API__EMPLOYEES__GET__STARTED`;
export const API__EMPLOYEES__GET__SUCCESS = `${ PREFIX }API__EMPLOYEES__GET__SUCCESS`;
export const API__EMPLOYEES__GET__FAIL = `${ PREFIX }API__EMPLOYEES__GET__FAIL`;

export const API__EMPLOYEES__ADD__STARTED = `${ PREFIX }API__EMPLOYEES__ADD__STARTED`;
export const API__EMPLOYEES__ADD__SUCCESS = `${ PREFIX }API__EMPLOYEES__ADD__SUCCESS`;
export const API__EMPLOYEES__ADD__FAIL = `${ PREFIX }API__EMPLOYEES__ADD__FAIL`;

export const API__EMPLOYEES__REINVITE__STARTED = `${ PREFIX }API__EMPLOYEES__REINVITE__STARTED`;
export const API__EMPLOYEES__REINVITE__SUCCESS = `${ PREFIX }API__EMPLOYEES__REINVITE__SUCCESS`;
export const API__EMPLOYEES__REINVITE__FAIL = `${ PREFIX }API__EMPLOYEES__REINVITE__FAIL`;

export const API__EMPLOYEES__UPDATE__STARTED = `${ PREFIX }API__EMPLOYEES__UPDATE__STARTED`;
export const API__EMPLOYEES__UPDATE__SUCCESS = `${ PREFIX }API__EMPLOYEES__UPDATE__SUCCESS`;
export const API__EMPLOYEES__UPDATE__FAIL = `${ PREFIX }API__EMPLOYEES__UPDATE__FAIL`;

export const API__EMPLOYEES__PATCH_ACCOUNT_STATUS__STARTED = `${ PREFIX }API__EMPLOYEES__PATCH_ACCOUNT_STATUS__STARTED`;
export const API__EMPLOYEES__PATCH_ACCOUNT_STATUS__SUCCESS = `${ PREFIX }API__EMPLOYEES__PATCH_ACCOUNT_STATUS__SUCCESS`;
export const API__EMPLOYEES__PATCH_ACCOUNT_STATUS__FAIL = `${ PREFIX }API__EMPLOYEES__PATCH_ACCOUNT_STATUS__FAIL`;

export const API__EMPLOYEES__DELETE__STARTED = `${ PREFIX }API__EMPLOYEES__DELETE__STARTED`;
export const API__EMPLOYEES__DELETE__SUCCESS = `${ PREFIX }API__EMPLOYEES__DELETE__SUCCESS`;
export const API__EMPLOYEES__DELETE__FAIL = `${ PREFIX }API__EMPLOYEES__DELETE__FAIL`;

export const API__FILES__ADD__STARTED = `${ PREFIX }API__FILES__ADD__STARTED`;
export const API__FILES__ADD__SUCCESS = `${ PREFIX }API__FILES__ADD__SUCCESS`;
export const API__FILES__ADD__FAIL = `${ PREFIX }API__FILES__ADD__FAIL`;
export const API__FILES__ADD__RESET = `${ PREFIX }API__FILES__ADD__RESET`;
export const API__FILES__UPDATE__STARTED = `${ PREFIX }API__FILES__UPDATE__STARTED`;
export const API__FILES__UPDATE__SUCCESS = `${ PREFIX }API__FILES__UPDATE__SUCCESS`;
export const API__FILES__UPDATE__FAIL = `${ PREFIX }API__FILES__UPDATE__FAIL`;

export const API__GEOCODING__FORWARD__STARTED = `${ PREFIX }API__GEOCODING__FORWARD__STARTED`;
export const API__GEOCODING__FORWARD__SUCCESS = `${ PREFIX }API__GEOCODING__FORWARD__SUCCESS`;
export const API__GEOCODING__FORWARD__FAIL = `${ PREFIX }API__GEOCODING__FORWARD__FAIL`;

export const API__GEOCODING__REVERSE__STARTED = `${ PREFIX }API__GEOCODING__REVERSE__STARTED`;
export const API__GEOCODING__REVERSE__SUCCESS = `${ PREFIX }API__GEOCODING__REVERSE__SUCCESS`;
export const API__GEOCODING__REVERSE__FAIL = `${ PREFIX }API__GEOCODING__REVERSE__FAIL`;

export const API__GEOCODING__RESET = `${ PREFIX }API__GEOCODING__RESET`;

export const API__OWNER_COORDINATES__GET__STARTED = `${ PREFIX }API__OWNER_COORDINATES__GET__STARTED`;
export const API__OWNER_COORDINATES__GET__SUCCESS = `${ PREFIX }API__OWNER_COORDINATES__GET__SUCCESS`;
export const API__OWNER_COORDINATES__GET__FAIL = `${ PREFIX }API__OWNER_COORDINATES__GET__FAIL`;

export const API__OWNER_CONFIGURATION__GET__STARTED = `${ PREFIX }API__OWNER_CONFIGURATION__GET__STARTED`;
export const API__OWNER_CONFIGURATION__GET__SUCCESS = `${ PREFIX }API__OWNER_CONFIGURATION__GET__SUCCESS`;
export const API__OWNER_CONFIGURATION__GET__FAIL = `${ PREFIX }API__OWNER_CONFIGURATION__GET__FAIL`;

export const API__MODULES__GET__STARTED = `${ PREFIX }API__MODULES__GET__STARTED`;
export const API__MODULES__GET__SUCCESS = `${ PREFIX }API__MODULES__GET__SUCCESS`;
export const API__MODULES__GET__FAIL = `${ PREFIX }API__MODULES__GET__FAIL`;

export const API__PASSWORD_CHANGE__UPDATE__STARTED = `${ PREFIX }API__PASSWORD_CHANGE__UPDATE__STARTED`;
export const API__PASSWORD_CHANGE__UPDATE__SUCCESS = `${ PREFIX }API__PASSWORD_CHANGE__UPDATE__SUCCESS`;
export const API__PASSWORD_CHANGE__UPDATE__FAIL = `${ PREFIX }API__PASSWORD_CHANGE__UPDATE__FAIL`;
export const API__PASSWORD_CHANGE__RESET = `${ PREFIX }API__PASSWORD_CHANGE__RESET`;

export const API__ACCESSIBILITY_STATEMENT__UPDATE__STARTED = `${ PREFIX }API__ACCESSIBILITY_STATEMENT__UPDATE__STARTED`;
export const API__ACCESSIBILITY_STATEMENT__UPDATE__SUCCESS = `${ PREFIX }API__ACCESSIBILITY_STATEMENT__UPDATE__SUCCESS`;
export const API__ACCESSIBILITY_STATEMENT__UPDATE__FAIL = `${ PREFIX }API__ACCESSIBILITY_STATEMENT__UPDATE__FAIL`;

export const API__ACCESSIBILITY_STATEMENT__GET__STARTED = `${ PREFIX }API__ACCESSIBILITY_STATEMENT__GET__STARTED`;
export const API__ACCESSIBILITY_STATEMENT__GET__SUCCESS = `${ PREFIX }API__ACCESSIBILITY_STATEMENT__GET__SUCCESS`;
export const API__ACCESSIBILITY_STATEMENT__GET__FAIL = `${ PREFIX }API__ACCESSIBILITY_STATEMENT__GET__FAIL`;

export const API__PASSWORD_RESET_REQUEST__ADD__STARTED = `${ PREFIX }API__PASSWORD_RESET_REQUEST__ADD__STARTED`;
export const API__PASSWORD_RESET_REQUEST__ADD__SUCCESS = `${ PREFIX }API__PASSWORD_RESET_REQUEST__ADD__SUCCESS`;
export const API__PASSWORD_RESET_REQUEST__ADD__FAIL = `${ PREFIX }API__PASSWORD_RESET_REQUEST__ADD__FAIL`;

export const API__PASSWORD_RESET_REQUEST__TOKEN_CHECK__STARTED = `${ PREFIX }API__PASSWORD_RESET_REQUEST__TOKEN_CHECK__STARTED`;
export const API__PASSWORD_RESET_REQUEST__TOKEN_CHECK__SUCCESS = `${ PREFIX }API__PASSWORD_RESET_REQUEST__TOKEN_CHECK__SUCCESS`;
export const API__PASSWORD_RESET_REQUEST__TOKEN_CHECK__FAIL = `${ PREFIX }API__PASSWORD_RESET_REQUEST__TOKEN_CHECK__FAIL`;

export const API__PASSWORD_RESET_REQUEST__UPDATE__STARTED = `${ PREFIX }API__PASSWORD_RESET_REQUEST__UPDATE__STARTED`;
export const API__PASSWORD_RESET_REQUEST__UPDATE__SUCCESS = `${ PREFIX }API__PASSWORD_RESET_REQUEST__UPDATE__SUCCESS`;
export const API__PASSWORD_RESET_REQUEST__UPDATE__FAIL = `${ PREFIX }API__PASSWORD_RESET_REQUEST__UPDATE__FAIL`;

export const API__USER_CONSENTS__GET__STARTED = `${ PREFIX }API__USER_CONSENTS__GET__STARTED`;
export const API__USER_CONSENTS__GET__SUCCESS = `${ PREFIX }API__USER_CONSENTS__GET__SUCCESS`;
export const API__USER_CONSENTS__GET__FAIL = `${ PREFIX }API__USER_CONSENTS__GET__FAIL`;

export const API__USER_CONSENTS_EXPORT__GET__STARTED = `${ PREFIX }API__USER_CONSENTS_EXPORT__GET__STARTED`;
export const API__USER_CONSENTS_EXPORT__GET__SUCCESS = `${ PREFIX }API__USER_CONSENTS_EXPORT__GET__SUCCESS`;
export const API__USER_CONSENTS_EXPORT__GET__FAIL = `${ PREFIX }API__USER_CONSENTS_EXPORT__GET__FAIL`;

export const API__USER_SUBSCRIPTIONS__GET__STARTED = `${ PREFIX }API__USER_SUBSCRIPTIONS__GET__STARTED`;
export const API__USER_SUBSCRIPTIONS__GET__SUCCESS = `${ PREFIX }API__USER_SUBSCRIPTIONS__GET__SUCCESS`;
export const API__USER_SUBSCRIPTIONS__GET__FAIL = `${ PREFIX }API__USER_SUBSCRIPTIONS__GET__FAIL`;

export const API__USER_SUBSCRIPTIONS_EXPORT__GET__STARTED = `${ PREFIX }API__USER_SUBSCRIPTIONS_EXPORT__GET__STARTED`;
export const API__USER_SUBSCRIPTIONS_EXPORT__GET__SUCCESS = `${ PREFIX }API__USER_SUBSCRIPTIONS_EXPORT__GET__SUCCESS`;
export const API__USER_SUBSCRIPTIONS_EXPORT__GET__FAIL = `${ PREFIX }API__USER_SUBSCRIPTIONS_EXPORT__GET__FAIL`;

export const API__REGULATION_PLACES__GET__STARTED = `${ PREFIX }API__REGULATION_PLACES__GET__STARTED`;
export const API__REGULATION_PLACES__GET__SUCCESS = `${ PREFIX }API__REGULATION_PLACES__GET__SUCCESS`;
export const API__REGULATION_PLACES__GET__FAIL = `${ PREFIX }API__REGULATION_PLACES__GET__FAIL`;

export const API__REGULATIONS__GET__STARTED = `${ PREFIX }API__REGULATIONS__GET__STARTED`;
export const API__REGULATIONS__GET__SUCCESS = `${ PREFIX }API__REGULATIONS__GET__SUCCESS`;
export const API__REGULATIONS__GET__FAIL = `${ PREFIX }API__REGULATIONS__GET__FAIL`;

export const API__REGULATIONS__ADD__STARTED = `${ PREFIX }API__REGULATIONS__ADD__STARTED`;
export const API__REGULATIONS__ADD__SUCCESS = `${ PREFIX }API__REGULATIONS__ADD__SUCCESS`;
export const API__REGULATIONS__ADD__FAIL = `${ PREFIX }API__REGULATIONS__ADD__FAIL`;

export const API__REGULATIONS__DELETE__STARTED = `${ PREFIX }API__REGULATIONS__DELETE__STARTED`;
export const API__REGULATIONS__DELETE__SUCCESS = `${ PREFIX }API__REGULATIONS__DELETE__SUCCESS`;
export const API__REGULATIONS__DELETE__FAIL = `${ PREFIX }API__REGULATIONS__DELETE__FAIL`;

export const API__REGULATIONS__DOWNLOAD__STARTED = `${ PREFIX }API__REGULATIONS__DOWNLOAD__STARTED`;
export const API__REGULATIONS__DOWNLOAD__SUCCESS = `${ PREFIX }API__REGULATIONS__DOWNLOAD__SUCCESS`;
export const API__REGULATIONS__DOWNLOAD__FAIL = `${ PREFIX }API__REGULATIONS__DOWNLOAD__FAIL`;
