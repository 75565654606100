import get from "./get";
import add from "./add";
import update from "./update";
import getMany from "./get_many";
import getTypes from "./get_types";
import getQuantitativeReport from "./get_quantitative_report";

export default (state = {}, action) => {
    return {
        ...state,
        get: get(state.get, action),
        add: add(state.add, action),
        update: update(state.update, action),
        getMany: getMany(state.getMany, action),
        getTypes: getTypes(state.getTypes, action),
        getQuantitativeReport: getQuantitativeReport(state.getQuantitativeReport, action)
    };
}
