import React, { Component } from "react";
import _ from "lodash";

import { ReactComponent as IconArrowSwitchUp } from "../../../../../../../../resource/img/icons/list/icon_arrow_switch_up.svg";
import BulkyWasteCurrentListItem from "./item";
import { isEmptyArray, isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";
import { MISC_SHOW_UNFINISHED_ELEMENTS } from "../../../../../../../core/constants";

class BulkyWasteListCurrent extends Component {

    render = () => {
        const { newBulkyWaste, publishedBulkyWaste, meta, error } = this.props;

        return (
            <section className="main-content--table__bottom container is-fluid">
                <div className='table-wrapper'>
                    <table className='table--single-line fixed'>
                        <thead>
                        <tr>
                            <th className='t-width-3'>

                                {
                                    MISC_SHOW_UNFINISHED_ELEMENTS &&
                                    <div className='t-checkbox'>
                                        <label className='acceptance-label'>
                                            <input type='checkbox' className='acceptance-checkbox'/>
                                        </label>
                                    </div>
                                }

                                Lokalizacja

                                <div className='th-action-btn'>
                                    <IconArrowSwitchUp/>
                                </div>
                            </th>
                            <th className='t-width-4'>Data podstawienia</th>
                            <th className='t-width-4'>Dodatkowy opis</th>
                        </tr>
                        </thead>

                        {
                            this.renderNewBulkyWasteItems(newBulkyWaste, meta, error)
                        }

                        {
                            this.renderPublishedBulkyWasteItems(publishedBulkyWaste, meta, error)
                        }

                    </table>
                </div>
            </section>
        );
    };

    renderNewBulkyWasteItems = (newBulkyWaste, meta, error) => {
        if (isNullOrUndefined(newBulkyWaste) || meta.loading || error) {
            return null;
        }

        const newBulkyWasteToRender = _.map(newBulkyWaste, bulkyWasteItem => {
            return (
                <BulkyWasteCurrentListItem
                    key={ bulkyWasteItem.id }
                    bulkyWasteItem={ bulkyWasteItem }/>
            );
        });

        if (isEmptyArray(newBulkyWasteToRender)) {
            return null;
        }

        return (
            <tbody className="prepared-for-publication">
            {
                newBulkyWasteToRender
            }
            </tbody>
        );
    };

    renderPublishedBulkyWasteItems = (publishedBulkyWaste, meta, error) => {
        if (isNullOrUndefined(publishedBulkyWaste) || meta.loading || error) {
            return null;
        }

        const publishedBulkyWasteToRender = _
            .map(publishedBulkyWaste, bulkyWasteItem => {
                return (
                    <BulkyWasteCurrentListItem
                        key={ bulkyWasteItem.id }
                        bulkyWasteItem={ bulkyWasteItem }/>
                );
            });

        if (isEmptyArray(publishedBulkyWasteToRender)) {
            return null;
        }

        return (
            <tbody>
            {
                publishedBulkyWasteToRender
            }
            </tbody>
        );
    };
}

export default BulkyWasteListCurrent;