import { deepFreeze } from "../../utils/misc_utils";

export const BulkyWasteEnum = deepFreeze({
    status: {
        NEW: "NEW",
        VISIBLE_ON_MAP: "VISIBLE_ON_MAP",
        PUBLISHED: "PUBLISHED",
        ARCHIVED: "ARCHIVED"
    },
    tab: {
        CURRENT: "CURRENT",
        ARCHIVED: "ARCHIVED"
    }
});