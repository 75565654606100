import React, { Component } from "react";
import { connect } from "react-redux";

import FormModal from "../../../../../common/modal/form_modal";
import { invoke, isNullOrUndefined } from "../../../../../../core/utils/misc_utils";
import { InspectionCompaniesEnum } from "../../../../../../core/enums/ekostraznik/inspection_companies";
import { generateNewInspectionCompanyCode } from "../../../../../../data/actions/ekostraznik/inspection_companies";
import { showToast } from "../../../../../../data/actions/common/ui/toast";

class GenerateNewCode extends Component {

    state = {
        currentStep: 1
    };

    render = () => {
        const { triggerComponent, inspectionCompany, inspectionCompanyGenerateNewCode } = this.props;
        const { currentStep } = this.state;

        return (
            <>
                {
                    currentStep === 1 &&

                    <FormModal
                        triggerComponent={ triggerComponent }
                        multiStepSaveOrCancelButtons={ true }
                        saveButtonText={ "Nadaj" }
                        _actionFunctionMultistep={ () => {
                            return this._onSubmit();
                        } }>

                        <div className='c-modal__body modal-size-m modal--no-header'>
                            <p className="confirmation-alert">
                                Czy na pewno chcesz nadać kontrolerowi { inspectionCompany.name } nowy kod dostępu do aplikacji Ekostrażnik ?
                            </p>
                            <br/>
                            <p>
                                Wiąże się to z koniecznością przekazania nowego kodu kontrolerowi.
                            </p>
                        </div>
                    </FormModal>
                }
                {
                    currentStep === 2 &&

                    <FormModal
                        triggerComponent={ triggerComponent }
                        headerText="Nadano nowy kod dostępu"
                        okButton={ true }
                        defaultOpen={ true }
                        _cancelFunction={ () => {
                            return this._onSubmit();
                        } }>

                        <div className='c-modal__body modal-size-m'>
                            <p>
                                <span className="is-bold"> Ważne! </span> Przekaż kontrolerowi { inspectionCompany.name } nowy kod dostępu do aplikacji Ekostrażnik.
                            </p>
                            <div className="code-box code-box--large">
                                {
                                    !isNullOrUndefined(inspectionCompanyGenerateNewCode.data) &&
                                    inspectionCompanyGenerateNewCode.data.code
                                }
                            </div>
                        </div>
                    </FormModal>
                }
            </>
        );
    };

    _onSubmit = () => {
        const { inspectionCompany, generateNewInspectionCompanyCode, _onSubmitCallback } = this.props;
        const { currentStep } = this.state;

        if (currentStep === 1) {

            this.setState({
                currentStep: 2
            });

            if (!isNullOrUndefined(inspectionCompany)) {
                return generateNewInspectionCompanyCode(inspectionCompany.id, { command: InspectionCompaniesEnum.command.INSPECTION_COMPANY_GENERATE_NEW_CODE})
            } else {
                invoke(_onSubmitCallback);
            }
        }
        else if (currentStep === 2) {

            this.setState({
                currentStep: 1
            }, () => {
                invoke(_onSubmitCallback)
                })
        }

        return Promise.resolve();
    };
}

const mapStateToProps = (state) => {
    return {
        inspectionCompanyGenerateNewCode: state.entities.ekostraznik.inspectionCompanies.generateNewCode
    };
};

export default connect(mapStateToProps, { generateNewInspectionCompanyCode, showToast })(GenerateNewCode);


