import React from "react";
import { Field } from "formik";

const SubcategoryCreateOrEditModalFormContent = () => {

    return (
        <div className='c-modal__body modal-size-m'>
            <Field name="name"
                   render={ ({ field }) => (
                       <label>
                           <span className="is-sr-only">Nazwa podkategorii</span>
                           <input placeholder="np. Ubezpieczenie społeczne"
                                  { ...field }/>
                       </label>
                   ) }/>
        </div>
    );
};

export default SubcategoryCreateOrEditModalFormContent;