import React, { Component } from "react";
import { connect } from "react-redux";

import Header from "../../../../../common/header";
import { ROUTE_EKOSTRAZNIK_INSPECTION_HISTORY } from "../../../../../../core/constants";
import InspectionDetailsOptions from "../common/options";
import InspectionDetailsMonth from "../common/month-table";
import { getDetailedGroupInspection } from "../../../../../../data/actions/ekostraznik/inspection_history";
import { isLoading, isNullOrUndefined } from "../../../../../../core/utils/misc_utils";
import moment from "moment/moment";
import Loader from "../../../../../common/loader";

class GroupInspectionDetails extends Component {

    state = {
        selectedFilter: { value: "all", label: "Wszystko" },
        selectedYear: { value: new Date().getFullYear(), label: new Date().getFullYear() }
    };

    render = () => {
        const { selectedFilter, selectedYear } = this.state;
        const { inspectionHistoryGet, match } = this.props;
        const groupId = match.params.inspectionGroupId;

        if (isLoading(inspectionHistoryGet)) {
            return (
                <Loader/>
            );
        }

        const groupedInspections = !isNullOrUndefined(inspectionHistoryGet.data) && !isNullOrUndefined(inspectionHistoryGet.data.inspections) ?
            inspectionHistoryGet.data.inspections.reduce((accumulator, current) => {

                const date = current.createdDate;
                const month = moment(date)
                    .format("MMMM");

                if (isNullOrUndefined(accumulator[month])) {
                    accumulator[month] = [];
                }

                accumulator[month].push(current);

                return accumulator;

            }, {})
            : "";

        return (
            <div>
                <Header goBackLink={ ROUTE_EKOSTRAZNIK_INSPECTION_HISTORY }>
                    {
                        this.renderTitle()
                    }
                </Header>
                {
                    !isNullOrUndefined(groupedInspections) &&
                    <div className="content--m content--not-centered no-padding--top">
                        <InspectionDetailsOptions
                            inspections={ groupedInspections }
                            selectedFilter={ selectedFilter }
                            selectedYear={ selectedYear }
                            _onFilterSelect={ this._onFilterSelect }
                            _onYearOptionSelect={ this._onYearOptionSelect }/>

                        {
                            this.renderMonths(groupedInspections, groupId)

                        }
                    </div>
                }
            </div>
        );
    };

    componentDidMount = () => {
        const { getDetailedGroupInspection, match } = this.props;
        const groupId = match.params.inspectionGroupId;

        getDetailedGroupInspection(groupId);
    };

    _onFilterSelect = (selectedFilterValue) => {
        const { getDetailedGroupInspection, match } = this.props;
        const groupId = match.params.inspectionGroupId;

        this.setState({
            selectedFilter: selectedFilterValue
        }, () => {
            const { selectedFilter, selectedYear } = this.state;

            getDetailedGroupInspection(groupId, { year: selectedYear.value, show: selectedFilter.value });
        });
    };

    _onYearOptionSelect = (selectedYearOption) => {
        const { getDetailedGroupInspection, match } = this.props;
        const groupId = match.params.inspectionGroupId;

        this.setState({
            selectedYear: selectedYearOption
        }, () => {
            const { selectedFilter, selectedYear } = this.state;

            getDetailedGroupInspection(groupId, { year: selectedYear.value, show: selectedFilter.value });
        });

    };

    renderTitle = () => {
        const { inspectionHistoryGet } = this.props;

        if (!isNullOrUndefined(inspectionHistoryGet.data))
        {
            return inspectionHistoryGet.data.group.name;
        }
    };

    renderMonths = (groupedInspections, groupId) => {
        const { selectedYear } = this.state;

        return Object.entries(groupedInspections)
                     .map(inspection => {

                         return <InspectionDetailsMonth
                                    selectedYear={ selectedYear.value }
                                    month={ inspection[0] }
                                    inspections={ inspection[1] }
                                    key={ inspection[0] }
                                    parentType={ "grupa" }
                                    parentId={ groupId }/>;

                     });
    };

}

const mapStateToProps = (state) => {
    return {
        inspectionHistoryGet: state.entities.ekostraznik.inspectionHistory.getGroup
    };
};

export default connect(mapStateToProps, { getDetailedGroupInspection })(GroupInspectionDetails);
