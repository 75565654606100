import React, { Component } from "react";
import { connect } from "react-redux";

import WasteCollectionPointsHeader from "./header";
import WasteCollectionPointsList from "./list";
import { isNullOrUndefined } from "../../../../../../core/utils/misc_utils";

class WasteCollectionPoints extends Component {

    state = {
        searchBarTerm: ""
    };

    render = () => {
        const { wasteCollectionPoints } = this.props;
        const { searchBarTerm } = this.state;

        return (
            <div className="main-content--table">
                <WasteCollectionPointsHeader
                    wasteCollectionPoints={ wasteCollectionPoints }
                    searchBarTerm={ searchBarTerm }
                    _onSearchBarInputChange={ this._onSearchBarInputChange }/>

                <WasteCollectionPointsList
                    wasteCollectionPoints={ wasteCollectionPoints }
                    searchBarTerm={ searchBarTerm }/>
            </div>
        );
    };

    _onSearchBarInputChange = (input) => {
        if (!isNullOrUndefined(input)) {
            this.setState({
                searchBarTerm: input
            });
        }
    };
}

const mapStateToProps = (state) => {
    return {
        wasteCollectionPoints: state.entities.wasteManagement.wasteCollectionPoints.getMany
    };
};

export default connect(mapStateToProps)(WasteCollectionPoints);