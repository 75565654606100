import React from "react";
import AccountsList from "./list";

const Accounts = () => {

    return (
        <div className="container is-fluid">
            <section className="content--s">
                <header className="header--basic">
                    <h1 className="title is-1">Konta administracyjne</h1>
                </header>
                <AccountsList/>
            </section>
        </div>
    );
};

export default Accounts;
