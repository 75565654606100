import React, { Component } from "react";

import { FileUpload, UploadedFile } from "../../../../../../common/file_upload";
import { isEmptyArray } from "../../../../../../../core/utils/misc_utils";
import { EkostraznikReportsEnum } from "../../../../../../../core/enums/ekostraznik/reports";


class EkostraznikReportsFiles extends Component {

    render = () => {

        const { errors, selectedType } = this.props;

        return (
            <div>
                {
                    selectedType.value === EkostraznikReportsEnum.type.OTHER.code.toLowerCase() ?
                        <span className='label-name'>Zdjęcia </span>
                    :
                        <span className='label-name'>
                            <span className="required-accent">*</span>
                             Dodaj minimum 1 zdjęcie
                        </span>
                }

                {
                    this.renderFiles()
                }

                <div className="message-sending-1__dropzone">
                    <FileUpload
                        _onStart={ this._onFileUploadStarted }
                        acceptedFileExtensions={['png', 'jpg', 'jpeg']}
                        maxSingleFileSizeInMB={ 15 }
                    />
                    {
                        errors.images &&
                        <span className="hint hint--rejected is-bold">{ errors.images }</span>
                    }
                </div>
            </div>
        );
    };

    renderFiles = () => {
        const { images } = this.props.formikBag.values;

        if (!isEmptyArray(images)) {
            return images.map(image => {
                return (
                    <UploadedFile
                        key={ image.name }
                        id={ image.name }
                        name={ image.name }
                        alternativeTextRequired={ false }
                        isLoading={ false }
                        _onDeleteUploadedFileClick={ this._onDeleteUploadedFileClick }/>
                );
            });
        }
    };

    _onFileUploadStarted = (fileArray) => {
        const { formikBag } = this.props;

        const newImages = formikBag.values.images;
        fileArray.forEach(file => newImages.push(file));

        formikBag.setFieldValue("images", newImages);

    };

    _onDeleteUploadedFileClick = (name) => {
        const { formikBag } = this.props;
        const { images } = formikBag.values;

        if (!name || isEmptyArray(images)) {
            return;
        }

        let i = 0;
        for (; i < images.length; i++) {
            if (images[i].name === name) {
                break;
            }
        }

        const newFiles = images;
        newFiles.splice(i, 1);

        formikBag.setFieldValue("images", newFiles);

    };
}


export default EkostraznikReportsFiles;
