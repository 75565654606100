import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, Form, Formik } from "formik";

import { signIn } from "../../../data/actions/common/authentication";
import { is4xx, is5xx } from "../../../core/utils/api_utils";

import hiddenIcon from "../../../../resource/img/icons/icon_eye_off.svg";
import visibleIcon from "../../../../resource/img/icons/icon_eye.svg";

import { ROUTE_PASSWORD_RESET_REQUEST } from "../../../core/constants";
import Loader from "../../common/loader";
import { isLoading } from "../../../core/utils/misc_utils";

const initialFormValues = {
    email: "",
    password: ""
};

export class SignInForm extends Component {

    state = {
        visiblePassword: false,
        email: '',
    };

    render = () => {
        const { authentication, isSubmitting } = this.props;

        if (isLoading(authentication)) {
            return <Loader/>;
        }

        initialFormValues.email = this.state.email;

        return (
            <div className="field form--signed-out">
                <Formik
                    initialValues={ initialFormValues }
                    onSubmit={ values => this._onSubmit(values) }
                    render={ () => (
                        <Form>

                            <div className='input-wrapper'>
                                <div className='field'>
                                    <label className='label'>
                                        E-mail
                                        <span>
                                            <Field
                                                name="email"
                                                render={ ({ field }) => (
                                                    <Fragment>

                                                        <input className={ `${ authentication.error && !authentication.meta.loading ? "rejected" : "" }` }
                                                               type="text"
                                                               { ...field } />

                                                    </Fragment>
                                                ) }/>
                                        </span>

                                    </label>
                                </div>

                                <div className='field'>
                                    <label className='label'>
                                        Hasło
                                        <span className='is-relative'>

                                            <Field
                                                name="password"
                                                render={ ({ field }) => (
                                                    <Fragment>
                                                        <input className={ `${ authentication.error && !authentication.meta.loading ? "rejected" : "" }` }
                                                               type={ this.state.visiblePassword ? "text" : "password" } { ...field }/>

                                                    </Fragment>
                                                ) }/>

                                            <span tabIndex="0" className='show-password'
                                                  onClick={ event => this._visiblePasswordToggle(event) }
                                                  onKeyPress={ event => this._visiblePasswordToggle(event) }>
                                                <img src={ this.state.visiblePassword ? visibleIcon : hiddenIcon } alt={ this.state.visiblePassword ? "Ukryj hasło" : "Pokaż hasło" }/>
                                            </span>

                                            {
                                                this.renderErrorMessage(authentication)
                                            }
                                        </span>
                                    </label>
                                    <p className='remind-password'>
                                        <span>
                                            <Link to={ ROUTE_PASSWORD_RESET_REQUEST }>Nie pamiętasz hasła?</Link>
                                        </span>
                                    </p>
                                </div>
                            </div>

                            <input type='submit' className='btn btn--primary' disabled={ isSubmitting } value='Zaloguj się'/>

                        </Form>
                    ) }/>
            </div>
        );
    };

    renderErrorMessage = (authentication) => {
        if (!authentication.meta.loading
            && authentication.error
            && is4xx(authentication.meta.status)) {
            return (
                <span className='hint hint--rejected'>Niepoprawny login lub hasło</span>
            );
        }

        if (!authentication.meta.loading
            && authentication.error
            && is5xx(authentication.meta.status)) {
            return (
                <span className='hint hint--rejected'>Błąd komunikacji z serwerem</span>
            );
        }

        return null;
    };

    _visiblePasswordToggle = (event) => {
        if (event.which === 13 || event.type === "click") {

            this.setState(prevState => ({
                visiblePassword: !prevState.visiblePassword
            }));
        }
    };

    _onSubmit = (values) => {
        this.props.signIn({
            email: values.email,
            password: values.password
        });

        this.setState({
            email: values.email
        })
    };
}

const mapStateToProps = (state) => {
    return {
        authentication: state.authentication
    };
};

export default connect(mapStateToProps, { signIn })(SignInForm);
