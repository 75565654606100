import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import _ from "lodash";

import withModules from "../common/hoc/with_modules";
import Loader from "../common/loader";
import { isLoading, isLoadingError, isNullOrUndefined } from "../../core/utils/misc_utils";
import { moduleCodeToData } from "../../core/utils/permission_utils";
import { ROUTE_OWNER_SELECTION } from "../../core/constants";

import { getManyReportStatuses } from "../../data/actions/waste_management/reports/statuses";
import { getOwnerCoordinates, getOwnerModules } from "../../data/actions/common/owner";
import { getManyLocationCandidatesTowns } from "../../data/actions/waste_management/location_candidates";
import { getAccount } from "../../data/actions/common/account";
import { ModulesEnum } from "../../core/enums/common/modules";

class ModuleSelection extends Component {

    state = {
        moduleCount: 0
    };

    render = () => {
        const { modules, setSidebarVariant, moduleItems } = this.props;
        const activeModuleCodes = [...new Set(moduleItems?.map(moduleItem => moduleItem.moduleCode))];

        if (isLoadingError(modules)) {
            // TODO
            return (
                <div>Błąd wczytywania modułów</div>
            );
        }

        if (isLoading(modules)) {
            return (
                <Loader/>
            );
        }

        if (this.state.moduleCount === 1) {
            const moduleData = moduleCodeToData(modules.data[Object.keys(modules.data)[0]].code);

            setSidebarVariant(moduleData.sidebarVariant);
            return (
                <Redirect to={ { pathname: moduleData.route, state: { from: this.props.location } } }/>
            );
        }

        return (
            <div className="tile-selection">

                {
                    this.renderOwnerSelection()
                }

                <header className="is-spaced--bottom-20">
                    <h1 className="title is-1">
                        Wybierz moduł
                    </h1>
                </header>

                <div className="columns is-multiline">
                    {
                        _.map(modules.data, module => {
                            if (activeModuleCodes.includes(module.code)) {
                                const moduleData = moduleCodeToData(module.code);
                                return (
                                    <div className="column is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
                                         key={ module.code }>
                                        <div className="module-wrapper">
                                            <Link to={ moduleData.route }
                                                  onClick={ () => setSidebarVariant(moduleData.sidebarVariant) }
                                                  className="module">
                                                <div className="module__name">
                                                    <h2 className="title is-2 is-spaced">
                                                        {
                                                            moduleData.name
                                                        }
                                                    </h2>
                                                </div>
                                                {
                                                    this.renderIcon(module.code)
                                                }
                                            </Link>
                                        </div>
                                    </div>
                                );
                            }
                        })
                    }
                </div>
            </div>
        );
    };

    componentDidMount = () => {
        const { getAccount, getOwnerModules, getOwnerCoordinates, getManyLocationCandidatesTowns, getManyReportStatuses } = this.props;

        getAccount();
        getOwnerModules()
            .then((response) => {
                this.setState({ moduleCount: response?.data?.length });
            });
        getOwnerCoordinates();
        getManyLocationCandidatesTowns();
        getManyReportStatuses();
    };

    renderIcon = (moduleCode) => {

        switch (moduleCode) {
            case ModulesEnum.waste_management.code:
                return <span className="module__icon module__icon--waste-management"/>;
            case ModulesEnum.services.code:
                return <span className="module__icon module__icon--services"/>;
            case ModulesEnum.ekostraznik.code:
                return <span className="module__icon module__icon--ekostraznik"/>;
            case ModulesEnum.blisko.code:
                return <span className="module__icon module__icon--blisko"/>;
            default:
                return;
        }

    };

    renderOwnerSelection = () => {

        const { account, ownerName } = this.props;

        if (isNullOrUndefined(account) || isNullOrUndefined(account.data) || isNullOrUndefined(account.data.owners)) {
            return;
        }

        if (account.data.owners.length > 1) {
            return <div className="tile-selection__owner is-spaced--bottom-30">
                <p className="tile-selection__owner-name">
                    {
                        ownerName
                    }
                </p>
                <Link to={ ROUTE_OWNER_SELECTION } className="btn btn--primary btn--short btn--medium">
                    Zmień nadawcę
                </Link>
            </div>;
        }
    };
}

const mapStateToProps = (state) => {
    return {
        account: state.entities?.common?.account?.get,
        ownerName: state.authentication?.data?.ownerName,
        moduleItems: state.authentication?.data?.moduleItems
    };
};

export default withModules(
    connect(mapStateToProps, { getAccount, getOwnerModules, getOwnerCoordinates, getManyLocationCandidatesTowns, getManyReportStatuses })(ModuleSelection)
);
