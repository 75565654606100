import React from "react";

const Icon = ({ children, squared, small }) => {

    return (
        <div className={ `icon ${ squared && "icon--squared" } ${ small && "icon--small" }` }>
            { children }
        </div>
    );
};

export default Icon;