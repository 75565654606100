import React from "react";

import FormModal from "../../../../../../../../common/modal/form_modal";
import AreaLocationDeleteModalFormContent from "./content";

import { ReactComponent as IconDelete } from "../../../../../../../../../../resource/img/icons/icon_delete.svg";

const AreaLocationDeleteModalForm = ({ location, _onLocationDeleteClick }) => {

    return (
        <FormModal
            triggerComponent={
                <button className='unstyled-btn' title='Usuń'>
                    <IconDelete/>
                </button>
            }
            ariaLabel='Usuń adres'
            deleteOrCancelButtons={ true }
            _actionFunction={ () => _onLocationDeleteClick(location) }>

            <AreaLocationDeleteModalFormContent/>

        </FormModal>
    );
};

export default AreaLocationDeleteModalForm;