import React, { Component } from "react";

import { FileUpload, UploadedFile } from "../../../../../../../common/file_upload";
import { isEmptyObject } from "../../../../../../../../core/utils/misc_utils";

class UploadRegulationFile extends Component {

    render = () => {
        const { required } = this.props;
        const { file } = this.props.formikBag.values;
        const { errors } = this.props.formikBag;

        return (
            <label onClick={ e => e.preventDefault() }>
                <span className="label-name">Regulamin
                    {
                        required &&
                        <span className="required-accent">*</span>
                    }
                </span>

                {
                    isEmptyObject(file) &&

                    <FileUpload
                        _onStart={ this._onFileUploadStarted }
                        acceptedFileExtensions={ ['pdf', 'docx', 'doc', 'odt'] }
                        maxSingleFileSizeInMB={ 15 }
                    />
                }
                {
                    this.renderUploadedFile()
                }
                {
                    errors.file &&
                    <span className="hint hint--rejected">{ errors.file }</span>
                }
            </label>
        );
    };

    _onFileUploadStarted = (fileArray) => {
        const { formikBag } = this.props;

        const file = fileArray[0];

        formikBag.setFieldValue("file", file);

    };

    renderUploadedFile = () => {
        const { file } = this.props.formikBag.values;

        if (!isEmptyObject(file)) {
            return <UploadedFile
                key={ file.id }
                id={ file.id }
                name={ file.name }
                alternativeTextRequired={ false }
                isLoading={ false }
                _onDeleteUploadedFileClick={ this._onDeleteUploadedFileClick }/>;
        }
    };

    _onDeleteUploadedFileClick = () => {
        const { formikBag } = this.props;

        formikBag.setFieldValue("file", {});
    };
}

export default UploadRegulationFile;
