import React, { Component } from "react";
import _ from "lodash";
import { EditorState } from "draft-js";

import UnderlinedHeader from "../../../../../common/header_underlined";
import ServicesCardContentSectionTitleEditModalForm from "./section_title_edit_modal_form";
import Accordion from "../../../../../common/accordion";
import ExternalStateDraftEditor from "../../../../../common/draft_editor/external_state_draft_editor";
import ServiceCardsEnum from "../../../../../../core/enums/services/service_cards";
import { isEmptyArray } from "../../../../../../core/utils/misc_utils";
import { ReactComponent as IconDelete } from "../../../../../../../resource/img/icons/icon_delete.svg";
import { ReactComponent as IconEdit } from "../../../../../../../resource/img/icons/icon_edit.svg";
import { ReactComponent as IconAdd } from "../../../../../../../resource/img/icons/icon_add_white.svg";
import ServicesCardKindRadio from "./card_kind_radio";
import { Field } from "formik";
import FormErrorMessage from "../../../../../common/form_error_message";

class ServicesCardContent extends Component {

    render = () => {
        const { step, cardKind, _onCardKindClicked, errors } = this.props;

        return (
            <section className="media mw-100">
                <div className="media-left step-count-wrap">
                    <div className="step-count">
                        <span>{ step }</span>
                    </div>
                </div>
                <div className="media-content media-content--steps">
                    <UnderlinedHeader text={
                        <>
                            Treść karty <span className="required-accent">*</span>
                        </>
                    }/>

                    <div className="options-wrapper--horizontal is-spaced--top-30">
                        {
                            errors.cardContent &&
                            <FormErrorMessage message={ errors.cardContent }/>
                        }
                        <Field name='type'>
                            {() => (
                                <>
                                    <ServicesCardKindRadio
                                        label="Treść z sekcjami"
                                        selectedCardKind={ cardKind }
                                        cardKind={ ServiceCardsEnum.kind.EXTENDED }
                                        _onCardKindClicked={ _onCardKindClicked }/>

                                    <ServicesCardKindRadio
                                        label="Treść bez sekcji"
                                        selectedCardKind={ cardKind }
                                        cardKind={ ServiceCardsEnum.kind.SIMPLE }
                                        _onCardKindClicked={ _onCardKindClicked }/>
                                </>
                            )}
                        </Field>
                    </div>


                    {
                        cardKind === ServiceCardsEnum.kind.SIMPLE &&
                        this.renderSingleEditor()
                    }

                    {
                        cardKind === ServiceCardsEnum.kind.EXTENDED &&
                        this.renderMultiEditor()
                    }

                </div>
            </section>
        );
    };

    renderSingleEditor = () => {
        const { content, _setContentValue } = this.props;

        return (
            <>
                <p className="is-spaced--bottom-20">
                    &nbsp;
                </p>
                {
                    <ExternalStateDraftEditor
                        editorState={ content }
                        _onChange={ editorState => _setContentValue(editorState) }/>
                }
            </>
        );
    };

    renderMultiEditor = () => {
        const { sections } = this.props;
        return (
            <>
                <p className="is-spaced--bottom-20 is-spaced--top-30">
                    Wybierz spośród zaproponowanych tytułów sekcji lub wpisz własne.
                    {/*when drag'n'drop is done*/}
                    {/*Możesz zmieniać kolejność sekcji, przeciągając tytuły w górę lub w dół.*/}
                </p>

                {
                    _.map(sections, section => section)
                     .sort((section1, section2) => section1.no - section2.no)
                     .map(section => (
                         <Accordion
                             key={ section.no }
                             title={ section.header }
                             actionButtons={
                                 <div className="flex-center">
                                     <div className="accordion__action_icon">

                                         <ServicesCardContentSectionTitleEditModalForm
                                             headerText="Edytuj tytuł sekcji"
                                             currentTitle={ section.header }
                                             triggerComponent={
                                                 <IconEdit/>
                                             }
                                             _onSubmit={ nextHeader => {
                                                 return this._onEditSectionClick(nextHeader, section);
                                             } }/>

                                     </div>
                                     <div className="accordion__action_icon" onClick={ () => this._onRemoveSectionClick(section.no) }>
                                         <IconDelete/>
                                     </div>
                                 </div>
                             }
                             wysiwygContent={ true }>
                             <div>
                                 <ExternalStateDraftEditor
                                     editorState={ section.body }
                                     _onChange={ editorState => this._onSectionChange(section.no, editorState) }/>
                             </div>
                         </Accordion>
                     ))
                }

                <div>
                    <button className="btn btn--primary"
                            onClick={ this._onAddSectionClick }>
                                <span className="btn__icon-wrapper--left">
                                    <span className="btn__icon">
                                        <IconAdd/>
                                    </span>
                                    Dodaj nową sekcję
                                </span>
                    </button>
                </div>

            </>
        );
    };

    _onSectionChange = (sectionNo, editorState) => {
        const { sections, _setSectionValue } = this.props;

        const nextSectionArray = _
            .map(sections, section => section)
            .filter(section => section.no === sectionNo)
            .map(section => {
                return {
                    ...section,
                    body: editorState
                };
            });

        if (!isEmptyArray(nextSectionArray)) {
            _setSectionValue(nextSectionArray[0]);
        }
    };

    _onAddSectionClick = () => {
        const { sections, _setSectionValue } = this.props;
        _setSectionValue({ header: "", body: EditorState.createEmpty(), no: Object.keys(sections).length });
    };

    _onEditSectionClick = (nextHeader, section) => {
        return new Promise(resolve => {
            const { _setSectionValue } = this.props;

            const nextSection = _.cloneDeep(section);
            nextSection.header = nextHeader;

            _setSectionValue(nextSection);

            return resolve();
        });
    };

    _onRemoveSectionClick = (sectionNo) => {
        const { sections, _setSectionsValue } = this.props;

        _setSectionsValue(
            _.filter(sections, section => section.no !== sectionNo)
             .map(section => {
                 if (section.no > sectionNo) {
                     return {
                         ...section,
                         no: section.no - 1
                     };
                 }

                 return section;
             })
        );
    };
}

export default ServicesCardContent;
