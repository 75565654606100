import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import AccountListItem from "./item";
import CreateAccountModalForm from "../create_modal_form/index";
import Loader from "../../../../common/loader";
import { getManyEmployees } from "../../../../../data/actions/common/employees";
import { isNullOrUndefined } from "../../../../../core/utils/misc_utils";

class AccountsList extends Component {

    render = () => {
        const { employees } = this.props;

        return (
            <>
                <div className="accounts-options">
                    <div className="row row--options row--separated">
                        <div className='row__left'>
                            <div className="accounts-options__slot-number">
                                <span>
                                    {
                                        !isNullOrUndefined(employees.data) ? employees.data.slots.used : null
                                    }
                                </span>/
                                <span>
                                    {
                                        !isNullOrUndefined(employees.data) ? employees.data.slots.available : null
                                    }
                                </span>
                            </div>
                            <p>Liczba wykorzystanych <br/> kont</p>
                        </div>

                        {
                            !isNullOrUndefined(employees.data) &&

                            <div className='row__right'>
                                <CreateAccountModalForm slots={ employees.data.slots }/>
                            </div>

                        }

                    </div>
                </div>

                {
                    this.renderContent(employees)
                }
            </>
        );
    };

    componentDidMount = () => {
        this._loadAccounts();
    };

    renderListItems = () => {
        const { employees } = this.props;

        if (isNullOrUndefined(employees.data)) {
            return null;
        }

        return _.map(employees.data.employees, employee => {
            return (
                <AccountListItem
                    key={ employee.id }
                    employee={ employee }
                    _onSubmitCallback={ this._loadAccounts }
                />
            );
        });
    };

    renderContent = (employees) => {
        if (employees.meta.loading) {
            return <Loader/>;
        } else {
            return (
                <main className="accounts-list">
                    {
                        this.renderListItems()
                    }
                </main>
            );
        }
    };

    _loadAccounts = () => {
        return this.props.getManyEmployees();
    }

}

const mapStateToProps = (state) => {
    return {
        employees: state.entities.common.employees.getMany
    };
};

export default connect(mapStateToProps, { getManyEmployees })(AccountsList);