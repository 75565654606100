import React, { useState } from "react";
import FormModal from "../../../../../common/modal/form_modal";
import { ReactComponent as IconEdit } from "../../../../../../../resource/img/icons/icon_edit.svg";
import { toastForCustomError } from "../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../data/actions/common/ui/toast";
import { useDispatch, useSelector } from "react-redux";
import { updateCommunalDocumentFilename } from "../../../../../../data/actions/waste_management/communal_documents";

const EditCommunalDocumentForm = ({ _onSuccess, communalDocument }) => {
    const dispatch = useDispatch();
    const updateStatus = useSelector(state => state.entities?.wasteManagement?.communalDocuments?.update);
    const [ filename, setFilename ] = useState(communalDocument?.filename);

    return (
        <FormModal
            headerText="Zmień nazwę pliku"
            triggerComponent={
                <button className='unstyled-btn' title='Edytuj'>
                    <IconEdit/>
                </button>
            }
            saveOrCancelButtons={ true }
            saveButtonText="Zmień nazwę"
            isLoading={ updateStatus?.meta?.loading }
            submitDisabled={ filename.length === 0 }
            _actionFunction={ () =>
                dispatch(updateCommunalDocumentFilename(communalDocument?.id, filename))
                    .then(async response => {
                        if (response?.status === 400) {
                            toastForCustomError((...args) => dispatch(showToast(...args)), 'Podana nazwa pliku już istnieje');
                        } else {
                            return _onSuccess();
                        }
                    })
            }
        >
            <div className='c-modal__body modal-size-m'>
                <input
                    placeholder="Wpisz nazwę pliku"
                    type='text'
                    value={ filename }
                    onChange={ (event) => setFilename(event.target.value) }
                />
                <p className="notice--small">
                    Nazwa pliku musi zawierać informację dla osób niewidzących odnośnie jego zawartości<br/>
                    np.: Wzór deklaracji - lokale niezamieszkałe. Rozszerzenie pliku zostanie zachowane.
                </p>
            </div>
        </FormModal>
    );

}

export default EditCommunalDocumentForm;