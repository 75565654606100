import getMany from "./get_many";
import getGroup from "./get_group";
import getLocation from "./get_location";
import generate from "./generate";


export default (state = {}, action) => {
    return {
        ...state,
        getMany: getMany(state.getMany, action),
        getGroup: getGroup(state.getGroup, action),
        getLocation: getLocation(state.getLocation, action),
        generate: generate(state.generate, action)
    };
}