import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { exportFile, extractErrorData } from "../../../core/utils/api_utils";

export const getManyInspections = (queryParams) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__INSPECTIONS__GET_MANY__STARTED
    });

    return api
        .ekostraznik
        .inspectionHistory
        .getManyInspectionLocations(queryParams)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTIONS__GET_MANY__SUCCESS,
                meta: {},
                payload: response.data,
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTIONS__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getDetailedLocationInspection = (id, queryParams) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__DETAILED_LOCATION_INSPECTION__GET__STARTED
    });

    return api
        .ekostraznik
        .inspectionHistory
        .getLocationInspection(id, queryParams)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__DETAILED_LOCATION_INSPECTION__GET__SUCCESS,
                meta: {},
                payload: response.data,
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__DETAILED_LOCATION_INSPECTION__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getDetailedGroupInspection = (id, queryParams) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__DETAILED_GROUP_INSPECTION__GET__STARTED
    });

    return api
        .ekostraznik
        .inspectionHistory
        .getGroupInspection(id, queryParams)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__DETAILED_GROUP_INSPECTION__GET__SUCCESS,
                meta: {},
                payload: response.data,
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__DETAILED_GROUP_INSPECTION__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const generateInspectionDocument = (body) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__INSPECTIONS_DOCUMENT__GENERATE__STARTED
    });

    return api
        .ekostraznik
        .inspectionHistory
        .generateInspectionDocument(body)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTIONS_DOCUMENT__GENERATE__SUCCESS,
                meta: {},
                payload: response.data,
                error: false
            });

            exportFile(response.data, "dokument_kontroli.pdf")
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTIONS_DOCUMENT__GENERATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};
