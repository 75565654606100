import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import BulkyWasteHeader from "./header";
import BulkyWasteListArchive from "./list_archive";
import BulkyWasteListCurrent from "./list_current";
import { BulkyWasteEnum } from "../../../../../../core/enums/waste_management/bulky_waste";
import { getManyBulkyWaste } from "../../../../../../data/actions/waste_management/bulky_waste";
import { isNullOrUndefined, stringContainsSearchTerm } from "../../../../../../core/utils/misc_utils";
import Loader from "../../../../../common/loader";

class BulkyWaste extends Component {

    state = {
        searchBarTerm: "",
        activeTab: BulkyWasteEnum.tab.CURRENT
    };

    render = () => {
        const { searchBarTerm, activeTab } = this.state;
        const { bulkyWaste } = this.props;
        const filteredNewBulkyWasteData = this._filterBulkyWaste(BulkyWasteEnum.status.NEW);

        return (
            <div className="main-content--table">
                <BulkyWasteHeader
                    searchBarTerm={ searchBarTerm }
                    activeTab={ activeTab }
                    newBulkyWaste={ _.mapKeys(filteredNewBulkyWasteData, "id") }
                    bulkyWasteDataLoading={ isNullOrUndefined(bulkyWaste.meta) || bulkyWaste.meta.loading }
                    _onSearchBarInputChange={ this._onSearchBarInputChange }
                    _onTabSelect={ this._onTabSelect }/>
                {
                    this.renderActiveTab()
                }
            </div>
        );
    };

    componentDidMount = () => {
        this.props.getManyBulkyWaste();
    };

    renderActiveTab = () => {
        const { activeTab } = this.state;
        const { bulkyWaste } = this.props;

        if (bulkyWaste.meta.loading) {
            return <Loader/>;
        }

        switch (activeTab) {
            case BulkyWasteEnum.tab.ARCHIVED:
                const filteredArchivedBulkyWasteData = this._filterBulkyWaste(BulkyWasteEnum.status.ARCHIVED);

                return (
                    <BulkyWasteListArchive
                        archivedBulkyWaste={ _.mapKeys(filteredArchivedBulkyWasteData, "id") }
                        meta={ bulkyWaste.meta }
                        error={ bulkyWaste.error }/>
                );

            case BulkyWasteEnum.tab.CURRENT:
            default:
                const filteredNewBulkyWasteData = this._filterBulkyWaste(BulkyWasteEnum.status.NEW);
                const filteredPublishedBulkyWasteData = this._filterBulkyWaste(BulkyWasteEnum.status.PUBLISHED);
                const filteredVisibleOnMapBulkyWasteData = this._filterBulkyWaste(BulkyWasteEnum.status.VISIBLE_ON_MAP);

                return (
                    <BulkyWasteListCurrent
                        newBulkyWaste={ _.mapKeys(filteredNewBulkyWasteData, "id") }
                        publishedBulkyWaste={ _.mapKeys(_.concat(filteredPublishedBulkyWasteData, filteredVisibleOnMapBulkyWasteData), "id") }
                        meta={ bulkyWaste.meta }
                        error={ bulkyWaste.error }/>
                );
        }
    };

    _filterBulkyWaste = (status) => {
        const { bulkyWaste } = this.props;
        const { searchBarTerm } = this.state;

        return !isNullOrUndefined(bulkyWaste) && !isNullOrUndefined(bulkyWaste.data)
            ? _.filter(bulkyWaste.data, singleBulkyWaste => singleBulkyWaste.status === status)
               .filter(singleBulkyWaste => stringContainsSearchTerm(singleBulkyWaste.location, searchBarTerm))
            : [];
    };

    _onSearchBarInputChange = (input) => {
        if (!isNullOrUndefined(input)) {
            this.setState({
                searchBarTerm: input
            });
        }
    };

    _onTabSelect = (tab) => {
        this.setState({
            activeTab: tab
        });
    };
}

const mapStateToProps = (state) => {
    return {
        bulkyWaste: state.entities.wasteManagement.bulkyWaste.getMany
    };
};

export default connect(mapStateToProps, { getManyBulkyWaste })(BulkyWaste);