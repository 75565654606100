import React, { Component } from "react";

import { MISC_EVENT_CLICK } from "../../core/constants";

//@deprecated use view/common/dropdown_menu.js
class ComponentWithDropdown extends Component {

    state = {
        dropdownOpen: false
    };

    render = () => {
        return (
            <>
            </>
        );
    };

    _onDropdownOpen = () => {
        this.setState({ dropdownOpen: true }, () => {
            document.addEventListener(MISC_EVENT_CLICK, this._onDropdownClose);
        });
    };

    _onDropdownClose = () => {
        this.setState({ dropdownOpen: false }, () => {
            document.removeEventListener(MISC_EVENT_CLICK, this._onDropdownClose);
        });
    };
}

export default ComponentWithDropdown;
