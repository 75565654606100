import React from "react";

const Radio = ({ name, value, ariaLabel, groupValue, onInputChange }) => {
    return (
        <div className="custom-input">
            <input
                className="custom-input__input"
                type="radio"
                name={ name }
                value={ value }
                aria-label={ ariaLabel }
                onChange={ () => onInputChange(value) }
                checked={ value === groupValue }/>
            <span className="custom-input__new-input"/>
        </div>
    )
};

export default Radio;