import React, { Component } from "react";
import { Field, Form } from "formik";

class ContainerExpirationFormContent extends Component {

    render = () => {
        const { disabled } = this.props;

        return (
            <Form className='c-modal__body modal-size-m form--vertical'>
                <label>
                    <span className="label-name label-name--light">
                        Jak długo kontenery będą widoczne na mapie?
                    </span>

                    <Field
                        name="days"
                        render={ ({ field }) => (
                            <div>
                                <input
                                    type="number"
                                    className="input-size-xs"
                                    disabled={ disabled }
                                    { ...field }/>
                                <span className="input-unit--right">Dni</span>
                            </div>
                        ) }/>
                </label>

            </Form>
        );
    };

    componentDidMount = () => {
        const { formikBag, _setFormValues } = this.props;
        _setFormValues(formikBag);
    };
}

export default ContainerExpirationFormContent;
