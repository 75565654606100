import React from "react";

import withLocationManagement from "../../../../../../../../common/hoc/with_location_management";
import FormModal from "../../../../../../../../common/modal/form_modal";
import AddressAddModalForm from "../../../../../../../../common/modal/forms/address_modal";
import { ReactComponent as IconPlus } from "../../../../../../../../../../resource/img/icons/icon_plus_white.svg";
import { generatePaginationQueryValues } from "../../../../../../../../../core/utils/api_utils";
import { useDispatch, useSelector } from "react-redux";
import {
    addOrRemoveLocations,
    getManyLocationsForAddressTemplate
} from "../../../../../../../../../data/actions/blisko/address_templates/locations";

const defaultPaginationQueryValues = generatePaginationQueryValues(0, 30, ["name", "asc"]);

const AddressTemplateLocationAddForm = ({
                                            addressTemplate,
                                            searchBarTerm,
                                            locationCandidates,
                                            actions,
                                            values,
                                            renderSuggestions,
                                            inputDisabled,
                                            _setFieldValue,
                                            _resetForm,
                                            _onInputFieldFocus,
                                            _onInputFieldBlur
                                        }) => {
    const dispatch = useDispatch();
    const addressTemplateLocationsPatch = useSelector(state => state.entities?.blisko?.addressTemplates?.locations?.patchMany);


    const _onSubmit = () => {
        if (!addressTemplate?.data || !values) {
            return Promise.reject();
        }

        if (values.selectedFrom && values.selectedTo) {
            return dispatch(addOrRemoveLocations(addressTemplate.data.id, {
                unitIdsRangeToAdd: {
                    from: values.selectedFrom.id,
                    to: values.selectedTo.id
                }
            })).then(() => dispatch(getManyLocationsForAddressTemplate(
                addressTemplate.data.id,
                {
                    ...defaultPaginationQueryValues,
                    query: searchBarTerm || ''
                }
            )));
        }

        const unitId = (values.selectedFrom || values.selectedStreet || values.selectedTown)?.id;
        if (unitId) {
            return dispatch(addOrRemoveLocations(addressTemplate.data.id, { unitIdsToAdd: [unitId] }))
                .then(() => dispatch(getManyLocationsForAddressTemplate(
                    addressTemplate.data.id,
                    {
                        ...defaultPaginationQueryValues,
                        query: searchBarTerm || ''
                    }
                )));
        }

        return Promise.reject();
    };

    return (
        <FormModal
            triggerComponent={
                <a href="#root" className='btn btn--big btn--primary header__main-cta-btn'>
                        <span className="btn__icon-wrapper--left">
                            <span className='btn__icon'>
                                <IconPlus/>
                            </span>
                            Dodaj adres
                        </span>
                </a>
            }
            ariaLabel='Dodaj adres'
            headerText='Dodaj adres'
            overflow={ true }
            saveOrStayOrCancelButtons={ true }
            isLoading={ addressTemplateLocationsPatch?.meta?.loading }
            _actionFunction={ () => {
                return _onSubmit()
                    .then(() => _resetForm());
            } }
            _actionFunctionWithFormClear={ () => {
                return _onSubmit()
                    .then(() => _resetForm({
                        town: values.town,
                        selectedTown: values.selectedTown
                    }));
            } }
            _cancelFunction={ async () => _resetForm() }
        >
            <AddressAddModalForm
                locationCandidates={ locationCandidates }
                actions={ actions }
                values={ values }
                renderSuggestions={ renderSuggestions }
                inputDisabled={ inputDisabled }
                _setFieldValue={ _setFieldValue }
                _onInputFieldFocus={ _onInputFieldFocus }
                _onInputFieldBlur={ _onInputFieldBlur }/>

        </FormModal>
    );
};

export default withLocationManagement(AddressTemplateLocationAddForm);