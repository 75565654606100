import React, { Component } from "react";
import { Field, Form } from "formik";
import _ from "lodash";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, { formatDate } from "react-day-picker/moment";
import moment from "moment";
import "moment/locale/pl";

import ReportModalFormContentLocation from "./location";
import ReverseGeocodingMap from "../../../../../../common/map/reverse_geocoding_map";
import FormRequiredLegend from "../../../../../../common/legend_required";
import { ReportEnum } from "../../../../../../../core/enums/waste_management/reports";
import { MISC_DATE_ISO_DATE_ONLY_FORMAT } from "../../../../../../../core/constants";
import { isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";
import Select from "../../../../../../common/select";

class CreateAndEditReportModalFormContent extends Component {

    render = () => {
        const { geocoding, bins, selectedType, formikBag, _handleSelectedTypeChange, typeOptions } = this.props;

        const binOptions = this.generateBinOptions();
        const selectedBin =
            !isNullOrUndefined(formikBag.values.binIds) && formikBag.values.binIds.length === 1 &&
            !isNullOrUndefined(bins.data) &&
            isNullOrUndefined(bins.data[0])
                ? { label: bins.data[formikBag.values.binIds[0]].name, value: bins.data[formikBag.values.binIds[0]] }
                : null;

        return (
            <div className="c-modal__body modal-size-m">
                <Form className="form--vertical form--with-legend">
                    <label>
                        <span className="label-name"><span className="required-accent">*</span>Rodzaj zgłoszenia</span>
                        <div className="is-relative full-width">
                            <Select
                                value={ selectedType }
                                options={ typeOptions }
                                onChange={ typeOption => _handleSelectedTypeChange(typeOption, formikBag) }
                            />
                        </div>
                    </label>

                    {
                        (
                            selectedType?.value === ReportEnum.type.UNCOLLECTED_WASTE.code ||
                            selectedType?.value === ReportEnum.type.ORDER_TRASHBAG.code ||
                            selectedType?.value === ReportEnum.type.ORDER_BIN.code
                        ) &&


                        <label>
                            <span className="label-name"><span className="required-accent">*</span>Rodzaj odpadu</span>
                            <div className="is-relative full-width">
                                <Select
                                    value={ selectedBin }
                                    options={ binOptions }
                                    onChange={ binOption => this._handleSelectedBinChange(binOption?.value.id) }
                                />
                                {
                                    !isNullOrUndefined(formikBag.errors.binIds) &&
                                    <span className="hint hint--rejected">
                                        Pole wymagane
                                    </span>
                                }
                            </div>
                        </label>
                    }

                    {
                        (
                            selectedType?.value === ReportEnum.type.UNCOLLECTED_WASTE.code ||
                            selectedType?.value === ReportEnum.type.SMOKE.code
                        ) &&

                        <Field name="additionalDate"
                               render={ ({ field }) => (
                                   <label onClick={ e => e.preventDefault() }>
                                       <span className="label-name"><span className="required-accent">*</span>Data zdarzenia</span>

                                       <div className="is-relative full-width">
                                           <DayPickerInput
                                               format={ MISC_DATE_ISO_DATE_ONLY_FORMAT }
                                               formatDate={ formatDate }
                                               value={ !isNullOrUndefined(field.value) ? field.value : moment()
                                                   .format(MISC_DATE_ISO_DATE_ONLY_FORMAT) }
                                               onDayChange={ date => formikBag.setFieldValue("additionalDate", moment(date)
                                                   .format(MISC_DATE_ISO_DATE_ONLY_FORMAT)) }
                                               inputProps={ {
                                                   readOnly: true
                                               } }
                                               dayPickerProps={ {
                                                   localeUtils: MomentLocaleUtils,
                                                   locale: "pl"
                                               } }/>
                                       </div>
                                   </label>
                               ) }/>
                    }

                    {
                        (
                            selectedType?.value === ReportEnum.type.UNCOLLECTED_WASTE.code ||
                            selectedType?.value === ReportEnum.type.OTHER.code ||
                            selectedType?.value === ReportEnum.type.SMOKE.code ||
                            selectedType?.value === ReportEnum.type.ORDER_TRASHBAG.code ||
                            selectedType?.value === ReportEnum.type.ORDER_BIN.code ||
                            selectedType?.value === ReportEnum.type.ORDER_CONTAINER.code
                        ) &&

                        <ReportModalFormContentLocation
                            value={ formikBag.values.localization }
                            errors={ formikBag.errors.localization }
                            _onLocationFieldChangeCallback={ this._onForwardGeocodingLocationFieldChangeCallback }
                            _onSuggestionClickCallback={ this._onForwardGeocodingSuggestionClickCallback }/>
                    }

                    {
                        (
                            selectedType?.value === ReportEnum.type.OTHER.code ||
                            selectedType?.value === ReportEnum.type.WILD_DUMP.code
                        ) &&

                        <ReverseGeocodingMap
                            reverse={ geocoding.reverse }
                            longitude={ formikBag.values.longitude }
                            latitude={ formikBag.values.latitude }
                            _reverseGeocodingCallback={ this._reverseGeocodingCallback }
                            _handleReverseGeocodingCallback={ this._handleReverseGeocodingCallback }/>
                    }

                    {
                        selectedType?.value === ReportEnum.type.OTHER.code &&

                        <Field name="subject"
                               render={ ({ field }) => (
                                   <label>
                                       <span className="label-name"><span className="required-accent">*</span>Temat</span>

                                       <div className="is-relative full-width">
                                           <input type="text"
                                                  { ...field }/>

                                           {
                                               !isNullOrUndefined(formikBag.errors.subject) &&
                                               <span className="hint hint--rejected">
                                                   Pole wymagane
                                               </span>
                                           }
                                       </div>
                                   </label>
                               ) }/>
                    }

                    <Field name="description"
                           render={ ({ field }) => (
                               <label>
                                   <span className="label-name">
                                       {
                                           selectedType?.value === ReportEnum.type.OTHER.code &&
                                           <span className="required-accent">*</span>
                                       }
                                       Opis
                                   </span>

                                   <div className="is-relative full-width">
                                       <textarea
                                           { ...field } />
                                       {
                                           !isNullOrUndefined(formikBag.errors.description) &&
                                           <span className="hint hint--rejected">
                                               Pole wymagane
                                           </span>
                                       }
                                   </div>
                               </label>
                           ) }/>
                    <FormRequiredLegend/>
                </Form>
            </div>
        );
    };

    componentDidMount = () => {
        const { formikBag } = this.props;
        if (formikBag != null) {
            formikBag.resetForm();
        }
    };

    generateBinOptions = () => {
        const { bins } = this.props;
        
        if (isNullOrUndefined(bins.data)) {
            return [];
        }

        const activeBins = _.filter(bins.data, bin => bin.status === 'ACTIVE');

        return _.map(activeBins, bin => {
            return {
                label: bin.name,
                value: bin,
            };
        });
    };

    _onForwardGeocodingLocationFieldChangeCallback = (value) => {
        const { formikBag } = this.props;

        formikBag.setFieldValue("localization", value);
    };

    _onForwardGeocodingSuggestionClickCallback = (lng, lat, placeName) => {
        const { formikBag } = this.props;

        formikBag.setFieldValue("longitude", lng);
        formikBag.setFieldValue("latitude", lat);
        formikBag.setFieldValue("localization", placeName);
    };

    _reverseGeocodingCallback = (longitude, latitude) => {
        const { formikBag, reverseGeocoding } = this.props;

        return reverseGeocoding(longitude, latitude)
            .then(() => {
                formikBag.setFieldValue("longitude", longitude);
                formikBag.setFieldValue("latitude", latitude);
            });
    };

    _handleReverseGeocodingCallback = (name) => {
        return this.props.formikBag.setFieldValue("localization", name);
    };

    _handleSelectedBinChange = (binId) => {
        const { formikBag } = this.props;
        return formikBag.setFieldValue("binIds", binId ? [binId] : undefined);
    };
}

export default CreateAndEditReportModalFormContent;
