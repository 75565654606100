import React, { Component } from "react";

const withMultiStepForm = (OriginalComponent, maxSteps) => {

    class WrappedComponent extends Component {

        state = {
            currentStep: 1
        };

        render = () => {
            return <OriginalComponent
                currentStep={ this.state.currentStep }
                _onNextStepClick={ this._onNextStepClick }
                _onPrevStepClick={ this._onPrevStepClick }
                _onFirstStepClick={ this._onFirstStepClick }
                _onLastStepClick={ this._onLastStepClick }
                { ...this.props }/>;
        };

        _onFirstStepClick = () => {
            this.setState({
                currentStep: 1
            });
        };

        _onLastStepClick = () => {
            this.setState({
                currentStep: maxSteps
            });
        };

        _onNextStepClick = () => {
            this.setState(prevState => ({
                currentStep: prevState.currentStep === maxSteps ? maxSteps : prevState.currentStep + 1
            }));
        };

        _onPrevStepClick = () => {
            this.setState(prevState => ({
                currentStep: prevState.currentStep === 1 ? 1 : prevState.currentStep - 1
            }));
        };
    }

    return WrappedComponent;
};

export default withMultiStepForm;