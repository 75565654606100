import React, { Component } from "react";
import { Link } from "react-router-dom";

import { MISC_SHOW_UNFINISHED_ELEMENTS, ROUTE_MODULE_SELECTION } from "../../../core/constants";
import AdminHeaderDropdownUser from "./header_dropdown_user";
import AdminHeaderDropdownModules from "./header_dropdown_modules";
import { ReactComponent as IconMail } from "../../../../resource/img/icons/icon_mail.svg";

class AdminHeader extends Component {

    render = () => {
        const { _onSidebarTriggerClicked, showHomeIcon } = this.props;

        return (
            <nav className='row admin-navbar'>

                { /* left side */ }
                <button className='navbar-burger burger sidebar-trigger'
                        onClick={ () => _onSidebarTriggerClicked() }>
                    <span aria-hidden="true"/>
                    <span aria-hidden="true"/>
                    <span aria-hidden="true"/>
                </button>

                <AdminHeaderDropdownModules showHomeIcon={ showHomeIcon }/>

                { /* right side */ }
                <div className="is-flex admin-navbar__right">

                    {
                        MISC_SHOW_UNFINISHED_ELEMENTS &&
                        <Link to={ ROUTE_MODULE_SELECTION } className="admin-navbar__navbar-item"
                              title='Wiadomości'>
                            <IconMail/>
                        </Link>
                    }

                    {
                        <AdminHeaderDropdownUser/>
                    }

                </div>
            </nav>
        );
    };

}

export default AdminHeader;
