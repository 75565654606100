import React, { Component } from "react";
import { isNullOrUndefined } from "../../core/utils/misc_utils";
import { ReactComponent as IconDelete } from "../../../resource/img/icons/icon_delete.svg";

class TextareaRemovable extends Component {

    render = () => {
        const { icon, placeholder, value, _onEdit, _onDeleteClick } = this.props;

        return (
            <div className={ `removable-input ${ !isNullOrUndefined(icon) ? "with-icon" : "" }` }>
                {
                    !isNullOrUndefined(icon) &&
                    <img src={ icon } alt=""/>
                }
                <textarea placeholder={ placeholder }
                          value={ value }
                          onChange={ event => _onEdit(event.target.value) }/>
                <button className="btn"
                        onClick={ _onDeleteClick }>
                    <IconDelete/>
                </button>
            </div>
        );
    };
}

export default TextareaRemovable;