import React, { useEffect, useState } from "react";
import TitleWithBottomDivider from "../../../../../common/title_with_bottom_divider";
import { useDispatch, useSelector } from "react-redux";
import { getManyEmployees } from "../../../../../../data/actions/common/employees";
import Select from "../../../../../common/select";
import Loader from "../../../../../common/loader";
import _ from "lodash";
import ChosenEmployees from "./chosen_employees";

const ReceiverGroupEmployee = ({ formikBag, currentEmployee }) => {
    const dispatch = useDispatch();
    const employees = useSelector(state => state.entities?.common?.employees?.getMany);
    const [chosenEmployee, setChosenEmployee] = useState(null);
    const [employeeList, setEmployeeList] = useState([]);

    const formikEmployees = formikBag.values.employees;

    useEffect(() => {
        dispatch(getManyEmployees())
            .then(data => {
                setEmployeeList([..._.filter(data.employees, employee => currentEmployee?.id !== employee.id)]);
            });
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        setEmployeeList([..._.filter(employees?.data?.employees, employee =>
            !formikEmployees.some(formikEmployee => formikEmployee.value === employee.id))]);
        // eslint-disable-next-line
    }, [formikEmployees]);

    const _onAddToListClick = () => {
        formikBag.setFieldValue("employees", [...formikEmployees, chosenEmployee]);
        setChosenEmployee(null);
    };

    const _onEmployeeRemove = (employee) => {
        const newEmployees = [...formikEmployees];
        const index = newEmployees.findIndex(e =>
            e.value === employee.value
        );

        newEmployees.splice(index, 1);
        formikBag.setFieldValue("employees", newEmployees);
    };

    if (employees.isLoading) {
        return (
            <Loader/>
        )
    }

    return (
        <section className="media">
            <div className="media-left step-count-wrap is-spaced--top-30">
                <div className="step-count">
                    <span>2/3</span>
                </div>
            </div>
            <div className="media-content is-spaced--top-30">
                <div className="message-sending-1__section">
                    <div className="message-sending-1__subtitle">
                        <TitleWithBottomDivider>
                            Dostęp do wysyłki wiadomości
                        </TitleWithBottomDivider>
                        <span>Dodaj do listy osoby, które mogą wysyłać wiadomości dla tego serwisu.</span>
                    </div>
                    <div className="is-spaced--top-40 is-flex align-end">
                        <label className="flex-grow-1">
                            <Select
                                isClearable={ false }
                                value={ chosenEmployee }
                                options={ _.map(employeeList, employee => ({
                                    label: employee.name + " " + employee.surname,
                                    value: employee.id
                                }))

                                }
                                onChange={ employeeOption => setChosenEmployee(employeeOption) }
                            />
                        </label>
                        <button
                            type='button'
                            disabled={ !chosenEmployee }
                            className="btn btn--primary is-spaced--left-30"
                            onClick={ _onAddToListClick }
                        >
                            Dodaj do listy
                        </button>
                    </div>

                    <ChosenEmployees
                        employees={ formikEmployees }
                        currentEmployee={ currentEmployee }
                        _onEmployeeRemove={ employee => _onEmployeeRemove(employee) }
                    />

                </div>
            </div>
        </section>
    )
};

export default ReceiverGroupEmployee;
