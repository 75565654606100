import React, { Component } from "react";
import { isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";
import moment from "moment/moment";
import { MISC_DATE_DAY_MONTH_YEAR_HOUR_MINUTE_FORMAT } from "../../../../../../../core/constants";
import { InspectionStatusesEnum } from "../../../../../../../core/enums/ekostraznik/inspection_statuses";
import { displayBinName } from "../../../../../../../core/utils/bins_utils";

class GenerateInspectionDocumentInspectionItem extends Component {

    render = () => {
        const { inspection, _onInspectionClick } = this.props;
        const isChecked = this.isInspectionChecked(inspection);

        if(inspection.status === InspectionStatusesEnum.statuses.REJECTED.value){
            return (
                <div className="generate-document__item">
                    <label className="acceptance-label acceptance-label--disabled"
                           onClick={ event => event.preventDefault() }>
                        <input type='checkbox' className='acceptance-checkbox'/>
                        <span className="checkbox-label__name">
                        <span>
                            {
                                moment(inspection.createdDate)
                                    .format(MISC_DATE_DAY_MONTH_YEAR_HOUR_MINUTE_FORMAT)
                            }
                        </span>
                        <span>
                            {
                                displayBinName(inspection.binCode)
                            }
                        </span>
                    </span>
                    </label>
                </div>
            )
        }

        return (
            <div className="generate-document__item">
                <label className={ `acceptance-label${ !isNullOrUndefined(isChecked) && isChecked ? " label--checked" : "" } ` }
                       onClick={ event => {
                           event.preventDefault();
                           _onInspectionClick(inspection.code);
                       } }>
                    <input type='checkbox' className='acceptance-checkbox'/>
                    <span className="checkbox-label__name">
                        <span>
                            {
                                moment(inspection.createdDate)
                                    .format(MISC_DATE_DAY_MONTH_YEAR_HOUR_MINUTE_FORMAT)
                            }
                        </span>
                        <span>
                            {
                                displayBinName(inspection.binCode)
                            }
                        </span>
                    </span>
                </label>
            </div>
        );
    };

    isInspectionChecked = (inspection) => {
        const { selectedInspections } = this.props;

        let indexOfInspection = selectedInspections.indexOf(inspection.code);

        return indexOfInspection === -1 ? false : true;
    };

}


export default GenerateInspectionDocumentInspectionItem;
