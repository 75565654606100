import webClient from "../../../core/web_client";
import { buildIdQueryParamArray, buildQueryParamValueArray } from "../../../core/utils/api_utils";
import { API_BASE_AKUN_GOK_PATH } from "../../../core/constants";

const PATH = `${ API_BASE_AKUN_GOK_PATH }/large-receptions`;
const PATH_CONFIG = `${ API_BASE_AKUN_GOK_PATH }/large-receptions/configuration`;

export const getManyBulkyWaste = () => {
    return webClient
        .get(`${ PATH }`);
};

export const addBulkyWaste = (data) => {
    return webClient
        .post(`${ PATH }`, data);
};

export const deleteManyBulkyWaste = (idArray) => {
    return webClient
        .delete(`${ PATH }?id=${ buildQueryParamValueArray(idArray) }`);
};

export const updateBulkyWaste = (id, data) => {
    return webClient
        .post(`${ PATH }/${ id }`, data);
};

export const publishBulkyWaste = (idArray) => {
    return webClient
        .put(`${ PATH }/publish?${ buildIdQueryParamArray(idArray) }`, null);
};

export const getBulkyWasteConfig = () => {
    return webClient
        .get(`${ PATH_CONFIG }`);
};

export const updateBulkyWasteConfig = (data) => {
    return webClient
        .post(`${ PATH_CONFIG }`, data);
};