import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import ComponentWithDropdown from "../../../../common/component_with_dropdown";
import { isNullOrUndefined } from "../../../../../core/utils/misc_utils";
import { EmployeesEnum } from "../../../../../core/enums/common/employees";
import EmployeesConfirmationModalForm from "./confirmation_modal_form";

import { ReactComponent as IconUser } from "../../../../../../resource/img/icons/icon_user_white.svg";
import { ReactComponent as IconAdmin } from "../../../../../../resource/img/icons/icon_crown.svg";
import { ReactComponent as IconPending } from "../../../../../../resource/img/icons/icon_hourglass.svg";
import { ReactComponent as IconSuspended } from "../../../../../../resource/img/icons/icon_lock.svg";
import { ReactComponent as IconEllipsisVertical } from "../../../../../../resource/img/icons/icon_ellipsis_vertical_gray.svg";
import { ROUTE_ACCOUNTS_DETAILS } from "../../../../../core/constants";
import {
    changeEmployeeAccountStatus,
    deleteEmployee,
    reinviteEmployee
} from "../../../../../data/actions/common/employees";
import { showToast } from "../../../../../data/actions/common/ui/toast";
import { toastFor500, toastForSuccess } from "../../../../../core/utils/toast_utils";

class AccountListItem extends ComponentWithDropdown {

    render = () => {
        const { dropdownOpen = false } = this.state;
        const { employee, _onSubmitCallback } = this.props;

        return (
            <div className="account-item">
                <Link to={ ROUTE_ACCOUNTS_DETAILS(employee.id) } className="account__content">

                    {
                        !isNullOrUndefined(employee) &&
                        this.renderStatusIcon(employee)
                    }

                    <div className="account-item__info">
                        <strong>
                            {
                                !isNullOrUndefined(employee) ? employee.name + " " + employee.surname : null
                            }
                        </strong>
                        <p>
                            {
                                !isNullOrUndefined(employee) ? employee.email : null
                            }
                        </p>

                        {
                            (!isNullOrUndefined(employee) ? employee.accountStatus : null) === EmployeesEnum.status.INACTIVE &&
                            <div className="account-item__status-info">
                                <span className="badge">
                                    NIE AKTYWOWANO KONTA
                                </span>
                                <span className="account-item__activation-code">
                                    KOD AKTYWACYJNY: <strong> { employee.activationCode } </strong>
                                </span>
                            </div>
                        }

                        {
                            (!isNullOrUndefined(employee) ? employee.accountStatus : null) === EmployeesEnum.status.BLOCKED &&
                            <span className="badge">
                                KONTO ZAWIESZONE
                            </span>
                        }
                    </div>

                </Link>

                {
                    employee.position !== EmployeesEnum.position.ADMINISTRATOR &&
                    <div className={ `dropdown account-item__dropdown ${ dropdownOpen ? "is-active" : "" }` }>
                        <div className="dropdown-trigger">
                            <button className="unstyled-btn icon--menu" title='Więcej' onClick={ this._onDropdownOpen }>
                                <IconEllipsisVertical/>
                            </button>
                        </div>

                        <div className="dropdown-menu drop-to-right">
                            <div className="dropdown-content">

                            {
                                    employee.accountStatus === EmployeesEnum.status.INACTIVE &&
                                        <EmployeesConfirmationModalForm
                                            employee={ employee }
                                            _onSubmitCallback={ _onSubmitCallback }
                                            _onSubmitAction={ this._onReinviteConfirmationAction }
                                            saveOrCancelButtons={ true }
                                            saveButtonText={ "Wyślij" }
                                            confirmationText="Czy na pewno chcesz wysłać ponownie zaproszenie do "
                                            triggerComponent={ <span className="unstyled-btn dropdown-item">Wyślij ponownie zaproszenie</span> }/>
                                }
                                {
                                    employee.accountStatus === EmployeesEnum.status.INACTIVE &&
                                        <EmployeesConfirmationModalForm
                                            employee={ employee }
                                            _onSubmitCallback={ _onSubmitCallback }
                                            _onSubmitAction={ this._onDeleteConfirmationAction }
                                            saveOrCancelButtons={ true }
                                            saveButtonText={ "Usuń" }
                                            confirmationText="Czy na pewno chcesz usunąć nieaktywowane konto "
                                            triggerComponent={ <span className="unstyled-btn dropdown-item">Usuń konto</span> }/>
                                }
                                {
                                    employee.accountStatus === EmployeesEnum.status.ACTIVE &&
                                        <EmployeesConfirmationModalForm
                                            employee={ employee }
                                            _onSubmitCallback={ _onSubmitCallback }
                                            _onSubmitAction={ this._onSuspendConfirmationAction }
                                            saveOrCancelButtons={ true }
                                            saveButtonText={ "Zawieś" }
                                            confirmationText="Czy na pewno chcesz zawiesić konto "
                                            triggerComponent={ <span className="unstyled-btn dropdown-item">Zawieś konto</span> }/>
                                }
                                {
                                    employee.accountStatus === EmployeesEnum.status.BLOCKED &&
                                        <EmployeesConfirmationModalForm
                                            employee={ employee }
                                            _onSubmitCallback={ _onSubmitCallback }
                                            _onSubmitAction={ this._onRestoreConfirmationAction }
                                            saveOrCancelButtons={ true }
                                            saveButtonText={ "Przywróć" }
                                            confirmationText="Czy na pewno chcesz przywrócić konto "
                                            triggerComponent={ <span className="unstyled-btn dropdown-item">Przywróć konto</span> }/>
                                }

                            </div>
                        </div>
                    </div>
                }

            </div>
        );
    };

    renderStatusIcon = (employee) => {

        switch (employee.accountStatus) {
            case EmployeesEnum.status.INACTIVE:
                return (
                    <div className="account__status-icon account__status-icon--pending">
                        <IconPending/>
                    </div>
                );
            case EmployeesEnum.status.BLOCKED:
                return (
                    <div className="account__status-icon account__status-icon--suspended">
                        <IconSuspended/>
                    </div>
                );
            case EmployeesEnum.status.ACTIVE:
                return this.adminOrUserCheck();
            default:
                return (
                    <div className="account__status-icon"/>
                );
        }
    };

    adminOrUserCheck = () => {
        const { employee } = this.props;

        if (employee.position === EmployeesEnum.position.ADMINISTRATOR) {
            return (
                <div className="account__status-icon account__status-icon--admin">
                    <IconAdmin/>
                </div>
            );
        } else {
            return (
                <div className="account__status-icon account__status-icon--user">
                    <IconUser/>
                </div>
            );
        }
    };

    _onReinviteConfirmationAction = (id) => {
        const { reinviteEmployee, showToast, reinvite } = this.props;

        return reinviteEmployee(id)
            .then(() => {

                    toastFor500(showToast, reinvite, "Wysłanie zaproszenia nie powiodło się. Spróbuj ponownie");
                    toastForSuccess(showToast, reinvite, "Zaproszenie zostało wysłane");
                }
            );
    };

    _onSuspendConfirmationAction = (id) => {
        const { changeEmployeeAccountStatus, showToast, change, employee } = this.props;

        return changeEmployeeAccountStatus(id, EmployeesEnum.status.BLOCKED)
            .then(() => {

                toastFor500(showToast, change, "Zawieszenie moderatora nie powiodło się. Spróbuj ponownie");
                toastForSuccess(showToast, change, `Konto moderatora ${employee.name} ${employee.surname} zostało zawieszone`);
            });


    };

    _onDeleteConfirmationAction = (id) => {
        const { deleteEmployee, showToast, change, employee } = this.props;

        return deleteEmployee(id)
            .then(() => {

                toastFor500(showToast, change, "Usunięcie konta nie powiodło się. Spróbuj ponownie");
                toastForSuccess(showToast, change, `Konto ${employee.name} ${employee.surname} zostało usunięte`);
            });


    };

    _onRestoreConfirmationAction = (id) => {
        const { changeEmployeeAccountStatus, showToast, change, employee } = this.props;

        return changeEmployeeAccountStatus(id, EmployeesEnum.status.ACTIVE)
            .then(() => {

            toastFor500(showToast, change, "Zawieszony moderator nie został przywrócony. Spróbuj ponownie");
            toastForSuccess(showToast, change, `Konto moderatora ${employee.name} ${employee.surname} zostało przywrócone`);
        });
    };

}

const mapStateToProps = (state) => {
    return{
        reinvite: state.entities.common.employees.reinvite,
        delete_: state.entities.common.employees.delete_,
        change: state.entities.common.employees.change
    };
};

export default connect(mapStateToProps, { deleteEmployee, reinviteEmployee, changeEmployeeAccountStatus, showToast })(AccountListItem);
