import React, { Component } from "react";
import moment from "moment/moment";

import { ReactComponent as IconPending } from "../../../../../../../resource/img/icons/status/icon_to_do.svg";
import { ReactComponent as IconDone } from "../../../../../../../resource/img/icons/status/icon_done.svg";
import { ReactComponent as IconRejected } from "../../../../../../../resource/img/icons/status/icon_rejected.svg";
import { MISC_DATE_FORMAT, MISC_TIME_FORMAT, ROUTE_BIN_INSPECTION } from "../../../../../../core/constants";
import { displayBinName } from "../../../../../../core/utils/bins_utils";
import { Redirect } from "react-router-dom";

class InspectionDetailsMonthTableItem extends Component {

    state = {
        shouldRedirectToInspectionDetails: false
    };

    render = () => {
        const { inspection, parentId, parentType } = this.props;

        if (this.state.shouldRedirectToInspectionDetails) {
            return <Redirect to={ { pathname: ROUTE_BIN_INSPECTION(parentType, parentId, inspection.code) } }/>;
        }

        return (
            <tr className="tr--clickable"
                onClick={ () => this.setState({ shouldRedirectToInspectionDetails: true }) }>
                <td className='t-width-2 td--column'>
                    <div className="mw-100">
                        <p className="table-text-content">
                            {
                                moment(inspection.createdDate)
                                    .format(MISC_DATE_FORMAT)
                            }
                        </p>
                    </div>
                    <p className="secondary-text secondary-text--under-ellipsis">
                        {
                            moment(inspection.createdDate)
                                .format(MISC_TIME_FORMAT)
                        }
                    </p>
                </td>
                <td className='t-width-6'>
                    {
                        displayBinName(inspection.binCode)
                    }
                </td>
                <td className='t-width-4 td--space-between'>
                    <div className="is-flex">
                        {
                            renderStatus(inspection.status)
                        }
                    </div>

                    {/*<div className="table-select">*/}
                        {/*Zmień*/}
                    {/*</div>*/}
                </td>
            </tr>
        );
    };

}

const renderStatus = (status) => {

    switch (status) {
        case "PENDING":
            return (
                <>
                    <span className="table-status">
                        <IconPending/>
                    </span>
                    Nierozstrzygnięta
                </>
            );

        case "PROCESSED":
            return (
                <>
                    <span className="table-status">
                        <IconDone/>
                    </span>
                    Wszczęto postępowanie
                </>
            );

        case "REJECTED":
            return (
                <>
                    <span className="table-status">
                        <IconRejected/>
                    </span>
                    Odrzucono
                </>
            );

        default:
        // empty
    }
};

export default InspectionDetailsMonthTableItem;



