import React, { useState } from "react";

import AddressTemplateList from "./list";
import { deepFreeze } from "../../../../../../core/utils/misc_utils";
import AddressTemplateHeader from "./header";

const addressTemplateAuthors = deepFreeze({
    ALL: {
        value: "all",
        description: "Wszystkie"
    },
    MINE: {
        value: "employee",
        description: "Utworzone przeze mnie"
    }
});

const AddressTemplate = () => {
    const [state, setState] = useState({
        searchBarTerm: '',
        addressTemplateAuthor: addressTemplateAuthors.ALL.value
    });

    return (
        <div className="main-content--table">
            <AddressTemplateHeader
                addressTemplateAuthors={ addressTemplateAuthors }
                state={ state}
                setState={ setState }/>
            <AddressTemplateList
                state={ state }/>
        </div>
    )
};


export default AddressTemplate;