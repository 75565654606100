import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const getPaymentsDueDate = (data) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__PAYMENTS_DUE_DATE__GET__STARTED
    });

    return api
        .waste_management
        .paymentsDueDate
        .getPaymentsDueDate(data)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__PAYMENTS_DUE_DATE__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__PAYMENTS_DUE_DATE__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const updatePaymentsDueDate = (data) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__PAYMENTS_DUE_DATE__UPDATE__STARTED
    });

    return api
        .waste_management
        .paymentsDueDate
        .updatePaymentsDueDate(data)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__PAYMENTS_DUE_DATE__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__PAYMENTS_DUE_DATE__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: false
            });
        });
};
