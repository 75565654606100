import inspectionCompanies from "./inspectionCompanies";
import inspectionHistory from "./inspectionHistory";
import inspectionCountThreshold from "./inspectionCountThreshold";
import inspections from "./inspections";
import reports from "./reports";
import bins from "./bins";
import qrCodes from "./qrCodes";
import addressGroups from "./addressGroups";
import apartmentAddresses from "./apartmentAddresses";
import inventoryHistory from "./inventoryHistory";
import inventories from "./inventories"

export default (state = {}, action) => {
    return {
        ...state,
        inspectionCompanies: inspectionCompanies(state.inspectionCompanies, action),
        inspectionHistory: inspectionHistory(state.inspectionHistory, action),
        inspectionCountThreshold: inspectionCountThreshold(state.inspectionCountThreshold, action),
        inspections: inspections(state.inspections, action),
        reports: reports(state.reports, action),
        bins: bins(state.bins, action),
        qrCodes: qrCodes(state.qrCodes, action),
        addressGroups: addressGroups(state.addressGroups, action),
        apartmentAddresses: apartmentAddresses(state.apartmentAddresses, action),
        inventoryHistory: inventoryHistory(state.inventoryHistory, action),
        inventories: inventories(state.inventories, action)
    };
}
