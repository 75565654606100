import React, { useEffect } from "react";
import TitleWithBottomDivider from "../../../../../../common/title_with_bottom_divider";
import { useDispatch, useSelector } from "react-redux";
import { getManyReceiverGroups } from "../../../../../../../data/actions/blisko/receiver_groups";
import CheckboxInput from "../../../../../../common/checkbox_input";

const ReceiverGroups = ({ receiverGroupIds, errors, setFieldValue, step }) => {
    const dispatch = useDispatch();
    const receiverGroups = useSelector(state => state.entities?.blisko?.receiverGroups?.getMany);

    useEffect(() => {
        dispatch(getManyReceiverGroups());
    }, [dispatch]);

    const _onReceiverGroupCheckboxChange = (id) => {
        const receiverGroupIndex = receiverGroupIds.indexOf(id);

        if (receiverGroupIndex >= 0) {
            const newCheckedReceiverGroups = [...receiverGroupIds];
            newCheckedReceiverGroups.splice(receiverGroupIndex, 1);

            setFieldValue("groupIds", newCheckedReceiverGroups);
        } else {
            setFieldValue("groupIds", [...receiverGroupIds, id]);
        }
    };

    return (
        <section className="media">
            <div className="media-left step-count-wrap">
                <div className="step-count">
                    <span>{ step }</span>
                </div>
            </div>
            <div className="media-content">
                <div className="message-sending-1__section">
                    <div className="message-sending-1__subtitle message-sending-1__subtitle--bottom-15">
                        <TitleWithBottomDivider>
                            <span className="required-accent">*</span><span className="is-bold">Serwisy publiczne</span>
                        </TitleWithBottomDivider>
                        <span>Wybierz do którego serwisu publicznego ma być wysłana wiadomość.</span>
                    </div>
                    {
                        receiverGroups?.data?.content?.length !== 0 &&
                        <div className="message-sending-1__subsection">
                            <div className='is-spaced--bottom-10 is-spaced--top-40 is-clickable'
                                 onClick={ () => setFieldValue("groupIds", receiverGroups.data.content.map(receiverGroup => receiverGroup.id)) }
                            >
                                <strong className='unstyled-btn is-uppercase'>ZAZNACZ WSZYSTKIE</strong>
                            </div>
                            {
                                receiverGroups?.data?.content?.map(receiverGroup => {
                                    return (
                                        <div key={ receiverGroup.id } className="is-spaced--top-10">
                                            <CheckboxInput
                                                label={ receiverGroup.name }
                                                checked={ receiverGroupIds.indexOf(receiverGroup.id) >= 0 }
                                                _onInputChange={ () => _onReceiverGroupCheckboxChange(receiverGroup.id) }
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        errors.groupIds &&
                        <strong className="hint hint--rejected">
                            { errors.groupIds }
                        </strong>
                    }
                </div>
            </div>
        </section>
    );
};

export default ReceiverGroups;