import React from "react";
import SettingsHeader from "../common/header";
import { SettingsEnum } from "../../../../../core/enums/common/settings";

const SettingsContactData = () => {

    return (
        <div className="side-spacing">
            <SettingsHeader tab={ SettingsEnum.tab.CONTACT_DATA }/>
        </div>

    );
};

export default SettingsContactData;
