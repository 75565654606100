import React, { Component } from "react";

import withMap from "../hoc/with_map";

class CenterPinMap extends Component {

    render = () => {
        const { mapboxContainerRef } = this.props;

        // TODO: inline style
        return (
            <div className="message-sending-1__map">
                <div ref={ mapboxContainerRef }
                     className="map-wrapper"
                     style={ { height: 300 } }/>
            </div>
        );
    };

    componentDidMount = () => {
        const { originalComponentInstanceRef } = this.props;
        // hook for accessing this components instance in the HOC
        originalComponentInstanceRef.current = this;
    };

    _onMapMove = (event) => {
        const { mapboxMarkerRef } = this.props;
        const map = event.target;
        const markerReference = mapboxMarkerRef.current;

        if (map && markerReference) {
            markerReference.setLngLat(map.getCenter());
        }
    };

    _onMapMoveEnd = (event) => {
        const {_onMapMoveEndCallback } = this.props;
        const map = event.target;

        if (!map) {
            return;
        }

        const center = map.getCenter();
        if (_onMapMoveEndCallback) {
            _onMapMoveEndCallback(center.lat, center.lng);
        }
    };

}


export default withMap(CenterPinMap);