import generate from "./generate";
import getMany from "./get_many";
import updatePreparationList from "./update_preparation_list";
import getPreparationList from "./get_preparation_list";

export default (state = {}, action) => {
    return {
        ...state,
        generate: generate(state.generate, action),
        getMany: getMany(state.getMany, action),
        updatePreparationList: updatePreparationList(state.updatePreparationList, action),
        getPreparationList: getPreparationList(state.getPreparationList, action),
    };
}
