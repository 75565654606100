import React, { useEffect, useState } from "react";

import Header from "../../../../common/header";
import SentMessages from "./sent";
import DraftMessages from "./draft";
import { useDispatch } from "react-redux";
import { getOwnerConfiguration } from "../../../../../data/actions/common/owner";

const Messages = ({ location }) => {
    const dispatch = useDispatch();
    const selectTab = location?.state?.selectTab;
    const [activeTab, setActiveTab] = useState(selectTab ? selectTab : 0);

    useEffect(() => {
        dispatch(getOwnerConfiguration())
    }, [dispatch])

    return (
        <div className="main-content--table">
            <Header>Wiadomości</Header>

            <section className="main-content--table__top container is-fluid">
                <div className="row row--options">
                    <div className='full-width'>
                        <div className="tabs">
                            <ul className="tabs-list">
                                <li onClick={ () => setActiveTab(0) }
                                    className={ `tabs-item ${ activeTab === 0 ? 'is-active' : '' }` }>
                                    <span className="tab-item">Wiadomości</span>
                                </li>
                                <li onClick={ () => setActiveTab(1) }
                                    className={ `tabs-item ${ activeTab === 1 ? 'is-active' : '' }` }>
                                    <span className="tab-item">Wiadomości robocze</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {
                activeTab === 0 &&
                <SentMessages/>
            }

            {
                activeTab === 1 &&
                <DraftMessages/>
            }

        </div>
    )
};

export default Messages;