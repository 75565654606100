import { deepFreeze } from "../../utils/misc_utils";

const ServiceCardsEnum = deepFreeze({
    type: {
        ISSUE_HANDLE: "ISSUE_HANDLE",
        SERVICE: "SERVICE"
    },
    kind: {
        EXTENDED: 'EXTENDED',
        SIMPLE: 'SIMPLE'
    },
    status: {
        DRAFT: "DRAFT",
        PUBLISHED: "PUBLISHED",
        HIDDEN: "HIDDEN"
    }
});

export default ServiceCardsEnum;
