import React from "react";

import CompaniesList from "./list";

const InspectionCompanies = () => {

    return (
        <>
            <section className="header--spaced">
                <header className='row page-header'>
                    <h1 className='title is-1'>
                        Kontrolerzy
                    </h1>
                </header>
            </section>

            <section className='container is-fluid'>
                <div>
                    <CompaniesList/>
                </div>
            </section>
        </>
    );
};

export default InspectionCompanies;
