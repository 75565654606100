import React from "react";

const SideListDetails = ({ children }) => {

    return (
        <article className="sll__details">
            {
                children
            }
        </article>
    );
};

export default SideListDetails;