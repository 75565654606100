import React, { Component } from "react";
import { connect } from "react-redux";

import "moment/locale/pl";
import { addRegulation, getRegulationPlaces } from "../../../../../../../data/actions/common/regulations";
import _ from "lodash";
import { Formik } from "formik";
import FormModal from "../../../../../../common/modal/form_modal";
import SettingsRegulationsAddNewTrigger from "../add_new_trigger";

import SettingsRegulationsAddNewContent from "./content";
import { invoke, isEmptyArray, isEmptyObject } from "../../../../../../../core/utils/misc_utils";
import { toastFor500, toastForCustomError, toastForSuccess } from "../../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../../data/actions/common/ui/toast";

const initialFormValues = {
    file: {},
    regulationPlaceCode: "",
    fromDate: ""
};

class SettingsRegulationsAddNew extends Component {

    state = {
        regulationPlaces: []
    };

    render = () => {
        const { regulationPlaces } = this.state;
        const { regulationAdd } = this.props;

        return (
            <Formik
                initialValues={ initialFormValues }
                render={ formikBag => (
                    <FormModal
                        triggerComponent={ <SettingsRegulationsAddNewTrigger/> }
                        isLoading={ regulationAdd?.meta?.loading }
                        overflow={ true }
                        saveOrCancelButtons={ true }
                        headerText="Dodaj regulamin"
                        _actionFunction={ () => {
                            return this._onSubmit(formikBag);
                        } }>

                        <SettingsRegulationsAddNewContent
                            formikBag={ formikBag }
                            regulationPlaces={ regulationPlaces }/>

                    </FormModal>
                ) }/>
        );
    };

    componentDidMount = () => {
        const { getRegulationPlaces } = this.props;

        getRegulationPlaces()
            .then(() => {
                const { regulationGetPlaces } = this.props;
                this.generateRegulationPlaces(regulationGetPlaces.data)
            });
    };

    generateRegulationPlaces = (regulationPlacesData) => {
        const selectOptions = _.map(regulationPlacesData, regulationPlace => {
            return {
                value: regulationPlace.code,
                label: regulationPlace.name
            };
        });

        this.setState({
            regulationPlaces: selectOptions
        });
    };

    _validate = (values) => {
        const errors = {};

        if (!values.regulationPlaceCode) {
            errors.regulationPlaceCode = "Pole wymagane";
        }

        if (isEmptyArray(values.fromDate)) {
            errors.fromDate = "Pole wymagane";
        }

        if (isEmptyObject(values.file)) {
            errors.file = "Dodaj plik";
        }

        return errors;
    };

    _onSubmit = (formikBag) => {
        const { addRegulation, _onSubmitCallback } = this.props;
        const errors = this._validate(formikBag.values);

        if (!isEmptyObject(errors)) {
            formikBag.setErrors(errors);
            return Promise.reject(null);
        }

        return addRegulation(formikBag.values)
            .then(() => invoke(_onSubmitCallback))
            .then(() => {
                const { showToast, regulationAdd } = this.props;

                if(regulationAdd.meta.status === 400){
                    toastForCustomError(showToast, "Nie można dodać kolejnego regulaminu tego samego typu.")
                }

                toastFor500(showToast, regulationAdd, "Regulamin nie został dodany. Spróbuj ponownie");
                toastForSuccess(showToast, regulationAdd, "Regulamin został dodany");

            });
    };
}

const mapStateToProps = (state) => {
    return {
        regulationGetPlaces: state.entities.common.regulations.getPlaces,
        regulationAdd: state.entities.common.regulations.add
    };
};

export default connect(mapStateToProps, { getRegulationPlaces, addRegulation, showToast })(SettingsRegulationsAddNew);
