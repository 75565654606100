import _ from "lodash";

import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

const innerGetManyLocationCandidatesTowns = (dispatch) => {
        dispatch({
            type: actions.wasteManagement.API__LOCATION_CANDIDATES_TOWNS__GET_MANY__STARTED
        });

        return api
            .waste_management
            .locationCandidates
            .getTowns()
            .then(response => {
                dispatch({
                    type: actions.wasteManagement.API__LOCATION_CANDIDATES_TOWNS__GET_MANY__SUCCESS,
                    meta: {},
                    payload: _.mapKeys(extractResponseData(response), "id"),
                    error: false
                });
            })
            .catch(error => {
                dispatch({
                    type: actions.wasteManagement.API__LOCATION_CANDIDATES_TOWNS__GET_MANY__FAIL,
                    meta: extractErrorData(error),
                    payload: null,
                    error: true
                });
            });

};

const debouncedInnerGetManyLocationCandidateTowns = _.debounce(innerGetManyLocationCandidatesTowns, 250, { leading: true });

export const getManyLocationCandidatesTowns = () => (dispatch, getState) => {
    return debouncedInnerGetManyLocationCandidateTowns(dispatch, getState());
};

export const getManyLocationCandidatesTownAddressesAndStreets = (townId) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__LOCATION_CANDIDATES_TOWN_ADDRESSES_AND_STREETS__GET_MANY__STARTED
    });

    return api
        .waste_management
        .locationCandidates
        .getTownAddressesAndStreets(townId)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__LOCATION_CANDIDATES_TOWN_ADDRESSES_AND_STREETS__GET_MANY__SUCCESS,
                meta: {},
                payload: _.mapKeys(extractResponseData(response), "id"),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__LOCATION_CANDIDATES_TOWN_ADDRESSES_AND_STREETS__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getManyLocationCandidatesStreetAddresses = (streetId) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__LOCATION_CANDIDATES_STREET_ADDRESSES__GET_MANY__STARTED
    });

    return api
        .waste_management
        .locationCandidates
        .getStreetAddresses(streetId)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__LOCATION_CANDIDATES_STREET_ADDRESSES__GET_MANY__SUCCESS,
                meta: {},
                payload: _.mapKeys(extractResponseData(response), "id"),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__LOCATION_CANDIDATES_STREET_ADDRESSES__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getManyLocationCandidatesStreetAddressesTo = (townId, streetId, from) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__LOCATION_CANDIDATES_STREET_ADDRESSES_TO__GET_MANY__STARTED
    });

    return api
        .waste_management
        .locationCandidates
        .getStreetAddressesTo(townId, streetId, from)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__LOCATION_CANDIDATES_STREET_ADDRESSES_TO__GET_MANY__SUCCESS,
                meta: {},
                payload: _.mapKeys(extractResponseData(response), "id"),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__LOCATION_CANDIDATES_STREET_ADDRESSES_TO__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};


export const getManyLocationCandidatesApartmentAddresses = (addressId) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__LOCATION_CANDIDATES_APARTMENT_ADDRESSS__GET_MANY__STARTED
    });

    return api
        .waste_management
        .locationCandidates
        .getApartmentAddresses(addressId)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__LOCATION_CANDIDATES_APARTMENT_ADDRESSS__GET_MANY__SUCCESS,
                meta: {},
                payload: _.mapKeys(extractResponseData(response), "id"),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__LOCATION_CANDIDATES_APARTMENT_ADDRESSS__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const resetLocationCandidates = (town = true, street = true, from = true, to = true, apartmentAddress = true) => {
    return {
        type: actions.wasteManagement.API_LOCATION_CANDIDATES_RESET,
        payload: {
            town,
            street,
            from,
            to,
            apartmentAddress
        }
    };
};
