import React, { Component } from "react";

import moment from "moment";
import striptags from "striptags";

import MessageHistoryDeleteForm from "../delete_modal_form";
import { ReactComponent as IconDelete } from "../../../../../../../../resource/img/icons/icon_delete.svg";
import { MessageEnum } from "../../../../../../../core/enums/waste_management/messages";
import {
    MISC_DATE_ISO_FORMAT,
    MISC_DATE_POLISH_FORMAT,
    ROUTE_MESSAGE_PREVIEW
} from "../../../../../../../core/constants";
import { replaceHtmlEntities } from "../../../../../../../core/utils/string_utils";
import { Redirect } from "react-router-dom";

class MessageHistoryListItem extends Component {

    state = {
        shouldRedirectToMessageDetails: false
    };

    render = () => {
        const { messages, cancelMessage } = this.props;

        if (this.state.shouldRedirectToMessageDetails) {
            return <Redirect to={ { pathname: ROUTE_MESSAGE_PREVIEW(messages.id), state: { messages: messages } }}/>;
        }

        return (
            <tr className="tr--clickable">
                <td className='t-width-2' onClick={ () => this.setState({ shouldRedirectToMessageDetails: true })}>

                    {
                        this.renderStatus(messages.sendingStatus)
                    }

                    <span className="notification__date">
                    {
                        moment(messages.requestedRealization, MISC_DATE_ISO_FORMAT)
                            .format(MISC_DATE_POLISH_FORMAT)
                    }
                </span>
                </td>
                <td className='t-width-3' onClick={ () => this.setState({ shouldRedirectToMessageDetails: true })}>
                    {
                        messages.type === MessageEnum.source.OPERATOR &&
                        `${ messages.employeeName } ${ messages.employeeSurname }`
                    }
                    {
                        messages.type === MessageEnum.source.AUTOMATIC &&
                        "Wiadomość automatyczna"
                    }
                </td>
                <td className='t-width-6' onClick={ () => this.setState({ shouldRedirectToMessageDetails: true })}>
                    <p className="table-text-content">
                        <span className="is-bold preceding-title">{ messages.title }</span>
                        {
                            replaceHtmlEntities(striptags(messages.body))
                        }
                    </p>
                </td>

                {
                    messages.sendingStatus === MessageEnum.sendingStatus.READY &&
                    <td className='sticky--right td-action-buttons'>
                        <MessageHistoryDeleteForm
                            messages={ messages }
                            cancelMessage={ cancelMessage }
                            triggerComponent={ <button className='unstyled-btn'><IconDelete/></button> }/>
                    </td>
                }

            </tr>
        );
    };

    renderStatus = (sendingStatus) => {
        switch (sendingStatus) {
            case MessageEnum.sendingStatus.CANCELED:
                return (
                    <div className="notification-status-wrapper">
                        <div className="notification-status notification-status--canceled"/>
                    </div>
                );

            case MessageEnum.sendingStatus.ERROR:
                return (
                    <div className="notification-status-wrapper">
                        <div className="notification-status notification-status--error"/>
                    </div>
                );

            default:
                return (
                    <div className="notification-status-wrapper">
                        <div className="notification-status notification-status--sent"/>
                    </div>
                )
        }
    };

}

export default MessageHistoryListItem;