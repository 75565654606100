import React, { useCallback, useMemo } from "react";

import _ from "lodash";
import { ReactComponent as IconSearch } from "../../../../../../../resource/img/icons/icon_search.svg";
import Header from "../../../../../common/header";
import Select from "../../../../../common/select";
import AddAndEditAddressTemplateForm from "./form/add_and_edit_form";
import { getManyAddressTemplates } from "../../../../../../data/actions/blisko/address_templates";
import { generatePaginationQueryValues } from "../../../../../../core/utils/api_utils";
import { useDispatch } from "react-redux";

const AddressTemplateHeader = ({ addressTemplateAuthors, state, setState }) => {
    const dispatch = useDispatch();

    const authorsOptions = useMemo(() => {
        return _.map(addressTemplateAuthors, status => {
            return {
                label: status.description,
                value: status.value
            };
        });
    }, [addressTemplateAuthors]);

    const _onSearchBarInputChange = useCallback(
        _.debounce(input => setState(prevState => {
            return { ...prevState, searchBarTerm: input || '' }
        }), 600),
        []
    );

    return (
        <>
            <Header>Szablon adresów</Header>
            <section className="main-content--table__top container is-fluid">
                <div className="row row--separated row--options flexible-height-wrap">
                    <div className="row__left is-v-centered wrapable">
                        <div className="input-field header__search with-icon--right options-item">
                            <input type='text'
                                   placeholder='Szukaj szablonu'
                                   onChange={ (event) => _onSearchBarInputChange(event.target.value) }
                            />
                            <IconSearch className='input-icon'/>
                        </div>
                        <div className="options-item is-v-centered">
                            <span className="is-bold is-spaced--right-10">Pokaż:</span>
                            <div className="is-v-centered">
                                <div className="input-size-l">
                                    <Select
                                        options={ authorsOptions }
                                        defaultValue={ authorsOptions[0] }
                                        isClearable={ false }
                                        onChange={ option => setState(prevState => {
                                            return { ...prevState, addressTemplateAuthor: option.value }
                                        }) }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row__right">
                        <AddAndEditAddressTemplateForm
                            _onSubmitCallback={ () => dispatch(getManyAddressTemplates({
                                ...generatePaginationQueryValues(0, 30, ["name", "asc"]),
                                query: state.searchBarTerm || '',
                                show: state.addressTemplateAuthor
                            })) }/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AddressTemplateHeader;