import React, { Component } from "react";
import { ReactComponent as IconPin } from "../../../../../../../../resource/img/icons/icon_pin.svg";
import _ from "lodash";

import DefineAreaBinItem from "./define_area_bin_item";

class DefineAreaItem extends Component {

    render = () => {
        const { area } = this.props;
        return (
            <div className="media">
                <div className="media-left">
                    <IconPin/>
                </div>
                <div className="media-content">
                    <p className="is-spaced--bottom-20">
                        {
                            area.name
                        }
                    </p>
                    <h3 className="title is-3">Wybierz zbierane odpady:</h3>
                    <span className="unstyled-btn is-uppercase f-12 define-options__check-all" onClick={ () => this._onCheckAllClicked() }>
                        Zaznacz wszystkie
                    </span>
                    <ul className="define-options__list columns is-multiline">
                        {
                            this.renderListItems()
                        }
                    </ul>
                </div>
            </div>
        );
    };

    renderListItems = () => {
        const { area, basicBins, _addOrRemoveCheckboxClass } = this.props;

        return _.map(basicBins, bin => {
            return (
                <DefineAreaBinItem
                    key={ bin.id }
                    bin={ bin }
                    checked={ _addOrRemoveCheckboxClass(area.id, bin.id) }
                    _onBinClick={ this._onBinClick }
                />
            );
        });
    };

    _onBinClick = (binId) => {
        const { area, _manageAssignedAreas } = this.props;
        _manageAssignedAreas(area.id, binId);
    };

    _onCheckAllClicked = () => {
        const { area, _manageAssignedAreas } = this.props;
        _manageAssignedAreas(area.id);
    };

}

export default DefineAreaItem;