import webClient from "../../../../core/web_client";

import { PATH as PATH_REPORTS } from "./index";

const PATH = "employee";

export const getManyReportEmployees = (reportId) => {
    return webClient
        .get(`${ PATH_REPORTS }/${ reportId }/${ PATH }`);
};

export const updateReportEmployee = (reportId, employeeId) => {
    return webClient
        .put(`${ PATH_REPORTS }/${ reportId }/${ PATH }/${ employeeId }`);
};