import * as api from "../../_api";

import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const addPasswordResetRequest = (email) => dispatch => {
    dispatch({
        type: actions.common.API__PASSWORD_RESET_REQUEST__ADD__STARTED
    });

    return api
        .common
        .passwordResetRequest
        .addPasswordResetRequest(email)
        .then(response => {

            dispatch({
                type: actions.common.API__PASSWORD_RESET_REQUEST__ADD__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {

            dispatch({
                type: actions.common.API__PASSWORD_RESET_REQUEST__ADD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const checkPasswordResetRequestTokenStatus = (token) => dispatch => {
    dispatch({
        type: actions.common.API__PASSWORD_RESET_REQUEST__TOKEN_CHECK__STARTED
    });

    api
        .common
        .passwordResetRequest
        .checkTokenStatus(token)
        .then(response => {
            dispatch({
                type: actions.common.API__PASSWORD_RESET_REQUEST__TOKEN_CHECK__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });

        })
        .catch(error => {
            dispatch({
                type: actions.common.API__PASSWORD_RESET_REQUEST__TOKEN_CHECK__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const updatePasswordResetRequest = (password, token) => dispatch => {
    dispatch({
        type: actions.common.API__PASSWORD_RESET_REQUEST__UPDATE__STARTED
    });

    return api
        .common
        .passwordResetRequest
        .updatePasswordResetRequest(password, token)
        .then(response => {
            dispatch({
                type: actions.common.API__PASSWORD_RESET_REQUEST__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });

        })
        .catch(error => {
            dispatch({
                type: actions.common.API__PASSWORD_RESET_REQUEST__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};