import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import ComponentWithDropdown from "../../../../../common/component_with_dropdown";
import WasteCollectionPointsCreateAndEditModalForm from "./create_and_edit_modal_form";
import {
    changeManyWasteCollectionPointStatuses,
    getManyWasteCollectionPoints
} from "../../../../../../data/actions/waste_management/waste_collection_points";

import { ReactComponent as IconEllipsis } from "../../../../../../../resource/img/icons/icon_ellipsis.svg";
import { ReactComponent as IconSearch } from "../../../../../../../resource/img/icons/icon_search.svg";
import { ReactComponent as IconCheckCircle } from "../../../../../../../resource/img/icons/icon_check_circle.svg";
import { ReactComponent as IconMapShow } from "../../../../../../../resource/img/icons/icon_map_show.svg";
import { isEmptyArray, isNullOrUndefined } from "../../../../../../core/utils/misc_utils";
import { WasteCollectionPointsEnum } from "../../../../../../core/enums/waste_management/waste_collection_points";
import { MISC_SHOW_UNFINISHED_ELEMENTS } from "../../../../../../core/constants";
import { toastFor500, toastForSuccess } from "../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../data/actions/common/ui/toast";

class WasteCollectionPointsHeader extends ComponentWithDropdown {

    render = () => {
        const { dropdownOpen = false } = this.state;
        const { searchBarTerm, getManyWasteCollectionPoints, wasteCollectionPoints, _onSearchBarInputChange } = this.props;

        const invisibleWasteCollectionPointIds = _
            .filter(wasteCollectionPoints.data, wasteCollectionPoint =>
                !isNullOrUndefined(wasteCollectionPoint.status) && wasteCollectionPoint.status.value === WasteCollectionPointsEnum.status.INVISIBLE.id)
            .map(invisibleWasteCollectionPoint => invisibleWasteCollectionPoint.id);

        return (
            <section className='main-content--table__top'>
                <header className='row page-header'>
                    <h1 className='title is-1'>
                        Punkty zbiórki
                    </h1>
                </header>
                <section className='container is-fluid'>
                    <div className="row row--separated row--options">
                        <div className='row__left scrollable'>
                            <div className="input-field header__search with-icon--right">
                                <input type='text' placeholder='Szukaj adresu punktu'
                                       value={ searchBarTerm }
                                       onChange={ event => _onSearchBarInputChange(event.target.value) }/>
                                <IconSearch className='input-icon'/>
                            </div>
                        </div>
                        <div className='row__right'>
                            <button className='btn btn--big btn--primary header__main-cta-btn'
                                    disabled={ isEmptyArray(invisibleWasteCollectionPointIds) || !!(wasteCollectionPoints.meta.loading) }
                                    onClick={ () => this._onPublishClick(invisibleWasteCollectionPointIds) }>
                                <span className="btn__icon-wrapper--left">
                                    <span className='btn__icon'>
                                        <IconCheckCircle/>
                                    </span>
                                    Opublikuj
                                </span>
                            </button>

                            <WasteCollectionPointsCreateAndEditModalForm
                                triggerText="Dodaj punkt zbiórki"
                                triggerClass="btn btn--outlined"
                                _onSubmitCallback={ getManyWasteCollectionPoints }/>


                            {
                                MISC_SHOW_UNFINISHED_ELEMENTS &&
                                <div className={ `dropdown  ${ dropdownOpen ? "is-active" : null }` }>
                                    <div className="dropdown-trigger">
                                        <button className="btn btn--outlined btn--icon" title='Więcej'
                                                onClick={ this._onDropdownOpen }>
                                            <IconEllipsis/>
                                        </button>
                                    </div>
                                    <div className="dropdown-menu dropdown-size-m drop-to-right">
                                        <div className="dropdown-content">
                                            <span className="unstyled-btn dropdown-item">Importuj szablon</span>
                                            <span className="unstyled-btn dropdown-item">Pobierz szablon</span>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </section>

                {
                    MISC_SHOW_UNFINISHED_ELEMENTS &&
                    <section className='container is-fluid'>
                        <div className="side-map-toggle row--end">
                            <button className="btn btn--narrow is-uppercase">
                            <span className="btn__icon-wrapper--right">
                                Pokaż mapę
                                <span className='btn__icon'>
                                    <IconMapShow/>
                                </span>
                            </span>
                            </button>
                        </div>
                    </section>
                }

            </section>
        );
    };

    _onPublishClick = (invisibleWasteCollectionPointIds) => {
        const { getManyWasteCollectionPoints, changeManyWasteCollectionPointStatuses, wasteCollectionPointsPublish, showToast } = this.props;

        return changeManyWasteCollectionPointStatuses(invisibleWasteCollectionPointIds, WasteCollectionPointsEnum.status.VISIBLE.id)
            .then(() => getManyWasteCollectionPoints())
            .then(() => {
                toastFor500(showToast, wasteCollectionPointsPublish, "Publikacja nie powiodła się. Spróbuj ponownie później");
                toastForSuccess(showToast, wasteCollectionPointsPublish, "Informacje zostały opublikowane");
            });
    }
}

const mapStateToProps = (state) => {
    return {
        wasteCollectionPointsPublish: state.entities.wasteManagement.wasteCollectionPoints.changeMany
    };
};

export default connect(mapStateToProps, { getManyWasteCollectionPoints, changeManyWasteCollectionPointStatuses, showToast })(WasteCollectionPointsHeader);
