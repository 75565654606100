import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    addAddressTemplateToMessageAddresses,
    addUnitsRangeToMessageAddresses,
    addUnitsToMessageAddresses,
    getManyMessageAddresses,
    removeUnitsFromMessageAddresses
} from "../../../../../../../../data/actions/blisko/message_addresses";
import Radio from "../../../../../../../common/radio";
import LocationForm, { LocationFormTypeEnum } from "../../../../../../../common/location_form";
import ChosenAddresses from "./chosen";
import TitleWithBottomDivider from "../../../../../../../common/title_with_bottom_divider";
import AddressTemplateAddresses from "./address_template";
import {
    addAddressTemplateToDraftMessageAddresses,
    addUnitsRangeToDraftMessageAddresses,
    addUnitsToDraftMessageAddresses,
    getManyDraftMessageAddresses,
    removeUnitsFromDraftMessageAddresses
} from "../../../../../../../../data/actions/blisko/draft_message_addresses";

const Addresses = ({ editDraftMode, draftMessageId, useLocationFilter, _useLocationFilterChange }) => {
    const dispatch = useDispatch();
    const messageAddresses = useSelector(state => state.entities?.blisko?.messageAddresses?.getMany);
    const draftMessageAddresses = useSelector(state => state.entities?.blisko?.draftMessageAddresses?.getMany);
    const [selectedAddressValues, setSelectedAddressValues] = useState();

    useEffect(() => {
        if (!editDraftMode) {
            dispatch(getManyMessageAddresses())
        }
    }, [dispatch, editDraftMode]);

    const dispatchAddAddressAction = useCallback(values => {
        if (!values) {
            return null;
        }

        if (values.selectedTo && values.selectedFrom) {
            return editDraftMode
                ? dispatch(addUnitsRangeToDraftMessageAddresses(draftMessageId, values.selectedFrom.id, values.selectedTo.id))
                : dispatch(addUnitsRangeToMessageAddresses(values.selectedFrom.id, values.selectedTo.id));
        }

        const unitId = (values.selectedFrom || values.selectedStreet || values.selectedTown)?.id;
        if (unitId) {
            return editDraftMode
                ? dispatch(addUnitsToDraftMessageAddresses(draftMessageId, [unitId]))
                : dispatch(addUnitsToMessageAddresses([unitId]));
        }

        return null;

    }, [dispatch, editDraftMode, draftMessageId]);

    return (
        <section className="media">
            <div className="media-left step-count-wrap">
                <div className="step-count">
                    <span>3/4</span>
                </div>
            </div>
            <div className="media-content">
                <div className="message-sending-1__section">

                    <div className="message-sending-1__subtitle message-sending-1__subtitle--bottom-15">
                        <TitleWithBottomDivider>
                            Adresy odbiorców
                        </TitleWithBottomDivider>
                    </div>

                    <div className="message-sending-1__subsection">
                        <label className="message-sending-1__toggle message-sending-1__toggle--bottom-5">

                            <Radio
                                name="useLocationFilter"
                                value={ false }
                                groupValue={ useLocationFilter }
                                ariaLabel="Wyślij do wszystkich odbiorców"
                                onInputChange={ _useLocationFilterChange }/>

                            <span>Wyślij do wszystkich odbiorców</span>
                        </label>
                        <label className="message-sending-1__toggle">

                            <Radio
                                name="useLocationFilter"
                                value={ true }
                                groupValue={ useLocationFilter }
                                ariaLabel="Wyślij na adres"
                                onInputChange={ _useLocationFilterChange }/>

                            <span>Wyślij na adres</span>
                        </label>
                    </div>

                    <div className="message-sending-1__subsection">
                        {
                            useLocationFilter &&
                            <>
                                <div className="message-sending-1__address" role="group">

                                    <div className='is-flex flex-grow-1 align-end'>
                                        <LocationForm
                                            horizontal={ true }
                                            formType={ LocationFormTypeEnum.WITH_BUILDING_NUMBERS_RANGE }
                                            _onSelect={ values => {
                                                setSelectedAddressValues(values);
                                            } }
                                            _onChange={ () => {
                                                setSelectedAddressValues(undefined);
                                            } }
                                        />
                                        <button
                                            disabled={ !selectedAddressValues }
                                            className='btn btn--primary btn--medium is-spaced--left-20'
                                            type="button"
                                            onClick={ () => dispatchAddAddressAction(selectedAddressValues)
                                                .then(() => {
                                                    editDraftMode
                                                        ? dispatch(getManyDraftMessageAddresses(draftMessageId))
                                                        : dispatch(getManyMessageAddresses())
                                                })
                                            }
                                        >
                                            Dodaj do listy
                                        </button>
                                    </div>

                                    <AddressTemplateAddresses
                                        _onAddressTemplateAdd={ addressTemplateId => {
                                            editDraftMode
                                                ? dispatch(addAddressTemplateToDraftMessageAddresses(draftMessageId, addressTemplateId))
                                                    .then(() => dispatch(getManyDraftMessageAddresses(draftMessageId)))
                                                : dispatch(addAddressTemplateToMessageAddresses(addressTemplateId))
                                                    .then(() => dispatch(getManyMessageAddresses()))
                                        } }
                                    />
                                </div>

                                <ChosenAddresses
                                    messageAddresses={ editDraftMode ? draftMessageAddresses?.data : messageAddresses?.data }
                                    _onLocationRemove={ location => {
                                        editDraftMode
                                            ? dispatch(removeUnitsFromDraftMessageAddresses(draftMessageId, [
                                                ...location.addresses.map(address => address.id),
                                                location.parent.id
                                            ])).then(() => dispatch(getManyDraftMessageAddresses(draftMessageId)))
                                            : dispatch(removeUnitsFromMessageAddresses([
                                                ...location.addresses.map(address => address.id),
                                                location.parent.id
                                            ])).then(() => dispatch(getManyMessageAddresses()))
                                    } }
                                />
                            </>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Addresses;