import React, { Component } from "react";
import { connect } from "react-redux";

import SideListLayout from "../../../../common/side_list_layout/side_list_layout";
import ReportDetails from "./details";
import CreateAndEditReportModalForm from "./create_modal_form";
import {
    getManyReports,
    getReport,
    sendStatusChangeConfirmation,
    updateReportStatus
} from "../../../../../data/actions/waste_management/reports";
import { getReportTypes } from "../../../../../data/actions/waste_management/reports/types";
import { addReportMessage, getManyReportMessages } from "../../../../../data/actions/waste_management/reports/messages";
import {
    getManyReportEmployees,
    updateReportEmployee
} from "../../../../../data/actions/waste_management/reports/emplyees";
import { showToast } from "../../../../../data/actions/common/ui/toast";
import { getManyBins } from "../../../../../data/actions/waste_management/bins";
import { toastFor500 } from "../../../../../core/utils/toast_utils";

import selectReportImg from "../../../../../../resource/img/icons/empty_list_icons/wybierz_zgloszenie.png";
import noReportsImg from "../../../../../../resource/img/icons/empty_list_icons/brak_zgloszen.png";
import { ReportEnum } from "../../../../../core/enums/waste_management/reports";
import withPagination from "../../../../common/hoc/with_pagination";
import Loader from "../../../../common/loader";
import { isNullOrUndefined } from "../../../../../core/utils/misc_utils";
import { generatePaginationQueryValues } from "../../../../../core/utils/api_utils";

class Reports extends Component {

    state = {
        selectedReportId: null
    };

    defaultPaginationQueryValues = generatePaginationQueryValues(0, 30, ["createdDate", "desc"]);

    render = () => {
        const { reports, bins, _onPrevClick, _onNextClick, _onPageNumberInputValueChange } = this.props;
        const { selectedReportId } = this.state;

        if (isNullOrUndefined(reports.data)) {
            return <Loader/>;
        }

        return (
            <article className="reports">
                <header className="row page-header">
                    <h1 className="title is-1">
                        Zgłoszenia mieszkańców
                    </h1>
                </header>
                <div className="container is-fluid">
                    <div className="row row--separated row--options">
                        <div className="row__left">
                        </div>
                        <div className="row__right">
                            <CreateAndEditReportModalForm
                                bins={ bins }
                                defaultPaginationQueryValues={ this.defaultPaginationQueryValues }/>
                        </div>
                    </div>
                </div>
                <SideListLayout
                    data={ reports.data }
                    list={ reports.data.content }
                    selectedListItem={ selectedReportId }
                    _onListItemClick={ this._onReportClick }
                    listType={ ReportEnum.name }
                    renderIconFunction={ this.renderReportTypeIcon }

                    _onPrevClick={ _onPrevClick }
                    _onNextClick={ _onNextClick }
                    _onPageNumberInputValueChange={ _onPageNumberInputValueChange }

                    noListText="Brak zgłoszeń"
                    noListImg={ noReportsImg }
                    selectItemText="Wybierz zgłoszenie"
                    selectItemImg={ selectReportImg }>
                    {
                        this.renderDetails()
                    }
                </SideListLayout>
            </article>
        );
    };

    componentDidMount = () => {
        const { getManyReports, getManyBins, currentPage, setMaxPage, getReportTypes } = this.props;
        getManyBins();
        getReportTypes();
        getManyReports({ ...this.defaultPaginationQueryValues, page: currentPage })
            .then(() => {
                    setMaxPage(this.props.reports.data.totalPages);
                }
            );
    };

    shouldComponentUpdate = (nextProps, nextState) => {
        const { currentPage, reports, getManyReports } = this.props;

        if (nextProps.currentPage !== currentPage) {
            getManyReports({ ...this.defaultPaginationQueryValues, page: nextProps.currentPage });
            return true;
        }

        if (nextProps.reports !== reports) {
            return true;
        }

        if (nextState !== this.state) {
            return true;
        }

        if (nextProps !== this.props) {
            return true;
        }

        return false;
    };

    renderDetails = () => {
        const { employees, bins, statuses, selectedReport, selectedReportResponses } = this.props;
        const { selectedReportId } = this.state;

        return (
            <ReportDetails
                employees={ employees }
                statuses={ statuses }
                bins={ bins }
                selectedReportId={ selectedReportId }
                selectedReport={ selectedReport }
                selectedReportResponses={ selectedReportResponses }
                _onEmployeeChange={ this._onEmployeeChange }
                _onStatusChange={ this._onStatusChange }
                _onSendResponseClick={ this._onSendResponseClick }
                _onSendStatusChangeConfirmation={ this._onSendStatusChangeConfirmation }
                _onClearSelectedReport={ this._onClearSelectedReport }/>
        );

    };

    renderReportTypeIcon = (listItem, listType) => {

        if (listType === ReportEnum.name) {
            switch (listItem.kind.code) {
                case ReportEnum.type.UNCOLLECTED_WASTE.code.toLowerCase():
                    return ReportEnum.type.UNCOLLECTED_WASTE.icon;

                case ReportEnum.type.WILD_DUMP.code.toLowerCase():
                    return ReportEnum.type.WILD_DUMP.icon;

                case ReportEnum.type.OTHER.code.toLowerCase():
                    return ReportEnum.type.OTHER.icon;

                case ReportEnum.type.ORDER_CONTAINER.code.toLowerCase():
                    return ReportEnum.type.ORDER_CONTAINER.icon;

                case ReportEnum.type.ORDER_TRASHBAG.code.toLowerCase():
                    return ReportEnum.type.ORDER_TRASHBAG.icon;

                case ReportEnum.type.ORDER_BIN.code.toLowerCase():
                    return ReportEnum.type.ORDER_BIN.icon;

                case ReportEnum.type.SMOKE.code.toLowerCase():
                    return ReportEnum.type.SMOKE.icon;

                default:
                    return "";
            }
        }
    };

    _onReportClick = (id) => {
        const { getReport, getManyReportEmployees, getManyReportMessages, getManyReports, currentPage } = this.props;

        return this.setState({
                selectedReportId: id,
            }, () => {
                getManyReportEmployees(id);
                getManyReportMessages(id);
                getReport(id)
                    .then(() => getManyReports({ ...this.defaultPaginationQueryValues, page: currentPage }));
            }
        );
    };

    _onEmployeeChange = (reportId, employeeId) => {
        const { getReport, updateReportEmployee, getManyReports, currentPage } = this.props;

        return updateReportEmployee(reportId, employeeId)
            .then(() => getReport(reportId))
            .then(() => getManyReports({ ...this.defaultPaginationQueryValues, page: currentPage }));
    };

    _onStatusChange = (reportId, statusCode) => {
        const { getReport, updateReportStatus, getManyReports, currentPage } = this.props;

        return updateReportStatus(reportId, statusCode)
            .then(() => getReport(reportId))
            .then(() => getManyReports({ ...this.defaultPaginationQueryValues, page: currentPage }));
    };

    _onSendResponseClick = (reportId, message) => {
        const { addReportMessage, getManyReportMessages } = this.props;

        return addReportMessage(reportId, { message })
            .then(() => getManyReportMessages(reportId));
    };

    _onSendStatusChangeConfirmation = (reportId) => {
        const { sendStatusChangeConfirmation, statusConfirmation, getReport } = this.props;

        return sendStatusChangeConfirmation(reportId)
            .then(() => getReport(reportId))
            .then(() => {
                toastFor500(showToast, statusConfirmation, "Wysyłka powiadomienia nie powiodła się. Spróbuj ponownie");
            });
    };

    _onClearSelectedReport = () => {
        return this.setState({
            selectedReportId: null,
        });
    };
}

const mapStateToProps = (state) => {
    return {
        reports: state.entities.wasteManagement.reports.getMany,
        bins: state.entities.wasteManagement.bins.getMany,
        employees: state.entities.wasteManagement.reports.employees.getMany,
        statuses: state.entities.wasteManagement.reports.statuses.getMany,
        selectedReport: state.entities.wasteManagement.reports.get,
        selectedReportResponses: state.entities.wasteManagement.reports.messages.getMany,
        statusConfirmation: state.entities.wasteManagement.reports.sendStatusChangeConfirmation
    };
};

export default withPagination(connect(mapStateToProps, {
    getManyReports,
    getReportTypes,
    getReport,
    updateReportStatus,
    addReportMessage,
    updateReportEmployee,
    getManyReportEmployees,
    getManyReportMessages,
    getManyBins,
    sendStatusChangeConfirmation,
    showToast
})(Reports));
