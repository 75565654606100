import { deepFreeze } from "../../utils/misc_utils";

export const PasswordChangeEnum = deepFreeze({
    errorCode: {
        INVALID_OLD_PASSWORD: "400.3",
        NEW_PASSWORD_EQUAL_TO_OLD_PASSWORD: "400.4",
        PASSWORD_TOO_SHORT: "400.5",
        CAPITAL_LETTER_MISSING: "400.6",
        SMALL_LETTER_MISSING: "400.7",
        NUMBER_OR_SPECIAL_SIGN_MISSING: "400.8",
    }
});