import webClient from "../../../core/web_client";
import { API_BASE_AKUN_KU_PATH } from "../../../core/constants";

import { PATH as PATH_CATEGORIES } from "./categories";

const PATH = `${ API_BASE_AKUN_KU_PATH }/service-cards`;
const PATH_ALT = "service-cards";
const PATH_ISSUE_HANDLE_SERVICE_CARDS = `${ API_BASE_AKUN_KU_PATH }/service-cards`;

/** @deprecated **/
const PATH_SERVICE_SERVICE_CARDS = PATH_ISSUE_HANDLE_SERVICE_CARDS;

export const getManyServiceCards = () => {
    return webClient
        .get(`${ PATH }`);
};

export const getServiceCard = (serviceCardId) => {
    return webClient
        .get(`${ PATH }/${ serviceCardId }`)
};

export const getManyServiceCardsForCategory = (categoryId) => {
    return webClient
        .get(`${ PATH_CATEGORIES }/${ categoryId }/${ PATH_ALT }`);
};

export const deleteServiceCard = (serviceCardId) => {
    return webClient
        .delete(`${ PATH }/${ serviceCardId }`);
};

export const publishServiceCard = (serviceCardId) => {
    return webClient
        .patch(`${ PATH }/${ serviceCardId }`, { publish: {} });
};

export const hideServiceCard = (serviceCardId) => {
    return webClient
        .patch(`${ PATH }/${ serviceCardId }`, { hide: {} });
};

export const addServiceServiceCard = (data) => {
    return webClient
        .post(`${ PATH_SERVICE_SERVICE_CARDS }`, data);
};

export const updateServiceServiceCard = (serviceCardId, data) => {
    return webClient
        .put(`${ PATH_SERVICE_SERVICE_CARDS }/${ serviceCardId }`, data);
};

export const addIssueHandleServiceCard = (data) => {
    return webClient
        .post(`${ PATH_ISSUE_HANDLE_SERVICE_CARDS }`, data);
};

export const updateIssueHandleServiceCard = (serviceCardId, data) => {
    return webClient
        .put(`${ PATH_ISSUE_HANDLE_SERVICE_CARDS }/${ serviceCardId }`, data);
};
