import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData, exportFile } from "../../../core/utils/api_utils";


export const getRegulationPlaces = () => dispatch => {
    dispatch({
        type: actions.common.API__REGULATION_PLACES__GET__STARTED
    });

    return api
        .common
        .regulations
        .getRegulationPlaces()
        .then(response => {
            dispatch({
                type: actions.common.API__REGULATION_PLACES__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__REGULATION_PLACES__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getRegulations = () => dispatch => {
    dispatch({
        type: actions.common.API__REGULATIONS__GET__STARTED
    });

    return api
        .common
        .regulations
        .getRegulations()
        .then(response => {
            dispatch({
                type: actions.common.API__REGULATIONS__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__REGULATIONS__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const addRegulation = (data) => dispatch => {
    dispatch({
        type: actions.common.API__REGULATIONS__ADD__STARTED
    });

    return api
        .common
        .regulations
        .addRegulation(data)
        .then(response => {
            dispatch({
                type: actions.common.API__REGULATIONS__ADD__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__REGULATIONS__ADD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const deleteRegulation = (id) => dispatch => {
    dispatch({
        type: actions.common.API__REGULATIONS__DELETE__STARTED
    });

    return api
        .common
        .regulations
        .deleteRegulation(id)
        .then(response => {
            dispatch({
                type: actions.common.API__REGULATIONS__DELETE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__REGULATIONS__DELETE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const downloadRegulation = (id, name) => dispatch => {
    dispatch({
        type: actions.common.API__REGULATIONS__DOWNLOAD__STARTED
    });

    return api
        .common
        .regulations
        .downlowadRegulation(id)
        .then(response => {
            dispatch({
                type: actions.common.API__REGULATIONS__DOWNLOAD__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });

            exportFile(response.data, name)
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__REGULATIONS__DOWNLOAD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};