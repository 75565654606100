import React, { useState } from "react";

import moment from "moment";
import { MISC_DATE_ISO_DATE_ONLY_FORMAT } from "../../../../../../core/constants";
import SentMessagesList from "./list"
import SentMessagesHeader from "./header";
import { deepFreeze } from "../../../../../../core/utils/misc_utils";

const sendingStatuses = deepFreeze({
    ALL: {
        value: "ALL",
        description: "Wszystkie"
    },
    READY: {
        value: "READY",
        description: "Zaplanowane"
    },
    SENT: {
        value: "SENT",
        description: "Wysłane"
    },
    CANCELED: {
        value: "CANCELED",
        description: "Anulowane"
    },
    ERROR: {
        value: "ERROR",
        description: "Błędne wysyłki"
    }
});

const SentMessages = () => {
    const [state, setState] = useState({
        searchBarTerm: '',
        from: moment().subtract(1, "weeks").format(MISC_DATE_ISO_DATE_ONLY_FORMAT),
        to: moment().format(MISC_DATE_ISO_DATE_ONLY_FORMAT),
        sendingStatus: sendingStatuses.ALL.value
    });

    return (
        <>
            <SentMessagesHeader
                sendingStatuses={ sendingStatuses }
                state={ state }
                setState={ setState }
            />
            <SentMessagesList
                state={ state }
            />
        </>
    )
};

export default SentMessages;