import React from "react";
import { useSelector } from "react-redux";

const ReceiverGroups = ({ receiverGroupIds }) => {
    const receiverGroups = useSelector(state => state.entities?.blisko?.receiverGroups?.getMany);

    return (
        <div className="notifications-2__row notifications-2__row--flex">
            <p className="notifications-2__label">Serwisy publiczne:</p>
            <div className="notifications-2__box notifications-2__box--list">
                <ul className="notifications-2__list">
                    {
                        receiverGroups?.data?.content?.map(receiverGroup => {
                            return receiverGroupIds.indexOf(receiverGroup.id) >= 0 ?
                                <li key={ receiverGroup.id } className="notifications-2__list-item">
                                    { receiverGroup.name }
                                </li> :
                                null
                        })
                    }
                </ul>
            </div>
        </div>
    )
};

export default ReceiverGroups;