/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";

const CheckboxGroup = ({ options, onChange, disabledValues = [], defaultValues = []}) => {
    const [checkedValues, setCheckedValues] = useState([]);

    const enabledOptions = options.filter(option => !disabledValues.includes(option.value));
    const allChecked = checkedValues.length === enabledOptions.length;

    useEffect(() => {
        setCheckedValues(checkedValues.filter(value => !disabledValues.includes(value)));
    }, [...disabledValues]);

    useEffect(() => {
        setCheckedValues(defaultValues);
    }, []);

    useEffect(() => {
        onChange && onChange(options.filter(option => checkedValues.includes(option.value)))
    }, [checkedValues.length])

    return (
        <div>
            <div
                className='is-clickable checkbox-group__select-all-btn unstyled-btn is-uppercase'
                onClick={ () => allChecked ? setCheckedValues([]) : setCheckedValues(enabledOptions.map(option => option.value)) }
            >
                { allChecked ? 'ODZNACZ' : 'ZAZNACZ' } WSZYSTKIE
            </div>
            {
                options.map(option => (
                    <div key={ option.value } className='is-spaced--top-10'>
                        <Checkbox
                            key={ option.value }
                            checked={ checkedValues.includes(option.value) }
                            label={ option.label }
                            description={ option.description }
                            disabled={ disabledValues.includes(option.value) }
                            onChange={ checked => {
                                if (disabledValues.includes(option.value)) {
                                    return;
                                }
                                if (!checked) {
                                    const newCheckedValues = [...checkedValues];
                                    newCheckedValues.splice(checkedValues.indexOf(option.value), 1)
                                    setCheckedValues(newCheckedValues)
                                } else {
                                    setCheckedValues([...checkedValues, option.value])
                                }
                            } }
                        />
                    </div>
                ))
            }

        </div>
    );
}
export default CheckboxGroup;


const Checkbox = ({ label, description, onChange, value, checked, disabled }) => {
    return (
        <div>
            <label className={ `acceptance-label ${ checked ? " label--checked" : "" } ${ disabled ? 'acceptance-label--disabled' : '' }` }>
                <input
                    type='checkbox'
                    className='acceptance-checkbox'
                    value={ value }
                    checked={ checked }
                    disabled={ disabled }
                    onChange={ ({ target }) => onChange(target.checked) }
                />
                <div>
                    <span className="acceptance-text">
                        {
                            label
                        }
                     </span>
                    {
                        description &&
                        <span className="acceptance-secondary-text">
                            {
                                description
                            }
                        </span>
                    }
                </div>
            </label>
        </div>
    );
};

