import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AreaAddresses from "./area";
import ChosenAddresses from "./chosen";
import TitleWithDivider from "../../../../../../../common/title_with_divider";
import Radio from "../../../../../../../common/radio";
import {
    addAreaToMessageAddresses,
    addUnitsRangeToMessageAddresses,
    addUnitsToMessageAddresses,
    getMessageAddresses,
    removeUnitsFromMessageAddresses
} from "../../../../../../../../data/actions/waste_management/message_addresses";
import LocationForm, { LocationFormTypeEnum } from "../../../../../../../common/location_form";

const Addresses = ({useLocationFilter, _useLocationFilterChange}) => {
    const dispatch = useDispatch();
    const messageAddresses = useSelector(state => state.entities?.wasteManagement?.messageAddresses?.getMany);
    const [selectedAddressValues, setSelectedAddressValues] = useState();

    useEffect(() => {
        dispatch(getMessageAddresses())
    }, [dispatch])

    const dispatchAddAddressAction = useCallback(values => {
        if (!values) {
            return null;
        }

        if (values.selectedTo && values.selectedFrom) {
            return dispatch(addUnitsRangeToMessageAddresses(values.selectedFrom.id, values.selectedTo.id));
        }

        const unitId = (values.selectedFrom || values.selectedStreet || values.selectedTown)?.id;
        if (unitId) {
            return dispatch(addUnitsToMessageAddresses([unitId]));
        }

        return null;

    }, [dispatch]);

    return (
        <section className="media">
            <div className="media-left step-count-wrap">
                <div className="step-count">
                    <span>2/3</span>
                </div>
            </div>
            <div className="media-content">
                <div className="message-sending-1__section">

                    <div className="message-sending-1__subtitle message-sending-1__subtitle--bottom-15">
                        <TitleWithDivider>
                            Wybierz adresy odbiorców
                        </TitleWithDivider>
                    </div>

                    <div className="message-sending-1__subsection">
                        <label className="message-sending-1__toggle message-sending-1__toggle--bottom-5">

                            <Radio
                                name="useLocationFilter"
                                value={ false }
                                groupValue={ useLocationFilter }
                                ariaLabel="Wyślij do wszystkich odbiorców"
                                onInputChange={ _useLocationFilterChange }/>

                            <span>Wyślij do wszystkich odbiorców</span>
                        </label>
                        <label className="message-sending-1__toggle">

                            <Radio
                                name="useLocationFilter"
                                value={ true }
                                groupValue={ useLocationFilter }
                                ariaLabel="Wyślij na adres"
                                onInputChange={ _useLocationFilterChange }/>

                            <span>Wyślij na adres</span>
                        </label>
                    </div>

                    <div className="message-sending-1__subsection">
                        {
                            useLocationFilter &&
                            <>
                                <div className="message-sending-1__address" role="group">

                                    <div className='is-flex flex-grow-1 align-end'>
                                        <LocationForm
                                            horizontal={ true }
                                            formType={ LocationFormTypeEnum.WITH_BUILDING_NUMBERS_RANGE }
                                            _onSelect={ values => {
                                                setSelectedAddressValues(values);
                                            } }
                                            _onChange={ () => {
                                                setSelectedAddressValues(undefined);
                                            } }
                                        />
                                        <button
                                            disabled={ !selectedAddressValues }
                                            className='btn btn--primary btn--medium is-spaced--left-20'
                                            type="button"
                                            onClick={ () => dispatchAddAddressAction(selectedAddressValues)
                                                .then(() => dispatch(getMessageAddresses()))
                                            }
                                        >
                                            Dodaj do listy
                                        </button>
                                    </div>


                                    <AreaAddresses
                                        _onAreaAdd={ areaId => dispatch(addAreaToMessageAddresses(areaId))
                                            .then(() => dispatch(getMessageAddresses()))
                                        }
                                    />

                                </div>

                                <ChosenAddresses
                                    messageAddresses={ messageAddresses?.data }
                                    _onLocationRemove={
                                        location => dispatch(removeUnitsFromMessageAddresses([
                                            ...location.addresses.map(address => address.id),
                                            location.parent.id
                                        ])).then(() => dispatch(getMessageAddresses()))
                                    }
                                />
                            </>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Addresses;