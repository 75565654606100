import React from "react";

const AreaLocationDeleteModalFormContent = ({ binName }) => {

    return (
        <div className='c-modal__body modal-size-m modal--no-header'>
            <p className="confirmation-alert">
                Czy na pewno chcesz usunąć <span className="is-bold">{ binName }</span>?
            </p>
            <br/>
            <p>
                Usunięcie spowoduje wyłączenie powiadomień o wywozie tej frakcji <span className="no-wrap">u użytkowników</span> końcowych.
            </p>
        </div>
    );
};

export default AreaLocationDeleteModalFormContent;

