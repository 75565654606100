import React from "react";
import moment from "moment";
import { UserRecordsEnum } from "../../../../../core/enums/common/logs";


import { ReactComponent as IconStatusActive } from "../../../../../../resource/img/icons/status/icon_done.svg";
import { ReactComponent as IconStatusRejected } from "../../../../../../resource/img/icons/status/icon_rejected.svg";


const UserConsentsItem = ({ userConsent }) => {

    return (
        <tr>
            <td className='t-width-3 td--column'>
                <div className="mw-100">
                    <p className="table-text-content" title={ userConsent.name }>
                        {
                            userConsent.user
                        }
                    </p>
                </div>
                <p className="secondary-text secondary-text--under-ellipsis">
                    Kanał: { userConsent.chanel }
                </p>
            </td>
            <td className='t-width-2'>
                <p className="table-text-content">
                    {
                        userConsent.module
                    }
                </p>
            </td>
            <td className='t-width-2'>
                {
                    renderActionType(userConsent.action)
                }
            </td>
            <td className='t-width-2'>
                <p>
                    {
                        moment(userConsent.dateOfAction)
                            .format("DD.MM.YYYY")
                    }
                </p>
            </td>
            <td className='t-width-3 td--column'>
                <p className="table-text-content">
                    {
                        userConsent.regulationType
                    }
                </p>
                <p className="secondary-text secondary-text--under-ellipsis">
                    Obowiązuje od: { moment(userConsent.regulationFromDate)
                    .format("DD.MM.YYYY") }
                </p>
            </td>
        </tr>
    );
};

const renderActionType = (action) => {

    switch (action) {
        case UserRecordsEnum.action.REGISTRATION :
            return (
                <>
                    <IconStatusActive className="status-icon"/>
                    Akceptacja
                </>
            );
        case UserRecordsEnum.action.UNSUBSCRIBE :
            return (
                <>
                    <IconStatusRejected className="status-icon"/>
                    Rezygnacja
                </>
            );
        case UserRecordsEnum.action.ACCEPTANCE :
            return "Akceptacja";
        default:
            return;

    }

};

export default UserConsentsItem;
