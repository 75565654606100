import _ from "lodash";

import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const getManyWasteCollectionPoints = () => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__WASTE_COLLECTION_POINTS__GET_MANY__STARTED
    });

    return api
        .waste_management
        .wasteCollectionPoints
        .getManyWasteCollectionPoints(null)
        .then(response => {
            const data = extractResponseData(response);
            const order = data.map(element => element.id);

            dispatch({
                type: actions.wasteManagement.API__WASTE_COLLECTION_POINTS__GET_MANY__SUCCESS,
                meta: { order },
                payload: _.mapKeys(data, "id"),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__WASTE_COLLECTION_POINTS__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const addWasteCollectionPoint = (data) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__WASTE_COLLECTION_POINTS__ADD__STARTED
    });

    return api
        .waste_management
        .wasteCollectionPoints
        .addWasteCollectionPoint(data)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__WASTE_COLLECTION_POINTS__ADD__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__WASTE_COLLECTION_POINTS__ADD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const updateWasteCollectionPoint = (id, data) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__WASTE_COLLECTION_POINTS__UPDATE__STARTED
    });

    return api
        .waste_management
        .wasteCollectionPoints
        .updateWasteCollectionPoint(id, data)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__WASTE_COLLECTION_POINTS__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__WASTE_COLLECTION_POINTS__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const changeManyWasteCollectionPointStatuses = (idList, status) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__WASTE_COLLECTION_POINTS__PATCH_MANY_STATUSES__STARTED
    });

    const data = idList.map(id => {
        return {
            id,
            status
        };
    });

    return api
        .waste_management
        .wasteCollectionPoints
        .patchManyWasteCollectionPoints(data)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__WASTE_COLLECTION_POINTS__PATCH_MANY_STATUSES__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__WASTE_COLLECTION_POINTS__PATCH_MANY_STATUSES__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const deleteWasteCollectionPoint = (id) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__WASTE_COLLECTION_POINTS__DELETE__STARTED
    });

    return api
        .waste_management
        .wasteCollectionPoints
        .deleteWasteCollectionPoint(id)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__WASTE_COLLECTION_POINTS__DELETE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__WASTE_COLLECTION_POINTS__DELETE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};