import { actions } from "../../../core/constants";
import * as api from "../../_api";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const getManyAddressGroups = (queryParams) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_ADDRESSES__GET_GROUPS__STARTED
    });

    return api
        .ekostraznik
        .addressGroups
        .getMany(queryParams)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__GET_GROUPS__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__GET_GROUPS__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getAllAddressGroups = () => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_ADDRESSES__GET_GROUPS_ALL__STARTED
    });

    return api
        .ekostraznik
        .qrCodesAddressGroups
        .getAll()
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__GET_GROUPS_ALL__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__GET_GROUPS_ALL__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const addAddressGroup = (data) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_ADDRESSES__ADD_GROUP__STARTED
    });

    return api
        .ekostraznik
        .addressGroups
        .add(data)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__ADD_GROUP__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__ADD_GROUP__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
            return error.response;
        });
};

export const editAddressGroup = (id, data) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_ADDRESSES__UPDATE_GROUP__STARTED
    });

    return api
        .ekostraznik
        .addressGroups
        .update(id, data)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__UPDATE_GROUP__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__UPDATE_GROUP__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
            return error.response;
        });
};

export const deleteManyAddressGroups = (data) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_ADDRESSES__DELETE_GROUPS__STARTED
    });

    return api
        .ekostraznik
        .addressGroups
        .deleteMany(data)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__DELETE_GROUPS__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__DELETE_GROUPS__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getAddressGroup = (id) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__EKO_ADDRESSES__GET_GROUP__STARTED
    });
    
    return api
        .ekostraznik
        .addressGroups
        .get(id)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__GET_GROUP__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__EKO_ADDRESSES__GET_GROUP__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};
