import React, { Component } from "react";
import { connect } from "react-redux";

import AdminHeader from "./header";
import Sidebar from "./sidebar";
import { resetSidebar, setSidebarItemSelected } from "../../../data/actions/common/ui/sidebar";
import { MISC_CLASS_SCROLL_LOCK, MISC_EVENT_CLICK } from "../../../core/constants";
import { isNullOrUndefined } from "../../../core/utils/misc_utils";
import Toast from "../../common/toast";

class LayoutWithSidebar extends Component {

    render = () => {
        const { authentication, history, sidebarVariant, sidebarSelected } = this.props;
        const { sidebarVisible, sidebarCollapsed, activeSidebarItem, activeSubmenuItem, submenuExpanded } = sidebarSelected;

        const childrenWithKey = React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                key: child,
                history: history
            });
        });

        return (
            <div className={ `container--with-sidebar  ${ sidebarCollapsed ? "with-sidebar--collapsed" : "with-sidebar--expanded" }` }>
                {
                    !isNullOrUndefined(authentication.data.permissions) &&
                    <Sidebar
                        sidebarVariant={ sidebarVariant}
                        sidebarVisible={ sidebarVisible }
                        sidebarCollapsed={ sidebarCollapsed }
                        activeSidebarItem={ activeSidebarItem }
                        activeSubmenuItem={ activeSubmenuItem }
                        submenuExpanded={ submenuExpanded }
                        _onModuleStartPageClicked={ this._onModuleStartPageClicked }
                        _onSidebarCollapseClicked={ this._onSidebarCollapseClicked }
                        _onSidebarItemSelected={ this._onSidebarItemSelected }
                        _onSidebarItemWithSubmenuSelected={ this._onSidebarItemWithSubmenuSelected }
                        _onSubmenuItemSelected={ this._onSubmenuItemSelected }/>
                }


                <div className='main-area'>
                    <AdminHeader _onSidebarTriggerClicked={ this._onSidebarTriggerClicked }/>
                    <div className="main-content">
                        {
                            childrenWithKey
                        }
                        <Toast/>
                    </div>
                </div>

            </div>
        );
    };

    _onModuleStartPageClicked = () => {
        this.props.resetSidebar();
    };

    _onSidebarItemSelected = (index) => {
        this.props.setSidebarItemSelected({
            activeSidebarItem: index,
            activeSubmenuItem: null
        });
    };

    _onSidebarItemWithSubmenuSelected = (index) => {

        // TODO: fugly, but event.stopPropagation() does not work for some reason...
        document.removeEventListener(MISC_EVENT_CLICK, this._onSidebarClose);
        const { activeSidebarItem, submenuExpanded } = this.props.sidebarSelected;

        this.props.setSidebarItemSelected({
            activeSidebarItem: index,
            submenuExpanded: activeSidebarItem === index ? !submenuExpanded : true
        }, () => {
            document.addEventListener(MISC_EVENT_CLICK, this._onSidebarClose);
        });
    };

    _onSubmenuItemSelected = (event, index) => {
        event.stopPropagation();

        this.props.setSidebarItemSelected({
            activeSubmenuItem: index
        });
    };

    _onSidebarTriggerClicked = () => {
        this.props.setSidebarItemSelected({
            sidebarVisible: true,
            sidebarCollapsed: false
        }, () => {
            document.addEventListener(MISC_EVENT_CLICK, this._onSidebarClose);
            this._toggleScrollLock(true);
        });
    };

    _onSidebarClose = () => {
        this.props.setSidebarItemSelected({
            sidebarVisible: false
        }, () => {
            document.removeEventListener(MISC_EVENT_CLICK, this._onSidebarClose);
            this._toggleScrollLock(false);
        });

    };

    _onSidebarCollapseClicked = () => {
        const { sidebarCollapsed } = this.props.sidebarSelected;
        this.props.setSidebarItemSelected({
            sidebarCollapsed: !sidebarCollapsed
        });
    };

    _toggleScrollLock = (forcedValue) => {
        const htmlElement = document.querySelector("html");

        if (isNullOrUndefined(forcedValue)) {
            htmlElement
                .classList
                .toggle(MISC_CLASS_SCROLL_LOCK);

        } else {

            if (forcedValue) {
                htmlElement
                    .classList
                    .add(MISC_CLASS_SCROLL_LOCK);

            } else {
                htmlElement
                    .classList
                    .remove(MISC_CLASS_SCROLL_LOCK);
            }

        }
    };
}

const mapStateToProps = (state) => {
    return {
        sidebarVariant: state.ui.sidebar.variant,
        sidebarSelected: state.ui.sidebar.selected,
        authentication: state.authentication
    };
};

export default connect(mapStateToProps, { setSidebarItemSelected, resetSidebar })(LayoutWithSidebar);
