import React from "react";

import { Draggable } from "react-beautiful-dnd";
import IconDragBlue from "../../../../../../../resource/img/icons/icon_drag_blue.svg";
import IconDragWhite from "../../../../../../../resource/img/icons/icon_drag_white.svg";

const ReceiverGroupItem = ({ name, id, current, index }) => (
    <Draggable
        draggableId={ id }
        index={ index }>
        { (provided) => (
            <div className="drag-and-drop__content"
                 { ...provided.draggableProps }
                 { ...provided.dragHandleProps }
                 ref={ provided.innerRef }>
                <div className={ `drag-and-drop__count ${ current ? "drag-and-drop__current" : "" }` }>
                    { index + 1 }
                </div>
                <div className={ `drag-and-drop__name ${ current ? "drag-and-drop__current" : "" }` }>
                    <span>{ name }</span>
                    { current ? <img src={ IconDragWhite } alt=""/> : <img src={ IconDragBlue } alt=""/> }
                </div>
            </div>
        ) }
    </Draggable>
);

export default ReceiverGroupItem;
