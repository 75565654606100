import towns from "./towns";
import streets from "./streets";
import addresses from "./addresses";
import addressesTo from "./addresses_to";
import apartmentAddresses from "./apartment_addresses";

export default (state = {}, action) => {
    return {
        ...state,
        towns: towns(state.towns, action),
        streets: streets(state.streets, action),
        addresses: addresses(state.addresses, action),
        addressesTo: addressesTo(state.addressesTo, action),
        apartmentAddresses: apartmentAddresses(state.apartmentAddresses, action),
    };
}
