import get from "./get";
import update from "./update";
import add from "./add";


export default (state = {}, action) => {
    return {
        ...state,
        get: get(state.get, action),
        update: update(state.update, action),
        add: add(state.add, action)
    };
}