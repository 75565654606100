import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { ROUTE_PASSWORD_RESET_REQUEST_CONFIRMATION } from "../../../../core/constants";
import PasswordResetRequestForm from "./form";

class PasswordResetRequest extends Component {
    state = {
        submitted: false,
        emailTarget: ""
    };

    render = () => {
        const { submitted, emailTarget } = this.state;

        if (submitted) {
            return <Redirect to={ { pathname: ROUTE_PASSWORD_RESET_REQUEST_CONFIRMATION, state: { emailTarget: emailTarget } } }/>;
        }

        return <PasswordResetRequestForm _handleSubmit={ this._handleSubmit } location={ this.props.location }/>;
    };

    _handleSubmit = (email) => {
        this.setState({
            submitted: true,
            emailTarget: email
        });
    };
}

export default PasswordResetRequest;