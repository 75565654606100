import React from "react";

const DefineAreaBinItem = ({ bin, checked, _onBinClick }) => {
    return (
        <li className="column is-paddingless is-half">
            <label className={ `acceptance-label ${ checked ? " label--checked" : "" }` }
                   onClick={ event => {
                       _onBinClick(bin.id);
                       event.preventDefault();
                   } }>

                <input type='checkbox' className='acceptance-checkbox'/>
                <span className="acceptance-text">
                    {
                        bin.name
                    }
                    </span>
            </label>
        </li>
    );
};

export default DefineAreaBinItem;