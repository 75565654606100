import React from "react";

import DeleteAddressGroupForm from "../form/delete_group_form";
import { ROUTE_DETAILED_ADDRESS_GROUP } from "../../../../../../core/constants";
import { useHistory } from "react-router-dom";
import EditAddressGroupForm from "../form/edit_form";
import CheckboxInput from "../../../../../common/checkbox_input";

const AddressGroupsListItem = ({ group, checkedAddressGroups, _onDeleteOrUpdateSuccess, _onAddressGroupCheckboxChange }) => {
    const history = useHistory();

    return (
        <tr>
            <td className="t-width-11">
                <CheckboxInput
                    _onInputChange={ () => _onAddressGroupCheckboxChange(group.id) }
                    checked={ checkedAddressGroups.indexOf(group.id) >= 0 }
                />

                <p className="table-text-content is-clickable"
                   onClick={ () => history.push(ROUTE_DETAILED_ADDRESS_GROUP(group.id)) }>
                    { group.name }
                </p>
            </td>
            <td className='sticky--right td-action-buttons'>

                <EditAddressGroupForm
                    _onSuccess={ _onDeleteOrUpdateSuccess }
                    group={ group }
                />

                <DeleteAddressGroupForm
                    _onSuccess={ _onDeleteOrUpdateSuccess }
                    group={ group }
                />

            </td>
        </tr>
    );
};

export default AddressGroupsListItem;