import _ from "lodash";

import * as api from "../../../_api";
import { actions } from "../../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../../core/utils/api_utils";

export const getManyReportStatuses = () => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__REPORT_STATUSES__GET_MANY__STARTED
    });

    return api
        .waste_management
        .reportStatuses
        .getManyReportStatuses()
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__REPORT_STATUSES__GET_MANY__SUCCESS,
                meta: {},
                payload: _.mapKeys(extractResponseData(response), "code"),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__REPORT_STATUSES__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};