import { actions } from "../../../core/constants";
import * as api from "../../_api";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const getManyDraftMessageAddresses = (id) => dispatch => {
    dispatch({
        type: actions.blisko.API__BLISKO__DRAFT_MESSAGE_ADDRESSES__GET_MANY__STARTED
    });

    return api
        .blisko
        .draftMessageAddresses
        .getMany(id)
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__DRAFT_MESSAGE_ADDRESSES__GET_MANY__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });

            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__DRAFT_MESSAGE_ADDRESSES__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });

};

export const addUnitsToDraftMessageAddresses = (id, unitsId) => dispatch => {
    return executeUpdateAction(() =>
            api
                .blisko
                .draftMessageAddresses
                .addUnits(id, unitsId),
        dispatch
    );

};

export const addUnitsRangeToDraftMessageAddresses = (id, unitIdFrom, unitIdTo) => dispatch => {
    return executeUpdateAction(() =>
            api
                .blisko
                .draftMessageAddresses
                .addUnitsRange(id, unitIdFrom, unitIdTo),
        dispatch
    );

};

export const addAddressTemplateToDraftMessageAddresses = (id, addressTemplateId) => dispatch => {
    return executeUpdateAction(() =>
            api
                .blisko
                .draftMessageAddresses
                .addAddressTemplate(id, addressTemplateId),
        dispatch
    );

};

export const removeUnitsFromDraftMessageAddresses = (id, unitIds) => dispatch => {
    return executeUpdateAction(() =>
            api
                .blisko
                .draftMessageAddresses
                .removeUnits(id, unitIds),
        dispatch
    );
};

const executeUpdateAction = (action, dispatch) => {
    dispatch({
        type: actions.blisko.API__BLISKO__DRAFT_MESSAGE_ADDRESSES__UPDATE__STARTED
    });

    return action()
        .then(response => {
            dispatch({
                type: actions.blisko.API__BLISKO__DRAFT_MESSAGE_ADDRESSES__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__BLISKO__DRAFT_MESSAGE_ADDRESSES__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};