import React from "react";

import moment from "moment";
import striptags from "striptags";
import { MessageEnum } from "../../../../../../../core/enums/waste_management/messages";
import {
    MISC_DATE_FORMAT,
    MISC_DATE_ISO_FORMAT,
    ROUTE_BLISKO_MESSAGE_PREVIEW
} from "../../../../../../../core/constants";
import { replaceHtmlEntities } from "../../../../../../../core/utils/string_utils";
import { useHistory } from "react-router-dom";
import DeleteMessageForm from "../form/delete_form";

const SentMessagesListItem = ({ message, _onCancelMessage }) => {
    const history = useHistory();

    return (
        <tr className="tr--clickable">
            <td className='t-width-1' onClick={ () => history.push(ROUTE_BLISKO_MESSAGE_PREVIEW(message.id)) }>
                <span className="notification__date">
                    {
                        moment(message.requestedRealization, MISC_DATE_ISO_FORMAT)
                            .format(MISC_DATE_FORMAT)
                    }
                </span>
            </td>
            <td className='t-width-2' onClick={ () => history.push(ROUTE_BLISKO_MESSAGE_PREVIEW(message.id)) }>
                <Status sendingStatus={ message.sendingStatus } date={ message }/>
            </td>
            <td className='t-width-5' onClick={ () => history.push(ROUTE_BLISKO_MESSAGE_PREVIEW(message.id)) }>
                <p className="table-text-content">
                    <span className="is-bold">{ message.title }</span>
                    <span> - </span>
                    {
                        replaceHtmlEntities(striptags(message.body))
                    }
                </p>
            </td>
            <td className='t-width-3' onClick={ () => history.push(ROUTE_BLISKO_MESSAGE_PREVIEW(message.id)) }>
                {
                    message.type === MessageEnum.source.OPERATOR &&
                    `${ message.employeeName } ${ message.employeeSurname }`
                }
                {
                    message.type === MessageEnum.source.AUTOMATIC &&
                    "Wiadomość automatyczna"
                }
            </td>
            {
                message.sendingStatus === MessageEnum.sendingStatus.READY &&
                <td className='sticky--right td-action-buttons'>
                    <DeleteMessageForm
                        _onCancelMessage={ _onCancelMessage }/>
                </td>
            }

        </tr>
    );
};

export default SentMessagesListItem;

const Status = ({ sendingStatus }) => {
    switch (sendingStatus) {

        case MessageEnum.sendingStatus.READY:
            return (
                <div className='notification-status-with-text'>
                    <div className="notification-status-wrapper notification-status-wrapper--pending">
                        <div className="notification-status notification-status--pending"/>
                    </div>
                    Zaplanowana
                </div>
            );

        case MessageEnum.sendingStatus.CANCELED:
            return (
                <div className='notification-status-with-text'>
                    <div className="notification-status-wrapper">
                        <div className="notification-status notification-status--canceled"/>
                    </div>
                    Anulowana
                </div>
            );

        case MessageEnum.sendingStatus.ERROR:
            return (
                <div className='notification-status-with-text notification-status-with-text--error'>
                    <div className="notification-status-wrapper">
                        <div className="notification-status notification-status--error"/>
                    </div>
                    Błąd wysyłki
                </div>
            );

        default:
            return (
                <div className='notification-status-with-text'>
                    <div className="notification-status-wrapper">
                        <div className="notification-status notification-status--sent"/>
                    </div>
                    Wysłana
                </div>
            )
    }
};