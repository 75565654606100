import React from "react";
import SettingsHeader from "../common/header";
import WasteManagementRegulations from "./waste_management/";
import { SettingsEnum } from "../../../../../core/enums/common/settings";


const SettingsRegulations = () => {

    return (
        <div className="side-spacing">
            <SettingsHeader tab={ SettingsEnum.tab.REGULATIONS }/>
            <WasteManagementRegulations/>
        </div>

    );
};

export default SettingsRegulations;
