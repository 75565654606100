import addIssueHandle from "./add_issue_handle";
import addService from "./add_service";
import _delete from "./delete";
import get from "./get";
import getMany from "./get_many";
import getManyForCategory from "./get_many_for_category";
import hide from "./hide";
import publish from "./publish";
import updateIssueHandle from "./update_issue_handle";
import updateService from "./update_service";

export default (state = {}, action) => {
    return {
        ...state,
        addIssueHandle: addIssueHandle(state.addIssueHandle, action),
        addService: addService(state.addService, action),
        _delete: _delete(state._delete, action),
        get: get(state.get, action),
        getMany: getMany(state.getMany, action),
        getManyForCategory: getManyForCategory(state.getManyForCategory, action),
        hide: hide(state.hide, action),
        publish: publish(state.publish, action),
        updateIssueHandle: updateIssueHandle(state.updateIssueHandle, action),
        updateService: updateService(state.updateService, action)
    };
}