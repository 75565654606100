import React from "react";
import moment from "moment";

import DocumentGallery from "../../../../../../common/document_gallery/gallery";
import TitleWithDivider from "../../../../../../common/title_with_divider";
import { MISC_DATE_DAY_MONTH_YEAR_HOUR_MINUTE_FORMAT, MISC_DATE_ISO_FORMAT } from "../../../../../../../core/constants";
import { isEmptyArray, isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";
import BinIcon from "../../../../../../common/BinIcon";
import AllAddressesInGroupModal from '../../../../ekostraznik/common/list/all_addresses_in_group_modal';
import { ReactComponent as IconInfo } from '../../../../../../../../resource/img/icons/icon_info.svg';

const ReportDetailsInfo = ({ selectedReport, bins }) => {

    if (!selectedReport?.data) {
        return null;
    }

    return (
        <div className="report-details__section">
            <TitleWithDivider>
                Zgłoszenie
            </TitleWithDivider>

            {
                selectedReport.data.company && selectedReport.data.company.name &&
                    <div className='report-details__subheader'>
                        {selectedReport.data.company.name}
                    </div>
            }

            <div className="report-details__row report-details__row--info">
                <p className="report-details__label">Data zgłoszenia:</p>
                {
                    !isNullOrUndefined(selectedReport.data.createdDate) &&

                    <p className="report-details__text">
                        {
                            moment(selectedReport.data.createdDate, MISC_DATE_ISO_FORMAT)
                                .format(MISC_DATE_DAY_MONTH_YEAR_HOUR_MINUTE_FORMAT)
                        }
                    </p>
                }
            </div>


            {
                !isEmptyArray(selectedReport.data.binIds) &&

                <div className="report-details__row report-details__row--info">
                    <p className="report-details__label">Rodzaj pojemnika:</p>
                    <p className="report-details__text">
                        <BinIcon color={ bins.data[selectedReport.data.binIds[0]].color }/>
                        <span className="is-spaced--left-5">
                             {
                                 bins.data[selectedReport.data.binIds[0]].name
                             }
                         </span>
                    </p>
                </div>

            }

            {
                !isNullOrUndefined(selectedReport.data.location) &&

                <div className="report-details__row report-details__row--info">
                    <p className="report-details__label">Lokalizacja:</p>
                    {
                        <p className="report-details__text">
                            {
                                selectedReport.data.location.town
                            }
                            {
                                selectedReport.data.location.street ? ", " + selectedReport.data.location.street : ""
                            }
                            {
                                " " + selectedReport.data.location.address
                            }
                        </p>
                    }
                </div>
            }

            {
                selectedReport.data.group &&

                <div className="report-details__row report-details__row--info">
                    <p className="report-details__label">Grupa:</p>
                    {

                        <p className="report-details__text">
                        {
                            selectedReport.data.group.name
                        }
                        </p>
                    }
                    {
                        <div className='label-icon-btn'>
                            <AllAddressesInGroupModal
                                triggerComponent={ <IconInfo/> }
                                groupName={ selectedReport.data.group.name }
                                reportDate={ selectedReport.data.createdDate }
                                addresses={ selectedReport.data.group.addresses }
                            />
                        </div>
                    }
                </div>
            }

            <div className="report-details__row report-details__row--info">
                <p className="report-details__label">Opis:</p>
                {
                    selectedReport.data.description &&
                    <p className="report-details__text">
                        {
                            selectedReport.data.description
                        }
                    </p>
                }
            </div>

            <DocumentGallery
                attachmentPaths={ selectedReport.data.attachmentPaths }
                label={ "Dokumentacja fotograficzna:" }/>
        </div>
    );
};

export default ReportDetailsInfo;
