import React from "react";
import { Link } from "react-router-dom"
import { isNullOrUndefined } from "../../core/utils/misc_utils";
import { ReactComponent as IconBack } from "../../../resource/img/icons/icon_back_blue.svg";

const Header = ({ children, goBackLink }) => {

    return (
        <header className={ `row page-header ${ !isNullOrUndefined(goBackLink) ? "page-header--with-back" : "" }` }>
            {
                !isNullOrUndefined(goBackLink) &&
                <Link to={ goBackLink } className="unstyled-btn">
                    <IconBack/>
                </Link>
            }
            <h1 className='title is-1'>
                {
                    children
                }
            </h1>
        </header>
    );
};

export default Header;
