import React from "react";

const TitleWithBottomDivider = ({ children, alternativeText }) => {

    return (
        <>
            <div className="title-with-bottom-divider">
                <h2 className="title-with-bottom-divider__title">{ children }</h2>
                {
                    alternativeText &&
                    <h2 className="title-with-bottom-divider__additional-text">{ alternativeText }</h2>
                }
            </div>
            <hr className="title-with-bottom-divider__divider"/>
        </>
    );
};

export default TitleWithBottomDivider;