import { API_BASE_AKUN_GOK_PATH } from '../../../core/constants';
import webClient from "../../../core/web_client";

const PATH = `${ API_BASE_AKUN_GOK_PATH }/receptions`;

export const copyReceptionFromYearToYear = ({areaIdsToUpdate, binIdsToUpdate, yearFrom, yearTo}) => {
    return webClient
        .patch(PATH, {
            command: 'duplicate-from-year-to-year',
            binIdsToUpdate,
            areaIdsToUpdate,
            yearFrom,
            yearTo
        });
};

export const copyReceptionFromAreaToArea = ({sourceAreaId, areaIdsToUpdate, binIdsToUpdate}, sourceYear) => {
    return webClient
        .patch(PATH, {
            command: 'duplicate-from-area-to-area',
            binIdsToUpdate,
            sourceAreaId,
            areaIdsToUpdate,
            sourceYear
        });
};

export const copyReceptionFromBinToBin = ({sourceAreaId, sourceBinId, binIdsToUpdate}, sourceYear) => {
    return webClient
        .patch(PATH, {
            command: 'duplicate-from-bin-to-bin',
            binIdsToUpdate,
            sourceAreaId,
            sourceBinId,
            sourceYear
        });
};

