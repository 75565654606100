import React from "react";

import SidebarItem from "../sidebar_item";
import { PermissionEnum } from "../../../../../core/enums/common/permissions";
import { SidebarEnum } from "../../../../../core/enums/common/sidebar";
import RestrictedView from "../../../../common/restricted_view";
import SidebarItemWithSubmenu from "../sidebar_item_with_submenu";
import SidebarSubmenuItem from "../sidebar_submenu_item";

const BliskoSidebarVariant = ({
                                  activeSidebarItem,
                                  submenuExpanded,
                                  activeSubmenuItem,
                                  _onSidebarItemSelected,
                                  _onSidebarItemWithSubmenuSelected,
                                  _onSubmenuItemSelected
                              }) => {

    return (
        <>
            <RestrictedView
                neededPermission={ PermissionEnum.module.BLISKO.type.MESSAGES.full_code }>
                <SidebarItem
                    sidebarItemId={ SidebarEnum.BLISKO.MESSAGES.id }
                    sidebarItemName={ SidebarEnum.BLISKO.MESSAGES.name }
                    sidebarItemIcon={ SidebarEnum.BLISKO.MESSAGES.icon }
                    sidebarItemLink={ SidebarEnum.BLISKO.MESSAGES.link }
                    activeSidebarItem={ activeSidebarItem }
                    _onSidebarItemSelected={ _onSidebarItemSelected }
                />
            </RestrictedView>

            <RestrictedView
                neededPermission={ [
                    PermissionEnum.module.BLISKO.type.ADDRESS_TEMPLATE.full_code,
                    PermissionEnum.module.BLISKO.type.MESSAGE_TEMPLATE.full_code,
                ] }>

                <SidebarItemWithSubmenu
                    sidebarItemId={ SidebarEnum.BLISKO.TEMPLATES.id }
                    sidebarItemName={ SidebarEnum.BLISKO.TEMPLATES.name }
                    sidebarItemIcon={ SidebarEnum.BLISKO.TEMPLATES.icon }
                    submenuExpanded={ submenuExpanded }
                    activeSidebarItem={ activeSidebarItem }
                    _onSidebarItemWithSubmenuSelected={ _onSidebarItemWithSubmenuSelected }>

                    <RestrictedView
                        neededPermission={ PermissionEnum.module.BLISKO.type.ADDRESS_TEMPLATE.full_code }>
                        <SidebarSubmenuItem
                            sidebarSubmenuItemId={ SidebarEnum.BLISKO.TEMPLATES.ADDRESS_TEMPLATE.id }
                            sidebarItemName={ SidebarEnum.BLISKO.TEMPLATES.ADDRESS_TEMPLATE.name }
                            sidebarSubmenuLink={ SidebarEnum.BLISKO.TEMPLATES.ADDRESS_TEMPLATE.link }
                            activeSubmenuItem={ activeSubmenuItem }
                            _onSubmenuItemSelected={ _onSubmenuItemSelected }/>
                    </RestrictedView>

                    <RestrictedView
                        neededPermission={ PermissionEnum.module.BLISKO.type.MESSAGE_TEMPLATE.full_code }>
                        <SidebarSubmenuItem
                            sidebarSubmenuItemId={ SidebarEnum.BLISKO.TEMPLATES.MESSAGE_TEMPLATE.id }
                            sidebarItemName={ SidebarEnum.BLISKO.TEMPLATES.MESSAGE_TEMPLATE.name }
                            sidebarSubmenuLink={ SidebarEnum.BLISKO.TEMPLATES.MESSAGE_TEMPLATE.link }
                            activeSubmenuItem={ activeSubmenuItem }
                            _onSubmenuItemSelected={ _onSubmenuItemSelected }/>
                    </RestrictedView>

                </SidebarItemWithSubmenu>
            </RestrictedView>

            <RestrictedView
                neededPermission={ PermissionEnum.module.BLISKO.type.RECEIVER_GROUP.full_code }>
                <SidebarItem
                    sidebarItemId={ SidebarEnum.BLISKO.RECEIVER_GROUP.id }
                    sidebarItemName={ SidebarEnum.BLISKO.RECEIVER_GROUP.name }
                    sidebarItemIcon={ SidebarEnum.BLISKO.RECEIVER_GROUP.icon }
                    sidebarItemLink={ SidebarEnum.BLISKO.RECEIVER_GROUP.link }
                    activeSidebarItem={ activeSidebarItem }
                    _onSidebarItemSelected={ _onSidebarItemSelected }
                />
            </RestrictedView>

            <RestrictedView
                neededPermission={ PermissionEnum.module.BLISKO.type.SENDER_DETAILS.full_code }>
                <SidebarItem
                    sidebarItemId={ SidebarEnum.BLISKO.SENDER_DETAILS.id }
                    sidebarItemName={ SidebarEnum.BLISKO.SENDER_DETAILS.name }
                    sidebarItemIcon={ SidebarEnum.BLISKO.SENDER_DETAILS.icon }
                    sidebarItemLink={ SidebarEnum.BLISKO.SENDER_DETAILS.link }
                    activeSidebarItem={ activeSidebarItem }
                    _onSidebarItemSelected={ _onSidebarItemSelected }
                />
            </RestrictedView>
        </>
    )
};

export default BliskoSidebarVariant;