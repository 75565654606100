import React from "react";

import FormModal from "../../../../../../../../common/modal/form_modal";
import CustomBinDeleteModalFormContent from "./content";

import { ReactComponent as IconDelete } from "../../../../../../../../../../resource/img/icons/icon_delete--white.svg";

const CustomBinDeleteModalForm = ({ bin, _onDeleteCustomBinTypeClick }) => {

    return (
        <FormModal
            triggerComponent={
                <strong tabIndex="0" className="tab-item__delete">
                    <IconDelete/>
                </strong>
            }
            ariaLabel='Usuń'
            deleteOrCancelButtons={ true }
            _actionFunction={ () => { return _onDeleteCustomBinTypeClick(bin) } }>

            <CustomBinDeleteModalFormContent
                binName={ bin.name }/>

        </FormModal>
    );
};

export default CustomBinDeleteModalForm;