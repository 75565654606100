import queryString from "query-string";

export const queryParameterValue = (search, param) => {
    const queryStringValues = queryString.parse(search);

    if (!queryStringValues) {
        return null;
    }

    const value = queryStringValues[param];


    if (typeof value == "string") {
        return value;
    }

    if (typeof value == "undefined") {
        return null;
    }

    if (typeof value == "object" && value.length > 0) {
        return value[0];
    }

    return null;
};

export const replaceHtmlEntities = (stringWithEntities) => {
    if (!stringWithEntities) {
        return stringWithEntities;
    }

    return stringWithEntities
        .replaceAll("&nbsp;", " ")
        .replaceAll("&lt;", "<")
        .replaceAll("&gt;", ">")
        .replaceAll("&amp;", "&")
        .replaceAll("&quot;", "\"")
        .replaceAll("&apos;", "'")
        .replaceAll("&cent;", "¢")
        .replaceAll("&pound;", "£")
        .replaceAll("&yen;", "¥")
        .replaceAll("&euro;", "€")
        .replaceAll("&copy;", "©")
        .replaceAll("&reg;", "®");
};

/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/
String.prototype.replaceAll = function(search, replacement) {
    return this.replace(new RegExp(search, 'g'), replacement);
};