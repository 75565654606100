import webClient from "../../../core/web_client";

const PATH_SUBSCRIBE = `akun/api/newsletter/acceptance`;
const PATH_UNSUBSCRIBE = `akun/api/newsletter/unsubscribe`;
const PATH_OWNER = `akun/api/owners/`;

export function addNewsletterSubscription(token) {
    return webClient
        .post(`${ PATH_SUBSCRIBE }/${ token }`);
}

export function checkTokenStatus(token) {
    return webClient
        .get(`${ PATH_SUBSCRIBE }/${ token }`);
}

export function deleteNewsletterSubscription(token) {
    return webClient
        .post(`${ PATH_UNSUBSCRIBE }/${ token }`);
}

export function getNewsletterOwner(ownerId) {
    return webClient
        .get(`${ PATH_OWNER }/${ ownerId }/info`);
}



