import { deepFreeze } from "../../utils/misc_utils";

export const InspectionCompaniesEnum = deepFreeze({
    command: {
        INSPECTION_COMPANY_DEACTIVATE: "deactivate",
        INSPECTION_COMPANY_ACTIVATE: "activate",
        INSPECTION_COMPANY_GENERATE_NEW_CODE: "generate-new-code"
    },
    statuses: {
        ACTIVE: "ACTIVE",
        INACTIVE: "INACTIVE"
    }
});