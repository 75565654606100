import React, { useCallback } from "react";
import FormModal from "../../../../../../../common/modal/form_modal";
import { ReactComponent as IconAdd } from "../../../../../../../../../resource/img/icons/icon_add_white.svg";
import ReverseGeocodingMap from "../../../../../../../common/map/reverse_geocoding_map";
import FormRequiredLegend from "../../../../../../../common/legend_required";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { reverseGeocoding } from "../../../../../../../../data/actions/common/geocoding";
import withForwardGeocoding from "../../../../../../../common/hoc/with_forward_geocoding";
import { MISC_MAPBOX_DEFAULT_MAP_CENTER_COORDINATES } from "../../../../../../../../core/constants";
import { addNest, updateNest } from "../../../../../../../../data/actions/ekostraznik/address_groups_nests";
import _ from 'lodash';
import { isEmptyObject } from "../../../../../../../../core/utils/misc_utils";
import { getAddressGroup } from "../../../../../../../../data/actions/ekostraznik/address_groups";

const AddOrEditNestModalForm = ({ groupId, nest, isOpen, _onFormClose }) => {
    const geocoding = useSelector(state => state.entities.common.geocoding);
    const dispatch = useDispatch();
    const isEdition = !!nest;

    const onSubmit = useCallback(
        formik => {
            const errors = _.pickBy({
                name: !formik.values.name.trim() ? 'Pole wymagane' : undefined,
                location: !formik.values.location.trim() ? 'Pole wymagane' : undefined,
            }, _.identity);
            formik.setErrors(errors);

            if (!isEmptyObject(errors)) {
                return Promise.reject();
            }

            return dispatch(isEdition ? updateNest(groupId, nest.id, formik.values) : addNest(groupId, formik.values))
                .then(() => !isEdition && formik.resetForm())
                .then(() => _onFormClose && _onFormClose())
                .then(() => dispatch(getAddressGroup(groupId)))
        },
        [dispatch, groupId, _onFormClose, nest, isEdition]
    )

    return (
        <Formik
            initialValues={ {
                longitude: nest?.longitude || MISC_MAPBOX_DEFAULT_MAP_CENTER_COORDINATES.lng,
                latitude: nest?.latitude || MISC_MAPBOX_DEFAULT_MAP_CENTER_COORDINATES.lat,
                name: nest?.name || '',
                location: ''
            } }
        >
            {
                formik => (
                    <FormModal
                        triggerComponent={
                            !isEdition ?
                                <button className="btn btn--primary btn--big">
                                    <IconAdd/>
                                    <span className="is-spaced--left-10">Dodaj nową wiatę</span>
                                </button>
                                :
                                ''
                        }
                        noTrigger={ isEdition }
                        forceOpen={ isOpen }
                        ariaLabel={ !isEdition ? 'Dodaj nową wiatę' : 'Edytuj wiatę' }
                        headerText={ !isEdition ? 'Dodaj nową wiatę' : 'Edytuj wiatę' }
                        saveOrStayOrCancelButtons={ !isEdition }
                        saveOrCancelButtons={ isEdition }
                        _actionFunction={ async () => onSubmit(formik) }
                        _actionFunctionWithFormClear={ async () => onSubmit(formik) }
                        _cancelFunction={ async () => {
                            formik.resetForm();
                            _onFormClose && _onFormClose()
                        } }
                    >
                        <div className='c-modal__body modal-size-m'>
                            <Form className='form--vertical form--with-legend'>
                                <Field
                                    name="name"
                                    render={ ({ field, form }) => (
                                        <label>
                                            <span className='label-name'><span className="required-accent">*</span> Nazwa punktu</span>
                                            <span className="is-relative full-width">
                                                <input
                                                    type="text"
                                                    className={ `${ form.errors?.name ? "rejected" : "" }` }
                                                    { ...field }
                                                />
                                                {
                                                    form.errors?.name &&
                                                    <span className="hint hint--rejected">{ form.errors.name }</span>
                                                }
                                            </span>
                                        </label>
                                    ) }
                                />

                                <LocationField
                                    errors={ formik.errors.location }
                                    value={ formik.values.location }
                                    _onLocationFieldChangeCallback={ value => {
                                        formik.setFieldValue("location", value);
                                    } }
                                    _onSuggestionClickCallback={ (lng, lat, placeName) => {
                                        formik.setFieldValue("longitude", lng);
                                        formik.setFieldValue("latitude", lat);
                                        formik.setFieldValue("location", placeName);
                                    } }
                                />

                                <ReverseGeocodingMap
                                    initReverseGeocoding={ isEdition }
                                    reverse={ geocoding.reverse }
                                    longitude={ formik.values.longitude }
                                    latitude={ formik.values.latitude }
                                    _reverseGeocodingCallback={async (longitude, latitude) =>
                                        dispatch(reverseGeocoding(longitude, latitude))
                                            .then(() => {
                                                formik.setFieldValue("longitude", longitude);
                                                formik.setFieldValue("latitude", latitude);
                                            })
                                    }
                                    _handleReverseGeocodingCallback={ name => formik.setFieldValue("location", name) }
                                />


                                <FormRequiredLegend/>
                            </Form>
                        </div>


                    </FormModal>
                )
            }
        </Formik>

    );
}
export default AddOrEditNestModalForm;

const LocationField = withForwardGeocoding(({ renderInputWithSuggestions }) => {
    return (
        <label>
            <span className='label-name'>
                <span className="required-accent">*</span>
                Lokalizacja
            </span>

            {
                renderInputWithSuggestions()
            }

        </label>
    );
})

