import React from "react";
import { isNullOrUndefined } from "../../core/utils/misc_utils";

const suggestionItem = ({ dataItem, _onSuggestionClick }) => {

    return (
        <div key={ dataItem.key }
             className="dropdown-suggestions__item"
             onMouseDown={ () => _onSuggestionClick(dataItem.data) }>
            {
                dataItem.label
            }
        </div>
    );
};

const InputSuggestions = ({ dataArray, _onSuggestionClick }) => {

    if (isNullOrUndefined(dataArray)) {
        return null;
    }

    return (
        <div className="dropdown-suggestions">
            {
                dataArray.map(dataItem => {
                    return suggestionItem({ dataItem, _onSuggestionClick });
                })
            }
        </div>
    );
};

export default InputSuggestions;