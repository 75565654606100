import { actions } from "../../../../core/constants";

const defaultState = {
    selected: null
};

export default (state = defaultState, action) => {
    switch (action.type) {

        case actions.common.UI__SIDEBAR_VARIANT__SELECTED:
            return action.payload;

        case actions.common.UI__AUTHENTICATION__SIGN_OUT:
            return defaultState;

        default:
            return state;
    }
}