import { API_BASE_AKUN_ADMIN_PATH } from "../../../core/constants";
import webClient from "../../../core/web_client";

const PATH = `${ API_BASE_AKUN_ADMIN_PATH }/owner/accessibility-statements`;

export const update = (data) => {
    return webClient
        .put(PATH, data);
};

export const get = () => {
    return webClient
        .get(PATH);
};