import { DEFAULT_STATE } from "../../../../../core/utils/store_utils";
import { actions, MISC_MAPBOX_DEFAULT_MAP_CENTER_COORDINATES } from "../../../../../core/constants";

export default (state = { latitude: MISC_MAPBOX_DEFAULT_MAP_CENTER_COORDINATES.lat, longitude: MISC_MAPBOX_DEFAULT_MAP_CENTER_COORDINATES.lng }, action) => {
    switch (action.type) {
        case actions.common.API__OWNER_COORDINATES__GET__SUCCESS:
            return action.payload;

        case actions.common.UI__AUTHENTICATION__SIGN_OUT:
            return DEFAULT_STATE;

        default:
            return state;
    }
}