import React, { useCallback, useState } from "react";
import ApartmentAddressesHeader from "./header";
import ApartmentAddressesList from "./list";
import _ from "lodash";

const ApartmentAddresses = () => {
    const [ searchBarTerm, setSearchBarTerm ] = useState('');

    const _onSearchBarInputChange = useCallback(
        _.debounce(input => setSearchBarTerm(input || ''), 600),
        []
    );

    return (
        <div className="main-content--table">
            <ApartmentAddressesHeader
                _onSearchBarInputChange={ _onSearchBarInputChange }
                searchBarTerm={ searchBarTerm }
            />

            <ApartmentAddressesList
                searchBarTerm={ searchBarTerm }
            />
        </div>
    );
}

export default ApartmentAddresses;
