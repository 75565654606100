import * as api from "../../_api";

import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const updatePasswordChange = (password) => dispatch => {
    dispatch({
        type: actions.common.API__PASSWORD_CHANGE__UPDATE__STARTED
    });

    return api
        .common
        .passwordChange
        .updatePasswordChange(password)
        .then(response => {
            dispatch({
                type: actions.common.API__PASSWORD_CHANGE__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.common.API__PASSWORD_CHANGE__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const resetPasswordChange = () => {
    return {
        type: actions.common.API__PASSWORD_CHANGE__RESET
    };
};