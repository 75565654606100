import React, { Component } from "react";
import EkostraznikQrCodesGeneration from "./qr_codes_generation";
import EkostraznikQrCodesDownload from "./qr_codes_download";

class EkostraznikQrCodes extends Component {
    state = {
        activeTab: 0
    }

    render() {

        return (
            <article>
                <div className='is-spaced--bottom-40'>
                    <header className="row page-header">
                        <h1 className="title is-1">
                            Naklejki z kodami QR
                        </h1>
                    </header>
                    <section className='container is-fluid '>
                        <div className="row row--options">
                            <div className='full-width'>
                                <div className="tabs">
                                    <ul className="tabs-list">
                                        <li onClick={ () => this.setState({ activeTab: 0 }) }
                                            className={ `tabs-item ${ this.state.activeTab === 0 ? 'is-active' : '' }` }>
                                            <span className="tab-item">Generowanie naklejek</span>
                                        </li>
                                        <li onClick={ () => this.setState({ activeTab: 1 }) }
                                            className={ `tabs-item ${ this.state.activeTab === 1 ? 'is-active' : '' }` }>
                                            <span className="tab-item">Pobierz naklejki</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div style={{maxWidth: '976px'}}>
                    {
                        this.state.activeTab === 0 &&
                        <EkostraznikQrCodesGeneration/>
                    }

                    {
                        this.state.activeTab === 1 &&
                        <EkostraznikQrCodesDownload/>
                    }
                </div>

            </article>
        );
    }
}

export default EkostraznikQrCodes;
