import React, { Component } from "react";
import FormModal from "../../../../common/modal/form_modal";
import { connect } from "react-redux";
import CheckboxInput from "../../../../common/checkbox_input";
import { generateQrCodes } from "../../../../../data/actions/ekostraznik/qr_codes";
import { showToast } from "../../../../../data/actions/common/ui/toast";
import { toastFor400, toastFor500, toastForSuccess } from "../../../../../core/utils/toast_utils";

class EkostraznikQrCodesGenerationForm extends Component {
    state = {
        selectedBins: {},
        validationError: false,
        isProcessing: false
    }

    render() {
        const { bins, triggerComponent } = this.props;

        return (
            <FormModal
                isLoading={ this.state.isProcessing }
                triggerComponent={ triggerComponent }
                headerText={ "Wybierz frakcje, dla których mają być wygenerowane naklejki z kodami QR" }
                saveOrCancelButtons={ true }
                _actionFunction={
                    () => this._onSubmit(
                        Object.keys(this.state.selectedBins).filter(binId => this.state.selectedBins[binId])
                    )
                }
                submitDisabled={ Object.values(this.state.selectedBins).filter(isSelected => isSelected).length < 1 }
                saveButtonText="Wygeneruj naklejki z kodami QR"
            >
                <div className='c-modal__body modal-size-l'>
                    {
                        bins && bins.data &&
                        <div>
                            Naklejki będą dostępne do pobrania w zakładce "Pobierz naklejki"

                            <div>
                                <div
                                    className='is-spaced--bottom-10 is-spaced--top-20 is-clickable'
                                    onClick={ () => this.setState({
                                        selectedBins: Object.values(bins.data).reduce((prev, bin) => ({
                                            ...prev,
                                            [bin.id]: true
                                        }), {})
                                    })}
                                >
                                    <strong className='unstyled-btn is-uppercase'>ZAZNACZ WSZYSTKIE</strong>
                                </div>
                                {
                                    Object.values(bins.data).map(bin => (
                                        <div key={ bin.id } className='is-spaced--top-10'>
                                            <CheckboxInput
                                                label={ bin.name }
                                                checked={ this.state.selectedBins[bin.id] }
                                                _onInputChange={ () => this.setState({
                                                    selectedBins: {
                                                        ...this.state.selectedBins,
                                                        [bin.id]: !this.state.selectedBins[bin.id]
                                                    }
                                                })}
                                            />
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                    }
                </div>
            </FormModal>
        );
    }

    _onSubmit = (bins) => {
        const { generateQrCodes, ignoreGenerated, listOnly, _onFinished } = this.props;
        this.setState({ isProcessing: true })

        return generateQrCodes(bins, ignoreGenerated, listOnly)
            .then(() => {
                _onFinished && _onFinished();

                const { qrCodesGenerate, showToast } = this.props;
                this.setState({ isProcessing: false })

                toastFor500(showToast, qrCodesGenerate, 'Generowanie nieudane, spróbuj ponownie');
                toastFor400(showToast, qrCodesGenerate, 'Generowanie nieudane, spróbuj ponownie');
                toastForSuccess(showToast, qrCodesGenerate, 'Generowanie rozpoczęte');
            });
    }
}

const mapStateToProps = (state) => {
    return {
        qrCodesGenerate: state.entities.ekostraznik.qrCodes.generate,
    };
};

export default connect(mapStateToProps, { generateQrCodes, showToast })(EkostraznikQrCodesGenerationForm);
