import React, { Component } from "react";
import { DateUtils } from "react-day-picker";
import moment from "moment";

import BulkyWasteCreateAndEditModalFormContentDateSelected from "./selected";
import BulkyWasteCreateAndEditModalFormContentDateCalendar from "./calendar";
import { MISC_DATE_ISO_DATE_ONLY_FORMAT } from "../../../../../../../../../core/constants";
import { isNullOrUndefined } from "../../../../../../../../../core/utils/misc_utils";

class BulkyWasteCreateAndEditModalFormDate extends Component {

    state = {
        selectedDays: []
    };

    render = () => {
        const { formikBag, readOnly, required } = this.props;
        const { dates } = formikBag.values;
        const { selectedDays } = this.state;

        return (
            <>
                <BulkyWasteCreateAndEditModalFormContentDateCalendar
                    required={ required }
                    errors={ formikBag.errors.dates }
                    selectedDays={ selectedDays }
                    _onDayClick={ !readOnly ? this._onDayClick : () => {
                    } }/>

                <BulkyWasteCreateAndEditModalFormContentDateSelected
                    dates={ dates }
                    readOnly={ readOnly }
                    _onSelectedDayClick={ this._onSelectedDayClick }/>
            </>
        );
    };

    componentDidMount = () => {
        this.updateSelectedDays();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { formikBag } = this.props;
        const { dates } = formikBag.values;

        if (isNullOrUndefined(prevProps.formikBag) ||
            isNullOrUndefined(prevProps.formikBag.values) ||
            prevProps.formikBag.values.dates !== dates) {
            this.updateSelectedDays();
        }
    }

    shouldComponentUpdate = (nextProps, nextState, nextContext) => {
        const { formikBag, readOnly } = this.props;
        const { dates } = formikBag.values;
        const { selectedDays } = this.state;

        if (nextProps.readOnly !== readOnly) {
            return true;
        }

        if (!isNullOrUndefined(nextProps.formikBag) &&
            !isNullOrUndefined(nextProps.formikBag.values) &&
            nextProps.formikBag.values.dates !== dates) {
            return true;
        }

        if (nextState.selectedDays !== selectedDays) {
            return true;
        }

        if (!isNullOrUndefined(nextProps.formikBag.errors.dates) ||
            nextProps.formikBag.errors.dates !== formikBag.errors.dates){
            return true;
        }

        return false;
    };

    updateSelectedDays = () => {
        const { dates } = this.props.formikBag.values;
        this.setState({
            selectedDays: this.generateSelectedDaysFromString(dates)
        });
    };

    _onDayClick = (day, { selected }) => {
        const { selectedDays } = this.state;
        const { formikBag } = this.props;

        if (selected) {
            const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );

            selectedDays.splice(selectedIndex, 1);
        } else {
            selectedDays.push(day);
        }
        this.setState({
            selectedDays
        }, () => {
            formikBag.setFieldValue("dates", this.generateSelectedDaysAsString(selectedDays));
        });
    };

    _onSelectedDayClick = (dayString) => {
        const { selectedDays } = this.state;
        const { formikBag } = this.props;

        const day = moment(dayString, MISC_DATE_ISO_DATE_ONLY_FORMAT)
            .toDate();

        const selectedIndex = selectedDays.findIndex(selectedDay =>
            DateUtils.isSameDay(selectedDay, day)
        );

        selectedDays.splice(selectedIndex, 1);

        this.setState({
            selectedDays
        }, () => {
            formikBag.setFieldValue("dates", this.generateSelectedDaysAsString(selectedDays));
        });
    };

    generateSelectedDaysFromString = (dates) => {
        return dates
            .map(date => moment(date, MISC_DATE_ISO_DATE_ONLY_FORMAT)
                .toDate())
            .filter(date => !isNullOrUndefined(date));
    };

    generateSelectedDaysAsString = (dates) => {
        return dates
            .map(date => moment(date)
                .format(MISC_DATE_ISO_DATE_ONLY_FORMAT))
            .filter(date => !isNullOrUndefined(date));
    };
}


export default BulkyWasteCreateAndEditModalFormDate;