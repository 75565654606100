import webClient from "../../../core/web_client";
import { API_BASE_AKUN_GOK_PATH } from '../../../core/constants';

const PATH = `${ API_BASE_AKUN_GOK_PATH }/bins`;

export const getManyBins = () => {
    return webClient
        .get(`${ PATH }`);
};

export const addBin = (data) => {
    return webClient
        .post(`${ PATH }`, data);
};

export const deleteBin = (binId) => {
    return webClient
        .delete(`${ PATH }/${ binId }`);
};