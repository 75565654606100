import React from "react";

import SignInLayoutFooter from "./footer.js";
import SignInIllustration from "../../../../../resource/img/sign_in_illustration.png";
import LogoSm from "../../../../../resource/img/logo_sm.png";
import FlagEu from "../../../../../resource/img/eu_flag.png";

const SignInLayout = ({ children }) => {

    const childrenWithKey = React.Children.map(children, child => {
        return React.cloneElement(child, {
            key: child
        });
    });

    return (
        <div className="main-container--signed-out">
            <div className="main-container--signed-out__left">
                <div className="signed-out-page">
                    <div className="">
                        <img src={ LogoSm } className="signed-out-page__logo" alt="Logo Strefy Mieszkańca"/>
                        <img src={ FlagEu } className="signed-out-page__ue" alt="Flaga Unii Europejskiej"/>
                        <div className='signed-out-page__main-content'>
                            {
                                childrenWithKey
                            }
                        </div>
                        <SignInLayoutFooter/>
                    </div>
                </div>
            </div>
            
            <div className="main-container--signed-out__right">
                <div className="signed-out-img">
                    <img src={ SignInIllustration } alt=""/>
                </div>
            </div>
        </div>
    );
};

export default SignInLayout;