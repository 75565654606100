import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import ServicesCardAddAndEditResponsibleEntityModalFormContent from "./content";
import FormModal from "../../../../../../common/modal/form_modal";
import { addResponsibleEntity, getManyResponsibleEntities, updateResponsibleEntity } from "../../../../../../../data/actions/services/responsible_entities";
import { isEmptyObject, isEmptyString, isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";
import { REGEX_EMAIL_SIMPLE } from "../../../../../../../core/constants";

const initialFormValues = {
    name: "",
    address: "",
    postcode: "",
    phoneNumber: "",
    emailAddress: "",
    openingHours: ""
};

class AddOrEditResponsibleEntityModalForm extends Component {

    render = () => {
        const { responsibleEntity, triggerComponent, headerText, ariaLabel, withBgHeader } = this.props;

        const initialValues =
            !isNullOrUndefined(responsibleEntity)
                ? {
                    ...responsibleEntity,
                    id: undefined
                }
                : initialFormValues;

        return (
            <Formik
                initialValues={ initialValues }
                render={ formikBag => (
                    <FormModal
                        triggerComponent={ triggerComponent }
                        saveOrCancelButtons={ true }
                        ariaLabel={ ariaLabel }
                        withBgHeader={ withBgHeader }
                        headerText={ headerText }
                        _actionFunction={ () => {
                            return this._onSubmit(formikBag);
                        } }>

                        <ServicesCardAddAndEditResponsibleEntityModalFormContent errors={ formikBag.errors }
                                                                                 formikResetForm={ formikBag.resetForm }/>

                    </FormModal>
                ) }/>
        );
    };

    _validate = (values) => {
        const errors = {};

        if (isEmptyString(values.name)) {
            errors.name = "Pole wymagane";
        }

        if (!isEmptyString(values.emailAddress) && !REGEX_EMAIL_SIMPLE.test(values.emailAddress)) {
            errors.emailAddress = "Niepoprawny format adresu email";
        }

        return errors;
    };

    _onSubmit = (formikBag) => {
        const { responsibleEntity, addResponsibleEntity, updateResponsibleEntity, getManyResponsibleEntities } = this.props;
        const errors = this._validate(formikBag.values);

        if (!isEmptyObject(errors)) {
            formikBag.setErrors(errors);
            return Promise.reject(null);
        }

        // update
        if (!isNullOrUndefined(responsibleEntity)) {
            return updateResponsibleEntity(responsibleEntity.id, formikBag.values)
                .then(() => getManyResponsibleEntities());
        }
        // add
        else {
            return addResponsibleEntity(formikBag.values)
                .then(() => getManyResponsibleEntities());
        }
    };
}

export default connect(null, { addResponsibleEntity, updateResponsibleEntity, getManyResponsibleEntities })(AddOrEditResponsibleEntityModalForm);