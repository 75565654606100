import React from "react";

import withStyleButton from "./with_style_button";

const StandardStyleButton = ({ active, _renderIcon, _onChange }) => {

    let className = "RichEditor-styleButton";
    if (active) {
        className += " RichEditor-activeButton";
    }

    return (
        <span className={ className }
              onMouseDown={ _onChange }>

            {
                _renderIcon()
            }

        </span>
    );
};

export default withStyleButton(StandardStyleButton);