import add from "./add";
import get from "./get";
import getMany from "./get_many";
import updateStatus from "./update_status";
import employees from "./employees";
import messages from "./messages";
import statuses from "./statuses";
import types from "./types";
import sendStatusChangeConfirmation from "./send_status_change_confirmation";

export default (state = {}, action) => {
    return {
        ...state,
        add: add(state.add, action),
        get: get(state.get, action),
        getMany: getMany(state.getMany, action),
        updateStatus: updateStatus(state.updateStatus, action),
        employees: employees(state.employees, action),
        messages: messages(state.messages, action),
        statuses: statuses(state.statuses, action),
        types: types(state.types, action),
        sendStatusChangeConfirmation: sendStatusChangeConfirmation(state.sendStatusChangeConfirmation, action)
    };
}