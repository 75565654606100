import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { MISC_DATE_FORMAT, ROUTE_DETAILED_INSPECTION } from "../../../../../../core/constants";
import { isNullOrUndefined } from "../../../../../../core/utils/misc_utils";

import { ReactComponent as IconAlert } from "../../../../../../../resource/img/icons/icon_alert_triangle.svg";

class InspectionsListItem extends Component{

    state = {
        shouldRedirectToInspectionDetails: false
    };

    render = () => {
        const { listItem } = this.props;

        if (this.state.shouldRedirectToInspectionDetails) {
            if (!isNullOrUndefined(listItem.location))
            {
                return <Redirect to={ { pathname: ROUTE_DETAILED_INSPECTION("adres", listItem.location.id.replace(/\//g, "-")) }}/>;
            }
            else
            {
                return <Redirect to={ { pathname: ROUTE_DETAILED_INSPECTION("grupa", listItem.group.id) }}/>;
            }
        }

        return (
            <tr className="tr--clickable"
                onClick={ () => this.setState({ shouldRedirectToInspectionDetails: true })}>
                <td className='data--flexible'>

                    {
                        listItem.inspectionThresholdExceeded &&

                        <span className="tr__alert-icon tr__alert-icon--error">
                            <IconAlert/>
                        </span>
                    }


                    {
                        this.renderGroupedInspectionName(listItem)
                    }
                </td>
                <td className='t-width-3 justify-end'>
                    {
                        moment(listItem.lastInspectionDate)
                            .format(MISC_DATE_FORMAT)
                    }
                </td>
            </tr>
        );
    };

    renderGroupedInspectionName = (listItem) => {

        if (!isNullOrUndefined(listItem.location))
        {
            if (!isNullOrUndefined(listItem.location.street)) {
                return listItem.location.town + " " + listItem.location.street + " " + listItem.location.address
            } else {
                return listItem.location.town + " " + listItem.location.address
            }
        }
        else
        {
            return listItem.group.name
        }
    }
}

export default InspectionsListItem;