import React, { Component } from "react";

const withMPagination = (OriginalComponent) => {

    class WrappedComponent extends Component {

        state = {
            currentPage: 0,
            maxPage: 0
        };

        render = () => {
            return (
                <OriginalComponent
                    currentPage={ this.state.currentPage }
                    setMaxPage={ this.setMaxPage }
                    _onPrevClick={ this._onPrevClick }
                    _onNextClick={ this._onNextClick }
                    _onPageNumberInputValueChange={ this._onPageNumberInputValueChange }
                    { ...this.props }/>
            );
        };

        setMaxPage = (maxPage) => {
            this.setState({ maxPage });
        };

        _onPrevClick = () => {
            if (this.state.currentPage === 0) {
                return;
            }

            const prevPage = this.state.currentPage - 1;

            this.setState({ currentPage: prevPage });
        };

        _onNextClick = () => {
            if (this.state.currentPage === this.state.maxPage - 1) {
                return;
            }

            const nextPage = this.state.currentPage + 1;

            this.setState({ currentPage: nextPage });
        };

        _onPageNumberInputValueChange = (value) => {
            this.setState({ currentPage: value });
        };
    }

    return WrappedComponent;
};

export default withMPagination;