import React from "react";
import { useDispatch, useSelector } from "react-redux";
import FormModal from "../../../../../common/modal/form_modal";
import { ReactComponent as IconDelete } from "../../../../../../../resource/img/icons/icon_delete.svg";
import { deleteCommunalDocument } from "../../../../../../data/actions/waste_management/communal_documents";

const DeleteCommunalDocumentForm = ({ communalDocument, _onSuccess }) => {
    const dispatch = useDispatch()
    const deleteStatus = useSelector(state => state.entities?.wasteManagement?.communalDocuments?.del)

    return (
        <FormModal
            triggerComponent={
                <button className='unstyled-btn' title='Usuń'>
                    <IconDelete/>
                </button>
            }
            isLoading={ deleteStatus?.meta?.loading }
            deleteOrCancelButtons={ true }
            _actionFunction={ () =>
                dispatch(deleteCommunalDocument(communalDocument?.id))
                    .then(async () => _onSuccess())
            }
        >

            <div className='c-modal__body modal-size-m modal--no-header'>
                <p className="confirmation-alert">
                    Czy na pewno chcesz usunąć ten plik?
                </p>
            </div>

        </FormModal>
    );
}

export default DeleteCommunalDocumentForm;