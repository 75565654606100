import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import withMap from "../../../../../../../common/hoc/with_map";
import AddOrEditNestModalForm from "./add_or_edit_form";
import { MISC_MAPBOX_DEFAULT_MAP_CENTER_COORDINATES } from "../../../../../../../../core/constants";
import mapboxgl from "../../../../../../../../core/mapbox";
import { useDispatch, useSelector } from 'react-redux';
import DeleteNestForm from "./delete_form";
import { ReactComponent as IconEdit } from "../../../../../../../../../resource/img/icons/icon_edit.svg";

const Nests = ({ groupId, nests }) => {
    const [nestIdToEdit, setNestIdToEdit] = useState();

    return (
        <div className='nests'>
            <section className='main-content--table__top'>
                <section className='container is-fluid'>
                    <div className="row row--separated row--options">
                        <div className='row__left'>
                            <div className='is-v-centered'>
                                <div className='nests__counter'>
                                    { nests.length }
                                </div>
                                <div className='nests__counter-text'>
                                    Liczba wiat śmietnikowych
                                </div>
                            </div>
                        </div>
                        <div className='row__right'>
                            <AddOrEditNestModalForm
                                groupId={ groupId }
                            />
                        </div>
                    </div>
                </section>
            </section>
            <Map
                latitude={ MISC_MAPBOX_DEFAULT_MAP_CENTER_COORDINATES.lat }
                longitude={ MISC_MAPBOX_DEFAULT_MAP_CENTER_COORDINATES.lng }
                nests={ nests }
                withoutInitialMarker
                withFullScreenControl
                groupId={ groupId }
                _onNestEditClick={ nestId => setNestIdToEdit(nestId) }
            />
            {
                nests?.map(nest => (
                    <AddOrEditNestModalForm
                        key={ nest.id }
                        nest={ nest }
                        groupId={ groupId }
                        isOpen={ nestIdToEdit === nest.id }
                        _onFormClose={ () => {
                            setNestIdToEdit(undefined)
                        } }
                    />
                ))
            }
        </div>
    )
}
export default withMap(Nests);

const Map = withMap(({ mapboxContainerRef, mapboxMap, nests, groupId, _onNestEditClick }) => {
    const [initialized, setInitialized] = useState(false);

    useEffect(
        () => {
            if (initialized || !mapboxMap || !(nests?.length > 0)) {
                return;
            }

            setInitialized(true);

            let minLat, maxLat, minLng, maxLng = null;

            for (let nest of (nests || [])) {

                const { longitude, latitude } = nest;

                minLat = !minLat || minLat > latitude ? latitude : minLat;
                maxLat = !maxLat || maxLat < latitude ? latitude : maxLat;
                minLng = !minLng || minLng > longitude ? longitude : minLng;
                maxLng = !maxLng || maxLng < longitude ? longitude : maxLng;
            }

            mapboxMap.fitBounds([
                [Number(minLng), Number(minLat)],
                [Number(maxLng), Number(maxLat)],
            ], { linear: true, padding: 50 })
        },
        [mapboxMap, nests, initialized]
    )

    return (
        <div
            className='nests__map'
            ref={ mapboxContainerRef }
        >
            {
                mapboxMap &&
                nests.map(nest =>
                    <Marker
                        key={ nest.id }
                        nest={ nest }
                        mapboxMap={ mapboxMap }
                        groupId={ groupId }
                        _onEditClick={ nestId => {
                            _onNestEditClick(nestId);
                            document.fullscreenElement && document.exitFullscreen();
                        } }
                        _onDeleteClick={ () => document.fullscreenElement && document.exitFullscreen() }
                    />
                )
            }
        </div>
    );
})

const Marker = ({ nest, mapboxMap, groupId, _onEditClick, _onDeleteClick }) => {
    const dispatch = useDispatch();
    const geocoding = useSelector(state => state.entities.common.geocoding);

    const markerElement = document.createElement('div');
    const popupElement = document.createElement('div');
    markerElement.className = 'nest-marker';
    popupElement.className = 'nest-marker-popup';

    const marker = useMemo(
        () => new mapboxgl.Marker(markerElement),
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            marker.remove();

            ReactDOM.render(
                <PopupContent
                    nest={ nest }
                    groupId={ groupId }
                    geocoding={ geocoding }
                    _dispatch={ dispatch }
                    _onDeleteSuccess={ () => marker.remove() }
                    _onEditClick={ _onEditClick }
                    _onDeleteClick={ _onDeleteClick }
                />,
                popupElement,
                () => {
                    marker
                        .setLngLat([nest.longitude, nest.latitude])
                        .setPopup(
                            new mapboxgl.Popup({ closeButton: false, offset: [0, -52] })
                                .setDOMContent(popupElement)
                        ).addTo(mapboxMap);

                }
            );
        },
        // eslint-disable-next-line
        [nest.name, nest.longitude, nest.latitude]
    );

    return <></>;
};

const PopupContent = ({ groupId, nest, _dispatch, _onDeleteSuccess, _onEditClick, _onDeleteClick }) => {
    return (
        <>
            <span>{ nest.name }</span>
            <div className='nest-marker-popup__actions'>
                <IconEdit onClick={ () => _onEditClick(nest.id) }/>
                <DeleteNestForm
                    groupId={ groupId }
                    nestId={ nest.id }
                    _dispatch={ _dispatch }
                    _onSuccess={ () => {
                        _onDeleteSuccess();
                    } }
                    _onDeleteClick={ _onDeleteClick }
                />
            </div>
        </>
    );
}