import React from "react";
import ReactSelect, { components } from "react-select";
import ReactCreatableSelect from "react-select/creatable";

const Select = (props) => {
    const isMulti = props.isMulti ?? false;
    const isSearchable = props.isSearchable ?? false;
    const isCreatable = props.isCreatable ?? false;

    const defaultProps = {
        isClearable: true,
        classNamePrefix: "react-select",
        noOptionsMessage: () => "Brak opcji",
        loadingMessage: () => "Trwa ładowanie danych",
        isSearchable: false,
        closeMenuOnSelect: !isMulti,
        hideSelectedOptions: false,
        placeholder: '- Wybierz z listy -',
        styles: selectCustomStyles,
        createOptionPosition: 'first',
        formatCreateLabel: inputValue => (<><span className='react-select__new-vale-label'>UTWÓRZ NOWE:</span> {inputValue}</>)
    };

    if (isMulti) {
        defaultProps["placeholder"] = '- Wybierz z listy. Możesz wybrać kilka -';

        if (!isSearchable) {
            defaultProps["components"] = { Menu: MenuWithSelectAll, ...props.components };
        }
    }

    const selectProps = {
        ...defaultProps,
        ...props,
        className: 'react-select-container ' + (props.className || '') + (isSearchable ? ' react-select--is-searchable' : ''),
    };

    return (
        <div className={ 'select-wrapper ' + (props.className || '') }>
            {
                isCreatable &&
                <ReactCreatableSelect
                    { ...selectProps }
                />
            }
            {
                !isCreatable &&
                <ReactSelect
                    { ...selectProps }
                />
            }
        </div>
    );
}
export default Select;

export const SelectWithIcons = (props) => {
    return (
        <Select
            {...props}
            className='select-with-icons'
            components={{SingleValue: SingleValueWithIcon, Option: OptionWithIcon, ...props.components}}
        />
    );
}

const MenuWithSelectAll = (props) => {
    const { children, selectProps: { onChange, options, value } } = props;

    const allChecked = options.length && options.length === value?.length;

    return (
        <components.Menu { ...props }>
            <div
                className='select-all-button'
                onClick={ () => {
                    onChange(allChecked ? [] : options, { action: 'set-value' });
                } }
            >
                { allChecked ? 'ODZNACZ WSZYSTKIE' : 'ZAZNACZ WSZYSTKIE' }
            </div>
            { children }
        </components.Menu>
    );
}


export const SingleValueWithIcon = (props) => (
    <components.SingleValue {...props} className="select-with-icons__single-value">
        {
            props.data.icon &&
            <span className="select-with-icons__icon">
                { props.data.icon }
            </span>
        }

        <span>
            { props.data.label }
        </span>
    </components.SingleValue>
);

export const OptionWithIcon = (props) => {
    const { selectProps, data } = props;

    let activeElement = "";
    if (selectProps.value.value && data.value === selectProps.value.value) {
        activeElement = "active";
    }

    let disabledElement = "";
    if (data.isDisabled) {
        disabledElement = "select-with-icons__option-disabled";
    }

    return (
        <components.Option {...props} className={ `select-with-icons__option ${ activeElement } ${ disabledElement }` }>
            {
                data.icon &&
                <span className="select-with-icons__icon">
                    { data.icon }
                </span>
            }
            <span>
                { data.label }
            </span>
        </components.Option>
    );
};


export const selectCustomStyles = {
    control: (provided, state) => ({
        ...provided,
        border: "1px solid #7a8fb5",
        fontSize: "14px",
        boxShadow: state.isFocused ? "0 0 0 3px #d6e2f7" : null,
        "&:hover": {
            boxShadow: "0 0 0 3px #d6e2f7",
        }
    }),
    dropdownIndicator: provided => ({
        ...provided,
        color: "#173870",
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
    menu: provided => ({
        ...provided,
        boxShadow: "0 2px 20px 0 #eceff4",
        borderRadius: "5px",
    }),
    input: () => ({
        margin: 0,
        padding: '0 6px',
    }),

    valueContainer: provided => ({
        ...provided,
        padding: "10px 15px",
        margin: 0
    })
};

export const selectBgCustomStyles = {
    ...selectCustomStyles,
    control: (provided, state) => ({
        ...provided,
        border: "none",
        background: "#eef1f5",
        fontSize: "14px",
        boxShadow: state.isFocused ? "0 0 0 3px #d6e2f7" : null,
        "&:hover": {
            boxShadow: "0 0 0 3px #d6e2f7",
        }
    }),
    singleValue: provided => ({
        ...provided,
        color: "#173870",
        fontWeight: "500",
        fontSize: "18px"
    }),
};