import React from "react";
import { useDispatch } from "react-redux";
import EditCommunalDocumentForm from "../form/edit_form";
import DeleteCommunalDocumentForm from "../form/delete_form";
import {
    updateCommunalDocumentHidden,
    downloadCommunalDocument
} from "../../../../../../data/actions/waste_management/communal_documents";
import { ReactComponent as IconDownload } from "../../../../../../../resource/img/icons/icon_download.svg";

const CommunalDocumentsListItem = ({ communalDocument, _onDeleteOrUpdateSuccess }) => {
    const dispatch = useDispatch();

    return (
        <tr>
            <td className='t-width-7'>
                { communalDocument.filename }
            </td>
            <td className='t-width-3 flex-center'>
                { formatFileSize(communalDocument.fileSize) }
            </td>
            <td className='t-width-2 flex-center'>
                {
                    <div className="input-field options-item">
                        <button
                            className={ `btn  btn--short btn--small ${ communalDocument.hidden ? 'btn--primary' : 'btn--outlined' }` }
                            onClick={ () =>
                                dispatch(updateCommunalDocumentHidden(communalDocument?.id, !communalDocument?.hidden))
                                    .then(async () => _onDeleteOrUpdateSuccess()) }>
                            { communalDocument.hidden ? 'Opublikuj' : 'Ukryj' }
                        </button>
                    </div>
                }
            </td>
            <td className='sticky--right td-action-buttons'>
                <button className='unstyled-btn' title='Pobierz'
                        onClick={ () => dispatch(downloadCommunalDocument(communalDocument?.id, communalDocument?.originalFilename)) }>
                    <IconDownload/>
                </button>

                <EditCommunalDocumentForm
                    communalDocument={ communalDocument }
                    _onSuccess={ _onDeleteOrUpdateSuccess }/>

                <DeleteCommunalDocumentForm
                    communalDocument={ communalDocument }
                    _onSuccess={ _onDeleteOrUpdateSuccess }/>
            </td>
        </tr>
    );
};

export default CommunalDocumentsListItem;

const formatFileSize = (sizeInBytes) => {
    if (sizeInBytes < 1024) {
        return `${ sizeInBytes } B`;
    }

    if (sizeInBytes < 1024 * 1024) {
        return `${ (sizeInBytes / 1024).toFixed(2) } KB`;
    }

    return `${ (sizeInBytes / 1024 / 1024).toFixed(2) } MB`;
}