import React from "react";

import SidebarItemWithSubmenu from "../sidebar_item_with_submenu";
import SidebarSubmenuItem from "../sidebar_submenu_item";
import SidebarItem from "../sidebar_item";
import { SidebarEnum } from "../../../../../core/enums/common/sidebar";
import { PermissionEnum } from "../../../../../core/enums/common/permissions";
import RestrictedView from "../../../../common/restricted_view";

const WasteManagementSidebarVariant = ({ activeSidebarItem, submenuExpanded, activeSubmenuItem, _onSidebarItemSelected, _onSubmenuItemSelected, _onSidebarItemWithSubmenuSelected }) => {
    return (
        <>
            <RestrictedView
                neededPermission={ [
                    PermissionEnum.module.WASTE_MANAGEMENT.type.MUNICIPAL_WASTE.full_code,
                    PermissionEnum.module.WASTE_MANAGEMENT.type.BULKY_WASTE.full_code,
                    PermissionEnum.module.WASTE_MANAGEMENT.type.WASTE_COLLECTION_POINTS.full_code
                ] }>

                <SidebarItemWithSubmenu
                    sidebarItemId={ SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.id }
                    sidebarItemName={ SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.name }
                    sidebarItemIcon={ SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.icon }
                    submenuExpanded={ submenuExpanded }
                    activeSidebarItem={ activeSidebarItem }
                    _onSidebarItemWithSubmenuSelected={ _onSidebarItemWithSubmenuSelected }>

                    <RestrictedView
                        neededPermission={ PermissionEnum.module.WASTE_MANAGEMENT.type.MUNICIPAL_WASTE.full_code }>
                        <SidebarSubmenuItem
                            sidebarSubmenuItemId={ SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.MUNICIPAL_WASTE.id }
                            sidebarItemName={ SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.MUNICIPAL_WASTE.name }
                            sidebarSubmenuLink={ SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.MUNICIPAL_WASTE.link }
                            activeSubmenuItem={ activeSubmenuItem }
                            _onSubmenuItemSelected={ _onSubmenuItemSelected }/>
                    </RestrictedView>

                    <RestrictedView
                        neededPermission={ PermissionEnum.module.WASTE_MANAGEMENT.type.BULKY_WASTE.full_code }>
                        <SidebarSubmenuItem
                            sidebarSubmenuItemId={ SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.BULKY_WASTE.id }
                            sidebarItemName={ SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.BULKY_WASTE.name }
                            sidebarSubmenuLink={ SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.BULKY_WASTE.link }
                            activeSubmenuItem={ activeSubmenuItem }
                            _onSubmenuItemSelected={ _onSubmenuItemSelected }/>
                    </RestrictedView>

                    <RestrictedView
                        neededPermission={ PermissionEnum.module.WASTE_MANAGEMENT.type.WASTE_COLLECTION_POINTS.full_code }>
                        <SidebarSubmenuItem
                            sidebarSubmenuItemId={ SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.WASTE_COLLECTION_POINTS.id }
                            sidebarItemName={ SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.WASTE_COLLECTION_POINTS.name }
                            sidebarSubmenuLink={ SidebarEnum.WASTE_MANAGEMENT.WASTE_MANAGEMENT.WASTE_COLLECTION_POINTS.link }
                            activeSubmenuItem={ activeSubmenuItem }
                            _onSubmenuItemSelected={ _onSubmenuItemSelected }/>
                    </RestrictedView>

                </SidebarItemWithSubmenu>
            </RestrictedView>

            <RestrictedView
                neededPermission={ PermissionEnum.module.WASTE_MANAGEMENT.type.MESSAGES.full_code }>

                <SidebarItemWithSubmenu
                    sidebarItemId={ SidebarEnum.WASTE_MANAGEMENT.MESSAGES.id }
                    sidebarItemName={ SidebarEnum.WASTE_MANAGEMENT.MESSAGES.name }
                    sidebarItemIcon={ SidebarEnum.WASTE_MANAGEMENT.MESSAGES.icon }
                    submenuExpanded={ submenuExpanded }
                    activeSidebarItem={ activeSidebarItem }
                    _onSidebarItemWithSubmenuSelected={ _onSidebarItemWithSubmenuSelected }>

                    <SidebarSubmenuItem
                        sidebarSubmenuItemId={ SidebarEnum.WASTE_MANAGEMENT.MESSAGES.SEND_MESSAGE.id }
                        sidebarItemName={ SidebarEnum.WASTE_MANAGEMENT.MESSAGES.SEND_MESSAGE.name }
                        sidebarSubmenuLink={ SidebarEnum.WASTE_MANAGEMENT.MESSAGES.SEND_MESSAGE.link }
                        activeSubmenuItem={ activeSubmenuItem }
                        _onSubmenuItemSelected={ _onSubmenuItemSelected }/>

                    <SidebarSubmenuItem
                        sidebarSubmenuItemId={ SidebarEnum.WASTE_MANAGEMENT.MESSAGES.SENT_MESSAGES.id }
                        sidebarItemName={ SidebarEnum.WASTE_MANAGEMENT.MESSAGES.SENT_MESSAGES.name }
                        sidebarSubmenuLink={ SidebarEnum.WASTE_MANAGEMENT.MESSAGES.SENT_MESSAGES.link }
                        activeSubmenuItem={ activeSubmenuItem }
                        _onSubmenuItemSelected={ _onSubmenuItemSelected }/>

                </SidebarItemWithSubmenu>

            </RestrictedView>

            <RestrictedView
                neededPermission={ PermissionEnum.module.WASTE_MANAGEMENT.type.REPORTS.full_code }>
                <SidebarItem
                    sidebarItemId={ SidebarEnum.WASTE_MANAGEMENT.REPORTS.id }
                    sidebarItemName={ SidebarEnum.WASTE_MANAGEMENT.REPORTS.name }
                    sidebarItemIcon={ SidebarEnum.WASTE_MANAGEMENT.REPORTS.icon }
                    sidebarItemLink={ SidebarEnum.WASTE_MANAGEMENT.REPORTS.link }
                    activeSidebarItem={ activeSidebarItem }
                    _onSidebarItemSelected={ _onSidebarItemSelected }/>
            </RestrictedView>

            <RestrictedView
                neededPermission={ PermissionEnum.module.WASTE_MANAGEMENT.type.AREAS.full_code }>
                <SidebarItem
                    sidebarItemId={ SidebarEnum.WASTE_MANAGEMENT.AREAS.id }
                    sidebarItemName={ SidebarEnum.WASTE_MANAGEMENT.AREAS.name }
                    sidebarItemIcon={ SidebarEnum.WASTE_MANAGEMENT.AREAS.icon }
                    sidebarItemLink={ SidebarEnum.WASTE_MANAGEMENT.AREAS.link }
                    activeSidebarItem={ activeSidebarItem }
                    _onSidebarItemSelected={ _onSidebarItemSelected }/>
            </RestrictedView>

            <RestrictedView
                neededPermission={ PermissionEnum.module.WASTE_MANAGEMENT.type.PAYMENTS_DUE_DATE.full_code }>
                <SidebarItem
                    sidebarItemId={ SidebarEnum.WASTE_MANAGEMENT.PAYMENTS_DUE_DATE.id }
                    sidebarItemName={ SidebarEnum.WASTE_MANAGEMENT.PAYMENTS_DUE_DATE.name }
                    sidebarItemIcon={ SidebarEnum.WASTE_MANAGEMENT.PAYMENTS_DUE_DATE.icon }
                    sidebarItemLink={ SidebarEnum.WASTE_MANAGEMENT.PAYMENTS_DUE_DATE.link }
                    activeSidebarItem={ activeSidebarItem }
                    _onSidebarItemSelected={ _onSidebarItemSelected }/>
            </RestrictedView>

            <RestrictedView
                neededPermission={ PermissionEnum.module.WASTE_MANAGEMENT.type.COMPANIES.full_code }>
                <SidebarItem
                    sidebarItemId={ SidebarEnum.WASTE_MANAGEMENT.COMPANIES.id }
                    sidebarItemName={ SidebarEnum.WASTE_MANAGEMENT.COMPANIES.name }
                    sidebarItemIcon={ SidebarEnum.WASTE_MANAGEMENT.COMPANIES.icon }
                    sidebarItemLink={ SidebarEnum.WASTE_MANAGEMENT.COMPANIES.link }
                    activeSidebarItem={ activeSidebarItem }
                    _onSidebarItemSelected={ _onSidebarItemSelected }/>
            </RestrictedView>

            <RestrictedView
                neededPermission={ PermissionEnum.module.WASTE_MANAGEMENT.type.COMMUNAL_DOCUMENTS.full_code }>
                <SidebarItem
                    sidebarItemId={ SidebarEnum.WASTE_MANAGEMENT.COMMUNAL_DOCUMENTS.id }
                    sidebarItemName={ SidebarEnum.WASTE_MANAGEMENT.COMMUNAL_DOCUMENTS.name }
                    sidebarItemIcon={ SidebarEnum.WASTE_MANAGEMENT.COMMUNAL_DOCUMENTS.icon }
                    sidebarItemLink={ SidebarEnum.WASTE_MANAGEMENT.COMMUNAL_DOCUMENTS.link }
                    activeSidebarItem={ activeSidebarItem }
                    _onSidebarItemSelected={ _onSidebarItemSelected }/>
            </RestrictedView>
        </>
    );
};

export default WasteManagementSidebarVariant;
