import React from "react";
import { Link } from "react-router-dom";
import { ROUTE_WASTE_COMPANIES } from "../../../../../../core/constants";

import { ReactComponent as IconBack } from "../../../../../../../resource/img/icons/icon_back_blue.svg";
import { isNullOrUndefined } from "../../../../../../core/utils/misc_utils";

const CompanyDetailsHeader = ({ company }) => {
    return (
        <section>
            <header className='page-header page-header--with-back'>
                <Link to={ ROUTE_WASTE_COMPANIES } className="unstyled-btn">
                    <IconBack/>
                </Link>
                <h1 className='title is-1'>
                    {
                        !isNullOrUndefined(company) &&
                        company.name
                    }
                </h1>
            </header>
        </section>
    );
};

export default CompanyDetailsHeader;
