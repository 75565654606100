import React from "react";

import LocationEditModalForm from "../../../../../../../../common/modal/forms/location_edit_modal";
import AddressTemplateLocationDeleteForm from "../form/delete_form";
import CheckboxInput from "../../../../../../../../common/checkbox_input";

const AddressTemplateLocationItem = ({
                                         location,
                                         _onLocationEditClick,
                                         _onLocationDeleteClick,
                                         _onLocationCheckboxChange,
                                         checkedLocations
                                     }) => (
    <tr>
        <td className='t-width-11 table-text-content'>
            <CheckboxInput
                label={ location.name }
                _onInputChange={ () => _onLocationCheckboxChange(location.name) }
                checked={ checkedLocations.indexOf(location.name) >= 0 }/>
        </td>
        <td className='sticky--right td-action-buttons'>
            <LocationEditModalForm
                location={ location }
                _onLocationEditClick={ _onLocationEditClick }/>
            <AddressTemplateLocationDeleteForm
                location={ location }
                _onLocationDeleteClick={ _onLocationDeleteClick }/>
        </td>
    </tr>
);

export default AddressTemplateLocationItem;