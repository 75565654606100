import React from "react";

import ImageModal from "../modal/image_modal";
import ReportImage from "./image";
import { isEmptyArray, isNullOrUndefined } from "../../../core/utils/misc_utils";

const DocumentGallery = ({ attachmentPaths, label }) => {

    if (isEmptyArray(attachmentPaths)) {
        return null;
    }

    return (
        <div className="report-details__gallery">
            {
                !isNullOrUndefined(label) &&

                <div className="report-details__row">
                    <p className="report-details__label report-details__label--bottom-8">
                        {
                            label
                        }
                    </p>
                </div>
            }

            <div className="report-details__row">
                {
                    attachmentPaths.map(url =>
                        <ImageModal
                            triggerImage={ <ReportImage src={ url }/> }
                            key={ url }>
                            <img src={ url } alt={ url }/>
                        </ImageModal>
                    )
                }
            </div>
        </div>
    );
};

export default DocumentGallery;