import React, { useCallback, useEffect, useState } from "react";

import ApartmentAddressesListItem from "./item";
import ListPagination from "../../../../../common/pagination";
import EmptyListPage from "../../../../../common/empty_list_page";
import IconEmptyGroups from "../../../../../../../resource/img/icons/empty_list_icons/grupy_adresow.png";
import Loader from "../../../../../common/loader";
import withPagination from "../../../../../common/hoc/with_pagination";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteManyApartmentAddresses,
    getAllApartmentAddresses
} from "../../../../../../data/actions/ekostraznik/apartment_addresses";
import { generatePaginationQueryValues } from "../../../../../../core/utils/api_utils";
import CheckboxInput from "../../../../../common/checkbox_input";
import FormModal from "../../../../../common/modal/form_modal";
import { ReactComponent as IconDeleteWhite } from "../../../../../../../resource/img/icons/icon_delete--white-bin.svg";

const defaultPaginationQueryValues = generatePaginationQueryValues(0, 30, [ "name", "asc" ]);

const ApartmentAddressesList = ({ searchBarTerm, _onPrevClick, _onNextClick, _onPageNumberInputValueChange, currentPage, setMaxPage }) => {
    const dispatch = useDispatch();
    const [ checkedApartmentAddresses, setCheckedApartmentAddresses ] = useState([]);
    const apartmentAddresses = useSelector(state => state.entities?.ekostraznik?.apartmentAddresses?.getMany);
    const deleteApartmentAddresses = useSelector(state => state.entities?.ekostraznik?.apartmentAddresses?.deleteMany);

    const totalPages = apartmentAddresses?.data?.totalPages;
    const isApartmentAddressesEmpty = !apartmentAddresses?.data;
    const allChecked = checkedApartmentAddresses.length === apartmentAddresses?.data?.content?.length;

    const _reloadTable = useCallback((searchBarTerm) => {
        dispatch(getAllApartmentAddresses({
            ...defaultPaginationQueryValues,
            page: currentPage,
            query: searchBarTerm
        })).then(() => {
                if (totalPages !== undefined) {
                    setMaxPage(totalPages);
                }

                setCheckedApartmentAddresses([]);
            }
        );
    }, [totalPages, currentPage, dispatch, setMaxPage]);

    useEffect(() => {
        _reloadTable(searchBarTerm);
        // eslint-disable-next-line
    }, [ currentPage, searchBarTerm ]);

    const _onApartmentAddressCheckboxChange = (id) => {
        const apartmentAddressIndex = checkedApartmentAddresses.indexOf(id);

        if (apartmentAddressIndex >= 0) {
            const newCheckedApartmentAddresses = [ ...checkedApartmentAddresses ];
            newCheckedApartmentAddresses.splice(apartmentAddressIndex, 1);

            setCheckedApartmentAddresses(newCheckedApartmentAddresses)
        } else {
            setCheckedApartmentAddresses(prevState => [ ...prevState, id ])
        }
    }

    const _onAllApartmentAddressCheckboxesChange = () => {
        if (allChecked) {
            setCheckedApartmentAddresses([]);
        } else {
            const apartmentAddressIds = apartmentAddresses.data.content
                .map(apartmentAddress => {
                    return apartmentAddress.id
                });
            setCheckedApartmentAddresses(apartmentAddressIds);
        }
    }

    const _onApartmentAddressDeleteClick = (id) => {
        return dispatch(deleteManyApartmentAddresses({
            unitIds: [ id ]
        })).then(() => _reloadTable(searchBarTerm));
    }

    const _onSelectedApartmentAddressesDeleteClick = () => {
        return dispatch(deleteManyApartmentAddresses({
            unitIds: checkedApartmentAddresses
        })).then(() => _reloadTable(searchBarTerm))
    }

    if (apartmentAddresses?.meta?.loading) {
        return (
            <Loader/>
        )
    }

    if (apartmentAddresses?.data?.content?.length === 0) {
        return (
            <EmptyListPage
                img={ IconEmptyGroups }
                imgAlt='Brak grup'
                text={ !searchBarTerm ? 'Wyodrębnij lokale' : 'Brak pasujących wyników' }
            />
        )
    }

    return (
        <>
            <section className="main-content--table__bottom container is-fluid">
                <div className='table-wrapper'>
                    <table className='table--single-line fixed'>
                        <thead>
                        <tr>
                            <th className='t-width-11'>
                                <CheckboxInput
                                    label="Wyodrębnione lokale"
                                    _onInputChange={ _onAllApartmentAddressCheckboxesChange }
                                    checked={ allChecked }/>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            !isApartmentAddressesEmpty &&
                            renderListItems(apartmentAddresses, deleteApartmentAddresses, checkedApartmentAddresses, _onApartmentAddressDeleteClick, _onApartmentAddressCheckboxChange)
                        }
                        </tbody>
                    </table>
                </div>
            </section>

            <section className="container is-fluid is-flex">
                <div className="sll__pagination-wrapper">
                    {
                        checkedApartmentAddresses.length > 0 &&
                        <FormModal
                            triggerComponent={
                                <div className="btn btn--danger btn--small">
                                        <span className="btn__icon-wrapper--left">
                                            <span className="btn__icon">
                                                <IconDeleteWhite/>
                                            </span>
                                            Usuń
                                        </span>
                                </div>
                            }
                            isLoading={ deleteApartmentAddresses?.meta?.loading }
                            deleteOrCancelButtons={ true }
                            _actionFunction={ _onSelectedApartmentAddressesDeleteClick }
                        >

                            <div className='c-modal__body modal-size-m modal--no-header'>
                                <p className="confirmation-alert">
                                    Czy napewno chcesz
                                    usunąć { checkedApartmentAddresses.length === 1 ? 'wybrany adres' : "wybrane adresy" }?<br/>
                                    Usunięcie wpłynie na dane wyświetlane w pozostałych zakładkach.
                                </p>
                            </div>

                        </FormModal>
                    }
                </div>
                <div className="is-flex justify-end pagination--list">
                    {
                        !isApartmentAddressesEmpty &&
                        <ListPagination
                            data={ apartmentAddresses.data }
                            _onPrevClick={ _onPrevClick }
                            _onNextClick={ _onNextClick }
                            _onPageNumberInputValueChange={ _onPageNumberInputValueChange }
                        />
                    }
                </div>
            </section>
        </>
    );
};

const renderListItems = (apartmentAddresses, deleteApartmentAddresses, checkedApartmentAddresses, _onApartmentAddressDeleteClick, _onApartmentAddressCheckboxChange) => {

    return apartmentAddresses.data.content
        .map(apartmentAddress => {
            return (
                <ApartmentAddressesListItem
                    key={ apartmentAddress.id }
                    apartmentAddress={ apartmentAddress }
                    deleteApartmentAddresses={ deleteApartmentAddresses }
                    checkedApartmentAddresses={ checkedApartmentAddresses }
                    _onApartmentAddressDeleteClick={ _onApartmentAddressDeleteClick }
                    _onApartmentAddressCheckboxChange={ _onApartmentAddressCheckboxChange }
                />
            );
        });
};

export default withPagination(ApartmentAddressesList);
