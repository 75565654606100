import React, { useEffect } from "react";
import { LocationsEnum } from "../../core/enums/waste_management/locations";
import withLocationManagement from "./hoc/with_location_management";

const LocationForm = ({ resetForm, _resetForm, _onChange, formType, horizontal, values, renderSuggestions, inputDisabled,
                          _setFieldValue, _onInputFieldFocus, _onInputFieldBlur, _onNewApartmentAddressSelect, errors }) => {
    useEffect(() => {
        resetForm && _resetForm();
    }, [resetForm, _resetForm])
    return (
        <div className="location-form">
            {
                horizontal &&
                <div className="location-form__inputs-group">
                    <label className="location-form__input-wrap is-spaced--right-20">
                        <span className="location-form__label">Miejscowość</span>
                        <input
                            className="location-form__input"
                            type="text"
                            aria-label="Miejscowość"
                            onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.TOWN) }
                            onBlur={ _onInputFieldBlur }
                            value={ values.town }
                            onChange={ event => {
                                _setFieldValue(LocationsEnum.visualType.TOWN, event.target.value)
                                _onChange(values);
                            } }
                        />
                        {
                            renderSuggestions(LocationsEnum.visualType.TOWN)
                        }
                    </label>
                    <label className="location-form__input-wrap is-spaced--right-20">
                        <span className="location-form__label">Ulica</span>
                        <input
                            className="location-form__input"
                            type="text"
                            aria-label="Ulica"
                            onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.STREET) }
                            onBlur={ _onInputFieldBlur }
                            value={ values.street }
                            disabled={ inputDisabled(LocationsEnum.visualType.STREET) }
                            onChange={ event => {
                                _setFieldValue(LocationsEnum.visualType.STREET, event.target.value)
                                _onChange(values);
                            } }
                        />
                        {
                            renderSuggestions(LocationsEnum.visualType.STREET)
                        }
                    </label>
                    {
                        (formType === LocationFormTypeEnum.WITH_BUILDING_NUMBERS_RANGE || formType === undefined) &&
                        <fieldset className="location-form__input-wrap">
                            <legend className="location-form__label">Nr&nbsp;budynku</legend>
                            <div className="location-form__row">
                                <label className="location-form__input-wrap">
                                    <span className="is-sr-only">Od</span>
                                    <input
                                        type="text"
                                        placeholder="Od"
                                        aria-label="Od"
                                        onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.ADDRESS_FROM) }
                                        onBlur={ _onInputFieldBlur }
                                        value={ values.from }
                                        disabled={ inputDisabled(LocationsEnum.visualType.ADDRESS_FROM) }
                                        onChange={ event => {
                                            _setFieldValue(LocationsEnum.visualType.ADDRESS_FROM, event.target.value)
                                            _onChange(values);
                                        } }
                                    />
                                    {
                                        renderSuggestions(LocationsEnum.visualType.ADDRESS_FROM)
                                    }
                                </label>
                                <div className="location-form__dash">-</div>
                                <label className="location-form__input-wrap">
                                    <span className="is-sr-only">Do</span>
                                    <input
                                        type="text"
                                        placeholder="Do"
                                        aria-label="Do"
                                        onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.ADDRESS_TO) }
                                        onBlur={ _onInputFieldBlur }
                                        value={ values.to }
                                        disabled={ inputDisabled(LocationsEnum.visualType.ADDRESS_TO) }
                                        onChange={ event => {
                                            _setFieldValue(LocationsEnum.visualType.ADDRESS_TO, event.target.value);
                                            _onChange(values);
                                        } }
                                    />
                                    {
                                        renderSuggestions(LocationsEnum.visualType.ADDRESS_TO)
                                    }
                                </label>
                            </div>
                        </fieldset>
                    }
                    {
                        formType === LocationFormTypeEnum.WITH_APARTMENT_ADDRESS &&
                        <div className='is-flex'>
                            <label className="location-form__input-wrap is-spaced--right-20">
                                <span className="location-form__label">Nr&nbsp;budynku</span>
                                <input
                                    type="text"
                                    aria-label="Nr budynku"
                                    onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.ADDRESS) }
                                    onBlur={ _onInputFieldBlur }
                                    value={ values.address }
                                    disabled={ inputDisabled(LocationsEnum.visualType.ADDRESS) }
                                    onChange={ event => {
                                        _setFieldValue(LocationsEnum.visualType.ADDRESS, event.target.value)
                                        _onChange(values);
                                    } }
                                />
                                {
                                    renderSuggestions(LocationsEnum.visualType.ADDRESS)
                                }
                            </label>
                            <label className="location-form__input-wrap">
                                <span className="location-form__label">Nr lokalu</span>
                                <input
                                    type="text"
                                    aria-label="Nr lokalu"
                                    onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.APARTMENT_ADDRESS) }
                                    onBlur={ _onInputFieldBlur }
                                    value={ values.apartmentAddress }
                                    disabled={ inputDisabled(LocationsEnum.visualType.APARTMENT_ADDRESS) }
                                    onChange={ event => {
                                        _setFieldValue(LocationsEnum.visualType.APARTMENT_ADDRESS, event.target.value);
                                        _onChange(values);
                                    } }
                                />
                                {
                                    renderSuggestions(LocationsEnum.visualType.APARTMENT_ADDRESS)
                                }
                            </label>
                        </div>
                    }
                </div>
            }
            {
                !horizontal &&
                formType === LocationFormTypeEnum.WITH_APARTMENT_ADDRESS &&
                <section>
                    <div className="location-form__row">
                        <label className="location-form__input-wrap">
                            <span className="location-form__label">Miejscowość</span>
                            <input
                                className="location-form__input"
                                type="text"
                                aria-label="Miejscowość"
                                onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.TOWN) }
                                onBlur={ _onInputFieldBlur }
                                value={ values.town }
                                onChange={ event => {
                                    _setFieldValue(LocationsEnum.visualType.TOWN, event.target.value)
                                    _onChange(values);
                                } }
                            />
                            {
                                renderSuggestions(LocationsEnum.visualType.TOWN)
                            }
                        </label>
                    </div>
                    <div className="location-form__row is-spaced--top-10">
                        <label className="location-form__input-wrap is-spaced--right-20">
                            <span className="location-form__label">Ulica</span>
                            <input
                                className="location-form__input"
                                type="text"
                                aria-label="Ulica"
                                onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.STREET) }
                                onBlur={ _onInputFieldBlur }
                                value={ values.street }
                                disabled={ inputDisabled(LocationsEnum.visualType.STREET) }
                                onChange={ event => {
                                    _setFieldValue(LocationsEnum.visualType.STREET, event.target.value)
                                    _onChange(values);
                                } }
                            />
                            {
                                renderSuggestions(LocationsEnum.visualType.STREET)
                            }
                            {
                                errors?.address &&
                                <span className="hint hint--rejected">
                                {
                                    errors.address
                                }
                                </span>
                            }
                        </label>
                        <label className="location-form__input-wrap-width-50 is-spaced--right-20">
                            <span className="location-form__label">Nr&nbsp;budynku</span>
                            <input
                                type="text"
                                aria-label="Nr budynku"
                                onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.ADDRESS) }
                                onBlur={ _onInputFieldBlur }
                                value={ values.address }
                                disabled={ inputDisabled(LocationsEnum.visualType.ADDRESS) }
                                onChange={ event => {
                                    _setFieldValue(LocationsEnum.visualType.ADDRESS, event.target.value)
                                    _onChange(values);
                                } }
                            />
                            {
                                renderSuggestions(LocationsEnum.visualType.ADDRESS)
                            }
                        </label>
                        <label className="location-form__input-wrap-width-50">
                            <span className="location-form__label">Nr lokalu</span>
                                <input
                                    type="text"
                                    aria-label="Nr lokalu"
                                    onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.APARTMENT_ADDRESS) }
                                    onBlur={ _onInputFieldBlur }
                                    value={ values.apartmentAddress }
                                    disabled={ inputDisabled(LocationsEnum.visualType.APARTMENT_ADDRESS) }
                                    onChange={ event => {
                                         _setFieldValue(LocationsEnum.visualType.APARTMENT_ADDRESS, event.target.value);
                                        _onChange(values);
                                    } }
                                />
                                {
                                    renderSuggestions(LocationsEnum.visualType.APARTMENT_ADDRESS)
                                }
                        </label>
                    </div>
                </section>
            }
            {
                !horizontal &&
                (formType === LocationFormTypeEnum.WITH_BUILDING_NUMBERS_RANGE || formType === LocationFormTypeEnum.WITH_BUILDING_NUMBER || formType === undefined) &&
                <section>
                    <label className='is-spaced-bottom--20'>
                        <div className="location-form__row">
                            <span className='required-accent'>*</span>
                            <span className='label-name'>Miejscowość</span>
                        </div>
                        <div className='input-with-suggestions'>
                            <input
                                type='text'
                                aria-label='Miejscowość'
                                onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.TOWN) }
                                onBlur={ _onInputFieldBlur }
                                value={ values.town }
                                onChange={ event => {
                                    _onChange(values);
                                    _setFieldValue(LocationsEnum.visualType.TOWN, event.target.value)
                                } }
                            />
                            {
                                renderSuggestions(LocationsEnum.visualType.TOWN)
                            }
                        </div>
                    </label>
                    <div className='multiple-inputs-row is-spaced--top-10'>
                        <label className='main-width-input main-width-input--left'>
                            
                            <div className="location-form__row">
                                <span className='required-accent'> *</span>
                                <span className='label-name'>Ulica</span>
                            </div>
                            <div className='input-with-suggestions'>
                                <input
                                    placeholder='Wpisz nazwę'
                                    type='text'
                                    aria-label='Ulica'
                                    onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.STREET) }
                                    onBlur={ _onInputFieldBlur }
                                    value={ values.street }
                                    disabled={ inputDisabled(LocationsEnum.visualType.STREET) }
                                    onChange={ event => {
                                        _setFieldValue(LocationsEnum.visualType.STREET, event.target.value);
                                        _onChange(values);
                                    } }
                                />
                                {
                                    renderSuggestions(LocationsEnum.visualType.STREET)
                                }
                            </div>
                            {
                                errors?.address &&
                                <span className="hint hint--rejected">
                                {
                                    errors.address
                                }
                                </span>
                            }
                        </label>
                        <fieldset>
                            <div className="location-form__row">
                                <span className='required-accent'> *</span>
                                <span className='label-name'>Nr budynku</span>
                            </div>
                            <div className='range-input'>
                                <label>
                                    <span className='is-sr-only'>Od</span>
                                    <div className='input-with-suggestions'>
                                        <input
                                            placeholder='Od'
                                            type='text'
                                            onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.ADDRESS_FROM) }
                                            onBlur={ _onInputFieldBlur }
                                            value={ values.from }
                                            aria-label='Od'
                                            disabled={ inputDisabled(LocationsEnum.visualType.ADDRESS_FROM) }
                                            onChange={ event => {
                                                _setFieldValue(LocationsEnum.visualType.ADDRESS_FROM, event.target.value)
                                                _onChange(values);
                                            } }
                                        />
                                        {
                                            renderSuggestions(LocationsEnum.visualType.ADDRESS_FROM)
                                        }
                                    </div>
                                </label>
                                {
                                    formType !== LocationFormTypeEnum.WITH_BUILDING_NUMBER &&
                                    <label>
                                        <div className="location-form__dash">-</div>
                                    </label>
                                }
                                {
                                    formType !== LocationFormTypeEnum.WITH_BUILDING_NUMBER &&
                                    <label>
                                        <span className='is-sr-only'>Do</span>
                                        <div className='input-with-suggestions'>
                                            <input
                                                placeholder='Do'
                                                type='text'
                                                onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.ADDRESS_TO) }
                                                onBlur={ _onInputFieldBlur }
                                                value={ values.to }
                                                aria-label='Do'
                                                disabled={ inputDisabled(LocationsEnum.visualType.ADDRESS_TO) }
                                                onChange={ event => {
                                                    _setFieldValue(LocationsEnum.visualType.ADDRESS_TO, event.target.value);
                                                    _onChange(values);
                                                } }
                                            />
                                            {
                                                renderSuggestions(LocationsEnum.visualType.ADDRESS_TO)
                                            }
                                        </div>
                                    </label>
                                }
                            </div>
                        </fieldset>
                    </div>
                </section>
            }
            {
                !horizontal &&
                formType === LocationFormTypeEnum.WITH_ADD_APARTMENT_ADDRESS &&
                <section>
                    <label className='is-spaced-bottom--20'>
                        <span className='required-accent'> *</span>
                        <span className='label-name'>Miejscowość</span>
                        <div className='input-with-suggestions'>
                            <input
                                type='text'
                                aria-label='Miejscowość'
                                onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.TOWN) }
                                onBlur={ _onInputFieldBlur }
                                value={ values.town }
                                onChange={ event => {
                                    _setFieldValue(LocationsEnum.visualType.TOWN, event.target.value);
                                    _onChange(values);
                                } }
                            />
                            {
                                renderSuggestions(LocationsEnum.visualType.TOWN)
                            }
                        </div>
                    </label>
                    <div className='multiple-inputs-row is-spaced--top-10'>
                        <label className='main-width-input main-width-input--left'>
                            <span className='required-accent'> *</span>
                            <span className='label-name'>Adres</span>
                            <div className='input-with-suggestions'>
                                <input
                                    placeholder='Wpisz nazwę'
                                    type='text'
                                    aria-label='Ulica'
                                    onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.STREET) }
                                    onBlur={ _onInputFieldBlur }
                                    value={ values.street }
                                    disabled={ inputDisabled(LocationsEnum.visualType.STREET) }
                                    onChange={ event => {
                                        _setFieldValue(LocationsEnum.visualType.STREET, event.target.value);
                                        _onChange(values);
                                    } }
                                />
                                {
                                    renderSuggestions(LocationsEnum.visualType.STREET)
                                }
                            </div>
                        </label>
                        <fieldset>
                            <span className='required-accent'> *</span>
                            <span className='label-name'>Nr budynku</span>
                            <div className='input-size-s'>
                                <label>
                                    <div className='input-with-suggestions'>
                                        <input
                                            type='text'
                                            onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.ADDRESS) }
                                            onBlur={ _onInputFieldBlur }
                                            value={ values.address }
                                            aria-label='Nr budynku'
                                            disabled={ inputDisabled(LocationsEnum.visualType.ADDRESS) }
                                            onChange={ event => {
                                                _setFieldValue(LocationsEnum.visualType.ADDRESS, event.target.value);
                                                _onChange(values);
                                            } }
                                        />
                                        {
                                            renderSuggestions(LocationsEnum.visualType.ADDRESS)
                                        }
                                    </div>
                                </label>
                            </div>
                        </fieldset>
                    </div>
                </section>
            }
            {
                formType === LocationFormTypeEnum.WITH_ADD_APARTMENT_ADDRESS &&
                <div className={ ` ${ horizontal ? 'location-form__input-wrap' : 'is-spaced--top-10' } ` }>
                    <label>
                        <span className='required-accent'> *</span>
                        <span className="location-form__label">Nr lokali</span>
                        <textarea
                            aria-label="Nr lokali"
                            onFocus={ () => _onInputFieldFocus(LocationsEnum.visualType.APARTMENT_ADDRESS) }
                            onBlur={ _onInputFieldBlur }
                            value={ values.apartmentAddress }
                            disabled={ !values.address }
                            onChange={ event => {
                                _setFieldValue(LocationsEnum.visualType.APARTMENT_ADDRESS, event.target.value);
                                _onNewApartmentAddressSelect(LocationsEnum.visualType.APARTMENT_ADDRESS);
                                _onChange(values);
                            } }
                        />
                        <span className='notice--small'>
                                Wpisz nr mieszkań oddzielone przecinkiem lub średnikiem np. 1,2,3
                            </span>
                    </label>
                </div>
            }
        </div>
    );
}
export default withLocationManagement(LocationForm);
export const LocationFormTypeEnum = Object.freeze({
    WITH_BUILDING_NUMBERS_RANGE: 0,
    WITH_APARTMENT_ADDRESS: 1,
    WITH_ADD_APARTMENT_ADDRESS: 2,
    WITH_BUILDING_NUMBER: 3,
});