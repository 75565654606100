import React, { Component } from "react";
import { connect } from "react-redux";

import { isNullOrUndefined } from "../../core/utils/misc_utils";
import { hideToast } from "../../data/actions/common/ui/toast";

class Toast extends Component {

    state = {
        display: false
    };

    render = () => {
        const { toast } = this.props;
        const { display } = this.state;

        const toastInfo = "toastInfo";
        const toastError = "toastError";

        if (!display) {
            return (
                <></>
            );
        }

        return (
            <div className={ `toast ${ toast.type === toastInfo ? "toast--info" : "" } ${ toast.type === toastError ? "toast--error" : "" }` }>
                <p className="toast__content">
                    {
                        toast.text
                    }
                </p>
            </div>
        );
    };

    componentWillUnmount = () => {
        clearTimeout(this.timer);
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { toast } = this.props;
        const { display } = this.state;


        if (!isNullOrUndefined(toast.text) && !isNullOrUndefined(toast.type) && !display) {

            this.setState({
                display: true
            }, () => {
                //if you change timeout here, you also need to change it in css animation (file: _toast.scss)
                this.timer = setTimeout(this.enableTimeout, 6100);
            });
        }
    };

    enableTimeout = () => {
        this.setState({
            display: false
        }, () => {
            this.props.hideToast();
        });
    };
}

const mapStateToProps = (state) => {
    return {
        toast: state.ui.toast
    };
};
export default connect(mapStateToProps, { hideToast })(Toast);
