import React, { Component } from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import _ from "lodash";

import Loader from "../../../../../common/loader";
import Accordion from "../../../../../common/accordion";
import CheckboxInput from "../../../../../common/checkbox_input";
import { getManyCategories } from "../../../../../../data/actions/services/categories";
import { isEmptyArray, isLoading, isLoadingError, isNullOrUndefinedOneOfMany } from "../../../../../../core/utils/misc_utils";
import { getManySubcategories } from "../../../../../../data/actions/services/subcategories";

class ServicesCardBasicInfoSubcategorySelection extends Component {

    render = () => {
        const { categories, subcategories } = this.props;
        if (isLoadingError(categories) || isLoadingError(subcategories)) {
            // TODO
            return (
                <div>Błąd wczytywania danych</div>
            );
        }

        if (isLoading(categories) || isLoading(subcategories)) {
            return (
                <Loader/>
            );
        }

        if (isNullOrUndefinedOneOfMany(categories, subcategories) ||
            isNullOrUndefinedOneOfMany(categories.data, subcategories.data)) {
            return null;
        }

        return (
            <>
                <p className="is-bold is-spaced--bottom-10 is-spaced--top-30">
                    Gdzie chcesz umieścić kartę?
                </p>
                <p className="is-spaced--bottom-20">
                    Zaznacz co najmniej jedną podkategorię.
                </p>
                <Field
                    name="assignedSubcategoryIds"
                    render={ ({ field, form }) => {
                        return (
                            this.renderCategories(field.value, form.setFieldValue)
                        );
                    } }/>
            </>
        );
    };

    renderCategories = (assignedSubcategoryIds, _setFormikFieldValue) => {
        const { categories } = this.props;

        return (
            _.map(categories.data, category => (

                <Accordion
                    icon={ <img src={category.iconUri} alt=""/> }
                    key={ category.id }
                    title={ category.name }>

                    <div>
                        {
                            this.renderSubcategoriesForCategory(category.id, assignedSubcategoryIds, _setFormikFieldValue)
                        }
                    </div>

                </Accordion>
            )));
    };

    renderSubcategoriesForCategory = (categoryId, assignedSubcategoryIds, _setFormikFieldValue) => {
        const { subcategories } = this.props;

        const subcategoriesForCategory = _
            .filter(subcategories.data, subcategory => subcategory.category_id === categoryId);

        if (isEmptyArray(subcategoriesForCategory)) {
            // TODO
            return (
                <div>Brak podkategorii do wyboru. Aby umieścić kartę w tej kategorii, najpierw utwórz dla niej podkategorię.</div>
            );
        }

        return subcategoriesForCategory
            .map(subcategory => {
                const subcategoryChecked = assignedSubcategoryIds.includes(subcategory.id);
                return (
                    <CheckboxInput
                        key={ subcategory.id }
                        label={ subcategory.name }
                        checked={ subcategoryChecked }
                        _onInputChange={ () => {
                            if (!subcategoryChecked) {
                                _setFormikFieldValue(
                                    "assignedSubcategoryIds",
                                    [...assignedSubcategoryIds, subcategory.id]);
                            } else {
                                _setFormikFieldValue(
                                    "assignedSubcategoryIds",
                                    _.reject(assignedSubcategoryIds, subcategoryId => subcategoryId === subcategory.id));
                            }
                        } }/>
                );
            });
    };

    componentDidMount = () => {
        const { getManyCategories, getManySubcategories } = this.props;
        getManyCategories();
        getManySubcategories();
    };
}

const mapStateToProps = (state) => {
    return {
        categories: state.entities.services.categories.getMany,
        subcategories: state.entities.services.subcategories.getMany
    };
};

export default connect(mapStateToProps, { getManyCategories, getManySubcategories })(ServicesCardBasicInfoSubcategorySelection);