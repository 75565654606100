import React, { Component } from "react";
import { Field, Form } from "formik";

import { isNullOrUndefined, deepFreeze } from "../../../../../../../core/utils/misc_utils";
import { EkostraznikReportsEnum } from "../../../../../../../core/enums/ekostraznik/reports";
import FormRequiredLegend from "../../../../../../common/legend_required";
import EkostraznikReportsFiles from "./files";
import SelectGroupForm from "../../../inventories/form/selectGroup";
import EkostraznikReportsBinType from "./bin_type";
import EkostraznikReportType from "./report_type";
import InspectionCompany from "../../../common/form/company";
import LocationForm, { LocationFormTypeEnum } from "../../../../../../common/location_form";

const FormType = deepFreeze({
    BUILDING: 'BUILDING',
    GROUP: 'GROUP',
    APARTMENT: 'APARTMENT',
});

class EkostraznikReportsCreateModalFormContent extends Component {

    state = {
        formType: FormType.BUILDING
    }

    render = () => {
        const { readOnly, errors, formikBag, selectedType, typeOptions, _handleSelectedTypeChange } = this.props;

        const Option = props => {
            const { formType, label } = props;

            return (
                <label
                    className={ `choice-label choice-label--radio ${ formType === this.state.formType ? 'checked--radio' : '' }` }
                    onClick={ () => this.setState({ formType }) }
                >
                    <input className="choice--radio" type="radio" value={ formType } name="formType" />
                    { label }
                </label>
            );
        };

        return (
            <div className='c-modal__body modal-size-m'>
                <Form className='form--vertical form--with-legend'>

                    <EkostraznikReportType
                        typeOptions={ typeOptions }
                        selectedType={ selectedType }
                        _handleSelectedTypeChange={ _handleSelectedTypeChange }
                        formikBag={ formikBag }
                    />

                    <div className="location-form__row font-size-13">
                        <Option formType={ FormType.BUILDING } label='Wskazany budynek' />
                        <Option formType={ FormType.GROUP } label='Grupa Adresów' />
                        <Option formType={ FormType.APARTMENT } label='Wyodrębniony Lokal' />
                    </div>
                    {
                        this.state.formType === FormType.BUILDING &&
                        <div>
                            <LocationForm
                                horizontal={ false }
                                formType={ LocationFormTypeEnum.WITH_BUILDING_NUMBER }
                                _onChange={ () => {
                                    formikBag.setFieldValue("address", null);
                                } }
                                _onSelect={ values => {
                                    formikBag.setFieldValue("address", values);
                                } }
                                errors={ errors }
                            />
                        </div>
                    }

                    {
                        this.state.formType === FormType.GROUP &&
                        <label className="flex-grow-1">
                        <span className="label-name">
                            <span className="required-accent">*</span>
                            Grupa adresów
                        </span>
                            <SelectGroupForm 
                                formikBag={ formikBag } 
                                errors={ errors }
                            />
                        </label>
                    }
                    {
                        this.state.formType === FormType.APARTMENT &&
                        <div>
                            <LocationForm
                                horizontal={ false }
                                formType={ LocationFormTypeEnum.WITH_APARTMENT_ADDRESS }
                                _onChange={ () => {
                                    formikBag.setFieldValue("address", null);
                                } }
                                _onSelect={ values => {
                                    formikBag.setFieldValue("address", values);
                                } }
                                errors={ errors }
                            />
                        </div>
                    }
                    {
                        selectedType.value !== EkostraznikReportsEnum.type.OTHER.code.toLowerCase() &&
                        <EkostraznikReportsBinType
                            errors={ errors }
                            formikBag={ formikBag }/>
                    }
                

                    <InspectionCompany
                        errors={ errors }
                        formikBag={ formikBag }/>

                    {
                        selectedType.value !== EkostraznikReportsEnum.type.MISSING_WASTE_BIN.code.toLowerCase() &&

                        <EkostraznikReportsFiles
                            selectedType={ selectedType }
                            errors={ errors }
                            formikBag={ formikBag }/>
                    }


                    <Field
                        name="description"
                        render={ ({ field }) => (
                            <label>
                                <span className='label-name'>
                                    {
                                        selectedType.value === EkostraznikReportsEnum.type.OTHER.code.toLowerCase() &&
                                        <span className="required-accent">*</span>
                                    }
                                    Opis
                                </span>
                                <textarea
                                    disabled={ !isNullOrUndefined(readOnly) ? readOnly : false }
                                    { ...field }/>
                                {
                                    !isNullOrUndefined(errors.description) &&

                                    <span className="hint hint--rejected">{ errors.description }</span>
                                }
                            </label>
                        ) }
                    />
                    <FormRequiredLegend/>
                </Form>
            </div>
        );
    };

    componentDidMount = () => {
        const { formikBag, withLocationManagementProps, _setFormValues } = this.props;

        _setFormValues(formikBag);
        withLocationManagementProps._resetForm();
    };
}


export default EkostraznikReportsCreateModalFormContent;
