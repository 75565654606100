import React from "react";

import { useHistory } from "react-router-dom";
import { ROUTE_BLISKO_ADDRESS_TEMPLATE_LOCATIONS } from "../../../../../../../core/constants";
import AddAndEditAddressTemplateForm from "../form/add_and_edit_form";
import DeleteAddressTemplateForm from "../form/delete_form";
import CheckboxInput from "../../../../../../common/checkbox_input";

const AddressTemplateItem = ({
                                 addressTemplate,
                                 checkedAddressTemplates,
                                 _onAddressTemplateCheckboxChange,
                                 _onDeleteOrUpdateSuccess
                             }) => {
    const history = useHistory();

    return (
        <tr>
            <td className="t-width-9">
                <CheckboxInput
                    _onInputChange={ () => _onAddressTemplateCheckboxChange(addressTemplate.id) }
                    checked={ checkedAddressTemplates.indexOf(addressTemplate.id) >= 0 }
                />
                <div>
                    <p className="table-text-content is-clickable"
                       onClick={ () => history.push(ROUTE_BLISKO_ADDRESS_TEMPLATE_LOCATIONS(addressTemplate.id)) }>
                        { addressTemplate.name }
                    </p>
                </div>
            </td>
            <td className="t-width-2">
                <p className="table-text-content">
                    { addressTemplate.createdBy }
                </p>
            </td>
            <td className='sticky--right td-action-buttons'>
                <AddAndEditAddressTemplateForm
                    addressTemplate={ addressTemplate }
                    _onSubmitCallback={ _onDeleteOrUpdateSuccess }
                />
                <DeleteAddressTemplateForm
                    addressTemplate={ addressTemplate }
                    _onSubmitCallback={ _onDeleteOrUpdateSuccess }
                />
            </td>
        </tr>
    )
};

export default AddressTemplateItem;