import * as api from "../../../../_api";
import { actions } from "../../../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../../../core/utils/api_utils";

export const getManyMunicipalWasteYearsForArea = (areaId) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__AREA_MUNICIPAL_WASTE_YEARS__GET_MANY__STARTED
    });

    return api
        .waste_management
        .areaMunicipalWaste
        .years
        .getManyMunicipalWasteYearsForArea(areaId)
        .then(response => {
            const data = extractResponseData(response);

            dispatch({
                type: actions.wasteManagement.API__AREA_MUNICIPAL_WASTE_YEARS__GET_MANY__SUCCESS,
                meta: {},
                payload: {
                    [areaId]: data
                },
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__AREA_MUNICIPAL_WASTE_YEARS__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};