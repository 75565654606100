import webClient from "../../../core/web_client";
import { API_BASE_AKUN_EKOSTRAZNIK_PATH } from "../../../core/constants";
import { buildQueryString } from "../../../core/utils/api_utils";

const PATH = `${ API_BASE_AKUN_EKOSTRAZNIK_PATH }/apartment-addresses`;

export const getMany = (queryParams) => {
    return webClient
        .get(`${PATH}${buildQueryString(queryParams)}`);
}

export const addApartmentAddresses = (parentAddressId, number) => {
    return webClient
        .post(PATH, { parentAddressId, number });
}

export const deleteManyApartmentAddresses = (data) => {
    return webClient
        .delete(PATH, { data });
}
