import React, { Component } from "react";
import { Field } from "formik";
import { isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";

class ServicesCardAddAndEditResponsibleEntityModalFormContent extends Component {

    render = () => {
        const { errors } = this.props;
        return (
            <div className="c-modal__body modal-size-m">
                <div className="form--vertical">

                    <Field
                        name="name"
                        render={ ({ field }) => (
                            <label>
                                <span className="label-name">Nazwa podmiotu<span className="required-accent">*</span></span>
                                <span className="is-relative">
                                <input
                                    type="text"
                                    placeholder="np. Urząd Gminy Aleksandrów Kujawski"
                                    className={ `${ !isNullOrUndefined(errors.name) ? "rejected" : "" }` }
                                    { ...field }/>
                                    {
                                        !isNullOrUndefined(errors.name) &&

                                        <span className="hint hint--rejected">{ errors.name }</span>
                                    }
                            </span>
                            </label>
                        ) }/>

                    <Field
                        name="address"

                        render={ ({ field }) => (
                            <label>
                                <span className="label-name">Adres</span>
                                <input
                                    type="text"
                                    placeholder="np. ul. Mydlana 12, 21-241 Aleksandrów Kujawski"
                                    { ...field }/>
                            </label>
                        ) }/>

                    <Field
                        name="phoneNumber"
                        render={ ({ field }) => (
                            <label>
                                <span className="label-name">Numer telefonu</span>
                                <input
                                    type="text"
                                    placeholder="np. 23 123 42 12"
                                    { ...field }/>
                            </label>
                        ) }/>

                    <Field
                        name="emailAddress"
                        render={ ({ field }) => (
                            <label>
                                <span className="label-name">Adres e-mail</span>
                                <span className="is-relative">
                                <input
                                    type="text"
                                    placeholder="np. ug@aleksandrowkujawski.pl"
                                    className={ `${ !isNullOrUndefined(errors.emailAddress) ? "rejected" : "" }` }
                                    { ...field }/>
                                    {
                                        !isNullOrUndefined(errors.emailAddress) &&

                                        <span className="hint hint--rejected">{ errors.emailAddress }</span>
                                    }
                            </span>
                            </label>
                        ) }/>

                    <Field
                        name="openingHours"
                        render={ ({ field }) => (
                            <label>
                                <span className="label-name">Godziny otwarcia</span>
                                <textarea
                                    placeholder="Np.: &#10;pon. - pt.: 8:00-16:00 &#10;sob.: 10:00-12:00"
                                    { ...field }/>
                            </label>
                        ) }/>

                </div>
            </div>
        );
    };

    componentDidMount = () => {
        const { formikResetForm } = this.props;
        formikResetForm();
    };

    componentWillUnmount = () => {
        const { formikResetForm } = this.props;
        formikResetForm();
    };
}

export default ServicesCardAddAndEditResponsibleEntityModalFormContent;