import _ from "lodash";

import * as api from "../../../../_api";
import { actions } from "../../../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../../../core/utils/api_utils";

export const getManyMunicipalWasteReceptionsForArea = (areaId, year, binId) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__AREA_MUNICIPAL_WASTE_RECEPTIONS__GET_MANY__STARTED
    });

    return api
        .waste_management
        .areaMunicipalWaste
        .reception
        .getManyReceptionsForArea(areaId, year, binId)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__AREA_MUNICIPAL_WASTE_RECEPTIONS__GET_MANY__SUCCESS,
                meta: {},
                payload: _.mapKeys(extractResponseData(response), "id"),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__AREA_MUNICIPAL_WASTE_RECEPTIONS__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const updateMunicipalWasteReceptionStatusForArea = (areaId, binId, date, status) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__AREA_MUNICIPAL_WASTE_RECEPTIONS__UPDATE_STATUS__STARTED
    });

    return api
        .waste_management
        .areaMunicipalWaste
        .reception
        .updateReceptionStatusForArea(areaId, binId, date, status)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__AREA_MUNICIPAL_WASTE_RECEPTIONS__UPDATE_STATUS__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__AREA_MUNICIPAL_WASTE_RECEPTIONS__UPDATE_STATUS__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const publishChangesToMunicipalWasteReceptionsForArea = (areaId) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__AREA_MUNICIPAL_WASTE_RECEPTIONS__PUBLISH_CHANGES__STARTED
    });

    return api
        .waste_management
        .areaMunicipalWaste
        .reception
        .publishChangesToMunicipalWasteReceptionsForArea(areaId)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__AREA_MUNICIPAL_WASTE_RECEPTIONS__PUBLISH_CHANGES__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__AREA_MUNICIPAL_WASTE_RECEPTIONS__PUBLISH_CHANGES__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};