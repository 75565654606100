import { isEmptyArray, isEmptyObject, isNullOrUndefined } from "./misc_utils";

export const extractResponseData = (response) => {

    if (isNullOrUndefined(response) || isNullOrUndefined(response.data)) {
        return null;
    }

    if (response.data.data === undefined) {
        return response.data;
    }

    return response.data.data;
};

export const extractErrorData = (error) => {
    if (isNullOrUndefined(error)) {
        return null;
    }

    if (isNullOrUndefined(error.response)) {
        return null;
    }

    if (isNullOrUndefined(error.response.data)) {
        return null;
    }

    return error.response.data;
};

export const buildQueryString = (queryData) => {
    let queryString = "";
    if (isNullOrUndefined(queryData) || isEmptyObject(queryData)) {
        return queryString;
    }

    let firstQueryParameter = true;

    for (let property in queryData) {
        if (queryData.hasOwnProperty(property)) {

            if (firstQueryParameter) {
                queryString += "?";
                firstQueryParameter = false;
            } else {
                queryString += "&";
            }

            queryString += property + "=" + queryData[property];
        }
    }

    return queryString;
};

export const generatePaginationQueryValues = (pageNumber, sizeNumber, sort) => {
    return {
        page: pageNumber,
        size: sizeNumber,
        sort: sort
    }
};

export const buildQueryParamValueArray = (array) => {
    let string = "";
    if (isNullOrUndefined(array) || isEmptyArray(array)) {
        return string;
    }

    for (let i = 0; i < array.length; i++) {
        if (i === array.length - 1) {
            string += array[i];
        } else {
            string += array[i] + ",";
        }
    }

    return string;
};

export const buildIdQueryParamArray = (idArray) => {
    let string = "";

    if (isNullOrUndefined(idArray) || isEmptyArray(idArray)) {
        return string;
    }

    for (let i = 0; i < idArray.length; i++) {
        string += "id=";
        if (i === idArray.length - 1) {
            string += idArray[i];
        } else {
            string += idArray[i] + "&";
        }
    }

    return string;
};

export const is2xx = (httpCode) => {
    if (isNullOrUndefined(httpCode) || isNaN(httpCode)) {
        return false;
    }

    return httpCode >= 200 && httpCode < 300;
};

export const is4xx = (httpCode) => {
    if (isNullOrUndefined(httpCode) || isNaN(httpCode)) {
        return false;
    }

    return httpCode >= 400 && httpCode < 500;
};

export const is5xx = (httpCode) => {
    if (isNullOrUndefined(httpCode) || isNaN(httpCode)) {
        return false;
    }

    return httpCode >= 500;
};

export const exportFile = (data, fileName) => {
    // explained:
    // https://medium.com/@drevets/you-cant-prompt-a-file-download-with-the-content-disposition-header-using-axios-xhr-sorry-56577aa706d6

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', fileName);
    link.click();

};
