import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getInventory } from "../../../../../../../data/actions/ekostraznik/inventories";
import Header from "../../../../../../common/header";
import {
    MISC_DATE_DAY_MONTH_YEAR_HOUR_MINUTE_FORMAT,
    MISC_DATE_ISO_FORMAT,
    ROUTE_EKOSTRAZNIK_INVENTORIES
} from "../../../../../../../core/constants";
import moment from "moment/moment";
import AllAddressesInGroupModal from "../../../common/list/all_addresses_in_group_modal";
import { ReactComponent as IconInfo } from "../../../../../../../../resource/img/icons/icon_info.svg";
import DocumentGallery from "../../../../../../common/document_gallery/gallery";
import BinIcon from "../../../../../../common/BinIcon";

const InventoryDetails = ({ match }) => {
    const dispatch = useDispatch();
    const inventoryId = match.params.inventoryId;
    const inventory = useSelector(state => state.entities?.ekostraznik?.inventories?.get);

    useEffect(() => {
        dispatch(getInventory(inventoryId));
    }, [dispatch, inventoryId]);

    return (
        <div>
            <Header goBackLink={ ROUTE_EKOSTRAZNIK_INVENTORIES }>
                Szczegóły inwentaryzacji
            </Header>

            <div className="content--s content--not-centered">
                <p className="f-18 is-spaced--bottom-10">
                    {
                        inventory?.data?.inspectionCompanyName || inventory?.data?.employeeName
                    }
                </p>

                {
                    inventory?.data?.date &&
                    <div className="report-details__row report-details__row--info">
                        <p className="report-details__label">Data inwentaryzacji:</p>
                        <p className="report-details__text">
                            {
                                moment(inventory.data.date, MISC_DATE_ISO_FORMAT)
                                    .format(MISC_DATE_DAY_MONTH_YEAR_HOUR_MINUTE_FORMAT)
                            }

                        </p>
                    </div>
                }

                {
                    inventory?.data?.address &&
                    <div className="report-details__row report-details__row--info">
                        <p className="report-details__label">Inwentaryzowana lokalizacja:</p>
                        <p className="report-details__text">
                            {
                                inventory.data.address.name
                            }
                        </p>
                    </div>
                }

                {
                    inventory?.data?.addressGroup &&
                    <div className="report-details__row report-details__row--info">
                        <p className="report-details__label">Inwentaryzowana grupa:</p>
                        {
                            <p className="report-details__text">
                                {
                                    inventory.data.addressGroup.name
                                }
                            </p>
                        }
                        {
                            <div className='label-icon-btn'>
                                <AllAddressesInGroupModal
                                    triggerComponent={ <IconInfo/> }
                                    groupName={ inventory.data.addressGroup.name }
                                    reportDate={ inventory.data.date }
                                    addresses={ inventory.data.addressGroup.addresses.map(address => address.name) }
                                />
                            </div>
                        }
                    </div>
                }

                {
                    inventory?.data?.binSumCount &&
                    <div className="report-details__row report-details__row--info">
                        <p className="report-details__label">Liczba pojemników:</p>
                        {
                            <p className="report-details__text">
                                {
                                    inventory.data.binSumCount
                                }
                            </p>
                        }
                    </div>
                }

                {
                    inventory?.data?.binInventories &&
                    <div className="report-details__row--bins">
                        <div className="is-bold">Rodzaj pojemnika:</div>
                        <div className="is-bold">Pojemność:</div>
                        <div className="is-bold">Liczba:</div>
                        {
                            inventory.data.binInventories.map(binInventory => {
                                return (
                                    <Fragment key={ binInventory.id }>
                                        <div>
                                            <BinIcon color={ binInventory.bin.color }/>
                                            <span className="is-spaced--left-5">
                                                { binInventory.bin.name }
                                            </span>
                                        </div>
                                        <div>{ binInventory.capacity }</div>
                                        <div>{ binInventory.count }</div>
                                    </Fragment>
                                )
                            })
                        }
                    </div>
                }

                {
                    inventory?.data?.description &&
                    <div className="report-details__row report-details__row--info">
                        <p className="report-details__label">Notatka z inwentaryzacji:</p>
                        <p className="report-details__text">
                            {
                                inventory.data.description
                            }
                        </p>
                    </div>
                }
                {
                    inventory?.data?.attachmentPaths &&
                    <DocumentGallery
                        label={ "Dokumentacja fotograficzna:" }
                        attachmentPaths={ inventory.data.attachmentPaths }/>
                }

            </div>
        </div>
    )
};

export default InventoryDetails;