import React from "react";
import FormModal from "../../../../../../../common/modal/form_modal";
import { ReactComponent as IconDelete } from "../../../../../../../../../resource/img/icons/icon_delete.svg";
import { deleteNest } from "../../../../../../../../data/actions/ekostraznik/address_groups_nests";
import { getAddressGroup } from "../../../../../../../../data/actions/ekostraznik/address_groups";

const DeleteNestForm = ({ groupId, nestId, _onSuccess, _dispatch, _onDeleteClick }) => {

    return (
        <FormModal
            triggerComponent={
                <div className='unstyled-btn' title='Usuń'>
                    <IconDelete
                        onClick={() => _onDeleteClick()}
                    />
                </div>
            }
            deleteOrCancelButtons={ true }
            _actionFunction={ () =>
                _dispatch(deleteNest(groupId, nestId))
                    .then(() => _dispatch(getAddressGroup(groupId)))
                    .then(async () => _onSuccess())
            }
        >

            <div className='c-modal__body modal-size-m modal--no-header'>
                <p className="confirmation-alert">
                    Czy na pewno chcesz usunąć wybraną lokalizację wiaty śmietnikowej?
                </p>
            </div>

        </FormModal>
    );
}

export default DeleteNestForm;