import FormModal from "../../../../../../common/modal/form_modal";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { ReactComponent as IconCopy } from "../../../../../../../../resource/img/icons/icon_copy.svg";
import React, { useMemo } from "react";

const AreaNotImportedLocationsModal = (props) => {
    const {notImportedLocations, onClose} = props;

    const formattedText = useMemo(() => {
        return notImportedLocations.reduce((text, address) => `${text}\n\r${address.townName}\t${address.prefix}\t${address.streetName}` , '');
    }, [notImportedLocations]);

    return (
        <FormModal
            defaultOpen={true}
            noTrigger={true}
            headerText='Znaleziono błędne adresy'
            closeButton={true}
            _cancelFunction={onClose}
        >
            <div className='incorrect-addresses-modal c-modal__body modal-size-l'>
                <div>
                    <strong>Uwaga!</strong> Znaleziono adresy, które mogą zawierać błąd w nazwie lub nie występują na obszarze administracyjnym. Nie mogą one zostać zaimportowane.
                    <br /><br />
                    Możesz skopiować adresy, wkleić do pustego szablonu, poprawić i ponownie zaimportować
                </div>

                <div className='is-spaced--top-20 is-spaced--bottom-30'>
                    <CopyToClipboard text={formattedText} onCopy={() => onClose}>
                        <button className='btn btn--outlined'>
                            <span className="btn__icon-wrapper--left">
                                <span className='btn__icon'>
                                    <IconCopy />
                                </span>
                                Kopiuj niedopasowane adresy
                            </span>
                        </button>
                    </CopyToClipboard>
                </div>


                <strong>Niedopasowane adresy</strong>
                <div className="table-wrapper is-spaced--top-10">
                    <table className="table--single-line">
                        <tbody>
                        {
                            notImportedLocations.map((address, index) => (
                                <tr key={index}>
                                    <td>{address.townName}</td>
                                    <td>{address.prefix}</td>
                                    <td>{address.streetName}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>

        </FormModal>
    );
}
export default AreaNotImportedLocationsModal;
