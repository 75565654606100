import React, { useCallback, useEffect, useState } from "react";
import ListPagination from "../../../../../common/pagination";
import withPagination from "../../../../../common/hoc/with_pagination";
import { useDispatch, useSelector } from "react-redux";
import { generatePaginationQueryValues } from "../../../../../../core/utils/api_utils";
import { getAllInventories } from "../../../../../../data/actions/ekostraznik/inventory_history";
import EmptyListPage from "../../../../../common/empty_list_page";
import IconEmptyGroups from "../../../../../../../resource/img/icons/empty_list_icons/brak_inwetaryzacji.png";
import CheckboxInput from "../../../../../common/checkbox_input";
import InventoriesListItem from "./item";
import FormModal from "../../../../../common/modal/form_modal";
import { ReactComponent as IconDeleteWhite } from "../../../../../../../resource/img/icons/icon_delete--white-bin.svg";
import { deleteManyInventories } from "../../../../../../data/actions/ekostraznik/inventories";

const defaultPaginationQueryValues = generatePaginationQueryValues(0, 30, ["name", "asc"]);

const InventoriesList = ({ searchBarTerm, _onPrevClick, _onNextClick, _onPageNumberInputValueChange, currentPage, setMaxPage }) => {
    const dispatch = useDispatch();
    const inventories = useSelector(state => state.entities?.ekostraznik?.inventoryHistory?.getMany);
    const deleteStatus = useSelector(state => state.entities?.ekostraznik?.inventories?.deleteMany);
    const [checkedInventories, setCheckedInventories] = useState([]);

    const totalPages = inventories?.data?.totalPages;
    const isInventoriesEmpty = !inventories?.data;
    const allChecked = checkedInventories.length === inventories?.data?.content?.length;

    const _reloadTable = useCallback((searchBarTerm) => {
        dispatch(getAllInventories({
            ...defaultPaginationQueryValues,
            page: currentPage,
            location: searchBarTerm
        })).then(() => {
                if (totalPages !== undefined) {
                    setMaxPage(totalPages);
                }

            setCheckedInventories([])
            }
        );
    }, [totalPages, currentPage, dispatch, setMaxPage]);

    useEffect(() => {
        _reloadTable(searchBarTerm);
        // eslint-disable-next-line
    }, [currentPage, searchBarTerm]);

    const _onInventoryCheckboxChange = (id) => {
        const inventoryIndex = checkedInventories.indexOf(id);

        if (inventoryIndex >= 0) {
            const newCheckedInventories = [...checkedInventories];
            newCheckedInventories.splice(inventoryIndex, 1);

            setCheckedInventories(newCheckedInventories)
        } else {
            setCheckedInventories(prevState => [...prevState, id])
        }
    };

    const _onAllInventoryCheckboxesChange = () => {
        if (allChecked) {
            setCheckedInventories([]);
        } else {
            const inventoryIds = inventories?.data?.content
                .map(inventory => {
                    return inventory.lastInventoryId
                });
            setCheckedInventories(inventoryIds);
        }
    };

    const _onSelectedInventoriesDeleteClick = () => {
        return dispatch(deleteManyInventories({
            ids: checkedInventories
        })).then(_onDeleteOrUpdateSuccess);
    };

    const _onDeleteOrUpdateSuccess = () => {
        _reloadTable(searchBarTerm);
    };

    if (inventories?.data?.content?.length === 0) {
        return (
            <EmptyListPage
                img={ IconEmptyGroups }
                imgAlt='Brak inwentaryzacji'
                text={ !searchBarTerm ? 'Brak inwentaryzacji' : 'Brak pasujących wyników' }
            />
        )
    }

    return (
        <>
            <section className="main-content--table__bottom container is-fluid">
                <div className='table-wrapper'>
                    <table className='table--single-line fixed'>
                        <thead>
                        <tr>
                            <th className='t-width-8'>
                                <CheckboxInput
                                    _onInputChange={ _onAllInventoryCheckboxesChange }
                                    checked={ allChecked }/>
                                Lokalizacja
                            </th>
                            <th className='t-width-3'>
                                Ostatnia inwentaryzacja
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            !isInventoriesEmpty &&
                            renderListItems(inventories, checkedInventories, _onDeleteOrUpdateSuccess, _onInventoryCheckboxChange)
                        }
                        </tbody>
                    </table>
                </div>

            </section>
            <section className="container is-fluid is-flex">
                <div className="sll__pagination-wrapper">
                    {
                        checkedInventories.length > 0 &&
                        <FormModal
                            triggerComponent={
                                <div className="btn btn--danger btn--small">
                                        <span className="btn__icon-wrapper--left">
                                            <span className="btn__icon">
                                                <IconDeleteWhite/>
                                            </span>
                                            Usuń
                                        </span>
                                </div>
                            }
                            deleteOrCancelButtons={ true }
                            _actionFunction={ _onSelectedInventoriesDeleteClick }
                            isLoading={ deleteStatus?.meta?.loading }
                        >

                            <div className='c-modal__body modal-size-m modal--no-header'>
                                <p className="confirmation-alert">
                                    <strong>Czy na pewno chcesz
                                        usunąć { checkedInventories?.length === 1 ? 'wybraną inwentaryzację' : "wybrane inwentaryzacje" }?</strong>
                                </p>
                            </div>

                        </FormModal>
                    }
                </div>
                <div className="is-flex justify-end pagination--list">
                    {
                        !isInventoriesEmpty &&
                        <ListPagination
                            data={ inventories.data }
                            _onPrevClick={ _onPrevClick }
                            _onNextClick={ _onNextClick }
                            _onPageNumberInputValueChange={ _onPageNumberInputValueChange }
                        />
                    }
                </div>
            </section>
        </>

    );
};

const renderListItems = (inventories, checkedInventories, _onDeleteOrUpdateSuccess, _onInventoryCheckboxChange) => {

    return inventories.data.content
        .map(inventory => {
            return (
                <InventoriesListItem
                    key={ inventory.lastInventoryDate }
                    inventory={ inventory }
                    checkedInventories={ checkedInventories }
                    _onDeleteOrUpdateSuccess={ _onDeleteOrUpdateSuccess }
                    _onInventoryCheckboxChange={ _onInventoryCheckboxChange }
                />
            );
        });
};

export default withPagination(InventoriesList);