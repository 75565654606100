import webClient from "../../../core/web_client";
import { API_BASE_AKUN_ADMIN_PATH } from "../../../core/constants";

const PATH = `${ API_BASE_AKUN_ADMIN_PATH }/owner`;

export const getConfiguration = () => {
    return webClient
        .get(`${ PATH }/configurations`);
};

export const getOwnerCoordinates = () => {
    return webClient
        .get(`${ PATH }/coordinates`);
};

export const getOwnerModules = () => {
    return webClient
        .get(`${ PATH }/modules`);
};