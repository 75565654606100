import React from "react";
import DayPicker from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/pl";
import { isNullOrUndefined } from "../../../../../../../../../core/utils/misc_utils";

const BulkyWasteCreateAndEditModalFormContentDateCalendar = ({ selectedDays, _onDayClick, required, errors }) => {

    return (
        <label className="no-spacing--bottom">
            <span className='label-name'>
                {
                    required &&
                    <span className="required-accent">*</span>
                }
                Data podstawienia:
            </span>

            <div className="is-relative">
                <DayPicker
                    numberOfMonths={ 2 }
                    pagedNavigation={ true }
                    localeUtils={ MomentLocaleUtils }
                    locale={ "pl" }
                    selectedDays={ selectedDays }
                    onDayClick={ _onDayClick }/>
                {
                    !isNullOrUndefined(errors) &&
                    <p className="hint hint--rejected hint--datepicker">
                        {
                            errors
                        }
                    </p>
                }
            </div>
        </label>
    );
};

export default BulkyWasteCreateAndEditModalFormContentDateCalendar;