import React, { Component } from "react";

import BulkyWasteArchiveListItem from "./item";
import { ReactComponent as IconArrowSwitchUp } from "../../../../../../../../resource/img/icons/list/icon_arrow_switch_up.svg";
import { isEmptyArray, isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";
import _ from "lodash";

class BulkyWasteListArchive extends Component {

    render = () => {
        const { archivedBulkyWaste, meta, error } = this.props;

        return (
            <section className="main-content--table__bottom container is-fluid">
                <div className='table-wrapper'>
                    <table className='table--single-line fixed'>
                        <thead>
                        <tr>
                            <th className='t-width-3'>
                                Lokalizacja
                                <div className='th-action-btn'>
                                    <IconArrowSwitchUp/>
                                </div>
                            </th>
                            <th className='t-width-4'>Data podstawienia</th>
                        </tr>
                        </thead>

                        {
                            this.renderArchivedBulkyWasteItems(archivedBulkyWaste, meta, error)
                        }
                    </table>
                </div>
            </section>
        );
    };

    renderArchivedBulkyWasteItems = (archivedBulkyWaste, meta, error) => {
        if (isNullOrUndefined(archivedBulkyWaste) || meta.loading || error) {
            return null;
        }

        const archivedBulkyWasteToRender = _.map(archivedBulkyWaste, bulkyWasteItem => {
            return (
                <BulkyWasteArchiveListItem
                    key={ bulkyWasteItem.id }
                    bulkyWasteItem={ bulkyWasteItem }/>
            );
        });

        if (isEmptyArray(archivedBulkyWasteToRender)) {
            return null;
        }

        return (
            <tbody>
            {
                archivedBulkyWasteToRender
            }
            </tbody>
        );
    };
}

export default BulkyWasteListArchive;