import webClient from "../../../core/web_client";
import { API_BASE_GEOCODING_PATH } from "../../../core/constants";

const PATH_FORWARD = `${ API_BASE_GEOCODING_PATH }/forward`;
const PATH_REVERSE = `${ API_BASE_GEOCODING_PATH }/reverse`;

export const forwardGeocoding = (searchText) => {
    return webClient
        .get(`${ PATH_FORWARD }?term=${ searchText }`);
};

export const reverseGeocoding = (longitude, latitude) => {
    return webClient
        .get(`${ PATH_REVERSE }?lat=${ latitude }&lng=${ longitude }`);
};