import React, { Component } from "react";
import _ from "lodash";
import { MISC_MAPBOX_EVENT_MOVEEND } from "../../../core/constants";
import { isNullOrUndefined } from "../../../core/utils/misc_utils";
import withMap from "../hoc/with_map";

class ReverseGeocodingMap extends Component {

    state = {
        mapTouched: false
    };

    render = () => {
        const { mapboxContainerRef } = this.props;

        return (
            <label onClick={ e => e.preventDefault() }>
                <span className="label-name"/>
                <div ref={ mapboxContainerRef } className="map-wrapper"/>
            </label>
        );
    };

    componentDidMount = () => {
        const { originalComponentInstanceRef } = this.props;
        // hook for accessing this components instance in the HOC
        originalComponentInstanceRef.current = this;

        const { _reverseGeocodingCallback, longitude, latitude, initReverseGeocoding } = this.props;

        if (initReverseGeocoding) {
            this.setState({
                mapTouched: true
            }, () => {
                _reverseGeocodingCallback(longitude, latitude)
            });
        }
    };

    shouldComponentUpdate = (nextProps) => {
        const { reverse, longitude, latitude } = this.props;

        if (!_.isEqual(reverse, nextProps.reverse)) {
            return true;
        } else if (!_.isEqual(longitude, nextProps.longitude) ||
            !_.isEqual(latitude, nextProps.latitude)) {
            return true;
        }

        return false;
    };

    UNSAFE_componentWillUpdate = (nextProps, nextState) => {
        const { mapboxMapRef } = this.props;

        mapboxMapRef.current.off(MISC_MAPBOX_EVENT_MOVEEND, this._onMapMoveEnd);

        const { reverse, longitude, latitude } = this.props;
        const { mapTouched } = nextState;

        if (!(_.isEqual(reverse, nextProps.reverse) &&
            _.isEqual(longitude, nextProps.longitude) &&
            _.isEqual(latitude, nextProps.latitude)) &&
            mapTouched) {
            this._handleReverseGeocoding(nextProps.reverse, nextProps.longitude, nextProps.latitude);
        }

        if (!mapTouched) {
            this._handleForwardGeocoding(nextProps.longitude, nextProps.latitude);
        }

        mapboxMapRef.current.on(MISC_MAPBOX_EVENT_MOVEEND, this._onMapMoveEnd);
    };

    _handleReverseGeocoding = (reverse) => {
        const { _handleReverseGeocodingCallback } = this.props;

        if (isNullOrUndefined(reverse?.meta?.loading) || reverse.meta.loading) {
            return;
        }

        if (isNullOrUndefined(reverse?.data?.place_name)) {
            return;
        }

        this.setState({
            mapTouched: false
        }, () => {
            _handleReverseGeocodingCallback(reverse.data.place_name);
        });
    };

    _handleForwardGeocoding = (longitude, latitude) => {
        const { mapboxMapRef, mapboxMarkerRef } = this.props;
        const { lng, lat } = mapboxMapRef.current.getCenter();

        if (isNullOrUndefined(longitude) || isNullOrUndefined(latitude)) {
            return;
        }

        if (!(lng === longitude && lat === latitude)) {
            mapboxMapRef.current.setCenter({ lng: longitude, lat: latitude });
            mapboxMarkerRef.current.setLngLat(mapboxMapRef.current.getCenter());
        }
    };

    // keep marker at the center
   _onMapMove = (event) => {
        const { mapboxMarkerRef } = this.props;
        const map = event.target;
        const markerReference = mapboxMarkerRef.current;

        if (!isNullOrUndefined(map) && !isNullOrUndefined(markerReference)) {
            markerReference.setLngLat(map.getCenter());
        }
    };

    // update location field (reverse geocoding)
    _onMapMoveEnd = (event) => {
        const { _reverseGeocodingCallback } = this.props;

        this.setState({
            mapTouched: true
        }, () => {
            const { lng, lat } = event.target.getCenter();
            _reverseGeocodingCallback(lng, lat);
        });
    };
}

export default withMap(ReverseGeocodingMap);