import React, { Component } from "react";
import { connect } from "react-redux";
import withModuleMainPage from "../../../common/hoc/with_module_main_page";
import { SidebarEnum } from "../../../../core/enums/common/sidebar";
import { isLoadingError, isNullOrUndefined } from "../../../../core/utils/misc_utils";
import { PermissionEnum } from "../../../../core/enums/common/permissions";
import { setSidebarItemSelected } from "../../../../data/actions/common/ui/sidebar";
import ModuleCategoryTile from "../../../common/module_category_tile";

class EkostraznikModule extends Component {

    render = () => {
        const { authentication, _onSubmenuItemSelected } = this.props;

        if (isLoadingError(authentication) || !authentication?.data?.permissions) {
            return null;
        }

        const { permissions } = authentication.data;

        return (
            <div>
                <div className="container is-fluid">
                    <header className="header--basic">
                        <h1 className="title is-1">
                            { SidebarEnum.EKOSTRAZNIK.name }

                        </h1>
                        <p>Wybierz kategorię</p>
                    </header>
                    <main>
                        <div className=''>
                            <div className='columns is-multiline'>

                                {
                                    !isNullOrUndefined(permissions[PermissionEnum.module.EKOSTRAZNIK.type.INSPECTIONS.full_code]) &&
                                    <ModuleCategoryTile
                                        to={SidebarEnum.EKOSTRAZNIK.INSPECTION_HISTORY.link}
                                        name={SidebarEnum.EKOSTRAZNIK.INSPECTION_HISTORY.name}
                                        description={SidebarEnum.EKOSTRAZNIK.INSPECTION_HISTORY.description}
                                        onClick={ () => _onSubmenuItemSelected(SidebarEnum.EKOSTRAZNIK.INSPECTION_HISTORY.id) }
                                        cssSubclass='inspections'
                                    />

                                }

                                {
                                    !isNullOrUndefined(permissions[PermissionEnum.module.EKOSTRAZNIK.type.REPORTS.full_code]) &&
                                    <ModuleCategoryTile
                                        to={SidebarEnum.EKOSTRAZNIK.REPORTS.link}
                                        name={SidebarEnum.EKOSTRAZNIK.REPORTS.name}
                                        description={SidebarEnum.EKOSTRAZNIK.REPORTS.description}
                                        onClick={ () => _onSubmenuItemSelected(SidebarEnum.EKOSTRAZNIK.REPORTS.id) }
                                        cssSubclass='reports'
                                    />
                                }

                                {
                                    !isNullOrUndefined(permissions[PermissionEnum.module.EKOSTRAZNIK.type.INVENTORIES.full_code]) &&
                                    <ModuleCategoryTile
                                        to={SidebarEnum.EKOSTRAZNIK.INVENTORIES.link}
                                        name={SidebarEnum.EKOSTRAZNIK.INVENTORIES.name}
                                        description={SidebarEnum.EKOSTRAZNIK.INVENTORIES.description}
                                        onClick={ () => _onSubmenuItemSelected(
                                            SidebarEnum.EKOSTRAZNIK.INVENTORIES.id
                                        ) }
                                        cssSubclass='inventories'
                                    />
                                }

                                {
                                    !isNullOrUndefined(permissions[PermissionEnum.module.EKOSTRAZNIK.type.ADDRESSES__GROUPED_ADDRESSES.full_code]) &&
                                    <ModuleCategoryTile
                                        to={SidebarEnum.EKOSTRAZNIK.ADDRESSES.GROUPED_ADDRESSES.link}
                                        name={SidebarEnum.EKOSTRAZNIK.ADDRESSES.GROUPED_ADDRESSES.name}
                                        description={SidebarEnum.EKOSTRAZNIK.ADDRESSES.GROUPED_ADDRESSES.description}
                                        onClick={() => _onSubmenuItemSelected(
                                            SidebarEnum.EKOSTRAZNIK.ADDRESSES.id,
                                            SidebarEnum.EKOSTRAZNIK.ADDRESSES.GROUPED_ADDRESSES.id,
                                        )}
                                        cssSubclass='addresses'
                                    />
                                }

                                {
                                    !isNullOrUndefined(permissions[PermissionEnum.module.EKOSTRAZNIK.type.ADDRESSES__APARTMENT_ADDRESS.full_code]) &&
                                    <ModuleCategoryTile
                                        to={SidebarEnum.EKOSTRAZNIK.ADDRESSES.APARTMENT_ADDRESS.link}
                                        name={SidebarEnum.EKOSTRAZNIK.ADDRESSES.APARTMENT_ADDRESS.name}
                                        description={SidebarEnum.EKOSTRAZNIK.ADDRESSES.APARTMENT_ADDRESS.description}
                                        onClick={() => _onSubmenuItemSelected(
                                            SidebarEnum.EKOSTRAZNIK.ADDRESSES.id,
                                            SidebarEnum.EKOSTRAZNIK.ADDRESSES.APARTMENT_ADDRESS.id,
                                        )}
                                        cssSubclass='addresses'
                                    />
                                }

                                {
                                    !isNullOrUndefined(permissions[PermissionEnum.module.EKOSTRAZNIK.type.ADDRESSES__QR_CODES.full_code]) &&
                                    <ModuleCategoryTile
                                        to={SidebarEnum.EKOSTRAZNIK.ADDRESSES.QR_CODES.link}
                                        name={SidebarEnum.EKOSTRAZNIK.ADDRESSES.QR_CODES.name}
                                        description={SidebarEnum.EKOSTRAZNIK.ADDRESSES.QR_CODES.description}
                                        onClick={() => _onSubmenuItemSelected(
                                            SidebarEnum.EKOSTRAZNIK.ADDRESSES.id,
                                            SidebarEnum.EKOSTRAZNIK.ADDRESSES.QR_CODES.id,
                                        )}
                                        cssSubclass='addresses'
                                    />
                                }

                                {
                                    !isNullOrUndefined(permissions[PermissionEnum.module.EKOSTRAZNIK.type.COMPANIES.full_code]) &&
                                    <ModuleCategoryTile
                                        to={SidebarEnum.EKOSTRAZNIK.COMPANIES.link}
                                        name={SidebarEnum.EKOSTRAZNIK.COMPANIES.name}
                                        description={SidebarEnum.EKOSTRAZNIK.COMPANIES.description}
                                        onClick={ () => _onSubmenuItemSelected(SidebarEnum.EKOSTRAZNIK.COMPANIES.id) }
                                        cssSubclass='eko-companies'
                                    />
                                }

                            </div>
                        </div>
                    </main>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        authentication: state.authentication
    };
};

export default withModuleMainPage(connect(mapStateToProps, { setSidebarItemSelected })(EkostraznikModule));
