import React, { Component } from "react";
import { Field } from "formik";

class CompaniesCreateAndEditModalFormContent extends Component {

    render = () => {
        const { formikBag } = this.props;

        return (
            <Field
                name="name"
                render={ ({ field }) => (
                    <div className='c-modal__body modal-size-m'>
                        <label>
                            <span className="is-sr-only">Nazwa firmy</span>
                            <textarea
                                className={ `${ formikBag.errors.name ? "rejected" : "" }` }
                                placeholder="Nazwa firmy"
                                cols="30" rows="10"
                                { ...field }/>
                            {
                                formikBag.errors.name &&
                                <span className="hint hint--rejected">
                                    {
                                        formikBag.errors.name
                                    }
                                </span>
                            }
                        </label>

                    </div>
                ) }/>
        );
    };

    componentWillUnmount = () => {
        const { formikBag } = this.props;
        formikBag.resetForm();
    };
}


export default CompaniesCreateAndEditModalFormContent;