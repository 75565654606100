import React from "react";

const BinsIcon = (props) => {

    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
            <g fill="#fff" stroke={ props.color } strokeWidth="3">
                <circle cx="7" cy="7" r="7" stroke="none"/>
                <circle cx="7" cy="7" r="5.5" fill="none"/>
            </g>
        </svg>
    )
};

export default BinsIcon;