import React, { Component } from "react";

import withForwardGeocoding from "../../../../../../common/hoc/with_forward_geocoding";
import { isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";

class ReportModalFormContentLocation extends Component {

    render = () => {
        const { renderInputWithSuggestions, errors } = this.props;

        return (
            <label>
                <span className="label-name"><span className="required-accent">*</span>Adres</span>
                <div className="is-relative full-width">
                    {
                        renderInputWithSuggestions()
                    }
                    {
                        !isNullOrUndefined(errors) &&
                            <span className="hint hint--rejected">
                                 Pole wymagane
                            </span>
                    }
                </div>
            </label>
        );
    };
}

export default withForwardGeocoding(ReportModalFormContentLocation);