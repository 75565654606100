import _ from "lodash";

import { deepFreeze } from "./misc_utils";
import { PermissionEnum } from "../enums/common/permissions";
import { ROUTE_BLISKO, ROUTE_EKOSTRAZNIK, ROUTE_SERVICES, ROUTE_WASTE } from "../constants";

export const moduleCodeToData = (code) => {
    if (!code) {
        return;
    }

    switch (code.trim().toLowerCase()) {
        case PermissionEnum.module.WASTE_MANAGEMENT.code:
            return {
                name: "Gospodarka Odpadami",
                description: "",
                route: ROUTE_WASTE,
                sidebarVariant: PermissionEnum.module.WASTE_MANAGEMENT.code
            };

        case PermissionEnum.module.SERVICES.code:
            return {
                name: "Karty Usług",
                description: "",
                route: ROUTE_SERVICES,
                sidebarVariant: PermissionEnum.module.SERVICES.code
            };
        case PermissionEnum.module.EKOSTRAZNIK.code:
            return {
                name: "Ekostrażnik",
                description: "",
                route: ROUTE_EKOSTRAZNIK,
                sidebarVariant: PermissionEnum.module.EKOSTRAZNIK.code
            };
        case PermissionEnum.module.BLISKO.code:
            return {
                name: "Blisko",
                description: "",
                route: ROUTE_BLISKO,
                sidebarVariant: PermissionEnum.module.BLISKO.code
            };

        default:
        // empty
    }
};

export const mapModuleKeys = (modules) => {

    return deepFreeze(
        _.mapKeys(modules.map(
            module => {
                module.groups = _.mapKeys(module.groups.map(
                    group => {
                        group.items = _.mapKeys(group.items, "code");
                        return group;
                    }), "code");
                return module;
            }), "code")
    );
};