import React from "react";
import { Tooltip } from 'react-tippy';

import { ReactComponent as IconInfo } from "../../../../../../../resource/img/icons/icon_info.svg";
import { ReactComponent as IconHourglass } from "../../../../../../../resource/img/icons/icon_hourglass.svg";

const AccountStatusPending = ({ employee, _onReinviteAction }) =>{
    return(
      <div className="status-box">
          <div className="status status--pending">
              <p className="is-v-centered">
                  <IconHourglass className="icon--white"/>
                  Konto w oczekiwaniu na aktywację. <span className="is-underlined is-bold" onClick={ () => _onReinviteAction(employee.id) }>Wyślij ponownie zaproszenie</span>
              </p>
          </div>
          <div className="activation-code">
              <div>
                  <div className="is-v-centered">Kod aktywacyjny
                      <Tooltip
                          title="Po zapisaniu konta moderatora zostanie wysłane na jego adres e-mail zaproszenie z prośbą o aktywację konta.
                                       <br>
                                       Pamiętaj! Do autoryzacji konta moderatora będzie potrzebny kod aktywacyjny. Przekaż go jak najszybciej."
                          trigger="mouseenter"
                          position="right"
                          arrow="true"
                          size="small"
                          theme="custom"
                          tabIndex="0"
                          offset="50"
                          className="tooltip-trigger"
                          html={(
                              <div>
                                  Po zapisaniu konta moderatora zostanie wysłane na jego adres e-mail zaproszenie z prośbą o aktywację konta.
                                  <br/>
                                  <br/>
                                  Pamiętaj! Do autoryzacji konta moderatora będzie potrzebny kod aktywacyjny. Przekaż go jak najszybciej.
                              </div>
                          )}>
                          <IconInfo/>
                      </Tooltip>
                  </div>
                  <div className="code">
                      {
                          employee.activationCode
                      }
                  </div>
              </div>
          </div>
      </div>
    );
};

export default AccountStatusPending;