import getMany from "./get_many";
import add from "./add";
import update from "./update";
import deleteMany from "./delete_many";
import get from "./get";
import locations from "./locations";

export default (state = {}, action) => {
    return {
        ...state,
        getMany: getMany(state.getMany, action),
        get: get(state.get, action),
        add: add(state.add, action),
        update: update(state.update, action),
        deleteMany: deleteMany(state.deleteMany, action),
        locations: locations(state.locations, action),
    };
}