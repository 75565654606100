import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import CreateAndEditModalFormContent from "./content";
import FormModal from "../../../../../../common/modal/form_modal";
import {
    addWasteCollectionPoint,
    updateWasteCollectionPoint
} from "../../../../../../../data/actions/waste_management/waste_collection_points";
import {
    invoke,
    isEmptyArray,
    isEmptyObject,
    isEmptyString,
    isNullOrUndefined
} from "../../../../../../../core/utils/misc_utils";
import { WasteCollectionPointsEnum } from "../../../../../../../core/enums/waste_management/waste_collection_points";
import { toastFor500, toastForSuccess } from "../../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../../data/actions/common/ui/toast";

const initialFormValues = {
    statusId: WasteCollectionPointsEnum.status.INVISIBLE.id,
    name: "",
    location: "",
    opening_hours: "",
    web_page: "",
    phone_number: "",
    types: []
};

class WasteCollectionPointsCreateAndEditModalForm extends Component {

    render = () => {
        const { triggerComponent, triggerText, triggerClass, wasteCollectionPoint, showToast, wasteCollectionPointAdd } = this.props;

        const fixedWasteCollectionPoint =
            wasteCollectionPoint
                ? this._fixWasteCollectionPointValues(wasteCollectionPoint)
                : null;

        const wasteCollectionPointValues =
            wasteCollectionPoint
                ? fixedWasteCollectionPoint
                : this._fixDefaultFormValues();

        return (
            <Formik
                initialValues={ wasteCollectionPointValues }
                render={ formikBag => (
                    <FormModal
                        triggerText={ triggerText }
                        triggerClass={ triggerClass }
                        triggerComponent={ triggerComponent }
                        ariaLabel='Dodaj punkt zbiórki'
                        headerText='Dodaj punkt zbiórki'
                        saveOrCancelButtons={ !!wasteCollectionPoint }
                        saveOrStayOrCancelButtons={ !wasteCollectionPoint }
                        _actionFunction={ () => {
                            return this._onSubmit(formikBag);
                        } }
                        _actionFunctionWithFormClear={ () => {
                            return this
                                ._onSubmit(formikBag)
                                .then(() => this._setFormValues(formikBag, null))
                                .then( () => {
                                    toastFor500(showToast, wasteCollectionPointAdd, "Punkt zbiórki nie został zapisany. Spróbuj ponownie");
                                    toastForSuccess(showToast, wasteCollectionPointAdd, "Zapisano punkt zbiórki")});
                        } }>

                        <CreateAndEditModalFormContent
                            wasteCollectionPoint={ fixedWasteCollectionPoint }
                            formikBag={ formikBag }
                            _setFormValues={ this._setFormValues }/>

                    </FormModal>
                ) }/>
        );
    };

    _fixDefaultFormValues = () => {
        const { defaultCoordinates } = this.props;

        return {
            ...initialFormValues,
            latitude: defaultCoordinates.latitude,
            longitude: defaultCoordinates.longitude
        };
    };

    // TODO: remove after API is fixed
    _fixWasteCollectionPointValues = (wasteCollectionPoint) => {

        if (isNullOrUndefined(wasteCollectionPoint)) {
            return {};
        }

        return {
            ...initialFormValues,
            statusId:
                !isNullOrUndefined(wasteCollectionPoint.status) && !isNullOrUndefined(wasteCollectionPoint.status.value)
                    ? wasteCollectionPoint.status.value
                    : initialFormValues.statusId,
            name:
                !isNullOrUndefined(wasteCollectionPoint.name)
                    ? wasteCollectionPoint.name
                    : initialFormValues.name,
            location:
                !isNullOrUndefined(wasteCollectionPoint.location)
                    ? wasteCollectionPoint.location
                    : initialFormValues.location,
            latitude:
                !isNullOrUndefined(wasteCollectionPoint.latitude)
                    ? parseFloat(wasteCollectionPoint.latitude)
                    : initialFormValues.latitude,
            longitude:
                !isNullOrUndefined(wasteCollectionPoint.longitude)
                    ? parseFloat(wasteCollectionPoint.longitude)
                    : initialFormValues.longitude,
            opening_hours:
                !isNullOrUndefined(wasteCollectionPoint.openingHours)
                    ? wasteCollectionPoint.openingHours
                    : initialFormValues.opening_hours,
            web_page:
                !isNullOrUndefined(wasteCollectionPoint.webPage)
                    ? wasteCollectionPoint.webPage
                    : initialFormValues.web_page,
            phone_number:
                !isNullOrUndefined(wasteCollectionPoint.phoneNumber)
                    ? wasteCollectionPoint.phoneNumber
                    : initialFormValues.phone_number,
            types:
                !isNullOrUndefined(wasteCollectionPoint.wasteCollectionPointTypes)
                    ? wasteCollectionPoint.wasteCollectionPointTypes.map(type => type.code)
                    : []
        };
    };

    _setFormValues = (formikBag, wasteCollectionPoint) => {
        if (!isNullOrUndefined(formikBag) && !isNullOrUndefined(wasteCollectionPoint)) {
            formikBag.resetForm(wasteCollectionPoint);
        } else if (!isNullOrUndefined(formikBag)) {
            formikBag.resetForm(this._fixDefaultFormValues());
        }
    };

    _validate = (values) => {
        const errors = {};

        if (isEmptyString(values.name)) {
            errors.name = "Pole wymagane";
        }

        if (isEmptyString(values.location)) {
            errors.location = "Pole wymagane";
        }

        if (isEmptyArray(values.types)) {
            errors.types = "Pole wymagane";
        }

        return errors;
    };

    _onSubmit = (formikBag) => {
        const { wasteCollectionPoint, addWasteCollectionPoint, updateWasteCollectionPoint, _onSubmitCallback } = this.props;
        const errors = this._validate(formikBag.values);

        if (!isEmptyObject(errors)) {
            formikBag.setErrors(errors);
            return Promise.reject(null);
        }

        if (!isNullOrUndefined(wasteCollectionPoint)) {
            return updateWasteCollectionPoint(wasteCollectionPoint.id, formikBag.values)
                .then(() => invoke(_onSubmitCallback));
        } else {
            return addWasteCollectionPoint(formikBag.values)
                .then(() => invoke(_onSubmitCallback));
        }
    };
}

const mapStateToProps = (state) => {
    return {
        defaultCoordinates: state.entities.common.owners.getCoordinates,
        wasteCollectionPointAdd: state.entities.wasteManagement.wasteCollectionPoints.add
    };
};

export default connect(mapStateToProps, { addWasteCollectionPoint, updateWasteCollectionPoint, showToast })(WasteCollectionPointsCreateAndEditModalForm);

