import React, { useEffect, useState } from "react";

import Header from "../../../../../../../common/header";
import { Form, Formik } from "formik";
import FormRequiredLegend from "../../../../../../../common/legend_required";
import { BottomCustomButtons } from "../../../../../../../common/bottom_action_buttons";
import { ROUTE_BLISKO_MESSAGE_TEMPLATE } from "../../../../../../../../core/constants";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOwnerConfiguration } from "../../../../../../../../data/actions/common/owner";
import MessageContent from "../../../../../../../common/message_content_form";
import { MessageEnum } from "../../../../../../../../core/enums/waste_management/messages";
import { addFile } from "../../../../../../../../data/actions/common/files";
import { isEmptyArray, isEmptyObject, isNullOrUndefined } from "../../../../../../../../core/utils/misc_utils";
import { toastForCustomError, toastForCustomSuccess } from "../../../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../../../data/actions/common/ui/toast";
import {
    addMessageTemplate,
    getMessageTemplate,
    updateMessageTemplate
} from "../../../../../../../../data/actions/blisko/message_templates";
import Loader from "../../../../../../../common/loader";
import _ from "lodash";
import MessageTemplateName from "./message_template_name";
import { is4xx, is5xx } from "../../../../../../../../core/utils/api_utils";
import { MessageTemplateEnum } from "../../../../../../../../core/enums/blisko/message_template";

const initialFormValues = {
    name: "",
    title: "",
    body: "",
    latitude: "",
    longitude: "",
    priority: MessageEnum.priorityLevel.INFO
};

const initialState = () => {
    return {
        useGeoLocation: false,
        uploadedFiles: [],
    };
};

const AddAndEditMessageTemplate = ({ match }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const uploadingFile = useSelector(state => state.entities?.common?.files?.add);
    const defaultCoordinates = useSelector(state => state.entities?.common?.owners?.getCoordinates);
    const formikInitialFormValues = {
        ...initialFormValues,
        latitude: defaultCoordinates.latitude,
        longitude: defaultCoordinates.longitude
    };
    const [state, setState] = useState(initialState);
    const [messageTemplate, setMessageTemplate] = useState();

    const messageTemplateId = match?.params?.messageTemplateId;
    const isEditMode = !!messageTemplateId;

    useEffect(() => {
        dispatch(getOwnerConfiguration());

        if (isEditMode) {
            dispatch(getMessageTemplate(messageTemplateId))
                .then(response => {
                    const data = response?.data;
                    setMessageTemplate(data);

                    setState(prevState => {
                        let newState = { ...prevState, useGeoLocation: data?.latitude && data?.longitude }

                        if (data?.attachments) {
                            newState = {
                                ...newState,
                                uploadedFiles: [...data?.attachments],
                            }
                        }
                        return newState;
                    });
                })
        }
    }, [dispatch, messageTemplateId, isEditMode]);

    const initializeFormValues = () => {
        let values = _.cloneDeep(formikInitialFormValues);

        if (isEditMode) {
            values.name = messageTemplate?.name;
            values.title = messageTemplate?.title;
            values.body = messageTemplate?.body;
            values.latitude = messageTemplate?.latitude;
            values.longitude = messageTemplate?.longitude;
            values.priority = Object.entries(MessageEnum.priorityLevel).filter(item => item[0] === messageTemplate?.priority).map(item => item[1])[0];
        }

        return values;
    };

    const _useGeoLocationToggle = () => {
        setState(prevState => {
            return { ...prevState, useGeoLocation: !prevState.useGeoLocation }
        })
    };

    const _onFileUploadStarted = (fileArray) => {
        const { uploadedFiles } = state;

        fileArray.forEach(file =>
            dispatch(addFile(file))
                .then(uploadedFile => {
                    if (!uploadedFile.error && uploadedFile.data) {
                        const uploadedFileData = uploadedFile.data;

                        if (uploadedFileData && uploadedFiles.indexOf(uploadedFileData) === -1) {

                            const newUploadedFiles = uploadedFiles;
                            newUploadedFiles.push(uploadedFileData);
                            setState(prevState => {
                                return { ...prevState, uploadedFiles: newUploadedFiles }
                            });
                        }
                    }
                })
        );
    };

    const _onDeleteUploadedFileClick = (id) => {
        const { uploadedFiles } = state;

        if (!id || isEmptyArray(uploadedFiles)) {
            return;
        }

        let i = 0;
        for (; i < uploadedFiles.length; i++) {
            if (uploadedFiles[i].id === id) {
                break;
            }
        }

        const newUploadedFiles = uploadedFiles;
        newUploadedFiles.splice(i, 1);

        setState(prevState => {
            return { ...prevState, uploadedFiles: newUploadedFiles }
        });
    };

    const _validate = (values) => {
        const { uploadedFiles } = state;

        const errors = {};
        const isTitleValid = !!values.title;
        const isNameValid = !!values.name;
        const isBodyValid =
            values.body &&
            !(values.body.search('<p><br data-mce-bogus="1"></p>') >= 0 ||
                values.body.search('<p><br></p>') >= 0);

        if (!isNameValid) {
            errors.name = "Pole wymagane";
        }

        if (!isTitleValid) {
            errors.title = "Pole wymagane";
        }

        if (!isBodyValid) {
            errors.body = "Pole wymagane";
        }

        uploadedFiles.forEach(file => {
            if (file.alternativeTextRequired && !file.alternativeText) {
                if (!errors.uploadedFiles) {
                    errors.uploadedFiles = new Map();
                }
                errors.uploadedFiles.set(file.id, "Opis alternatywny wymagany");
            }
        });

        return errors;
    };

    const _handleErrorMessage = (setErrors, response, message) => {
        const errors = {};
        const nameAlreadyExistsCode = MessageTemplateEnum.errorCode.NAME_ALREADY_EXISTS;

        if (response?.data?.message.startsWith(nameAlreadyExistsCode)) {
            errors.name = "Szablon wiadomości o tej nazwie już istnieje";
        }

        if (!isEmptyObject(errors)) {
            setErrors(errors);
            toastForCustomError((...args) => dispatch(showToast(...args)), "Błędnie wypełniony formularz");
        } else {
            toastForCustomError((...args) => dispatch(showToast(...args)), message);
        }
    };

    const _onSubmit = (formValues, { setErrors }) => {
        const errors = _validate(formValues);
        const { useGeoLocation, uploadedFiles } = state;

        const data = {
            name: formValues.name,
            title: formValues.title,
            body: formValues.body,
            priority: formValues.priority.value,
            latitude: useGeoLocation ? formValues.latitude : null,
            longitude: useGeoLocation ? formValues.longitude : null,
            fileIds: uploadedFiles.map(file => file.id),
        };

        if (!isEmptyObject(errors)) {
            setErrors(errors);
            toastForCustomError((...args) => dispatch(showToast(...args)), "Błędnie wypełniony formularz");
        } else {
            if (isEditMode) {
                dispatch(updateMessageTemplate(messageTemplateId, data))
                    .then(response => {
                        if (is4xx(response?.status) || is5xx(response?.status)) {
                            _handleErrorMessage(setErrors, response, "Edycja szablonu wiadomości nie powiodła się. Spróbuj ponownie")
                        } else {
                            toastForCustomSuccess((...args) => dispatch(showToast(...args)), "Szablon wiadomości został pomyślnie wyedytowany");
                            history.push(ROUTE_BLISKO_MESSAGE_TEMPLATE);
                        }
                    })
            } else {
                dispatch(addMessageTemplate(data))
                    .then(response => {
                        if (is4xx(response?.status) || is5xx(response?.status)) {
                            _handleErrorMessage(setErrors, response, "Nie udało się dodać szablonu wiadomości. Spróbuj ponownie")
                        } else {
                            toastForCustomSuccess((...args) => dispatch(showToast(...args)), "Szablon wiadomości został pomyślnie dodany");
                            history.push(ROUTE_BLISKO_MESSAGE_TEMPLATE);
                        }
                    })
            }
        }
    }

    if (isEditMode && isNullOrUndefined(messageTemplate)) {
        return (
            <Loader/>
        )
    }

    return (
        <>
            <Header>
                { isEditMode ? 'Edycja szablonu wiadomości' : 'Dodaj szablon wiadomości' }
            </Header>
            <Formik
                initialValues={ initializeFormValues() }
                onSubmit={ _onSubmit }
                validateOnChange={ false }
                validateOnBlur={ false }
                render={ formikBag => (
                    <Form className={ "has-bottom-action-buttons" }>
                        <main className="message-sending-1">
                            <div className="content--m content--not-centered">
                                <div className="is-relative">
                                    <FormRequiredLegend/>
                                </div>
                                <MessageTemplateName
                                    formikBag={ formikBag }
                                />
                                <MessageContent
                                    body={ formikBag.values.body }
                                    useGeoLocation={ state.useGeoLocation }
                                    uploadedFiles={ state.uploadedFiles }
                                    priorityLevel={ formikBag.values.priority }
                                    latitude={ formikBag.values.latitude }
                                    longitude={ formikBag.values.longitude }
                                    defaultCoordinates={ defaultCoordinates }
                                    setFieldValue={ formikBag.setFieldValue }
                                    errors={ formikBag.errors }
                                    step={ "2/2" }
                                    blisko
                                    _useGeoLocationToggle={ _useGeoLocationToggle }
                                    _onFileUploadStarted={ _onFileUploadStarted }
                                    _onDeleteUploadedFileClick={ _onDeleteUploadedFileClick }
                                    _onAlternativeTextChanged={ () => formikBag.setErrors({}) }
                                    _onMessageContentChanged={ (value) => formikBag.setFieldValue("body", value) }
                                />
                            </div>
                            <BottomCustomButtons
                                containerClass="content--m content--not-centered"
                                buttonSpacingClass="space-between"
                            >
                                <button className="btn btn--small btn--outlined"
                                        onClick={ () => history.push(ROUTE_BLISKO_MESSAGE_TEMPLATE) }>
                                    Zamknij
                                </button>
                                <button type='submit' className="btn btn--primary btn--medium"
                                        disabled={ uploadingFile?.meta?.loading }
                                >
                                    Zapisz
                                </button>
                            </BottomCustomButtons>
                        </main>
                    </Form>
                ) }
            />
        </>
    )
};

export default AddAndEditMessageTemplate;