import areas from "./areas";
import bins from "./bins";
import bulkyWaste from "./bulky_waste";
import companies from "./companies";
import locationCandidates from "./location_candidates";
import newsletter from "./newsletter";
import paymentsDueDate from "./payments_due_date";
import reports from "./reports";
import messages from "./messages";
import wasteCollectionPoints from "./waste_collection_points";
import messageAddresses from "./message_addresses";
import communalDocuments from "./communal_documents";
import receptions from "./receptions";

export default (state = {}, action) => {
    return {
        ...state,
        areas: areas(state.areas, action),
        bins: bins(state.bins, action),
        bulkyWaste: bulkyWaste(state.bulkyWaste, action),
        companies: companies(state.companies, action),
        locationCandidates: locationCandidates(state.locationCandidates, action),
        newsletter: newsletter(state.newsletter, action),
        paymentsDueDate: paymentsDueDate(state.paymentsDueDate, action),
        reports: reports(state.reports, action),
        messages: messages(state.messages, action),
        wasteCollectionPoints: wasteCollectionPoints(state.wasteCollectionPoints, action),
        messageAddresses: messageAddresses(state.messageAddresses, action),
        communalDocuments: communalDocuments(state.communalDocuments, action),
        receptions: receptions(state.receptions, action)
    }
}