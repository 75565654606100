import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import InspectionCreateModalFormContent from "./content";
import FormModal from "../../../../../common/modal/form_modal";
import {
    filesToBase64,
    invoke,
    isEmptyArray,
    isEmptyObject,
    isEmptyString,
    isNullOrUndefined
} from "../../../../../../core/utils/misc_utils";
import { toastFor500, toastForSuccess } from "../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../data/actions/common/ui/toast";

import { ReactComponent as IconAdd } from "../../../../../../../resource/img/icons/icon_add_white.svg";
import { addInspection } from "../../../../../../data/actions/ekostraznik/inspections";
import withLocationManagement from "../../../../../common/hoc/with_location_management";


const initialFormValues = {
    binCode: "",
    companyId: "",
    description: "",
    images: [],
    groupId: '',
    address: []
};

class InspectionCreateModalForm extends Component {

    state = {
        resetAddressForm: false
    };

    render = () => {
        const {
            triggerText, triggerClass, inspectionAdd,
            // withLocationManagementProps
            values, renderSuggestions, inputDisabled, _setFieldValue, _onInputFieldFocus, _onInputFieldBlur, _resetForm

        } = this.props;

        return (
            <Formik
                initialValues={ initialFormValues }
                render={ formikBag => (
                    <FormModal
                        triggerText={ triggerText }
                        triggerClass={ triggerClass }
                        triggerComponent={
                            <button className="btn btn--primary btn--big header__main-cta-btn">
                                    <span className="btn__icon-wrapper--left">
                                        <span className='btn__icon'>
                                            <IconAdd/>
                                        </span>
                                        Dodaj kontrolę
                                    </span>
                            </button>
                        }
                        ariaLabel='Dodaj kontrolę nieprawidłowej segregacji'
                        headerText='Dodaj kontrolę nieprawidłowej segregacji'
                        saveOrStayOrCancelButtons={ true }
                        isLoading={ inspectionAdd.meta.loading }
                        _actionFunction={ () => {
                            return this._onSubmit(formikBag);
                        } }
                        _actionFunctionWithFormClear={ () => {
                            return this
                                ._onSubmit(formikBag)
                                .then(() => this._setFormValues(formikBag, null))
                                .then(this.setState({
                                    resetAddressForm: true
                                }));
                        } }>

                        <InspectionCreateModalFormContent
                            withLocationManagementProps={ { values, renderSuggestions, inputDisabled, _setFieldValue, _onInputFieldFocus, _onInputFieldBlur, _resetForm } }
                            formikBag={ formikBag }
                            errors={ formikBag.errors }
                            resetAddressForm={ this.state.resetAddressForm }
                            _setRestartAddress={ this._setRestartAddress}
                            _setFormValues={ this._setFormValues }/>

                    </FormModal>
                ) }/>
        );
    };

    _setFormValues = (formikBag, item) => {
        const { _resetForm } = this.props;

        if (!isNullOrUndefined(formikBag) && !isNullOrUndefined(item)) {
            formikBag.resetForm(item);
        } else if (!isNullOrUndefined(formikBag)) {
            formikBag.resetForm({
                ...initialFormValues,
                images: []
            });
            _resetForm();
        }
    };

    _validate = (formValues) => {
        const errors = {};

        if (isEmptyString(formValues.binCode)) {
            errors.binCode = "Pole wymagane";
        }

        if (isEmptyString(formValues.companyId)) {
            errors.companyId = "Pole wymagane";
        }

        if (!formValues.address.selectedFrom && !formValues.groupId && !formValues.address.selectedApartmentAddress) {
            errors.address = "Pole wymagane";
        }

        if (isEmptyString(formValues.description)) {
            errors.description = "Pole wymagane";
        }

        if (isEmptyArray(formValues.images) || formValues.images.length < 3) {
            errors.images = "Dodaj min. 3 zdjęcia";
        }

        return errors;
    };

    _setRestartAddress = (value) => {
        this.setState({
            resetAddressForm: value
        })
    }

    _onSubmit = (formikBag) => {
        const { addInspection, _onSubmitCallback, inspectionAdd } = this.props;
        const errors = this._validate(formikBag.values);

        if (!isEmptyObject(errors)) {
            formikBag.setErrors(errors);
            return Promise.reject(null);
        }

        return filesToBase64(formikBag.values.images)

            .then(base64Images =>
                addInspection({
                    type: 'invalid_segregation',
                    binCode: formikBag.values.binCode,
                    location: formikBag.values.address.selectedFrom?.id || formikBag.values.address.selectedApartmentAddress?.id,
                    images: base64Images,
                    companyId: Number(formikBag.values.companyId),
                    group: formikBag.values.groupId,
                    description: formikBag.values.description,
                    isLocationIncomplete: false
                })
            )
            .then(() => {
                toastFor500(showToast, inspectionAdd, "Dodanie kontroli nieprawidłowej segragacji nie powiodło się. Spróbuj ponownie");
                toastForSuccess(showToast, inspectionAdd, "Nowa kontrola nieprawidłowej segragacji została dodana");
            })
            .then(() => invoke(_onSubmitCallback));
    };
}

const mapStateToProps = (state) => {
    return {
        inspectionAdd: state.entities.ekostraznik.inspections.add,
    };
};

export default withLocationManagement(connect(mapStateToProps, { addInspection, showToast })(InspectionCreateModalForm));
