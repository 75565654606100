import { deepFreeze } from "../../utils/misc_utils";

export const BinsEnum = deepFreeze({
    colors: {
        BLACK: "BLACK",
        BLUE: "BLUE",
        BROWN: "BROWN",
        GREEN: "GREEN",
        YELLOW: "YELLOW",
        WHITE: "WHITE",
    }
});

export const CustomBinsEnum = deepFreeze({
    colors: {
        GRAY: "#c6cece",
        BEIGE: "#c2b280",
        CHERRY: "#b50000",
        CELADON : "#ace1af",
        PURPLE: "#8800be"
    }
});
