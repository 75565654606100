import webClient from "../../../core/web_client";
import { API_BASE_AKUN_ADMIN_PATH } from "../../../core/constants";

const PATH = `${ API_BASE_AKUN_ADMIN_PATH }/employees`;

export const getManyEmployees = () => {
    return webClient
        .get(`${ PATH }`);
};

export const getEmployee = (id) => {
    return webClient
        .get(`${ PATH }/${ id }`);
};

export const addEmployee = (data) => {
    return webClient
        .post(`${ PATH }`, data);
};

export const reinviteEmployee = (id) => {
    return webClient
        .post(`${ PATH }/${ id }/invitation`);
};

export const updateEmployee = (id, data) => {
    return webClient
        .put(`${ PATH }/${ id }`, data);
};

export const patchEmployee = (id, data) => {
    return webClient
        .patch(`${ PATH }/${ id }`, data);
};

export const deleteEmployee = (id) => {
    return webClient
        .delete(`${ PATH }/${ id }`);
};