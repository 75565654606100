import React, { Component } from "react";
import { Form, Formik } from "formik";
import { connect } from "react-redux";

import FormModal from "../../../../../../common/modal/form_modal";
import InspectionCompaniesCreateAndEditModalFormContent from "./content";
import { InspectionCompaniesEnum } from "../../../../../../../core/enums/waste_management/inspection_companies";
import { invoke, isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";
import {
    addInspectionCompany,
    renameInspectionCompany,
    resetInspectionCompanies
} from "../../../../../../../data/actions/ekostraznik/inspection_companies";
import { toastFor500, toastForSuccess } from "../../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../../data/actions/common/ui/toast";
import { is4xx } from "../../../../../../../core/utils/api_utils";

const initialFormValues = {
    name: ""
};

class InspectionCompaniesCreateAndEditModalForm extends Component {

    state = {
        errorMessage: ""
    };

    render = () => {
        const { inspectionCompany, _onSubmitCallback, triggerComponent, headerText, ariaLabel, resetInspectionCompanies } = this.props;

        const initialValues =
            !isNullOrUndefined(inspectionCompany) && !isNullOrUndefined(inspectionCompany.name)
                ? { name: inspectionCompany.name }
                : initialFormValues;

        return (
            <Formik
                initialValues={ initialValues }
                initialFormValues={ initialFormValues }
                render={ formikBag => (
                    <Form>
                        <FormModal
                            triggerComponent={ triggerComponent }
                            saveOrCancelButtons={ true }
                            ariaLabel={ ariaLabel }
                            headerText={ headerText }
                            _actionFunction={ () => {
                                return this
                                    ._onSubmit(formikBag)
                                    .then(this._handleErrorMessage)
                                    .then(() => invoke(_onSubmitCallback));
                            } }>

                            <InspectionCompaniesCreateAndEditModalFormContent
                                formikResetForm={ formikBag.resetForm }
                                errorMessage={ this.state.errorMessage }
                                _clearErrorMessage={ this._clearErrorMessage }
                                resetInspectionCompanies={ resetInspectionCompanies }/>
                        </FormModal>
                    </Form>
                ) }/>
        );
    };

    _handleErrorMessage = () => {
        const { inspectionCompanyAdd, inspectionCompanyRename } = this.props;

        if ((is4xx(inspectionCompanyAdd.meta.status)) ||
            (is4xx(inspectionCompanyRename.meta.status))) {

            const NameAlreadyExistsCode = InspectionCompaniesEnum.errorCode.NAME_ALREADY_EXISTS;
            const EmptyNameCode = InspectionCompaniesEnum.errorCode.EMPTY_NAME;

            if ((!isNullOrUndefined(inspectionCompanyAdd.meta.message) && inspectionCompanyAdd.meta.message.startsWith(NameAlreadyExistsCode)) ||
                (!isNullOrUndefined(inspectionCompanyRename.meta.message) && inspectionCompanyRename.meta.message.startsWith(NameAlreadyExistsCode))) {

                this.setState({
                    errorMessage: "Kontroler o tej nazwie już istnieje"
                });
                return Promise.reject(null);
            }
            else if ((!isNullOrUndefined(inspectionCompanyAdd.meta.message) && inspectionCompanyAdd.meta.message.startsWith(EmptyNameCode)) ||
                (!isNullOrUndefined(inspectionCompanyRename.meta.message) && inspectionCompanyRename.meta.message.startsWith(EmptyNameCode))) {

                this.setState({
                    errorMessage: "Dodaj nazwę kontrolera"
                });
                return Promise.reject(null);
            }
            else {
                this.setState({
                    errorMessage: "Wystąpił błąd"
                });

                return Promise.reject(null);
            }
        }

        return Promise.resolve();
    };

    _clearErrorMessage = () => {
        this.setState({
            errorMessage: ""
        });
    };

    _onSubmit = (formikBag) => {
        const { inspectionCompany, addInspectionCompany, renameInspectionCompany } = this.props;

        if (!isNullOrUndefined(inspectionCompany)) {
            return renameInspectionCompany(inspectionCompany.id, formikBag.values).then( () => {
                const { showToast, inspectionCompanyRename } = this.props;

                toastFor500(showToast, inspectionCompanyRename, "Zmiana nazwy firmy nie powiodła się. Spróbuj ponownie");
                toastForSuccess(showToast, inspectionCompanyRename, "Nazwa kontrolera została zmieniona");
            });
        }
        else {
            return addInspectionCompany(formikBag.values).then( () => {
                const { showToast, inspectionCompanyAdd } = this.props;

                toastFor500(showToast, inspectionCompanyAdd, "Dodanie kontrolera nie powiodło się. Spróbuj ponownie");
                toastForSuccess(showToast, inspectionCompanyAdd, "Dodano kontrolera");
            });
        }

    };
}

const mapStateToProps = (state) => {
    return {
        inspectionCompanyAdd: state.entities.ekostraznik.inspectionCompanies.add,
        inspectionCompanyRename: state.entities.ekostraznik.inspectionCompanies.rename
    };
};

export default connect(mapStateToProps, { addInspectionCompany, renameInspectionCompany, resetInspectionCompanies, showToast })(InspectionCompaniesCreateAndEditModalForm);
