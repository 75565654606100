import getPlaces from "./get_places";
import get from "./get";
import add from "./add";
import delete_ from "./delete";
import download from "./download";


export default (state = {}, action) => {
    return {
        ...state,
        getPlaces: getPlaces(state.getPlaces, action),
        get: get(state.get, action),
        add: add(state.add, action),
        delete_: delete_(state.delete_, action),
        download: download(state.download, action)
    };
}