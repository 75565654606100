import React from "react";

import CheckboxInput from "../../../../../../common/checkbox_input";
import DeleteMessageTemplateForm from "../form/delete_form";
import { useHistory } from "react-router-dom";
import { ROUTE_BLISKO_MESSAGE_TEMPLATE_EDIT } from "../../../../../../../core/constants";
import { replaceHtmlEntities } from "../../../../../../../core/utils/string_utils";
import striptags from "striptags";

const MessageTemplateItem = ({
                                 messageTemplate,
                                 checkedMessageTemplates,
                                 _onMessageTemplateCheckboxChange,
                                 _onDeleteOrUpdateSuccess
                             }) => {
    const history = useHistory();

    return (
        <tr>
            <td className="t-width-3">
                <CheckboxInput
                    _onInputChange={ () => _onMessageTemplateCheckboxChange(messageTemplate.id) }
                    checked={ checkedMessageTemplates.indexOf(messageTemplate.id) >= 0 }/>
                <p className="table-text-content is-clickable" onClick={() => history.push(ROUTE_BLISKO_MESSAGE_TEMPLATE_EDIT(messageTemplate.id))}>
                    { messageTemplate.name }
                </p>
            </td>
            <td className="t-width-5">
                <p className="table-text-content">
                    {
                        replaceHtmlEntities(striptags(messageTemplate.body))
                    }
                </p>
            </td>
            <td className="t-width-3">
                <p className="table-text-content">
                    { messageTemplate.createdBy }
                </p>
            </td>
            <td className='sticky--right td-action-buttons'>
                <DeleteMessageTemplateForm
                    messageTemplate={ messageTemplate }
                    _onSubmitCallback={ _onDeleteOrUpdateSuccess }/>
            </td>
        </tr>
    )
};

export default MessageTemplateItem;