import React, { useCallback, useEffect } from "react";

import EmptyListPage from "../../../../../../common/empty_list_page";
import IconEmptyMessages from "../../../../../../../../resource/img/icons/empty_list_icons/brak_wpisow.png";
import ListPagination from "../../../../../../common/pagination";
import withPagination from "../../../../../../common/hoc/with_pagination";
import { useDispatch, useSelector } from 'react-redux';
import { generatePaginationQueryValues, is4xx, is5xx } from '../../../../../../../core/utils/api_utils';
import SentMessagesListItem from "./item";
import { cancelMessage, getManyMessages } from "../../../../../../../data/actions/blisko/messages";
import { toastForCustomError } from '../../../../../../../core/utils/toast_utils';
import { showToast } from '../../../../../../../data/actions/common/ui/toast';

const SentMessagesList = ({ state, _onPrevClick, _onNextClick, _onPageNumberInputValueChange, currentPage, setMaxPage }) => {
    const dispatch = useDispatch();
    const messages = useSelector(state => state.entities?.blisko?.messages?.getMany);
    const { searchBarTerm, from, to, sendingStatus } = state;

    const totalPages = messages?.data?.totalPages;
    const isMessagesEmpty = !messages?.data;

    const _reloadTable = useCallback((searchBarTerm, from, to, sendingStatus) => {
        dispatch(getManyMessages({
            ...generatePaginationQueryValues(0, 30, ["requestedRealization", "desc"]),
            page: currentPage,
            query: searchBarTerm || '',
            from,
            to,
            sendingStatus
        })).then(() => {
                if (totalPages !== undefined) {
                    setMaxPage(totalPages);
                }
            }
        );
    }, [totalPages, currentPage, dispatch, setMaxPage]);

    useEffect(() => {
        _reloadTable(searchBarTerm, from, to, sendingStatus);
        // eslint-disable-next-line
    }, [currentPage, searchBarTerm, from, to, sendingStatus]);

    return (
        messages?.data?.content?.length === 0 ?
            (
                <EmptyListPage
                    img={ IconEmptyMessages }
                    imgAlt='Brak wiadomości'
                    text={ !searchBarTerm ? 'Brak wysłanych wiadomości' : 'Brak pasujących wyników' }
                />
            )
            :
            (
                <>
                    <section className="main-content--table__bottom container is-fluid">
                        <div className='table-wrapper'>
                            <table className='table--single-line fixed'>
                                <thead>
                                <tr>
                                    <th className='t-width-1'>Data wysyłki</th>
                                    <th className='t-width-2'>Status</th>
                                    <th className='t-width-5'>Treść wiadomości</th>
                                    <th className='t-width-3'>Utworzono przez</th>
                                </tr>
                                </thead>
                                <tbody>

                                {
                                    !isMessagesEmpty &&
                                    messages.data.content
                                        .map(message => {
                                            return (
                                                <SentMessagesListItem
                                                    key={ message.id }
                                                    message={ message }
                                                    _onCancelMessage={ () =>
                                                        dispatch(cancelMessage(message.id))
                                                            .then(response => {
                                                                if (is4xx(response?.status) || is5xx(response?.status)) {
                                                                    toastForCustomError((...args) => dispatch(showToast(...args)), "Nie udało się anulować wysyłki");
                                                                } else {
                                                                    _reloadTable(searchBarTerm, from, to, sendingStatus);
                                                                }
                                                            })
                                                    }
                                                />
                                            );
                                        })
                                }

                                </tbody>
                            </table>
                        </div>

                    </section>
                    <section className="container is-fluid is-flex">
                        <div className="is-flex justify-end pagination--list">
                            {
                                !isMessagesEmpty &&
                                <ListPagination
                                    data={ messages.data }
                                    _onPrevClick={ _onPrevClick }
                                    _onNextClick={ _onNextClick }
                                    _onPageNumberInputValueChange={ _onPageNumberInputValueChange }
                                />
                            }
                        </div>
                    </section>
                </>
            )
    )

};

export default withPagination(SentMessagesList);
