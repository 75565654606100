import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { queryParameterValue } from "../../../core/utils/string_utils";
import { deleteNewsletterSubscription, getNewsletterOwner } from "../../../data/actions/waste_management/newsletter";
import { isNullOrUndefinedOneOfMany } from "../../../core/utils/misc_utils";
import { ROUTE_NEWSLETTER_UNSUBSCRIBE_CONFIRMATION } from "../../../core/constants";
import { is4xx, is5xx } from "../../../core/utils/api_utils";

class NewsletterUnsubscribe extends Component {

    state = {
        requestFinished: false
    };

    render = () => {
        const { requestFinished } = this.state;
        const { newsletterSubscriptionDelete, getOwner } = this.props;

        if (requestFinished && !newsletterSubscriptionDelete.error) {
            return <Redirect to={ { pathname: ROUTE_NEWSLETTER_UNSUBSCRIBE_CONFIRMATION } }/>;
        }

        return (
            <div className="content--message">
                <div className="owner is-v-centered">

                    {
                        !isNullOrUndefinedOneOfMany(getOwner, getOwner.data) &&
                        !getOwner.meta.loading &&
                        <>
                            <img src={ getOwner.data.thumbnailUrl }
                                 alt="Herb"/>
                            <p>
                                {
                                    getOwner.data.name
                                }
                            </p>
                        </>
                    }


                </div>
                <p className="message">
                    Czy chcesz zrezygnować ze wszystkich powiadomień z e-usług
                    Gospodarka Odpadami? Rezygnacja dotyczy wszystkich adresów.
                </p>
                <button className="btn btn--danger is-spaced--bottom-10" onClick={ this._onUnsubscribeClick }>
                    Rezygnuję z powiadomień
                </button>
                {
                    !isNullOrUndefinedOneOfMany(newsletterSubscriptionDelete, newsletterSubscriptionDelete.data) &&
                    !newsletterSubscriptionDelete.meta.loading &&
                    newsletterSubscriptionDelete.error &&

                    this.renderErrorMessage
                }

            </div>
        );
    };

    componentDidMount = () => {
        const { location, getNewsletterOwner } = this.props;
        const ownerId = queryParameterValue(location.search, "ownerId");

        getNewsletterOwner(ownerId);
    };

    _onUnsubscribeClick = () => {
        const { location, deleteNewsletterSubscription } = this.props;
        const token = queryParameterValue(location.search, "token");

        deleteNewsletterSubscription(token)
            .then(() => this.setState({
                    requestFinished: true
                })
            );
    };

    renderErrorMessage = (newsletterSubscriptionDelete) => {
        if (!newsletterSubscriptionDelete.meta.loading
            && newsletterSubscriptionDelete.error
            && is4xx(newsletterSubscriptionDelete.meta.status)) {
            return (
                <p className='newsletter-error-message hint--rejected'>Adres email został już wypisany</p>
            );
        }

        if (!newsletterSubscriptionDelete.meta.loading
            && newsletterSubscriptionDelete.error
            && is5xx(newsletterSubscriptionDelete.meta.status)) {
            return (
                <p className='newsletter-error-message hint--rejected'>Błąd komunikacji z serwerem</p>
            );
        }

        return null;
    };
}


const mapStateToProps = (state) => {
    return {
        newsletterSubscriptionDelete: state.entities.wasteManagement.newsletter.delete_,
        getOwner: state.entities.wasteManagement.newsletter.getOwner
    };
};

export default connect(mapStateToProps, { deleteNewsletterSubscription, getNewsletterOwner })(NewsletterUnsubscribe);