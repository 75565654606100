import _ from "lodash";

import * as api from "../../../_api";
import { actions } from "../../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../../core/utils/api_utils";

export const getManyAreas = () => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__AREAS__GET_MANY__STARTED
    });

    return api
        .waste_management
        .areas
        .getManyAreas()
        .then(response => {
            const data = extractResponseData(response);
            const order = [];

            dispatch({
                type: actions.wasteManagement.API__AREAS__GET_MANY__SUCCESS,
                meta: { order },
                payload: _.mapKeys(data, "id"),
                error: false
            });

            return _.mapKeys(data, "id");
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__AREAS__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getManyAreasPaginated = (queryParams) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__AREAS__GET_MANY_PAGINATED__STARTED
    });

    return api
        .waste_management
        .areas
        .getManyAreasPaginated(queryParams)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__AREAS__GET_MANY_PAGINATED__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__AREAS__GET_MANY_PAGINATED__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getArea = (id) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__AREAS__GET__STARTED
    });

    return api
        .waste_management
        .areas
        .getArea(id)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__AREAS__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__AREAS__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const addArea = (data) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__AREAS__ADD__STARTED
    });

    return api
        .waste_management
        .areas
        .addArea(data)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__AREAS__ADD__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__AREAS__ADD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
            return error.response;
        });
};

export const updateArea = (id, data) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__AREAS__UPDATE__STARTED
    });

    return api
        .waste_management
        .areas
        .updateArea(id, data)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__AREAS__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__AREAS__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
            return error.response;
        });
};

export const deleteArea = (id) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__AREAS__DELETE__STARTED
    });

    return api
        .waste_management
        .areas
        .deleteArea(id)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__AREAS__DELETE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__AREAS__DELETE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
            return error.response;
        });
};