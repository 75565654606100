import webClient from "../../../core/web_client";
import { API_BASE_AKUN_GOK_PATH } from '../../../core/constants';

const PATH = `${API_BASE_AKUN_GOK_PATH}/messages/addresses`

export const getMany = () => {
    return webClient
        .get(PATH);
}

export const addUnits = (unitIds) => {
    return webClient
        .patch(PATH, {
            command: 'add-units',
            unitIds
        });
};

export const addUnitsRange = (unitIdFrom, unitIdTo) => {
    return webClient
        .patch(PATH, {
            command: 'add-address-range',
            fromId: unitIdFrom,
            toId: unitIdTo
        });
};

export const addArea = (areaId) => {
    return webClient
        .patch(PATH, {
            command: 'add-area-units',
            areaId
        });
};

export const removeUnits = (unitIds) => {
    return webClient
        .patch(PATH, {
            command: 'remove-units',
            unitIds
        });
};