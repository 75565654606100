import React from "react";
import { useDispatch, useSelector } from "react-redux";

import FormModal from "../../../../../../common/modal/form_modal";
import { ReactComponent as IconDelete } from "../../../../../../../../resource/img/icons/icon_delete.svg";
import { deleteManyAddressTemplates } from "../../../../../../../data/actions/blisko/address_templates";

const DeleteAddressTemplateForm = ({ addressTemplate, _onSubmitCallback }) => {
    const dispatch = useDispatch();
    const deleteStatus = useSelector(state => state.entities?.blisko?.addressTemplates?.deleteMany);

    return (
        <FormModal
            triggerComponent={
                <div className='unstyled-btn' title='Usuń'>
                    <IconDelete/>
                </div>
            }
            deleteOrCancelButtons={ true }
            _actionFunction={ () =>
                dispatch(deleteManyAddressTemplates({
                    ids: [addressTemplate.id]
                })).then(async () => _onSubmitCallback())
            }
            isLoading={ deleteStatus?.meta?.loading }
        >

            <div className='c-modal__body modal-size-m modal--no-header'>
                <p className="confirmation-alert">
                    Czy na pewno chcesz usunąć ten szablon adresu?
                </p>
            </div>

        </FormModal>
    );
};

export default DeleteAddressTemplateForm;
