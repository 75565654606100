import _ from "lodash";

import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const getManyCategories = () => dispatch => {
    dispatch({
        type: actions.services.API__CATEGORIES__GET_MANY__STARTED
    });

    return api
        .services
        .categories
        .getManyCategories()
        .then(response => {
            dispatch({
                type: actions.services.API__CATEGORIES__GET_MANY__SUCCESS,
                meta: {},
                payload: _.mapKeys(extractResponseData(response), "id"),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__CATEGORIES__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getCategory = (categoryId) => dispatch => {
    dispatch({
        type: actions.services.API__CATEGORIES__GET__STARTED
    });

    return api
        .services
        .categories
        .getCategory(categoryId)
        .then(response => {
            dispatch({
                type: actions.services.API__CATEGORIES__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            })
        })
        .catch(error => {
            dispatch({
                type: actions.services.API__CATEGORIES__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};