import React from "react";

const CheckboxInput = ({ label, checked, _onInputChange }) => {
    return (
        <label className={ `acceptance-label ${ checked ? " label--checked" : "" }` }
               onChange={ _onInputChange }>

            <input type='checkbox' className='acceptance-checkbox'/>
            <span className="acceptance-text">
                {
                    label
                }
             </span>
        </label>
    );
};

export default CheckboxInput;
