import React, { useCallback, useEffect, useState } from "react";

import withPagination from "../../../../../../common/hoc/with_pagination";
import CheckboxInput from "../../../../../../common/checkbox_input";
import FormModal from "../../../../../../common/modal/form_modal";
import {
    ReactComponent as IconDeleteWhite
} from "../../../../../../../../resource/img/icons/icon_delete--white-bin.svg";
import ListPagination from "../../../../../../common/pagination";
import { useDispatch, useSelector } from "react-redux";
import { generatePaginationQueryValues } from "../../../../../../../core/utils/api_utils";
import {
    deleteManyMessageTemplates,
    getManyMessageTemplatesPaginated
} from "../../../../../../../data/actions/blisko/message_templates";
import MessageTemplateItem from "./item";
import IconEmptyMessageTemplates from "../../../../../../../../resource/img/icons/empty_list_icons/brak_wpisow.png";
import EmptyListPage from "../../../../../../common/empty_list_page";

const defaultPaginationQueryValues = generatePaginationQueryValues(0, 30, ["name", "asc"]);

const MessageTemplateList = ({
                                 searchBarTerm,
                                 _onPrevClick,
                                 _onNextClick,
                                 _onPageNumberInputValueChange,
                                 currentPage,
                                 setMaxPage
                             }) => {
    const dispatch = useDispatch();
    const messageTemplates = useSelector(state => state.entities?.blisko?.messageTemplates?.getManyPaginated);
    const deleteStatus = useSelector(state => state.entities?.blisko?.messageTemplates?.deleteMany);
    const [checkedMessageTemplates, setCheckedMessageTemplates] = useState([]);

    const totalPages = messageTemplates?.data?.totalPages;
    const isMessageTemplatesEmpty = !messageTemplates?.data;
    const allChecked = checkedMessageTemplates.length === messageTemplates?.data?.content?.length;

    const _reloadTable = useCallback((searchBarTerm) => {
        dispatch(getManyMessageTemplatesPaginated({
            ...defaultPaginationQueryValues,
            page: currentPage,
            query: searchBarTerm
        })).then(() => {
                if (totalPages !== undefined) {
                    setMaxPage(totalPages);
                }

                setCheckedMessageTemplates([])
            }
        );
    }, [totalPages, currentPage, dispatch, setMaxPage]);

    useEffect(() => {
        _reloadTable(searchBarTerm);
        // eslint-disable-next-line
    }, [currentPage, searchBarTerm]);

    const _onMessageTemplateCheckboxChange = (id) => {
        const messageTemplateIndex = checkedMessageTemplates.indexOf(id);

        if (messageTemplateIndex >= 0) {
            const newCheckedMessageTemplates = [...checkedMessageTemplates];
            newCheckedMessageTemplates.splice(messageTemplateIndex, 1);

            setCheckedMessageTemplates(newCheckedMessageTemplates)
        } else {
            setCheckedMessageTemplates(prevState => [...prevState, id])
        }
    };

    const _onAllMessageTemplateCheckboxesChange = () => {
        if (allChecked) {
            setCheckedMessageTemplates([]);
        } else {
            const messageTemplateIds = messageTemplates?.data?.content
                .map(messageTemplate => {
                    return messageTemplate.id
                });
            setCheckedMessageTemplates(messageTemplateIds);
        }
    };

    const _onSelectedMessageTemplatesDeleteClick = () => {
        return dispatch(deleteManyMessageTemplates({
            templateIds: checkedMessageTemplates
        })).then(_onDeleteOrUpdateSuccess);
    };

    const _onDeleteOrUpdateSuccess = () => {
        _reloadTable(searchBarTerm);
    };

    if (messageTemplates?.data?.content?.length === 0) {
        return (
            <EmptyListPage
                img={ IconEmptyMessageTemplates }
                imgAlt='Brak szablonów wiadomości'
                text={ !searchBarTerm ? 'Brak szablonów wiadomości' : 'Brak pasujących wyników' }
            />
        )
    }

    return (
        <>
            <section className="main-content--table__bottom container is-fluid">
                <div className='table-wrapper'>
                    <table className='table--single-line fixed'>
                        <thead>
                        <tr>
                            <th className='t-width-3'>
                                <CheckboxInput label={ <p className="is-bold">Nazwa szablonu</p> }
                                               _onInputChange={ _onAllMessageTemplateCheckboxesChange }
                                               checked={ allChecked }/>
                            </th>
                            <th className='t-width-5'>
                                Treść wiadomości
                            </th>
                            <th className='t-width-3'>
                                Utworzono przez
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            !isMessageTemplatesEmpty &&
                            messageTemplates.data.content.map(messageTemplate => {
                                return (
                                    <MessageTemplateItem
                                        key={ messageTemplate.id }
                                        messageTemplate={ messageTemplate }
                                        checkedMessageTemplates={ checkedMessageTemplates }
                                        _onMessageTemplateCheckboxChange={ _onMessageTemplateCheckboxChange }
                                        _onDeleteOrUpdateSuccess={ _onDeleteOrUpdateSuccess }
                                    />
                                );
                            })
                        }
                        </tbody>
                    </table>
                </div>

            </section>
            <section className="container is-fluid is-flex">
                <div className="sll__pagination-wrapper">
                    {
                        checkedMessageTemplates.length > 0 &&
                        <FormModal
                            triggerComponent={
                                <div className="btn btn--danger btn--small">
                                        <span className="btn__icon-wrapper--left">
                                            <span className="btn__icon">
                                                <IconDeleteWhite/>
                                            </span>
                                            Usuń
                                        </span>
                                </div>
                            }
                            deleteOrCancelButtons={ true }
                            _actionFunction={ _onSelectedMessageTemplatesDeleteClick }
                            isLoading={ deleteStatus?.meta?.loading }
                        >

                            <div className='c-modal__body modal-size-m modal--no-header'>
                                <p className="confirmation-alert">
                                    Czy na pewno chcesz
                                    usunąć { checkedMessageTemplates?.length === 1 ? 'wybrany szablon' : "wybrane szablony" }?
                                </p>
                            </div>

                        </FormModal>
                    }
                </div>
                <div className="is-flex justify-end pagination--list">
                    {
                        !isMessageTemplatesEmpty &&
                        <ListPagination
                            data={ messageTemplates.data }
                            _onPrevClick={ _onPrevClick }
                            _onNextClick={ _onNextClick }
                            _onPageNumberInputValueChange={ _onPageNumberInputValueChange }
                        />
                    }
                </div>
            </section>
        </>

    );
};

export default withPagination(MessageTemplateList);