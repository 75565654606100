import React from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";


import moment from "moment/moment";
import MomentLocaleUtils, { formatDate } from "react-day-picker/moment";
import { MISC_DATE_ISO_DATE_ONLY_FORMAT, MISC_DATE_FORMAT } from "../../../../../../../../core/constants/index";
import { isNullOrUndefined } from "../../../../../../../../core/utils/misc_utils";

const PickRegulationDate = ({ formikBag, required }) => {
    const { fromDate } = formikBag.errors;

    return (
        <label onClick={ e => e.preventDefault() }>
            <span className="label-name">Data obowiązywania regulaminu
                {
                    required &&
                    <span className="required-accent">*</span>
                }
             </span>
            <DayPickerInput
                dayPickerProps={ {
                    disabledDays: { before: new Date() },
                    localeUtils: MomentLocaleUtils,
                    locale: "pl"
                } }
                formatDate={ formatDate }
                format={ MISC_DATE_FORMAT }
                onDayChange={ date => formikBag.setFieldValue("fromDate", moment(date)
                    .format(MISC_DATE_ISO_DATE_ONLY_FORMAT)) }
                placeholder="Wybierz"
                inputProps={ !isNullOrUndefined(fromDate) ? { className: "rejected" } : {} }
                localeUtils={ MomentLocaleUtils }/>
            {
                !isNullOrUndefined(fromDate) &&

                <span className="hint hint--rejected">{ fromDate }</span>
            }
        </label>
    );
};

export default PickRegulationDate;
