import React, { Component } from "react";
import { connect } from "react-redux";
import {
    MISC_DATE_DAY_MONTH_YEAR_HOUR_MINUTE_FORMAT,
    MISC_DATE_ISO_FORMAT,
    ROUTE_DETAILED_INSPECTION
} from "../../../../../../core/constants";
import Header from "../../../../../common/header";
import { getInspection, updateInspectionStatus } from "../../../../../../data/actions/ekostraznik/inspections";
import { isLoading, isNullOrUndefined } from "../../../../../../core/utils/misc_utils";
import Loader, { ButtonLoader } from "../../../../../common/loader";
import moment from "moment/moment";
import DocumentGallery from "../../../../../common/document_gallery/gallery";
import { InspectionStatusesEnum } from "../../../../../../core/enums/ekostraznik/inspection_statuses";
import { getManyBins } from "../../../../../../data/actions/waste_management/bins";
import BinIcon from "../../../../../common/BinIcon";
import { toastFor500, toastForSuccess } from "../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../data/actions/common/ui/toast";
import { ReactComponent as IconExport } from "../../../../../../../resource/img/icons/icon_export_document.svg";
import { generateInspectionDocument } from "../../../../../../data/actions/ekostraznik/inspection_history";
import AllAddressesInGroupModal from '../../common/list/all_addresses_in_group_modal';
import { ReactComponent as IconInfo } from '../../../../../../../resource/img/icons/icon_info.svg';
import { SelectWithIcons } from "../../../../../common/select";

class BinInspectionDetails extends Component {

    render = () => {
        const { match, inspection, bins, inspectionDocumentGenerate } = this.props;
        const parentType = match.params.parentType;
        const parentId = match.params.parentId;

        if (isLoading(inspection) || isNullOrUndefined(inspection.data) || isNullOrUndefined(bins.data) || !bins.data[inspection.data.binIds[0]]) {
            return (
                <Loader/>
            );
        }

        return (
            <div>
                <Header goBackLink={ ROUTE_DETAILED_INSPECTION(parentType, parentId) }>
                    Szczegóły kontroli
                </Header>
                <div className="content--s content--not-centered">

                    <div className="is-spaced--bottom-30">
                        <div className="report-details__row report-details__row--select">
                            <p className="report-details__label report-details__label--select">
                                Status kontroli:
                            </p>
                            <div>
                                <div className="report-details__field-input">
                                    <SelectWithIcons
                                        isClearable={ false }
                                        value={
                                            {
                                                label: InspectionStatusesEnum.statuses[inspection.data.status].label,
                                                value: InspectionStatusesEnum.statuses[inspection.data.status].value,
                                                icon: InspectionStatusesEnum.statuses[inspection.data.status].icon
                                            }
                                        }
                                        options={ this.renderStatuses(inspection.data.status) }
                                        onChange={ statusOption => this._onStatusChange(statusOption) }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        !isNullOrUndefined(inspection.data.company) &&

                        <p className="f-18 is-spaced--bottom-10">
                            {
                                inspection.data.company.name
                            }
                        </p>
                    }

                    {
                        !isNullOrUndefined(inspection.data.documentNumber) &&

                        <div className="report-details__row report-details__row--info">
                            <p className="report-details__label">Numer kontroli:</p>
                            <p className="report-details__text">
                                {
                                    inspection.data.documentNumber
                                }
                            </p>
                        </div>
                    }

                    {
                        !isNullOrUndefined(inspection.data.createdDate) &&

                        <div className="report-details__row report-details__row--info">
                            <p className="report-details__label">Data dodania:</p>
                            <p className="report-details__text">
                                {
                                    moment(inspection.data.createdDate, MISC_DATE_ISO_FORMAT)
                                        .format(MISC_DATE_DAY_MONTH_YEAR_HOUR_MINUTE_FORMAT)
                                }

                            </p>
                        </div>
                    }

                    {
                        !isNullOrUndefined(inspection.data.binIds) &&

                        <div className="report-details__row report-details__row--info">
                            <p className="report-details__label">Rodzaj pojemnika:</p>
                            <p className="report-details__text flex-center">

                                <BinIcon color={ bins.data[inspection.data.binIds[0]].color }/>
                                <span className="is-spaced--left-5">
                                     {
                                         bins.data[inspection.data.binIds[0]].name
                                     }
                                </span>
                            </p>
                        </div>
                    }

                    {
                        !isNullOrUndefined(inspection.data.location) &&

                        <div className="report-details__row report-details__row--info">
                            <p className="report-details__label">Lokalizacja pojemnika:</p>
                            <p className="report-details__text">
                                {
                                    inspection.data.location.town + ", "
                                }

                                {
                                    !isNullOrUndefined(inspection.data.location.street) &&
                                    inspection.data.location.street + " "
                                }

                                {
                                    inspection.data.location.address
                                }
                            </p>
                        </div>
                    }

                    {
                        !isNullOrUndefined(inspection.data.group) &&

                        <div className="report-details__row report-details__row--info">
                            <p className="report-details__label">Grupa:</p>
                            {
                                <p className="report-details__text">
                                    {
                                        inspection.data.group.name
                                    }
                                </p>
                            }
                            {
                                <div className='label-icon-btn'>
                                    <AllAddressesInGroupModal
                                        triggerComponent={ <IconInfo/> }
                                        groupName={ inspection.data.group.name }
                                        reportDate={ inspection.data.createdDate }
                                        addresses={ inspection.data.group.addresses }
                                    />
                                </div>
                            }
                        </div>
                    }

                    {
                        !isNullOrUndefined(inspection.data.description) &&

                        <div className="report-details__row report-details__row--info">
                            <p className="report-details__label">Opis:</p>
                            <p className="report-details__text">
                                {
                                    inspection.data.description
                                }
                            </p>
                        </div>
                    }
                    {
                        !isNullOrUndefined(inspection.data.attachmentPaths) &&

                        <DocumentGallery
                            label={ "Dokumentacja fotograficzna:" }
                            attachmentPaths={ inspection.data.attachmentPaths }/>
                    }

                    <div className="text-right">
                        <button className="btn btn--primary" onClick={ this._onGenerateDocumentClick } disabled={ inspectionDocumentGenerate.meta.loading }>
                        <span className="btn__icon-wrapper--left">

                            <span className='btn__icon'>
                                 {
                                     inspectionDocumentGenerate.meta.loading
                                         ?
                                         <ButtonLoader/>
                                         :
                                         <IconExport/>
                                 }
                            </span>
                            Wygeneruj dokument kontroli
                         </span>
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    componentDidMount = () => {
        const { match, getInspection, getManyBins } = this.props;
        const inspectionId = match.params.inspectionId;

        getInspection(inspectionId);
        getManyBins();
    };

    renderStatuses = (status) => {
        const { PENDING, REJECTED, PROCESSED } = InspectionStatusesEnum.statuses;

        switch (status) {
            case PENDING.value:
                return [ PROCESSED, REJECTED ];
            case PROCESSED.value:
                return [ REJECTED, PENDING ];
            case REJECTED.value:
                return [ PROCESSED, PENDING ];
            default:
                return [];
        }
    };

    _onStatusChange = (statusOption) => {
        const { updateInspectionStatus, updatedStatus, getInspection, showToast, match } = this.props;
        const inspectionId = match.params.inspectionId;

        updateInspectionStatus(inspectionId, { command: statusOption.command })
            .then( () => getInspection(inspectionId))
            .then(() => {
                toastFor500(showToast, updatedStatus, "Status nie został zmieniony, spróbuj ponownie później");
                toastForSuccess(showToast, updatedStatus, "Status został zmieniony");
            })

    };

    _onGenerateDocumentClick = () => {
        const { match, generateInspectionDocument } = this.props;
        const inspectionId = match.params.inspectionId;

        generateInspectionDocument({ command: "generate-inspection-document", inspectionCodes: [ inspectionId ] });
    }
}

const mapStateToProps = (state) => {
    return {
        inspection: state.entities.ekostraznik.inspections.get,
        updatedStatus: state.entities.ekostraznik.inspections.update,
        bins: state.entities.wasteManagement.bins.getMany,
        inspectionDocumentGenerate: state.entities.ekostraznik.inspectionHistory.generate

    };
};


export default connect(mapStateToProps, { getInspection, updateInspectionStatus, getManyBins, generateInspectionDocument, showToast })(BinInspectionDetails);
