import React, { useEffect, useState } from 'react';

import { useHistory } from "react-router-dom";
import Header from "../../../../../common/header";
import { Form, Formik } from "formik";
import FormRequiredLegend from "../../../../../common/legend_required";
import { BottomCustomButtons } from "../../../../../common/bottom_action_buttons";
import { ROUTE_BLISKO_RECEIVER_GROUP } from "../../../../../../core/constants";
import FormModal from "../../../../../common/modal/form_modal";
import ReceiverGroupContent from "./receiver_group_content";
import ReceiverGroupOrder from "./receiver_group_order";
import ReceiverGroupEmployee from "./receiver_group_employee";
import { useDispatch, useSelector } from "react-redux";
import {
    addReceiverGroup,
    getReceiverGroup,
    updateReceiverGroup
} from '../../../../../../data/actions/blisko/receiver_groups';
import { is4xx, is5xx } from "../../../../../../core/utils/api_utils";
import { toastForCustomError, toastForCustomSuccess } from "../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../data/actions/common/ui/toast";
import _ from 'lodash';
import { isNullOrUndefined } from '../../../../../../core/utils/misc_utils';
import Loader from '../../../../../common/loader';
import { ReceiverGroupsEnum } from "../../../../../../core/enums/blisko/receiver_groups";

const initialValues = {
    name: "",
    description: "",
    isCommercial: false,
    receiverGroups: []
};

const ReceiverGroup = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const currentEmployee = useSelector(state => state.entities?.common?.account?.get)?.data;

    const receiverGroupId = props.match?.params?.receiverGroupId;
    const [receiverGroup, setReceiverGroup] = useState();
    const [isNameChanged, setNameChanged] = useState(false);

    const isEditMode = !!receiverGroupId;

    useEffect(() => {
        if (isEditMode) {
            dispatch(getReceiverGroup(receiverGroupId))
                .then(response => setReceiverGroup(response.data));
        }
    }, [dispatch, receiverGroupId, isEditMode]);

    const initialFormValues = () => {
        let values = _.cloneDeep(initialValues);
        let employees = [{
            label: currentEmployee.name + " " + currentEmployee.surname,
            value: currentEmployee.id
        }];

        if (isEditMode) {
            values.name = receiverGroup?.name;
            values.description = receiverGroup?.description;
            values.isCommercial = receiverGroup?.isCommercial;

            employees = receiverGroup?.employees.map(employee => {
                return {
                    label: employee.name + " " + employee.surname,
                    value: employee.id
                }
            })
        }

        return {
            ...values,
            employees: employees
        }
    };

    const _validate = (values) => {
        const errors = {};

        if (!values.name?.trim()) {
            errors.name = "Pole wymagane";
            toastForCustomError((...args) => dispatch(showToast(...args)), "Błędnie wypełniony formularz");
        }

        return errors;
    }

    const _onSubmit = (values) => {
        if (isEditMode) {
            updateGroup(values);
        } else {
            createGroup(values, false);
        }
    };

    const createGroup = (values, isDraft) => {
        let orderIndex;
        let receiverGroupsOrder = {};

        values.receiverGroups.forEach((receiverGroup, index) => {
            if (!receiverGroup.current) {
                receiverGroupsOrder[receiverGroup.id] = index;
            } else {
                orderIndex = index;
            }
        })

        const data = {
            name: values.name,
            description: values.description,
            isCommercial: values.isCommercial,
            status: isDraft ? "draft" : "active",
            employeeIds: values.employees.map(employee => employee.value),
            orderIndex,
            receiverGroupsOrder
        };

        return dispatch(addReceiverGroup(data))
            .then(response => {
                if (response?.data?.message?.startsWith(ReceiverGroupsEnum.errorCode.LIMIT_EXCEEDED)) {
                    toastForCustomError((...args) => dispatch(showToast(...args)),"Przekroczono limit serwisów publicznych");
                } else if (is4xx(response?.status) || is5xx(response?.status)) {
                    toastForCustomError((...args) => dispatch(showToast(...args)),
                        isDraft
                            ? "Dodanie serwisu w wersji roboczej nie powiodło się. Spróbuj ponownie"
                            : "Dodanie serwisu nie powiodło się. Spróbuj ponownie");
                } else {
                    toastForCustomSuccess((...args) => dispatch(showToast(...args)),
                        isDraft
                            ? "Serwis roboczy został pomyślnie dodany"
                            : "Serwis został pomyślnie dodany");
                    history.push(ROUTE_BLISKO_RECEIVER_GROUP);
                }
            });
    }

    const updateGroup = (values) => {
        let receiverGroupsOrder = {};

        values.receiverGroups.forEach((receiverGroup, index) => {
            receiverGroupsOrder[receiverGroup.id] = index;
        })

        const data = {
            name: values.name,
            description: values.description,
            isCommercial: values.isCommercial,
            employeeIds: values.employees.map(employee => employee.value),
            receiverGroupsOrder
        };

        return dispatch(updateReceiverGroup(receiverGroupId, data))
            .then(response => {
                if (is4xx(response?.status) || is5xx(response?.status)) {
                    toastForCustomError((...args) => dispatch(showToast(...args)),
                        "Edycja serwisu nie powiodła się. Spróbuj ponownie");
                } else {
                    toastForCustomSuccess((...args) => dispatch(showToast(...args)),
                        "Serwis został pomyślnie wyedytowany");
                    history.push(ROUTE_BLISKO_RECEIVER_GROUP);
                }
            });
    }

    if (isEditMode && isNullOrUndefined(receiverGroup)) {
        return (
            <Loader/>
        )
    }

    return (
        <>
            <Header>
                { isEditMode ? 'Edycja serwisu' : 'Dodaj serwis' }
            </Header>
            <Formik
                initialValues={ initialFormValues() }
                validate={ _validate }
                onSubmit={ _onSubmit }
                validateOnChange={ false }
                validateOnBlur={ false }
                render={ formikBag => (
                    <Form className={ "has-bottom-action-buttons" }>

                        <main className="message-sending-1">
                            <div className="content--m content--not-centered">
                                <div className="is-relative">
                                    <FormRequiredLegend/>
                                </div>
                                <ReceiverGroupContent
                                    formikBag={ formikBag }
                                    onNameChange={ (value) => {
                                        setNameChanged(receiverGroup?.name !== value)
                                    } }
                                />
                                <ReceiverGroupEmployee
                                    formikBag={ formikBag }
                                    currentEmployee={ isEditMode ? receiverGroup?.createdByEmployee : currentEmployee }
                                />
                                <ReceiverGroupOrder
                                    isEditMode={ isEditMode }
                                    formikBag={ formikBag }
                                />
                            </div>
                            <BottomCustomButtons
                                containerClass="content--m content--not-centered"
                                buttonSpacingClass="space-between"
                            >
                                <button className="btn btn--small btn--outlined"
                                        onClick={ () => history.push(ROUTE_BLISKO_RECEIVER_GROUP) }>
                                    Zamknij
                                </button>
                                <div>
                                    {
                                        !isEditMode &&
                                        <>
                                            <FormModal
                                                triggerDisabled={ !formikBag.values.name?.trim() }
                                                triggerComponent={ (
                                                    <button type="button" className="btn" style={ { fontWeight: 500 } }
                                                            onClick={ () => formikBag.setErrors(_validate(formikBag.values)) }>
                                                        Zapisz wersję roboczą
                                                    </button>
                                                ) }
                                                ariaLabel='Zapisz wersję roboczą'
                                                headerText='Zapisz wersję roboczą'
                                                saveOrCancelButtons={ true }
                                                _actionFunction={ () => {
                                                    return createGroup(formikBag.values, true);
                                                } }
                                            >
                                                <div className='c-modal__body modal-size-m modal--no-header'>
                                                    <p className="confirmation-alert">
                                                        Czy chcesz zapisać serwis jako roboczy?
                                                    </p>
                                                </div>
                                            </FormModal>

                                            <button type='submit' className="btn btn--primary btn--medium">
                                                Zapisz i opublikuj
                                            </button>
                                        </>
                                    }
                                    {
                                        isEditMode && !isNameChanged &&
                                        <button type='submit' className="btn btn--primary btn--medium">
                                            Zapisz
                                        </button>
                                    }
                                    {
                                        isEditMode && isNameChanged &&
                                        <FormModal
                                            triggerDisabled={ !formikBag.values.name?.trim() }
                                            triggerComponent={ (
                                                <button type='button' className="btn btn--primary btn--medium"
                                                        onClick={ () => formikBag.setErrors(_validate(formikBag.values)) }>
                                                    Zapisz
                                                </button>
                                            ) }
                                            dangerOrCancelButtons={ true }
                                            dangerButtonText={ 'Zmień nazwę' }
                                            _actionFunction={ () => {
                                                return updateGroup(formikBag.values);
                                            } }
                                        >
                                            <div className='c-modal__body modal-size-m modal--no-header'>
                                                <p className="confirmation-alert">
                                                    Czy na pewno chcesz zmienić nazwę serwisu? Pamiętaj, że użytkownicy
                                                    dostaną wiadomość o zmianie nazwy serwisu i charakteru wysłanych
                                                    informacji.
                                                    Mogą się przez to wypisać z serwisu.
                                                </p>
                                            </div>
                                        </FormModal>
                                    }
                                </div>
                            </BottomCustomButtons>
                        </main>
                    </Form>
                ) }
            />
        </>
    )
};

export default ReceiverGroup;
