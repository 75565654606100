import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData } from "../../../core/utils/api_utils";

export const getInspectionCountThreshold = () => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__INSPECTION_COUNT_THRESHOLD__GET__STARTED
    });

    return api
        .ekostraznik
        .inspectionCountThreshold
        .getInspectionCountThreshold()
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTION_COUNT_THRESHOLD__GET__SUCCESS,
                meta: {},
                payload: response.data,
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTION_COUNT_THRESHOLD__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};
export const updateInspectionCountThreshold = (body) => dispatch => {
    dispatch({
        type: actions.ekostraznik.API__INSPECTION_COUNT_THRESHOLD__UPDATE__STARTED
    });

    return api
        .ekostraznik
        .inspectionCountThreshold
        .updateInspectionCountThreshold(body)
        .then(response => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTION_COUNT_THRESHOLD__UPDATE__SUCCESS,
                meta: {},
                payload: response.data,
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.ekostraznik.API__INSPECTION_COUNT_THRESHOLD__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};
