import React from "react";
import { Link } from "react-router-dom";

const SidebarItem = ({ _onSidebarItemSelected, sidebarItemName, sidebarItemLink, sidebarItemIcon, sidebarItemId, activeSidebarItem }) => {
    return (
        <li className={ `sidebar__item  ${ activeSidebarItem === sidebarItemId ? "sidebar__item--active" : "" }` }
            onClick={ () => _onSidebarItemSelected(sidebarItemId) }>

            <Link to={ sidebarItemLink }
                  className='is-v-centered sidebar__link'>
                <img src={ sidebarItemIcon } className='sidebar__link-icon' alt=''/>
                <span className='sidebar__item-name'> { sidebarItemName } </span>
            </Link>

        </li>
    );
};

export default SidebarItem;