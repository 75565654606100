import webClient from "../../../../../core/web_client";
import { PATH as PATH_AREAS } from "../index";
import { isNullOrUndefined } from "../../../../../core/utils/misc_utils";
import { API_ACCEPT_HEADER, MISC_REST_CONTENT_TYPE_APPLICATION_PDF } from "../../../../../core/constants";

const PATH = "receptions";

export const getManyReceptionsForArea = (areaId, year, binId) => {
    const queryParameters = `?year=${ year }${ !isNullOrUndefined(binId) ? "&binId=" + binId : "" }`;
    return webClient
        .get(`${ PATH_AREAS }/${ areaId }/${ PATH }${ queryParameters }`);
};

export const getReceptionDocumentForArea = (areaId, year, binId) => {
    const queryParameters = `?year=${ year }${ !isNullOrUndefined(binId) ? "&binId=" + binId : "" }`;
    return webClient
        .get(`${ PATH_AREAS }/${ areaId }/${ PATH }${ queryParameters }`, {
            headers: {
                [API_ACCEPT_HEADER]: MISC_REST_CONTENT_TYPE_APPLICATION_PDF
            }
        });
};

export const updateReceptionStatusForArea = (areaId, binId, date, status) => {
    const body = { binId, date, status };
    return webClient
        .post(`${ PATH_AREAS }/${ areaId }/${ PATH }`, body);
};

export const publishChangesToMunicipalWasteReceptionsForArea = (areaId) => {
    return webClient
        .post(`${ PATH_AREAS }/${ areaId }/${ PATH }/publish`, null);
};