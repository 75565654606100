import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import FormModal from "../../../../../../common/modal/form_modal";
import BulkyWasteCreateAndEditModalFormContent from "./content";
import { isEmptyArray, isEmptyObject, isEmptyString, isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";
import { addBulkyWaste, getManyBulkyWaste, updateBulkyWaste } from "../../../../../../../data/actions/waste_management/bulky_waste";
import { toastFor500, toastForSuccess } from "../../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../../data/actions/common/ui/toast";

const initialFormValues = {
    dates: [],
    description: "",
    location: ""
};

class BulkyWasteCreateAndEditModalForm extends Component {

    render = () => {
        const {
            triggerComponent, triggerText, triggerClass, ariaLabel, headerText, saveOrStayOrCancelButtons,
            saveOrCancelButtons, readOnly, bulkyWasteItem, getManyBulkyWaste, showToast, bulkyWasteAdd
        } = this.props;

        const initialValues =
            !isNullOrUndefined(bulkyWasteItem)
                ? this._fixBulkyWasteValues(bulkyWasteItem)
                : this._fixDefaultFormValues(initialFormValues);

        return (
            <Formik
                initialValues={ initialValues }
                render={ formikBag => (
                    <FormModal
                        triggerComponent={ triggerComponent }
                        triggerText={ triggerText }
                        triggerClass={ triggerClass }
                        ariaLabel={ ariaLabel }
                        headerText={ headerText }
                        saveOrStayOrCancelButtons={ saveOrStayOrCancelButtons }
                        saveOrCancelButtons={ saveOrCancelButtons }
                        _actionFunction={ () => {
                            return this._onSubmit(formikBag, getManyBulkyWaste);
                        } }
                        _actionFunctionWithFormClear={ () => {
                            return this._onSubmit(formikBag)
                                       .then(() => this._setFormValues(formikBag, null))
                                       .then( () => {
                                            toastFor500(showToast, bulkyWasteAdd, "Kontener nie został zapisany. Spróbuj ponownie");
                                            toastForSuccess(showToast, bulkyWasteAdd, "Zapisano kontener")});
                        } }>

                        <BulkyWasteCreateAndEditModalFormContent
                            bulkyWasteItem={ bulkyWasteItem }
                            formikBag={ formikBag }
                            readOnly={ !isNullOrUndefined(readOnly) ? readOnly : false }
                            _setFormValues={ this._setFormValues }/>

                    </FormModal>
                ) }/>
        );
    };

    _fixDefaultFormValues = () => {
        const { defaultCoordinates } = this.props;

        return {
            ...initialFormValues,
            latitude: defaultCoordinates.latitude,
            longitude: defaultCoordinates.longitude
        };
    };

    _fixBulkyWasteValues = (bulkyWasteItem) => {
        return {
            dates:
                !isNullOrUndefined(bulkyWasteItem.dates)
                    ? bulkyWasteItem.dates
                    : initialFormValues.dates,
            description:
                !isNullOrUndefined(bulkyWasteItem.description)
                    ? bulkyWasteItem.description
                    : initialFormValues.description,
            latitude:
                !isNullOrUndefined(bulkyWasteItem.latitude)
                    ? bulkyWasteItem.latitude
                    : initialFormValues.latitude,
            longitude:
                !isNullOrUndefined(bulkyWasteItem.longitude)
                    ? bulkyWasteItem.longitude
                    : initialFormValues.longitude,
            location:
                !isNullOrUndefined(bulkyWasteItem.location)
                    ? bulkyWasteItem.location
                    : initialFormValues.location
        };
    };

    _setFormValues = (formikBag, bulkyWasteItem) => {
        if (!isNullOrUndefined(formikBag) && !isNullOrUndefined(bulkyWasteItem)) {
            formikBag.resetForm(this._fixBulkyWasteValues(bulkyWasteItem));
        } else if (!isNullOrUndefined(formikBag)) {
            formikBag.resetForm(this._fixDefaultFormValues(initialFormValues));
        }
    };


    _validate = (values) => {
        const errors = {};

        if (isEmptyString(values.location)) {
            errors.location = "Pole wymagane";
        }

        if (isEmptyArray(values.dates)) {
            errors.dates = "Wybierz datę podstawienia kontenera";
        }

        return errors;
    };

    _onSubmit = (formikBag) => {
        const { bulkyWasteItem, addBulkyWaste, getManyBulkyWaste, updateBulkyWaste } = this.props;
        const data = formikBag.values;
        const errors = this._validate(formikBag.values);

        if (!isEmptyObject(errors)) {
            formikBag.setErrors(errors);
            return Promise.reject(null);
        }

        if (isNullOrUndefined(bulkyWasteItem)) {
            return addBulkyWaste(data)
                .then(() => getManyBulkyWaste());
        } else {
            return updateBulkyWaste(bulkyWasteItem.id, data)
                .then(() => getManyBulkyWaste());
        }
    };
}

const mapStateToProps = (state) => {
    return {
        defaultCoordinates: state.entities.common.owners.getCoordinates,
        bulkyWasteAdd: state.entities.wasteManagement.bulkyWaste.add
    };
};

export default connect(mapStateToProps, { addBulkyWaste, updateBulkyWaste, getManyBulkyWaste, showToast })(BulkyWasteCreateAndEditModalForm);