import React, { Component } from "react";
import { Form } from "formik";
import GenerateInspectionDocumentMonthItem from "./month_inspections";

class GenerateInspectionDocumentModalFormContent extends Component {

    render = () => {
        const { inspections } = this.props;

        return (
            <div className='c-modal__body modal-size-m is-relative'>
                <Form className='form--vertical'>
                    {
                        this.renderMonths(inspections)
                    }
                </Form>
            </div>
        );
    };

    renderMonths = (inspections) => {
        const { selectedInspections, _onInspectionClick, selectedYear } = this.props;

        return Object.entries(inspections)
                     .map(inspection => {
                         return <GenerateInspectionDocumentMonthItem selectedInspections={ selectedInspections }
                                                                     selectedYear={ selectedYear }
                                                                     month={ inspection[0] }
                                                                     inspections={ inspection[1] }
                                                                     key={ inspection[0] }
                                                                     _onInspectionClick={ _onInspectionClick }/>;
                     });
    };

    componentDidMount = () => {
        const { _resetForm } = this.props;
        _resetForm();
    };

    componentWillUnmount = () => {
        const { _resetForm } = this.props;
        _resetForm();
    };
}


export default GenerateInspectionDocumentModalFormContent;