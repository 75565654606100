import add from "./add";
import change from "./change";
import changeMany from "./change_many";
import delete_ from "./delete";
import getMany from "./get_many";
import update from "./update";

export default (state = {}, action) => {
    return {
        ...state,
        add: add(state.add, action),
        change: change(state.change, action),
        changeMany: changeMany(state.changeMany, action),
        delete_: delete_(state.delete_, action),
        getMany: getMany(state.getMany, action),
        update: update(state.update, action)
    }
}