import _ from "lodash";

import * as api from "../../../_api";
import { actions } from "../../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../../core/utils/api_utils";

export const getManyReportEmployees = (reportId) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__REPORT_EMPLOYEES__GET_MANY__STARTED
    });

    return api
        .waste_management
        .reportEmployees
        .getManyReportEmployees(reportId)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__REPORT_EMPLOYEES__GET_MANY__SUCCESS,
                meta: {},
                payload: _.mapKeys(extractResponseData(response), "id"),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__REPORT_EMPLOYEES__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const updateReportEmployee = (reportId, employeeId) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__REPORT_EMPLOYEES__UPDATE_EMPLOYEE__STARTED
    });

    return api
        .waste_management
        .reportEmployees
        .updateReportEmployee(reportId, employeeId)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__REPORT_EMPLOYEES__UPDATE_EMPLOYEE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__REPORT_EMPLOYEES__UPDATE_EMPLOYEE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};