import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, Form, Formik } from "formik";

import SignedOutHeader from "../../header";
import GoBackLink from "../../go_back_link";
import { addPasswordResetRequest } from "../../../../data/actions/common/password_reset_request";
import { isNullOrUndefined } from "../../../../core/utils/misc_utils";
import { REGEX_EMAIL_SIMPLE } from "../../../../core/constants";
import { PasswordResetRequestEnum } from "../../../../core/enums/common/password_reset_request";


const initialFormValues = {
    email: "",
};

class PasswordResetRequestForm extends Component {
    state = {
        errors: {}
    };

    render = () => {
        const { passwordResetRequest, isSubmitting } = this.props;
        const { errors } = this.state;

        return (
            <>
                {
                    this.renderHeader()
                }

                <div className="form--signed-out">
                    <Formik
                        initialValues={ initialFormValues }
                        onSubmit={ values => this._onSubmit(values) }
                        render={ () => (
                            <Form>
                                <div className='input-wrapper'>
                                    <div className='field'>
                                        <label className='label'>
                                            E-mail
                                            <span>
                                                    <Field name="email"
                                                           render={ ({ field }) => {
                                                               return (
                                                                   <>

                                                                       <input className={ `${ !passwordResetRequest.meta.loading && !isNullOrUndefined(errors.email) ? "rejected" : "" }` }
                                                                              type="text"
                                                                              { ...field } />
                                                                       {
                                                                           !isNullOrUndefined(errors.email) &&
                                                                           <span className='hint hint--rejected'>{ errors.email }</span>
                                                                       }

                                                                   </>
                                                               );
                                                           } }/>
                                                </span>
                                        </label>
                                    </div>
                                </div>
                                <input type='submit' className='btn btn--primary' disabled={ isSubmitting } value='Wyślij'/>
                            </Form>
                        ) }/>

                </div>
                <GoBackLink/>
            </>
        );
    };

    renderHeader = () => {
        const { location } = this.props;
        const tokenExists = !isNullOrUndefined(location.state && location.state.tokenStatus);

        if ((tokenExists && location.state.tokenStatus === PasswordResetRequestEnum.data.EXPIRED)
            || (tokenExists && location.state.tokenStatus === PasswordResetRequestEnum.data.USED)) {
            return (
                <SignedOutHeader signedOutHeaderText="Link jest nieaktywny"
                                 signedOutSubtitle="Spróbuj ponownie zmienić hasło. Wpisz swój e-mail, wyślemy Ci link do zmiany hasła."/>
            );
        } else {
            return (
                <SignedOutHeader signedOutHeaderText="Zmiana hasła"
                                 signedOutSubtitle="Wpisz swój e-mail, wyślemy Ci link do zmiany hasła."/>
            );
        }
    };

    _onSubmit = (values) => {
        const { addPasswordResetRequest, _handleSubmit } = this.props;

        if (isNullOrUndefined(values.email)
            || !REGEX_EMAIL_SIMPLE.test(values.email)) {

            const { errors } = this.state;

            this.setState({
                errors: {
                    ...errors,
                    email: "Niepoprawny format adresu email"
                }
            });

        } else {
            this.setState({
                errors: {
                    errors: {}
                }
            }, () => {
                addPasswordResetRequest({
                    email: values.email
                });
            });

            _handleSubmit(values.email);
        }
    };
}

const mapStateToProps = (state) => {
    return {
        passwordResetRequest: state.entities.common.passwordResetRequest.add
    };
};


export default connect(mapStateToProps, { addPasswordResetRequest })(PasswordResetRequestForm);