import React, { Component } from "react";
import { Field, Form } from "formik";
import { connect } from "react-redux";

import CreateAndEditModalFormTypeSelect from "./type_select";
import CreateAndEditModalFormContentLocation from "./location";
import ReverseGeocodingMap from "../../../../../../../common/map/reverse_geocoding_map";
import { resetGeocoding, reverseGeocoding } from "../../../../../../../../data/actions/common/geocoding";
import FormRequiredLegend from "../../../../../../../common/legend_required";

class CreateAndEditModalFormContent extends Component {

    render = () => {
        const { formikBag, geocoding } = this.props;

        return (
            <div className='c-modal__body modal-size-m'>
                <Form className='form--vertical form--with-legend'>

                    <label>
                        <span className='label-name'><span className="required-accent">*</span> Rodzaj zbiórki:</span>
                        <span className="is-relative full-width">
                            <div>
                                <CreateAndEditModalFormTypeSelect
                                    formikBag={ formikBag }/>
                            </div>
                        </span>
                    </label>

                    <Field name="name"
                           render={ ({ field, form }) => (
                               <label>
                                   <span className='label-name'><span className="required-accent">*</span> Nazwa punktu:</span>
                                   <span className="is-relative full-width">
                                       <input type="text"
                                              className={ `${ form.errors.name ? "rejected" : "" }` }
                                              { ...field }/>

                                       {
                                           form.errors.name &&
                                           <span className="hint hint--rejected">{ form.errors.name }</span>
                                       }
                                   </span>
                               </label>
                           ) }/>


                    <CreateAndEditModalFormContentLocation
                        errors={ formikBag.errors.location }
                        required={ true }
                        value={ formikBag.values.location }
                        _onLocationFieldChangeCallback={ this._onForwardGeocodingLocationFieldChangeCallback }
                        _onSuggestionClickCallback={ this._onForwardGeocodingSuggestionClickCallback }/>

                    <ReverseGeocodingMap
                        reverse={ geocoding.reverse }
                        longitude={ formikBag.values.longitude }
                        latitude={ formikBag.values.latitude }
                        _reverseGeocodingCallback={ this._reverseGeocodingCallback }
                        _handleReverseGeocodingCallback={ this._handleReverseGeocodingCallback }/>

                    <Field name="opening_hours"
                           render={ ({ field }) => (
                               <label>
                                   <span className='label-name'>Godziny otwarcia:</span>
                                   <textarea
                                       placeholder='Np.: &#10;Poniedziałek - środa: 8:00 - 17:00 &#10;Czwartek - sobota: 8:30 - 17:30'
                                       { ...field } />
                               </label>
                           ) }/>

                    <Field name="web_page"
                           render={ ({ field }) => (
                               <label>
                                   <span className='label-name'>Adres www:</span>
                                   <input
                                       type="text"
                                       { ...field } />
                               </label>
                           ) }/>

                    <Field name="phone_number"
                           render={ ({ field }) => (
                               <label>
                                   <span className='label-name'>Telefon:</span>
                                   <input
                                       type="text"
                                       { ...field }/>
                               </label>
                           ) }/>
                    <FormRequiredLegend/>
                </Form>
            </div>
        );
    };

    componentDidMount = () => {
        const { wasteCollectionPoint, formikBag, _setFormValues } = this.props;
        _setFormValues(formikBag, wasteCollectionPoint);
    };

    componentWillUnmount = () => {
        const { resetGeocoding } = this.props;
        resetGeocoding();
    };

    _onForwardGeocodingLocationFieldChangeCallback = (value) => {
        const { formikBag } = this.props;

        formikBag.setFieldValue("location", value);
    };

    _onForwardGeocodingSuggestionClickCallback = (lng, lat, placeName) => {
        const { formikBag } = this.props;

        formikBag.setFieldValue("longitude", lng);
        formikBag.setFieldValue("latitude", lat);
        formikBag.setFieldValue("location", placeName);
    };

    _reverseGeocodingCallback = (longitude, latitude) => {
        const { formikBag, reverseGeocoding } = this.props;

        return reverseGeocoding(longitude, latitude)
            .then(() => {
                formikBag.setFieldValue("longitude", longitude);
                formikBag.setFieldValue("latitude", latitude);
            });
    };

    _handleReverseGeocodingCallback = (name) => {
        this.props.formikBag.setFieldValue("location", name);
    };
}

const mapStateToProps = (state) => {
    return {
        geocoding: state.entities.common.geocoding
    };
};

export default connect(mapStateToProps, { reverseGeocoding, resetGeocoding })(CreateAndEditModalFormContent);