import React from "react";
import { ReactComponent as IconDelete } from "../../../../../../../../../resource/img/icons/icon_delete.svg";


const ChosenAddresses = ({ messageAddresses, _onLocationRemove }) => {

    if (!messageAddresses?.length) {
        return null;
    }

    return (
        <div className="message-sending-1__box message-sending-1__box--list">
            <ul>
                {
                    messageAddresses.map(messageAddress =>
                        <li key={ messageAddress.name } className="message-sending-1__list-item">
                            <span>
                                {
                                    messageAddress.name
                                }
                            </span>

                            <span className="delete-trigger" onClick={ () => _onLocationRemove(messageAddress) } title='Usuń'>
                                <IconDelete/>
                            </span>
                        </li>
                    )
                }
            </ul>
        </div>
    );
};

export default ChosenAddresses;