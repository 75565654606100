import activate from "./activate";
import get from "./get";
import getActivationStatus from "./get_activation_status";

export default (state = {}, action) => {
    return {
        ...state,
        activate: activate(state.activate, action),
        get: get(state.get, action),
        getActivationStatus: getActivationStatus(state.getActivationStatus, action)
    };
}