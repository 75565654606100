import React from "react";

const NewsletterUnsubscribeConfirmation = () => {

    return (
        <div className="content--message flex-center">
            <p className="text--message">
                Rezygnacja przyjęta
            </p>
        </div>
    );
};

export default NewsletterUnsubscribeConfirmation;
