import React from "react";

import BulkyWasteCreateAndEditModalForm from "../create_and_edit_modal_form";
import { ReactComponent as IconNewDate } from "../../../../../../../../resource/img/icons/list/icon_new_date.svg";
import { ReactComponent as IconDetails } from "../../../../../../../../resource/img/icons/list/icon_details.svg";
import { arrayValuesAsString, isNullOrUndefined } from "../../../../../../../core/utils/misc_utils";

const BulkyWasteArchiveListItem = ({ bulkyWasteItem }) => {

    return (
        <tr>
            <td className='t-width-3'>
                <p className="table-text-content" title={ bulkyWasteItem.location }>
                    {
                        bulkyWasteItem.location
                    }
                </p>
            </td>
            <td className='t-width-8'>
                {
                    renderBulkyWasteDates(bulkyWasteItem)
                }
            </td>
            <td className='sticky--right td-action-buttons'>
                <BulkyWasteCreateAndEditModalForm
                    bulkyWasteItem={ bulkyWasteItem }
                    triggerComponent={ <button className='unstyled-btn'><IconNewDate/></button> }
                    headerText={ "Dodaj nową datę" }
                    saveOrCancelButtons={ true }/>

                <BulkyWasteCreateAndEditModalForm
                    bulkyWasteItem={ bulkyWasteItem }
                    triggerComponent={ <button className='unstyled-btn'><IconDetails/></button> }
                    headerText={ "Podgląd" }
                    readOnly={ true }/>
            </td>
        </tr>
    );
};

const renderBulkyWasteDates = (bulkyWasteItem) => {
    if (isNullOrUndefined(bulkyWasteItem.dates)) {
        return null;
    }

    const bulkyWasteDateItem = bulkyWasteItem.dates.map(date => (
        <span key={ date } className="single-date">{ date }</span>
    ));

    return (
        <p className="table-text-content" title={ arrayValuesAsString(bulkyWasteItem.dates) }>
            {
                bulkyWasteDateItem
            }
        </p>
    );
};

export default BulkyWasteArchiveListItem;