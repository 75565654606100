import add from "./add";
import generateNewCode from "./generate_new_code";
import getMany from "./get_many";
import rename from "./rename";
import update from "./update";

export default (state = {}, action) => {
    return {
        ...state,
        add: add(state.add, action),
        generateNewCode: generateNewCode(state.generateNewCode, action),
        getMany: getMany(state.getMany, action),
        rename: rename(state.rename, action),
        update: update(state.update, action)
    };
}