import { deepFreeze } from "../../utils/misc_utils";

const EkostraznikQrCodesEnum = deepFreeze({
    status: {
        NEW: 'NEW',
        PROCESSING: 'PROCESSING',
        READY: 'READY',
        REMOVED: 'REMOVED'
    }
});

export default EkostraznikQrCodesEnum;
