import React from "react";
import { useDispatch, useSelector } from "react-redux";

import FormModal from "../../../../../common/modal/form_modal";
import { hideReceiverGroup } from '../../../../../../data/actions/blisko/receiver_groups';
import { invoke } from "../../../../../../core/utils/misc_utils";
import { toastForCustomError, toastForCustomSuccess } from "../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../data/actions/common/ui/toast";
import { is4xx, is5xx } from "../../../../../../core/utils/api_utils";

const ReceiverGroupHideModalForm = ({ receiverGroup, _onSubmitCallback }) => {
    const dispatch = useDispatch();
    const receiverGroupHide = useSelector(state => state.entities?.blisko?.receiverGroups?.hide);

    const _onSubmit = () => {
        return dispatch(hideReceiverGroup(receiverGroup.id))
            .then(response => {
                if (is4xx(response?.status) || is5xx(response?.status)) {
                    toastForCustomError((...args) => dispatch(showToast(...args)), "Serwis nie został ukryty. Spróbuj ponownie");
                } else {
                    toastForCustomSuccess((...args) => dispatch(showToast(...args)), "Serwis został ukryty");
                    return invoke(_onSubmitCallback);
                }
            });
    };

    return (
        <FormModal
            triggerComponent={
                <button
                    className={ `btn  btn--short btn--small btn--outlined }` }>
                    { 'Ukryj' }
                </button>
            }
            dangerOrCancelButtons={ true }
            dangerButtonText={ 'Ukryj serwis' }
            isLoading={ receiverGroupHide?.meta.loading }
            _actionFunction={ () => _onSubmit() }>

            <div className='c-modal__body modal-size-m modal--no-header'>
                <p className="confirmation-alert">
                    Czy na pewno chcesz ukryć serwis? Pamiętaj, że użytkownicy dostaną wiadomość że serwis jest tymczasowo wyłączony. Czy chcesz kontynuować?
                </p>
            </div>

        </FormModal>
    );
};

export default ReceiverGroupHideModalForm;

