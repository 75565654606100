import { actions } from "../../../core/constants";
import * as api from "../../_api";
import { extractErrorData, extractResponseData, exportFile } from "../../../core/utils/api_utils";

export const getManyCommunalDocuments = () => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__COMMUNAL_DOCUMENTS__GET_MANY__STARTED
    });

    return api
        .waste_management
        .ownerCommunalDocuments
        .getMany()
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__COMMUNAL_DOCUMENTS__GET_MANY__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__COMMUNAL_DOCUMENTS__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const addCommunalDocument = (data) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__COMMUNAL_DOCUMENTS__ADD__STARTED
    });

    return api
        .waste_management
        .ownerCommunalDocuments
        .addCommunalDocument(data)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__COMMUNAL_DOCUMENTS__ADD__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__COMMUNAL_DOCUMENTS__ADD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });

            return error.response;
        });
}

export const downloadCommunalDocument = (id, name) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__COMMUNAL_DOCUMENTS__DOWNLOAD__STARTED
    });

    return api
        .waste_management
        .ownerCommunalDocuments
        .downloadCommunalDocument(id)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__COMMUNAL_DOCUMENTS__DOWNLOAD__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });

            exportFile(response.data, name);
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__COMMUNAL_DOCUMENTS__DOWNLOAD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
}

export const updateCommunalDocumentFilename = (id, filename) => dispatch => {
    return executeUpdateAction(() => api
            .waste_management
            .ownerCommunalDocuments
            .updateCommunalDocumentFilename(id, filename),
        dispatch
    )
}

export const updateCommunalDocumentHidden = (id, hidden) => dispatch => {
    return executeUpdateAction(() => api
            .waste_management
            .ownerCommunalDocuments
            .updateCommunalDocumentHidden(id, hidden),
        dispatch
    )
}

const executeUpdateAction = (action, dispatch) => {
    dispatch({
        type: actions.wasteManagement.API__COMMUNAL_DOCUMENTS__UPDATE__STARTED
    });

    return action()
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__COMMUNAL_DOCUMENTS__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__COMMUNAL_DOCUMENTS__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });

            return error.response;
        });
}

export const deleteCommunalDocument = (id) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__COMMUNAL_DOCUMENTS__DELETE__STARTED
    });

    return api
        .waste_management
        .ownerCommunalDocuments
        .deleteCommunalDocument(id)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__COMMUNAL_DOCUMENTS__DELETE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__COMMUNAL_DOCUMENTS__DELETE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
}