import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getManyReceiverGroups } from "../../../../../../data/actions/blisko/receiver_groups";
import withPagination from "../../../../../common/hoc/with_pagination";
import ListPagination from "../../../../../common/pagination";
import EmptyListPage from "../../../../../common/empty_list_page";
import IconEmptyReceiverGroup from "../../../../../../../resource/img/icons/empty_list_icons/brak_serwisow.png";
import ReceiverGroupListItem from "./item";
import { generatePaginationQueryValues } from "../../../../../../core/utils/api_utils";

const defaultPaginationQueryValues = generatePaginationQueryValues(0, 30, [ "name", "asc" ]);

const ReceiverGroupsList = ({ receiverGroups, _onPrevClick, _onNextClick, _onPageNumberInputValueChange, currentPage, setMaxPage }) => {
    const dispatch = useDispatch();
    const isReceiverGroupsEmpty = !receiverGroups?.data;
    const totalPages = receiverGroups?.data?.totalPages;
    
    const _reloadTable = useCallback(() => {
        dispatch(getManyReceiverGroups({
            ...defaultPaginationQueryValues,
            page: currentPage
        })).then(() => {
                if (totalPages !== undefined) {
                    setMaxPage(totalPages);
                }
            }
        );
    }, [totalPages, currentPage, dispatch, setMaxPage]);

    useEffect(() => {
        _reloadTable();
        // eslint-disable-next-line
    }, [ currentPage ]);

    const _onDeleteOrUpdateSuccess = () => {
        _reloadTable();
    };

    if (receiverGroups?.data?.content?.length === 0) {
        return (
            <EmptyListPage
                img={ IconEmptyReceiverGroup }
                imgAlt='Brak dodanych seriwsów publicznych'
                text={ 'Brak dodanych seriwsów publicznych' }
            />
        )
    }

    return (
        <>
            <section className="main-content--table__bottom container is-fluid">
                <div className='table-wrapper'>
                    <table className='table--single-line fixed'>
                        <thead>
                            <tr>
                                <th className='t-width-3'>
                                    Nazwa serwisu
                                </th>
                                <th className='t-width-5'>
                                    Liczba użytkowników
                                </th>
                                <th className='t-width-2'>
                                    Status
                                </th>
                                <th className='t-width-2'>
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                        {
                            !isReceiverGroupsEmpty &&
                            receiverGroups?.data?.content
                                .map(receiverGroup => {
                                    return (
                                        <ReceiverGroupListItem
                                            key={ receiverGroup.id }
                                            receiverGroup={ receiverGroup }
                                            _onDeleteOrUpdateSuccess={ _onDeleteOrUpdateSuccess }
                                        />
                                    );
                                })
                        }

                        </tbody>
                    </table>
                </div>

            </section>
            <section className="container is-fluid is-flex">
                <div className="is-flex justify-end pagination--list">
                    {
                        !isReceiverGroupsEmpty &&
                        <ListPagination
                            data={ receiverGroups?.data }
                            _onPrevClick={ _onPrevClick }
                            _onNextClick={ _onNextClick }
                            _onPageNumberInputValueChange={ _onPageNumberInputValueChange }
                        />
                    }
                </div>
            </section>
        </>

    );
};

export default withPagination(ReceiverGroupsList);