import React, { useCallback, useState } from "react";
import AddressesList from "./list";
import AddressesListHeader from "./header";
import _ from "lodash";

const Addresses = ({ groupId, groupName }) => {
    const [searchBarTerm, setSearchBarTerm] = useState('');

    const _onSearchBarInputChange = useCallback(
        _.debounce(input => setSearchBarTerm(input || ''), 600),
        []
    );

    return (
        <>
            <AddressesListHeader
                groupId={ groupId }
                groupName={ groupName }
                _onSearchBarInputChange={ _onSearchBarInputChange }
                searchBarTerm={ searchBarTerm }
            />
            <AddressesList
                groupId={ groupId }
                searchBarTerm={ searchBarTerm }
            />
        </>
    );
};

export default Addresses;