import React from "react";
import { ReactComponent as IconAdd } from "../../../../../../../resource/img/icons/icon_add_white.svg";

const SettingsRegulationsAddNewTrigger = () => {

    return (
        <button className='btn btn--big btn--primary header__main-cta-btn'>
            <span className="btn__icon-wrapper--left">
                <span className='btn__icon'>
                    <IconAdd/>
                </span>
                Dodaj regulamin
            </span>
        </button>
    );
};

export default SettingsRegulationsAddNewTrigger;
