import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import AddOrEditResponsibleEntityModalForm from "./create_and_edit_modal_form";
import UnderlinedHeader from "../../../../../common/header_underlined";
import ResponsibleEntitySelectedItem from "./selected_item";
import Loader from "../../../../../common/loader";
import { getManyResponsibleEntities } from "../../../../../../data/actions/services/responsible_entities";
import { ReactComponent as IconAdd } from "../../../../../../../resource/img/icons/icon_add_white.svg";
import { firstOfArray, isLoading, isLoadingError, isNullOrUndefined } from "../../../../../../core/utils/misc_utils";
import Select from "../../../../../common/select";

class ServicesCardResponsibleEntity extends Component {

    render = () => {
        const { step } = this.props;

        return (
            <section className="media mw-100">
                <div className="media-left step-count-wrap">
                    <div className="step-count">
                        <span>{ step }</span>
                    </div>
                </div>
                <div className="media-content media-content--steps">
                    <UnderlinedHeader text="Podmiot odpowiedzialny"/>
                    <p className="is-spaced--bottom-30">Określ, jaki podmiot jest odpowiedzialny za usługę lub sprawę. Jeśli nie znajdziesz go na liście, możesz utworzyć nowy.</p>

                    {
                        this.renderContent()
                    }

                </div>
            </section>
        );
    };

    renderContent = () => {
        const { responsibleEntityId, responsibleEntities, _setFormikFieldValue } = this.props;

        if (isLoadingError(responsibleEntities)) {
            // TODO
            return (
                <div>Błąd wczytywania danych.</div>
            );
        }

        if (isLoading(responsibleEntities)) {
            return (
                <Loader/>
            );
        }

        if (isNullOrUndefined(responsibleEntities) ||
            isNullOrUndefined(responsibleEntities.data)) {
            return null;
        }

        const responsibleEntitiesOption = this._generateResponsibleEntityOptions();
        const selectedResponsibleEntityOption = firstOfArray(
            responsibleEntitiesOption
                .filter(option => option.value.id === responsibleEntityId));

        const responsibleEntity =
            !isNullOrUndefined(selectedResponsibleEntityOption)
                ? selectedResponsibleEntityOption.value
                : null;

        return (
            <div className="form--vertical">
                <span className="label-name">Lista podmiotów</span>
                <div className="select-with-button-wrapper">

                    <div className="is-relative grow">
                        <Select
                            options={ responsibleEntitiesOption }
                            value={ selectedResponsibleEntityOption }
                            placeholder="- Wybierz z dostępnych podmiotów -"
                            onChange={ option => _setFormikFieldValue("responsibleEntityId", option?.value.id) }/>
                    </div>

                    <AddOrEditResponsibleEntityModalForm
                        triggerComponent={
                            <button className="btn btn--primary"
                                    onClick={ e => e.preventDefault() }>
                                <span className="btn__icon-wrapper--left">
                                    <span className="btn__icon">
                                        <IconAdd/>
                                    </span>
                                    Utwórz nowy podmiot
                                </span>
                            </button>
                        }
                        headerText="Utwórz nowy podmiot"/>
                </div>

                <ResponsibleEntitySelectedItem
                    responsibleEntity={ responsibleEntity }
                    _setFormikFieldValue={ _setFormikFieldValue }/>
            </div>
        );
    };

    componentDidMount = () => {
        const { getManyResponsibleEntities } = this.props;
        getManyResponsibleEntities();
    };

    _generateResponsibleEntityOptions = () => {
        const { responsibleEntities } = this.props;

        return _.map(responsibleEntities.data, entity => {
            return {
                label: entity.name,
                value: entity
            };
        });
    };
}

const mapStateToProps = (state) => {
    return {
        responsibleEntities: state.entities.services.responsibleEntities.getMany
    };
};

export default connect(mapStateToProps, { getManyResponsibleEntities })(ServicesCardResponsibleEntity);