import React, { Component } from "react";
import _ from "lodash";
import DayPicker, { DateUtils } from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import moment from "moment";
import "moment/locale/pl";

import { MunicipalWasteEnum } from "../../../../../../core/enums/waste_management/municipal_waste";
import { isNullOrUndefined } from "../../../../../../core/utils/misc_utils";
import { MISC_DATE_ISO_DATE_ONLY_FORMAT } from "../../../../../../core/constants";

class MunicipalWasteCalendar extends Component {

    state = {
        modifiers: {}
    };

    render = () => {
        const { selectedYear } = this.props;
        const { modifiers } = this.state;

        return (
            <>
                <section className="container is-fluid">
                    <div className="text-center">
                        <div className="header--centered">
                            <h3>Zaznacz dni wywozu na kalendarzu</h3>
                        </div>
                    </div>
                    <div className="day-picker-wrapper">
                        <DayPicker
                            numberOfMonths={ 12 }
                            canChangeMonth={ false }
                            month={ new Date(!isNullOrUndefined(selectedYear) ? selectedYear : new Date().getFullYear(), 0, 1) }
                            modifiers={ modifiers }
                            localeUtils={ MomentLocaleUtils }
                            onDayClick={ this._onDayClick }
                            locale={ "pl" }/>
                    </div>
                </section>
            </>
        );
    };

    componentDidMount = () => {
        this.setModifierState();
    };

    componentDidUpdate = (prevProps) => {
        const { receptions } = this.props;

        if (prevProps.receptions !== receptions) {
            this.setModifierState();
        }
    };

    shouldComponentUpdate = (nextProps, nextState) => {
        const { receptions, selectedYear } = this.props;
        const { modifiers } = this.state;

        if (nextProps.selectedYear !== selectedYear) {
            return true;
        }

        if (nextProps.receptions !== receptions) {
            return true;
        }

        if (nextState.modifiers !== modifiers) {
            return true;
        }

        return false;
    };

    setModifierState = () => {
        const { receptions } = this.props;

        if (!isNullOrUndefined(receptions) &&
            !isNullOrUndefined(receptions.data)) {

            this.setState(
                this.generateModifiersData(receptions.data)
            );
        }
    };

    generateModifiersData = (receptionsData) => {
        if (isNullOrUndefined(receptionsData)) {
            return {};
        }

        const modifierMap = _.mapKeys(receptionsData, "date");

        const municipalWastePublished = _
            .filter(receptionsData, reception => reception.status === MunicipalWasteEnum.reception.status.PUBLISHED)
            .map(reception => this.receptionToDate(reception));

        const municipalWasteNew = _
            .filter(receptionsData, reception => reception.status === MunicipalWasteEnum.reception.status.NEW)
            .map(reception => this.receptionToDate(reception));

        const municipalWasteRemoved = _
            .filter(receptionsData, reception => reception.status === MunicipalWasteEnum.reception.status.REMOVED)
            .map(reception => this.receptionToDate(reception));


        return {
            modifierMap,
            modifiers: {
                municipalWastePublished,
                municipalWasteNew,
                municipalWasteRemoved
            }
        };
    };

    receptionToDate = (reception) => {
        if (isNullOrUndefined(reception)) {
            return null;
        }

        return moment(reception.date, MISC_DATE_ISO_DATE_ONLY_FORMAT).toDate();
    };

    _onDayClick = (day) => {
        const { modifiers } = this.state;
        const { _onCalendarDateClicked } = this.props;

        if (isNullOrUndefined(modifiers)) {
            return;
        }

        // 1. in NEW => remove
        if (this.modifierContainsDate(modifiers.municipalWasteNew, day)) {
            _onCalendarDateClicked(moment(day)
                .format(MISC_DATE_ISO_DATE_ONLY_FORMAT), MunicipalWasteEnum.reception.status.EMPTY);
        }
        // 2. in PUBLISHED => REMOVED
        else if (this.modifierContainsDate(modifiers.municipalWastePublished, day)) {
            _onCalendarDateClicked(moment(day)
                .format(MISC_DATE_ISO_DATE_ONLY_FORMAT), MunicipalWasteEnum.reception.status.REMOVED);
        }
        // 3. in REMOVED => PUBLISHED
        else if (this.modifierContainsDate(modifiers.municipalWasteRemoved, day)) {
            _onCalendarDateClicked(moment(day)
                .format(MISC_DATE_ISO_DATE_ONLY_FORMAT), MunicipalWasteEnum.reception.status.PUBLISHED);
        }
        // 4. not in any modifier state => NEW
        else {
            _onCalendarDateClicked(moment(day)
                .format(MISC_DATE_ISO_DATE_ONLY_FORMAT), MunicipalWasteEnum.reception.status.NEW);
        }
    };

    modifierContainsDate = (modifier, day) => {
        if (isNullOrUndefined(modifier) || isNullOrUndefined(day)) {
            return false;
        }

        for (let i = 0; i < modifier.length; i++) {
            if (DateUtils.isSameDay(modifier[i], day)) {
                return true;
            }
        }

        return false;
    };
}

export default MunicipalWasteCalendar;