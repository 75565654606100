import React from "react";
import ByYearCopyForm from "./copy_forms/by_year";
import ByAreaCopyForm from "./copy_forms/by_area";
import ByBinCopyForm from "./copy_forms/by_bin.js";

const CopyForm = ({ formType, bins, areas, selectedArea, selectedYear, selectedBin }) => {
    return (
        <>
            {
                formType === CopyFormTypeEnum.BY_YEAR &&
                <ByYearCopyForm
                    areas={ areas }
                    bins={ bins }
                    selectedArea={ selectedArea }
                    selectedYear={ selectedYear }
                    selectedBin={ selectedBin }/>
            }
            {
                formType === CopyFormTypeEnum.BY_AREA &&
                <ByAreaCopyForm areas={ areas } bins={ bins } selectedYear={ selectedYear }/>
            }
            {
                formType === CopyFormTypeEnum.BY_BIN &&
                <ByBinCopyForm areas={ areas } bins={ bins } selectedYear={ selectedYear }/>
            }
        </>
    );
}
export default CopyForm;

export const CopyFormTypeEnum = Object.freeze({
    BY_YEAR: 0,
    BY_AREA: 1,
    BY_BIN: 2,
});
