import React from "react";
import { Field, Formik } from "formik";

import FormModal from "../../../../../common/modal/form_modal";
import { isEmptyString } from "../../../../../../core/utils/misc_utils";

const initialFormValues = {
    title: ""
};

const ServicesCardContentSectionTitleEditModalForm = ({ triggerComponent, headerText, currentTitle, _onSubmit }) => {

    const initialValues =
        !isEmptyString(currentTitle)
            ? { title: currentTitle }
            : initialFormValues;

    return (
        <Formik
            initialValues={ initialValues }
            render={ formikBag => (

                <FormModal
                    headerText={ headerText }
                    triggerComponent={ triggerComponent }
                    saveOrCancelButtons={ true }
                    _actionFunction={ () => {
                        return _onSubmit(formikBag.values.title);
                    } }>

                    <div className='c-modal__body modal-size-m'>
                        <Field name="title"
                               render={ ({ field }) => (
                                   <label>
                                       <span className="is-sr-only">Tytuł sekcji</span>
                                       <input placeholder="Tytuł sekcji"
                                              { ...field }/>
                                   </label>
                               ) }/>
                    </div>

                </FormModal>
            ) }
        />
    );
};

export default ServicesCardContentSectionTitleEditModalForm;