import React from "react";
import { Field } from "formik";

import UnderlinedHeader from "../../../../../common/header_underlined";
import ServicesCardBasicInfoCardTypeRadio from "./card_type_radio";
import ServicesCardBasicInfoSubcategorySelection from "./subcategory_selection";
import ServicesCardBasicInfoDescription from "./description";
import ServiceCardsEnum from "../../../../../../core/enums/services/service_cards";
import FormErrorMessage from "../../../../../common/form_error_message";

const ServicesCardBasicInfo = ({ step, cardType, _onCardTypeClicked, errors }) => {
    return (
        <section className="media mw-100">
            <div className="media-left step-count-wrap">
                <div className="step-count">
                    <span>{ step }</span>
                </div>
            </div>
            <div className="media-content media-content--steps">
                <UnderlinedHeader text={
                    <>
                        Informacje podstawowe <span className="required-accent">*</span>
                    </>
                }/>
                <p className="is-spaced--bottom-30">Wypełnij podstawowe parametry karty.</p>
                <div className="is-spaced--bottom-30">
                    <div className="form--vertical">

                        {
                            errors.basicInfo &&
                            <FormErrorMessage message={ errors.basicInfo }/>
                        }

                        <p className="is-bold is-spaced--bottom-10">
                            Typ karty
                        </p>

                        <div className="options-wrapper--horizontal">
                            <Field
                                name='type'
                                render={() => (
                                    <>
                                        <ServicesCardBasicInfoCardTypeRadio
                                            label="Sprawa urzędowa"
                                            selectedCardType={ cardType }
                                            cardType={ ServiceCardsEnum.type.ISSUE_HANDLE }
                                            _onCardTypeClicked={ _onCardTypeClicked }
                                            />

                                        <ServicesCardBasicInfoCardTypeRadio
                                            label="Inne usługi"
                                            selectedCardType={ cardType }
                                            cardType={ ServiceCardsEnum.type.SERVICE }
                                            _onCardTypeClicked={ _onCardTypeClicked }
                                        />
                                    </>
                                )}
                            />
                        </div>

                        <Field name="title"
                               render={ ({ field }) => (
                                   <label>
                                       <span className='label-name'>Tytuł karty</span>
                                       <input type="text"
                                              placeholder={ cardType === ServiceCardsEnum.type.ISSUE_HANDLE
                                                  ?
                                                  "np. Jak wyrobić dowód osobisty"
                                                  :
                                                  "np. Sprawdź stan autobusu wycieczkowego Twojego dziecka"}
                                              { ...field }/>
                                   </label>
                               ) }/>

                        <ServicesCardBasicInfoDescription
                            maxChars={ 250 }/>

                        <ServicesCardBasicInfoSubcategorySelection/>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServicesCardBasicInfo;
