import React, { Component } from "react";
import { Link } from "react-router-dom";
import "moment/locale/pl";
import moment from "moment/moment";

import { MISC_DATE_FORMAT, MISC_TIME_FORMAT, ROUTE_WASTE_NOTIFICATIONS_SENT } from "../../../../../../core/constants";
import { ReactComponent as IconBack } from "../../../../../../../resource/img/icons/icon_back_blue.svg";
import { connect } from 'react-redux';
import { getMessage } from '../../../../../../data/actions/waste_management/messages';
import { isLoading } from '../../../../../../core/utils/misc_utils';
import Loader from '../../../../../common/loader';
import MessageSummary from "../../../../../common/message_summary";

class MessagePreview extends Component {

    render = () => {
        const { message } = this.props;

        if (isLoading(message)) {
            return (
                <Loader/>
            );
        }

        return (
            <div>
                <header className='page-header page-header--with-back'>
                    <Link to={ ROUTE_WASTE_NOTIFICATIONS_SENT } className="unstyled-btn">
                        <IconBack/>
                    </Link>

                    {
                        message.data &&

                        <h1 className='title is-1'>
                            {
                                message.data.sendingStatus === "READY" &&
                                "Zaplanowana wysyłka"
                            }
                            {
                                message.data.sendingStatus === "SENT" &&
                                "Wiadomość wysłana"
                            }
                            {
                                message.data.sendingStatus === "CANCELED" &&
                                "Wiadomość anulowana"
                            }
                        </h1>
                    }
                </header>

                <main className="notifications-2">
                    <div className="content--m content--not-centered">

                        {
                            message.data &&

                            <MessageSummary
                                time={ moment(message.data.requestedRealization).format(MISC_TIME_FORMAT) }
                                date={ moment(message.data.requestedRealization).format(MISC_DATE_FORMAT) }
                                title={ message.data.title }
                                body={ message.data.body }
                                latitude={ message.data.latitude }
                                longitude={ message.data.longitude }
                                locations={ message.data.recipientAddresses }
                                uploadedFiles={ message.data.attachments }
                                useLocationFilter={ true }
                                useGeoLocation={ true }
                            />
                        }
                    </div>
                </main>
            </div>
        );
    };

    componentDidMount = () => {
        const { getMessage, match } = this.props;
        const messageId = match.params.messageId;
        getMessage(messageId);
    };

}


const mapStateToProps = (state) => {
    return {
        message: state.entities.wasteManagement.messages.get
    };
};

export default connect(mapStateToProps, { getMessage })(MessagePreview);
