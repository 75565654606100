import * as api from "../../_api";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";
import { actions } from "../../../core/constants";

export const addNewsletterSubscription = (token) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__NEWSLETTER_SUBSCRIPTION__ADD__STARTED
    });

    return api
        .waste_management
        .newsletter
        .addNewsletterSubscription(token)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__NEWSLETTER_SUBSCRIPTION__ADD__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__NEWSLETTER_SUBSCRIPTION__ADD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const deleteNewsletterSubscription = (token) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__NEWSLETTER_SUBSCRIPTION__DELETE__STARTED
    });

    return api
        .waste_management
        .newsletter
        .deleteNewsletterSubscription(token)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__NEWSLETTER_SUBSCRIPTION__DELETE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__NEWSLETTER_SUBSCRIPTION__DELETE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getNewsletterOwner = (ownerId) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__NEWSLETTER_OWNER__GET__STARTED
    });

    return api
        .waste_management
        .newsletter
        .getNewsletterOwner(ownerId)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__NEWSLETTER_OWNER__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });

        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__NEWSLETTER_OWNER__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const checkTokenStatus = (token) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__NEWSLETTER_SUBSCRIPTION__TOKEN_CHECK__STARTED
    });

    return api
        .waste_management
        .newsletter
        .checkTokenStatus(token)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__NEWSLETTER_SUBSCRIPTION__TOKEN_CHECK__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });

        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__NEWSLETTER_SUBSCRIPTION__TOKEN_CHECK__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};