import React, { Component } from "react";

import FormModal from "../../../../../../common/modal/form_modal";

class MessageHistoryDeleteForm extends Component {

    render = () => {
        const { triggerComponent } = this.props;

        return (
            <FormModal
                triggerComponent={ triggerComponent }
                deleteOrCancelButtons={ true }
                _actionFunction={ () => {
                    return this._onSubmit();
                } }>

                <div className='c-modal__body modal-size-m modal--no-header'>
                    <p className="confirmation-alert">
                        Czy na pewno chcesz usunąć zaplanowaną wiadomość?
                    </p>
                </div>

            </FormModal>
        );
    };

    _onSubmit = () => {
        const { messages, cancelMessage } = this.props;

        if (cancelMessage !== null) {
            return cancelMessage(messages.id);
        }
    }
}

export default MessageHistoryDeleteForm;

