import React, { useCallback, useEffect, useState } from "react";

import { generatePaginationQueryValues } from "../../../../../../../../../core/utils/api_utils";
import { useDispatch, useSelector } from "react-redux";
import {
    addOrRemoveLocations,
    getManyLocationsForAddressTemplate
} from "../../../../../../../../../data/actions/blisko/address_templates/locations";
import withPagination from "../../../../../../../../common/hoc/with_pagination";
import ListPagination from "../../../../../../../../common/pagination";
import AddressTemplateLocationItem from "./item";
import EmptyListPage from "../../../../../../../../common/empty_list_page";
import noLocationsImg from "../../../../../../../../../../resource/img/icons/empty_list_icons/dodaj_adresy.png";
import FormModal from "../../../../../../../../common/modal/form_modal";
import {
    ReactComponent as IconDeleteWhite
} from "../../../../../../../../../../resource/img/icons/icon_delete--white-bin.svg";
import CheckboxInput from "../../../../../../../../common/checkbox_input";

const defaultPaginationQueryValues = generatePaginationQueryValues(0, 30, ["name", "asc"]);

const AddressTemplateLocationList = ({
                                         addressTemplateId,
                                         searchBarTerm,
                                         _onPrevClick,
                                         _onNextClick,
                                         _onPageNumberInputValueChange,
                                         currentPage,
                                         setMaxPage,
                                     }) => {
    const dispatch = useDispatch();
    const [checkedLocations, setCheckedLocations] = useState([]);
    const locations = useSelector(state => state.entities?.blisko?.addressTemplates?.locations?.getMany);

    const allChecked = checkedLocations.length === locations?.data?.content?.length;
    const isLocationsValid = !!locations?.data;
    const totalPages = locations?.data?.totalPages;

    const _reloadTable = useCallback((searchBarTerm) => {
        dispatch(getManyLocationsForAddressTemplate(
            addressTemplateId,
            {
                ...defaultPaginationQueryValues,
                page: currentPage,
                query: searchBarTerm || ''
            }
        )).then(() => {
                if (totalPages !== undefined) {
                    setMaxPage(totalPages);
                }

                setCheckedLocations([]);
            }
        )
    }, [dispatch, addressTemplateId, currentPage, totalPages, setMaxPage]);

    useEffect(() => {
        _reloadTable(searchBarTerm);
        // eslint-disable-next-line
    }, [currentPage, searchBarTerm]);

    const _onLocationEditClick = (unitIdsToAdd, unitIdsToRemove) => {
        return dispatch(addOrRemoveLocations(addressTemplateId, { unitIdsToAdd, unitIdsToRemove }))
            .then(() => _reloadTable(searchBarTerm));
    }

    const _onLocationDeleteClick = (locationItem) => {
        return dispatch(addOrRemoveLocations(addressTemplateId,
            { unitIdsToRemove: locationItem.addresses.map(address => address.id) }))
            .then(() => _reloadTable(searchBarTerm));
    }

    const _onAllLocationsCheckboxChange = () => {
        if (allChecked) {
            setCheckedLocations([]);
        } else {
            const locationNames = locations.data.content
                .map(locationDataItem => {
                    return locationDataItem.name
                });
            setCheckedLocations(locationNames);
        }
    }

    const _onLocationCheckboxChange = (locationName) => {
        const locationIndex = checkedLocations.indexOf(locationName);

        if (locationIndex >= 0) {
            const newCheckedLocations = [...checkedLocations];
            newCheckedLocations.splice(locationIndex, 1);

            setCheckedLocations(newCheckedLocations)
        } else {
            setCheckedLocations(prevState => [...prevState, locationName])
        }
    }

    const _onSelectedLocationsDeleteClick = () => {
        const unitIdsToRemove = [];

        for (const location of locations.data.content) {
            const locationIndex = checkedLocations.indexOf(location.name);

            if (locationIndex >= 0) {
                unitIdsToRemove.push(...location.addresses.map(address => address.id))
            }
        }

        return dispatch(addOrRemoveLocations(addressTemplateId,
            { unitIdsToRemove }))
            .then(() => _reloadTable(searchBarTerm))
    }

    if (locations?.data?.content?.length === 0) {
        return (
            <EmptyListPage
                img={ noLocationsImg }
                imgAlt='Brak adresów'
                text={ !searchBarTerm ? 'Dodaj adresy do szablonu' : 'Brak pasujących wyników' }/>
        )
    }

    return (
        <>
            <section className="main-content--table__bottom container is-fluid">
                <div className='table-wrapper'>
                    <table className='table--single-line fixed'>
                        <thead>
                        <tr>
                            <th className='t-width-11'>
                                <CheckboxInput
                                    label="Adresy"
                                    _onInputChange={ _onAllLocationsCheckboxChange }
                                    checked={ allChecked }/>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            isLocationsValid &&
                            locations.data.content
                                .map(locationDataItem => {
                                    return (
                                        <AddressTemplateLocationItem
                                            key={ locationDataItem.name }
                                            location={ locationDataItem }
                                            _onLocationEditClick={ _onLocationEditClick }
                                            _onLocationDeleteClick={ _onLocationDeleteClick }
                                            _onLocationCheckboxChange={ _onLocationCheckboxChange }
                                            checkedLocations={ checkedLocations }
                                        />
                                    );
                                })
                        }
                        </tbody>
                    </table>
                </div>
            </section>

            <section className="container is-fluid is-flex">
                <div className="sll__pagination-wrapper">
                    {
                        checkedLocations.length > 0 &&
                        <FormModal
                            triggerComponent={
                                <div className="btn btn--danger btn--small">
                                        <span className="btn__icon-wrapper--left">
                                            <span className="btn__icon">
                                                <IconDeleteWhite/>
                                            </span>
                                            Usuń
                                        </span>
                                </div>
                            }
                            deleteOrCancelButtons={ true }
                            _actionFunction={ () => _onSelectedLocationsDeleteClick() }
                        >
                            <div className='c-modal__body modal-size-m modal--no-header'>
                                <p className="confirmation-alert">
                                    {
                                        checkedLocations.length === 1
                                            ? 'Czy chcesz usunąć wybrany adres?'
                                            : 'Czy chcesz usunąć wybrane adresy?'
                                    }
                                </p>
                            </div>
                        </FormModal>
                    }
                </div>
                <div className="is-flex justify-end pagination--list">
                    {
                        isLocationsValid &&
                        <ListPagination data={ locations.data }
                                        _onPrevClick={ _onPrevClick }
                                        _onNextClick={ _onNextClick }
                                        _onPageNumberInputValueChange={ _onPageNumberInputValueChange }/>
                    }
                </div>
            </section>
        </>
    )
};

export default withPagination(AddressTemplateLocationList);