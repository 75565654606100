export const isNullOrUndefined = (input) => {
    return !!(input === undefined || input === null);
};

export const isNullOrUndefinedOneOfMany = (...inputs) => {
    if (isEmptyArray(inputs)) {
        return true;
    }

    return inputs
        .reduce((accumulator, current) => {
            if (!isNullOrUndefined(accumulator) && accumulator) {
                return true;
            }

            return isNullOrUndefined(accumulator) || isNullOrUndefined(current);
        }, false);
};

export const isEmptyObject = (object) => {
    return isNullOrUndefined(object) || !!(Object.keys(object).length === 0 && object.constructor === Object);
};

/**
 * @deprecated just don't use it
 * @param string
 * @returns {*|boolean}
 */
export const isEmptyString = (string) => {
    return isNullOrUndefined(string) || string.length === 0;
};

export const isHtmlStringWithoutText = (string) => {
    if (isNullOrUndefined(string)) {
        return true;
    }
    const div = document.createElement("div");
    div.innerHTML = string;
    return isEmptyString(div.textContent.trim()) && isEmptyString(div.innerText.trim());
};

export const isEmptyArray = (array) => {
    return isNullOrUndefined(array) || array.length === 0;
};

export const isLoading = (input) => {
    return isNullOrUndefined(input) || isNullOrUndefined(input.meta) || isNullOrUndefined(input.meta.loading) || input.meta.loading;
};

export const isLoadingOneOfMany = (...inputs) => {
    if (isEmptyArray(inputs)) {
        return true;
    }

    return inputs
        .reduce((accumulator, current) => {
            if (!isNullOrUndefined(accumulator) && accumulator) {
                return true;
            }

            return isLoading(accumulator) || isLoading(current);
        }, false);
};

export const isLoadingError = (input) => {
    return isNullOrUndefined(input) || isNullOrUndefined(input.error) || input.error;
};

export const isLoadingErrorOneOfMany = (...inputs) => {
    if (isEmptyArray(inputs)) {
        return true;
    }

    return inputs
        .reduce((accumulator, current) => {
            if (!isNullOrUndefined(accumulator) && accumulator) {
                return true;
            }

            return isLoadingError(accumulator) || isLoadingError(current);
        }, false);
};

export const concatArrays = (arrayOfArrays) => {
    if (isNullOrUndefined(arrayOfArrays)) {
        return [];
    }

    let concatenatedArrays = [];
    for (let i = 0; i < arrayOfArrays.length; i++) {
        concatenatedArrays = concatenatedArrays.concat(arrayOfArrays[i]);
    }

    return deepFreeze(concatenatedArrays);
};

export const randomString = () => {
    return Math.random()
               .toString(36)
               .substring(2, 10);
};

export const stringContainsSearchTerm = (string, searchTerm) => {
    if (isNullOrUndefined(string) || isNullOrUndefined(searchTerm)) {
        return false;
    }

    return string.trim()
                 .toLowerCase()
                 .includes(searchTerm.trim()
                                     .toLowerCase());
};

export const stringsEqualTrimIgnoreCase = (string1, string2) => {
    if (isNullOrUndefined(string1) || isNullOrUndefined(string2)) {
        return false;
    }

    return string1.trim()
                  .toLowerCase() === string2.trim()
                                            .toLowerCase();
};

export const deepFreeze = (object) => {
    if (isNullOrUndefined(object)) {
        return object;
    }

    Object.freeze(object);

    Object
        .getOwnPropertyNames(object)
        .forEach((prop) => {
            if (object.hasOwnProperty(prop)
                && object[prop] !== null
                && (typeof object[prop] === "object" || typeof object[prop] === "function")
                && !Object.isFrozen(object[prop])) {
                deepFreeze(object[prop]);
            }
        });

    return object;
};

export const firstOfArray = (array) => {
    if (isEmptyArray(array)) {
        return null;
    }

    return array[0];
};

export const arrayValuesAsString = (array, separator = ", ") => {
    if (isNullOrUndefined(array) || isEmptyArray(array)) {
        return "";
    }

    let result = "";
    for (let i = 0; i < array.length; i++) {
        if (i === array.length - 1) {
            result += array[i];
        } else {
            result += array[i] + separator;
        }
    }

    return result;
};

export const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
})};

export const filesToBase64 = (files) => {
    return Promise.all(files.map(file => fileToBase64(file)));
};

export const containsWithId = (array, id) => {
    if (isNullOrUndefinedOneOfMany(array, id)) {
        return false;
    }

    for (let i = 0; i < array.length; i++) {
        if (array[i].id === id) {
            return true;
        }
    }

    return false;
};

export const getWithId = (array, id) => {
    if (isNullOrUndefinedOneOfMany(array, id)) {
        return null;
    }

    for (let i = 0; i < array.length; i++) {
        if (array[i].id === id) {
            return array[i];
        }
    }

    return null;
};

export const objectValueIsEmptyString = (object) => {
    if (isNullOrUndefined(object)) {
        return false;
    }

    for (let key in object) {
        if (object.hasOwnProperty(key) && isEmptyString(object[key])) {
            return true;
        }
    }
    return false;
};

export const invoke = (func) => {
    func && func();
};
