import React from "react";

const FormErrorMessage = ({ message }) => {

    return (
        <div className="form-message form-message--error">
            <p className="form-message__text">
                {
                    message
                }
            </p>
        </div>
    );
};

export default FormErrorMessage;