import React, { Component } from "react";
import { connect } from "react-redux";

import FormModal from "../../../../common/modal/form_modal";
import { invoke, isNullOrUndefined } from "../../../../../core/utils/misc_utils";
import { deleteEmployee } from "../../../../../data/actions/common/employees";

class EmployeesConfirmationModalForm extends Component {

    render = () => {
        const { employee, triggerComponent, confirmationText, triggerText, deleteOrCancelButtons, saveOrCancelButtons, saveButtonText } = this.props;
        return (
            <FormModal
                triggerComponent={ triggerComponent }
                triggerText={ triggerText }
                deleteOrCancelButtons={ deleteOrCancelButtons }
                saveOrCancelButtons={ saveOrCancelButtons }
                saveButtonText={ saveButtonText }
                _actionFunction={ () => {
                    return this._onSubmit();
                } }>

                <div className='c-modal__body modal-size-m modal--no-header'>
                    <p>
                        { confirmationText } <span className="is-bold">{ employee.name } { employee.surname }</span>?
                    </p>
                </div>

            </FormModal>
        );
    };

    _onSubmit = () => {
        const { employee, _onSubmitCallback, _onSubmitAction } = this.props;

        if (!isNullOrUndefined(employee)) {
            return _onSubmitAction(employee.id)
                .then(() => invoke(_onSubmitCallback));
        } else {
            invoke(_onSubmitCallback);
        }
    };

}

export default connect(null, { deleteEmployee })(EmployeesConfirmationModalForm);


