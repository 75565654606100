import webClient from "../../../core/web_client";
import { API_BASE_AKUN_EKOSTRAZNIK_PATH } from "../../../core/constants";

const PATH = `${ API_BASE_AKUN_EKOSTRAZNIK_PATH }/inspection-companies`;

export const getManyInspectionCompanies = () => {
    return webClient
        .get(`${ PATH }`);
};

export const addInspectionCompany = (data) => {
    return webClient
        .post(`${ PATH }`, data);
};

export const updateInspectionCompany = (id, data) => {
    return webClient
        .patch(`${ PATH }/${ id }`, data);
};

export const renameInspectionCompany = (id, data) => {
    return webClient
        .put(`${ PATH }/${ id }`, data);
};