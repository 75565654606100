import { actions } from "../../../core/constants";
import * as api from "../../_api";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const getManyReceiverGroups = (queryParams) => dispatch => {
    dispatch({
        type: actions.blisko.API__RECEIVER_GROUPS__GET_MANY__STARTED
    });

    return api
        .blisko
        .receiverGroups
        .getMany(queryParams)
        .then(response => {
            dispatch({
                type: actions.blisko.API__RECEIVER_GROUPS__GET_MANY__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });

            return response?.data?.content;
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__RECEIVER_GROUPS__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getReceiverGroup = (id) => dispatch => {
    dispatch({
        type: actions.blisko.API__RECEIVER_GROUPS__GET__STARTED
    });

    return api
        .blisko
        .receiverGroups
        .getReceiverGroup(id)
        .then(response => {
            dispatch({
                type: actions.blisko.API__RECEIVER_GROUPS__GET__SUCCESS,
                meta: {},
                payload: response.data,
                error: false
            });
            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__RECEIVER_GROUPS__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const addReceiverGroup = (data) => dispatch => {
    dispatch({
        type: actions.blisko.API__RECEIVER_GROUPS__ADD_AND_PUBLISH__STARTED
    });

    return api
        .blisko
        .receiverGroups
        .addReceiverGroup(data)
        .then(response => {
            dispatch({
                type: actions.blisko.API__RECEIVER_GROUPS__ADD_AND_PUBLISH__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__RECEIVER_GROUPS__ADD_AND_PUBLISH__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });

            return error.response;
        });
};

export const updateReceiverGroup = (id, data) => dispatch => {
    dispatch({
        type: actions.blisko.API__RECEIVER_GROUPS__UPDATE__STARTED
    });

    return api
        .blisko
        .receiverGroups
        .updateReceiverGroup(id, data)
        .then(response => {
            dispatch({
                type: actions.blisko.API__RECEIVER_GROUPS__UPDATE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__RECEIVER_GROUPS__UPDATE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });

            return error.response;
        });
};

export const hideReceiverGroup = (id) => dispatch => {
    dispatch({
        type: actions.blisko.API__RECEIVER_GROUPS__HIDE__STARTED
    });

    return api
        .blisko
        .receiverGroups
        .hideReceiverGroup(id)
        .then(response => {
            dispatch({
                type: actions.blisko.API__RECEIVER_GROUPS__HIDE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__RECEIVER_GROUPS__HIDE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const activeReceiverGroup = (id) => dispatch => {
    dispatch({
        type: actions.blisko.API__RECEIVER_GROUPS__ACTIVE__STARTED
    });

    return api
        .blisko
        .receiverGroups
        .activeReceiverGroup(id)
        .then(response => {
            dispatch({
                type: actions.blisko.API__RECEIVER_GROUPS__ACTIVE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__RECEIVER_GROUPS__ACTIVE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const deleteReceiverGroup = (id) => dispatch => {
    dispatch({
        type: actions.blisko.API__RECEIVER_GROUPS__DELETE__STARTED
    });

    return api
        .blisko
        .receiverGroups
        .removeReceiverGroups({ ids: [ id ] })
        .then(response => {
            dispatch({
                type: actions.blisko.API__RECEIVER_GROUPS__DELETE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__RECEIVER_GROUPS__DELETE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
            return error.response;
        });
};
