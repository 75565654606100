import React from "react";
import { ReactComponent as IconCancelSmall } from "../../../../../../../../../../../resource/img/icons/icon_cancel_small.svg";
import { isNullOrUndefined } from "../../../../../../../../../../core/utils/misc_utils";

const BulkyWasteCreateAndEditModalFormContentDateSelectedItem = ({ date, readOnly, _onSelectedDayClick }) => {

    return (
        <div className="selected-dates__item">
            <p className="selected-dates__date">
                {
                    date
                }
            </p>
            <span className="unstyled-btn">
                {
                    !isNullOrUndefined(readOnly) &&
                    !readOnly &&
                    <IconCancelSmall
                        onClick={ () => _onSelectedDayClick(date) }/>
                }
            </span>
        </div>
    );
};

export default BulkyWasteCreateAndEditModalFormContentDateSelectedItem;