import * as api from "../../../_api";

import { actions } from "../../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../../core/utils/api_utils";

export const getManyReports = (queryParams) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__REPORTS__GET_MANY__STARTED
    });

    return api
        .waste_management
        .reports
        .getManyReports(queryParams)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__REPORTS__GET_MANY__SUCCESS,
                meta: {},
                payload: response.data,
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__REPORTS__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const getReport = (id) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__REPORTS__GET__STARTED
    });

    return api
        .waste_management
        .reports
        .getReport(id)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__REPORTS__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__REPORTS__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const updateReportStatus = (id, statusCode) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__REPORTS__UPDATE_STATUS__STARTED
    });

    return api
        .waste_management
        .reports
        .updateReportStatus(id, statusCode)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__REPORTS__UPDATE_STATUS__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__REPORTS__UPDATE_STATUS__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const sendStatusChangeConfirmation = (id) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__REPORTS__SEND_STATUS_CHANGE_CONFIRMATION__STARTED
    });

    return api
        .waste_management
        .reports
        .sendStatusChangeConfirmation(id)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__REPORTS__SEND_STATUS_CHANGE_CONFIRMATION__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__REPORTS__SEND_STATUS_CHANGE_CONFIRMATION__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const addReport = (type, data) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__REPORTS__ADD__STARTED
    });

    return api
        .waste_management
        .reports
        .addReport(type, data)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__REPORTS__ADD__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__REPORTS__ADD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};