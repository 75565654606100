import React from 'react';
import { createPortal } from 'react-dom';
import FocusTrap from 'focus-trap-react';

import Modal from './modal';
import { invoke, isNullOrUndefined } from '../../../core/utils/misc_utils';
import { ReactComponent as IconCancel } from '../../../../resource/img/icons/icon_cancel.svg';
import { ButtonLoader } from '../loader';

class FormModal extends Modal {

    render = () => {
        const {
            children, triggerText, triggerClass, triggerDisabled, triggerComponent, triggerWrapperClass, ariaLabel, overflow, isLoading, role,
            headerText, saveButtonText, cancelButtonText, dangerButtonText, withBgHeader, saveOrCancelButtons, okButton, closeButton, multiStepSaveOrCancelButtons, deleteOrCancelButtons, dangerOrCancelButtons, saveOrStayOrCancelButtons, saveOrAdditionalSaveOrCancelButtons,
            submitDisabled = false, _onOpenCallback, _actionFunction, _additionalActionFunction, _actionFunctionWithFormClear, _actionFunctionMultistep, _cancelFunction, noTrigger, defaultOpen, forceOpen, _onClickAway
        } = this.props;
        const isOpen = forceOpen || (this.state.isOpen ?? defaultOpen ?? false);

        return (
            <>
                {
                    !noTrigger &&
                    <ModalTrigger
                        _onOpen={ () => this._onOpen(_onOpenCallback) }
                        buttonRef={ n => this.openButtonNode = n }
                        triggerText={ triggerText }
                        triggerClass={ triggerClass }
                        triggerWrapperClass={ triggerWrapperClass }
                        triggerComponent={ triggerComponent }
                        triggerDisabled={ !isNullOrUndefined(triggerDisabled) ? triggerDisabled : false }/>
                }

                {
                    isOpen &&
                    <ModalContent
                        _onClose={ this._onClose }
                        _onKeyDown={ this._onKeyDown }
                        _onClickAway={ _onClickAway }
                        modalRef={ n => this.modalNode = n }
                        buttonRef={ n => this.closeButtonNode = n }
                        overflow={ overflow }
                        isLoading={ isLoading }
                        role={ role }
                        content={ children }
                        ariaLabel={ ariaLabel }
                        headerText={ headerText }
                        saveButtonText={ saveButtonText }
                        cancelButtonText={ cancelButtonText }
                        dangerButtonText={ dangerButtonText }
                        withBgHeader={ withBgHeader }
                        okButton={ okButton }
                        closeButton={ closeButton }
                        multiStepSaveOrCancelButtons={ multiStepSaveOrCancelButtons }
                        saveOrCancelButtons={ saveOrCancelButtons }
                        deleteOrCancelButtons={ deleteOrCancelButtons }
                        dangerOrCancelButtons={ dangerOrCancelButtons }
                        saveOrStayOrCancelButtons={ saveOrStayOrCancelButtons }
                        saveOrAdditionalSaveOrCancelButtons={ saveOrAdditionalSaveOrCancelButtons }
                        submitDisabled={ submitDisabled }
                        _actionFunction={ _actionFunction }
                        _additionalActionFunction={ _additionalActionFunction }
                        _actionFunctionWithFormClear={ _actionFunctionWithFormClear }
                        _actionFunctionMultistep={ _actionFunctionMultistep }
                        _cancelFunction={ _cancelFunction }/>
                }

            </>
        );
    };

    componentDidMount = () => {
        const { defaultOpen } = this.props;

        if (defaultOpen) {
            this._onOpen();
        }
    };

    _onOpen = (_onOpenCallback) => {
        this.setState({ isOpen: true }, () => {

            if (!isNullOrUndefined(this.closeButtonNode)) {
                this.closeButtonNode.focus();
            }

            if (!isNullOrUndefined(_onOpenCallback)) {
                _onOpenCallback();
            }
        });

        this._toggleScrollLock(true);
    };

    _onClose = (_actionFunction) => {
        if (!isNullOrUndefined(_actionFunction)) {

            return _actionFunction()
                .then(() => {
                    this._closeModal();
                }, () => {
                    // empty
                });
        } else {
            this._closeModal();
        }
    };

    _closeModal = () => {
        this._toggleScrollLock(false);
        if (!isNullOrUndefined(this.openButtonNode)) {
            this.openButtonNode.focus();
        }

        this.setState({ isOpen: false });

    };
}

export const ModalTrigger = ({ triggerComponent, triggerText, triggerClass, triggerWrapperClass, triggerDisabled, buttonRef, _onOpen }) => {

    if (!isNullOrUndefined(triggerComponent)) {
        return (
            <span
                onClick={ () => {
                    if (!triggerDisabled) {
                        _onOpen();
                    }
                } }
                ref={ buttonRef }
                className={ triggerWrapperClass }>

                {
                    triggerComponent
                }

            </span>
        );
    }

    return (
        <button
            onClick={ () => {
                if (!triggerDisabled) {
                    _onOpen();
                }
            } }
            className={ triggerClass }
            ref={ buttonRef }
            disabled={ triggerDisabled }>
            {
                triggerText
            }
        </button>
    );
};


const ModalContent = ({
                          _onClose, _onClickAway, _onKeyDown, content, modalRef, buttonRef, ariaLabel, role = 'dialog', overflow = false, isLoading,
                          headerText, withBgHeader, okButton, closeButton, multiStepSaveOrCancelButtons, saveOrCancelButtons, deleteOrCancelButtons, dangerOrCancelButtons, saveOrStayOrCancelButtons, saveOrAdditionalSaveOrCancelButtons,
                          saveButtonText, cancelButtonText, dangerButtonText, additionalSaveButton, submitDisabled,
                          _actionFunction, _additionalActionFunction, _actionFunctionWithFormClear, _actionFunctionMultistep, _cancelFunction
                      }) => {
    return createPortal(
        <FocusTrap>
            <aside
                onKeyDown={ _onKeyDown }
                onClick={ _onClickAway }
                className='c-modal-cover'
                aria-modal='true'
                aria-label={ ariaLabel }
                role={ role }
                tabIndex='-1'>

                <section className={ `c-modal ${ overflow ? 'c-modal--overflow-visible' : '' }` } ref={ modalRef }>
                    <div className="c-modal__close_wrapper">
                        <button className='unstyled-btn c-modal__close' aria-label='Zamknij' onClick={ () => _onClose(_cancelFunction) } ref={ buttonRef }>
                            <span className="is-sr-only">Zamknij</span>
                            <IconCancel/>
                        </button>
                    </div>
                    <header className={ `c-modal__header ${ withBgHeader ? 'c-modal__header--with-bg' : '' }` }>
                        <h2 className='c-modal__title'>
                            { headerText }
                        </h2>
                    </header>

                    {
                        content
                    }

                    {
                        closeButton &&
                        <footer className='c-modal__footer c-modal__footer--right'>
                            <button className="btn btn--small btn--primary" onClick={ () => _onClose(_cancelFunction) }>
                                Zamknij
                            </button>
                        </footer>
                    }

                    {
                        okButton &&
                        <footer className='c-modal__footer c-modal__footer--right'>
                            <button className="btn btn--small btn--primary" onClick={ () => _onClose(_cancelFunction) }>
                                OK
                            </button>
                        </footer>
                    }

                    {
                        deleteOrCancelButtons &&
                        <footer className='c-modal__footer c-modal__footer--right'>
                            <button className="btn btn--small btn--outlined" onClick={ () => _onClose(_cancelFunction) }>
                                Anuluj
                            </button>
                            <button className="btn btn--danger btn--small" onClick={ () => _onClose(_actionFunction) }>
                                { isLoading ? <ButtonLoader/> : '' }Usuń
                            </button>
                        </footer>
                    }

                    {
                        dangerOrCancelButtons &&
                        <footer className='c-modal__footer c-modal__footer--right'>
                            <button className="btn btn--small btn--outlined" onClick={ () => _onClose(_cancelFunction) }>
                                Anuluj
                            </button>
                            <button className="btn btn--danger btn--small" onClick={ () => _onClose(_actionFunction) }>
                                { isLoading ? <ButtonLoader/> : '' } { dangerButtonText ? dangerButtonText : "Zatwierdź" }
                            </button>
                        </footer>
                    }

                    {
                        saveOrCancelButtons &&
                        <footer className='c-modal__footer c-modal__footer--right'>
                            <button className="btn btn--small btn--outlined"
                                    onClick={ () => _onClose(_cancelFunction) }>
                                { cancelButtonText ? cancelButtonText : 'Anuluj' }
                            </button>
                            <button type="submit" className="btn btn--primary btn--small" disabled={ submitDisabled || isLoading }
                                    onClick={ () => _onClose(_actionFunction) }>{ isLoading ? <ButtonLoader/> : '' }
                                { saveButtonText ? saveButtonText : 'Zapisz' }
                            </button>
                        </footer>
                    }

                    {
                        saveOrStayOrCancelButtons &&
                        <footer className='c-modal__footer c-modal__footer--apart'>
                            <button className="btn btn--small btn--outlined"
                                    onClick={ () => _onClose(_cancelFunction) }>
                                { cancelButtonText ? cancelButtonText : 'Anuluj' }
                            </button>
                            <div>
                                <button className="btn is-hidden-mobile" disabled={ submitDisabled || isLoading } onClick={ () => invoke(_actionFunctionWithFormClear) }>
                                    Zapisz i dodaj kolejny
                                </button>
                                <button className="btn btn--primary btn--small" disabled={ submitDisabled || isLoading }
                                        onClick={ () => _onClose(_actionFunction) }>{ isLoading ? <ButtonLoader/> : '' }{ saveButtonText ? saveButtonText : 'Zapisz' }
                                </button>
                            </div>
                        </footer>
                    }

                    {
                        saveOrAdditionalSaveOrCancelButtons &&
                        <footer className='c-modal__footer c-modal__footer--apart'>
                            <button className="btn btn--small btn--outlined"
                                    onClick={ () => _onClose(_cancelFunction) }>
                                { cancelButtonText ? cancelButtonText : 'Anuluj' }
                            </button>
                            <div>
                                <button className="btn" disabled={ submitDisabled || isLoading }
                                        onClick={ () => _onClose(_actionFunction) }>{ isLoading ? <ButtonLoader/> : '' }
                                    { saveButtonText ? saveButtonText : 'Zapisz' }
                                </button>
                                <button className="btn btn--primary btn--small" disabled={ submitDisabled || isLoading }
                                        onClick={ () => _onClose(_additionalActionFunction) }>{ isLoading ? <ButtonLoader/> : '' }
                                    { additionalSaveButton ? additionalSaveButton : 'Zapisz i opublikuj' }
                                </button>
                            </div>
                        </footer>
                    }

                    {
                        multiStepSaveOrCancelButtons &&
                        <footer className='c-modal__footer c-modal__footer--right'>
                            <button className="btn btn--small btn--outlined"
                                    onClick={ () => _onClose(_cancelFunction) }>
                                { cancelButtonText ? cancelButtonText : 'Anuluj' }
                            </button>
                            <button type="submit" className="btn btn--primary btn--small" disabled={ submitDisabled || isLoading }
                                    onClick={ () => invoke(_actionFunctionMultistep) }>
                                { saveButtonText ? saveButtonText : 'Zapisz' }
                            </button>
                        </footer>
                    }

                </section>
            </aside>
        </FocusTrap>,
        document.body
    );
};

export default FormModal;

