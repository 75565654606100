import React, { Component } from "react";

class Loader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            displayLoader: false,
        };

        this.timer = setTimeout(this.enableLoader, 250);
    }

    render = () => {
        const { displayLoader } = this.state;

        if (!displayLoader) {
            return this.renderEmpty();
        }

        return this.renderSpinner();
    };

    renderEmpty = () => {
        return (
            <div className="loader--custom">
                <div className="spinner"/>
                <p>&nbsp;</p>
            </div>
        );
    };

    renderSpinner = () => {
        return (
            <div className="loader--custom">
                <div className="spinner">
                    <div className="double-bounce1"/>
                    <div className="double-bounce2"/>
                </div>
                <p>
                    Poczekaj chwilę...
                </p>
            </div>
        );
    };

    componentWillUnmount = () => {
        clearTimeout(this.timer);
    };

    enableLoader = () => {
        this.setState({ displayLoader: true });
    };
}

export const ButtonLoader = () => {
    return (
        <div className="button-loader">
            <div className="spinner">
                <div className="double-bounce1"/>
                <div className="double-bounce2"/>
            </div>
        </div>
    );
};

export default Loader;
