import React from "react";

const RegulationsDeleteFormContent = () => {

    return (
        <div className='c-modal__body modal-size-m modal--no-header'>
            <p className="confirmation-alert">
                Czy na pewno chcesz usunąć ten regulamin?
            </p>
        </div>
    );
};

export default RegulationsDeleteFormContent;