import React, { Component } from "react";
import { connect } from "react-redux";
import LogsHeader from "../common/header";
import { UserRecordsEnum } from "../../../../../core/enums/common/logs";
import UserSubscriptionsItem from "./item";
import Loader from "../../../../common/loader";
import { getUserSubscriptions, getUserSubscriptionsExport } from "../../../../../data/actions/common/logs";
import { isNullOrUndefined } from "../../../../../core/utils/misc_utils";


class UserSubscriptions extends Component {

    render = () => {
        const { userSubscriptions } = this.props;

        if (userSubscriptions.meta.loading) {
            return (
                <>
                    <LogsHeader tab={ UserRecordsEnum.tab.USER_SUBSCRIPTIONS }/>
                    <Loader/>
                </>
            );
        }

        return (
            <div className="side-spacing">
                <LogsHeader tab={ UserRecordsEnum.tab.USER_SUBSCRIPTIONS }/>

                <div className="row row--options justify-end">
                    <button onClick={ this.userSubscriptionsExportDownloadTrigger } className="btn btn--primary btn--medium">
                        Pobierz raport
                    </button>
                </div>

                <div className="options-table">
                    <div className='table-wrapper'>
                        <table className='table--single-line fixed'>
                            <thead>
                            <tr>
                                <th className='t-width-2'>Użytkownik</th>
                                <th className='t-width-3'>Adres użytkownika</th>
                                <th className='t-width-2'>Nadawca</th>
                                <th className='t-width-2'>Akcja</th>
                                <th className='t-width-1'>Data akcji</th>
                                <th className='t-width-2'>Rodzaj powiadomienia</th>
                            </tr>
                            </thead>
                            <tbody>

                            {
                                this.renderUserSubscriptionsList()
                            }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        );

    };

    componentDidMount = () => {
        this.props.getUserSubscriptions();
    };

    userSubscriptionsExportDownloadTrigger = () => {
        this.props.getUserSubscriptionsExport();
    };

    renderUserSubscriptionsList = () => {
        const { userSubscriptions } = this.props;

        if(isNullOrUndefined(userSubscriptions.data)){
            return
        }

        const userSubscriptionsToRender = userSubscriptions.data
                                                 .map((userSubscription, index) => {
                                                         return <UserSubscriptionsItem key={ index }
                                                                                       userSubscription={ userSubscription }
                                                         />;
                                                     }
                                                 );

        return userSubscriptionsToRender;
    };
}

const mapStateToProps = (state) => {
    return {
        userSubscriptions: state.entities.common.userSubscriptions.get,
        userSubscriptionsExport: state.entities.common.userSubscriptions.getExport
    };
};


export default connect(mapStateToProps, { getUserSubscriptions, getUserSubscriptionsExport })(UserSubscriptions);
