import React from "react";
import { Link } from "react-router-dom";

import WasteManagementSidebarVariant from "./variants/waste_management";
import ServicesSidebarVariant from "./variants/services";
import EkostraznikSidebarVariant from "./variants/ekostraznik";
import iconDoubleChevrons from "../../../../../resource/img/icons/sidebar/icon_double_chevrons.svg";
import { SidebarEnum } from "../../../../core/enums/common/sidebar";
import { PermissionEnum } from "../../../../core/enums/common/permissions";
import BliskoSidebarVariant from "./variants/blisko";

const Sidebar = ({
                     sidebarVariant, sidebarCollapsed, sidebarVisible, activeSidebarItem, submenuExpanded, activeSubmenuItem,
                     _onModuleStartPageClicked, _onSidebarCollapseClicked, _onSidebarItemSelected, _onSidebarItemWithSubmenuSelected, _onSubmenuItemSelected
                 }) => {

    const selectedModuleData = generateSelectedModuleData(sidebarVariant);

    return (
        <aside
            className={ `sidebar  ${ sidebarCollapsed ? "sidebar--collapsed" : "" } ${ sidebarVisible ? "sidebar--visible" : "" }` }>
            <nav className="sidebar-nav-wrapper">
                <Link to={ selectedModuleData.link }
                      className="is-v-centered row module-name"
                      onClick={ _onModuleStartPageClicked }>
                    <span className="title is-4">
                        {
                            selectedModuleData.name
                        }
                    </span>
                </Link>
                <ul className="sidebar__item-container">

                    {
                        sidebarVariant === PermissionEnum.module.WASTE_MANAGEMENT.code &&

                        <WasteManagementSidebarVariant
                            activeSidebarItem={ activeSidebarItem }
                            submenuExpanded={ submenuExpanded }
                            activeSubmenuItem={ activeSubmenuItem }
                            _onSidebarItemSelected={ _onSidebarItemSelected }
                            _onSubmenuItemSelected={ _onSubmenuItemSelected }
                            _onSidebarItemWithSubmenuSelected={ _onSidebarItemWithSubmenuSelected }/>
                    }

                    {
                        sidebarVariant === PermissionEnum.module.SERVICES.code &&

                        <ServicesSidebarVariant
                            activeSidebarItem={ activeSidebarItem }
                            _onSidebarItemSelected={ _onSidebarItemSelected }
                            _onSubmenuItemSelected={ _onSubmenuItemSelected }/>
                    }

                    {
                        sidebarVariant === PermissionEnum.module.EKOSTRAZNIK.code &&

                        <EkostraznikSidebarVariant
                            activeSidebarItem={ activeSidebarItem }
                            submenuExpanded={ submenuExpanded }
                            activeSubmenuItem={ activeSubmenuItem }
                            _onSidebarItemSelected={ _onSidebarItemSelected }
                            _onSubmenuItemSelected={ _onSubmenuItemSelected }
                            _onSidebarItemWithSubmenuSelected={ _onSidebarItemWithSubmenuSelected }/>
                    }

                    {
                        sidebarVariant === PermissionEnum.module.BLISKO.code &&

                        <BliskoSidebarVariant
                            activeSidebarItem={ activeSidebarItem }
                            submenuExpanded={ submenuExpanded }
                            activeSubmenuItem={ activeSubmenuItem }
                            _onSidebarItemSelected={ _onSidebarItemSelected }
                            _onSubmenuItemSelected={ _onSubmenuItemSelected }
                            _onSidebarItemWithSubmenuSelected={ _onSidebarItemWithSubmenuSelected }/>
                    }

                </ul>
            </nav>
            <div className="sidebar__control"
                 onClick={ () => _onSidebarCollapseClicked() }>
                <button className="unstyled-btn">
                    <img src={ iconDoubleChevrons } alt="zwiń/rozwiń menu" className="sidebar-expand-icon"/>
                </button>
            </div>
        </aside>
    );
};

const generateSelectedModuleData = (sidebarVariant) => {
    switch (sidebarVariant) {
        case PermissionEnum.module.WASTE_MANAGEMENT.code:
            return {
                name: SidebarEnum.WASTE_MANAGEMENT.name,
                link: SidebarEnum.WASTE_MANAGEMENT.link
            };

        case PermissionEnum.module.SERVICES.code:
            return {
                name: SidebarEnum.SERVICES.name,
                link: SidebarEnum.SERVICES.link
            };
        case PermissionEnum.module.EKOSTRAZNIK.code:
            return {
                name: SidebarEnum.EKOSTRAZNIK.name,
                link: SidebarEnum.EKOSTRAZNIK.link
            };

        case PermissionEnum.module.BLISKO.code:
            return {
                name: SidebarEnum.BLISKO.name,
                link: SidebarEnum.BLISKO.link
            };

        default:
            return {};
    }
};

export default Sidebar;
