import webClient, { webClientFileDownload } from "../../../core/web_client";
import { API_BASE_AKUN_EKOSTRAZNIK_PATH } from "../../../core/constants";
import { buildQueryString } from "../../../core/utils/api_utils";

const PATH = `${ API_BASE_AKUN_EKOSTRAZNIK_PATH }/inspection-history`;

export const getManyInspectionLocations = (queryParams) => {
    return webClient
        .get(`${ PATH }${buildQueryString(queryParams)}`);
};

export const getLocationInspection = (id, queryParams) => {
    return webClient
        .get(`${ PATH }/locations/${id}${buildQueryString(queryParams)}`);
};

export const getGroupInspection = (id, queryParams) => {
    return webClient
        .get(`${ PATH }/groups/${id}${buildQueryString(queryParams)}`);
};

export const generateInspectionDocument = (body) => {
    return webClientFileDownload
        .patch(`${ PATH }`, body);
};