import React from "react";
import FormModal from "../../../../../common/modal/form_modal";
import { ReactComponent as IconDelete } from "../../../../../../../resource/img/icons/icon_delete.svg";
import { deleteAddressesFromGroup } from "../../../../../../data/actions/ekostraznik/address_group_addresses";
import { useDispatch, useSelector } from "react-redux";

const DeleteAddressForm = ({ groupId, addressId, _onSuccess }) => {
    const dispatch = useDispatch();
    const deleteStatus = useSelector(state => state.entities?.ekostraznik?.addressGroups?.addresses?.update);

    return (
        <FormModal
            triggerComponent={
                <div className='unstyled-btn' title='Usuń'>
                    <IconDelete/>
                </div>
            }
            deleteOrCancelButtons={ true }
            _actionFunction={ () =>
                dispatch(deleteAddressesFromGroup(groupId, [addressId]))
                    .then(async () => _onSuccess())
            }
            isLoading={ deleteStatus?.meta?.loading }
        >

            <div className='c-modal__body modal-size-m modal--no-header'>
                <p className="confirmation-alert">
                    Czy na pewno chcesz usunąć wybrany adres?
                    <br/>
                    Usunięcie wpłynie na dane wyświetlane w pozostałych zakładkach.
                </p>
            </div>

        </FormModal>
    );
};

export default DeleteAddressForm;