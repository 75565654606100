import React, { Component } from "react";
import { Form } from "formik";

class CreateAccountModalFormContent extends Component {

    render = () => {
        const { renderAccountFields } = this.props;

        return (
            <div className="c-modal__body modal-size-m">
                <Form className="form--vertical form--with-legend">

                    {
                        renderAccountFields(false, true)
                    }

                </Form>
            </div>
        );
    };

    componentDidMount = () => {
        const { _resetForm } = this.props;
        _resetForm();
    };
}

export default CreateAccountModalFormContent;
