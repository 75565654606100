import { actions } from "../../../core/constants";
import * as api from "../../_api";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const getSenderDetails = () => dispatch => {
    dispatch({
        type: actions.blisko.API__SENDER_DETAILS__GET__STARTED
    });

    return api
        .blisko
        .senderDetails
        .get()
        .then(response => {
            dispatch({
                type: actions.blisko.API__SENDER_DETAILS__GET__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__SENDER_DETAILS__GET__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const updateSenderDetails = (data) => dispatch => {
    dispatch({
        type: actions.blisko.API__SENDER_DETAILS__UPDATE__STARTED
    });

    return api
        .blisko
        .senderDetails
        .update(data)
        .then(response => {
            dispatch({
                type: actions.blisko.API__SENDER_DETAILS__UPDATE__STARTED,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
            return response;
        })
        .catch(error => {
            dispatch({
                type: actions.blisko.API__SENDER_DETAILS__UPDATE__STARTED,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });

            return error?.response;
        });
};
