import React from "react";

const Checkbox = ({ name, ariaLabel, checked, _onInputChange }) => {
    return (
        <div className="custom-input">
            <input
                className="custom-input__input"
                type="checkbox"
                name={ name }
                aria-label={ ariaLabel }
                onChange={ _onInputChange }
                checked={ checked }/>
            <span className="custom-input__new-input"/>
        </div>
    );
};

export default Checkbox;