import _ from "lodash";

import * as api from "../../_api";
import { actions } from "../../../core/constants";
import { extractErrorData, extractResponseData } from "../../../core/utils/api_utils";

export const getManyBins = () => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__BINS__GET_MANY__STARTED
    });

    return api
        .waste_management
        .bins
        .getManyBins()
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__BINS__GET_MANY__SUCCESS,
                meta: {},
                payload: _.mapKeys(extractResponseData(response), "id"),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__BINS__GET_MANY__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const addBin = (name) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__BINS__ADD__STARTED
    });

    return api
        .waste_management
        .bins
        .addBin(name)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__BINS__ADD__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__BINS__ADD__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};

export const deleteBin = (id) => dispatch => {
    dispatch({
        type: actions.wasteManagement.API__BINS__DELETE__STARTED
    });

    return api
        .waste_management
        .bins
        .deleteBin(id)
        .then(response => {
            dispatch({
                type: actions.wasteManagement.API__BINS__DELETE__SUCCESS,
                meta: {},
                payload: extractResponseData(response),
                error: false
            });
        })
        .catch(error => {
            dispatch({
                type: actions.wasteManagement.API__BINS__DELETE__FAIL,
                meta: extractErrorData(error),
                payload: null,
                error: true
            });
        });
};