import React from "react";
import { createPortal } from "react-dom";
import FocusTrap from "focus-trap-react";

import Modal from "./modal";
import { ReactComponent as IconCancel } from "../../../../resource/img/icons/icon_cancel.svg";
import { isNullOrUndefined } from "../../../core/utils/misc_utils";

class ImageModal extends Modal {

    render = () => {
        const { children, triggerImage, triggerDisabled, ariaLabel, role, _onClickAway } = this.props;
        const { isOpen } = this.state;

        return (
            <>
                <ModalTrigger
                    _onOpen={ this._onOpen }
                    buttonRef={ n => this.openButtonNode = n }
                    image={ triggerImage }
                    triggerDisabled={ !isNullOrUndefined(triggerDisabled) ? triggerDisabled : false }/>

                {
                    isOpen &&
                    <ModalContent
                        _onClose={ this._onClose }
                        _onKeyDown={ this._onKeyDown }
                        _onClickAway={ _onClickAway }
                        content={ children }
                        modalRef={ n => this.modalNode = n }
                        buttonRef={ n => this.closeButtonNode = n }
                        role={ role }
                        ariaLabel={ ariaLabel }/>
                }
            </>
        );
    }
}

const ModalTrigger = ({ _onOpen, image, triggerDisabled, buttonRef }) => {
    return (
        <div className="image-modal-trigger"
             onClick={ () => {
                 if (!triggerDisabled) {
                     _onOpen();
                 }
             } }
             ref={ buttonRef }>
            {
                image
            }
        </div>
    );
};

const ModalContent = ({ _onClickAway, _onClose, _onKeyDown, content, modalRef, buttonRef, ariaLabel, role = "dialog" }) => {

    return createPortal(
        <FocusTrap>
            <aside
                onKeyDown={ _onKeyDown }
                onClick={ _onClickAway }
                className='c-modal-cover'
                aria-modal='true'
                aria-label={ ariaLabel }
                role={ role }
                tabIndex='-1'>

                <div className='c-modal c-modal--image' ref={ modalRef }>
                    <button className='c-modal__close c-modal__close--image'
                            aria-label='Zamknij'
                            ref={ buttonRef }
                            onClick={ () => _onClose() }>
                        <span className="is-sr-only">Zamknij</span>
                        <IconCancel/>
                    </button>

                    <div className='c-modal__body--image'>
                        {
                            content
                        }
                    </div>
                </div>

            </aside>
        </FocusTrap>,
        document.body
    );
};


export default ImageModal;