import React from "react";
import moment from "moment";

import { UserRecordsEnum } from "../../../../../core/enums/common/logs";
import { ReactComponent as IconStatusActive } from "../../../../../../resource/img/icons/status/icon_done.svg";
import { ReactComponent as IconStatusPending } from "../../../../../../resource/img/icons/status/icon_in_progress.svg";
import { ReactComponent as IconStatusRejected } from "../../../../../../resource/img/icons/status/icon_rejected.svg";


const UserSubscriptionsItem = ({ userSubscription }) => {

    return (
        <tr>
            <td className='t-width-2 td--column'>
                <div className="mw-100">
                    <p className="table-text-content" title={ userSubscription.name }>
                        {
                            userSubscription.name
                        }
                    </p>
                </div>
                <p className="secondary-text secondary-text--under-ellipsis">
                    Kanał: { userSubscription.chanel }
                </p>
            </td>
            <td className='t-width-3'>
                <p className="table-text-content" title={ userSubscription.address }>
                    {
                        userSubscription.address
                    }
                </p>
            </td>
            <td className='t-width-2'>
                <p className="table-text-content">
                    {
                        userSubscription.module
                    }
                </p>
            </td>
            <td className='t-width-2'>
                {
                    renderActionType(userSubscription.action)
                }
            </td>
            <td className='t-width-1'>
                <p>
                    {
                        moment(userSubscription.dateOfAction)
                            .format("DD.MM.YYYY")
                    }
                </p>
            </td>
            <td className='t-width-2'>
                <p className="table-text-content" title={ userSubscription.notificationType }>
                    {
                        userSubscription.notificationType
                    }
                </p>
            </td>
        </tr>
    );
};

const renderActionType = (action) => {

    switch (action) {
        case UserRecordsEnum.action.REGISTRATION :
            return (
                <>
                    <IconStatusPending className="status-icon"/>
                    Zamówione
                </>
            );
        case UserRecordsEnum.action.ACCEPTANCE :
            return (
                <>
                    <IconStatusActive className="status-icon"/>
                    Akceptacja
                </>
            );
        case UserRecordsEnum.action.UNSUBSCRIBE :
            return (
                <>
                    <IconStatusRejected className="status-icon"/>
                    Rezygnacja
                </>
            );
        default:
            return;

    }
};

export default UserSubscriptionsItem;
