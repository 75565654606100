import { deepFreeze } from "../../utils/misc_utils";

export const EmployeesEnum = deepFreeze({
    status: {
        UNSET: "UNSET",
        ACTIVE: "ACTIVE",
        INACTIVE: "INACTIVE",
        BLOCKED: "BLOCKED"
    },
    position: {
        ADMINISTRATOR: "ADMINISTRATOR",
        MODERATOR: "MODERATOR"
    }
});