import React, { Component } from "react";
import { connect } from "react-redux";

import EmptyListPage from "../../../../../common/empty_list_page";
import MunicipalWasteHeader from "./header";
import MunicipalWasteMenu from "./menu";
import MunicipalWasteCalendar from "./calendar";
import NoAreasIllustration from "../../../../../../../resource/img/icons/empty_list_icons/brak_obszarow.png";
import Loader from "../../../../../common/loader";
import { isEmptyObject, isNullOrUndefined } from "../../../../../../core/utils/misc_utils";
import { getManyAreas } from "../../../../../../data/actions/waste_management/areas";
import { getManyMunicipalWasteYearsForArea } from "../../../../../../data/actions/waste_management/areas/municipal_waste/years";
import { getManyBins } from "../../../../../../data/actions/waste_management/bins";
import {
    getManyMunicipalWasteReceptionsForArea,
    publishChangesToMunicipalWasteReceptionsForArea,
    updateMunicipalWasteReceptionStatusForArea
} from "../../../../../../data/actions/waste_management/areas/municipal_waste/receptions";
import { ROUTE_WASTE_AREAS } from "../../../../../../core/constants";
import { toastFor500, toastForSuccess } from "../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../data/actions/common/ui/toast";

class MunicipalWaste extends Component {

    state = {
        selectedArea: null,
        selectedBin: null,
        selectedYear: null
    };

    render = () => {
        const { areas } = this.props;
        const { selectedArea } = this.state;

        if (!areas.meta.loading && isEmptyObject(areas.data)) {

            return (
                <EmptyListPage
                    img={ NoAreasIllustration }
                    imgAlt='Brak obszarów'
                    text='Najpierw musisz utworzyć obszary, żeby zacząć układać harmonogramy odbioru odpadów'
                    link={ ROUTE_WASTE_AREAS }
                    linkText='Przejdź do: Obszary'
                />
            )

        }

        return (
            <>
                <MunicipalWasteHeader
                    areas={ areas }
                    selectedArea={ selectedArea }
                    _onAreaSelect={ this._onAreaSelect }/>

                {
                    this.renderContent()
                }

            </>
        );
    };

    renderContent = () => {
        const { areas, bins, municipalWaste } = this.props;
        const { selectedArea, selectedBin, selectedYear } = this.state;

        if (areas.meta.loading) {
            return <Loader/>;
        } else {
            return (
                <>
                    <MunicipalWasteMenu
                        selectedArea={ selectedArea }
                        areas={ areas }

                        bins={ bins }
                        selectedBin={ selectedBin }
                        _onBinSelect={ this._onBinSelect }

                        years={ municipalWaste.years }
                        selectedYear={ selectedYear }
                        _onYearSelect={ this._onYearSelect }

                        receptions={ municipalWaste.receptions }
                        _onPublishClicked={ this._onPublishClicked }/>

                    <MunicipalWasteCalendar
                        receptions={ municipalWaste.receptions }
                        selectedYear={ selectedYear }
                        _onCalendarDateClicked={ this._onCalendarDateClicked }/>
                </>
            );
        }
    };

    componentDidMount = () => {
        const { getManyAreas, getManyBins } = this.props;
        getManyAreas();
        getManyBins();
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { getManyMunicipalWasteYearsForArea, getManyMunicipalWasteReceptionsForArea } = this.props;
        const { selectedArea, selectedYear, selectedBin } = this.state;

        if (prevState.selectedArea !== selectedArea) {
            getManyMunicipalWasteYearsForArea(selectedArea);
        }

        if (this.shouldCalendarUpdate(prevState)) {
            getManyMunicipalWasteReceptionsForArea(selectedArea, selectedYear, selectedBin);
        }
    };

    shouldComponentUpdate = (nextProps, nextState) => {
        const { selectedArea, selectedBin, selectedYear } = this.state;

        if (this.props.areas !== nextProps.areas) {
            return true;
        }

        if (this.props.bins !== nextProps.bins) {
            return true;
        }

        if (this.props.municipalWaste.years !== nextProps.municipalWaste.years) {
            return true;
        }

        if (this.props.municipalWaste.receptions !== nextProps.municipalWaste.receptions) {
            return true;
        }

        if (selectedArea !== nextState.selectedArea) {
            return true;
        }

        if (selectedBin !== nextState.selectedBin) {
            return true;
        }

        if (selectedYear !== nextState.selectedYear) {
            return true;
        }

        return false;
    };

    shouldCalendarUpdate = (prevState) => {
        const { selectedArea, selectedYear, selectedBin } = this.state;

        if (!isNullOrUndefined(selectedArea) &&
            !isNullOrUndefined(selectedYear) &&
            !isNullOrUndefined(selectedBin) &&
            prevState !== this.state) {
            return true;
        }

        return false;
    };

    _onAreaSelect = (areaId) => {
        this.setState({
            selectedArea: areaId
        });
    };

    _onBinSelect = (binId) => {
        this.setState({
            selectedBin: binId
        });
    };

    _onYearSelect = (year) => {
        this.setState({
            selectedYear: year
        });
    };

    _onCalendarDateClicked = (date, status) => {
        const { selectedArea, selectedBin, selectedYear } = this.state;
        const { updateMunicipalWasteReceptionStatusForArea, getManyMunicipalWasteReceptionsForArea } = this.props;

        if (!isNullOrUndefined(date) &&
            !isNullOrUndefined(selectedArea) &&
            !isNullOrUndefined(selectedBin) &&
            !isNullOrUndefined(selectedYear)) {

            return updateMunicipalWasteReceptionStatusForArea(selectedArea, selectedBin, date, status)
                .then(() => getManyMunicipalWasteReceptionsForArea(selectedArea, selectedYear, selectedBin));
        }
    };

    _onPublishClicked = () => {
        const { selectedArea, selectedBin, selectedYear } = this.state;
        const { publishChangesToMunicipalWasteReceptionsForArea, getManyMunicipalWasteReceptionsForArea, showToast, municipalWaste } = this.props;

        if (!isNullOrUndefined(selectedArea) &&
            !isNullOrUndefined(selectedBin) &&
            !isNullOrUndefined(selectedYear)) {

            return publishChangesToMunicipalWasteReceptionsForArea(selectedArea)
                .then(() => {
                    getManyMunicipalWasteReceptionsForArea(selectedArea, selectedYear, selectedBin);
                })
                .then(() => {
                    toastFor500(showToast, municipalWaste.publish, "Harmonogram nie został opublikowany. Spróbuj ponownie");
                    toastForSuccess(showToast, municipalWaste.publish, "Harmonogram został opublikowany");
                });
        }
    };
}

const mapStateToProps = (state) => {
    return {
        areas: state.entities.wasteManagement.areas.getMany,
        bins: state.entities.wasteManagement.bins.getMany,
        municipalWaste: {
            years: state.entities.wasteManagement.areas.municipalWaste.years.getMany,
            receptions: state.entities.wasteManagement.areas.municipalWaste.receptions.getMany,
            publish: state.entities.wasteManagement.areas.municipalWaste.receptions.publish
        }
    };
};

export default connect(
    mapStateToProps, {
        getManyAreas,
        getManyBins,
        getManyMunicipalWasteYearsForArea,
        getManyMunicipalWasteReceptionsForArea,
        updateMunicipalWasteReceptionStatusForArea,
        publishChangesToMunicipalWasteReceptionsForArea,
        showToast
    })(MunicipalWaste);