import add from "./add";
import getMany from "./get_many";
import publish from "./publish";
import unpublish from "./unpublish";

export default (state = {}, action) => {
    return {
        ...state,
        add: add(state.add, action),
        getMany: getMany(state.getMany, action),
        publish: publish(state.publish, action),
        unpublish: unpublish(state.unpublish, action)
    };
}