import React from "react";
import messageSentImg from "../../../../../../../../resource/img/icons/empty_list_icons/message-sent.png";

const Step3 = ({ currentStep, _onNewMessageClicked }) => {

    if (currentStep !== 3) {
        return null;
    }

    return (
        <div className="notifications-3__confirmation">
            <img src={ messageSentImg } alt=""/>
            <h2 className="notifications-3__confirmation-header is-bold">Zlecenie wysyłki zostało przyjęte</h2>
            <button className="btn btn--outlined"
                    type="button"
                    onClick={ _onNewMessageClicked }>
                Wyślij nową wiadomość
            </button>
        </div>
    );

};

export default Step3;