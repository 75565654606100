import React, { useState } from "react";
import { useDispatch } from "react-redux";
import LocationForm, { LocationFormTypeEnum } from "../../../../../common/location_form";
import { ReactComponent as Icon } from "../../../../../../../resource/img/icons/icon_wyodrebnij_lokale.svg";
import FormModal from "../../../../../common/modal/form_modal";
import FormRequiredLegend from "../../../../../common/legend_required";
import {
    addApartmentAddresses,
    getAllApartmentAddresses
} from "../../../../../../data/actions/ekostraznik/apartment_addresses";
import { showToast } from "../../../../../../data/actions/common/ui/toast";
import { ToastEnum } from "../../../../../../core/enums/common/toast"
import { generatePaginationQueryValues } from "../../../../../../core/utils/api_utils";

const AddApartmentAddressesForm = ({ searchBarTerm }) => {

    const dispatch = useDispatch();
    const [ selectedAddresses, setSelectedAddresses ] = useState([]);
    const [ submitDisabled, setSubmitDisabled ] = useState(true);
    const [ resetLocationForm, setResetLocationForm ] = useState(false);

    const _onSelect = (values) => {
        if (values.apartmentAddress && values.selectedAddress ) {
            setSelectedAddresses(values);
            setSubmitDisabled(false);
        }
    };

    const _onSubmit = () => {
        if (selectedAddresses.apartmentAddress && selectedAddresses.selectedAddress ) {
            return dispatch(addApartmentAddresses(selectedAddresses.selectedAddress.id, selectedAddresses.apartmentAddress))
                .then(response => {
                    if (response.status >= 400 && response.status < 500) {
                        dispatch(showToast(ToastEnum.type.ERROR, 'Lokal jest już wyodrębniony'));
                    }
                    if (response.status === 200) {
                        dispatch(showToast(ToastEnum.type.INFO, 'Lokale zostały poprawnie wyodrębnione'));
                    }
                })
                .then(() => dispatch(getAllApartmentAddresses({
                    ...generatePaginationQueryValues(0, 30, ["name", "asc"]),
                    query: searchBarTerm
                })))
                .then(() => {
                    setResetLocationForm(true);
                    setSelectedAddresses(null);
                    setSubmitDisabled(true);
                })
        }
    };

    return (
        <FormModal
            triggerComponent={
                <div className='row__right'>
                    <button className="btn btn--primary btn--big">
                        <Icon />
                        <span className="is-spaced--left-10">Wyodrębnij Lokale</span>
                    </button>
                </div>
            }
            ariaLabel='Wyodrębnij lokale'
            headerText='Wyodrębnij lokale'
            saveOrStayOrCancelButtons={ true }
            submitDisabled={ submitDisabled }
            _actionFunction={ () => {
                return _onSubmit();
            } }
            _actionFunctionWithFormClear={ () => {
                return _onSubmit();
            } }
            _cancelFunction={ async () => {
                setSubmitDisabled(true);
                setSelectedAddresses(null);
            } }
        >

            <div className='c-modal__body modal-size-m'>
                <div className='is-relative is-spaced--top-10'>
                    <FormRequiredLegend />
                </div>
                {
                    <LocationForm
                        horizontal={ false }
                        formType={ LocationFormTypeEnum.WITH_ADD_APARTMENT_ADDRESS }
                        _onChange={ () => {
                            setResetLocationForm(false);
                        } }
                        _onSelect={ values => {
                            _onSelect(values);
                        } }
                        resetForm={ resetLocationForm }
                    />
                }
            </div>
        </FormModal>
    );
};

export default AddApartmentAddressesForm;