import React from "react";
import { Link } from "react-router-dom";

const SidebarSubmenuItem = ({ activeSubmenuItem, _onSubmenuItemSelected, sidebarSubmenuItemId, sidebarSubmenuLink, sidebarItemName }) => {

    return (
        <li>
            <Link to={ sidebarSubmenuLink }
                  className={ `sidebar__submenu-item  ${ activeSubmenuItem === sidebarSubmenuItemId ? "sidebar__submenu-item--active" : "" }` }
                  onClick={ event => _onSubmenuItemSelected(event, sidebarSubmenuItemId) }>
                {
                    sidebarItemName
                }
            </Link>
        </li>
    );
};

export default SidebarSubmenuItem;