import React from "react";

import FormModal from "../../../../../common/modal/form_modal";

const SubcategoryDeleteModalForm = ({ triggerComponent, _actionFunction }) => {

    return (
        <FormModal
            triggerComponent={ triggerComponent }
            deleteOrCancelButtons={ true }
            _actionFunction={ () => {
                return _actionFunction();
            } }>

            <div className='c-modal__body modal-size-m modal--no-header'>
                <p className="confirmation-alert">
                    Czy na pewno chcesz usunąć tę podkategorię?
                </p>
            </div>

        </FormModal>
    );

};

export default SubcategoryDeleteModalForm;
