import React from "react";

const MunicipalWasteMenuYearsItem = ({ year, selectedYear, _onYearSelect }) => {

    return (
        <span className={ `schedule-year ${ year === selectedYear ? "schedule-year--selected" : "" }` }
              tabIndex="0"
              role="button"
              onClick={ () => _onYearSelect(year) }>
            { year }
        </span>
    );
};

export default MunicipalWasteMenuYearsItem;