import React from "react";
import { isEmptyArray } from "../../../core/utils/misc_utils";

const Attachments = ({ attachments }) => {

    return (
        <div className="notifications-2__row notifications-2__row--flex">
            <p className="notifications-2__label">Załączniki:</p>
            <ul className="full-width">

                {
                    !isEmptyArray(attachments) &&

                    attachments.map(attachment =>
                        <li key={ attachment.id } className="uploaded-file">
                            <p className="prevent-long-text">
                                { attachment.filename }
                            </p>
                        </li>

                    )
                }

            </ul>
        </div>
    );
};

export default Attachments;