import React from "react";

import { Link } from "react-router-dom";
import { ReactComponent as IconSearch } from "../../../../../../../../../resource/img/icons/icon_search.svg";
import { ReactComponent as IconBack } from "../../../../../../../../../resource/img/icons/icon_back_blue.svg";
import { ROUTE_BLISKO_ADDRESS_TEMPLATE } from "../../../../../../../../core/constants";
import AddressTemplateLocationAddForm from "./form/add_form";

const AddressTemplateLocationsHeader = ({ addressTemplate, searchBarTerm, _onSearchBarInputChange }) => (
    <section className='main-content--table__top'>
        <header className='page-header page-header--with-back'>
            <Link to={ ROUTE_BLISKO_ADDRESS_TEMPLATE } className="unstyled-btn">
                <IconBack/>
            </Link>
            <h1 className='title is-1'>
                {
                    !addressTemplate?.meta?.loading &&
                    addressTemplate?.data?.name
                }
            </h1>
        </header>

        <section className='container is-fluid'>
            <div className="row row--separated row--options">
                <div className='row__left'>
                    <div className="input-field header__search with-icon--right">
                        <input type='text'
                               placeholder='Szukaj adresu'
                               onChange={ event => _onSearchBarInputChange(event.target.value) }/>
                        <IconSearch className='input-icon'/>
                    </div>
                </div>
                <div className='row__right is-flex'>
                    <AddressTemplateLocationAddForm addressTemplate={ addressTemplate }
                                                    searchBarTerm={ searchBarTerm }/>
                    <div className='is-spaced--left-10'>
                        {/*todo dropdown*/ }
                    </div>
                </div>
            </div>
        </section>
    </section>
);

export default AddressTemplateLocationsHeader;