import React, { Component } from "react";

import StandardStyleButton from "./style_button/standard";

const controls = [
    { type: "block", label: "IconH3", style: "header-three" },
    { type: "block", label: "IconH4", style: "header-four" },
    { type: "inline", label: "IconBold", style: "BOLD" },
    { type: "inline", label: "IconItalic", style: "ITALIC" },
    { type: "inline", label: "IconUnderline", style: "UNDERLINE" },
    { type: "block", label: "IconUl", style: "unordered-list-item" },
    { type: "block", label: "IconOl", style: "ordered-list-item" },
    { type: "block", label: "IconInfo", style: "INFO" },
    { type: "block", label: "IconWarning", style: "WARNING" },
];

class RenderControls extends Component {

    render = () => {
        const { onToggle } = this.props;

        return (
            <div className="RichEditor-controls">
                {
                    controls.map(control => {

                        return (
                            <StandardStyleButton
                                key={ control.label }
                                label={ control.label }
                                style={ control.style }
                                active={ this.setType(control) }
                                _onChange={ event => {
                                    event.preventDefault();
                                    onToggle(control.style);
                                } }/>
                        );
                    })
                }
            </div>
        );
    };

    setType = (control) => {
        const { editorState } = this.props;

        const selection = editorState.getSelection();
        const blockType = editorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey())
            .getType();

        const currentStyle = editorState.getCurrentInlineStyle();

        switch (control.type) {
            case "block":
                return control.style === blockType;

            case "inline":
                return currentStyle.has(control.style);

            default:
                return null;

        }
    };
}

export default RenderControls;