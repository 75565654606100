import React, { useEffect, useState } from "react";
import { isEmptyArray } from "../../../../../../../../core/utils/misc_utils";
import Select from "../../../../../../../common/select";
import { useDispatch } from "react-redux";
import { getManyAddressTemplates } from "../../../../../../../../data/actions/blisko/address_templates";
import _ from "lodash";

const AddressTemplateAddresses = ({ _onAddressTemplateAdd }) => {
    const dispatch = useDispatch();
    const [selectedAddressTemplate, setSelectedAddressTemplate] = useState();
    const [addressTemplateOptions, setAddressTemplateOptions] = useState();

    useEffect(() => {
        dispatch(getManyAddressTemplates({ all: true }))
            .then(response => {
                const options = _.map(response?.data?.content?.filter(addressTemplate => addressTemplate.units), addressTemplate => {
                    return {
                        label: addressTemplate.name,
                        value: addressTemplate
                    };
                });
                setAddressTemplateOptions(options);

                return options;
            })
            .then(options => {
                if (!isEmptyArray(options)) {
                    setSelectedAddressTemplate(options[0]);
                }
            });
    }, [dispatch]);

    if (isEmptyArray(addressTemplateOptions)) {
        return null;
    }

    return (
        <div className="message-sending-1__row message-sending-1__row--flex is-spaced--top-10">
            <label className="message-sending-1__input-wrap message-sending-1__input-wrap--select">
                <span className="location-form__label">Wybierz adresy z szablonów</span>
                <Select
                    options={ addressTemplateOptions }
                    value={ selectedAddressTemplate }
                    isClearable={ false }
                    onChange={ addressTemplateOption => setSelectedAddressTemplate(addressTemplateOption) }/>
            </label>
            <button className="btn btn--primary message-sending-1__button is-spaced--bottom-20"
                    type="button"
                    disabled={ !selectedAddressTemplate }
                    onClick={ () => _onAddressTemplateAdd(selectedAddressTemplate.value.id) }>
                Dodaj do listy
            </button>
        </div>
    );
};

export default AddressTemplateAddresses;