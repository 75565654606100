import React from "react";

import FormRequiredLegend from "../../../../../../common/legend_required";
import { BottomCustomButtons } from "../../../../../../common/bottom_action_buttons";
import Addresses from "./addresses";
import ShipmentDate from "./shipment_date";
import ReceiverGroups from "./receiver_group";
import { useHistory } from "react-router-dom";
import { ROUTE_BLISKO_MESSAGES } from "../../../../../../../core/constants";
import FormModal from "../../../../../../common/modal/form_modal";
import MessageContent from "../../../../../../common/message_content_form";
import { useSelector } from "react-redux";

const Step1 = ({
                   formikBag,
                   currentStep,
                   useGeoLocation,
                   useLocationFilter,
                   sendImmediately,
                   uploadedFiles,
                   defaultCoordinates,
                   editDraftMode,
                   draftMessageId,
                   setState,
                   _useGeoLocationToggle,
                   _useLocationFilterChange,
                   _sendImmediatelyChange,
                   _alternativeTextChange,
                   _onFileUploadStarted,
                   _onDeleteUploadedFileClick,
                   _onNextClick,
                   _onSubmitDraftMessage
               }) => {
    const history = useHistory();
    const locationEnabled = useSelector(state => state.entities?.common?.owner?.getConfiguration)?.data?.locationEnabled;
    const { values, setFieldValue, errors } = formikBag;

    if (currentStep !== 1) {
        return null;
    }

    return (
        <main className="message-sending-1">
            <div className="content--m content--not-centered">
                <div className="is-relative">
                    <FormRequiredLegend/>
                </div>
                <MessageContent
                    body={ values.body }
                    useGeoLocation={ useGeoLocation }
                    uploadedFiles={ uploadedFiles }
                    priorityLevel={ values.priority }
                    latitude={ values.latitude }
                    longitude={ values.longitude }
                    defaultCoordinates={ defaultCoordinates }
                    withPasteTemplate={ !editDraftMode }
                    setFieldValue={ setFieldValue }
                    errors={ errors }
                    step={ locationEnabled ? "1/4" : "1/3" }
                    blisko
                    setState={ setState }
                    _useGeoLocationToggle={ _useGeoLocationToggle }
                    _onFileUploadStarted={ _onFileUploadStarted }
                    _onDeleteUploadedFileClick={ _onDeleteUploadedFileClick }
                    _onAlternativeTextChanged={ _alternativeTextChange }
                    _onMessageContentChanged={ (value) => setFieldValue("body", value) }
                />

                <ReceiverGroups
                    receiverGroupIds={ values.groupIds }
                    errors={ errors }
                    setFieldValue={ setFieldValue }
                    step={ locationEnabled? "2/4" : "2/3" }
                />

                {
                    locationEnabled &&
                    <Addresses
                        editDraftMode={ editDraftMode }
                        draftMessageId={ draftMessageId }
                        useLocationFilter={ useLocationFilter }
                        _useLocationFilterChange={ _useLocationFilterChange }
                    />
                }

                <ShipmentDate
                    date={ values.date }
                    time={ values.time }
                    errors={ errors }
                    sendImmediately={ sendImmediately }
                    setFieldValue={ setFieldValue }
                    _sendImmediatelyChange={ _sendImmediatelyChange }
                    step={ locationEnabled ? "4/4" : "3/3" }
                />
            </div>

            <BottomCustomButtons
                containerClass="content--m content--not-centered"
                buttonSpacingClass="space-between"
            >
                <button className="btn btn--small btn--outlined"
                        onClick={ () => history.push(ROUTE_BLISKO_MESSAGES, { selectTab: editDraftMode ? 1 : 0 }) }>
                    Anuluj
                </button>
                <div>
                    <FormModal
                        triggerComponent={ (
                            <div className="btn" style={ { fontWeight: 500 } }>
                                Zapisz wersję roboczą
                            </div>
                        ) }
                        ariaLabel='Zapisz wersję roboczą'
                        headerText='Zapisz wersję roboczą'
                        saveOrCancelButtons={ true }
                        _actionFunction={ () => {
                            return _onSubmitDraftMessage(formikBag)
                        } }
                    >
                        <div className='c-modal__body modal-size-m modal--no-header'>
                            <p className="confirmation-alert">
                                Czy chcesz
                                {
                                    editDraftMode
                                        ? ' zaktualizować wiadomość roboczą?'
                                        : ' zapisać wiadomość jako roboczą?'
                                }
                            </p>
                        </div>
                    </FormModal>
                    <button className="btn btn--primary btn--medium"
                            onClick={ _onNextClick }>
                        Wyślij wiadomość
                        <div className="btn__arrow"/>
                    </button>
                </div>

            </BottomCustomButtons>
        </main>
    );
};

export default Step1;