import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AreaDetailsHeader from "./header";
import AreaDetailsLocationList from "./location/list";
import { getArea } from "../../../../../../data/actions/waste_management/areas";
import _ from "lodash";

const AreaDetails = ({ match }) => {
    const dispatch = useDispatch();
    const [ searchBarTerm, setSearchBarTerm ] = useState('');
    const area = useSelector(state => state.entities?.wasteManagement?.areas?.get)

    const areaId = match.params.areaId;

    useEffect(() => {
        dispatch(getArea(areaId))
    }, [ dispatch, areaId ])

    const _onSearchBarInputChange = useCallback(
        _.debounce(input => setSearchBarTerm(input || ''), 600),
        []
    );

    return (
        <div className="main-content--table">
            <AreaDetailsHeader
                area={ area }
                _onSearchBarInputChange={ _onSearchBarInputChange }
                searchBarTerm={ searchBarTerm }
            />

            <AreaDetailsLocationList
                areaId={ areaId }
                searchBarTerm={ searchBarTerm }
            />
        </div>
    );
}

export default AreaDetails;
