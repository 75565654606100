import FormModal from "../../../../../../common/modal/form_modal";
import { ReactComponent as IconDelete } from "../../../../../../../../resource/img/icons/icon_delete.svg";
import React from "react";
import ApartmentAddressDeleteModalFormContent from "./content";

const ApartmentAddressDeleteModalForm = ({ apartmentAddress, deleteApartmentAddresses, _onApartmentAddressDeleteClick }) => {

    return (
        <FormModal
            triggerComponent={
                <button className='unstyled-btn' title='Usuń'>
                    <IconDelete/>
                </button>
            }
            ariaLabel='Usuń adres'
            deleteOrCancelButtons={ true }
            isLoading={ deleteApartmentAddresses?.meta?.loading }
            _actionFunction={ () => _onApartmentAddressDeleteClick(apartmentAddress.id) }>

            <ApartmentAddressDeleteModalFormContent/>

        </FormModal>
    );
};

export default ApartmentAddressDeleteModalForm;