import _ from "lodash";

import { DEFAULT_STATE, DEFAULT_STATE__API__FAIL, DEFAULT_STATE__API__STARTED, DEFAULT_STATE__API__SUCCESS } from "../../../../../../core/utils/store_utils";
import { actions } from "../../../../../../core/constants";
import { isNullOrUndefined } from "../../../../../../core/utils/misc_utils";

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case actions.wasteManagement.API__LOCATION_CANDIDATES_TOWNS__GET_MANY__STARTED:
            return DEFAULT_STATE__API__STARTED(state, DEFAULT_STATE);

        case actions.wasteManagement.API__LOCATION_CANDIDATES_TOWNS__GET_MANY__SUCCESS:
            const actionWithSortedPayload = {
                ...action,
                payload: _.mapKeys(_.sortBy(action.payload, ["name"]), "id")
            };

            return DEFAULT_STATE__API__SUCCESS(state, DEFAULT_STATE, actionWithSortedPayload);

        case actions.wasteManagement.API__LOCATION_CANDIDATES_TOWNS__GET_MANY__FAIL:
            return DEFAULT_STATE__API__FAIL(state, DEFAULT_STATE, action);

        case actions.wasteManagement.API_LOCATION_CANDIDATES_RESET:
            if (!isNullOrUndefined(action.payload.town) && action.payload.town) {
                return DEFAULT_STATE;
            } else {
                return state;
            }

        case actions.common.UI__AUTHENTICATION__SIGN_OUT:
            return DEFAULT_STATE;

        default:
            return state;
    }
}