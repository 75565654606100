import React from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { addArea, updateArea } from "../../../../../../../data/actions/waste_management/areas";
import { invoke, isEmptyObject, isNullOrUndefined } from '../../../../../../../core/utils/misc_utils';
import FormModal from "../../../../../../common/modal/form_modal";
import AreaCreateAndEditModalFormContent from "./content";
import { ReactComponent as IconAdd } from "../../../../../../../../resource/img/icons/icon_add_white.svg";
import { ReactComponent as IconEdit } from "../../../../../../../../resource/img/icons/icon_edit.svg";
import { is4xx, is5xx } from "../../../../../../../core/utils/api_utils";
import { AreasEnum } from "../../../../../../../core/enums/waste_management/areas";
import _ from "lodash";
import { toastForCustomError, toastForCustomSuccess } from "../../../../../../../core/utils/toast_utils";
import { showToast } from "../../../../../../../data/actions/common/ui/toast";

const initialFormValues = {
    name: ""
};

const AreaCreateAndEditModalForm = ({ area, _onSubmitCallback }) => {
    const dispatch = useDispatch();
    const areaAdd = useSelector(state => state.entities?.wasteManagement?.areas?.add);
    const areaUpdate = useSelector(state => state.entities?.wasteManagement?.areas?.update);
    const initialValues = !isNullOrUndefined(area) && !isNullOrUndefined(area.name)
        ? { name: area.name }
        : initialFormValues;

    const _handleErrorMessage = (formikBag, response, message) => {
        const errors = {};
        const nameAlreadyExistsCode = AreasEnum.errorCode.NAME_ALREADY_EXISTS;

        if (response?.data?.message.startsWith(nameAlreadyExistsCode)) {
            errors.name = "Obszar o tej nazwie już istnieje";
        }

        if (!isEmptyObject(errors)) {
            formikBag.setErrors(errors);
        } else {
            toastForCustomError((...args) => dispatch(showToast(...args)), message);
        }

        return Promise.reject(null);
    };

    const _validate = (values) => _.pickBy({
        name: !values.name ? "Pole wymagane" : undefined
    });

    const _onSubmit = (formikBag) => {
        const errors = _validate(formikBag.values);

        if (!isEmptyObject(errors)) {
            formikBag.setErrors(errors);
            return Promise.reject(null);
        }

        if (!isNullOrUndefined(area)) {
            return dispatch(updateArea(area.id, formikBag.values))
                .then(response => {
                    if (is4xx(response?.status) || is5xx(response?.status)) {
                        return _handleErrorMessage(formikBag, response, "Zmiana nazwy obszaru nie powiodła się. Spróbuj ponownie");
                    } else {
                        toastForCustomSuccess((...args) => dispatch(showToast(...args)), 'Nazwa obszaru została zmieniona');
                        return invoke(_onSubmitCallback);
                    }
                });
        } else {
            return dispatch(addArea(formikBag.values))
                .then(response => {
                    if (is4xx(response?.status) || is5xx(response?.status)) {
                        return _handleErrorMessage(formikBag, response, "Dodanie obszaru nie powiodło się. Spróbuj ponownie");
                    } else {
                        toastForCustomSuccess((...args) => dispatch(showToast(...args)), 'Obszar został dodany');
                        formikBag.resetForm();
                        return invoke(_onSubmitCallback);
                    }
                });
        }
    };

    return (
        <Formik
            initialValues={ initialValues }
            render={ formikBag => (
                <FormModal
                    triggerComponent={
                        area
                            ? <button className='unstyled-btn' title='Edytuj'>
                                <IconEdit/>
                            </button>
                            : <button className='btn btn--primary btn--big'>
                                <IconAdd/>
                                <span className='is-spaced--left-10'>Dodaj obszar</span>
                            </button>
                    }
                    headerText={
                        area
                            ? 'Zmień nazwę'
                            : 'Nowy obszar'
                    }
                    saveOrCancelButtons={ true }
                    isLoading={
                        !isNullOrUndefined(area)
                            ? areaUpdate.meta.loading
                            : areaAdd.meta.loading
                    }
                    _actionFunction={ () => _onSubmit(formikBag) }>

                    <Form>
                        <AreaCreateAndEditModalFormContent
                            formikBag={ formikBag }
                        />
                    </Form>

                </FormModal>
            ) }/>
    );
};

export default AreaCreateAndEditModalForm;