import { isEmptyArray, isEmptyString, isNullOrUndefined } from "./misc_utils";
import { AddressComponentsEnum } from "../enums/common/address_components";

export const extractRelevantForwardGeocodingData = (data) => {
    if (isNullOrUndefined(data) || isEmptyArray(data)) {
        return [];
    }

    return data
        .map(entry => {
            return {
                ...entry,
                place_name: formatAddressFromAddressComponents(entry.address_components)
            };
        });
};

export const extractRelevantReverseGeocodingData = (data) => {
    if (isNullOrUndefined(data) || isEmptyArray(data)) {
        return [];
    }

    return data
        .map(entry => {
            return {
                ...entry,
                place_name: formatAddressFromAddressComponents(entry.address_components)
            };
        })[0];
};

const formatAddressFromAddressComponents = (addressComponents) => {
    const premise = findInAddressComponents(addressComponents, AddressComponentsEnum.types.PREMISE);

    const streetNumber = findInAddressComponents(addressComponents, AddressComponentsEnum.types.STREET_NUMBER);
    const streetAddress = findInAddressComponents(addressComponents, AddressComponentsEnum.types.STREET_ADDRESS);
    const route = findInAddressComponents(addressComponents, AddressComponentsEnum.types.ROUTE);

    const locality = findInAddressComponents(addressComponents, AddressComponentsEnum.types.LOCALITY);

    const street =
        !isNullOrUndefined(streetAddress)
            ? streetAddress
            : !isNullOrUndefined(route)
            ? route
            : "";

    const formattedStreet = `${ street !== "Unnamed Road" ? street : "ulica bez nazwy" }`;

    if (!isNullOrUndefined(premise)) {

        return `${ locality } ${ premise }`;

    } else if (!isNullOrUndefined(streetNumber) && !isEmptyString(street)) {

        return `${ formattedStreet } ${ streetNumber }, ${ locality }`;

    } else if (!isEmptyString(street) && !isNullOrUndefined(locality)) {

        return `${ formattedStreet }, ${ locality }`;

    } else if (!isEmptyString(street)) {

        return `${ formattedStreet }`;

    } else {

        return locality;
    }
};

const findInAddressComponents = (addressComponents, addressComponentType) => {
    for (let i = 0; i < addressComponents.length; i++) {
        if (addressComponents[i].types.includes(addressComponentType)) {
            return addressComponents[i].long_name;
        }
    }

    return null;
};