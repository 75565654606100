import { deepFreeze } from "../../utils/misc_utils";

export const LocationsEnum = deepFreeze({
    type: {
        TOWN: "TOWN",
        TOWN_ADDRESS: "TOWN_ADDRESS",
        STREET: "STREET",
        STREET_ADDRESS: "STREET_ADDRESS",
        APARTMENT_ADDRESS: "APARTMENT_ADDRESS",
    },
    kind: {
        TOWN_LOCATION: "TOWN_LOCATION",
        STREET_LOCATION: "STREET_LOCATION",
        ADDRESS_LOCATION: "ADDRESS_LOCATION",
        APARTMENT_LOCATION: "APARTMENT_LOCATION"
    },
    editOperationType: {
        ADDITIVE: "ADDITIVE",
        SUBTRACTIVE: "SUBTRACTIVE"
    },
    visualType: {
        TOWN: "TOWN",
        STREET: "STREET",
        ADDRESS_FROM: "ADDRESS_FROM",
        ADDRESS_TO: "ADDRESS_TO",
        ADDRESS: "ADDRESS",
        APARTMENT_ADDRESS: "APARTMENT_ADDRESS",
    }
});
