import React from "react";
import { FileUpload, UploadedFile } from "../../../../../common/file_upload";
import TitleWithDivider from "../../../../../common/title_with_divider";
import { isEmptyArray } from "../../../../../../core/utils/misc_utils";

const SelectAdditionalInformationForm = ({ formikBag, images, onFileUploadStarted, onDeleteUploadedFileClick }) => {

    return (
        <div className='is-spaced--top-40'>
            <TitleWithDivider>
                Informacje dodatkowe
            </TitleWithDivider> 
            <span className='label-name'>Zdjęcia</span>
            <div className="message-sending-1__dropzone">
                <FileUpload
                    _onStart={ onFileUploadStarted }
                    acceptedFileExtensions={ ['png', 'jpg', 'jpeg'] }
                />
                {
                    !isEmptyArray(images) &&
                    images.map(image => {
                        return (
                            <UploadedFile
                                key={ image.name }
                                id={ image.name }
                                name={ image.name }
                                alternativeTextRequired={ false }
                                isLoading={ false }
                                _onDeleteUploadedFileClick={ onDeleteUploadedFileClick }/>
                        );
                    })
                }
            </div>
            <label>
                <span className='label-name'>Opis</span>
                <textarea 
                    value={ formikBag.values.description }
                    onChange={ event => formikBag.setFieldValue("description", event.target.value) }
                />
            </label>
        </div>
    );
};

export default SelectAdditionalInformationForm;