import React from "react";
import { Link } from "react-router-dom";
import { ROUTE_WASTE_AREAS } from "../../../../../../core/constants";

import AreaLocationAddModalForm from "./location/list/create_form";

import { ReactComponent as IconSearch } from "../../../../../../../resource/img/icons/icon_search.svg";
import { ReactComponent as IconBack } from "../../../../../../../resource/img/icons/icon_back_blue.svg";
import AreaDetailsDropdownMenu from "./dropdown_menu";

const AreaDetailsHeader = ({ area, _onSearchBarInputChange, searchBarTerm }) => {
    return (
        <section className='main-content--table__top'>
            <header className='page-header page-header--with-back'>
                <Link to={ ROUTE_WASTE_AREAS } className="unstyled-btn">
                    <IconBack/>
                </Link>
                <h1 className='title is-1'>
                    {
                        !area?.meta?.loading &&
                        area?.data?.name
                    }
                </h1>
            </header>

            <section className='container is-fluid'>
                <div className="row row--separated row--options">
                    <div className='row__left'>
                        <div className="input-field header__search with-icon--right">
                            <input type='text'
                                   placeholder='Szukaj adresu punktu'
                                   onChange={ event => _onSearchBarInputChange(event.target.value) }/>
                            <IconSearch className='input-icon'/>
                        </div>
                    </div>
                    <div className='row__right is-flex'>
                        <AreaLocationAddModalForm area={ area } searchBarTerm={ searchBarTerm }/>
                        <div className='is-spaced--left-10'>
                            <AreaDetailsDropdownMenu areaId={ area?.data?.id }/>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
};

export default AreaDetailsHeader;
