import React, { Component } from "react";

import withForwardGeocoding from "../../../../../../../common/hoc/with_forward_geocoding";

class BulkyWasteCreateAndEditModalFormLocation extends Component {

    render = () => {
        const { renderInputWithSuggestions, required } = this.props;

        return (
            <label className="less-spacing--bottom">
                <span className="label-name">
                    {
                        required &&
                        <span className="required-accent">*</span>
                    }
                    Lokalizacja:
                </span>

                {
                    renderInputWithSuggestions()
                }

            </label>
        );
    };
}

export default withForwardGeocoding(BulkyWasteCreateAndEditModalFormLocation);