import add from "./add";
import get from "./get"
import deleteMany from "./delete_many";

export default (state = {}, action) => {
    return {
        ...state,
        add: add(state.add, action),
        get: get(state.get, action),
        deleteMany: deleteMany(state.deleteMany, action)
    };
}