import { ReactComponent as IconInfo } from "../../../resource/img/icons/icon_info.svg";
import React from 'react';

const InfoBanner = ({ children }) => {
    return (
        <div className="info-banner">
            <p className="is-flex">
                <IconInfo/>
                { children }
            </p>
        </div>
    )
}

export default InfoBanner;