import React from "react";

import { ReactComponent as IconLock } from "../../../../../../../resource/img/icons/icon_lock.svg";


const AccountStatusSuspended = ({ employee, _onRestoreAction }) =>{
    return(
        <div className="status-box">
            <div className="status status--suspended">
                <p className="is-v-centered">
                    <IconLock className="icon--danger"/>
                    Konto zawieszone.
                    <span className="is-underlined is-bold"
                          onClick={ () => _onRestoreAction(employee.id) }>
                        Przywróć konto
                    </span>
                </p>
            </div>
        </div>
    );
};

export default AccountStatusSuspended;