import React, { useEffect, useState } from 'react';

import withMultiStepForm from "../../../../../common/hoc/with_multistep_form";
import { MessageEnum } from "../../../../../../core/enums/waste_management/messages";
import { addFile, resetFileState } from "../../../../../../data/actions/common/files";
import Header from "../../../../../common/header";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Step1 from "./step-1";
import { isEmptyArray, isEmptyObject, isLoading } from "../../../../../../core/utils/misc_utils";
import Step2 from "./step-2";
import Step3 from "./step-3";
import { toastForCustomError, toastForCustomSuccess } from "../../../../../../core/utils/toast_utils";
import { addMessage } from "../../../../../../data/actions/blisko/messages";
import { showToast } from '../../../../../../data/actions/common/ui/toast';
import {
    addDraftMessage,
    getDraftMessage,
    patchDraftMessage,
    updateDraftMessage
} from "../../../../../../data/actions/blisko/draft_messages";
import { ROUTE_BLISKO_MESSAGES } from "../../../../../../core/constants";
import { useHistory } from "react-router-dom";
import Loader from "../../../../../common/loader";
import { getManyDraftMessageAddresses } from "../../../../../../data/actions/blisko/draft_message_addresses";
import { is4xx, is5xx } from "../../../../../../core/utils/api_utils";
import { getOwnerConfiguration } from "../../../../../../data/actions/common/owner";

const initialFormValues = {
    title: "",
    body: "",
    latitude: "",
    longitude: "",
    groupIds: [],
    date: null,
    time: null,
    types: [MessageEnum.type.CLOUD, MessageEnum.type.EMAIL],
    priority: MessageEnum.priorityLevel.INFO
};

const initialState = () => {
    return {
        useGeoLocation: false,
        useLocationFilter: false,
        sendImmediately: true,
        uploadedFiles: [],
        sendToAll: true
    };
};

const MessageSending = ({ match, currentStep, _onNextStepClick, _onPrevStepClick, _onFirstStepClick }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(initialState);
    const defaultCoordinates = useSelector(state => state.entities?.common?.owners?.getCoordinates);
    const formikInitialFormValues = {
        ...initialFormValues,
        latitude: defaultCoordinates.latitude,
        longitude: defaultCoordinates.longitude
    };

    const draftMessageId = match?.params?.draftMessageId;
    const editDraftMode = !!draftMessageId;
    const [shouldInitializeDraftValues, setShouldInitializeDraftValues] = useState(true);
    const draftMessageGet = useSelector(state => state.entities?.blisko?.draftMessages?.get);

    useEffect(() => {
        resetFileState();
        dispatch(getOwnerConfiguration());
    }, [dispatch]);

    const _useGeoLocationToggle = () => {
        setState(prevState => {
            return { ...prevState, useGeoLocation: !prevState.useGeoLocation }
        })
    };

    const _useLocationFilterChange = (value) => {
        setState(prevState => {
            return {
                ...prevState,
                useLocationFilter: value,
                sendToAll: !value
            }
        })
    };

    const _sendImmediatelyChange = (value) => {
        setState(prevState => {
            return { ...prevState, sendImmediately: value }
        })
    };

    const _onFileUploadStarted = (fileArray) => {
        const { uploadedFiles } = state;

        fileArray.forEach(file =>
            dispatch(addFile(file))
                .then(uploadedFile => {
                    if (!uploadedFile.error && uploadedFile.data) {

                        const uploadedFileData = uploadedFile.data;

                        if (uploadedFileData && uploadedFiles.indexOf(uploadedFileData) === -1) {

                            const newUploadedFiles = uploadedFiles;
                            newUploadedFiles.push(uploadedFileData);
                            setState(prevState => {
                                return { ...prevState, uploadedFiles: newUploadedFiles }
                            });
                        }
                    }
                })
        );
    };

    const _onDeleteUploadedFileClick = (id) => {
        const { uploadedFiles } = state;

        if (!id || isEmptyArray(uploadedFiles)) {
            return;
        }

        let i = 0;
        for (; i < uploadedFiles.length; i++) {
            if (uploadedFiles[i].id === id) {
                break;
            }
        }

        const newUploadedFiles = uploadedFiles;
        newUploadedFiles.splice(i, 1);

        setState(prevState => {
            return { ...prevState, uploadedFiles: newUploadedFiles }
        });
    };

    const _onNextClick = (event, formikBag) => {
        const errors = _validate(formikBag.values);

        if (!isEmptyObject(errors)) {
            formikBag.setErrors(errors);
            toastForCustomError((...args) => dispatch(showToast(...args)), "Błędnie wypełniony formularz");
            event.preventDefault();
            return;
        }

        return _onNextStepClick();
    };

    const _validate = (values) => {
        const { uploadedFiles } = state;

        const errors = {};
        const isTitleValid = !!values.title;
        const isBodyValid =
            values.body &&
            !(values.body.search('<p><br data-mce-bogus="1"></p>') >= 0 ||
                values.body.search('<p><br></p>') >= 0);
        const isGroupIdsValid = !isEmptyArray(values.groupIds);
        const isDateValid = state.sendImmediately ? state.sendImmediately : Date.parse(values.date + "T" + values.time) >= Date.now();

        if (!isTitleValid) {
            errors.title = "Pole wymagane";
        }

        if (!isDateValid) {
            errors.date = "Termin wysyłki musi być w przyszłości";
        }

        if (!isBodyValid) {
            errors.body = "Pole wymagane";
        }

        if (!isGroupIdsValid) {
            errors.groupIds = "Wybierz mininimum jeden serwis"
        }

        uploadedFiles.forEach(file => {
            if (file.alternativeTextRequired && !file.alternativeText) {
                if (!errors.uploadedFiles) {
                    errors.uploadedFiles = new Map();
                }
                errors.uploadedFiles.set(file.id, "Opis alternatywny wymagany");
            }
        });

        return errors;
    };

    const _onCancel = (formikResetForm) => {
        if (editDraftMode) {
            formikResetForm(initialFormValues);
            setState(initialState);
            history.push(ROUTE_BLISKO_MESSAGES, { selectTab: 1 });
        } else {
            formikResetForm(initialFormValues);
            _onFirstStepClick();
            setState(initialState);
        }
    };

    const _onSubmit = (formValues) => {
        const { useGeoLocation, sendImmediately, uploadedFiles, sendToAll } = state;

        const data = {
            title: formValues.title,
            body: formValues.body,
            fileIds: uploadedFiles.map(file => file.id),
            latitude: useGeoLocation ? formValues.latitude : null,
            longitude: useGeoLocation ? formValues.longitude : null,
            requestedRealization: !sendImmediately ? formValues.date + "T" + formValues.time : null,
            types: [MessageEnum.type.CLOUD, MessageEnum.type.EMAIL],
            sendToAll: sendToAll,
            priority: formValues.priority.value,
            groupIds: formValues.groupIds
        };

        return editDraftMode
            ? dispatch(updateDraftMessage(draftMessageId, data))
                .then(response => {
                    if (is4xx(response?.status) || is5xx(response?.status)) {
                        toastForCustomError((...args) => dispatch(showToast(...args)), "Nie udało się wysłać wiadomości. Spróbuj ponownie");
                    } else {
                        dispatch(patchDraftMessage(draftMessageId, { command: 'dispatch' }))
                            .then(response => {
                                if (is4xx(response?.status) || is5xx(response?.status)) {
                                    toastForCustomError((...args) => dispatch(showToast(...args)), "Nie udało się wysłać wiadomości. Spróbuj ponownie");
                                } else {
                                    _onNextStepClick()
                                }
                            })
                    }
                })
            : dispatch(addMessage(data))
                .then(response => {
                    if (is4xx(response?.status) || is5xx(response?.status)) {
                        toastForCustomError((...args) => dispatch(showToast(...args)), "Nie udało się wysłać wiadomości. Spróbuj ponownie");
                    } else {
                        _onNextStepClick()
                    }
                });
    };

    const _onSubmitDraftMessage = (formikBag) => {
        const { useGeoLocation, sendImmediately, uploadedFiles, sendToAll } = state;
        const { values, setErrors } = formikBag;
        const errors = _validate(values);

        if (!isEmptyObject(errors)) {
            setErrors(errors);
            toastForCustomError((...args) => dispatch(showToast(...args)), "Błędnie wypełniony formularz");
            return Promise.reject();
        }

        const data = {
            title: values.title,
            body: values.body,
            fileIds: uploadedFiles.map(file => file.id),
            latitude: useGeoLocation ? values.latitude : null,
            longitude: useGeoLocation ? values.longitude : null,
            requestedRealization: !sendImmediately ? values.date + "T" + values.time : null,
            types: [MessageEnum.type.CLOUD, MessageEnum.type.EMAIL],
            sendToAll: sendToAll,
            priority: values.priority.value,
            groupIds: values.groupIds
        };

        return editDraftMode
            ? dispatch(updateDraftMessage(draftMessageId, data))
                .then(response => {
                    if (is4xx(response?.status) || is5xx(response?.status)) {
                        toastForCustomError((...args) => dispatch(showToast(...args)), "Nie udało się zaktualizować wiadomości roboczej. Spróbuj ponownie");
                    } else {
                        toastForCustomSuccess((...args) => dispatch(showToast(...args)), "Wiadomość robocza została zaktualizowana");
                        history.push(ROUTE_BLISKO_MESSAGES, { selectTab: 1 });
                    }
                })
            : dispatch(addDraftMessage(data))
                .then(response => {
                    if (is4xx(response?.status) || is5xx(response?.status)) {
                        toastForCustomError((...args) => dispatch(showToast(...args)), "Nie udało się zapisać wiadomości w wersji roboczej. Spróbuj ponownie");
                    } else {
                        toastForCustomSuccess((...args) => dispatch(showToast(...args)), "Wiadomość została zapisana jako robocza");
                        history.push(ROUTE_BLISKO_MESSAGES, { selectTab: 0 });
                    }
                });
    };

    const _onNewMessageClicked = (resetForm) => {
        return dispatch(resetFileState())
            .then(() => setState(initialState))
            .then(() => resetForm(initialFormValues))
            .then(() => _onFirstStepClick());
    };

    const renderAppropriateStep = (formikBag) => {
        const { useGeoLocation, useLocationFilter, sendImmediately, uploadedFiles } = state;

        const { values } = formikBag;

        if (editDraftMode && shouldInitializeDraftValues) {
            dispatch(getDraftMessage(draftMessageId))
                .then(response => {
                    const data = response?.data;
                    const priorityValue = data?.priority;

                    formikBag.setFieldValue("priority", Object.entries(MessageEnum.priorityLevel).filter(item => item[0] === priorityValue).map(item => item[1])[0]);
                    formikBag.setFieldValue("title", data?.title);
                    formikBag.setFieldValue("body", data?.body);
                    formikBag.setFieldValue("latitude", data?.latitude);
                    formikBag.setFieldValue("longitude", data?.longitude);
                    formikBag.setFieldValue("groupIds", data?.receiverGroups.map(group => group.id));
                    formikBag.setFieldValue("date", data?.requestedRealization?.split("T")[0]);
                    formikBag.setFieldValue("time", data?.requestedRealization?.split("T")[1]);

                    setState(prevState => {
                        return {
                            ...prevState,
                            sendImmediately: !data?.requestedRealization,
                            useGeoLocation: data?.latitude && data?.longitude,
                            uploadedFiles: [...data?.attachments],
                        }
                    });
                });

            dispatch(getManyDraftMessageAddresses(draftMessageId))
                .then(response => {
                    if (response?.data?.length > 0) {
                        setState(prevState => {
                            return {
                                ...prevState,
                                useLocationFilter: true,
                                sendToAll: false
                            }
                        });
                    }
                });
            setShouldInitializeDraftValues(false);
        }

        if (editDraftMode && isLoading(draftMessageGet)) {
            return (
                <Loader/>
            )
        }

        switch (currentStep) {
            case 1:
                return (
                    <Step1
                        formikBag={ formikBag }
                        currentStep={ currentStep }
                        useGeoLocation={ useGeoLocation }
                        useLocationFilter={ useLocationFilter }
                        sendImmediately={ sendImmediately }
                        uploadedFiles={ uploadedFiles }
                        defaultCoordinates={ defaultCoordinates }
                        editDraftMode={ editDraftMode }
                        draftMessageId={ draftMessageId }
                        setState={ setState }
                        _useGeoLocationToggle={ _useGeoLocationToggle }
                        _useLocationFilterChange={ _useLocationFilterChange }
                        _sendImmediatelyChange={ _sendImmediatelyChange }
                        _alternativeTextChange={ () => {
                            formikBag.setErrors({})
                        } }
                        _onFileUploadStarted={ _onFileUploadStarted }
                        _onDeleteUploadedFileClick={ _onDeleteUploadedFileClick }
                        _onNextClick={ (event) => {
                            _onNextClick(event, formikBag)
                        } }
                        _onSubmitDraftMessage={ _onSubmitDraftMessage }
                    />
                );

            case 2:
                return (
                    <Step2
                        values={ values }
                        editDraftMode={ editDraftMode }
                        useGeoLocation={ useGeoLocation }
                        useLocationFilter={ useLocationFilter }
                        uploadedFiles={ uploadedFiles }
                        currentStep={ currentStep }
                        onPrevClick={ _onPrevStepClick }
                        _onCancel={ () => _onCancel(formikBag.resetForm) }/>
                );

            case 3:
                return (
                    <Step3
                        editDraftMode={ editDraftMode }
                        currentStep={ currentStep }
                        _onNewMessageClicked={ () => _onNewMessageClicked(formikBag.resetForm) }
                    />
                );

            default:
                return null;
        }

    };

    return (
        <>
            {
                currentStep !== 3 &&
                <Header>
                    {
                        editDraftMode
                            ? currentStep === 1 &&
                            "Edycja wiadomości roboczej"
                            :
                            currentStep === 1 &&
                            "Nowa wiadomość"
                    }
                    {
                        currentStep === 2 &&
                        "Sprawdź poprawność i zatwierdź wysyłkę"
                    }
                </Header>
            }

            <Formik
                initialValues={ formikInitialFormValues }
                onSubmit={ _onSubmit }
                render={ formikBag => (
                    <Form className={ `${ currentStep !== 3 ? "has-bottom-action-buttons" : "" }` }>

                        {
                            renderAppropriateStep(formikBag)
                        }

                    </Form>
                ) }/>
        </>
    )

};

export default withMultiStepForm(MessageSending)