import add from "./add";
import delete_ from "./delete";
import get from "./get";
import getMany from "./get_many";
import getManyPaginated from "./get_many_paginated"
import update from "./update";
import locations from "./locations";
import municipalWaste from "./municipal_waste";
import importFile from "./import_file";
import downloadTemplate from "./download_template";

export default (state = {}, action) => {
    return {
        ...state,
        add: add(state.add, action),
        delete_: delete_(state.delete_, action),
        get: get(state.get, action),
        getMany: getMany(state.getMany, action),
        getManyPaginated: getManyPaginated(state.getManyPaginated, action),
        update: update(state.update, action),
        locations: locations(state.locations, action),
        municipalWaste: municipalWaste(state.municipalWaste, action),
        import: importFile(state.import, action),
        download: downloadTemplate(state.download, action)
    };
}
